import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getInputs, initialValues } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  checkPlanExists,
  createPlan,
  getPlanEnums,
  getPlanInfo,
  updatePlan,
} from './queries';
import {
  LookupToList,
  extractSublines,
  graphqlEntityToPlanInfo,
} from './utils';
import Loader from '../../components/Loader';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { isEmpty } from '../../utils/validationUtils';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { getError } from '../../utils/graph-utils';
import { useNavigate } from 'react-router-dom';
import DateService from '../../services/dateService';

const PlanDrawer: React.FC<IPlanDrawerProps> = ({
  planId,
  lineId,
  lineName,
  sublineName,
  sublineID,
  isSublineNameDisabled = false,
  isLineNameDisabled = true,
  open,
  onSuccess,
  onClose,
}) => {
  const navigate = useNavigate();
  const [booted, setBooted] = useState<boolean>(false);

  const [values, setValues] = useState<Record<string, any>>({});

  const [planEnumResultsQuery] = useLazyQuery(getPlanEnums());

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [lists, setLists] = useState<Record<string, Record<string, string>>>({
    line: {},
    subline: {},
  });

  const [planAction] = useMutation(planId ? updatePlan() : createPlan());

  const [checkPlanExistsLazy] = useLazyQuery(checkPlanExists(), {});

  const [planInfoQuery] = useLazyQuery(getPlanInfo());

  const loadLovList = async () => {
    const result = await planEnumResultsQuery({
      fetchPolicy: 'no-cache',
      variables: { SelectedLineId: lineId },
    });

    const newPlanEnums = LookupToList(result.data);

    return newPlanEnums;
  };

  const getEntityInfo = async () => {
    if (planId) {
      const apiResult = await planInfoQuery({
        variables: { id: planId },
      });
      if (apiResult.data) {
        const planEntity = graphqlEntityToPlanInfo(
          apiResult.data,
          lineId,
          lineName,
          sublineID
        );
        return planEntity;
      }
    }

    return null;
  };

  const initialize = async () => {
    try {
      const [planEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);
      setLists({
        line: lovData.lines,
        subline: lineId ? lovData.sublines : {},
      });
      setValues({
        ...planEntity,
        lineName: lineName,
        sublineName: sublineID,
      });
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(
      getInputs(
        values as any,
        lists,
        isLineNameDisabled,
        isSublineNameDisabled
      ),
      values
    );

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const variables = {
        planDetailInputs: {
          externalCode: data.externalCode,
          lineID: lineId || data.lineName,
          sublineID: data.sublineName,
          name: data.planName,
          nameArabic: data.arabicName,
          abbreviation: data.abbreviation,
          effectiveFrom: DateService.formatDateBackend(data.effectiveFrom),
          effectiveTo: DateService.formatDateBackend(data.effectiveTo),
          planStatus: data.planStatus,
        },
      };

      const response = await planAction({
        variables: planId ? { ...variables, entityId: planId } : variables,
        errorPolicy: 'all',
      });

      if (isEmpty(response.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {planId ? 'Plan successfully updated' : 'Plan successfully created'}
          </ToastSuccessMessage>
        );

        setSubmitButtonState('success');

        setTimeout(() => {
          onSuccess();
          onClose();

          if (isEmpty(lineId) && isEmpty(sublineID)) {
            const newPlanId =
              response.data.planConfigManagement.actions.createPlanDetails.id;
            navigate(`/plan/plans/${newPlanId}`);
          }
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{getError(response)}</ToastErrorMessage>
        );
      }
    } catch (err) {
      setSubmitButtonState(undefined);
      toast.error(
        <ToastErrorMessage>
          {getError(err) || DEFAULT_ERROR_TEXT}
        </ToastErrorMessage>
      );
    } finally {
      setFormDisabled(false);
    }
  };

  const validatePlanExistsOnNameChange = async (
    selectedSublineId: string,
    planName?: string
  ): Promise<string> => {
    if (!isEmpty(planName) && !isEmpty(selectedSublineId)) {
      const validationResponse = await checkPlanExistsLazy({
        variables: {
          name: planName,
          planId: planId,
          sublineId: !isEmpty(sublineID) ? sublineID : selectedSublineId,
          externalCode: '',
        },
      });

      if (
        validationResponse.data.PlanConfigManagement?.queries?.checkPlanExists
          .length > 0
      ) {
        return 'Plan Name already exists under the same Subline';
      }
    }
    return '';
  };

  const validatePlanExistsOnExternalCodeChange = async (
    selectedSublineId: string,
    externalCode?: string
  ): Promise<string> => {
    if (!isEmpty(externalCode) && !isEmpty(selectedSublineId)) {
      const validationResponse = await checkPlanExistsLazy({
        variables: {
          planId: planId,
          externalCode: externalCode,
          sublineId: selectedSublineId,
          name: '',
        },
      });

      if (
        validationResponse.data.PlanConfigManagement?.queries?.checkPlanExists
          .length > 0
      ) {
        return 'External Code already exists under the same Subline';
      }
    }
    return '';
  };

  const handleEffectiveDateChange = (effectiveTo: any, effectiveFrom: any) => {
    const isDateInvalid =
      effectiveTo &&
      effectiveFrom &&
      DateService.isDateBefore(effectiveTo, effectiveFrom);

    if (isDateInvalid) {
      return 'Effective To Date shall be equal or greater than Effective From Date';
    } else {
      return '';
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const handleLineSelection = async (selectedOption: string) => {
    if (!isEmpty(selectedOption)) {
      const newData = await planEnumResultsQuery({
        variables: {
          SelectedLineId: selectedOption,
        },
      });
      const sublines = extractSublines(newData.data);
      return sublines;
    }
    return {} as Record<string, string>;
  };

  const onCustomValidate = async (
    fieldName: string,
    values: Record<string, any>
  ) => {
    if (fieldName === 'planName') {
      return await validatePlanExistsOnNameChange(
        values.sublineName,
        values.planName
      );
    }

    if (fieldName === 'externalCode') {
      return await validatePlanExistsOnExternalCodeChange(
        values.sublineName,
        values[fieldName]
      );
    }

    if (fieldName === 'effectiveFrom' || fieldName === 'effectiveTo') {
      return handleEffectiveDateChange(
        values.effectiveTo,
        values.effectiveFrom
      );
    }

    return '';
  };

  const onChange = async (
    fieldName: string,
    value: string,
    values: Record<string, any>,
    errors: Record<string, string>,
    touched: Record<string, boolean>
  ) => {
    const newLists = { ...lists };

    if (fieldName === 'lineName') {
      newLists['subline'] = await handleLineSelection(value);
      values['sublineName'] = '';
      const [planNameError, externalCodeError] = await Promise.all([
        onCustomValidate('planName', values),
        onCustomValidate('externalCode', values),
      ]);
      errors.planName = planNameError;
      errors.externalCode = externalCodeError;

      touched.planName = true;
      touched.externalCode = true;
    }

    if (fieldName === 'sublineName') {
      const [planNameError, externalCodeError] = await Promise.all([
        onCustomValidate('planName', values),
        onCustomValidate('externalCode', values),
      ]);

      errors.planName = planNameError;
      errors.externalCode = externalCodeError;

      touched.planName = true;
      touched.externalCode = true;
    }

    if (fieldName === 'effectiveFrom' || fieldName === 'effectiveTo') {
      const effectiveToError = await onCustomValidate('effectiveTo', values);
      errors.effectiveTo = effectiveToError;

      touched.effectiveFrom = true;
      touched.effectiveTo = true;
    }
    setValues(values);
    setLists(newLists);
  };

  const onCustomBlur = async (
    fieldName: string,
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    if (
      fieldName === 'planName' ||
      fieldName === 'externalCode' ||
      fieldName === 'effectiveFrom' ||
      fieldName === 'effectiveTo'
    ) {
      if (!errors[fieldName]) {
        errors[fieldName] = await onCustomValidate(fieldName, values);
      }
    }
    return { values, errors };
  };

  const onCustomValidateForm = async (
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    if (!errors.planName) {
      errors.planName = await onCustomValidate('planName', values);
    }

    if (!errors.externalCode) {
      errors.externalCode = await onCustomValidate('externalCode', values);
    }

    if (!errors.effectiveFrom) {
      errors.effectiveFrom = await onCustomValidate('effectiveFrom', values);
    }

    if (!errors.effectiveTo) {
      errors.effectiveTo = await onCustomValidate('effectiveTo', values);
    }

    return errors;
  };

  const inputForm = useMemo(() => {
    const result = getInputs(
      values || (initialValues as any),
      lists,
      isLineNameDisabled,
      isSublineNameDisabled
    );

    return result;
  }, [values, lists]);

  return (
    <GenericDrawer
      title={planId ? 'Edit Plan' : 'New Plan'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={!!submitButtonState}
            disableForm={formDisabled}
            title="Information"
            onCustomBlur={onCustomBlur}
            onChange={onChange}
            onCustomValidate={onCustomValidateForm}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PlanDrawer;

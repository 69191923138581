import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ICommissionListingData } from '..';
import WidgetPaper from '../../../components/common/WidgetPaper';
import WidgetSection from '../../../components/common/WidgetSection';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import EnhancedButton from '../../../components/EnhancedButton';
import { IListingData } from '../../../models/listing';
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterLowerOthers,
  trimTrailingZeros,
} from '../../../utils/formatting-utils';
import { comissionHeaders } from './content';
import { IProposalDetailsSummary } from './index2';
import UpdateCommissionProposalDrawer from '../../update-commission-proposal/UpdateCommissionProposalDrawer';
import { isValidNumber } from '../../../utils/validationUtils';
import PrecisionService from '../../../services/precisionService';

const useStyles = makeStyles()(() => ({
  paperClass: {
    boxShadow: 'none !important',
  },
  updateBtn: {
    margin: '15px auto 0',
  },
}));

interface IComissionProposalWidgetProps {
  data: IProposalDetailsSummary;
  className?: string;
  onUpdateSuccess: () => void;
}

export const ComissionProposalWidget: React.FC<
  IComissionProposalWidgetProps
> = ({ data, className, onUpdateSuccess }) => {
  const { classes } = useStyles();

  const [modifyCommissionDrawerOpen, setModifyCommissionDrawerOpen] =
    useState<boolean>(false);

  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 3,
    pageNumber: 0,
    totalCount: 0,
  });

  const [modalData, setModalData] = useState<IUpdateCommissionProposalData>();
  const [partnerLov, setPartnerLov] = useState<Record<string, string>>();

  useEffect(() => {
    const result: Record<string, ICommissionListingData> = {};
    const newPartnerLov: Record<string, string> = {};

    data.CommissionDetails.forEach((item) => {
      newPartnerLov[item.Id] = item.BusinessUserFullName;

      result[item.Id] = {
        id: item.Id,
        partner: item.BusinessUserFullName,
        share: isValidNumber(item.Share)
          ? trimTrailingZeros(
              PrecisionService.multiplyBy100(Number(item.Share))
            )
          : 0,
        planCommission: trimTrailingZeros(item.PlanCommissionAmount),
        applicableOn: capitalizeFirstLetterLowerOthers(item.ApplicableOn, '_'),
        totalAmount: item.PolicyCommisisonAmount,
        isPrimary: item.IsPrimary,
      };
    });

    const primaryBP = data.CommissionDetails.find((c) => c.IsPrimary);

    const secondaryBPs = data.CommissionDetails.filter((c) => !c.IsPrimary);

    const data2: IUpdateCommissionProposalData = {
      primaryBP: primaryBP.Id,
      primaryPercentage: primaryBP.Share,
      secondaryBP: secondaryBPs?.[0]?.Id,
      secondaryPercentage: secondaryBPs?.[0]?.Share || 0,
      additionalBP: secondaryBPs?.[1]?.Id,
      additionalPercentage: secondaryBPs?.[1]?.Share || 0,
    };

    setModalData(data2);

    setPartnerLov(newPartnerLov);

    setTableData({
      pagedItems: result,
      pageSize: data.CommissionDetails?.length | 3,
      pageNumber: 0,
      totalCount: data.CommissionDetails?.length,
    });
  }, [data]);

  return (
    <WidgetPaper className={className}>
      <WidgetSection title={capitalizeFirstLetter('Commission Split')}>
        <EnhancedTable
          hideToolbar
          disableSelection
          tableClasses={{
            paperClass: classes.paperClass,
          }}
          usePagination={false}
          name={''}
          inline={true}
          showTablePagination={false}
          headers={comissionHeaders(data?.PolicyCurrency?.Symbol || '')}
          data={tableData}
        />

        {data?.Status?.toUpperCase() === 'IN_PROGRESS' && (
          <>
            <EnhancedButton
              onClick={() => {
                setModifyCommissionDrawerOpen(true);
              }}
              // disabled={disableForm}
              variant="contained"
              className={classes.updateBtn}
              // state={submitButtonState}
            >
              Update
            </EnhancedButton>
            {modifyCommissionDrawerOpen && (
              <UpdateCommissionProposalDrawer
                open={modifyCommissionDrawerOpen}
                onClose={() => setModifyCommissionDrawerOpen(false)}
                onSuccess={() => {
                  onUpdateSuccess();
                }}
                id={data.Id}
                data={modalData}
                partnerLov={partnerLov}
              />
            )}
          </>
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { filterQuery, lineFilter, listQuery } from './queries';
import {
  dataToBPLovs,
  dataToLovs,
  dataToPlanLovs,
  dataToSublineLovs,
  mapToListingData,
} from './utils';
import { headers, filterSectionsContent } from './content';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import ListingWidget from '../../components/form-fields/listing-widget/ListingWidget';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IFilterModel } from '../../components/form-fields/listing-widget';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { useAppSelector } from '../../redux/hooks';
import { isEqual } from 'lodash';
import { isEmpty } from '../../utils/validationUtils';
import { DEFAULT_FILTER_LOVS, IPolicyFilterLovs } from '.';
import DateService from '../../services/dateService';

const PolicyListingPage: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const businessUserId = user.info.businessUser_Id;
  const FILTER_SESSION_KEY = 'policyFilter';

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          line: [],
          subline: [],
          plan: [],
          businessPartner: [],
          insured: [],
          SelectedStatuses: [],
          SelectedStatusReason: [],
          effectiveDate: [],
          issueDate: [],
        };

  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialFilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
  });

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const [lovs, setLovs] = useState<IPolicyFilterLovs>(DEFAULT_FILTER_LOVS);
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [getProductionPoliciesLazy, { loading: loadingTable }] =
    useLazyQuery(listQuery());
  const [filterResponseLazy] = useLazyQuery(filterQuery());
  const [filterLineLazy] = useLazyQuery(lineFilter());

  const initialize = async () => {
    Promise.all([
      filterResponseLazy({
        variables: {
          selectedBusinessUserID: businessUserId,
          selectedLineID: filterValues.namedFilters.line,
        },
      }),
      filterLineLazy({
        variables: {
          selectedBusinessUserID: businessUserId,
        },
      }),
    ]).then(([{ data: filtersData }, { data: lineFiltersData }]) => {
      const lovs = dataToLovs(
        filtersData,
        lineFiltersData,
        filterValues.namedFilters
      );
      setLovs(lovs);
      setFilterSections(filterSectionsContent(lovs, filterValues.namedFilters));
    });
  };

  const onFilterUpdate = async (
    filters: Record<string, unknown>,
    inputName?: string
  ) => {
    if (isEqual(filters, filterValues.namedFilters)) {
      // Do nothing if filters are the same
      return;
    }
    const newFilters = {
      ...filterValues,
      namedFilters: filters,
    };
    setFilter(filters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    getListingData(newFilters);
    if (inputName === 'line') {
      filterResponseLazy({
        variables: {
          selectedBusinessUserID: businessUserId,
          selectedLineID: filters.line,
        },
      }).then(({ data: filtersData }) => {
        const businessPartners = dataToBPLovs(filtersData);
        const sublines = dataToSublineLovs(filtersData);
        const plans = dataToPlanLovs(
          filtersData,
          filters.subline?.toString(),
          sublines
        );
        const newLovs = {
          ...lovs,
          businessPartner: businessPartners,
          subline: sublines,
          plan: plans,
        };
        setLovs(newLovs);
        setFilterSections(filterSectionsContent(newLovs, filters));
      });
    }
  };

  const getListingData = (filter = filterValues) => {
    if (!filter) {
      return {};
    }

    const filterV = filter.namedFilters;
    const pagination = filter.pagination;
    return getProductionPoliciesLazy({
      variables: {
        currentPage: pagination.pageNumber,
        currentPageSize: pagination.pageSize,
        KeywordSearch: isEmpty(filter.searchKey) ? null : filter.searchKey,
        // OrderBy: {
        //   Attribute: filter.orderBy,
        //   Descending: filter.descending || true,
        // },
        selectedLines: filterV?.line?.length ? filterV.line : [],
        selectedSublines: filterV?.subline?.length ? filterV.subline : [],
        selectedPlans: filterV?.plan?.length ? filterV.plan : [],
        selectedBusinessPartners: filterV?.businessPartner?.length
          ? filterV.businessPartner
          : [],
        selectedInsuredIDs: filterV?.insured?.length ? filterV.insured : [],
        SelectedStatuses: filterV?.status?.length ? filterV.status : null,
        selectedStatusReasons: filterV?.statusReason?.length
          ? filterV.statusReason
          : null,
        effectiveDateFrom: DateService.formatDateBackend(
          filterV.effectiveDate?.[0]
        ),
        effectiveDateTo: DateService.formatDateBackend(
          filterV.effectiveDate?.[1]
        ),
        issueDateFrom: DateService.formatDateBackend(filterV.issueDate?.[0]),
        issueDateTo: DateService.formatDateBackend(filterV.issueDate?.[1]),
        type: 'INDIVIDUAL',
      },
    })
      .then(({ data: policiesData }) => {
        if (policiesData.error) {
          toast.error(
            <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
          );
          return;
        }

        setFilterValues(filter);

        // Update table data
        const newTableData = mapToListingData(policiesData);
        setTableData(newTableData);
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      });
  };

  function handlePageChange(
    page: number,
    filterModel = filterValues
  ): Promise<void> {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  }

  const renderMainChildren = () => (
    <ListingWidget
      title="Policies"
      name="policies"
      data={tableData}
      tableSettings={{ headers }}
      onPageChange={(filterModel) => {
        const newFilterModel = {
          ...filterValues,
          ...filterModel,
        };
        const page = filterModel.pagination.pageNumber;
        return handlePageChange(page, newFilterModel);
      }}
      usePagination
      disableSelection
      disabledSorting
      actions={[]}
      loading={loadingTable}
    />
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StaticLayout
      name="PolicyListingPage"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default PolicyListingPage;

import React, { useMemo, useState } from 'react';
import { getHeaders } from './content';
import { useQuery } from '@apollo/client';
import ListingTable from '../../../../../components/form-fields/table/ListingTable';
import { getAmendmentListQuery } from './queries';
import { IListingData } from '../../../../../models/listing';
import { mapToListingData } from './utils';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../../../constants';
import {
  ActionTarget,
  IEnhancedMenuItem,
} from '../../../../../components/form-fields/table';
import AddAmendmentDrawer from './AddAmendmentDrawer';

interface IAmendmentListing {
  policyId: string;
  allowEndorsement: boolean;
  allowPolicyCorrection: boolean;
  transferredToAccounting: boolean;
  isGroup?: boolean;
}

const ProductionAmendmentListing: React.FC<IAmendmentListing> = ({
  policyId,
  allowEndorsement,
  allowPolicyCorrection,
  transferredToAccounting,
  isGroup = false,
}) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const { data, loading, error } = useQuery(getAmendmentListQuery, {
    variables: {
      policyId,
    },
  });

  const { tableData, lovs } = useMemo((): {
    tableData: IListingData;
    lovs: Record<string, Record<string, string>>;
  } => {
    if (!data) {
      return {
        lovs: {},
        tableData: {
          pagedItems: {},
          pageSize: 5,
          pageNumber: 1,
          totalCount: 0,
        },
      };
    }

    const { items, lovs } = mapToListingData(
      data,
      transferredToAccounting,
      allowPolicyCorrection,
      allowEndorsement
    );
    return { tableData: items, lovs };
  }, [data]);

  const actions: IEnhancedMenuItem[] = [
    {
      name: 'newamendment',
      title: 'Add Amendment',
      onClick: () => {
        setOpenDrawer(true);
      },
      rowIndependant: true,
      target: ActionTarget.Entity,
    },
  ];

  if (error) {
    toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    return undefined;
  }

  return (
    <>
      <ListingTable
        inlineTitle="Amendments"
        name="amendments"
        data={tableData}
        headers={getHeaders(isGroup)}
        loader={loading}
        actions={allowEndorsement ? actions : []}
        inline
        orderByAscendingByDefault
        disableSelection
      />
      {openDrawer && (
        <AddAmendmentDrawer
          open={openDrawer}
          data={lovs}
          onClose={() => {
            setOpenDrawer(false);
          }}
        />
      )}
    </>
  );
};

export default ProductionAmendmentListing;

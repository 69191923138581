import React, { useEffect, useRef, useState } from 'react';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableDateRangeFilter,
  IEnhancedTableFilter,
  IEnhancedTableSelectFilter,
} from '../../../enhanced-table';
import { ITableRowSettings } from '../../../widgets/listing-widget';
import { IAbstractRecord } from '../../../../models';
import { IListingData } from '../../../../models/listing';
import {
  getSorting,
  rowSort,
  TableSortOrder,
} from '../../../../utils/table-utils';
import ListingTableBodyWithCellInputs from './ListingTableBodyWithCellInputs';
import { makeStyles } from 'tss-react/mui';
import { cloneDeep } from 'lodash';
import { useAppSelector } from '../../../../redux/hooks';
import { Paper, Table, TablePagination } from '@mui/material';
import clsx from 'clsx';
import Separator from '../../../common/Separator';
import ListingTableToolbar from '../ListingTableToolbar';
import ListingTableHead from '../ListingTableHead';
import { IEnhancedRow } from '..';
import DateService from '../../../../services/dateService';

export interface IListingTableWithCellInputsProps {
  name: string;
  headers: Record<string, EnhancedTableHeader>;
  rowSettings?: ITableRowSettings;
  dynamicRowMessage?: (row: IEnhancedRow) => string[];
  data: IListingData;
  loader?: boolean;
  inline?: boolean;
  inlineTitle?: string;
  defaultOrderByColumn?: string;
  orderByAscendingByDefault?: boolean;
  onDataUpdate?(data: IAbstractRecord[]): void;
  selected?: string[];
  setSelected?(selections: string[]): void;
  handleUpdate(rowKey: number, property: string, value: unknown): void;
  handleCellValueChanged?(
    rowKey: number,
    property: string,
    value: unknown
  ): void;
  inlineFilters?: Record<string, IEnhancedTableFilter>;
  disableSelection?: boolean;
  forceShowSelectColumn?: boolean;
}

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    margin: '0',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  toolbarPaper: {
    margin: '0 0 1em',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  separator: {
    height: '2px !important',
    margin: '0 !important',
  },
}));

const ListingTableWithCellInputs: React.FC<
  IListingTableWithCellInputsProps
> = ({
  name,
  headers,
  rowSettings,
  data,
  inline,
  inlineTitle,
  defaultOrderByColumn,
  orderByAscendingByDefault,
  selected = [],
  setSelected = () => {},
  handleUpdate,
  handleCellValueChanged,
  inlineFilters,
  disableSelection,
  dynamicRowMessage,
  loader,
  forceShowSelectColumn,
}) => {
  const { classes } = useStyles();
  let filterDelay: NodeJS.Timeout;
  const [order, setOrder] = useState<TableSortOrder>();
  const [orderBy, setOrderBy] = useState<string>();
  const [page, setPage] = useState(
    data.pageNumber > 0 ? data.pageNumber - 1 : 0
  );
  const [pagedItems, setPagedItems] = useState<IAbstractRecord>({});
  const [rowsPerPage, setRowsPerPage] = useState(data.pageSize);
  const [filtered, setFiltered] = useState(data.pagedItems);
  const [filterValue, setFilterValue] = useState('');
  const [filters, setFilters] =
    useState<Record<string, IEnhancedTableFilter>>();
  const currentName = useRef<string>();
  const { cdnUrl, phoneNumberPattern, currencySymbol } = useAppSelector(
    (state) => state.tenant
  );

  useEffect(() => {
    if (inlineFilters && !filters) {
      setFilters(inlineFilters);
    }
  }, [filters, inlineFilters]);

  useEffect(() => {
    setPagedItems(data.pagedItems);
  }, [data]);

  // Watches data.pagedItems, headers and fiterValue, and recalculates when one changes
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    filterDelay = setTimeout(() => {
      if (headers) {
        const outKeys: string[] = Object.keys(pagedItems).filter((itemKey) => {
          const curItem = pagedItems[itemKey];
          return (
            Object.values(headers).some((header) => {
              return (
                curItem[header.name] &&
                ((typeof curItem[header.name] === 'string' &&
                  header.type !== EnhancedTableHeaderType.Input &&
                  curItem[header.name]
                    .toLowerCase()
                    .includes(filterValue.toLowerCase())) ||
                  (header.name === 'DueDate' &&
                    DateService.formatDate(curItem[header.name])
                      .toLowerCase()
                      .includes(filterValue.toLowerCase())))
              );
            }) &&
            (!filters ||
              Object.values(filters).every((filter) => {
                switch (filter.type) {
                  case 'select': {
                    const filterType = filter as IEnhancedTableSelectFilter;
                    if (
                      filterType.options.length === 0 ||
                      filterType.selectedValues.length === 0
                    ) {
                      return true;
                    }
                    return filterType.selectedValues.includes(
                      curItem[filter.name]
                    );
                  }
                  case 'daterange': {
                    const filterType = filter as IEnhancedTableDateRangeFilter;
                    if (
                      filterType.value.length === 0 ||
                      filterType.value.length === 0
                    ) {
                      return true;
                    }
                    return false;
                  }
                }
              }))
          );
        });
        const output: typeof pagedItems = {};
        outKeys.forEach((key) => {
          output[key] = pagedItems[key];
        });

        const newSelections = selected.filter((key) =>
          Object.keys(pagedItems).includes(key)
        );
        // if (!isEqual(sortBy(selected), sortBy(newSelections))) {
        setSelected(newSelections);
        // }
        setFiltered(output);
        // if (!updatingInput.current) {
        //   setPage(0);
        // }
      } else {
        setFiltered(pagedItems);
      }
    }, 250);
    return () => {
      clearTimeout(filterDelay);
    };
  }, [pagedItems, filterValue, headers, filters]);

  useEffect(() => {
    if (currentName.current !== name && defaultOrderByColumn) {
      currentName.current = name;
      setOrder(orderByAscendingByDefault ? 'asc' : 'desc');
      setOrderBy(defaultOrderByColumn);
    }
  }, [defaultOrderByColumn, name, orderByAscendingByDefault]);

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: string
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(rowName: string, checked: boolean) {
    if (checked) {
      setSelected(Object.keys(filtered));
      return;
    }
    setSelected([]);
  }

  function handleClick(rowName: string) {
    const selectedIndex = selected.indexOf(rowName);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const handleChange = (search: string) => {
    setFilterValue(search);
  };

  // const handleFilterChange = (
  //   filterName: string,
  //   field: string,
  //   value: boolean
  // ) => {
  //   if (filters && filters[filterName]) {
  //     const row: Record<string, IEnhancedTableFilter> = {};
  //     row[filterName] = cloneDeep(filters[filterName]);
  //     if (row[filterName].type === 'select') {
  //       const filter = row[filterName] as IEnhancedTableSelectFilter;
  //       if (value) {
  //         filter.selectedValues.push(field);
  //       } else {
  //         filter.selectedValues = filter.selectedValues.filter(
  //           (option) => option !== field
  //         );
  //       }
  //     }
  //     setFilters({ ...filters, ...row });
  //   }
  // };

  const handleFilterClear = () => {
    if (filters) {
      const newFilters = cloneDeep(filters);
      Object.values(newFilters).forEach((filter) => {
        if (filter.type === 'select') {
          (filter as IEnhancedTableSelectFilter).selectedValues = [];
        }
      });
      setFilters(newFilters);
    }
  };

  const isSelected = (key: string) => selected.indexOf(key) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, Object.keys(filtered).length - page * rowsPerPage);

  const renderToolbar = () => (
    <ListingTableToolbar
      selected={selected}
      actions={[]}
      data={filtered}
      handleChange={handleChange}
      cdnUrl={cdnUrl}
      title={inlineTitle}
      onFilterClick={() => {}}
      onFilterClear={handleFilterClear}
      dropdownActionsNames={[]}
    />
  );
  return (
    <div className={classes.root}>
      {!inline && (
        <Paper className={clsx(classes.paper, classes.toolbarPaper)}>
          {renderToolbar()}
        </Paper>
      )}
      <Paper className={classes.paper}>
        {inline && (
          <>
            {renderToolbar()}
            <Separator className={classes.separator} />
          </>
        )}
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <ListingTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={Object.keys(filtered).length}
              headRows={headers}
              disableSelection={disableSelection}
              forceDisableSelectAll
              forceShowSelectColumn={forceShowSelectColumn}
            />
            <ListingTableBodyWithCellInputs
              emptyRows={emptyRows}
              handleClick={handleClick}
              headers={headers}
              rowSettings={rowSettings}
              dynamicRowMessage={dynamicRowMessage}
              loader={loader}
              isSelected={isSelected}
              rows={rowSort(
                filtered,
                getSorting(order, orderBy, headers)
              ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
              handleUpdate={handleUpdate}
              handleCellValueChanged={handleCellValueChanged}
              currencySymbol={currencySymbol}
              disableSelection={disableSelection}
              phoneNumberPattern={phoneNumberPattern}
              forceShowSelectColumn={forceShowSelectColumn}
            />
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={Object.keys(filtered).length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
        />
      </Paper>
    </div>
  );
};

export default ListingTableWithCellInputs;

import * as XLSX from 'xlsx-js-style';
import { IAbstractRecord } from '../models';
import { isEmpty } from './validationUtils';

type ExcelJSON = Record<string, IAbstractRecord[]>;

const excelToJson = async (file: File): Promise<ExcelJSON> => {
  const reader = new FileReader();

  const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      reader.onload = (e) => resolve(e.target?.result as ArrayBuffer);
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const data = new Uint8Array(await readFileAsArrayBuffer(file));
  const workbook = XLSX.read(data, { type: 'array', cellDates: true });

  const result: ExcelJSON = {};

  workbook.SheetNames.forEach((sheetName) => {
    const worksheet = workbook.Sheets[sheetName];
    const jsonSheet = XLSX.utils.sheet_to_json<IAbstractRecord>(worksheet, {
      header: 1,
    });

    // Convert array of arrays to array of objects using the first row as keys
    const headers = jsonSheet[0];
    const rows = jsonSheet.slice(1).map((row) => {
      const rowObject: IAbstractRecord = {};
      headers.forEach((header: any, index: number) => {
        rowObject[header] = row[index];
      });
      return rowObject;
    });

    result[sheetName] = rows;
  });

  const resultFiltered = filterNonEmptyObjects(result);
  return resultFiltered;
};

function isNonEmptyObject(obj: IAbstractRecord): boolean {
  return Object.values(obj).some((value) => !isEmpty(value));
}

function filterNonEmptyObjects(obj: ExcelJSON): ExcelJSON {
  const filteredObject: ExcelJSON = {};

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      filteredObject[key] = obj[key].filter(
        (item: IAbstractRecord) =>
          Object.keys(item).length > 0 && isNonEmptyObject(item)
      );
    }
  }

  return filteredObject;
}

export { excelToJson };

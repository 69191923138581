import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import { gridWidthToPercentage } from '../../../../utils/grid-utils';
import EnhancedStepper from '../../../../components/common/EnhancedStepper';
import ProductionPolicyEntityInfo from './ProductionPolicyEntityInfo';
import { DEFAULT_ERROR_TEXT, MAIN_ONE_THEME } from '../../../../constants';
import ProductionPolicyDetailsWidget from './SummaryTab/PolicyDetailsWidget';
import TabsLayout from '../../../../page-layout/tabs-layout/TabsLayout';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { productionPolicyPage } from './content';
import _ from 'lodash';
import ProductionPolicyCoversInfo from './SummaryTab/ProductionPolicyCoversInfo';
import ProductionCostChargesWidget from './SummaryTab/ProductionCostChargesWidget';
import ProductionPolicyMotorDetailsWidget from './SummaryTab/ProductionPolicyMotorDetailsWidget';
import {
  getPlanPolicySpecificMedicalCovers,
  getPolicyDetailsQuery,
  getProductionDocuments,
  regenerateDocuments,
} from './queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GetDocumentsData, GetProductionPolicyData } from './functions';
import { IDocumentsDetails, IProductionDetailsSummary } from '.';
import ProductionPolicyQuoteWidget from './SummaryTab/PolicyProposalQuoteWidget';
import PersonsWidget from './custom-components/PersonsWidget';
import { ProductionComissionWidget } from './custom-components/commission-widget/ProductionComissionWidget';
import DocumentWidget from '../../../../components/widgets/file-card/DocumentWidget';
import {
  FileType,
  FileUploadStatus,
  IFile,
  IFileCardProps,
} from '../../../../models/file';
import Separator from '../../../../components/common/Separator';
import EnhancedButton from '../../../../components/form-fields/buttons/EnhancedButton';
import AddDocumentDrawer from './drawers/AddDocumentDrawer';
import ProductionPolicyExpatDetailsWidget from './SummaryTab/ProductionPolicyExpatDetailsWidget';
import ProductionPolicyPaymentTermsSchedule from './PaymentTermsTab/ProductionPolicyPaymentTermsSchedule';
import ProductionPolicyBankersDetailsInfo from './ProductionPolicyBankersInfo';
import ProductionPolicyMarineDetailsWidget from './SummaryTab/ProductionPolicyMarineDetailsWidget';
import ProductionPolicyMarineHullDetailsWidget from './SummaryTab/ProductionPolicyMarineHullDetailsWidget';
import ProductionPolicyMedicalDetailsWidget from './SummaryTab/ProductionPolicyMedicalDetailsWidget';
import ProductionDescriptionOfRiskDetailsWidget from './SummaryTab/ProductionDescriptionOfRiskDetailsWidget';
import { MedicalInsuredTableWidget } from '../../proposal/page/medical/custom-widgets/insured-table-widget/MedicalInsuredTableWidget';
import ProductionPropertyDescriptionDetailsInfo from './ProductionPropertyDescriptionDetailsInfo';
import { LookupToList, getFirePlanCoversLov, getPlanCoversLov } from './utils';
import PolicyMedicalGroupDetailsWidget from './SummaryTab/PolicyMedicalGroupDetailsWidget';
import ProductionPolicyTravelDetailsWidget from './SummaryTab/ProductionPolicyTravelDetailsWidget';
import { TravelInsuredTableWidget } from '../../proposal/page/travel/custom-widgets/insured-table-widget/TravelInsuredTableWidget';
import ProductionAmendmentListing from './amendments-tab/ProductionAmendmentListing';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import { IConfirmation } from '../../../../redux/confirmation/types';
import { OpenConfirmationAction } from '../../../../redux/confirmation/actions';
import ToastSuccessMessage from '../../../../components/ToastSuccessMessage';
import { getError } from '../../../../utils/graph-utils';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '95%',
    width: '95%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  buttonsContainer: {
    width: '95%',
    margin: '10px auto 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  widgetTopMargin: {
    marginTop: '1em',
  },
  tabPanelClassName: {
    padding: '24px 0',
  },
  thickSeperator: {
    height: 10,
    margin: '10px 0 10px 0',
    gridColumnStart: '1',
    gridColumnEnd: '4',
  },
}));

const ProductionPolicyPage: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);
  const tenant = useAppSelector((state) => state.tenant);

  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams();
  const entityId = params.id;

  const [data, setData] = useState<IProductionDetailsSummary>();
  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    policyCovers: {},
  });

  const [documentsData, setDocumentsData] = useState<IDocumentsDetails>();

  const [getPolicyDetailsLazy] = useLazyQuery(getPolicyDetailsQuery);
  const [geDocumentsDetailsLazy] = useLazyQuery(getProductionDocuments());
  const [getNonSelectedPlanCoverLazy] = useLazyQuery(
    getPlanPolicySpecificMedicalCovers()
  );
  const [addDocumentDrawerOpen, setAddDocumentDrawerOpen] =
    useState<boolean>(false);
  const [regenerateDocumentsMutation] = useMutation(regenerateDocuments());

  const isDescriptionOfRiskVisible = [
    '5',
    '9',
    '10',
    '13',
    '21',
    '35',
    '11',
    '15',
    '16',
    '25',
    '27',
    '29',
    '30',
    '31',
    '37',
    '38',
    '39',
    '42',
    '46',
  ].includes(data?.LineId?.ExternalCode);

  const getPlanCoverDetails = async (
    planId: string,
    planCoverIds: string[]
  ) => {
    const { data } = await getNonSelectedPlanCoverLazy({
      variables: {
        selectedPlanID: planId,
        selectedPlanCoverIDs: planCoverIds,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return data;
  };

  const initialize = async () => {
    setLoading(true);
    try {
      const { data: policyDetails } = await getPolicyDetailsLazy({
        variables: { id: entityId, state: 'LATEST' },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      const summaryData = GetProductionPolicyData(policyDetails);

      if (summaryData.LineId.ExternalCode?.toString() === '6') {
        const planCoverDetails = await getPlanCoverDetails(
          summaryData.PlanID.Id,
          []
        );
        const lovCovers = getPlanCoversLov(planCoverDetails);
        setLovs((prevLovs) => ({
          ...prevLovs,
          policyCovers: lovCovers,
        }));
      }

      const lists = LookupToList(policyDetails);
      setLovs((prevLovs) => ({
        ...prevLovs,
        medicalClasses: lists.medicalClasses,
        plateCodes: lists.plateCodes,
        usageTypes: lists.usageTypes,
        bodyTypes: lists.bodyTypes,
        engineTypes: lists.engineTypes,
        brands: lists.brands,
        colors: lists.colors,
        matters: lists.matters,
        geoLocations: lists.geoLocations,
        phoneType: lists.phoneType,
        occupationClasses: lists.occupationClasses,
        nationalities: lists.nationalities,
        countries: lists.countries,
        planCovers: getFirePlanCoversLov(policyDetails),
      }));

      setData(summaryData);
      setLoading(false);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const loadDocuments = async () => {
    const { data } = await geDocumentsDetailsLazy({
      variables: { id: entityId },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    const summaryData = GetDocumentsData(data);

    setDocumentsData(summaryData);
  };

  useEffect(() => {
    initialize();
    loadDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLeftSection = () => {
    if (data) {
      return <ProductionPolicyEntityInfo data={data} />;
    }

    return <></>;
  };

  const renderMainChildren = () => {
    if (data) {
      const steps: string[] = ['Bound', 'Inforce', 'Cancelled'];

      const activeStep = steps.find(
        (a) => a.toLowerCase() === data.Status?.toLowerCase()
      );

      return (
        <>
          <div style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <div style={{ width: `${gridWidthToPercentage(8)}%` }}>
                <EnhancedStepper
                  activeStep={activeStep}
                  steps={steps}
                  className={classes.stepper}
                />
              </div>
            </div>
          </div>
        </>
      );
    }

    return <></>;
  };

  const addFileToDocumentWidgetFiles = (
    file: IFile,
    propertyId: string,
    entityTypeId: string,
    hideReviewedLabel = true,
    createdOn?: string,
    version?: number,
    documentCode = ''
  ) => {
    const newFile: IFileCardProps = {
      title: documentCode,
      status: FileUploadStatus.Uploaded,
      createdOn: createdOn,
      version: version,
      fileInfo: {
        id: file?.id,
        location: file?.location,
        path: file?.path,
        fileName: file?.fileName,
        length: file?.length,
        contentType: file?.contentType,
      },
      allowedFileTypes: [
        FileType.pdf,
        FileType.docx,
        FileType.jpeg,
        FileType.png,
      ],
      entityViewId: 'Production-download',
      propertyId: propertyId,
      entityId: file?.path?.split('/')[2],
      entityTypeId: entityTypeId,
      hideReviewedLabel: hideReviewedLabel,
    };

    return newFile;
  };
  const renderTabs = useMemo(() => {
    if (!data) {
      return null;
    }

    const documentWidgetFiles: IFileCardProps[] = [];
    const additionalDocumentWidgetFiles: IFileCardProps[] = [];

    if (documentsData) {
      if (
        documentsData.regeneratedDocuments &&
        documentsData.regeneratedDocuments.length > 0
      ) {
        documentsData.regeneratedDocuments.forEach((policyDocument) => {
          documentWidgetFiles.push(
            addFileToDocumentWidgetFiles(
              policyDocument.file,
              'Document',
              'Production-PolicyDocument',
              true,
              policyDocument.createdOn || '',
              policyDocument.version,
              policyDocument.code
            )
          );
        });
      }

      if (
        documentsData.additionalDocuments &&
        documentsData.additionalDocuments.length > 0
      ) {
        documentsData.additionalDocuments.forEach((policyDocument) => {
          additionalDocumentWidgetFiles.push(
            addFileToDocumentWidgetFiles(
              policyDocument.file,
              'Document',
              'Production-PolicyDocument',
              true,
              policyDocument.createdOn,
              policyDocument.version,
              policyDocument.code
            )
          );
        });
      }
    }
    const tabs = _.cloneDeep(productionPolicyPage);

    tabs.tabs[0].widgets[0].children = (
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            width: `${gridWidthToPercentage(8)}%`,
            padding: '0px 1em 1em 0',
          }}
        >
          <ProductionPolicyDetailsWidget data={data} />
          {['51', '50', '4'].includes(data.LineId.ExternalCode) && (
            <>
              <div style={{ height: '1em' }} />
              <ProductionPolicyMotorDetailsWidget
                data={data}
                lovs={lovs}
                allowPolicyCorrection={
                  user.info.businessUser_AllowPolicyCorrection
                }
                onDataRefresh={initialize}
              />
            </>
          )}
          {data?.LineId?.ExternalCode === '43' && (
            <>
              <div style={{ height: '1em' }} />
              <ProductionPolicyExpatDetailsWidget
                data={data}
                lovs={lovs}
                allowPolicyCorrection={
                  user.info.businessUser_AllowPolicyCorrection
                }
                onDataRefresh={initialize}
              />
            </>
          )}

          {data?.LineId?.ExternalCode === '19' && (
            <>
              <div style={{ height: '1em' }} />
              <ProductionPolicyMedicalDetailsWidget data={data} />
            </>
          )}

          {data?.LineId?.ExternalCode === '48' && (
            <>
              <div style={{ height: '1em' }} />
              <ProductionPolicyTravelDetailsWidget data={data} />
            </>
          )}

          {data?.LineId?.ExternalCode === '3' && (
            <>
              <div style={{ height: '1em' }} />
              <ProductionPolicyMarineDetailsWidget
                allowPolicyCorrection={
                  user.info.businessUser_AllowPolicyCorrection
                }
                data={data}
                lovs={lovs}
              />
            </>
          )}

          {isDescriptionOfRiskVisible && (
            <>
              <div style={{ height: '1em' }} />
              <ProductionDescriptionOfRiskDetailsWidget
                data={data}
                lovs={lovs}
                allowPolicyCorrection={
                  user.info.businessUser_AllowPolicyCorrection
                }
              />
            </>
          )}
          {(data?.LineId?.ExternalCode === '33' ||
            data?.LineId?.ExternalCode === '20') && (
            <>
              <div style={{ height: '1em' }} />
              <ProductionPolicyMarineHullDetailsWidget
                data={data}
                allowPolicyCorrection={
                  user.info.businessUser_AllowPolicyCorrection
                }
              />
            </>
          )}

          {data?.Covers?.length > 0 && (
            <>
              <div style={{ height: '1em' }} />
              <ProductionPolicyCoversInfo
                currencySymbol={data?.PolicyCurrency?.Symbol || ''}
                data={data.Covers}
                lineExternalCode={Number(data.LineId.ExternalCode)}
              />
            </>
          )}
          {data?.Bankers?.length > 0 && data?.LineId?.ExternalCode === '12' && (
            <>
              <div style={{ height: '1em' }} />
              <ProductionPolicyBankersDetailsInfo data={data.Bankers} />
            </>
          )}
          {data?.PropertyDescription?.length > 0 &&
            ['2', '8', '23', '26'].includes(data?.LineId?.ExternalCode) && (
              <>
                <div style={{ height: '1em' }} />
                <ProductionPropertyDescriptionDetailsInfo
                  currencySymbol={data?.PolicyCurrency?.Symbol}
                  data={data.PropertyDescription}
                  lovs={lovs}
                  allowPolicyCorrection={
                    user.info.businessUser_AllowPolicyCorrection
                  }
                  onDataRefresh={initialize}
                />
              </>
            )}
          <div style={{ height: '1em' }} />
          <ProductionCostChargesWidget data={data} />

          {data?.LineId?.ExternalCode === '6' && (
            <PolicyMedicalGroupDetailsWidget lovs={lovs} />
          )}

          {(data?.LineId?.ExternalCode === '19' ||
            data?.LineId?.ExternalCode === '6') && (
            <>
              <MedicalInsuredTableWidget data={data as any} />
            </>
          )}

          {data?.LineId?.ExternalCode === '48' && (
            <>
              <TravelInsuredTableWidget data={data as any} />
            </>
          )}
        </div>

        <div
          style={{
            width: `${gridWidthToPercentage(4)}%`,
            padding: '0',
          }}
        >
          <ProductionPolicyQuoteWidget data={data} />
          <ProductionComissionWidget
            className={classes.widgetTopMargin}
            data={data}
          />
        </div>
      </div>
    );

    tabs.tabs[1].widgets[0].children = (
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            width: `${gridWidthToPercentage(12)}%`,
            padding: '0px 1em 1em 0',
          }}
        >
          <PersonsWidget data={data} lovs={lovs} onDataRefresh={initialize} />
        </div>
      </div>
    );

    tabs.tabs[2].widgets[0].children = (
      <>
        <DocumentWidget
          title="Policy Documents"
          cdnUrl={tenant.cdnUrl}
          files={documentWidgetFiles}
          actions={
            user.info.businessUser_AllowPolicyCorrection && (
              <div>
                <EnhancedButton
                  onClick={() => {
                    const confirmation: IConfirmation = {
                      open: true,
                      title: 'Confirmation',
                      message:
                        'Are you sure you want to regenerate the documents?',
                      callback: async () => {
                        if (entityId) {
                          await regenerateDocumentsMutation({
                            variables: {
                              entityId: entityId,
                            },
                          }).then((response) => {
                            if (response.data) {
                              toast.success(
                                <ToastSuccessMessage>
                                  Documents regenerated successfully
                                </ToastSuccessMessage>
                              );
                            } else if (response.errors) {
                              toast.error(
                                <ToastErrorMessage>
                                  {getError(response)}
                                </ToastErrorMessage>
                              );
                            }
                          });
                        }
                      },
                      submitButtonText: 'Yes',
                      cancelButtonText: 'No',
                    };
                    dispatch(OpenConfirmationAction(confirmation));
                  }}
                >
                  Regenerate Documents
                </EnhancedButton>
              </div>
            )
          }
        />
        <Separator className={classes.thickSeperator} />
        <DocumentWidget
          title="Additional Documents"
          cdnUrl={tenant.cdnUrl}
          files={additionalDocumentWidgetFiles}
          disabledAddDocumentAction={false}
          actions={
            <EnhancedButton
              type="button"
              style={{ display: 'inline' }}
              onClick={() => {
                setAddDocumentDrawerOpen(true);
              }}
              disabled={addDocumentDrawerOpen}
            >
              Add Document
            </EnhancedButton>
          }
          policyEntityId={params.id}
        />
      </>
    );

    tabs.tabs[3].widgets[0].children = (
      <>
        <ProductionPolicyPaymentTermsSchedule policyId={params.id} />
      </>
    );

    tabs.tabs[4].widgets[0].children = (
      <>
        <ProductionAmendmentListing
          policyId={params.id}
          allowEndorsement={user.info.businessUser_AllowEndorsement}
          allowPolicyCorrection={user.info.businessUser_AllowPolicyCorrection}
          transferredToAccounting={data.TransferredToAccounting}
        />
      </>
    );
    return tabs;
  }, [
    data,
    documentsData,
    user,
    tenant,
    entityId,
    regenerateDocumentsMutation,
  ]);

  return loading || !data ? (
    <Loader />
  ) : (
    <>
      <TabsLayout
        name="productionPolicyPage"
        layout={renderTabs}
        theme={MAIN_ONE_THEME}
        tabPanelClassName={classes.tabPanelClassName}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
        firstTabAsActiveTab={true}
        cdnUrl={tenant.cdnUrl}
        userInfo={user.info}
      />
      {addDocumentDrawerOpen && (
        <AddDocumentDrawer
          open={addDocumentDrawerOpen}
          onClose={() => {
            setAddDocumentDrawerOpen(false);
          }}
          onSuccess={() => {
            setAddDocumentDrawerOpen(false);
            loadDocuments();
          }}
          entityId={params.id}
        />
      )}
    </>
  );
};

export default ProductionPolicyPage;

import { EnhancedDisplayType } from '../../../../../components/enhanced-form/EnhancedDisplay';
import { IDynamicDiplayView } from '../../../../../components/widgets/dynamic-display';

export interface IPersonWidgetInfo {
  name: string;
  dateOfBirth: string;
  phoneType: string;
  phoneNumber: string;
  nationalityOne: string;
  nationalityOneCode: string;
  isTaxable: string;
  occupationClass: string;
  occupationClassCode: string;
  profession: string;
  address: string;
  fullName?: string;
  order?: number;
  percentage?: number;
  relation?: string;
  gender?: string;
  smoker?: boolean;
  passportNumber?: string;
  grLimitDays?: string;
  cardNumber?: string;
  continuity?: string;
  tpaReference1?: string;
  tpaReference2?: string;
  exclusions?: string;
  remarks?: string;
  limitOfCover?: string;
  building?: string;
  street?: string;
  city?: string;
  country?: string;
  countryCode?: string;
  zone?: string;
  addressDetails?: string;
  addressDetailsArabic?: string;
}

export const getPayerView = (
  data: IPersonWidgetInfo,
  owner?: IPersonWidgetInfo
) => {
  const dynamicView: IDynamicDiplayView = {
    name: 'Payer',
    sections: [],
  };

  if (data && data.name) {
    dynamicView.sections.push({
      name: 'Payer',
      title: 'Payer',
      showActionButtons: true,
      properties: {
        Payer: {
          multiline: false,
          value: data.name,
          name: 'Payer',
          title: 'Payer',
          tooltip: false,
          type: EnhancedDisplayType.Date,
        },
        DateOfBirth: {
          multiline: false,
          value: data.dateOfBirth,
          name: 'DateOfBirth',
          title: 'Date of Birth',
          tooltip: false,
          type: EnhancedDisplayType.Date,
        },
        PhoneType: {
          multiline: false,
          value: data.phoneType || '',
          name: 'PhoneType',
          title: 'Phone Type',
          tooltip: false,
        },
        PhoneNumber: {
          multiline: false,
          value: data.phoneNumber || '',
          name: 'PhoneNumber',
          title: 'Phone Number',
          tooltip: false,
          type: EnhancedDisplayType.IntPhoneNumber,
        },
        Nationality: {
          multiline: false,
          value: data.nationalityOne || '',
          name: 'Nationality',
          title: 'Nationality',
          tooltip: false,
        },
        IsTaxable: {
          multiline: false,
          value: data.isTaxable ? 'Yes' : 'No',
          name: 'IsTaxable',
          title: 'Taxable',
          tooltip: false,
        },
        Profession: {
          multiline: false,
          value: data.profession || '',
          name: 'Profession',
          title: 'Profession',
          tooltip: false,
        },
        Address: {
          multiline: false,
          value: data.address || '',
          name: 'Address',
          title: 'Payer Address',
          tooltip: false,
        },
      },
    });
  }

  if (owner && owner.name) {
    dynamicView.sections.push({
      name: 'Owner',
      title: 'Owner',
      showActionButtons: false,
      properties: {
        Owner: {
          multiline: false,
          value: owner.name,
          name: 'Owner',
          title: 'Owner',
          tooltip: false,
          type: EnhancedDisplayType.Date,
        },
        ODateOfBirth: {
          multiline: false,
          value: owner.dateOfBirth,
          name: 'ODateOfBirth',
          title: 'Date of Birth',
          tooltip: false,
          type: EnhancedDisplayType.Date,
        },
        OPhoneType: {
          multiline: false,
          value: owner.phoneType || '',
          name: 'OPhoneType',
          title: 'Phone Type',
          tooltip: false,
        },
        OPhoneNumber: {
          multiline: false,
          value: owner.phoneNumber || '',
          name: 'OPhoneNumber',
          title: 'Phone Number',
          tooltip: false,
          type: EnhancedDisplayType.IntPhoneNumber,
        },
        ONationality: {
          multiline: false,
          value: owner.nationalityOne || '',
          name: 'ONationality',
          title: 'Nationality',
          tooltip: false,
        },
        OIsTaxable: {
          multiline: false,
          value: owner.isTaxable ? 'Yes' : 'No',
          name: 'OIsTaxable',
          title: 'Taxable',
          tooltip: false,
        },
        OProfession: {
          multiline: false,
          value: owner.profession || '',
          name: 'OProfession',
          title: 'Profession',
          tooltip: false,
        },
        OAddress: {
          multiline: false,
          value: owner.address || '',
          name: 'OAddress',
          title: 'Owner Address',
          tooltip: false,
        },
      },
    });
  }
  return dynamicView;
};

export const getInsuredView = (
  data: IPersonWidgetInfo[],
  lineExternalCode: string
) => {
  const dynamicView: IDynamicDiplayView = {
    name: 'Insured',
    sections: data.map((item, index) => ({
      name: 'Insured',
      title: index == 0 ? 'Insured' : '',
      properties: {
        ['Insured' + index]: {
          multiline: false,
          value: item.name,
          name: 'Insured' + index,
          title: 'Insured',
          tooltip: false,
          type: EnhancedDisplayType.Date,
        },
        ['Relation' + index]: {
          multiline: false,
          value: item.relation || '',
          name: 'Relation' + index,
          title: 'Relation',
          tooltip: false,
          hidden: !['19', '48'].includes(lineExternalCode),
        },
        ['DateOfBirth' + index]: {
          multiline: false,
          value: item.dateOfBirth,
          name: 'DateOfBirth' + index,
          title: 'Date of Birth',
          tooltip: false,
          type: EnhancedDisplayType.Date,
        },
        ['PhoneType' + index]: {
          multiline: false,
          value: item.phoneType || '',
          name: 'PhoneType' + index,
          title: 'Phone Type',
          tooltip: false,
        },
        ['PhoneNumber' + index]: {
          multiline: false,
          value: item.phoneNumber || '',
          name: 'PhoneNumber' + index,
          title: 'Phone Number',
          tooltip: false,
          type: EnhancedDisplayType.IntPhoneNumber,
        },
        ['Gender' + index]: {
          multiline: false,
          value: item.gender || '',
          name: 'Gender' + index,
          title: 'Gender',
          tooltip: false,
        },
        ['Smoker' + index]: {
          multiline: false,
          value: item.smoker ? 'Yes' : 'No',
          name: 'Smoker' + index,
          title: 'Smoker',
          tooltip: false,
          hidden: lineExternalCode !== '19',
        },
        ['PassportNumber' + index]: {
          multiline: false,
          value: item.passportNumber || '',
          name: 'PassportNumber' + index,
          title: 'Passport Number',
          tooltip: false,
        },
        ['Nationality' + index]: {
          multiline: false,
          value: item.nationalityOne || '',
          name: 'Nationality' + index,
          title: 'Nationality',
          tooltip: false,
        },
        ['IsTaxable' + index]: {
          multiline: false,
          value: item.isTaxable ? 'Yes' : 'No',
          name: 'IsTaxable' + index,
          title: 'Taxable',
          tooltip: false,
        },
        ['OccupationClass' + index]: {
          multiline: false,
          value: item.occupationClass || '',
          name: 'OccupationClass' + index,
          title: 'Occupation Class',
          tooltip: false,
        },
        ['Profession' + index]: {
          multiline: false,
          value: item.profession || '',
          name: 'Profession' + index,
          title: 'Profession',
          tooltip: false,
        },
        ['Address' + index]: {
          multiline: false,
          value: item.address || '',
          name: 'Address' + index,
          title: 'Insured Address',
          tooltip: false,
          hidden: [
            '5',
            '9',
            '10',
            '13',
            '21',
            '35',
            '11',
            '15',
            '16',
            '25',
            '27',
            '29',
            '30',
            '31',
            '37',
            '38',
            '39',
            '42',
            '46',
          ].includes(lineExternalCode),
        },
        ['Order' + index]: {
          multiline: false,
          value: String(item.order || ''),
          name: 'Order' + index,
          title: 'Order',
          tooltip: false,
          hidden: data.length <= 1,
        },
        ['GRLimitDays' + index]: {
          multiline: false,
          value: item.grLimitDays || '',
          name: 'GRLimitDays' + index,
          title: 'GR Limit Days',
          tooltip: false,
          hidden: lineExternalCode !== '19',
        },
        ['CardNumber' + index]: {
          multiline: false,
          value: item.cardNumber || '',
          name: 'CardNumber' + index,
          title: 'Card Number',
          tooltip: false,
          hidden: !['19', '48'].includes(lineExternalCode),
        },
        ['Continuity' + index]: {
          multiline: false,
          value: item.continuity || '',
          name: 'Continuity' + index,
          title: 'Continuity',
          tooltip: false,
          hidden: lineExternalCode !== '19',
        },
        ['TPAReference1' + index]: {
          multiline: false,
          value: item.tpaReference1 || '',
          name: 'TPAReference1' + index,
          title: 'TPA Reference 1',
          tooltip: false,
          hidden: lineExternalCode !== '19',
        },
        ['TPAReference2' + index]: {
          multiline: false,
          value: item.tpaReference2 || '',
          name: 'TPAReference2' + index,
          title: 'TPA Reference 2',
          tooltip: false,
          hidden: lineExternalCode !== '19',
        },
        ['Exclusions' + index]: {
          multiline: false,
          value: item.exclusions || '',
          name: 'Exclusions' + index,
          title: 'Exclusions',
          tooltip: false,
          hidden: !['19', '48'].includes(lineExternalCode),
        },
        ['Remarks' + index]: {
          multiline: true,
          value: item.remarks || '',
          name: 'Remarks' + index,
          title: 'Remarks',
          tooltip: false,
          hidden: !['19', '48'].includes(lineExternalCode),
        },
        ['LimitOfCover' + index]: {
          multiline: false,
          value: item.limitOfCover || '',
          name: 'LimitOfCover' + index,
          title: 'Limit of Cover',
          tooltip: false,
          hidden: ![
            '5',
            '9',
            '10',
            '13',
            '21',
            '35',
            '11',
            '15',
            '16',
            '25',
            '27',
            '29',
            '30',
            '31',
            '37',
            '38',
            '39',
            '42',
            '46',
          ].includes(lineExternalCode),
        },
      },
    })),
  };

  return dynamicView;
};

export const getBenefieryView = (data: IPersonWidgetInfo[]) => {
  const dynamicView: IDynamicDiplayView = {
    name: 'Beneficiary',
    sections: data.map((item, index) => ({
      name: 'Beneficiary',
      title: index == 0 ? 'Beneficiary' : '',
      properties: {
        ['Beneficiary' + index]: {
          multiline: false,
          value: item.name,
          name: 'Beneficiary' + index,
          title: 'Beneficiary',
          tooltip: false,
          type: EnhancedDisplayType.Date,
        },
        ['DateOfBirth' + index]: {
          multiline: false,
          value: item.dateOfBirth,
          name: 'DateOfBirth' + index,
          title: 'Date of Birth',
          tooltip: false,
          type: EnhancedDisplayType.Date,
        },
        ['PhoneType' + index]: {
          multiline: false,
          value: item.phoneType || '',
          name: 'PhoneType' + index,
          title: 'Phone Type',
          tooltip: false,
        },
        ['PhoneNumber' + index]: {
          multiline: false,
          value: item.phoneNumber || '',
          name: 'PhoneNumber' + index,
          title: 'Phone Number',
          tooltip: false,
          type: EnhancedDisplayType.IntPhoneNumber,
        },
        ['Nationality' + index]: {
          multiline: false,
          value: item.nationalityOne || '',
          name: 'Nationality' + index,
          title: 'Nationality',
          tooltip: false,
        },
        ['IsTaxable' + index]: {
          multiline: false,
          value: item.isTaxable ? 'Yes' : 'No',
          name: 'IsTaxable' + index,
          title: 'Taxable',
          tooltip: false,
        },
        ['Profession' + index]: {
          multiline: false,
          value: item.profession || '',
          name: 'Profession' + index,
          title: 'Profession',
          tooltip: false,
        },
        ['Address' + index]: {
          multiline: false,
          value: item.address || '',
          name: 'Address' + index,
          title: 'Beneficiary Address',
          tooltip: false,
        },
        ['Percentage' + index]: {
          multiline: false,
          value: item.percentage?.toString() || '',
          name: 'Percentage' + index,
          title: 'Percentage',
          tooltip: false,
          type: EnhancedDisplayType.RoundedPercentage,
        },
      },
    })),
  };

  return dynamicView;
};

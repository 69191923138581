import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  EnhancedHeaderInputTypes,
} from '../../../../../../components/enhanced-table';

export const PVPageHeaders: Record<string, EnhancedTableHeader> = {
  paymentDate: {
    name: 'paymentDate',
    title: 'Payment Date',
    type: EnhancedTableHeaderType.Clickable,
    callback: null,
  },
  paymentMethod: {
    name: 'paymentMethod',
    title: 'Payment Method',
    type: EnhancedTableHeaderType.Text,
  },
  referenceNumber: {
    name: 'referenceNumber',
    title: 'Reference Number',
    type: EnhancedTableHeaderType.Text,
  },
  amountPaid: {
    name: 'amountPaid',
    title: 'Amount Paid',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  createdBy: {
    name: 'createdBy',
    title: 'Created By',
    type: EnhancedTableHeaderType.Text,
  },
  createdOn: {
    name: 'createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
};

export const PVPopupHeaders: (
  currency: string,
  currencyList: Record<string, string>,
  disabled: boolean,
  isReinsurer: boolean
) => Record<string, EnhancedTableHeader> = (
  currency,
  currencyList,
  disabled,
  isReinsurer
) => {
  const currencySymbol = currencyList[currency];

  return {
    ...(isReinsurer && {
      facultativeID: {
        name: 'facultativeID',
        title: 'Facultative ID',
        type: EnhancedTableHeaderType.Text,
      },
    }),
    policyNum: {
      name: 'policyNum',
      title: 'Policy #',
      type: EnhancedTableHeaderType.Text,
    },
    billType: {
      name: 'billType',
      title: 'Bill Type',
      type: EnhancedTableHeaderType.Text,
    },
    billNum: {
      name: 'billNum',
      title: 'Bill #',
      type: EnhancedTableHeaderType.Text,
    },
    dueDate: {
      name: 'dueDate',
      title: 'Due Date',
      type: EnhancedTableHeaderType.Date,
    },
    amountDue: {
      name: 'amountDue',
      title: 'Amount Due',
      type: EnhancedTableHeaderType.RoundedCurrency,
    },
    amountOutstanding: {
      name: 'amountOutstanding',
      title: 'Amount Outstanding',
      type: EnhancedTableHeaderType.RoundedCurrency,
    },
    amountOutstandingCurrency: {
      name: 'amountOutstandingCurrency',
      title: currencySymbol
        ? `Amount Outstanding - ${currencySymbol}`
        : 'Amount Outstanding',
      type: EnhancedTableHeaderType.RoundedCurrency,
      currency: currencySymbol,
    },
    amount: {
      name: 'amount',
      title: 'Amount Paid',
      type: EnhancedTableHeaderType.Input,
      inputType: EnhancedHeaderInputTypes.RoundedCurrency,
      currency: currencySymbol,
      disabled: disabled,
      allowNegative: true,
    },
  };
};

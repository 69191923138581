import React, { useEffect, useState } from 'react';
import { DEFAULT_ERROR_TEXT } from '../../../../../constants';
import { IListingData } from '../../../../../models/listing';
import { headers } from './content';
import { useLazyQuery } from '@apollo/client';
import { businessPartnerQuery, getPolicyBills } from '../queries';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import Loader from '../../../../../components/Loader';
import { mapToListingData } from './utils';
import PaymentTermsPopup from '../../../../../forms/payment-terms/PaymentTermsPopup';
import { IAbstractRecord } from '../../../../../models';
import { getPolicyDetailsQuery } from '../../../../../forms/payment-terms/queries';
import ListingTable from '../../../../../components/form-fields/table/ListingTable';
import {
  ActionTarget,
  IEnhancedMenuItem,
} from '../../../../../components/form-fields/table';
import { enumListAsRecordObject } from '../../../../../utils/graph-utils';
import { IEnhanceTableHeaderClickable } from '../../../../../components/enhanced-table';
import ViewBreakdownShell from './ViewBreakdownShell';
import PrecisionService from '../../../../../services/precisionService';

interface IPolicyPaymentTermsSchedule {
  policyId: string;
}

const ProductionPolicyPaymentTermsSchedule: React.FC<
  IPolicyPaymentTermsSchedule
> = ({ policyId }) => {
  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showTermsPopup, setShowTermsPopup] = useState<{
    open: boolean;
    type?: 'changePaymentTerms' | 'viewBreakdown';
    amendmentId?: string;
    amendmentEffectiveDate?: string;
    amendmentNumber?: string;
    policyNumber?: string;
    billType?: string;
    billId?: string;
    data?: IAbstractRecord;
  }>({
    open: false,
  });
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 5,
    pageNumber: 0,
    totalCount: 0,
  });

  const tableAction: IEnhancedMenuItem[] = [
    {
      name: 'changepaymentterms',
      title: 'Change Payment Terms',
      target: ActionTarget.Entity,
      displayActionPerRecord: true,
      onClick: (payload: string[]) => {
        const currentBill = tableData.pagedItems[payload[0]]; // since only 1 record is selected

        setShowTermsPopup((oldState) => ({
          ...oldState,
          type: 'changePaymentTerms',
          open: true,
          billId: currentBill.id,
          billType: currentBill.billType?.toLowerCase(),
          amendmentNumber: currentBill.linkedAmendment,
          amendmentEffectiveDate: currentBill.linkedAmendmentEffectiveDate,
        }));
      },
    },
  ];

  const [getPolicyBillsLazy] = useLazyQuery(getPolicyBills);
  const [getBusinessPartnerDetailsLazy] = useLazyQuery(businessPartnerQuery);
  const [getPolicyDetailsLazy] = useLazyQuery(getPolicyDetailsQuery);

  const loadData = async () => {
    setLoading(true);

    try {
      const { data, error } = await getPolicyBillsLazy({
        variables: {
          policyId: policyId,
        },
      });

      if (error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const categories = enumListAsRecordObject(
        data?.Accounting_ParentBillCategoryList?.enumValues
      );

      const newTableData = mapToListingData(
        data?.Accounting?.queries?.GetParentBillDetails,
        categories
      );
      setTableData({ ...newTableData });
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }

    try {
      const policyDetailsResult = await getPolicyDetailsLazy({
        variables: {
          policyId,
        },
      });

      if (policyDetailsResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      setShowTermsPopup({
        open: false,
        policyNumber:
          policyDetailsResult.data?.Production?.entities?.policy?.views
            ?.Production_all?.properties?.PolicyNumber,
        data: policyDetailsResult.data,
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const initialize = async () => {
    await loadData();
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleTooltipFetch(id: string): Promise<IAbstractRecord> {
    const popoverRows: IAbstractRecord = {};
    await getBusinessPartnerDetailsLazy({
      variables: {
        parentBillID: id,
      },
    }).then((response) => {
      response?.data?.Accounting?.queries?.GetBusinessPartnersCommissions?.forEach(
        (obj: IAbstractRecord) => {
          popoverRows[obj.businessPartner_Id] = {
            BusinessPartner: obj.businessPartner_FullName,
            Share: PrecisionService.multiplyBy100(
              Number(obj.policyBusinessPartner_CommissionPercentage)
            ),
            Commission: obj.policyBusinessPartner_PolicyCommissionAmount,
            Currency: obj.accounting_Bills_Currency?.Symbol,
          };
        }
      );
    });
    return popoverRows;
  }

  function viewBreakdownCallback(payload: IAbstractRecord) {
    setShowTermsPopup((oldState) => ({
      ...oldState,
      open: true,
      amendmentId: payload.columns.linkedAmendmentId,
      amendmentNumber: payload.columns.linkedAmendment,
      billType: payload.columns.billType?.toLowerCase(),
      billId: payload.columns.id,
      type: 'viewBreakdown',
    }));
  }

  (headers.viewBreakdown as IEnhanceTableHeaderClickable).callback =
    viewBreakdownCallback;

  if (!booted) {
    return <Loader />;
  }

  return (
    <>
      <ListingTable
        inlineTitle="Payment Terms Schedule"
        name="paymentTermsSchedule"
        data={tableData}
        headers={headers}
        loader={loading}
        actions={tableAction}
        inline
        orderByAscendingByDefault
      />
      {showTermsPopup.open &&
        (showTermsPopup.type === 'changePaymentTerms' ? (
          <PaymentTermsPopup
            generalData={{
              amendment: {
                id: showTermsPopup.amendmentId,
                number: showTermsPopup.amendmentNumber,
                effectiveDate: showTermsPopup.amendmentEffectiveDate,
              },
              bill: {
                id: showTermsPopup.billId,
                type: showTermsPopup.billType,
              },
              policy: {
                id: policyId,
                number: showTermsPopup.policyNumber,
              },
            }}
            data={showTermsPopup.data}
            currencySymbol={Object.values(tableData.pagedItems)[0]?.Currency}
            open={showTermsPopup.open}
            onClose={() => {
              setShowTermsPopup((oldState) => ({
                ...oldState,
                open: false,
              }));
              initialize();
            }}
          />
        ) : (
          <ViewBreakdownShell
            generalData={{
              amendment: {
                id: showTermsPopup.amendmentId,
                number: showTermsPopup.amendmentNumber,
                effectiveDate: showTermsPopup.amendmentEffectiveDate,
              },
              bill: {
                id: showTermsPopup.billId,
                type: showTermsPopup.billType,
              },
              policy: {
                id: policyId,
                number: showTermsPopup.policyNumber,
              },
            }}
            data={showTermsPopup.data}
            open={showTermsPopup.open}
            onClose={() => {
              setShowTermsPopup((oldState) => ({
                ...oldState,
                open: false,
              }));
              initialize();
            }}
          />
        ))}
    </>
  );
};

export default ProductionPolicyPaymentTermsSchedule;

import { makeStyles } from 'tss-react/mui';
import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { IEnhancedFormInputBaseProps } from '.';
import DateService from '../../services/dateService';

export interface IDatePickerFormFieldProps extends IEnhancedFormInputBaseProps {
  value: string;
  maxDate?: Date;
  minDate?: Date;
  material?: boolean;
  onDateChange: (date: Date | '') => void;
  onBlur?: () => void;
  canClearDate?: boolean;
  classes?: {
    labelWrapper?: string;
    inputContainer?: string;
  };
}

const useStyles = makeStyles()(() => ({
  inputProps: {
    fontFamily: contentFontFamilyRegular,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
    height: 35,
    width: '100%',
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    borderRadius: '5px',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    '&:disabled': {
      cursor: 'not-allowed',
    },
    '& .MuiInputBase-input': {
      fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
      fontFamily: contentFontFamilyRegular,
      lineHeight: '15px',
      height: 31,
      width: '100%',
      padding: '0 15px',
      margin: '0 auto',
      backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
      boxSizing: 'border-box',
      borderRadius: '5px',
      '&::placeholder': {
        fontStyle: 'italic',
        fontFamily: contentFontFamilyRegular,
        color: '#33333333',
        opacity: 1,
        fontSize: '14px',
      },
    },
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },
    '& .MuiButtonBase-root': {
      marginRight: '3px',
    },
  },
  inputError: {
    borderColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
    outlineColor: MAIN_ONE_THEME.palette.error.main,
  },
  disabledInput: {
    '& .Mui-disabled::before': {
      borderBottom: 0,
    },
    '& .Mui-disabled': {
      cursor: 'not-allowed',
    },
  },
  materialInput: {
    backgroundColor: 'unset',
    border: 'none',
    borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    color: MAIN_ONE_THEME.palette.primary2.main,
    margin: '0 auto',
    borderRadius: 0,
    padding: '10px 15px 4px 4px',
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
  },
  clearBtn: {
    cursor: 'pointer',
    height: '12px',
    width: '12px',
  },
}));

const DatePickerFormField: React.FC<IDatePickerFormFieldProps> = ({
  style,
  name,
  title,
  error,
  disabled,
  value,
  maxDate,
  minDate,
  material,
  placeholder,
  inputOnly,
  onDateChange,
  onBlur,
  canClearDate = true,
  classes = {},
}) => {
  const { classes: inputClasses } = useStyles();
  const acceptParse = useRef<boolean>(true);
  const chooseFromCalendar = useRef<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleDateChange = (date: Date | null) => {
    if ((date && acceptParse.current) || chooseFromCalendar.current) {
      if (minDate && date && date < minDate) {
        date = minDate;
      }
      onDateChange(date);
    } else {
      onDateChange('');
    }
  };

  const handleClearDate = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDateChange('');
  };

  const DatePickerComponent = (
    <DatePicker
      open={openDialog}
      onOpen={() => setOpenDialog(true)}
      onClose={() => setOpenDialog(false)}
      value={value || null}
      onChange={(e, value) => {
        if (!value && e) {
          chooseFromCalendar.current = true;
        } else if (value && DateService.isValidFormat(value)) {
          acceptParse.current = true;
        } else {
          acceptParse.current = false;
          chooseFromCalendar.current = false;
        }

        handleDateChange(e);
      }}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      inputFormat={DateService.DATE_PICKER_FORMAT}
      orientation="portrait"
      renderInput={(params) => (
        <TextField
          {...params}
          className={clsx(inputClasses.inputProps, classes.inputContainer, {
            [inputClasses.materialInput]: material,
            [inputClasses.inputError]: error,
            [inputClasses.disabledInput]: disabled,
          })}
          name={name}
          value={value}
          placeholder={placeholder}
          onKeyPressCapture={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              setOpenDialog(true);
            }
          }}
          disabled={disabled}
          error={!!error}
          onClickCapture={() => {
            if (!disabled) {
              setOpenDialog(true);
            }
          }}
          onBlur={onBlur}
          variant={material ? 'filled' : 'standard'}
          InputProps={{
            ...params.InputProps,
            startAdornment: params.InputProps.startAdornment,
            endAdornment: (
              <>
                {canClearDate && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear date"
                      onClick={handleClearDate}
                      edge="end"
                      size="small"
                      style={{ marginRight: material ? '30px' : '-17px' }}
                    >
                      <Clear className={inputClasses.clearBtn} />
                    </IconButton>
                  </InputAdornment>
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );

  if (inputOnly) {
    return DatePickerComponent;
  }

  return (
    <EnhancedInputsWrapper
      title={title}
      error={error}
      name={name}
      style={style}
    >
      {DatePickerComponent}
    </EnhancedInputsWrapper>
  );
};

export default DatePickerFormField;

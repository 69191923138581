import { FormInputTypes, IDynamicSection } from '../../DynamicForm';
import { IProductionDetailsSummary } from '../../modules/production/policy/page';

export const initialValues = {
  fullName: '',
  dateOfBirth: '',
  phoneType: 'MOBILE',
  phoneNumber: '',
  nationalityOne: '',
  isTaxable: true,
  occupationClass: '',
  profession: '',
  country: '',
  zone: '',
  city: '',
  street: '',
  building: '',
  addressDetails: '',
  addressDetailsArabic: '',
};

export const getSections = (
  data: IProductionDetailsSummary,
  lists: Record<string, Record<string, string>>,
  section: string
): Record<string, IDynamicSection> => {
  const isPayer = section === 'payer';
  return {
    section1: {
      title: 'General Details',
      inputs: {
        fullName: {
          name: 'fullName',
          title: 'Full Name',
          placeholder: 'Full Name',
          type: FormInputTypes.text,
          value: isPayer
            ? data?.Persons?.payer?.fullName
            : data?.Persons?.owner?.fullName,
          required: true,
          minCharacters: 2,
          maxCharacters: 300,
          disabled: true,
        },
        dateOfBirth: {
          name: 'dateOfBirth',
          title: 'Date of Birth',
          placeholder: 'Date of Birth',
          type: FormInputTypes.date,
          value: isPayer
            ? data?.Persons?.payer?.dateOfBirth
            : data?.Persons?.owner?.dateOfBirth,
          maxDate: new Date(),
          required: false,
          disabled: true,
        },
        phoneType: {
          name: 'phoneType',
          title: 'Phone Type',
          type: FormInputTypes.chips,
          placeholder: 'Phone Type',
          value: isPayer
            ? data?.Persons?.payer?.phoneType
            : data?.Persons?.owner?.phoneType,
          multiple: false,
          selectOptions: lists.phoneType,
        },
        phoneNumber: {
          name: 'phoneNumber',
          title: 'Phone Number',
          type: FormInputTypes.phoneNumber,
          placeholder: 'Phone Number',
          value: isPayer
            ? data?.Persons?.payer?.phoneNumber
            : data?.Persons?.owner?.phoneNumber,
          required: false,
          editCountryCode: false,
        },
        nationalityOne: {
          name: 'nationalityOne',
          title: 'Nationality',
          placeholder: 'Nationality',
          type: FormInputTypes.chips,
          value: isPayer
            ? data?.Persons?.payer?.nationalityOneCode
            : data?.Persons?.owner?.nationalityOneCode,
          multiple: false,
          selectOptions: lists.nationalities,
          required: false,
        },
      },
    },
    section2: {
      title: 'Business Details',
      inputs: {
        isTaxable: {
          name: 'isTaxable',
          title: 'Taxable',
          placeholder: 'Taxable',
          type: FormInputTypes.switch,
          value: isPayer
            ? data?.Persons?.payer?.isTaxable
            : data?.Persons?.owner?.isTaxable,
          required: false,
          disabled: true,
        },
        occupationClass: {
          name: 'occupationClass',
          title: 'Occupation Class',
          placeholder: 'Occupation Class',
          type: FormInputTypes.chips,
          value: isPayer
            ? data?.Persons?.payer?.occupationClassCode
            : data?.Persons?.owner?.occupationClassCode,
          multiple: false,
          selectOptions: lists.occupationClasses,
        },
        profession: {
          name: 'profession',
          title: 'Profession',
          placeholder: 'Profession',
          type: FormInputTypes.text,
          value: isPayer
            ? data?.Persons?.payer?.profession
            : data?.Persons?.owner?.profession,
          minCharacters: 2,
          maxCharacters: 100,
        },
      },
    },
    section3: {
      title: 'Address Details',
      inputs: {
        country: {
          name: 'country',
          title: 'Country',
          placeholder: 'Country',
          type: FormInputTypes.chips,
          value: isPayer
            ? data?.Persons?.payer?.countryCode
            : data?.Persons?.owner?.countryCode,
          multiple: false,
          selectOptions: lists.countries,
          required: true,
        },
        zone: {
          name: 'zone',
          title: 'Zone',
          placeholder: 'Zone',
          type: FormInputTypes.chips,
          value: isPayer
            ? data?.Persons?.payer?.zone
            : data?.Persons?.owner?.zone,
          multiple: false,
          selectOptions: lists.zone,
        },
        city: {
          name: 'city',
          title: 'City',
          placeholder: 'City',
          type: FormInputTypes.text,
          value: isPayer
            ? data?.Persons?.payer?.city
            : data?.Persons?.owner?.city,
          minCharacters: 2,
          maxCharacters: 100,
          required: true,
        },
        street: {
          name: 'street',
          title: 'Street',
          placeholder: 'Street',
          type: FormInputTypes.text,
          value: isPayer
            ? data?.Persons?.payer?.street
            : data?.Persons?.owner?.street,
          minCharacters: 2,
          maxCharacters: 100,
        },
        building: {
          name: 'building',
          title: 'Building',
          placeholder: 'Building',
          type: FormInputTypes.text,
          value: isPayer
            ? data?.Persons?.payer?.building
            : data?.Persons?.owner?.building,
          minCharacters: 2,
          maxCharacters: 100,
        },
        addressDetails: {
          name: 'addressDetails',
          title: 'Address Details',
          placeholder: 'Address Details',
          type: FormInputTypes.text,
          value: isPayer
            ? data?.Persons?.payer?.addressDetails
            : data?.Persons?.owner?.addressDetails,
          minCharacters: 2,
          maxCharacters: 300,
        },
        addressDetailsArabic: {
          name: 'addressDetailsArabic',
          title: 'Address Details Arabic',
          placeholder: 'Address Details Arabic',
          type: FormInputTypes.text,
          value: isPayer
            ? data?.Persons?.payer?.addressDetailsArabic
            : data?.Persons?.owner?.addressDetailsArabic,
          minCharacters: 2,
          maxCharacters: 300,
        },
      },
    },
  };
};

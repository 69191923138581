import React, { useMemo, useState } from 'react';
import { IDynamicDiplayView } from '../../../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../../../components/widgets/dynamic-display/DynamicWidget';
import { IProductionDetailsSummary } from '..';
import EnhancedButton from '../../../../../components/form-fields/buttons/EnhancedButton';
import DescriptionOfRiskDrawer from '../../../../../forms/description-of-risk-drawer/DescriptionOfRiskDrawer';

interface IDescriptionOfRiskWidgetProps {
  data: IProductionDetailsSummary;
  lovs: Record<string, Record<string, string>>;
  allowPolicyCorrection: boolean;
}

const ProductionDescriptionOfRiskDetailsWidget: React.FC<
  IDescriptionOfRiskWidgetProps
> = ({ data, lovs, allowPolicyCorrection }) => {
  const [descriptionOfRiskInfoDrawerOpen, setDescriptionOfRiskInfoDrawerOpen] =
    useState<boolean>(false);

  const dynamicView = useMemo(
    (): IDynamicDiplayView => ({
      name: 'DescriptionOfRiskDetails',
      sections: [
        {
          name: 'RiskDetails',
          title: 'Risk Details',
          properties: {
            descriptionOfRisk: {
              multiline: true,
              value: data?.DescriptionOfRisk || '',
              name: 'descriptionOfRisk',
              title: 'Description of Risk',
            },
            geoLocation: {
              multiline: false,
              value: data?.GeoLocation?.Title || '',
              name: 'geoLocation',
              title: 'Geographical Location',
              hidden: ![
                '11',
                '15',
                '16',
                '25',
                '27',
                '29',
                '30',
                '31',
                '37',
                '38',
                '39',
                '42',
                '46',
              ].includes(data?.LineId?.ExternalCode),
            },
          },
        },
      ],
    }),
    [data]
  );

  return (
    <>
      <DynamicWidget
        hasFourFields={true}
        view={dynamicView}
        actions={
          allowPolicyCorrection && (
            <div>
              <EnhancedButton
                isPrimary
                onClick={() => {
                  setDescriptionOfRiskInfoDrawerOpen(true);
                }}
              >
                Modify
              </EnhancedButton>
            </div>
          )
        }
      />
      {descriptionOfRiskInfoDrawerOpen && (
        <DescriptionOfRiskDrawer
          open={descriptionOfRiskInfoDrawerOpen}
          onClose={() => setDescriptionOfRiskInfoDrawerOpen(false)}
          onSuccess={() => {}}
          data={data}
          lovs={lovs}
        />
      )}
    </>
  );
};

export default ProductionDescriptionOfRiskDetailsWidget;

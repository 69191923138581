export default class PrecisionService {
  /**
   * Multiplies a number by 100, rounding to the specified decimals.
   *
   * @param num - The original number
   * @param decimals - Number of decimal places to preserve after rounding
   * @returns The rounded result
   */
  public static multiplyBy100(num: number, decimals = 3): number {
    const factor = 10 ** decimals;
    return Math.round(num * 100 * factor) / factor;
  }

  /**
   * Divides a number by 100, rounding to the specified decimals.
   *
   * @param num - The original number
   * @param decimals - Number of decimal places to preserve after rounding
   * @returns The rounded result
   */
  public static divideBy100(num: number, decimals = 3): number {
    const factor = 10 ** decimals * 100;
    return Math.round((num / 100) * factor) / factor;
  }
}

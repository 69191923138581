import React, { useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import {
  contentFontFamilyRegular,
  MAIN_ONE_THEME,
  mainFontFamilyMedium,
} from '../../../constants';
import { IListingData } from '../../../models/listing';
import { useAppSelector } from '../../../redux/hooks';
import {
  TableSortOrder,
  rowSort,
  getSorting,
} from '../../../utils/table-utils';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../enhanced-table';
import EnhancedTableBody from '../../enhanced-table/EnhancedTableBody';
import EnhancedTableHead from '../../enhanced-table/EnhancedTableHead';
import { makeStyles } from 'tss-react/mui';
import CollapsibleTableToolbar from './CollapsibleTableToolbar';
import { Toolbar } from '@mui/material';
import DateService from '../../../services/dateService';
import { ITableRowSettings } from '../table';

export interface IListingCollapsibleTableProps {
  name: string;
  title: string;
  headers: Record<string, EnhancedTableHeader>;
  rowSettings?: ITableRowSettings;
  data: IListingData;
  defaultOrderByColumn?: string;
  orderByAscendingByDefault?: boolean;
  secondOrderByColumn?: string;
  secondOrderByAscendingByDefault?: boolean;
  disableSelection?: boolean;
  loader?: boolean;
  onSelectionChange?: (selectedRows: string[]) => void;
  pageSizeOptions?: number[];
}

const useStyles = makeStyles<{ cdnUrl: string }>()((theme, { cdnUrl }) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    margin: '0 auto',
    borderRadius: 0,
  },
  toolbarPaper: {
    margin: '0 0 1em',
  },
  separator: {
    height: '2 !important',
    margin: '0 !important',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  header: {
    margin: '0 0 1em',
    display: 'grid',
    gridTemplateAreas: `'title actions'`,
    gridGap: '1em',
  },
  title: {
    display: 'block',
    textAlign: 'left',
    fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
    fontFamily: mainFontFamilyMedium,
    letterSpacing: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontWeight: 'normal',
    margin: '0 0 17.5px 0',
    gridArea: 'title',
    alignSelf: 'center',
    // textTransform: 'uppercase',
    width: '450px',
  },
  actionsContainer: {
    margin: '0 3px 0 auto',
  },
  action: {
    '& span': {
      margin: '0.5em auto 0',
    },
  },
  button: {
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px!important`,
  },
  heightAndCenter: {
    height: '500px',
    justifyContent: 'center',
  },
  searchbar: {
    color: MAIN_ONE_THEME.palette.primary2.main,
    height: '23px',
    minWidth: '150px',
    width: '50%',
    border: 'unset',
    outline: 'unset',
    backgroundColor: 'transparent',
    fontFamily: contentFontFamilyRegular,
    '&:focus': {
      '-webkit-input-placeholder': { color: 'transparent' },
    },
    marginRight: 'calc(2em + 10px)',
  },
  filterContainer: {
    display: 'flex',
    paddingLeft: '40px',
    background: `url(${cdnUrl}/icons/filter-thick-primary-new.svg)`,
    backgroundSize: '16px 16px',
    backgroundPosition: 'top 4px left 0',
    backgroundRepeat: 'no-repeat',
    boxSizing: 'border-box',
  },
  toolbarRoot: {
    padding: '1px 16px',
    minHeight: '45px!important',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}));

const defaultpageSizeOptions = [5, 10, 25];

const ListingCollapsibleTable: React.FC<IListingCollapsibleTableProps> = ({
  name,
  title,
  headers,
  rowSettings,
  data,
  defaultOrderByColumn,
  orderByAscendingByDefault,
  secondOrderByColumn,
  secondOrderByAscendingByDefault,
  disableSelection,
  loader,
  onSelectionChange = undefined,
  pageSizeOptions = defaultpageSizeOptions,
}) => {
  const cdnUrl = useAppSelector((state) => state.tenant.cdnUrl);
  const { classes } = useStyles({ cdnUrl });
  let filterDelay: NodeJS.Timeout;

  const [searchValue, setSearchValue] = useState<string>('');
  const [order, setOrder] = useState<TableSortOrder>();
  const [orderBy, setOrderBy] = useState<string>();
  const [secondOrder, setSecondOrder] = useState<TableSortOrder>();
  const [page, setPage] = useState(
    data.pageNumber > 0 ? data.pageNumber - 1 : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState<number>(data.pageSize);
  const [selected, setSelected] = useState<string[]>([]);
  const [filtered, setFiltered] = useState(data.pagedItems);
  const [filterValue, setFilterValue] = useState('');
  const currentName = useRef<string>();

  useEffect(() => {
    if (onSelectionChange != undefined) {
      onSelectionChange(selected);
    }
  }, [onSelectionChange, selected]);

  // Watches data.pagedItems, headers and fiterValue, and recalculates when one changes
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    filterDelay = setTimeout(() => {
      if (headers) {
        const outKeys: string[] = Object.keys(data.pagedItems).filter(
          (itemKey) => {
            const curItem = data.pagedItems[itemKey];
            return Object.values(headers).some(
              (header) =>
                (header.type != EnhancedTableHeaderType.Date &&
                  curItem[header.name] &&
                  typeof curItem[header.name] === 'string' &&
                  curItem[header.name]
                    .toLowerCase()
                    .includes(filterValue.toLowerCase())) ||
                (header.type == EnhancedTableHeaderType.Date &&
                  curItem[header.name] &&
                  typeof curItem[header.name] === 'string' &&
                  DateService.formatDate(curItem[header.name]).includes(
                    filterValue
                  ))
            );
          }
        );
        const output: typeof data.pagedItems = {};
        outKeys.forEach((key) => {
          output[key] = data.pagedItems[key];
        });
        setSelected(selected.filter((key) => outKeys.includes(key)));
        setFiltered(output);
        setPage(0);
      } else {
        setFiltered(data.pagedItems);
      }
    }, 250);
    return () => {
      clearTimeout(filterDelay);
    };
  }, [data.pagedItems, filterValue, headers]);

  useEffect(() => {
    if (currentName.current !== name && defaultOrderByColumn) {
      currentName.current = name;
      setOrder(orderByAscendingByDefault ? 'asc' : 'desc');
      setOrderBy(defaultOrderByColumn);
      if (secondOrderByColumn) {
        setSecondOrder(secondOrderByAscendingByDefault ? 'asc' : 'desc');
      }
    }
  }, [
    defaultOrderByColumn,
    name,
    orderByAscendingByDefault,
    secondOrderByAscendingByDefault,
    secondOrderByColumn,
  ]);

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: string
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(rowName: string, checked: boolean) {
    if (checked) {
      setSelected(Object.keys(filtered));
      return;
    }
    setSelected([]);
  }

  function handleClick(rowName: string) {
    const selectedIndex = selected.indexOf(rowName);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(Number(+event.target.value));
    setPage(0);
  }

  const handleChange = (search: string) => {
    setFilterValue(search);
  };

  const isSelected = (key: string) => selected.indexOf(key) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, Object.keys(filtered).length - page * rowsPerPage);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    handleChange(event.target.value ? event.target.value.trim() : '');
  };

  const renderFilterInput = () => (
    <div className={classes.filterContainer}>
      <input
        type="text"
        className={classes.searchbar}
        placeholder={'Search'}
        onChange={handleSearchChange}
        onFocus={(e) => {
          e.target.placeholder = '';
        }}
        onBlur={(e) => {
          e.target.placeholder = 'Search';
        }}
        value={searchValue}
      />
    </div>
  );

  return (
    <div className={classes.root}>
      <CollapsibleTableToolbar title={title}>
        <>
          <Paper className={classes.paper}>
            <Toolbar
              classes={{
                root: classes.toolbarRoot,
              }}
            >
              {renderFilterInput()}
            </Toolbar>
          </Paper>
          <Paper className={classes.paper}>
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={Object.keys(filtered).length}
                  headRows={headers}
                  disableSelection={disableSelection}
                />
                <EnhancedTableBody
                  emptyRows={emptyRows}
                  handleClick={handleClick}
                  headers={headers}
                  rowSettings={rowSettings}
                  isSelected={isSelected}
                  rows={rowSort(
                    filtered,
                    getSorting(
                      order,
                      orderBy,
                      headers,
                      secondOrder,
                      secondOrderByColumn
                    )
                  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                  disableSelection={disableSelection}
                  loader={loader}
                />
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={pageSizeOptions || defaultpageSizeOptions}
              component="div"
              count={Object.keys(filtered).length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage=""
            />
          </Paper>
        </>
      </CollapsibleTableToolbar>
    </div>
  );
};
export default ListingCollapsibleTable;

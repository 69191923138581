import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { EnhancedDisplayType } from '../../../components/enhanced-form/EnhancedDisplay';
import { IEntityInfoView } from '../../../components/widgets/entity-info/EntityInfoFields';
import EntityInfoWidget from '../../../components/widgets/entity-info/EntityInfoWidget';

export const useStyles = makeStyles()(() => ({
  paperClass: {
    boxShadow: 'none !important',
  },
  updateBtn: {
    margin: '15px auto 0',
  },
}));

interface IProviderEntityInfoWidgetProps {
  data: Record<string, any>;
}

const ProviderEntityInfoWidget: React.FC<IProviderEntityInfoWidgetProps> = ({
  data,
}) => {
  const entityView = useMemo(() => {
    const result: IEntityInfoView = {
      name: 'section1',
      title: 'Provider Details',
      iconUrl: '',
      sections: [
        {
          name: 'providerDetailsSection1',
          title: '',
          properties: {
            name: {
              multiline: false,
              name: 'name',
              title: 'Full Name',
              value: data.name,
              type: EnhancedDisplayType.RelativeLink,
              tooltip: false,
              url: '/salesforce/persons/' + data.personId,
            },
            providerCode: {
              value: data.providerCode,
              multiline: false,
              name: 'providerCode',
              title: 'Provider Code',
            },
            dateOfBirth: {
              value: data.dateOfBirth,
              multiline: false,
              name: 'dateOfBirth',
              title: 'Date Of Birth',
              type: EnhancedDisplayType.Date,
            },
            email: {
              value: data.email,
              multiline: false,
              name: 'email',
              title: 'Email',
            },
            phoneType: {
              value: data.phoneType,
              multiline: false,
              name: 'phoneType',
              title: 'Phone Type',
            },
            phoneNumber: {
              value: data.phoneNumber,
              multiline: false,
              name: 'phoneNumber',
              title: 'Phone Number',
            },
            taxable: {
              value: data.taxable,
              multiline: false,
              name: 'taxable',
              title: 'Taxable',
            },
          },
        },
        {
          name: 'providerDetailsSection2',
          title: '',
          properties: {
            createdBy: {
              value: data.createdBy,
              multiline: false,
              name: 'createdBy',
              title: 'Created By',
            },
            createdOn: {
              value: data.createdOn,
              multiline: false,
              type: EnhancedDisplayType.DateTime,
              name: 'createdOn',
              title: 'Created On',
            },
            modifiedBy: {
              value: data.modifiedBy,
              multiline: false,
              name: 'modifiedBy',
              title: 'Modified By',
            },
            modifiedOn: {
              value: data.modifiedOn,
              multiline: false,
              type: EnhancedDisplayType.DateTime,
              name: 'modifiedOn',
              title: 'Modified On',
            },
          },
        },
      ],
      socialMedia: {
        facebook: '',
        linkedIn: '',
        twitter: '',
      },
    };

    return result;
  }, [data]);

  return (
    <EntityInfoWidget
      background="rgb(193, 0, 0)"
      classification={{ classifications: {}, value: '' }}
      progress={100}
      supportsClassification={true}
      supportsProgressBar={true}
      title={data.name}
      view={entityView}
    ></EntityInfoWidget>
  );
};

export default ProviderEntityInfoWidget;

import { AnyAction } from 'redux';

export interface ITenant {
  name: string;
  backgroundTemplate: string;
  apiUrl: string;
  cdnUrl: string;
  enrolleeApiUrl: string;
  signalRUrl: string;
  schedulerUrl: string;
  tenantId: string;
  header: IHeader;
  login: ILogin;
  theme: ITheme;
  astAccountName: string;
  aircall: boolean;
  currencySymbol: string;
  phoneNumberPattern: string;
  systemReferenceDate?: string;
  hasTrackingLink?: boolean;
}

export interface ITheme {
  palette: {
    primary1: IColor;
    primary2: IColor;
    primary3: IColor;
    primary4: IColor;
    primary5: IColor;
    secondary1: IColor;
    secondary2: IColor;
    secondary3: IColor;
    secondary4: IColor;
    secondary5: IColor;
    error: IColor;
  };
  typography: {
    small: {
      sm1: ITextSize;
      sm2: ITextSize;
      sm3: ITextSize;
    };
    regular: {
      reg1: ITextSize;
      reg2: ITextSize;
      reg3: ITextSize;
      reg4: ITextSize;
    };
    medium: {
      med1: ITextSize;
      med2: ITextSize;
    };
    big: {
      big1: ITextSize;
      big2: ITextSize;
    };
  };
}

interface IColor {
  main: string;
  light?: string;
  lighter?: string;
  text?: string;
}

interface ITextSize {
  fontSize: number;
}

export interface IHeader {
  appLauncherUrl: string;
  logoAlt: string;
  logoUrl: string;
}

export interface ILogin {
  background: string;
  logoUrl: string;
  logoHeight: string;
  logoWidth: string;
  btnBackgroundColor: string;
  btnFontColor: string;
}

export enum TenantActionTypes {
  LOAD_TENANT_SUCCESS = 'LOAD_TENANT_SUCCESS',
}

export interface LoadTenantSuccessAction extends AnyAction {
  type: typeof TenantActionTypes.LOAD_TENANT_SUCCESS;
  tenant: ITenant;
}

/*
 * Combines the action types that handle the tenant store
 */
export type TenantAction = LoadTenantSuccessAction;

import React, { useState } from 'react';
import {
  CertificateInput,
  ExcelRow,
  IAddCertificateDrawerProps,
  Production_CertificateDetailsOutputInput,
} from '.';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { isEmpty } from '../../utils/validationUtils';
import { inputs } from './content';
import { EnhancedButtonStatus } from '../../components/EnhancedButton';
import { toast } from 'react-toastify';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getError } from '../../utils/graph-utils';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { useAppSelector } from '../../redux/hooks';
import { useMutation } from '@apollo/client';
import { UPLOAD_CERTIFICATE_MUTATION } from './queries';
import PolicyCertficateFleetSummaryDialogShell from '../../modules/production/policy/page/certificates-tab/fleet-certificate-table/PolicyCertficateFleetSummaryDialogShell';
import { excelToJson } from '../../utils/excel-reader';
import DateService from '../../services/dateService';

const AddCertificateDrawer: React.FC<IAddCertificateDrawerProps> = ({
  id,
  open,
  lineExternalCode,
  onSuccess,
  onClose,
}) => {
  const tenant = useAppSelector((state) => state.tenant);

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [summaryData, setSummaryData] =
    useState<Production_CertificateDetailsOutputInput>(null);

  const [uploadCertificates] = useMutation(UPLOAD_CERTIFICATE_MUTATION);

  const simulateDataUpload = async (file: File): Promise<void> => {
    setSubmitButtonState('loading');
    setFormDisabled(true);

    try {
      const excelData = await excelToJson(file);

      // Assuming data is in the first sheet
      const sheetNames = Object.keys(excelData);
      if (sheetNames.length === 0) {
        toast.error(<ToastErrorMessage>{'Invalid file'}</ToastErrorMessage>);
      }

      const firstSheetName = sheetNames[0];
      const rows = excelData[firstSheetName];
      const certificateInputs: CertificateInput[] = rows
        .map((excelRow: ExcelRow, index: number) => {
          // Remove trailing '*' from keys in the row
          const row = Object.fromEntries(
            Object.entries(excelRow).map(([key, value]) => [
              key.replace(/\*$/, '').trim(),
              value,
            ])
          );

          return {
            rowNumber: index + 1,
            plateCode: row['Plate Code'],
            plateNumber: row['Plate Number']?.toString(),
            vignetteCode: row['Vignette Code']?.toString() || null,
            vignette: row['Vignette'] || null,
            usageType: row['Usage'],
            bodyType: row['Body'],
            engineType: row['Engine Type'],
            make: row['Make'],
            model: row['Model'],
            color: row['Color'],
            yearOfMake: Number(row['Year of Make']),
            engine: row['Engine']?.toString(),
            weight: Number(row['Weight (in Tons)']),
            engineSize: Number(row['Engine Size (in cc)']),
            seatingCapacity: Number(row['Seating Capacity']),
            chassis: row['Chassis']?.toString(),
            horsePower: Number(row['Horse Power']),
            carValue: Number(row['Car Value']),
            agencyRepairYear: Number(row['Agency Repair Years']),
            depreciationYears: Number(row['Number of Depreciation Years']),
            effectiveDate: DateService.formatDateBackend(
              row['Effective Date'] as Date
            ),
            sublineExternalCode: row['Subline']?.toString(),
            planExternalCode: row['Plan']?.toString(),
          };
        })
        .filter((row) => !isEmpty(row.plateNumber));

      const { data, errors } = await uploadCertificates({
        variables: {
          entityId: id,
          certificateInput: certificateInputs,
        },
        errorPolicy: 'all',
      });

      if (isEmpty(errors)) {
        toast.success(
          <ToastSuccessMessage>
            {'Data successfully uploaded'}
          </ToastSuccessMessage>
        );

        const uploadResult =
          data?.production?.entities?.policy?.production
            ?.uploadFleetCertificateInputs;

        const summaryResult = {
          Items: uploadResult.Items || [],
        };

        setSummaryData(summaryResult);
        setSubmitButtonState('success');
      } else {
        setSubmitButtonState(undefined);
        const errorMessage = getError({ errors });
        toast.error(<ToastErrorMessage>{errorMessage}</ToastErrorMessage>);
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const submitForm = async (values: Record<string, File>) => {
    await simulateDataUpload(values.document);
  };

  const handleSummaryClose = () => {
    setSummaryData(null);
    onClose();
  };

  const handleSummarySuccess = () => {
    setSummaryData(null);
    onSuccess();
    onClose();
  };

  return (
    <>
      <GenericDrawer title={'Add Certificate'} onClose={onClose} isOpen={open}>
        <DynamicForm
          inputs={inputs(tenant, lineExternalCode)}
          onSubmit={submitForm}
          buttonText={'Submit'}
          submitButtonState={submitButtonState}
          disableForm={formDisabled}
          title="Upload your Certificates"
        />
      </GenericDrawer>

      {summaryData && (
        <PolicyCertficateFleetSummaryDialogShell
          data={summaryData}
          entityId={id}
          onClose={handleSummaryClose}
          onSuccess={handleSummarySuccess}
        />
      )}
    </>
  );
};

export default AddCertificateDrawer;

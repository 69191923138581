import React, { useEffect, useState } from 'react';
import { IProductionDetailsSummary } from '../../..';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  addCertificate,
  getActiveAssignedBPPlanList,
  getEligiblePlanCovers,
  getLovs,
} from './queries';
import { getInitialFormState, IFormState } from './form';
import _ from 'lodash';
import MarineGroupCertificateDetailsWidget from './widgets/MarineGroupCertificateDetailsWidget';
import MarineGroupPolicyCostChargesWidget from './widgets/MarineGroupPolicyCostChargesWidget';
import MarineGroupPolicyCoversRepeaterWidget from './widgets/MarineGroupPolicyCoversRepeaterWidget';
import MarineGroupPolicyDetailsWidget from './widgets/MarineGroupPolicyDetailsWidget';
import {
  extractCovers,
  extractPlans,
  extractSublines,
} from './widgets/functions';
import EnhancedButton, {
  EnhancedButtonStatus,
} from '../../../../../../../components/form-fields/buttons/EnhancedButton';
import { makeStyles } from 'tss-react/mui';
import { IAbstractRecord } from '../../../../../../../models';
import { validateProposalPageForm } from './validation';
import { mapCertificateInput } from './utils';
import {
  getError,
  lookupListAsRecordObject,
} from '../../../../../../../utils/graph-utils';
import { isEmpty } from '../../../../../../../utils/validationUtils';
import ToastErrorMessage from '../../../../../../../components/ToastErrorMessage';
import { toast } from 'react-toastify';

interface IPolicyCertficateMarineGroupSummaryDialogProps {
  data: IProductionDetailsSummary;
  onClose: () => void;
  onSuccess: (payload: {
    lovs: IAbstractRecord;
    data: IAbstractRecord;
  }) => void;
}

const useStyles = makeStyles()(() => ({
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '15px',
    paddingTop: '1em',
  },
}));

const PolicyCertficateMarineGroupSummaryDialog: React.FC<
  IPolicyCertficateMarineGroupSummaryDialogProps
> = ({ data, onClose = () => undefined, onSuccess }) => {
  const initialFormState = getInitialFormState(data);
  const coversIds = data.Covers.map((cover) => cover.policyCoverId);
  const { classes } = useStyles();

  const [lovs, setLovs] = useState<Record<string, IAbstractRecord>>({
    subline: {},
    plan: {},
    planCovers: {},
    coversList: {},
    matters: {},
    policyCosts: {},
  });
  const [pageState, setPageState] = useState<IFormState>(
    _.cloneDeep(initialFormState)
  );

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const loadSublineLov = async () => {
    const newData = await getActiveAssignedBPPlanListLazy({
      variables: {
        currentPage: 1,
        currentPageSize: 10,
        selectedBusinessUserID: data.BusinessUserID.Id,
        selectedBusinessPartnerID: data.PrimaryBPID.Id,
        selectedLineID: data.LineId.Id,
      },
      errorPolicy: 'all',
    });
    return extractSublines(newData.data);
  };

  const loadCoversLov = async (plan: string) => {
    const newData = await getEligiblePlanCoversLazy({
      variables: {
        planID: plan,
      },
      errorPolicy: 'all',
    });
    return extractCovers(newData.data, pageState.values);
  };

  const loadPlanLov = async (subline: string) => {
    const newData = await getActiveAssignedBPPlanListLazy({
      variables: {
        selectedBusinessUserID: data.BusinessUserID.Id,
        selectedBusinessPartnerID: data.PrimaryBPID.Id,
        selectedLineID: data.LineId.Id,
        selectedSublineID: subline,
      },
      errorPolicy: 'all',
    });
    return extractPlans(newData.data);
  };

  const [getActiveAssignedBPPlanListLazy, { loading: planLoading }] =
    useLazyQuery(getActiveAssignedBPPlanList());
  const [getLovsQuery] = useLazyQuery(getLovs());
  const [getEligiblePlanCoversLazy] = useLazyQuery(getEligiblePlanCovers());
  const [addCertificateAction] = useMutation(addCertificate());

  const initialize = async () => {
    const sublines = await loadSublineLov();
    const lookups = await getLovsQuery();
    const matters = lookupListAsRecordObject(
      lookups.data?.Core?.lookups?.matters
    );

    setLovs({
      subline: sublines,
      plan: {},
      planCovers: {},
      coversList: {},
      matters: matters,
      policyCosts: {},
    });
  };

  const onSublineSelect = async (newPageState: IFormState) => {
    setPageState(newPageState);
    const plans = await loadPlanLov(newPageState.values.policyDetails.subline);
    setLovs((oldLovs) => ({
      ...oldLovs,
      plan: plans.lines,
      policyCosts: plans.policyCosts,
    }));
  };

  const onPlanSelect = async (newPageState: IFormState) => {
    const { planCovers, coversList } = await loadCoversLov(
      newPageState.values.policyDetails.plan
    );
    const newCovers = Object.values(planCovers)
      .filter(
        (cover) =>
          cover.isMandatory ||
          cover.isMain ||
          coversIds.includes(cover.policyCover)
      )
      .map((cover) => ({
        ...cover,
        isInGroupPolicy: coversIds.includes(cover.policyCover),
      }));

    setLovs((oldLovs) => ({
      ...oldLovs,
      planCovers,
      coversList,
    }));

    setPageState({
      errors: {
        ...newPageState.errors,
        covers:
          newCovers?.length > 1
            ? newCovers?.map(() => initialFormState.errors.covers?.[0])
            : initialFormState.errors.covers,
      },
      touched: {
        ...newPageState.touched,
        covers:
          newCovers?.length > 1
            ? newCovers?.map(() => initialFormState.touched.covers?.[0])
            : initialFormState.touched.covers,
      },
      values: {
        ...newPageState.values,
        covers:
          newCovers?.length > 0 ? newCovers : initialFormState.values.covers,
        costs: lovs.policyCosts[newPageState.values.policyDetails.plan],
      },
    });
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = () => {
    setSubmitButtonState('loading');
    setSubmitting(true);
    const { errors, touched, isValid } = validateProposalPageForm(
      pageState.values,
      data
    );

    const newPageState = _.cloneDeep(pageState);
    newPageState.errors = errors;
    newPageState.touched = touched;

    setPageState(newPageState);

    if (isValid) {
      const payload = {
        entityId: data.Id,
        certificateInputs: mapCertificateInput(pageState.values, lovs),
      };
      addCertificateAction({
        variables: {
          entityId: payload.entityId,
          certificateInput: payload.certificateInputs,
        },
        errorPolicy: 'all',
      }).then((result) => {
        if (isEmpty(result.errors)) {
          const items =
            result.data.production.entities.policy.production
              .uploadMarineCertificate.Items?.[0];
          onSuccess({
            lovs: lovs,
            data: {
              ...payload,
              ...items,
            },
          });
          setSubmitButtonState('success');
          setSubmitting(false);
        } else {
          toast.error(
            <ToastErrorMessage>{getError(result.errors)}</ToastErrorMessage>
          );
          setSubmitButtonState(undefined);
          setSubmitting(false);
        }
      });
    } else {
      setSubmitButtonState(undefined);
      setSubmitting(false);
    }
  };

  return (
    <>
      <MarineGroupPolicyDetailsWidget
        pageState={pageState}
        onPageStateUpdate={(v) => {
          setPageState((oldValues) => {
            return { ...oldValues, ...v };
          });
        }}
        onSublineSelect={(v) => {
          onSublineSelect(v);
        }}
        onPlanSelect={(v) => {
          onPlanSelect(v);
        }}
        disabledForm={submitting}
        lovs={lovs}
        loader={planLoading}
      />

      <MarineGroupPolicyCoversRepeaterWidget
        pageState={pageState}
        onPageStateUpdate={(v) => {
          setPageState((oldValues) => {
            return { ...oldValues, ...v };
          });
        }}
        lovs={lovs}
        disabledForm={submitting}
        defaultPlanCoversList={lovs.planCovers}
        data={data}
      />

      <MarineGroupPolicyCostChargesWidget
        pageState={pageState}
        onPageStateUpdate={(v) => {
          setPageState((oldValues) => {
            return { ...oldValues, ...v };
          });
        }}
        status={''}
        editableLine={data.BusinessUserID?.EditableLineChargesIDs?.includes(
          data?.LineId.Id
        )}
        currencySymbol={data.PolicyCurrency.Symbol}
      />

      <MarineGroupCertificateDetailsWidget
        pageState={pageState}
        onPageStateUpdate={(v) => {
          setPageState((oldValues) => {
            return { ...oldValues, ...v };
          });
        }}
        lovs={lovs}
        currencySymbol={data.PolicyCurrency.Symbol}
      />

      <div className={classes.buttonsContainer}>
        <EnhancedButton disabled={submitting} onClick={() => onClose()}>
          Cancel
        </EnhancedButton>

        <EnhancedButton
          state={submitButtonState}
          disabled={submitting}
          isPrimary
          onClick={() => submit()}
        >
          Submit
        </EnhancedButton>
      </div>
    </>
  );
};

export default PolicyCertficateMarineGroupSummaryDialog;

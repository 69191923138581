import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { IAbstractRecord } from '../../models';
import { IProductionDetailsSummary } from '../../modules/production/policy/page';

export const initialValues = {
  medicalClass: '',
  continuity: '',
  continuityNumber: '',
  exclusion: '',
  remarks: '',
};

export const getInputs = (
  lovs: Record<string, IAbstractRecord> = {},
  values: IProductionDetailsSummary
): Record<string, DynamicFormInputType> => ({
  medicalClass: {
    name: 'medicalClass',
    title: 'Medical Class',
    type: FormInputTypes.chips,
    placeholder: 'Medical Class',
    value: values?.ExpatDetails?.MedicalClass?.Code,
    required: true,
    selectOptions: lovs?.medicalClasses || {},
    disabled: true,
  },
  continuity: {
    name: 'continuity',
    title: 'Continuity',
    type: FormInputTypes.chips,
    placeholder: 'Continuity',
    value: values?.ExpatDetails?.Continuity,
    required: false,
    selectOptions: { YES: 'Yes', NO: 'No' },
    hidden: false,
  },
  continuityNumber: {
    name: 'continuityNumber',
    title: 'Continuity Number',
    type: FormInputTypes.number,
    placeholder: 'Continuity Number',
    value: values?.ExpatDetails?.ContinuityNumber,
    required: false,
  },
  exclusion: {
    name: 'exclusion',
    title: 'Exclusion',
    type: FormInputTypes.text,
    multiline: true,
    placeholder: 'Exclusion',
    value: values?.ExpatDetails?.Exclusion,
    required: false,
  },
  remarks: {
    name: 'remarks',
    title: 'Remarks',
    type: FormInputTypes.text,
    multiline: true,
    placeholder: 'Remarks',
    value: values?.ExpatDetails?.Remarks,
    required: false,
  },
});

import { IAbstractRecord } from '../../models';

export enum EnhancedTableHeaderType {
  Badge = 'Badge',
  BadgeArray = 'BadgeArray',
  Clickable = 'Clickable',
  Date = 'Date',
  DateAndTime = 'DateAndTime',
  Link = 'Link',
  Text = 'Text',
  Currency = 'Currency',
  RoundedCurrency = 'RoundedCurrency',
  ColoredCurrency = 'ColoredCurrency',
  Percentage = 'Percentage',
  RoundedPercentage = 'RoundedPercentage',
  Classification = 'Classification',
  Rate = 'Rate',
  Input = 'Input',
  PhoneNumber = 'PhoneNumber',
  IntPhoneNumber = 'IntPhoneNumber',
  Image = 'Image',
  JSXElement = 'JSXElement',
}

export interface IEnhancedTableToolTipSettings {
  headers: Record<string, EnhancedTableHeader>;
  tooltipFetch?: (rowId: string) => Promise<IAbstractRecord>;
}

export interface IEnhancedTableHeaderBase {
  title: string;
  name: string;
  type: EnhancedTableHeaderType;
  tooltipSettings?: IEnhancedTableToolTipSettings;
  disabled?: boolean;
  hidden?: boolean;
}

export interface IEnhancedTableFilters {
  filters: Record<string, IEnhancedTableFilter>;
}

export interface IEnhancedTableFilter {
  type: 'daterange' | 'select';
  name: string;
  title: string;
}

export interface IEnhancedTableDateRangeFilter extends IEnhancedTableFilter {
  minDate: Date;
  maxDate: Date;
  value: Date[];
  type: 'daterange';
}

export interface IEnhancedTableSelectFilter extends IEnhancedTableFilter {
  selectedValues: string[];
  options: string[];
  type: 'select';
  splitCharacter?: string;
}

export type EnhancedTableFilterType =
  | IEnhancedTableDateRangeFilter
  | IEnhancedTableSelectFilter;

export interface IBadgeConfig {
  color: string;
  backgroundColor: string;
}

export interface IEnhanceTableHeaderText extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.Text;
}

export interface IEnhanceTableHeaderCurrency extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.Currency;
  currency?: string;
}

export interface IEnhanceTableHeaderRoundedCurrency
  extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.RoundedCurrency;
  currency?: string;
}

export interface IEnhanceTableHeaderColoredCurrency
  extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.ColoredCurrency;
  compareToKey?: string;
  currency?: string;
}

export interface IEnhanceTableHeaderPercentage
  extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.Percentage;
}

export interface IEnhanceTableHeaderRoundedPercentage
  extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.RoundedPercentage;
}

export interface IEnhanceTableHeaderRate extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.Rate;
}

export interface IEnhanceTableHeaderClassification
  extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.Classification;
  classificationPropertyName: string;
}

export interface IEnhanceTableHeaderDate extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.Date;
}

export interface IEnhanceTableHeaderDateAndTime
  extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.DateAndTime;
}

export interface IEnhanceTablePhoneNumber extends IEnhancedTableHeaderBase {
  type:
    | EnhancedTableHeaderType.PhoneNumber
    | EnhancedTableHeaderType.IntPhoneNumber;
}

export interface IEnhanceTableHeaderBadge extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.Badge;
  badgeConfig: Record<string, IBadgeConfig>;
}

export interface IEnhanceTableHeaderBadgeArray
  extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.BadgeArray;
  badgeConfig: Record<string, IBadgeConfig>;
}

export interface IEnhanceTableHeaderClickable extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.Clickable;
  icon?: JSX.Element;
  buttonLabel?: string;
  callback: (payload: any) => void;
}

export interface IEnhanceTableHeaderInput extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.Input;
  inputType: EnhancedHeaderInputTypes;
  currencyIcon?: string;
  currency?: string;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
  hasBetweenValidation?: boolean;
  error?: string;
  maxDecimalPercision?: number;
  options?: Record<string, string>;
  allowNegative?: boolean;
}

export interface IEnhanceTableHeaderLink extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.Link;
  urlTemplate: string;
  openUrlInNewTab: boolean;
}

export enum EnhancedHeaderInputTypes {
  Text = 'Text',
  Date = 'Date',
  Number = 'Number',
  Decimal = 'Decimal',
  RoundedCurrency = 'RoundedCurrency',
  Select = 'Select',
}

export interface IEnhancedTableMenuItem {
  title: string;
  onClick: (selected?: any[]) => void;
  isBulk?: boolean;
  isEntity?: boolean;
  name?: string;
  isSecondary?: boolean;
  disabled?: boolean;
  conditionalDisable?: (
    selected?: string[],
    selectedItems?: IAbstractRecord[]
  ) => boolean;
  returnAllData?: boolean;
  iconUrl?: string;
  hidden?: boolean;
}

export interface IEnhanceTableHeaderImage extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.Image;
  imageKey?: string;
  hasConditionalImage?: boolean;
  variableOneImageKey?: string;
  variableTwoImageKey?: string;
}

export interface IEnhanceTableHeaderJSXElement
  extends IEnhancedTableHeaderBase {
  type: EnhancedTableHeaderType.JSXElement;
}

export type EnhancedTableHeader =
  | IEnhanceTableHeaderBadge
  | IEnhanceTableHeaderClickable
  | IEnhanceTableHeaderDate
  | IEnhanceTableHeaderDateAndTime
  | IEnhanceTableHeaderLink
  | IEnhanceTableHeaderBadgeArray
  | IEnhanceTableHeaderText
  | IEnhanceTableHeaderColoredCurrency
  | IEnhanceTableHeaderCurrency
  | IEnhanceTableHeaderPercentage
  | IEnhanceTableHeaderRate
  | IEnhanceTableHeaderClassification
  | IEnhanceTableHeaderInput
  | IEnhanceTablePhoneNumber
  | IEnhanceTableHeaderImage
  | IEnhanceTableHeaderJSXElement
  | IEnhanceTableHeaderRoundedCurrency
  | IEnhanceTableHeaderRoundedPercentage;

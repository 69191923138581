import { gql } from '@apollo/client';

export const getBusinessPartnerInfo = gql`
  query getBusinessPartnerInfo($id: String!) {
    SalesforceManagement {
      entities {
        businessPartner(id: $id) {
          id
          views {
            SalesforceManagement_all {
              properties {
                Type {
                  Code
                }
                PersonID {
                  id
                  views {
                    SalesforceManagement_all {
                      properties {
                        FullName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getGeneralLovs = gql`
  query {
    Accounting_PaymentMethodList: __type(name: "Accounting_PaymentMethodList") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const getLovs = gql`
  query {
    Core {
      lookups {
        currencies {
          Code
          Symbol
        }
      }
    }
    PlanConfigManagement {
      queries {
        allLines {
          planConfigManagement_Line_Name
          planConfigManagement_Line_Id
        }
      }
    }
    Production_PaymentDivisionTypeList: __type(
      name: "Production_PaymentDivisionTypeList"
    ) {
      name
      enumValues {
        name
      }
    }
  }
`;

export const getPolicies = gql`
  query BrokerPoliciesList(
    $SelectedLine: [String]
    $SelectedCurrency: [String]
    $PolicyIssuanceFrom: Date
    $PolicyIssuanceTo: Date
    $BusinessPartnerID: String
  ) {
    Accounting {
      queries {
        BrokerPoliciesList(
          SelectedLine: $SelectedLine
          SelectedCurrency: $SelectedCurrency
          PolicyIssuanceFrom: $PolicyIssuanceFrom
          PolicyIssuanceTo: $PolicyIssuanceTo
          BusinessPartnerID: $BusinessPartnerID
        ) {
          policy_PolicyNumber
          policy_Id
          policy_PaymentDivisionType
          receivableBills_TotalPremium
          receivableBills_AmountReceived
          policy_TotalAmountReceived
          payableBills_TotalCommission
          payableBills_TaxOnCommission
          payableBills_AmountPaid
          payableBills_AmountReceived
          policy_PaymentMode {
            Title
          }
          receivableBills_Currency {
            Symbol
          }
        }
      }
    }
  }
`;

export const getBreakdown = gql`
  query GetPolicyReceivableBills(
    $PolicyID: String!
    $BusinessPartnerID: String!
    $pagination: PaginationInput!
  ) {
    Accounting {
      queries {
        GetPolicyReceivableBills(PolicyID: $PolicyID) {
          parentBill_ParentBillCategory
          accounting_Bills_Id
          accounting_Bills_BillNumber
          accounting_Bills_DueDate
          accounting_Bills_NetPremium
          accounting_Bills_BillType
          accounting_Bills_AmountDue
          accounting_Bills_TotalPremium
          accounting_Bills_AmountReceived
          accounting_Bills_AmountPaid
          accounting_Bills_OutstandingBalance
          accounting_Bills_BillStatus
          linkedAmendment_AmendmentNumber
          accounting_Bills_Currency {
            Symbol
          }
        }
        GetBusinessPartnerBillOfPolicy(
          PolicyID: $PolicyID
          BusinessPartnerID: $BusinessPartnerID
          pagination: $pagination
        ) {
          items {
            linkedAmendment_AmendmentNumber
            parentBill_ParentBillCategory
            accounting_Bills_BillNumber
            accounting_Bills_DueDate
            accounting_Bills_Discount
            accounting_Bills_TaxOnCommission
            accounting_Bills_TotalCommission
            accounting_Bills_OutstandingBalance
            accounting_Bills_AmountPaid
            accounting_Bills_BillStatus
            accounting_Bills_Id
            accounting_Bills_BillType
            accounting_Bills_Currency {
              Symbol
            }
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
  }
`;

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import { getInputs, initialValues } from './content';
import { modifyMotor, getModelsByBrand } from './queries';
import { IMotorDetailsProps } from '.';
import { IFormSelectDynamicProps } from '../../DynamicForm';
import { getError, lookupListAsRecordObject } from '../../utils/graph-utils';
import { mapDataToFormValues } from './utils';
import { isEmpty } from '../../utils/validationUtils';
import DataService from '../../services/dataService';
import { DEFAULT_ERROR_TEXT } from '../../constants';

const MotorDetailsDrawer: React.FC<IMotorDetailsProps> = ({
  open,
  onSuccess,
  onClose,
  data,
  lovs,
}) => {
  const params = useParams();

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [localLovs, setLocalLovs] = useState(lovs);
  const [formValues, setFormValues] = useState(() =>
    data ? mapDataToFormValues(data) : initialValues
  );
  const [loadingTransliteration, setLoadingTransliteration] = useState(false);

  const [fetchModelsByBrand, { loading: loadingModels }] = useLazyQuery(
    getModelsByBrand(),
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [saveMotorDetails] = useMutation(modifyMotor());

  const disabledForm = formDisabled || loadingModels || loadingTransliteration;

  useEffect(() => {
    if (data) {
      setFormValues(mapDataToFormValues(data));
    }
  }, [data]);

  const inputForm = useMemo(() => {
    const inputs = getInputs(lovs, data);
    if (localLovs?.model) {
      (inputs.model as IFormSelectDynamicProps).selectOptions = localLovs.model;
    }
    Object.keys(inputs).forEach((key) => {
      if (key === 'model' && localLovs?.model && formValues.model) {
        inputs[key].value =
          localLovs.model[formValues.model] || formValues.model;
      } else {
        inputs[key].value = (formValues as Record<string, string>)[key];
      }
    });
    return inputs;
  }, [lovs, data, localLovs, formValues]);

  useEffect(() => {
    const brand = data?.Vehicle?.BrandCode;
    if (brand) {
      loadModels(brand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const loadModels = useCallback(
    async (brandValue: string) => {
      if (!brandValue) {
        setLocalLovs((prevLovs) => ({ ...prevLovs, model: {} }));
        return;
      }
      try {
        const { data: response } = await fetchModelsByBrand({
          variables: { modelId: brandValue },
        });
        const modelArray = response?.Insurance?.lookups?.models || [];
        const modelRecord = lookupListAsRecordObject(modelArray);
        setLocalLovs((prevLovs) => ({
          ...prevLovs,
          model: modelRecord,
        }));
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    },
    [fetchModelsByBrand]
  );

  const transliterateNameOnLicense = async (enTxt: string) => {
    if (!enTxt) return;
    setLoadingTransliteration(true);
    try {
      const response = await DataService.get(
        `/api/prx/transliterate.qcri.org/en2ar/${enTxt}`
      );
      if (response.ok) {
        const result = await response.json();
        setFormValues((prev) => ({
          ...prev,
          nameOnLicenseAr: result.results,
        }));
      } else {
        setFormValues((prev) => ({
          ...prev,
          nameOnLicenseAr: enTxt,
        }));
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }
    } catch (error) {
      setFormValues((prev) => ({
        ...prev,
        nameOnLicenseAr: enTxt,
      }));
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoadingTransliteration(false);
    }
  };

  const submitForm = async (values: Record<string, string>) => {
    const [formData] = normaliseDynamicValues(getInputs(lovs, data), values);
    setFormDisabled(true);
    setSubmitButtonState('loading');
    try {
      const variables = {
        entityId: params.id,
        vehicleDetailInputs: {
          nameOnLicenseEn: formData.nameOnLicenseEn,
          nameOnLicenseAr: formData.nameOnLicenseAr,
          vignetteCode: formData.vignetteCode || null,
          vignette: Number(formData.vignette) || null,
          plateCode: formData.plateCode,
          plateNumber: formData.plateNumber,
          usageType: formData.usageType,
          bodyType: formData.bodyType,
          engineType: formData.engineType,
          make: formData.make,
          model: formData.model,
          yearOfMake: formData.yearOfMake || null,
          engine: formData.engine,
          weight: formData.weight || null,
          engineSize: formData.engineSize || null,
          seatingCapacity: formData.seatingCapacity || null,
          chassis: formData.chassis,
          horsePower: formData.horsePower || null,
          carValue: formData.carValue || null,
          color: formData.color,
        },
        depreciationYears: Number(formData.depreciationYears) || null,
        agencyRepairYear: Number(formData.agencyRepairYear) || null,
      };

      const { errors } = await saveMotorDetails({ variables });

      if (isEmpty(errors)) {
        toast.success(
          <ToastSuccessMessage>
            Motor details updated successfully!
          </ToastSuccessMessage>
        );

        setSubmitButtonState('success');
        onSuccess();
        onClose();
      } else {
        setSubmitButtonState(undefined);
        const errorMessage = getError({ errors });
        toast.error(<ToastErrorMessage>{errorMessage}</ToastErrorMessage>);
      }
    } catch (error) {
      const errorMessage = getError({ error });
      toast.error(<ToastErrorMessage>{errorMessage}</ToastErrorMessage>);
      setSubmitButtonState(undefined);
    } finally {
      setFormDisabled(false);
    }
  };

  const handleFormChange = (fieldName: string, fieldValue: any) => {
    if (fieldName === 'make') {
      loadModels(fieldValue);
      setFormValues((prev) => ({ ...prev, make: fieldValue, model: '' }));
    } else {
      setFormValues((prev) => ({ ...prev, [fieldName]: fieldValue }));
      if (fieldName === 'nameOnLicenseEn') {
        transliterateNameOnLicense(fieldValue);
      }
    }
  };

  return (
    <GenericDrawer title="Modify Motor Details" isOpen={open} onClose={onClose}>
      {!data ? (
        <Loader />
      ) : (
        <DynamicForm
          inputs={inputForm}
          onSubmit={submitForm}
          onChange={handleFormChange}
          buttonText="Submit"
          submitButtonState={submitButtonState}
          disableForm={disabledForm}
          title="Information"
        />
      )}
    </GenericDrawer>
  );
};

export default MotorDetailsDrawer;

import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { useMutation } from '@apollo/client';
import TextInputFormField from '../../components/form-fields/TextInputFormField';
import SelectFormField from '../../components/form-fields/SelectFormField';
import { IPolicyPropertyDescriptionDetails } from '../../modules/production/policy/page';
import CoverSplit from './custom-section/CoverSplit';
import EnhancedButton from '../../components/form-fields/buttons/EnhancedButton';
import TabPanel from '../../components/form-fields/tabs-widget/TabPanel';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getError } from '../../utils/graph-utils';
import { modifyPolicyDescription } from './queries';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';

interface IDescriptionOfRiskPopUpFormProps {
  currencySymbol: string;
  data: IPolicyPropertyDescriptionDetails[];
  lovs: Record<string, Record<string, string>>;
  onClose: () => void;
  onSuccess: () => void;
}

const useStyles = makeStyles()(() => ({
  content: {
    padding: '0 63px 40px 30px',
  },
  tabsRoot: {
    minHeight: 'auto',
    borderBottom: '1px solid #E5E5E5',
    marginBottom: '20px',
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: 'auto',
    padding: '6px 12px',
    marginRight: '4px',
    color: '#8e8e8e',
    fontFamily: 'SourceSansPro-Regular',
    '&.Mui-selected': {
      color: '#000000',
      fontFamily: 'SourceSansPro-SemiBold',
      backgroundColor: '#F9F9F9',
    },
  },
  firstGrid: {
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 3fr',
    gridColumnGap: '21px',
    marginBottom: '20px',
  },
  singleItem: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '15px',
    marginTop: '3em',
  },
}));

const DescriptionOfRiskPopUpForm: React.FC<
  IDescriptionOfRiskPopUpFormProps
> = ({ currencySymbol, data, lovs, onClose, onSuccess }) => {
  const { classes } = useStyles();
  const params = useParams();

  const [modifyPolicyDescriptionAction] = useMutation(
    modifyPolicyDescription()
  );

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [propertyDescriptions, setPropertyDescriptions] =
    useState<IPolicyPropertyDescriptionDetails[]>(data);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleFieldUpdate = (
    index: number,
    fieldName: keyof IPolicyPropertyDescriptionDetails,
    value: string
  ) => {
    const newProps = [...propertyDescriptions];
    (newProps[index] as any)[fieldName] = value;
    setPropertyDescriptions(newProps);
  };

  const handleSubmit = async () => {
    const policyPropertyInput = propertyDescriptions.flatMap((desc, idx) =>
      desc.planCovers.map((cover) => ({
        RiskDescription: desc.riskDescription || '',
        Location: desc.location || '',
        GeographicalLocation: desc.geoLocationCode || '',
        PlanCoverID: cover.planCoverId || '',
        FireRate: cover.riskRate ?? 0,
        SumInsured: cover.sumInsured ?? 0,
        Premium: cover.premium ?? 0,
        Order: idx + 1,
      }))
    );

    try {
      await modifyPolicyDescriptionAction({
        variables: {
          entityId: params.id,
          policyPropertyInput,
        },
      });
      toast.success(
        <ToastSuccessMessage>
          Property Risk Description updated successfully!
        </ToastSuccessMessage>
      );
      onSuccess();
      onClose();
    } catch (err) {
      toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
    }
  };

  return (
    <div className={classes.content}>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="Property Description Tabs"
        classes={{ root: classes.tabsRoot }}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        {propertyDescriptions.map((_, index) => (
          <Tab
            key={`Risk-${index}`}
            label={`Risk ${index + 1}`}
            classes={{ root: classes.tabRoot }}
          />
        ))}
      </Tabs>

      {propertyDescriptions.map((desc, index) => (
        <TabPanel key={`property-${index}`} index={index}>
          <div className={clsx('container', classes.firstGrid)}>
            <TextInputFormField
              name={`riskDescription-${index}`}
              title="Risk Description"
              placeholder="Enter description"
              value={desc.riskDescription || ''}
              onChange={(e) =>
                handleFieldUpdate(index, 'riskDescription', e.target.value)
              }
            />
            <SelectFormField
              name={`geoLocationCode-${index}`}
              title="Geographical Location"
              placeholder="Select location..."
              value={desc.geoLocationCode || ''}
              onChange={(val: string) =>
                handleFieldUpdate(index, 'geoLocationCode', val)
              }
              selectOptions={lovs.geoLocations}
            />
            <TextInputFormField
              name={`location-${index}`}
              title="Location"
              placeholder="Enter location"
              value={desc.location || ''}
              onChange={(e) =>
                handleFieldUpdate(index, 'location', e.target.value)
              }
            />
          </div>
          <CoverSplit
            currencySymbol={currencySymbol}
            rows={desc.planCovers}
            lovs={lovs}
            onUpdateRows={(newRows) => {
              const updated = [...propertyDescriptions];
              updated[index].planCovers = newRows;
              setPropertyDescriptions(updated);
            }}
          />
        </TabPanel>
      ))}

      <div className={classes.buttonsContainer}>
        <EnhancedButton onClick={onClose}>Cancel</EnhancedButton>
        <EnhancedButton isPrimary onClick={handleSubmit}>
          Submit
        </EnhancedButton>
      </div>
    </div>
  );
};

export default DescriptionOfRiskPopUpForm;

import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';
import DateService from '../../services/dateService';

export const initialValues = {
  commissionType: '',
  lineCommissionValue: '',
  lineCommissionValueCurrency: '',
  lineCommissionRate: '',
  lineCommissionApplicableOn: '',
  activeFrom: '',
  activeTo: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  commissionType: {
    name: 'commissionType',
    title: 'Commission Type',
    placeholder: 'Commission Type',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.commissionType)
      ? initialValues.commissionType
      : '',
    multiple: false,
    required: true,
    hidden: false,
  },
  lineCommissionValue: {
    name: 'lineCommissionValue',
    title: 'Commission',
    type: FormInputTypes.formattedNumber,
    placeholder: 'Commission',
    value: initialValues.lineCommissionValue,
    required: false,
    disabled: false,
    hidden: false,
    maxDecimalPercision: 3,
    conditionalHidden: (values) => {
      const isRate = values?.commissionType?.toLowerCase() === 'rate';
      return isRate;
    },
    conditionalDisable: (values) => {
      const isRate = values?.commissionType?.toLowerCase() === 'rate';
      return isRate;
    },
    conditionalRequired: (values) => {
      const isRate = values?.commissionType?.toLowerCase() === 'rate';
      return !isRate;
    },
  },
  lineCommissionValueCurrency: {
    name: 'lineCommissionValueCurrency',
    title: 'Commission Currency',
    placeholder: 'Commission Currency',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.lineCommissionValueCurrency)
      ? initialValues.lineCommissionValueCurrency
      : '',
    required: false,
    disabled: false,
    hidden: false,
    conditionalHidden: (values) => {
      const isRate = values?.commissionType?.toLowerCase() === 'rate';
      return isRate;
    },
    conditionalDisable: (values) => {
      const isRate = values?.commissionType?.toLowerCase() === 'rate';
      return isRate;
    },
    conditionalRequired: (values) => {
      const isRate = values?.commissionType?.toLowerCase() === 'rate';
      return !isRate;
    },
  },
  lineCommissionRate: {
    name: 'lineCommissionRate',
    title: 'Commission Rate',
    type: FormInputTypes.percentage,
    placeholder: 'Commission Rate',
    value: initialValues.lineCommissionRate,
    hasBetweenValidation: true,
    required: false,
    disabled: false,
    hidden: false,
    conditionalHidden: (values) => {
      const isRate = values?.commissionType?.toLowerCase() === 'rate';
      return !isRate;
    },
    conditionalDisable: (values) => {
      const isRate = values?.commissionType?.toLowerCase() === 'rate';
      return !isRate;
    },
    conditionalRequired: (values) => {
      const isRate = values?.commissionType?.toLowerCase() === 'rate';
      return isRate;
    },
  },
  lineCommissionApplicableOn: {
    name: 'lineCommissionApplicableOn',
    title: 'Commission Applicable On',
    placeholder: 'Commission Applicable On',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.lineCommissionApplicableOn)
      ? initialValues.lineCommissionApplicableOn
      : '',
    required: false,
    disabled: false,
    hidden: false,
    conditionalHidden: (values) => {
      const isRate = values?.commissionType?.toLowerCase() === 'rate';
      return !isRate;
    },
    conditionalDisable: (values) => {
      const isRate = values?.commissionType?.toLowerCase() === 'rate';
      return !isRate;
    },
    conditionalRequired: (values) => {
      const isRate = values?.commissionType?.toLowerCase() === 'rate';
      return isRate;
    },
  },
  activeFrom: {
    name: 'activeFrom',
    title: 'Active From',
    placeholder: 'Active From',
    type: FormInputTypes.date,
    value: initialValues.activeFrom,
    required: true,
    customValidator: (values) => {
      const { activeTo, activeFrom } = values;
      if (
        activeTo &&
        activeFrom &&
        DateService.isDateBefore(activeTo, activeFrom)
      ) {
        return 'Effective To Date shall be equal or greater than Effective From Date';
      }
      return '';
    },
  },
  activeTo: {
    name: 'activeTo',
    title: 'Active To',
    placeholder: 'Active To',
    type: FormInputTypes.date,
    value: initialValues.activeTo,
    required: true,
    hidden: false,
    disabled: false,
  },
};

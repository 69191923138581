import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import WidgetPaper from '../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../components/common/WidgetSection';
import { IPolicyPropertyDescriptionDetails } from '.';
import EnhancedButton from '../../../../components/form-fields/buttons/EnhancedButton';
import DescriptionOfRiskPopUpShell from '../../../../forms/property-description-popup/DescriptionOfRiskPopUpShell';

interface IProductionPropertyDescriptionDetailsInfoProps {
  currencySymbol: string;
  data: IPolicyPropertyDescriptionDetails[];
  lovs: Record<string, Record<string, string>>;
  allowPolicyCorrection: boolean;
  onDataRefresh: () => void;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '0 0 4px 4px',
    padding: '16px 24px',
    marginBottom: '14px',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '10px',
    marginBottom: '12px',
  },
  label: {
    color: '#000000',
    fontSize: '16px',
    fontFamily: 'SourceSansPro-Regular',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  value: {
    color: '#000000',
    fontSize: '16px',
    fontFamily: 'SourceSansPro-Regular',
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: 'none',
  },
  tabPanel: {
    padding: '0',
  },
  widgetPaper: {
    marginTop: '1em',
  },
  tabsRoot: {
    minHeight: 'auto',
    overflowX: 'auto',
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: 'auto',
    padding: '6px 12px',
    marginRight: '4px',
    color: '#8e8e8e',
    border: '1px solid #E5E5E5',
    borderBottom: 'none',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: '#F9F9F9',
    fontFamily: 'SourceSansPro-Regular',
    '&.Mui-selected': {
      color: '#000000',
      fontFamily: 'SourceSansPro-SemiBold',
      backgroundColor: '#F9F9F9',
      borderBottom: 'none',
    },
  },
  indicator: {
    display: 'none',
  },
  separator: {
    margin: '8px 0',
  },
}));

const ProductionPropertyDescriptionDetailsInfo: React.FC<
  IProductionPropertyDescriptionDetailsInfoProps
> = ({ currencySymbol, data, lovs, allowPolicyCorrection, onDataRefresh }) => {
  const { classes } = useStyles();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [descriptionOfRiskPopupOpen, setDescriptionOfRiskPopupOpen] =
    useState<boolean>(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <WidgetPaper className={classes.widgetPaper}>
        <WidgetSection
          title="Property Description"
          actions={
            allowPolicyCorrection && (
              <div>
                <EnhancedButton
                  isPrimary
                  onClick={() => {
                    setDescriptionOfRiskPopupOpen(true);
                  }}
                >
                  Modify
                </EnhancedButton>
              </div>
            )
          }
        >
          <div className={classes.tabsContainer}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="Policy Cover Tabs"
              classes={{ root: classes.tabsRoot }}
              TabIndicatorProps={{ className: classes.indicator }}
            >
              {data.map((_, index) => (
                <Tab
                  key={`Risk ${index + 1}`}
                  label={`Risk ${index + 1}`}
                  classes={{ root: classes.tabRoot }}
                />
              ))}
            </Tabs>
          </div>
          {data.map((item, index) => (
            <TabPanel value={selectedTab} index={index} key={item.geoLocation}>
              <Box className={classes.container}>
                <div className={classes.fieldRow}>
                  <div>
                    <Typography className={classes.label}>
                      Risk Description
                    </Typography>
                    <Typography className={classes.value}>
                      {item.riskDescription}
                    </Typography>
                  </div>
                  <div>
                    <Typography className={classes.label}>
                      Geographical Location
                    </Typography>
                    <Typography className={classes.value}>
                      {item.geoLocation}
                    </Typography>
                  </div>
                  <div>
                    <Typography className={classes.label}>Location</Typography>
                    <Typography className={classes.value}>
                      {item.location}
                    </Typography>
                  </div>
                </div>
                <Divider className={classes.separator} />
                {item.planCovers.map((cover, coverIndex) => (
                  <div key={cover.planCoverID} className={classes.fieldRow}>
                    <div>
                      <Typography className={classes.label}>
                        Policy Cover
                      </Typography>
                      <Typography className={classes.value}>
                        {cover.planCoverID || '--'}
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.label}>Premium</Typography>
                      <Typography className={classes.value}>
                        {cover.premium || '--'}
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.label}>
                        Sum Insured
                      </Typography>
                      <Typography className={classes.value}>
                        {cover.sumInsured || '--'}
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.label}>
                        Risk Rate
                      </Typography>
                      <Typography className={classes.value}>
                        {cover.riskRate || '--'}
                      </Typography>
                    </div>
                  </div>
                ))}
              </Box>
            </TabPanel>
          ))}
        </WidgetSection>
      </WidgetPaper>
      {descriptionOfRiskPopupOpen && (
        <DescriptionOfRiskPopUpShell
          open={descriptionOfRiskPopupOpen}
          currencySymbol={currencySymbol}
          propertyData={data}
          lovs={lovs}
          onClose={() => setDescriptionOfRiskPopupOpen(false)}
          onSuccess={() => {
            onDataRefresh();
          }}
        />
      )}
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`property-tabpanel-${index}`}
      aria-labelledby={`property-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default ProductionPropertyDescriptionDetailsInfo;

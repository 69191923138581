import { isEmpty } from 'lodash';
import { quote } from '../../../../components/custom/QuotesCards';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../../../utils/graph-utils';

export function LookupToList(data: any) {
  const result: Record<string, Record<string, string>> = {};

  result['medicalClasses'] = lookupListAsRecordObject(
    data?.Core?.lookups?.medicalClasses
  );
  result['currency'] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies,
    true
  );
  result['usageTypes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.usageTypes
  );
  result['bodyTypes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.bodyTypes
  );
  result['engineTypes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.engineTypes
  );
  result['plateCodes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.plateCodes
  );
  result['brands'] = lookupListAsRecordObject(data?.Insurance?.lookups?.brands);
  result['colors'] = lookupListAsRecordObject(data?.Core?.lookups?.colors);
  result['geoLocations'] = lookupListAsRecordObject(
    data?.Core?.lookups?.geoLocations
  );
  result['matters'] = lookupListAsRecordObject(data?.Core?.lookups?.matters);
  result['phoneType'] = enumListAsRecordObject(
    data?.Insurance_PhoneType?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );
  result['nationalities'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.nationalities
  );
  result['countries'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.countries
  );
  result['occupationClasses'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.occupationClasses
  );

  return result;
}

export function graphqlEntityToQuoteDetails(
  data: any,
  currencySymbol: string
): quote {
  return {
    id: data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
      ?.QuoteID?.id,
    title:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.Name,
    status:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.Status,
    statusReason:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.StatusReason,
    createdOn:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.createdOn,
    modifiedOn:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.modifiedOn,
    hasCoverage: !isEmpty(
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.MotorDetailsID
    ),
    coverageAmount:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.MotorDetailsID?.views
        ?.Insurance_all?.properties?.SumInsured,
    coverageAmountCurrencySymbol: currencySymbol,
    premiumAmount:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties?.TotalAnnualPremium,
    premiumAmountCurrencySymbol: currencySymbol,
    towingServiceTitle:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.QuoteID?.views?.Insurance_all?.properties
        ?.ApplicationMotorPlanDetailsID?.views?.Insurance_all?.properties
        ?.TowingService?.Title,
    vignetteCode:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.views?.Insurance_all?.properties?.VignetteCode,
    vignette:
      data?.Insurance?.entities?.policy?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.views?.Insurance_all?.properties?.Vignette,
  };
}

export function getPlanCoversLov(data: any): Record<string, string> {
  const result: Record<string, string> = {};

  data.PlanConfigManagement.queries.getPlanSpecificMedicalCovers.forEach(
    (planCover: any) => {
      const id =
        planCover.planConfigManagement_PlanSpecificMedical_PolicyCoverID?.id;
      const policyCover =
        planCover.planConfigManagement_PlanSpecificMedical_PolicyCoverID.views
          .PlanConfigManagement_all.properties;
      result[id] = `${policyCover.ExternalCode} - ${policyCover.Name}`;
    }
  );

  return result;
}

import { useLazyQuery, useMutation } from '@apollo/client';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from '../utils/userUtils';
import { isEmpty } from '../utils/validationUtils';
import { getAppVisibilitySettings, userDetailsQuery } from './queries';
import type { AppDispatch, RootState } from './store';
import { IUserDetails, IUserMeta } from './user/types';
import { IAppVisibilityDetails } from './app-settings/types';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type UseUserDetailsServiceApi = {
  getUserDetails: () => Promise<IUserDetails | null>;
};
export const useUserDetailsApi = (): UseUserDetailsServiceApi => {
  const [getUserDetails] = useLazyQuery<{
    SalesforceManagement: {
      queries: {
        getCurrentUser: {
          system_User_Id?: string;
          system_User_firstName?: string;
          system_User_lastName?: string;
          businessUser_Id?: string;
          businessUser_Code?: string;
          system_User_mobile?: string;
          system_User_email?: string;
          system_User_roles?: IUserMeta['userRoles'];
          system_User_groups: string[];
          agent_Id?: string;
          agent_AgentType?: string;
          businessUser_AllowGroupPolicy: boolean;
          businessUser_AllowPolicyCorrection: boolean;
          businessUser_AllowEndorsementCostEdits: boolean;
          businessUser_AllowEndorsement: boolean;
          agent_AgencyID?: {
            id: string;
          }[];
          agent_LinesOfBusiness?: {
            Code: string;
          }[];
          agent_Products?: {
            Code: string;
          }[];
          agent_EligiblePlans?: {
            Code: string;
          }[];
        }[];
      };
    };
    Insurance: {
      lookups: {
        linesOfBusiness: {
          Code?: string;
          Title?: string;
          Active?: boolean;
        };
      };
    };
  }>(userDetailsQuery);

  return {
    getUserDetails: () => {
      if (isEmpty(getUserToken())) {
        return null;
      }
      return getUserDetails().then((res) => {
        const { data, error } = res;

        if (error) {
          return null;
        }

        if (data) {
          const user = data.SalesforceManagement.queries.getCurrentUser[0];
          const linesOfBusinessStatuses =
            data.Insurance.lookups.linesOfBusiness;
          const userDetails: IUserDetails = {
            haveWeakPassword: false,
            info: {
              id: user.system_User_Id,
              firstName: user.system_User_firstName,
              lastName: user.system_User_lastName,
              businessUser_Id: user.businessUser_Id,
              businessUser_Code: user.businessUser_Code,
              email: user.system_User_email,
              agencyId: user.agent_AgencyID?.map((agId) => agId.id) || [],
              agentId: user.agent_Id,
              businessUser_AllowGroupPolicy: user.businessUser_AllowGroupPolicy,
              businessUser_AllowPolicyCorrection:
                user.businessUser_AllowPolicyCorrection,
              businessUser_AllowEndorsementCostEdits:
                user.businessUser_AllowEndorsementCostEdits,
              businessUser_AllowEndorsement: user.businessUser_AllowEndorsement,
              linesOfBusiness: user.agent_LinesOfBusiness?.map(
                (lkp) => lkp.Code
              ),
              products: user.agent_Products?.map((lkp) => lkp.Code),
              eligiblePlans: user.agent_EligiblePlans?.map((lkp) => lkp.Code),
              agentType: user.agent_AgentType,
            },
            userRoles: user.system_User_roles || [],
            linesOfBusinessStatuses: linesOfBusinessStatuses,
          };
          return userDetails;
        }
        return null;
      });
    },
  };
};

type UseAppDetailsServiceApi = {
  getAppDetails: () => Promise<IAppVisibilityDetails | null>;
};
export const useAppDetailsApi = (): UseAppDetailsServiceApi => {
  const [getAppDetails] = useMutation<{
    insurance: {
      actions: {
        getAppVisibilitySettings: {
          IsPlanConfigurationEnabled?: boolean;
          IsSalesForceAppEnabled?: boolean;
        };
      };
    };
  }>(getAppVisibilitySettings());

  return {
    getAppDetails: () => {
      return getAppDetails().then((res) => {
        const { data } = res;

        if (data) {
          const appDetails: IAppVisibilityDetails = {
            isPlanConfigurationEnabled:
              res?.data?.insurance?.actions?.getAppVisibilitySettings
                ?.IsPlanConfigurationEnabled,
            isSalesForceAppEnabled:
              res?.data?.insurance?.actions?.getAppVisibilitySettings
                ?.IsSalesForceAppEnabled,
          };
          return appDetails;
        }
        return null;
      });
    },
  };
};

import React from 'react';
import WidgetPaper from '../../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../../components/common/WidgetSection';
import { makeStyles } from 'tss-react/mui';
import TextInputFormField from '../../../../../../components/form-fields/TextInputFormField';
import SelectFormField from '../../../../../../components/form-fields/SelectFormField';
import DatePickerFormField from '../../../../../../components/form-fields/DatePickerFormField';
import CurrencyFormField from '../../../../../../components/form-fields/CurrencyFormField';
import EnhancedPercentageInput from '../../../../../../components/enhanced-form/EnhancedPercentageInput';
import { getMutationVariables } from './utils';
import { ITreatyLovs, ITreatyDetails, ITreatyErrors } from '../..';
import EnhancedButton from '../../../../../../components/form-fields/buttons/EnhancedButton';
import { Chip } from '@mui/material';
import { MAIN_ONE_THEME } from '../../../../../../constants';
import { cloneDeep, isEqual } from 'lodash';
import TabsRepeater from './TabsRepeater';
import { useMutation } from '@apollo/client';
import { saveTreatyMutation } from './queries';
import { toast } from 'react-toastify';
import ToastSuccessMessage from '../../../../../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../../utils/graph-utils';
import DateService from '../../../../../../services/dateService';

interface ITreatyDetailsWidget {
  currentTreatyId: string;
  isDisabled: boolean;
  values: ITreatyDetails;
  updateValues: (values: ITreatyDetails) => void;
  errors: ITreatyErrors;
  updateErrors: (errors: ITreatyErrors) => void;
  lovs: ITreatyLovs;
  shouldSave: boolean;
  setShouldSave: (shouldSave: boolean) => void;
  validateForm: () => boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, 32%)`,
    gap: '2%',
  },
  dirtyChip: {
    marginBottom: '-7px',
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    '& span': {
      color: '#ffffff',
    },
  },
}));

const TreatyDetailsWidget: React.FC<ITreatyDetailsWidget> = ({
  currentTreatyId,
  isDisabled,
  values,
  updateValues,
  errors,
  updateErrors,
  lovs,
  shouldSave,
  setShouldSave,
  validateForm,
}) => {
  const { classes } = useStyles();
  const [saveTreaty] = useMutation(saveTreatyMutation);

  const handleFieldChange = (
    newValue: any,
    fieldName: keyof ITreatyDetails
  ) => {
    const newValues = cloneDeep(values);
    const valueChanged = !isEqual(newValues[fieldName], newValue);
    if (valueChanged) {
      if (fieldName === 'treatyType') {
        const isProportional = newValue.toLowerCase() === 'proportional';

        newValues.substandardLimit = isProportional ? '100' : '';
        newValues.layers.tab_0.treatySubType = isProportional
          ? 'QuotaShare'
          : 'ExcessOfLoss';
      }

      newValues[fieldName] = newValue;
      updateValues(newValues);
      setShouldSave(true);
    }
  };

  const handleFieldBlur = (fieldName: string) => {
    if (fieldName === 'effectiveDate' || fieldName === 'expiryDate') {
      validateDates();
    }
  };

  const handleSave = async () => {
    const isValid = validateForm();

    if (isValid) {
      try {
        const mutationVariables = getMutationVariables(currentTreatyId, values);

        const res = await saveTreaty({
          variables: mutationVariables,
        });

        if (!res.errors) {
          setShouldSave(false);
          toast.success(
            <ToastSuccessMessage>Successfully saved</ToastSuccessMessage>
          );
        }
      } catch (error) {
        toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
      }
    }
  };

  const validateDates = () => {
    if (!values.effectiveDate || !values.expiryDate) {
      return;
    }
    const newErrors = cloneDeep(errors);
    const error = DateService.isDateBefore(
      values.expiryDate,
      values.effectiveDate
    )
      ? 'Cannot be less than Effective Date'
      : '';

    newErrors.expiryDate = error;
    updateErrors(newErrors);
  };

  return (
    <WidgetPaper>
      <WidgetSection
        title="Treaty Details"
        hasTitleSpecificDesign={false}
        useSeparator={false}
        middle={
          shouldSave &&
          !isDisabled && (
            <Chip
              label={'Treaty details changed, please save again'}
              className={classes.dirtyChip}
            />
          )
        }
        actions={
          isDisabled ? null : (
            <EnhancedButton onClick={handleSave} disabled={!shouldSave}>
              Save
            </EnhancedButton>
          )
        }
      >
        <div className={classes.fieldRow}>
          <TextInputFormField
            name="treatyName"
            title="Treaty Name*"
            placeholder="Enter Treaty Name"
            value={values.treatyName}
            onChange={(e) => {
              handleFieldChange(e.target.value, 'treatyName');
            }}
            error={errors.treatyName}
            disabled={isDisabled}
          />
          <SelectFormField
            name="treatyType"
            title="Treaty Type*"
            placeholder="Select Treaty Type"
            selectOptions={lovs.treatyType}
            value={values.treatyType}
            onChange={(v) => {
              handleFieldChange(v, 'treatyType');
            }}
            error={errors.treatyType}
            disabled={isDisabled}
          />
          <DatePickerFormField
            name="effectiveDate"
            title="Effective Date*"
            value={values.effectiveDate}
            onDateChange={(v) => {
              handleFieldChange(v.toString(), 'effectiveDate');
            }}
            onBlur={() => {
              handleFieldBlur('effectiveDate');
            }}
            error={errors.effectiveDate}
            disabled={isDisabled}
          />
          <DatePickerFormField
            name="expiryDate"
            title="Expiry Date*"
            value={values.expiryDate}
            minDate={DateService.addDays(values.effectiveDate, 1)}
            onDateChange={(v) => {
              handleFieldChange(v.toString(), 'expiryDate');
            }}
            onBlur={() => {
              handleFieldBlur('expiryDate');
            }}
            error={errors.expiryDate}
            disabled={isDisabled}
          />
          <SelectFormField
            name="currency"
            title="Currency*"
            placeholder="Select Currency"
            selectOptions={lovs.currency}
            value={values.currency}
            onChange={(v) => {
              handleFieldChange(v, 'currency');
            }}
            error={errors.currency}
            disabled={isDisabled}
          />
          <CurrencyFormField
            name="underwritingLimit"
            title="Underwriting Limit*"
            placeholder="Enter Underwriting Limit"
            value={values.underwritingLimit}
            onChange={(e) => {
              handleFieldChange(e.target.value, 'underwritingLimit');
            }}
            currencySymbol={lovs?.currency?.[values.currency]}
            error={errors.underwritingLimit}
            disabled={isDisabled}
          />
          <CurrencyFormField
            name="claimAdviseLimit"
            title="Claim Advise Limit*"
            placeholder="Enter Claim Advise Limit"
            value={values.claimAdviseLimit}
            onChange={(e) => {
              handleFieldChange(e.target.value, 'claimAdviseLimit');
            }}
            currencySymbol={lovs?.currency?.[values.currency]}
            error={errors.claimAdviseLimit}
            disabled={isDisabled}
          />
          {values?.treatyType?.toLowerCase() === 'proportional' && (
            <EnhancedPercentageInput
              name="substandardLimit"
              title="Substandard Limit*"
              placeholder="Enter Substandard Limit"
              value={values.substandardLimit}
              onChange={(e) => {
                handleFieldChange(e.target.value, 'substandardLimit');
              }}
              onBlur={() => {}}
              error={errors.substandardLimit}
              disabled={isDisabled}
            />
          )}
        </div>
      </WidgetSection>
      <WidgetSection>
        <TabsRepeater
          values={values}
          errors={errors}
          onChange={(allValues: ITreatyDetails) => {
            updateValues(allValues);
            setShouldSave(true);
          }}
          updateErrors={(allErrors: ITreatyErrors) => {
            updateErrors(allErrors);
          }}
          lovs={lovs}
          showTabs={values.treatyType === 'PROPORTIONAL'}
          tabsTitle="Layer"
          isDisabled={isDisabled}
        />
      </WidgetSection>
    </WidgetPaper>
  );
};

export default TreatyDetailsWidget;

// excelUtils.ts
import XLSX from 'xlsx-js-style';
import { isEmpty } from 'lodash';
import {
  trimTrailingZeros,
  valueCommaSeparated,
} from '../../utils/formatting-utils';
import DateService from '../../services/dateService';

interface DownloadExcelProps {
  data: any[];
  filename: string;
  headers: {
    [key: string]: {
      label: string;
      visible: boolean;
      isDate: boolean;
      isCurrency: boolean;
      currencySymbol?: string;
    };
  };
  specialHeaders?: { [key: string]: string[] };
}

export function downloadExcelFile({
  data,
  filename,
  headers,
  specialHeaders,
}: DownloadExcelProps) {
  const formatDate = (dateString: string) => {
    if (isEmpty(dateString)) {
      return '';
    }
    return DateService.formatDate(dateString);
  };

  const getFieldValue = (
    item: any,
    field: string,
    index: number,
    isDate?: boolean,
    isCurrency?: boolean,
    currencySymbol?: string
  ) => {
    const value = item[field];
    if (value && typeof value === 'object' && 'Title' in value) {
      return value.Title;
    }

    if (isDate) {
      return formatDate(value);
    }

    if (isCurrency) {
      const v = Number(value);
      let displayValue = `${trimTrailingZeros(valueCommaSeparated(Math.abs(v)), 3, true)}`;
      if (currencySymbol) {
        displayValue = currencySymbol + ' ' + displayValue;
      } else if (data[index].currencySymbol) {
        displayValue = data[index].currencySymbol + ' ' + displayValue;
      }
      return displayValue;
    }

    return value;
  };

  const MAX_WIDTH = 30;

  if (!data || data.length === 0) {
    console.error('No data provided.');
    return;
  }

  if (isEmpty(filename)) {
    console.error('Filename is empty.');
    return;
  }

  const transformedData = data.map((item, index) => {
    const transformedItem: { [key: string]: any } = {};

    Object.keys(headers).forEach((key) => {
      if (headers[key].visible) {
        transformedItem[headers[key].label] = getFieldValue(
          item,
          key,
          index,
          headers[key].isDate,
          headers[key].isCurrency,
          headers[key].currencySymbol
        );
      }
    });

    if (specialHeaders) {
      Object.keys(specialHeaders).forEach((specialKey) => {
        const fields = specialHeaders[specialKey];
        transformedItem[specialKey] = fields
          .map((field) => getFieldValue(item, field, index))
          .join(' ');
      });
    }

    return transformedItem;
  });

  const newWorkbook = XLSX.utils.book_new();
  const newWorksheet = XLSX.utils.json_to_sheet(transformedData);

  const headerRow = Object.values(headers)
    .filter((header) => header.visible)
    .map((header) => header.label);

  XLSX.utils.sheet_add_aoa(newWorksheet, [headerRow], { origin: 'A1' });

  const headerStyle = {
    font: { sz: 12, bold: true, color: { rgb: 'FFFFFF' } },
    fill: { fgColor: { rgb: 'D30D2B' } },
    // alignment: { horizontal: "center", vertical: "center" },
  };

  const range = XLSX.utils.decode_range(newWorksheet['!ref']);
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cellAddress = XLSX.utils.encode_cell({ c: C, r: 0 });
    if (!newWorksheet[cellAddress]) continue;
    newWorksheet[cellAddress].s = headerStyle;
  }

  const columnWidths = headerRow.map((headerLabel) => {
    const maxHeaderLength = headerLabel.length;
    const maxContentLength = Math.max(
      ...transformedData.map((row) =>
        row[headerLabel] ? row[headerLabel].toString().length : 0
      )
    );
    const calculatedWidth = Math.max(maxHeaderLength, maxContentLength) + 2;
    return { wch: Math.min(calculatedWidth, MAX_WIDTH) };
  });

  newWorksheet['!cols'] = columnWidths;

  XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, 'Sheet1');

  const excelBuffer = XLSX.write(newWorkbook, {
    bookType: 'xlsx',
    type: 'array',
  });
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

import { gql } from '@apollo/client';

export function modifyMotor() {
  return gql`
    mutation modifyMotor(
      $entityId: String!
      $vehicleDetailInputs: Production_VehicleDetailsInputs!
      $depreciationYears: Long
      $agencyRepairYear: Long
    ) {
      production {
        entities {
          policy {
            production {
              modifyMotor(
                entityId: $entityId
                vehicleDetailsInput: $vehicleDetailInputs
                agencyRepairYear: $agencyRepairYear
                depreciationYears: $depreciationYears
              )
            }
          }
        }
      }
    }
  `;
}

export function getModelsByBrand() {
  return gql`
    query getModelsByBrand($modelId: String!) {
      Insurance {
        lookups {
          models(Insurance_Brands: $modelId) {
            Id
            Title
          }
        }
      }
    }
  `;
}

import { gql } from '@apollo/client';

export function modifyPolicyDescription() {
  return gql`
    mutation modifyPolicyDescription(
      $entityId: String!
      $policyPropertyInput: [Production_PolicyPropertyInput]
    ) {
      production {
        entities {
          policy {
            production {
              modifyPropertyDetails(
                entityId: $entityId
                policyPropertyInput: $policyPropertyInput
              )
            }
          }
        }
      }
    }
  `;
}

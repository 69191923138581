import { IMarineGroupCertficateForm } from '.';
import { IAbstractRecord } from '../../../../../../../models';
import PrecisionService from '../../../../../../../services/precisionService';
import DateService from '../../../../../../../services/dateService';

export const mapCertificateInput = (
  values: IMarineGroupCertficateForm,
  lovs: Record<string, IAbstractRecord>
) => {
  return {
    Bank: values.details.bank,
    CargoContract: values.details.cargoContract,
    ChargesPercentage: PrecisionService.divideBy100(
      values.costs.chargesPercentage
    ),
    ClaimsPayableBy: values.details.claimsPayableBy,
    DepartureFrom: values.details.from,
    Description: values.details.description,
    DestinationTo: values.details.to,
    EffectiveDate: DateService.formatDateBackend(
      values.policyDetails.policyEffectiveDate
    ),
    ExpiryDate: DateService.formatDateBackend(
      values.policyDetails.policyExpiryDate
    ),
    LCIssueDate: values.details.lcIssueDate ? values.details.lcIssueDate : null,
    LCNumber: values.details.lcNumber,
    Loading: PrecisionService.divideBy100(values.details.loading),
    Matter: values.details.matter,
    planExternalCode: lovs.plan[values.policyDetails.plan].split(' - ')?.[0],
    PlanID: values.policyDetails.plan,
    PolicyCost: Number(values.costs.policyCost),
    Remarks: values.details.remarks,
    sublineExternalCode:
      lovs.subline[values.policyDetails.subline].split(' - ')?.[0],
    SublineID: values.policyDetails.subline,
    SumPerShipment: Number(values.details.sumPerShipment),
    Survey: values.details.survey,
    TPAFeesAmount: Number(values.costs.tpaFeesAmount),
    VesselAge: Number(values.details.vesselAge),
    VesselName: values.details.vesselName,
    TransportationVia: values.details.via,
    policyPlanCoverInput: values.covers.map((cover) => ({
      CoverEffectiveFrom: DateService.formatDateBackend(
        cover.coverEffectiveFrom
      ),
      CoverEffectiveTo: DateService.formatDateBackend(cover.coverEffectiveTo),
      CoverPremiumValue: Number(cover.premium),
      CoverSumInsured: Number(cover.coverSumInsured),
      ExcessOnClaimAmount: Number(cover.excessOnClaimAmount),
      ExcessOnClaimDays: Number(cover.excessOnClaimDays),
      ExcessOnClaimPercentage: PrecisionService.divideBy100(
        cover.excessOnClaimPercentage
      ),
      ExcessOnClaimType: cover.excessOnClaimType,
      IsEditable: cover.isEditable,
      IsMain: cover.isMain,
      isMandatory: cover.isMandatory,
      SumInsuredIsAdditive: cover.sumInsuredIsAdditive,
      SumInsuredPerClaim: Number(cover.coverSumInsured),
      CoverPremiumType: 'VALUE',
      PlanCoverID: cover.planCoverId,
      // CoverPremiumPercentage: cover.premium,
    })),
  };
};

export const mapSummaryFields = (values: IAbstractRecord) => {
  return {
    subline: values.SublineID,
    line: values.LineID,
    plan: values.PlanID,
    matter: values.Matter,
    vesselName: values.VesselName,
    vesselAge: values.VesselAge,
    from: values.DepartureFrom,
    to: values.DestinationTo,
    via: values.TransportationVia,
    lcNumber: values.LCNumber,
    lcIssueDate: values.LCIssueDate,
    loading: PrecisionService.multiplyBy100(Number(values.Loading)),
    policyEffectiveDate: values.EffectiveDate,
    sumPerShipment: values.SumPerShipment,
    sumInsured: values.certificatePricingOption.sumInsured,
    netPremium: values.certificatePricingOption.netPremium,
    policyCostAmount: values.certificatePricingOption.policyCostAmount,
    chargesAmount: values.certificatePricingOption.chargesAmount,
    tpaFeesTotalAmount: values.certificatePricingOption.tpaFeesTotalAmount,
    grossPremium: values.certificatePricingOption.grossPremium,
    fixedStampAmount: values.certificatePricingOption.fixedStampAmount,
    proportionalStampAmount:
      values.certificatePricingOption.proportionalStampAmount,
    municipalityTaxAmount:
      values.certificatePricingOption.municipalityTaxAmount,
    totalPremium: values.certificatePricingOption.totalPremium,
    currency: values.certificatePricingOption.pricingOptionCurrency.Symbol,
  };
};

export const mapCertificateOutput = (values: IAbstractRecord) => {
  const result = {
    Bank: values.Bank,
    CargoContract: values.CargoContract,
    ChargesPercentage: PrecisionService.divideBy100(values.ChargesPercentage),
    ClaimsPayableBy: values.ClaimsPayableBy,
    ClauseText: values.ClauseText,
    DepartureFrom: values.DepartureFrom,
    Description: values.Description,
    DestinationTo: values.DestinationTo,
    EffectiveDate: values.EffectiveDate,
    ExpiryDate: values.ExpiryDate,
    LCIssueDate: values.LCIssueDate,
    LCNumber: values.LCNumber,
    LineID: values.LineID,
    Loading: values.Loading,
    Matter: values.Matter,
    planExternalCode: values.certificateInputs.planExternalCode,
    PlanID: values.PlanID,
    PolicyCost: values.PolicyCost,
    Remarks: values.Remarks,
    sublineExternalCode: values.certificateInputs.sublineExternalCode,
    SublineID: values.SublineID,
    SumPerShipment: values.SumPerShipment,
    Survey: values.Survey,
    TPAFeesAmount: values.TPAFeesAmount,
    TransportationVia: values.TransportationVia,
    VesselAge: values.VesselAge,
    VesselName: values.VesselName,
    policyPlanCoverInput: values.certificatePolicyPlanCovers,
    certificatePricingOption: {
      ...values.certificatePricingOption,
      fixedStampCurrency:
        values.certificatePricingOption?.fixedStampCurrency.Id,
      pricingOptionCurrency:
        values.certificatePricingOption.pricingOptionCurrency?.Id,
    },
    certificatePolicyBusinessPartner: {
      ...values.certificatePolicyBusinessPartner,
      applicableOn: values.certificatePolicyBusinessPartner?.applicableOn.Id,
      valueCurrency: values.certificatePolicyBusinessPartner?.valueCurrency.Id,
    },
  };

  if (!values.certificatePolicyBusinessPartner) {
    delete result.certificatePolicyBusinessPartner;
  }

  return result;
};

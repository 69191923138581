import _ from 'lodash';
import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IFormState } from '../form';
import { IMarineGroupCertificateDetails } from '..';
import WidgetPaper from '../../../../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../../../../components/common/WidgetSection';
import EnhancedInput from '../../../../../../../../components/enhanced-form/EnhancedInput';
import EnhancedFormattedNumberInput from '../../../../../../../../components/enhanced-form/EnhancedFormattedNumberInput';
import EnhancedDatePicker from '../../../../../../../../components/enhanced-form/EnhancedDatePicker';
import EnhancedPercentageInput from '../../../../../../../../components/enhanced-form/EnhancedPercentageInput';
import EnhancedChipInput from '../../../../../../../../components/enhanced-form/EnhancedChipInput';
import { validateMarineDetailsField } from '../validation';
import EnhancedCurrencyInput from '../../../../../../../../components/enhanced-form/EnhancedCurrencyInput';
import { useMutation } from '@apollo/client';
import { calculateMarineBasicRate } from '../queries';
import ToastErrorMessage from '../../../../../../../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { getError } from '../../../../../../../../utils/graph-utils';

interface IMarineGroupCertificateDetailsWidgetProps {
  pageState: IFormState;
  onPageStateUpdate: (pageState: IFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  currencySymbol: string;
  policyCurrency?: string;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  section: {
    padding: '23px 0 25px',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 23.5%)`,
    gap: '10px 2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  fullFieldRow: {
    gridColumn: '1 / -1',
    width: '100%',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
}));

const MarineGroupCertificateDetailsWidget: React.FC<
  IMarineGroupCertificateDetailsWidgetProps
> = ({
  pageState,
  onPageStateUpdate,
  disabledForm,
  lovs,
  currencySymbol,
  policyCurrency,
}) => {
  const { classes } = useStyles();

  const values = pageState.values.details;
  const errors = pageState.errors.details;
  const touched = pageState.touched.details;

  const [calculateBasicRateAction] = useMutation(calculateMarineBasicRate());

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const initialize = async () => {};

  useEffect(() => {
    initialize();
  }, []);

  const onFieldBlur = async (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.details[fieldName] = true;

    if (
      fieldName === 'sumPerShipmentCurrency' ||
      fieldName === 'sumPerShipment'
    ) {
      if (!values.sumPerShipmentCurrency) {
        newPageState.values.details.sumPerShipmentRate = 0;
        newPageState.values.details.sumPerShipmentInPolicyCurrency = 0;
        onPageStateUpdate(newPageState);
        return;
      }

      if (values.sumPerShipmentCurrency === policyCurrency) {
        newPageState.values.details.sumPerShipmentRate = 1;
        newPageState.values.details.sumPerShipmentInPolicyCurrency = Number(
          values.sumPerShipment.toFixed()
        );
        onPageStateUpdate(newPageState);
        return;
      }

      try {
        const { data: sumPerShipmentData } = await calculateBasicRateAction({
          variables: { currency: values.sumPerShipmentCurrency },
        });

        const { data: policyData } = await calculateBasicRateAction({
          variables: { currency: policyCurrency },
        });

        const sumPerShipmentBasicRate =
          sumPerShipmentData?.production?.actions?.calculateBasicRate
            ?.BasicRate || 1;
        const policyToBasicRate =
          policyData?.production?.actions?.calculateBasicRate?.BasicRate || 1;

        const sumPerShipmentRate = sumPerShipmentBasicRate / policyToBasicRate;

        const sumInPolicy = Number(
          (values.sumPerShipment * sumPerShipmentRate).toFixed(3)
        );
        newPageState.values.details.sumPerShipmentRate = sumPerShipmentRate;
        newPageState.values.details.sumPerShipmentInPolicyCurrency =
          sumInPolicy;
      } catch (err) {
        newPageState.values.details.sumPerShipmentRate = 0;
        newPageState.values.details.sumPerShipmentInPolicyCurrency = 0;
        toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
      }
    }

    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IMarineGroupCertificateDetails,
    value: any,
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);
    (newPageState.values.details as any)[fieldName] = value;
    newPageState.errors.details[fieldName] = validateMarineDetailsField(
      fieldName,
      value
    );

    if (touched) {
      newPageState.touched.details[fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection
        title="Marine Details"
        hasTitleSpecificDesign={false}
        classes={{
          container: classes.section,
        }}
        useSeparator={false}
      >
        <div className={classes.fieldRow}>
          <EnhancedChipInput
            key="matter"
            name="matter"
            title="Matter*"
            placeholder="Matter"
            value={values.matter}
            error={touched.matter ? errors.matter : ''}
            onChange={(v) => onFieldUpdate('matter', v, true)}
            disabled={disabledForm}
            selectOptions={lovs.matters}
            required={true}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
            className={classes.fullFieldRow}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="description"
            name="description"
            title="Description*"
            placeholder="Description"
            value={values.description}
            error={touched.description ? errors.description : ''}
            onBlur={() => onFieldBlur('description')}
            onChange={(v) => onFieldUpdate('description', v.target.value)}
            disabled={disabledForm}
            material={true}
            type="text"
            className={classes.fullFieldRow}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="vesselName"
            name="vesselName"
            title="Vessel Name*"
            placeholder="Vessel Name"
            value={values.vesselName}
            error={touched.vesselName ? errors.vesselName : ''}
            onBlur={() => onFieldBlur('vesselName')}
            onChange={(v) => onFieldUpdate('vesselName', v.target.value)}
            disabled={disabledForm}
            material={true}
            type="text"
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedFormattedNumberInput
            key="vesselAge"
            name="vesselAge"
            title="Vessel Age"
            placeholder="Vessel Age"
            value={values.vesselAge}
            error={touched.vesselAge ? errors.vesselAge : ''}
            onBlur={() => onFieldBlur('vesselAge')}
            onChange={(v) => onFieldUpdate('vesselAge', v.target.value)}
            disabled={disabledForm}
            material={true}
            type="text"
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="from"
            name="from"
            title="Departure From*"
            placeholder="Departure From"
            value={values.from}
            error={touched.from ? errors.from : ''}
            onBlur={() => onFieldBlur('from')}
            onChange={(v) => onFieldUpdate('from', v.target.value)}
            disabled={disabledForm}
            material={true}
            type="text"
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="to"
            name="to"
            title="Destination To*"
            placeholder="Destination To"
            value={values.to}
            error={touched.to ? errors.to : ''}
            onBlur={() => onFieldBlur('to')}
            onChange={(v) => onFieldUpdate('to', v.target.value)}
            disabled={disabledForm}
            material={true}
            type="text"
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="via"
            name="via"
            title="Transportation Via*"
            placeholder="Transportation Via"
            value={values.via}
            error={touched.via ? errors.via : ''}
            onBlur={() => onFieldBlur('via')}
            onChange={(v) => onFieldUpdate('via', v.target.value)}
            disabled={disabledForm}
            material={true}
            type="text"
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="survey"
            name="survey"
            title="Survey*"
            placeholder="Survey"
            value={values.survey}
            error={touched.survey ? errors.survey : ''}
            onBlur={() => onFieldBlur('survey')}
            onChange={(v) => onFieldUpdate('survey', v.target.value)}
            disabled={disabledForm}
            material={true}
            type="text"
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="cargoContract"
            name="cargoContract"
            title="Cargo Contract*"
            placeholder="Cargo Contract"
            value={values.cargoContract}
            error={touched.cargoContract ? errors.cargoContract : ''}
            onBlur={() => onFieldBlur('cargoContract')}
            onChange={(v) => onFieldUpdate('cargoContract', v.target.value)}
            disabled={disabledForm}
            material={true}
            type="text"
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedChipInput
            key="sumPerShipmentCurrency"
            name="sumPerShipmentCurrency"
            title="Sum Per Shipment Currency"
            placeholder="Sum Per Shipment Currency"
            value={values.sumPerShipmentCurrency}
            error={
              touched.sumPerShipmentCurrency
                ? errors.sumPerShipmentCurrency
                : ''
            }
            onBlur={() => onFieldBlur('sumPerShipmentCurrency')}
            onChange={(v) => onFieldUpdate('sumPerShipmentCurrency', v, true)}
            disabled={disabledForm}
            selectOptions={lovs.currencies}
            multiple={false}
            required
            material
            canClearSingleValueSelection={false}
            customStyles={{ labelStyles: classes.labelHeader }}
          />

          <EnhancedCurrencyInput
            key="sumPerShipment"
            name="sumPerShipment"
            title="Sum Per Shipment"
            placeholder="Sum Per Shipment"
            currencyText={currencySymbol}
            useCurrencyText
            value={values.sumPerShipment}
            maxDecimalPercision={3}
            error={touched.sumPerShipment ? errors.sumPerShipment : ''}
            onBlur={() => onFieldBlur('sumPerShipment')}
            onChange={(v) => onFieldUpdate('sumPerShipment', v.target.value)}
            type={''}
            disabled={disabledForm}
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedCurrencyInput
            key="sumPerShipmentInPolicyCurrency"
            name="sumPerShipmentInPolicyCurrency"
            title="Sum Per Shipment In Policy Currency"
            placeholder="Sum Per Shipment In Policy Currency"
            value={values.sumPerShipmentInPolicyCurrency || 0}
            error={
              touched.sumPerShipmentInPolicyCurrency
                ? errors.sumPerShipmentInPolicyCurrency
                : ''
            }
            onChange={() => undefined}
            onBlur={() => undefined}
            disabled={disabledForm}
            material={true}
            maxDecimalPercision={3}
            useCurrencyText
            currencyText={currencySymbol}
            customStyles={{ labelStyles: classes.labelHeader }}
          />

          <EnhancedInput
            key="lcNumber"
            name="lcNumber"
            title="LC Number"
            placeholder="LC Number"
            value={values.lcNumber}
            error={touched.lcNumber ? errors.lcNumber : ''}
            onBlur={() => onFieldBlur('lcNumber')}
            onChange={(v) => onFieldUpdate('lcNumber', v.target.value)}
            disabled={disabledForm}
            material={true}
            type="text"
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedDatePicker
            key="lcIssueDate"
            name="lcIssueDate"
            title="LC Issue Date"
            placeholder="LC Issue Date"
            value={values.lcIssueDate}
            error={touched.lcIssueDate ? errors.lcIssueDate : ''}
            onDateChange={(v) => {
              (values.lcIssueDate = v.toLocaleString()),
                onFieldUpdate('lcIssueDate', v.toLocaleString(), true);
            }}
            onBlur={() => undefined}
            disabled={disabledForm}
            material={true}
            customStyles={{
              useWrapperLabel: true,
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="bank"
            name="bank"
            title="Bank"
            placeholder="Bank"
            value={values.bank}
            error={touched.bank ? errors.bank : ''}
            onBlur={() => onFieldBlur('bank')}
            onChange={(v) => onFieldUpdate('bank', v.target.value)}
            disabled={disabledForm}
            material={true}
            type="text"
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="claimsPayableBy"
            name="claimsPayableBy"
            title="Claims Payable By*"
            placeholder="Claims Payable By"
            value={values.claimsPayableBy}
            error={touched.claimsPayableBy ? errors.claimsPayableBy : ''}
            onBlur={() => onFieldBlur('claimsPayableBy')}
            onChange={(v) => onFieldUpdate('claimsPayableBy', v.target.value)}
            disabled={disabledForm}
            material={true}
            type="text"
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedPercentageInput
            key="loading"
            name="loading"
            title="Loading*"
            placeholder="Loading"
            value={values.loading}
            error={touched.loading ? errors.loading : ''}
            onBlur={() => onFieldBlur('loading')}
            onChange={(v) => onFieldUpdate('loading', v.target.value)}
            disabled={disabledForm}
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="remarks"
            name="remarks"
            title="Remarks"
            placeholder="Remarks"
            value={values.remarks}
            error={touched.remarks ? errors.remarks : ''}
            onBlur={() => onFieldBlur('remarks')}
            onChange={(v) => onFieldUpdate('remarks', v.target.value)}
            disabled={disabledForm}
            material={true}
            type="text"
            className={classes.fullFieldRow}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />
        </div>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default MarineGroupCertificateDetailsWidget;

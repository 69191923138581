import {
  IDocumentsDetails,
  IPolicyCoverID,
  IPolicyPropertyDescriptionDetails,
  IProductionDetailsSummary,
  ITravelDetailsResponse,
} from '.';
import { IDocument } from '../../../../models/file';
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterLowerOthers,
} from '../../../../utils/formatting-utils';
import { IPersonWidgetInfo } from './custom-components';
import { isEmpty } from '../../../../utils/validationUtils';
import { IGetInsuredPricingOptionTravelResponse } from '../../proposal/page/travel/index2';
import { IGetPolicyBusienssPartnerDetails } from '../../proposal/page/generic/index2';

export function GetProductionPolicyData(
  response: any
): IProductionDetailsSummary | undefined {
  const policy = response?.Production?.entities?.policy;
  const policyProperties = policy?.views?.Production_all?.properties;
  const planProperties =
    policyProperties?.PlanID?.views?.PlanConfigManagement_all?.properties;
  const policyCovers = response?.Production?.queries?.getPolicyPlanCovers;
  const bankers = policyProperties?.BBBDetailsID;
  const policyProperty =
    response?.Production?.queries?.getPolicyPropertyDetails;
  const person = response?.Production?.queries?.GetPolicyPerson;
  const marine = response?.Production?.queries?.getPolicyMarineDetails?.[0];
  const marineHullDetails =
    policyProperties?.MarineHullDetailsID?.views?.Production_all?.properties;
  const pricingOptionInfo = policy?.queries?.production?.pricingOption?.[0];
  const motorDetails =
    policyProperties?.MotorDetailsID?.views?.Production_all?.properties;
  const vehicleDetails =
    motorDetails?.VehicleID?.views?.Production_all?.properties;
  const regeneratedDocuments =
    response?.Production?.queries?.regeneratedDocuments;
  const additionalDocuments =
    response?.Production?.queries?.additionalDocuments;
  const expatDetails =
    policyProperties?.ExpatDetailsID?.views?.Production_all?.properties;
  const medical = policyProperties?.MedicalDetailsID;
  const travel: ITravelDetailsResponse = policyProperties?.TravelDetailsID;
  const insuredPricingOptionMedical =
    response?.Production?.queries?.getInsuredPricingOptionMedical || [];
  const insuredPricingOptionTravel =
    response?.Production?.queries?.getInsuredPricingOptionTravel || [];
  return {
    Id: policy?.id || '',
    TransferredToAccounting: policyProperties?.TransferredToAccounting,
    DescriptionOfRisk: policyProperties?.DescriptionOfRisk || '',
    GeoLocation: policyProperties?.GeoLocation || '',
    PolicyNumber: policyProperties?.PolicyNumber || '',
    ParentPolicyId: policyProperties?.ParentPolicyID?.id || '',
    CertificateNumber: policyProperties?.CertificateNumber || '',
    ParentPolicyNumber:
      policyProperties?.ParentPolicyID?.views?.Production_all?.properties
        ?.PolicyName || '',
    Barcode: expatDetails?.BarCodeFullCode || '',
    PolicyName: policyProperties?.PolicyName || '',
    InternalCode: policyProperties?.InternalCode || 0,
    PolicyEffectiveDate: policyProperties?.PolicyEffectiveDate || '',
    PolicyExpiryDate: policyProperties?.PolicyExpiryDate || '',
    modifiedOn: policyProperties?.modifiedOn || '',
    lastModifiedBy:
      policy?.queries.production.relatedUsers?.[0]?.modifiedBy_firstName +
      ' ' +
      policy?.queries.production.relatedUsers?.[0]?.modifiedBy_lastName,
    createdBy:
      policy?.queries?.production?.relatedUsers?.[0]?.createdBy_firstName +
      ' ' +
      policy?.queries.production.relatedUsers?.[0]?.createdBy_lastName,
    createdOn: policyProperties?.createdOn || '',
    PolicyCurrency:
      pricingOptionInfo?.pricingOption_PricingOptionCurrency || undefined,
    Status: policyProperties?.Status || '',
    StatusReason: policyProperties?.StatusReason || '',
    LineId: {
      Id: policyProperties?.LineID?.id,
      Name:
        policyProperties?.LineID?.views?.PlanConfigManagement_all?.properties
          ?.Name || '',
      ExternalCode:
        policyProperties?.LineID?.views?.PlanConfigManagement_all?.properties
          ?.ExternalCode || '',
    },
    SubLineId: {
      Id: policyProperties?.SublineID?.id,
      Name:
        policyProperties?.SublineID?.views?.PlanConfigManagement_all?.properties
          ?.Name || '',
      ExternalCode:
        policyProperties?.SublineID?.views?.PlanConfigManagement_all?.properties
          ?.ExternalCode || '',
    },
    BusinessUserID: {
      Id: policyProperties?.BusinessUserID?.id,
      Code:
        policyProperties?.BusinessUserID?.views?.SalesforceManagement_all
          ?.properties?.Code || '',
      AllowPlanCoverEdits:
        policyProperties?.BusinessUserID?.views?.SalesforceManagement_all
          ?.properties?.AllowPlanCoverEdits || false,
      AllowEndorsement:
        policyProperties?.BusinessUserID?.views?.SalesforceManagement_all
          ?.properties?.AllowEndorsement || false,
      AllowPolicyCorrection:
        policyProperties?.BusinessUserID?.views?.SalesforceManagement_all
          ?.properties?.AllowPolicyCorrection || false,
      FullName:
        policyProperties?.BusinessUserID?.views?.SalesforceManagement_all
          ?.properties?.PersonID?.views?.SalesforceManagement_all?.properties
          ?.FullName || '',
      EditableLineChargesIDs:
        policyProperties?.BusinessUserID?.views?.SalesforceManagement_all?.properties?.EditableLineChargesIDs?.map(
          (a: any) => a.views.PlanConfigManagement_all.properties.Id
        ) || [],
      AllowClauseEdits:
        policyProperties?.BusinessUserID?.views?.SalesforceManagement_all
          ?.properties?.AllowClauseEdits,
    },
    PrimaryBPID: {
      Id: policyProperties?.PrimaryBPID?.id,
      Code:
        policyProperties?.PrimaryBPID?.views?.SalesforceManagement_all
          ?.properties?.Code || '',
      FullName:
        policyProperties?.PrimaryBPID?.views?.SalesforceManagement_all
          ?.properties?.PersonID?.views?.SalesforceManagement_all?.properties
          ?.FullName || '',
      RelatedCompany:
        policyProperties?.PrimaryBPID?.views?.SalesforceManagement_all
          ?.properties?.RelatedCompany?.Title,
    },
    PlanID: {
      Id: policyProperties?.PlanID?.id || '',
      Name: planProperties?.Name || '',
      ExternalCode: planProperties?.ExternalCode || '',
      ClauseEditable: planProperties?.ClauseEditable,
      MaxNbInsured: planProperties?.MaxNbInsured,
      AcalCategory: planProperties?.AcalCategory,
      SOSService: planProperties?.SOSService,
      Scratching: planProperties?.Scratching,
      ReplacementCarValue: planProperties?.ReplacementCarValue,
      ReplacementCarDays: planProperties?.ReplacementCarDays,
      ReplacementCarApplied: planProperties?.ReplacementCarApplied,
      GRLimitDays: planProperties?.GRLimitDays,
    },
    PricingOption: {
      PolicyCost: pricingOptionInfo?.pricingOption_PolicyCost,
      PolicyCostAmount: pricingOptionInfo?.pricingOption_PolicyCostAmount,
      ChargesPercentage: pricingOptionInfo?.pricingOption_ChargesPercentage,
      TPAFeesType: pricingOptionInfo?.pricingOption_TPAFeesType,
      TPAFeesAmount: pricingOptionInfo?.pricingOption_TPAFeesAmount,
      TPAFeesPercentage: pricingOptionInfo?.pricingOption_TPAFeesPercentage,
      TPAFeesApplicableOn: pricingOptionInfo?.pricingOption_TPAFeesApplicableOn,
      BasicPremium: pricingOptionInfo?.pricingOption_BasicPremium,
      NetPremium: pricingOptionInfo?.pricingOption_NetPremium,
      PlanToPolicyRate: pricingOptionInfo?.pricingOption_PlanToPolicyRate,
      GrossPremium: pricingOptionInfo?.pricingOption_GrossPremium,
      TaxOnCommissionAmount:
        pricingOptionInfo?.pricingOption_TaxOnCommissionAmount,
      ReinsuranceTaxAmount:
        pricingOptionInfo?.pricingOption_ReinsuranceTaxAmount,
      MunicipalityTaxAmount:
        pricingOptionInfo?.pricingOption_MunicipalityTaxAmount,
      TotalPremium: pricingOptionInfo?.pricingOption_TotalPremium,
      ChargesAmount: pricingOptionInfo?.pricingOption_ChargesAmount,
      TPATotalFeesAmount: pricingOptionInfo?.pricingOption_TPAFeesTotalAmount,
      FixedStampAmount: pricingOptionInfo?.pricingOption_FixedStampAmount,
      Status: pricingOptionInfo?.pricingOption_Status,
      ProportionalStampAmount:
        pricingOptionInfo?.pricingOption_ProportionalStampAmount,
      CreatedOn: pricingOptionInfo?.pricingOption_createdOn,
      SumInsured: pricingOptionInfo?.pricingOption_SumInsured,
      PricingOptionCurrencySymbol:
        pricingOptionInfo?.pricingOption_PricingOptionCurrency?.Symbol,
    },
    NameOnLicenseAr: motorDetails?.NameOnLicenseAr,
    NameOnLicenseEn: motorDetails?.NameOnLicenseEn,
    PolicyMotor: {
      Vignette: motorDetails?.Vignette,
      VignetteCode: motorDetails?.VignetteCode,
      AgencyRepairYear: motorDetails?.AgencyRepairYear,
      DepreciationYears: motorDetails?.DepreciationYears,
    },
    Vehicle: {
      BodyType: vehicleDetails?.BodyType?.Title,
      Brand: vehicleDetails?.Make?.Title,
      BrandCode: vehicleDetails?.Make?.Code,
      CarValue: vehicleDetails?.CarValue,
      Chassis: vehicleDetails?.Chassis,
      EngineSize: vehicleDetails?.EngineSize,
      EngineType: vehicleDetails?.EngineType?.Id,
      HorsePower: vehicleDetails?.HorsePower,
      Color: vehicleDetails?.Color?.Title,
      ColorCode: vehicleDetails?.Color?.Code,
      Model: vehicleDetails?.Model?.Title,
      ModelCode: vehicleDetails?.Model?.Code,
      Motor: vehicleDetails?.Engine,
      PlateCode: vehicleDetails?.PlateCode?.Title,
      PlateNumber: vehicleDetails?.PlateNumber,
      Seats: vehicleDetails?.SeatingCapacity,
      UsageType: vehicleDetails?.UsageType.Id,
      Weight: vehicleDetails?.Weight,
      YearOfMake: vehicleDetails?.YearOfMake,
    },
    Persons: convertToPolicyPersons(response),
    Covers: policyCovers.map((cover: any) => ({
      isMain: cover.production_PolicyPlanCover_IsMain,
      isMandatory: cover.production_PolicyPlanCover_IsMandatory,
      isEditable: cover.production_PolicyPlanCover_IsEditable,
      policyCoverId: cover.planCover_PolicyCoverID?.id,
      policyCover: cover.policyCover_Name,
      coverPremiumType: cover.production_PolicyPlanCover_CoverPremiumType,
      coverPremiumPercentage:
        cover.production_PolicyPlanCover_CoverPremiumPercentage || 0,
      coverPremiumValue:
        cover.production_PolicyPlanCover_CoverPremiumValue || 0,
      currency:
        cover.production_PolicyPlanCover_ProposalID.views.Production_all
          .properties.PolicyCurrency.Code,
      coverSumInsured: cover.production_PolicyPlanCover_CoverSumInsured || 0,
      sumInsuredIsAdditive:
        cover.production_PolicyPlanCover_SumInsuredIsAdditive,
      excessOnClaimType: cover.production_PolicyPlanCover_ExcessOnClaimType,
      excessOnClaimAmount:
        cover.production_PolicyPlanCover_ExcessOnClaimAmount || 0,
      excessOnClaimPercentage:
        cover.production_PolicyPlanCover_ExcessOnClaimPercentage || 0,
      excessOnClaimDays:
        cover.production_PolicyPlanCover_ExcessOnClaimDays || 0,
      name: cover.policyCover_Name,
      clauseID: cover.production_PolicyPlanCover_ClauseID || '',
      coverEffectiveFrom: cover.production_PolicyPlanCover_CoverEffectiveFrom,
      coverEffectiveTo: cover.production_PolicyPlanCover_CoverEffectiveTo,
    })),
    Bankers: (bankers || []).map((banker: any) => ({
      branchName: banker?.views?.Production_all?.properties?.BranchName || '',
      isMain: isEmpty(banker?.views?.Production_all?.properties?.IsMain)
        ? ''
        : banker?.views?.Production_all?.properties?.IsMain
          ? 'Yes'
          : 'No',
      isAgency: isEmpty(banker?.views?.Production_all?.properties?.IsAgency)
        ? ''
        : banker?.views?.Production_all?.properties?.IsAgency
          ? 'Yes'
          : 'No',
      numberOfEmployees: Number(
        banker?.views?.Production_all?.properties?.NumberOfEmployees || 0
      ),
      underwritingYear: Number(
        banker?.views?.Production_all?.properties?.UnderwritingYear || 0
      ),
      infidelity: banker?.views?.Production_all?.properties?.Infidelity || 0,
      burglary: banker?.views?.Production_all?.properties?.Burglary || 0,
      holdUp: banker?.views?.Production_all?.properties?.HoldUp || 0,
      inSafe: banker?.views?.Production_all?.properties?.InSafe || 0,
      inTransit: banker?.views?.Production_all?.properties?.InTransit || 0,
      forgedChecks:
        banker?.views?.Production_all?.properties?.ForgedCheckes || 0,
      counterfeitCurrencies:
        banker?.views?.Production_all?.properties?.CounterFeitCurrencies || 0,
      clause6: banker?.views?.Production_all?.properties?.Clause6 || 0,
      falsification:
        banker?.views?.Production_all?.properties?.Falsification || 0,
    })),
    PropertyDescription: (() => {
      const groupedProperties: Record<
        string,
        IPolicyPropertyDescriptionDetails
      > = {};

      (policyProperty || []).forEach((property: any) => {
        const planCoverName =
          property?.production_PolicyProperty_PlanCoverID?.views
            ?.PlanConfigManagement_all?.properties?.PolicyCoverID?.views
            ?.PlanConfigManagement_all?.properties?.Name || '--';

        const planCoverDetails =
          property?.production_PolicyProperty_PlanCoverPropertyDetails || [];

        const propertyOrder = property.production_PolicyProperty_Order || 0;

        planCoverDetails.forEach((detail: any) => {
          const geoLocationCode = detail.GeoLocation?.Code || '';
          const key = geoLocationCode;

          if (!groupedProperties[key]) {
            groupedProperties[key] = {
              riskDescription: detail.RiskDescription || '',
              geoLocation: detail.GeoLocation?.Title || '',
              location: detail.Location || '',
              planCovers: [],
              order: propertyOrder,
            };
          }

          groupedProperties[key].planCovers.push({
            planCoverID: planCoverName,
            riskRate: detail.RiskRate || 0,
            sumInsured: detail.SumInsured || 0,
            premium: detail.Premium || 0,
          });
        });
      });

      const sortedProperties = Object.values(groupedProperties).sort(
        (a, b) => a.order - b.order
      );

      return sortedProperties.map(({ order, ...rest }) => rest);
    })(),

    CommissionDetails: (
      response as any
    ).Production.queries.getPolicyBusienssPartnerDetails
      .sort(
        (
          a: IGetPolicyBusienssPartnerDetails,
          b: IGetPolicyBusienssPartnerDetails
        ) => {
          const typeOrder: Record<string, number> = {
            PRIMARY: 1,
            SECONDARY: 2,
            ADDITIONAL: 3,
          };
          return (
            typeOrder[a.production_PolicyBusinessPartner_Type] -
            typeOrder[b.production_PolicyBusinessPartner_Type]
          );
        }
      )
      .map((detail: any) => ({
        BusinessUserFullName:
          detail.production_PolicyBusinessPartner_BusinessPartnerID.views
            .SalesforceManagement_all.properties.PersonID.views
            .SalesforceManagement_all.properties.FullName,
        PlanCommissionType:
          detail.production_PolicyBusinessPartner_PlanCommissionType,
        PlanCommissionAmount:
          detail.production_PolicyBusinessPartner_PlanCommissionAmount,
        PlanCommissionPercentage:
          detail.production_PolicyBusinessPartner_PlanCommissionRate,
        ApplicableOn:
          detail?.production_PolicyBusinessPartner_ApplicableOn?.Title,
        PolicyCommisisonAmount:
          detail.production_PolicyBusinessPartner_PolicyCommissionAmount,
        Id: detail.production_PolicyBusinessPartner_Id,
        Share: detail.production_PolicyBusinessPartner_CommissionPercentage,
        IsPrimary: detail.production_PolicyBusinessPartner_IsPrimary,
      })),
    regeneratedDocuments: getPolicyDocuments(regeneratedDocuments),
    additionalDocuments: getPolicyDocuments(additionalDocuments),
    ExpatDetails: {
      Continuity: expatDetails?.Continuity,
      ContinuityNumber: expatDetails?.ContinuityNumber,
      Exclusion: expatDetails?.Exclusion,
      MedicalClass: expatDetails?.MedicalClass,
      Remarks: expatDetails?.Remarks,
    },
    Marine: {
      matter: marine?.production_PolicyMarine_Matter?.Title || '',
      description: marine?.production_PolicyMarine_Description || '',
      vesselName: marine?.production_PolicyMarine_VesselName || '',
      vesselAge: marine?.production_PolicyMarine_VesselAge || '',
      from: marine?.production_PolicyMarine_DepartureFrom || '',
      to: marine?.production_PolicyMarine_DestinationTo || '',
      via: marine?.production_PolicyMarine_TransportationVia || '',
      survey: marine?.production_PolicyMarine_Survey || '',
      cargoContract: marine?.production_PolicyMarine_CargoContract || '',
      lcNumber: marine?.production_PolicyMarine_LCNumber || '',
      lcIssueDate: marine?.production_PolicyMarine_LCIssueDate || '',
      bank: marine?.production_PolicyMarine_Bank || '',
      claimsPayableBy: marine?.production_PolicyMarine_ClaimsPayableBy || '',
      loading: marine?.production_PolicyMarine_Loading || '',
      remarks: marine?.production_PolicyMarine_Remarks || '',
      sumPerShipment: marine?.production_PolicyMarine_Remarks || '',
    },
    MarineHull: {
      VesselName: marineHullDetails?.VesselName || '',
      ConnectionPort: marineHullDetails?.ConnectionPort || '',
      TerritorialWaters: marineHullDetails?.TerritorialWaters || '',
      Usage: marineHullDetails?.Usage || '',
      Builder: marineHullDetails?.Builder || '',
      ConstructionYear: marineHullDetails?.ConstructionYear || '',
      RegisteryPort: marineHullDetails?.RegisteryPort || '',
      Length: marineHullDetails?.Length || '',
      Weight: marineHullDetails?.Weight || '',
      Height: marineHullDetails?.Height || '',
      EngineType: marineHullDetails?.EngineType || '',
      EngineNumber: marineHullDetails?.EngineNumber || '',
      EngineYearBuilt: marineHullDetails?.EngineYearBuilt || '',
      EngineHorsePower: marineHullDetails?.EngineHorsePower || '',
      EngineMaxDesignedSpeed: marineHullDetails?.EngineMaxDesignedSpeed || '',
      EngineFuel: marineHullDetails?.EngineFuel || '',
      Remarks: marineHullDetails?.Remarks || '',
    },
    Medical: {
      Class: medical?.views?.Production_all?.properties?.Class?.Title || '',
      Level: medical?.views?.Production_all?.properties?.Level?.Title || '',
      Nssf: capitalizeFirstLetter(
        medical?.views?.Production_all?.properties?.Nssf || ''
      ),
      PolicyCover: medical?.views?.Production_all?.properties?.CoversID?.map(
        (item: any) => item?.views?.PlanConfigManagement_all?.properties?.Name
      ).join(', '),
    },
    Travel: {
      Destination:
        travel?.views?.Production_all?.properties?.Destination?.Title || '',
      Duration: travel?.views?.Production_all?.properties?.Duration || '',
      PolicyCover:
        travel?.views?.Production_all?.properties?.PolicyCoverID?.map(
          (item: IPolicyCoverID) =>
            item?.views?.PlanConfigManagement_all?.properties?.Name
        ).join(', '),
    },
    InsuredPricingOptionMedical: insuredPricingOptionMedical.map(
      (option: any) => ({
        PolicyPersonID: {
          isPrincipal: option?.policyPerson_IsPrincipal,
          ReferenceNumber: option?.policyPerson_ReferenceNumber || '',
          PrincipalReference: option?.policyPerson_PrincipalReference || '',
          Id: option.production_InsuredPricingOptionMedical_PolicyPersonID?.id,
          FullName:
            option.production_InsuredPricingOptionMedical_PolicyPersonID.views
              .Production_all.properties.FullName || '',
          Relation:
            option.production_InsuredPricingOptionMedical_PolicyPersonID.views
              .Production_all.properties.Relation || '',
        },
        Age: option.production_InsuredPricingOptionMedical_Age || 0,
        CoverSumInsured:
          option.production_InsuredPricingOptionMedical_CoverSumInsured || 0,
        SumInsuredIsAdditive:
          option.production_InsuredPricingOptionMedical_SumInsuredIsAdditive ||
          false,
        CoverNetPremiumValue:
          option.production_InsuredPricingOptionMedical_CoverNetPremiumValue ||
          0,
        CoverCharges:
          option.production_InsuredPricingOptionMedical_CoverCharges || 0,
        CoverTPAFees:
          option.production_InsuredPricingOptionMedical_CoverTPAFees || 0,
        CoverProportionalStamp:
          option.production_InsuredPricingOptionMedical_CoverProportionalStamp ||
          0,
        CoverMunicipalityTax:
          option.production_InsuredPricingOptionMedical_CoverMunicipalityTax ||
          0,
        CoverGrossPremium:
          option.production_InsuredPricingOptionMedical_CoverGrossPremium || 0,
        CoverTotalPremium:
          option.production_InsuredPricingOptionMedical_CoverTotalPremium || 0,
        CoverLevel:
          option?.production_InsuredPricingOptionMedical_CoverLevel?.Title ||
          '',
        CoverClass:
          option?.production_InsuredPricingOptionMedical_CoverClass?.Title ||
          '',
        CoverFixedStamp:
          option?.production_InsuredPricingOptionMedical_CoverFixedStamp || 0,
        CoverNssf:
          option?.production_InsuredPricingOptionMedical_CoverNssf || '',
        PolicyCover:
          option?.planSpecificMedical_PolicyCoverID?.views
            ?.PlanConfigManagement_all?.properties?.Name || '',
        CoverCost: option.production_InsuredPricingOptionMedical_CoverCost || 0,
      })
    ),
    InsuredPricingOptionTravel: insuredPricingOptionTravel
      .sort(
        (
          a: IGetInsuredPricingOptionTravelResponse,
          b: IGetInsuredPricingOptionTravelResponse
        ) => a.policyPerson_OrderNumber - b.policyPerson_OrderNumber
      )
      .map((option: IGetInsuredPricingOptionTravelResponse) => ({
        PolicyPersonID: {
          isPrincipal:
            option.production_InsuredPricingOptionTravel_PolicyPersonID?.views
              ?.Production_all?.properties?.IsPrincipal,
          ReferenceNumber:
            option.production_InsuredPricingOptionTravel_PolicyPersonID?.views
              ?.Production_all?.properties?.ReferenceNumber || '',
          Id: option.production_InsuredPricingOptionTravel_PolicyPersonID?.id,
          FullName:
            option.production_InsuredPricingOptionTravel_PolicyPersonID.views
              .Production_all.properties.FullName || '',
          Relation:
            option.production_InsuredPricingOptionTravel_PolicyPersonID.views
              .Production_all.properties.Relation || '',
        },
        Age: option.production_InsuredPricingOptionTravel_Age || 0,
        CoverSumInsured:
          option.production_InsuredPricingOptionTravel_CoversSumInsured || 0,
        SumInsuredIsAdditive: false,
        CoverNetPremiumValue:
          option.production_InsuredPricingOptionTravel_NetPremium || 0,
        CoverCharges: option.production_InsuredPricingOptionTravel_Charges || 0,
        CoverTPAFees: option.production_InsuredPricingOptionTravel_TPAFees || 0,
        CoverProportionalStamp:
          option.production_InsuredPricingOptionTravel_ProportionalStamp || 0,
        CoverMunicipalityTax:
          option.production_InsuredPricingOptionTravel_MunicipalityTax || 0,
        CoverGrossPremium:
          option.production_InsuredPricingOptionTravel_GrossPremium || 0,
        CoverTotalPremium:
          option.production_InsuredPricingOptionTravel_TotalPremium || 0,
      })),
  };
}

export function GetDocumentsData(response: any): IDocumentsDetails | undefined {
  const regeneratedDocuments =
    response?.Production?.queries?.regeneratedDocuments;
  const additionalDocuments =
    response?.Production?.queries?.additionalDocuments;
  return {
    regeneratedDocuments: getPolicyDocuments(regeneratedDocuments),
    additionalDocuments: getPolicyDocuments(additionalDocuments),
  };
}

function getPolicyDocuments(policyDocuments: any[]) {
  const policDoc: IDocument[] = [];
  if (policyDocuments && policyDocuments.length > 0) {
    policyDocuments.map((document) => {
      policDoc.push({
        id: document?.production_PolicyDocument_Id,
        code: document?.production_PolicyDocument_DocumentName,
        version: document?.production_PolicyDocument_Version,
        createdOn: document?.production_PolicyDocument_createdOn,
        file: document?.production_PolicyDocument_Document,
      });
    });
  }
  return policDoc;
}

export function convertToPolicyPersons(data: any) {
  const persons = data?.Production?.queries?.GetPolicyPerson || [];

  let payer: IPersonWidgetInfo = {
    name: '',
    dateOfBirth: '',
    phoneType: '',
    phoneNumber: '',
    nationalityOne: '',
    nationalityOneCode: '',
    isTaxable: '',
    occupationClass: '',
    occupationClassCode: '',
    profession: '',
    address: '',
    building: '',
    city: '',
    street: '',
    country: '',
    countryCode: '',
    zone: '',
    addressDetails: '',
    addressDetailsArabic: '',
    fullName: '',
  };
  let owner: IPersonWidgetInfo = {
    name: '',
    dateOfBirth: '',
    phoneType: '',
    phoneNumber: '',
    nationalityOne: '',
    nationalityOneCode: '',
    isTaxable: '',
    occupationClass: '',
    occupationClassCode: '',
    profession: '',
    address: '',
    building: '',
    city: '',
    street: '',
    country: '',
    countryCode: '',
    zone: '',
    addressDetails: '',
    addressDetailsArabic: '',
    fullName: '',
  };
  const insured: IPersonWidgetInfo[] = [];
  const beneficiaries: IPersonWidgetInfo[] = [];

  persons.forEach((person: any) => {
    const building = person?.production_PolicyPerson_Address?.Building;
    const street = person?.production_PolicyPerson_Address?.Street;
    const city = person?.production_PolicyPerson_Address?.City;
    const country = person?.production_PolicyPerson_Address?.Country?.Title;
    const countryCode = person?.production_PolicyPerson_Address?.Country?.Id;
    const zone = person?.production_PolicyPerson_Address?.Zone?.Title;
    const addressDetails =
      person?.production_PolicyPerson_Address?.AddressDetails;
    const addressDetailsArabic =
      person?.production_PolicyPerson_Address?.AddressDetailsArabic;
    const address = `${building ? building + ', ' : ''} ${
      street ? street + ', ' : ''
    } ${city ? city + ', ' : ''} ${country ? country : ''}`;
    const fullName = person?.production_PolicyPerson_FullName;

    const commonInfo = {
      name: `${person?.production_PolicyPerson_PersonCode} - ${fullName}`,
      dateOfBirth: person?.production_PolicyPerson_DateOfBirth,
      phoneType: capitalizeFirstLetterLowerOthers(
        person?.production_PolicyPerson_PhoneDetails?.PhoneType || ''
      ),
      phoneNumber: person?.production_PolicyPerson_PhoneDetails?.PhoneNumber,
      nationalityOne: person?.production_PolicyPerson_NationalityOne?.Title,
      nationalityOneCode: person?.production_PolicyPerson_NationalityOne?.Code,
      isTaxable: person?.production_PolicyPerson_IsTaxable,
      occupationClass:
        person?.production_PolicyPerson_OccupationClass?.Title || '',
      occupationClassCode:
        person?.production_PolicyPerson_OccupationClass?.Code || '',
      profession: person?.production_PolicyPerson_Profession || '',
      address: address,
      fullName,
      building,
      street,
      city,
      country,
      countryCode,
      zone,
      addressDetails,
      addressDetailsArabic,
    };

    switch (person?.production_PolicyPerson_Type) {
      case 'PAYER':
        payer = {
          ...commonInfo,
        };
        break;
      case 'INSURED':
        insured.push({
          ...commonInfo,
          order: person?.production_PolicyPerson_OrderNumber,
          relation: capitalizeFirstLetter(
            person?.production_PolicyPerson_Relation
          ),
          gender: capitalizeFirstLetter(person?.production_PolicyPerson_Gender),
          smoker: person?.production_PolicyPerson_Smoker,
          passportNumber: person?.production_PolicyPerson_PassportNumber,
          grLimitDays: person?.production_PolicyPerson_GRLimitDays,
          cardNumber: person?.production_PolicyPerson_CardNumber,
          continuity: capitalizeFirstLetter(
            person?.production_PolicyPerson_Continuity
          ),
          tpaReference1: person?.production_PolicyPerson_TPAReference1,
          tpaReference2: person?.production_PolicyPerson_TPAReference2,
          exclusions: person?.production_PolicyPerson_Exclusions,
          remarks: person?.production_PolicyPerson_Remarks,
          limitOfCover: person?.production_PolicyPerson_LimitOfCover,
        });
        insured.sort((a, b) => Number(a.order) - Number(b.order));
        break;
      case 'BENEFICIARY':
        beneficiaries.push({
          ...commonInfo,
          percentage: person?.production_PolicyPerson_Percentage,
        });
        break;
      case 'OWNER':
        owner = {
          ...commonInfo,
        };
        break;
    }
  });

  return {
    payer,
    owner,
    insured,
    beneficiaries,
  };
}

import React, { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import PageShell from './components/PageShell';
import ApplicationDetailsPageMotor from './forms/application-details-page-motor/ApplicationDetailsPageMotor';
import Header from './header/Header';
import HomePage from './pages/HomePage';
import AgenciesPage from './pages/agency-listing/AgenciesPage';
import AgentPage from './pages/agent-listing/AgentPage';
import ApplicationsPage from './pages/applications-listing/ApplicationsPage';
import HealthApplicationsPage from './pages/health-applications-listing/HealthApplicationsPage';
import PolicyNumberRangesPage from './pages/policy-number-ranges-listing/PolicyNumberRangesPage';
import StoreConfirmation from './store-confirmation/StoreConfirmation';
import ApplicationDetailsPageHealth from './forms/application-details-page-health/ApplicationDetailsPageHealth';
import PolicyDetailsPage from './forms/policy-details-page/PolicyDetailsPage';
import PolicyListing from './pages/policy-listing/PolicyListing';
import UsersPage from './pages/user-listing/UsersPage';
import AccountsPage from './modules/accounting/account-listing/AccountsPage';
import TransactionsPage from './modules/accounting/transaction-listing/TransactionPage';
import { useAppSelector } from './redux/hooks';
import { AGENT_LINEOFBUSINESS } from './redux/user/types';
import DownloadPage from './pages/DownloadPage';
import ExpatApplicationsPage from './pages/expat-applications-listing/ExpatApplicationsPage';
import ApplicationDetailsPageExpat from './forms/application-details-page-expat/ApplicationDetailsPageExpat';
import ExpatPolicyListing from './pages/expat-policy-listing/ExpatPolicyListing';
import ExpatPolicyDetailsPage from './forms/expat-policy-details-page/ExpatPolicyDetailsPage';
import BusinessPartnerDetailsPage from './forms/business-partner-details-page/BusinessPartnerDetailsPage';
import SalesforceBusinessPartnerDetailsPage from './forms/salesforce-business-partner-details-page/SalesforceBusinessPartnerDetailsPage';
import NssfCodeNumberRangesPage from './pages/nssfcode-number-ranges-listing/NssfCodeNumberRangesPage';
import EnvironmentSettingsPage from './forms/settings/EnvironmentSettings';
import LinesPage from './modules/plan-configuration/lines-listing/LinesPage';
import LineDetailsPage from './modules/plan-configuration/line-details-page/LineDetailsPage';
import SublineDetailsPage from './modules/plan-configuration/subline-details-page/SublineDetailsPage';
import PlansPage from './modules/plan-configuration/plans-listing/PlansPage';
import CoversPage from './pages/covers-listing/CoversPage';
import PlanDetailsPage from './modules/plan-configuration/plan-details-page/PlanDetailsPage';
import PolicyCoverDetailsPage from './forms/policy-cover-details-page/PolicyCoverDetailsPage';
import PersonsPage from './pages/persons-listing/PersonsPage';
import PersonDetailsPage from './forms/person-details-page/PersonDetailsPage';
import BusinessPartnersPage from './pages/business-partners-listing/BusinessPartnersPage';
import ClausesPage from './pages/clauses-listing/ClausesPage';
import TaxationsPage from './pages/taxations-listing/TaxationsPage';
import BusinessUsersPage from './pages/business-users-listing/BusinessUsersPage';
import SalesforceBusinessUserDetailsPage from './forms/salesforce-business-user-details-page/SalesforceBusinessUserDetailsPage';
import ProposalPage from './pages/proposal-listings/ProposalPage';
import PoliciesPage from './pages/policy-listings/PoliciesPage';
import ProductionPolicyPage from './modules/production/policy/page/ProductionPolicyPage';
import MainProposalPage from './modules/production/proposal/page/main/MainProposalPage';
import SystemCurrencyConfig from './forms/system-currency-config/SystemCurrencyConfig';
import CurrencyExchangeRatesPage from './pages/currency-exchange-rate-listing/CurrencyExchangeRatesPage';
import CurrencyExchangeRateNewPage from './forms/currency-exchange-rate-new-page/CurrencyExchangeRateNewPage';
import ProvidersPage from './pages/providers-listing/ProvidersPage';
import ProviderDetailsPage from './forms/provider-details-page/ProviderDetailsPage';
import TransfersPage from './modules/accounting/transfers-listing/TransfersListing';
import TransferLinksListing from './modules/accounting/transfer-links-listing/TransferLinksListing';
import ReceivablesPayablesPage from './modules/accounting/receivables-payables-listing/ReceivablesPayablesPage';
import InsuredPage from './modules/production/insured/listing/InsuredPage';
import InsuredDetailsPage from './modules/production/insured/page/InsuredDetailsPage';
import { isProd } from './utils/env-utils';
import ReceivablesPayablesDetailsPage from './modules/accounting/receivables-payables/page/ReceivablesPayablesDetailsPage';
import ReinsurersListingPage from './modules/reinsurance/reinsurers/reinsurers-listing-page/ReinsurersListingPage';
import TreatiesListingPage from './modules/reinsurance/treaties/treaties-listing-page/TreatiesListingPage';
import TreatyDetailsPage from './modules/reinsurance/treaties/treaty-details-page/TreatyDetailsPage';
import GroupProductionPolicyPage from './modules/production/policy/page/GroupProductionPolicyPage';
import GroupPoliciesListingPage from './modules/production/policy/group-listing/policy-listings/GroupPoliciesListingPage';
import FacultativesListingPage from './modules/reinsurance/facultatives/facultatives-listing-page/FacultativesListingPage';
import FacultativeDetailsPage from './modules/reinsurance/facultatives/facultative-details-page/FacultativeDetailsPage';
import ProdDistListingPage from './modules/reinsurance/production-distribution/prod-dist-listing-page/ProdDistListingPage';
import ProdDistDetailsPage from './modules/reinsurance/production-distribution/prod-dist-details-page/ProdDistDetailsPage';
import AmendmentDetailsPage from './modules/production/amendment-details/AmendmentDetailsPage';
import SublinesPage from './modules/plan-configuration/sublines-listing/SublinesPage';

export interface IAppModule {
  name: string;
  route: string;
  title: string;
  hidden?: boolean;
  isDropdown?: boolean;
  subModules?: Record<string, IAppModule>;
}

export interface IApplication {
  description: string;
  icon: string;
  modules: Record<string, IAppModule>;
  name: string;
  route: string;
  title: string;
  hidden?: boolean;
}
export interface IResult {
  applications?: { name?: string; route?: string; title?: string };
  healthApplications?: { name?: string; route?: string; title?: string };
  expatApplications?: { name?: string; route?: string; title?: string };
  motorPolicies?: { name?: string; route?: string; title?: string };
  expatPolicies?: { name?: string; route?: string; title?: string };
}
const Shell: React.FC = () => {
  const isPlanConfigurationEnabled = useAppSelector(
    (state) => state.app.isPlanConfigurationEnabled
  );
  const isSalesForceAppEnabled = useAppSelector(
    (state) => state.app.isSalesForceAppEnabled
  );
  const user = useAppSelector((state) => state.user);
  const cdnUrl = useAppSelector((state) => state.tenant.cdnUrl);
  const confirmation = useAppSelector((state) => state.confirmation);
  const [expatStatus, setExpatStatus] = useState();
  const [motorStatus, setMotorStatus] = useState();
  const [healthStatus, setHealthStatus] = useState();

  const defaultApplication = 'agencymanagementapplication';
  const [currentApp, setCurrentApp] = useState<string>(defaultApplication);
  const location = useLocation();
  let [homePageLink] = useState<string>('');

  const applications: Record<string, IApplication> = {};

  useEffect(() => {
    Object.values(user.linesOfBusinessStatuses).forEach(
      (lineOfBusiness: any) => {
        const code = lineOfBusiness.Code;
        const active = lineOfBusiness.Active;

        if (code === 'Expat') {
          setExpatStatus(active);
        } else if (code === 'Health') {
          setHealthStatus(active);
        } else if (code === 'Motor') {
          setMotorStatus(active);
        }
      }
    );
  }, [user]);

  const getSalesApplicationModules = () => {
    const applications = {
      name: 'Applications',
      route: '/applications/motor',
      title: 'Motor Applications',
    };
    const healthApplications = {
      name: 'HealthApplications',
      route: '/applications/health',
      title: 'Health Applications',
    };
    const expatApplications = {
      name: 'ExpatApplications',
      route: '/applications/expat',
      title: 'Expat Applications',
    };

    const roles = user.userRoles;
    const linesOfBusiness = user.info.linesOfBusiness || [];

    const hasAdminOrUnderwriterRole =
      roles.includes('Insurance-Admin') ||
      roles.includes('Insurance-Underwriter');
    const hasQuotingOrIssuingAgentRole =
      roles.includes('Insurance-QuotingAgent') ||
      roles.includes('Insurance-IssuingAgent');

    const isHealthAgent = linesOfBusiness.includes(AGENT_LINEOFBUSINESS.Health);
    const isMotorAgent = linesOfBusiness.includes(AGENT_LINEOFBUSINESS.Motor);
    const isExpatAgent = linesOfBusiness.includes(AGENT_LINEOFBUSINESS.Expat);

    const result: IResult = {};

    if (hasAdminOrUnderwriterRole) {
      if (motorStatus) result.applications = applications;
      if (healthStatus) result.healthApplications = healthApplications;
      if (expatStatus) result.expatApplications = expatApplications;
    } else if (
      hasQuotingOrIssuingAgentRole &&
      isHealthAgent &&
      isMotorAgent &&
      isExpatAgent
    ) {
      if (motorStatus) result.applications = applications;
      if (healthStatus) result.healthApplications = healthApplications;
      if (expatStatus) result.expatApplications = expatApplications;
    } else if (hasQuotingOrIssuingAgentRole && isHealthAgent && isMotorAgent) {
      if (motorStatus) result.applications = applications;
      if (healthStatus) result.healthApplications = healthApplications;
    } else if (hasQuotingOrIssuingAgentRole && isHealthAgent && isExpatAgent) {
      if (healthStatus) result.healthApplications = healthApplications;
      if (expatStatus) result.expatApplications = expatApplications;
    } else if (hasQuotingOrIssuingAgentRole && isExpatAgent && isMotorAgent) {
      if (motorStatus) result.applications = applications;
      if (expatStatus) result.expatApplications = expatApplications;
    } else if (hasQuotingOrIssuingAgentRole && isHealthAgent) {
      if (healthStatus) result.healthApplications = healthApplications;
    } else if (hasQuotingOrIssuingAgentRole && isExpatAgent) {
      if (expatStatus) result.expatApplications = expatApplications;
    } else if (hasQuotingOrIssuingAgentRole && isMotorAgent) {
      if (motorStatus) result.applications = applications;
    }

    return result;
  };

  const getPoliyManagementApplicationModules = () => {
    const roles = user.userRoles;
    const linesOfBusiness = user.info.linesOfBusiness || [];

    const result: IResult = {};

    const addMotorPolicy = () => {
      if (motorStatus) {
        result.motorPolicies = {
          name: 'Policies',
          route: '/policies',
          title: 'Motor Policies',
        };
      }
    };

    const addExpatPolicy = () => {
      if (expatStatus) {
        result.expatPolicies = {
          name: 'ExpatPolicies',
          route: '/expat-policies',
          title: 'Expat Policies',
        };
      }
    };

    if (
      roles.includes('Insurance-Admin') ||
      roles.includes('Insurance-Underwriter')
    ) {
      addMotorPolicy();
      addExpatPolicy();
    } else if (
      roles.includes('Insurance-QuotingAgent') ||
      roles.includes('Insurance-IssuingAgent')
    ) {
      if (linesOfBusiness.includes(AGENT_LINEOFBUSINESS.Motor)) {
        addMotorPolicy();
      }

      if (linesOfBusiness.includes(AGENT_LINEOFBUSINESS.Expat)) {
        addExpatPolicy();
      }
    }

    return result;
  };

  const agencymanagementapplication = {
    description: 'View and manage business partners and business users',
    icon: cdnUrl + '/icons/producers-icon-primary.svg',
    name: 'agencymanagementapplication',
    route: '/business',
    title: 'Business Partners Management',
    modules: {
      Partners: {
        name: 'Business Partners',
        route: '/partners',
        title: 'Business Partners',
      },
      Users: {
        name: 'Business Users',
        route: '/users',
        title: 'Business Users',
      },
    },
  };
  const salesapplication = {
    description: 'View and manage customers and applications',
    icon: cdnUrl + '/icons/sales-icon-primary.svg',
    name: 'salesapplication',
    route: '/sales',
    title: 'Sales',
    modules: {},
  };
  const policymanagement = {
    description: 'View and manage policies',
    icon: cdnUrl + '/icons/policymanagement-icon-primary.svg',
    name: 'policymanagement',
    route: '/plm',
    title: 'Policy Management',
    modules: {},
  };
  const adminapplication = {
    description: 'View and manage system users',
    icon: cdnUrl + '/icons/administration-icon-primary.svg',
    name: 'adminapplication',
    route: '/admin',
    title: 'Admin',
    modules: {
      Users: {
        name: 'Users',
        route: '/users',
        title: 'Users',
      },
      MotorPolicyNumberRanges: {
        name: 'MotorPolicyNumberRanges',
        route: '/policynumberranges/motor',
        title: 'Motor Policy Number Ranges',
      },
      ExpatPolicyNumberRanges: {
        name: 'ExpatPolicyNumberRanges',
        route: '/policynumberranges/expat',
        title: 'Expat Policy Number Ranges',
      },
      NssfCodeNumberRanges: {
        name: 'NssfCodeyNumberRanges',
        route: '/nssfcodenumberranges',
        title: 'Bar Code Number Ranges',
      },
    },
  };
  const planConfigurationApplication = {
    description: 'View and manage lines, sublines, and covers',
    icon: cdnUrl + '/icons/configurations-icon-primary.svg',
    name: 'planConfigurationApplication',
    route: '/plan',
    title: 'Plan Configuration',
    modules: {
      Lines: {
        name: 'Lines',
        route: '/lines',
        title: 'Lines',
      },
      Sublines: {
        name: 'Sublines',
        route: '/sublines',
        title: 'Sublines',
      },
      Plans: {
        name: 'Plans',
        route: '/plans',
        title: 'Plans',
      },
      Covers: {
        name: 'Covers',
        route: '/covers',
        title: 'Covers',
      },
      Clauses: {
        name: 'Clauses',
        route: '/clauses',
        title: 'Clauses',
      },
    },
  };
  const salesforceapplication = {
    description:
      'View and manage persons, business partners, and business users',
    icon: cdnUrl + '/icons/salesforces-icon-primary.svg',
    name: 'salesforceapplication',
    route: '/salesforce',
    title: 'Salesforce',
    modules: {
      Persons: {
        name: 'Persons',
        route: '/persons',
        title: 'Persons',
      },
      BusinessPartners: {
        name: 'BusinessPartners',
        route: '/business-partners',
        title: 'Business Partners',
      },
      BusinessUsers: {
        name: 'BusinessUsers',
        route: '/business-users',
        title: 'Business Users',
      },
    },
  };

  const settingsapplication = {
    description: '',
    icon: cdnUrl,
    name: 'settingsapplication',
    route: '/settings',
    title: 'Settings',
    hidden: true,
    modules: {
      Environment: {
        name: 'Environment',
        route: '/environment',
        title: 'Environment',
      },
    },
  };

  const accountingapplication = {
    description: 'Manage all your accounting transactions in one place',
    icon: cdnUrl + '/icons/accounting01.svg',
    name: 'accountingapplication',
    route: '/accounting',
    title: 'Accounting',
    modules: {
      AccountsAndTransactions: {
        name: 'AccountsAndTransactions',
        route: '',
        title: 'Accounts & Transactions',
        subModules: [
          {
            name: 'ChartsofAccounts',
            route: '/accounts',
            title: 'Chart of Accounts',
          },
          {
            name: 'Transactions',
            route: '/transactions',
            title: 'Transactions',
          },
        ],
      },
      // !DEPRECATED
      // SalesAndCollections: {
      //   name: "SalesAndCollections",
      //   route: "",
      //   title: "Sales & Collections",
      //   subModules: [
      //     {
      //       name: "Customers",
      //       route: "/customers",
      //       title: "Customers",
      //     },
      //     {
      //       name: "Invoices",
      //       route: "/invoices",
      //       title: "Invoices",
      //     }
      //   ]
      // },
      TransfersAndLinks: {
        name: 'TransfersAndLinks',
        route: '',
        title: 'Transfers & Links',
        subModules: [
          {
            name: 'TransferLinks',
            route: '/transfer-links',
            title: 'Transfer Links',
          },
          {
            name: 'Transfers',
            route: '/transfers',
            title: 'Transfers',
          },
        ],
      },
      SalesAndCollections: {
        name: 'ReceivablesAndPayables',
        route: '/receivables-payables',
        title: 'Receivables & Payables',
      },
    },
  };

  const configurationapplication = {
    description: 'View and manage configurations',
    icon: cdnUrl + '/icons/configuration-icon-primary.svg',
    name: 'configurationapplication',
    route: '/configuration',
    title: 'Configurations',
    modules: {
      systemCurrency: {
        name: 'systemCurrency',
        route: '/system-currency',
        title: 'System Currency',
      },
      currencyExchangeRate: {
        name: 'currencyExchangeRate',
        route: '/currency-exchange-rate',
        title: 'Currency Exchange Rate',
      },
      Taxation: {
        name: 'Taxation',
        route: '/taxation',
        title: 'Taxation',
      },
      Providers: {
        name: 'Providers',
        route: '/providers',
        title: 'Providers',
      },
    },
  };

  const productionapplication = {
    description: 'View and manage production',
    icon: cdnUrl + '/icons/administration-icon-primary.svg',
    name: 'productionapplication',
    route: '/production',
    title: 'Production',
    modules: {
      Proposal: {
        name: 'Proposal',
        route: '/proposal',
        title: 'Proposals',
      },
      Policies: {
        name: 'Policies',
        route: '/policies',
        title: 'Policies',
      },
      GroupPolicies: {
        name: 'GroupPolicies',
        route: '/group-policies',
        title: 'Group Policies',
      },
      Insured: {
        name: 'Insured',
        route: '/insured',
        title: 'Insured',
      },
    },
  };

  const reinsuraceapplication = {
    description: 'Track and manage reinsurance contracts',
    icon: cdnUrl + '/icons/reinsurance-icon-primary.png',
    name: 'reinsuranceapplication',
    route: '/reinsurance',
    title: 'Reinsurance',
    modules: {
      Reinsurers: {
        name: 'Reinsurers',
        route: '/reinsurers',
        title: 'Reinsurers',
      },
      Treaties: {
        name: 'Treaties',
        route: '/treaties',
        title: 'Treaties',
      },
      Facultatives: {
        name: 'Facultatives',
        route: '/facultatives',
        title: 'Facultatives',
      },
      ProductionDistribution: {
        name: 'Production Distribution',
        route: '/prod-dist',
        title: 'Production Distribution',
      },
    },
  };

  if (user?.userRoles?.includes('Insurance-Admin')) {
    homePageLink = '/sales/applications/motor';

    policymanagement.modules = getPoliyManagementApplicationModules();
    applications.policymanagement = policymanagement;

    salesapplication.modules = getSalesApplicationModules();
    applications.salesapplication = salesapplication;

    applications.agencymanagementapplication = agencymanagementapplication;
    applications.adminapplication = adminapplication;
    applications.settingsapplication = settingsapplication;
    if (isPlanConfigurationEnabled) {
      applications.planConfigurationApplication = planConfigurationApplication;
    }
    if (isSalesForceAppEnabled) {
      applications.salesforceapplication = salesforceapplication;
      applications.accountingapplication = accountingapplication as any;
      applications.configurationapplication = configurationapplication;
      applications.productionapplication = productionapplication;
    }
    applications.reinsuraceapplication = reinsuraceapplication;
  } else if (user?.userRoles?.includes('Insurance-IssuingAgent')) {
    if (user.info.linesOfBusiness.includes('Motor')) {
      homePageLink = '/sales/applications/motor';
    } else if (user.info.linesOfBusiness.includes('Health')) {
      homePageLink = '/sales/applications/health';
    } else if (user.info.linesOfBusiness.includes('Expat')) {
      homePageLink = '/sales/applications/expat';
    }

    policymanagement.modules = getPoliyManagementApplicationModules();
    applications.policymanagement = policymanagement;

    salesapplication.modules = getSalesApplicationModules();
    applications.salesapplication = salesapplication;
  } else if (user?.userRoles?.includes('Insurance-QuotingAgent')) {
    if (user.info.linesOfBusiness.includes('Motor')) {
      homePageLink = '/sales/applications/motor';
    } else if (user.info.linesOfBusiness.includes('Health')) {
      homePageLink = '/sales/applications/health';
    } else if (user.info.linesOfBusiness.includes('Expat')) {
      homePageLink = '/sales/applications/expat';
    }

    salesapplication.modules = getSalesApplicationModules();
    applications.salesapplication = salesapplication;
  } else if (user?.userRoles?.includes('Insurance-Underwriter')) {
    homePageLink = '/sales/applications/motor';

    salesapplication.modules = getSalesApplicationModules();
    applications.salesapplication = salesapplication;

    policymanagement.modules = getPoliyManagementApplicationModules();
    applications.policymanagement = policymanagement;
    applications.settingsapplication = settingsapplication;
  } else if (user?.userRoles?.includes('Core-BusinessUser')) {
    homePageLink = '/production/proposal';

    if (isSalesForceAppEnabled) {
      applications.productionapplication = productionapplication;
    }
  } else if (user?.userRoles?.includes('Insurance-Accountant')) {
    homePageLink = '/accounting/accounts';

    policymanagement.modules = getPoliyManagementApplicationModules();

    salesapplication.modules = getSalesApplicationModules();
    if (isSalesForceAppEnabled) {
      applications.salesforceapplication = salesforceapplication;
    }
    applications.accountingapplication = accountingapplication as any;
  }

  if (isProd()) {
    delete applications.salesforceapplication;
    delete applications.accountingapplication;
    delete applications.configurationapplication;
    delete applications.productionapplication;
    delete applications.planConfigurationApplication;
  }

  const getApplicationKeys = useCallback(
    () => Object.keys(applications),
    [applications]
  );

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    let app;
    if (pathParts.length > 1) {
      getApplicationKeys().forEach((appKey) => {
        if (applications[appKey].route === `/${pathParts[1]}`) {
          app = appKey;
        }
      });
    }
    if (!currentApp || app !== currentApp) {
      if (app) {
        setCurrentApp(app);
      } else if (getApplicationKeys().length > 0) {
        const appKey =
          defaultApplication && applications[defaultApplication]
            ? defaultApplication
            : getApplicationKeys()[0];

        setCurrentApp(appKey);
      }
    }
    // We are only concerning ourselves with the currentApp here
    // Only if the app path is wrong do we redirect the page
  }, [applications, location.pathname]);

  return (
    <>
      <PageShell
        header={<Header currentApp={currentApp} applications={applications} />}
      >
        <Routes>
          <Route path="/" element={<HomePage homePageLink={homePageLink} />} />

          <Route path="plm">
            <Route path="policies" element={<PolicyListing />} />
            <Route path="policies/:id" element={<PolicyDetailsPage />} />

            <Route path="expat-policies" element={<ExpatPolicyListing />} />
            <Route
              path="expat-policies/:id"
              element={<ExpatPolicyDetailsPage />}
            />
          </Route>

          <Route path="sales">
            <Route path="applications">
              <Route path="motor" element={<ApplicationsPage />} />
              <Route
                path="motor/:id"
                element={<ApplicationDetailsPageMotor />}
              />

              <Route path="health" element={<HealthApplicationsPage />} />
              <Route
                path="health/:id"
                element={<ApplicationDetailsPageHealth />}
              />

              <Route path="expat" element={<ExpatApplicationsPage />} />
              <Route
                path="expat/:id"
                element={<ApplicationDetailsPageExpat />}
              />
            </Route>
          </Route>

          <Route path="business">
            <Route path="partners" element={<AgenciesPage />} />
            <Route
              path="partners/:id"
              element={<BusinessPartnerDetailsPage />}
            />

            <Route path="/business/users" element={<AgentPage />} />
          </Route>

          <Route path="admin">
            <Route
              path="policynumberranges/:rangeType"
              element={<PolicyNumberRangesPage />}
            />
            <Route
              path="nssfcodenumberranges"
              element={<NssfCodeNumberRangesPage />}
            />
            <Route path="users" element={<UsersPage />} />
          </Route>

          {!isProd() && (
            <>
              <Route path="plan">
                <Route path="lines" element={<LinesPage />} />
                <Route path="lines/:id" element={<LineDetailsPage />} />

                <Route path="sublines" element={<SublinesPage />} />
                <Route path="sublines/:id" element={<SublineDetailsPage />} />

                <Route path="plans" element={<PlansPage />} />
                <Route path="plans/:id" element={<PlanDetailsPage />} />

                <Route path="covers" element={<CoversPage />} />
                <Route path="covers/:id" element={<PolicyCoverDetailsPage />} />

                <Route path="clauses" element={<ClausesPage />} />
              </Route>

              <Route path="salesforce">
                <Route path="persons" element={<PersonsPage />} />
                <Route path="persons/:id" element={<PersonDetailsPage />} />

                <Route
                  path="business-partners"
                  element={<BusinessPartnersPage />}
                />
                <Route
                  path="business-partners/:id"
                  element={<SalesforceBusinessPartnerDetailsPage />}
                />

                <Route path="business-users" element={<BusinessUsersPage />} />
                <Route
                  path="business-users/:id"
                  element={<SalesforceBusinessUserDetailsPage />}
                />
              </Route>

              <Route path="accounting">
                <Route path="accounts" element={<AccountsPage />} />
                <Route path="transactions" element={<TransactionsPage />} />

                <Route
                  path="transfer-links"
                  element={<TransferLinksListing />}
                />
                <Route path="transfers" element={<TransfersPage />} />

                <Route
                  path="receivables-payables"
                  element={<ReceivablesPayablesPage />}
                />
                <Route
                  path="receivables-payables/:id"
                  element={<ReceivablesPayablesDetailsPage />}
                />

                {/* 
                //! DEPRECATED
                <Route path="customers" element={<CustomersPage />} />
                <Route path="products" element={<ProductsPage />} />
                <Route path="invoices">
                  <Route path="" element={<InvoicePage />}>
                    <Route path=":id" element={<InvoiceDetailsPage />} />
                  </Route>
                </Route>
                */}
              </Route>

              <Route path="configuration">
                <Route
                  path="system-currency"
                  element={<SystemCurrencyConfig />}
                />
                <Route
                  path="currency-exchange-rate"
                  element={<CurrencyExchangeRatesPage />}
                />
                <Route
                  path="currency-exchange-rate/add-ons"
                  element={<CurrencyExchangeRateNewPage />}
                />
                <Route path="taxation" element={<TaxationsPage />} />
                <Route path="providers" element={<ProvidersPage />} />
                <Route path="providers/:id" element={<ProviderDetailsPage />} />
              </Route>

              <Route path="/production">
                <Route path="proposal" element={<ProposalPage />} />
                <Route path="proposal/:id" element={<MainProposalPage />} />

                <Route path="policies" element={<PoliciesPage />} />
                <Route path="policies/:id" element={<ProductionPolicyPage />} />

                <Route
                  path="amendment/:id"
                  element={<AmendmentDetailsPage />}
                />

                <Route
                  path="group-policies"
                  element={<GroupPoliciesListingPage />}
                />
                <Route
                  path="group-policies/:id"
                  element={<GroupProductionPolicyPage />}
                />

                <Route path="insured" element={<InsuredPage />} />
                <Route path="insured/:id" element={<InsuredDetailsPage />} />
              </Route>

              <Route path="/reinsurance">
                <Route path="reinsurers" element={<ReinsurersListingPage />} />

                <Route path="treaties" element={<TreatiesListingPage />} />
                <Route path="treaties/:id" element={<TreatyDetailsPage />} />

                <Route
                  path="facultatives"
                  element={<FacultativesListingPage />}
                />
                <Route
                  path="facultatives/:id"
                  element={<FacultativeDetailsPage />}
                />

                <Route path="prod-dist" element={<ProdDistListingPage />} />
                <Route path="prod-dist/:id" element={<ProdDistDetailsPage />} />
              </Route>
            </>
          )}

          <Route path="/download-document" element={<DownloadPage />} />
          <Route
            path="/settings/environment"
            element={<EnvironmentSettingsPage />}
          />
        </Routes>
      </PageShell>
      <StoreConfirmation confirmation={confirmation} />
    </>
  );
};

export default Shell;

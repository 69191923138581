import { IFacDetails, IFacErrors } from '.';
import { DynamicFormInputType, FormInputTypes } from '../../../../DynamicForm';

export const initialFacValues: {
  values: IFacDetails;
  errors: IFacErrors;
} = {
  values: {
    createdBy: '',
    createdOn: '',
    modifiedBy: '',
    modifiedOn: '',
    status: '',
    linkedTo: '',
    facId: '',
    slipRefNo: '',
    proposal: '',
    policy: '',
    paymentDueDate: '',
    totalCededSumInsured: 0,
    totalCededPremium: 0,
    totalCommission: 0,
    covers: [
      {
        coverId: '',
        effectiveFrom: '',
        effectiveTo: '',
        currencyCode: '',
        currencySymbol: '',
        cededSumInsured: '',
        cededPremium: '',
        facultativeRate: '',
        commissionRate: '',
        commissionAmount: '',
        sumInsuredIsAdditive: false,
        maxCededSumInsured: 0,
        maxCededPremium: 0,
        maxSumInsured: 0,
        maxPremium: 0,
      },
    ],
  },
  errors: {
    facId: '',
    slipRefNo: '',
    proposal: '',
    policy: '',
    paymentDueDate: '',
    totalCededSumInsured: '',
    totalCededPremium: '',
    totalCommission: '',
    covers: [
      {
        coverId: '',
        effectiveFrom: '',
        effectiveTo: '',
        currencyCode: '',
        currencySymbol: '',
        cededSumInsured: '',
        cededPremium: '',
        facultativeRate: '',
        commissionRate: '',
        commissionAmount: '',
        sumInsuredIsAdditive: '',
      },
    ],
  },
};

export const updateFacReinsurerInputs = (values: {
  reinsurer: string;
  share: number;
}): Record<string, DynamicFormInputType> => ({
  reinsurer: {
    name: 'reinsurer',
    title: 'Reinsurer',
    placeholder: 'Type to search reinsurers',
    type: FormInputTypes.newautocomplete,
    value: values.reinsurer,
    required: true,
  },
  share: {
    name: 'share',
    title: 'Share %',
    type: FormInputTypes.percentage,
    value: values.share,
    disabled: true,
    required: true,
  },
});

export const addFacDocumentDrawerInputs: Record<string, DynamicFormInputType> =
  {
    documentName: {
      name: 'documentName',
      title: 'Document Name',
      placeholder: 'Enter document name',
      type: FormInputTypes.text,
      value: '',
      required: true,
    },
    document: {
      name: 'document',
      title: 'Document',
      type: FormInputTypes.fileuploader,
      value: '',
      required: true,
      disabled: false,
    },
  };

export const validationRules = {
  facId: true,
  slipRefNo: true,
  proposal: true,
  policy: true,
  paymentDueDate: true,
  totalCededSumInsured: true,
  totalCededPremium: true,
  totalCommission: true,
  covers: {
    coverId: true,
    currencyCode: true,
    currencySymbol: true,
    cededSumInsured: true,
    cededPremium: true,
    facultativeRate: true,
    commissionRate: true,
    commissionAmount: true,
  },
};

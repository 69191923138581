import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { IListingData } from '../../../models/listing';
import StaticLayout from '../../../page-layout/static-layout/StaticLayout';
import { linesQuery, listQuery, sublinesQuery } from './queries';
import { linesLovs, sublinesLovs, mapToListingData } from './utils';
import { filterSectionsContent, headers } from './content';
import { IListingFilterWidgetSection } from '../../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../../models';
import { getFilter, setFilter } from '../../../utils/filter-utils';
import { DEFAULT_ERROR_TEXT } from '../../../constants';
import { capitalizeFirstCharacter } from '../../../utils/formatting-utils';
import ListingWidget from '../../../components/form-fields/listing-widget/ListingWidget';
import { IFilterModel } from '../../../components/form-fields/listing-widget';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import DateService from '../../../services/dateService';

const PAGE_CONTEXT = 'Plans';
const FILTER_SESSION_KEY = 'plansFilter';

const PlansPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });
  const filterLovs = useRef<{
    line: Record<string, string>;
    subline: Record<string, string>;
    status: Record<string, string>;
  }>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          line: [],
          subline: [],
          SelectedStatuses: [],
          createdOn: [],
        };

  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialfilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
  });

  const [planListQuery] = useLazyQuery(listQuery());
  const [linesListQuery] = useLazyQuery(linesQuery());
  const [sublinesListQuery] = useLazyQuery(sublinesQuery());

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = () => {
    Promise.all([
      linesListQuery(),
      sublinesListQuery({
        variables: {
          SelectedLineId: filterValues.namedFilters.line,
        },
      }),
    ]).then(([{ data: lines }, { data: sublines }]) => {
      const lovs = {
        ...linesLovs(lines),
        ...sublinesLovs(sublines),
      };
      filterLovs.current = {
        line: lovs.line,
        subline: lovs.subline,
        status: lovs.status,
      };
      const newFilterSections = filterSectionsContent(
        lovs,
        filterValues.namedFilters
      );
      setFilterSections(newFilterSections);
    });
  };

  const getListingData = (
    filter = filterValues,
    newFilterLovs?: IAbstractRecord
  ) => {
    if (!filter) {
      setLoading(false);
      return {};
    }

    setFilter(filter, FILTER_SESSION_KEY);
    setFilterValues(filter);

    const filterV = filter.namedFilters;
    const pagination = filter.pagination;

    setLoading(true);

    return planListQuery({
      variables: {
        keywordSearch: filter.searchKey,
        currentPage: pagination.pageNumber || 1,
        currentPageSize: pagination.pageSize || 10,
        Attribute:
          capitalizeFirstCharacter(filter.orderBy) ||
          'PlanConfigManagement_Plan_Name',
        Descending: filter.descending,
        SelectedLineId: filterV?.line?.length ? filterV.line : [],
        SelectedSublineID: filterV?.subline?.length ? filterV.subline : [],
        SelectedStatuses: filterV?.SelectedStatuses?.length
          ? filterV.SelectedStatuses
          : null,
        FromCreatedOn: DateService.formatDateBackend(filterV.createdOn?.[0]),
        ToCreatedOn: DateService.formatDateBackend(filterV.createdOn?.[1]),
      },
    })
      .then(({ data }) => {
        if (data) {
          const newTableData = mapToListingData(data);
          setTableData(newTableData);
          const newLovs = {
            ...filterLovs.current,
            ...newFilterLovs,
          };
          filterLovs.current = newLovs;
          const newFilterSections = filterSectionsContent(
            filterLovs.current,
            filterV
          );
          setFilterSections(newFilterSections);
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = async (
    page: number,
    filterModel = filterValues
  ): Promise<void> => {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  };

  const onFilterUpdate = (filters: IAbstractRecord, inputName?: string) => {
    const newFilters = {
      ...filterValues,
      namedFilters: filters,
    };
    if (_.isEqual(newFilters, filterValues)) {
      // Do nothing if filters are the same
      return;
    }

    if (inputName === 'line') {
      sublinesListQuery({
        variables: {
          SelectedLineId: filters.line,
        },
      }).then(({ data }) => {
        const lovs = sublinesLovs(data);
        getListingData(newFilters, lovs);
      });
    } else {
      getListingData(newFilters);
    }
  };

  const renderMainChildren = () => (
    <ListingWidget
      title="Plans"
      name="plans"
      orderByAscendingByDefault
      data={tableData}
      tableSettings={{
        headers,
      }}
      loading={loading}
      defaultOrderByColumn="planConfigManagement_Plan_Name"
      pageContext={PAGE_CONTEXT}
      onPageChange={(filterModel) => {
        const newFilterModel = {
          ...filterValues,
          ...filterModel,
        };
        const page = filterModel.pagination.pageNumber;
        return handlePageChange(page, newFilterModel);
      }}
      actions={[]}
      disableSelection
      usePagination
    />
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        disabled={loading}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  return (
    <StaticLayout
      name="Plans"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default PlansPage;

import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../../components/enhanced-table';

export const getHeaders = (
  isGroup = false
): Record<string, EnhancedTableHeader> => ({
  production_Amendment_AmendmentNumber: {
    name: 'production_Amendment_AmendmentNumber',
    title: isGroup ? 'Number' : 'Reference Number',
    type: EnhancedTableHeaderType.Link,
    urlTemplate: '/production/amendment/{id}',
    openUrlInNewTab: false,
  },
  production_Amendment_AmendmentType: {
    name: 'production_Amendment_AmendmentType',
    title: 'Type',
    type: EnhancedTableHeaderType.Text,
  },
  production_Amendment_AmendmentSubType: {
    name: 'production_Amendment_AmendmentSubType',
    title: 'Sub Type',
    type: EnhancedTableHeaderType.Text,
  },
  production_Amendment_AmendmentEffectiveDate: {
    name: 'production_Amendment_AmendmentEffectiveDate',
    title: 'Effective Date',
    type: EnhancedTableHeaderType.Date,
  },
  production_Amendment_AmendmentStatus: {
    name: 'production_Amendment_AmendmentStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Classification,
    classificationPropertyName: 'StatusColor',
  },
});

import { IAssignPolicyCoverInfo } from '.';
import PrecisionService from '../../services/precisionService';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const enums: Record<string, Record<string, string>> = {};

  enums['coverPremiumTypes'] = enumListAsRecordObject(
    data?.Core_CoverPremiumTypes?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );
  enums['excessOnClaimTypes'] = enumListAsRecordObject(
    data?.Core_ExcessOnClaimTypes?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  enums['currencies'] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  return enums;
}

export function extractPolicyCovers(data: any) {
  const policyCovers: Record<string, string> = {};

  data?.PlanConfigManagement?.queries?.allPolicyCoverList?.forEach(
    (element: {
      planConfigManagement_PolicyCover_Id: string;
      planConfigManagement_PolicyCover_ExternalCode: string;
      planConfigManagement_PolicyCover_Name: string;
    }) => {
      policyCovers[element.planConfigManagement_PolicyCover_Id] =
        `${element.planConfigManagement_PolicyCover_ExternalCode} - ${element.planConfigManagement_PolicyCover_Name}`;
    }
  );

  return policyCovers;
}

export function extractClauses(data: any) {
  const clauses: Record<string, string> = {};

  data?.PlanConfigManagement?.queries?.allClauseList?.forEach(
    (element: {
      planConfigManagement_Clause_Id: string;
      planConfigManagement_Clause_ExternalCode: string;
      planConfigManagement_Clause_Name: string;
    }) => {
      clauses[element.planConfigManagement_Clause_Id] =
        `${element.planConfigManagement_Clause_ExternalCode} - ${element.planConfigManagement_Clause_Name}`;
    }
  );

  return clauses;
}

export function mapToAssignPolicyCoverInfo(data: any): IAssignPolicyCoverInfo {
  const assignedPolicyCovers =
    data?.PlanConfigManagement?.entities?.planCover?.views
      ?.PlanConfigManagement_all?.properties;

  return {
    clauseReferenceTitle: `${assignedPolicyCovers?.ClauseID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${assignedPolicyCovers?.ClauseID?.views?.PlanConfigManagement_all?.properties?.Name}`,
    policyCover: assignedPolicyCovers?.PolicyCoverID?.id,
    policyCoverTitle: `${assignedPolicyCovers?.PolicyCoverID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${assignedPolicyCovers?.PolicyCoverID?.views?.PlanConfigManagement_all?.properties?.Name}`,
    coverPremiumPercentage: `${PrecisionService.multiplyBy100(
      assignedPolicyCovers.CoverPremiumPercentage
    )}`,
    coverPremiumType: assignedPolicyCovers?.CoverPremiumType,
    coverPremiumValue: assignedPolicyCovers?.CoverPremiumValue,
    coverSumInsured: assignedPolicyCovers?.CoverSumInsured,
    excessOnClaimAmount: assignedPolicyCovers?.ExcessOnClaimAmount,
    excessOnClaimDays: assignedPolicyCovers?.ExcessOnClaimDays,
    additiveSumInsured: assignedPolicyCovers?.SumInsuredIsAdditive,
    main: assignedPolicyCovers?.IsMain,
    mandatory: assignedPolicyCovers?.IsMandatory,
    editable: assignedPolicyCovers?.IsEditable,
    excessOnClaimType: assignedPolicyCovers?.ExcessOnClaimType,
    excessOnClaimPercentage: `${PrecisionService.multiplyBy100(
      assignedPolicyCovers.ExcessOnClaimPercentage
    )}`,
    clauseReference: assignedPolicyCovers?.ClauseID?.id,
  };
}

import React, { useState } from 'react';
import { IListingData } from '../../../models/listing';
import StaticLayout from '../../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { listQuery, GetTransactionsAuditTrails } from './queries';
import {
  mapToListingData,
  toLookupsData,
  convertAuditTrailsToActivities,
} from './utils';
import { filterSectionsContent, headers } from './content';
import { IListingFilterWidgetSection } from '../../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../../models';
import { getFilter, setFilter } from '../../../utils/filter-utils';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import { IEnhanceTableHeaderClickable } from '../../../components/enhanced-table';
import { toast } from 'react-toastify';
import CustomActivities from '../../../activities/CustomActivities';
import { DEFAULT_ERROR_TEXT, MAIN_ONE_THEME } from '../../../constants';
import AccountDrawer from '../../../forms/account-drawer/AccountDrawer';
import AccountImportDrawer from '../../../forms/account-import-drawer/AccountImportDrawer';
import { IActivityEntityBase } from '../../../activities';
import TransactionPopUpShell from '../../../forms/transaction-popup/TransactionPopUpShell';
import ListingWidget from '../../../components/form-fields/listing-widget/ListingWidget';
import { IFilterModel } from '../../../components/form-fields/listing-widget';
import { IEnhancedMenuItem } from '../../../components/form-fields/table';
import ExportTransactionsDrawer from './export-transactions/ExportTransactionsDrawer';
import DateService from '../../../services/dateService';

const PAGE_CONTEXT = 'Transactions';
const FILTER_SESSION_KEY = 'transactionsFilter';

interface ITransactionPageAuditTrailInfo {
  title: string;
  items: IActivityEntityBase[];
  loading: boolean;
}

const TransactionsPage: React.FC = () => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [accountDrawerOpen, setAccountDrawerOpen] = useState<boolean>(false);
  const [transactionsPopupOpen, setTransactionsPopupOpen] =
    useState<boolean>(false);
  const [exportTransactionsDrawer, setExportTransactionsDrawer] =
    useState<boolean>(false);
  const [accountImportDrawerOpen, setAccountImportDrawerOpen] =
    useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>('');
  const [auditTrailInfo, setAuditTrailInfo] =
    useState<ITransactionPageAuditTrailInfo>();
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const [tableData, setTableData] = useState<
    IListingData & {
      lovs: Record<string, Record<string, string>>;
    }
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
    lovs: {},
  });
  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          createdOn: [],
          company: '41',
          account: [],
          type: [],
        };
  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialfilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
  });

  const actions: IEnhancedMenuItem[] = [
    {
      name: 'newtranaction',
      title: '+ New',
      isPrimary: true,
      onClick: () => {
        setChosenEditId(undefined);
        setTransactionsPopupOpen(true);
      },
    },
    {
      name: 'exporttransactions',
      title: 'Export Transactions',
      onClick: () => {
        setExportTransactionsDrawer(true);
      },
    },
  ];

  const [getTransactionsAuditTrails] = useLazyQuery(
    GetTransactionsAuditTrails()
  );
  const [getTransactionsLazy] = useLazyQuery(listQuery());

  const getVariables = (filterModel: IFilterModel) => {
    const filterValues = filterModel.namedFilters;
    const pagination = filterModel.pagination;

    if (!filterValues) return {};

    return {
      SelectedCompany:
        filterValues.company && filterValues.company?.length > 0
          ? filterValues?.company
          : '41',
      SelectedType:
        filterValues.type && filterValues.type?.length > 0
          ? filterValues?.type
          : null,
      CreatedOnFromDate: DateService.formatDateBackend(
        filterValues?.createdOn?.[0]
      ),
      CreatedOnToDate: DateService.formatDateBackend(
        DateService.addDays(filterValues?.createdOn?.[1], 1)
      ),
      SelectedAccounts: filterValues.account ? filterValues.account : null,
      KeywordSearch: filterModel.searchKey,
      OrderByField: filterModel.orderBy,
      DescendingField: filterModel.descending,
      pageNumber: pagination.pageNumber || 1,
      pageSize: pagination.pageSize || 10,
    };
  };

  const viewHistoryClick = async (id: string, title: string) => {
    setAuditTrailInfo({
      items: [],
      loading: true,
      title: title,
    });

    const result = await getTransactionsAuditTrails({
      variables: {
        id,
      },
    });

    if (result.data) {
      setAuditTrailInfo({
        items: convertAuditTrailsToActivities(result.data),
        loading: false,
        title,
      });
    }
  };

  const getListingData = (filter = filterValues) => {
    if (!filter) {
      setLoadingState(false);
      return {};
    }

    setLoadingState(true);
    const variables = getVariables(filter);
    return getTransactionsLazy({
      variables,
    })
      .then(({ data }) => {
        if (data) {
          const lovs = toLookupsData(data);

          if (!Object.keys(lovs.account).length) {
            lovs.account = filter.namedFilters.account;
          }

          if (!Object.keys(lovs.company).length) {
            lovs.company = filter.namedFilters.company;
          }

          const newFilterSections = filterSectionsContent(
            lovs,
            filter.namedFilters
          );
          setFilterSections(newFilterSections);
          setFilterValues(filter);

          const tableData = mapToListingData(data, lovs);

          setTableData({
            ...tableData,
            pageNumber: tableData.pageNumber,
            pageSize: tableData.pageSize,
            totalCount: tableData.totalCount,
            lovs,
          });
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      })
      .finally(() => {
        setLoadingState(false);
      });
  };

  function handlePageChange(
    page: number,
    filterModel = filterValues
  ): Promise<void> {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  }

  (
    headers.Accounting_Transactions_TransactionNumber as IEnhanceTableHeaderClickable
  ).callback = (payload: IAbstractRecord) => {
    setChosenEditId(payload.columns.Accounting_Transactions_Id);
    setTransactionsPopupOpen(true);
  };

  (headers.viewHistory as IEnhanceTableHeaderClickable).callback = (
    payload: IAbstractRecord
  ) => {
    const id = payload.columns.Accounting_Transactions_Id;
    viewHistoryClick(
      id,
      payload.columns.Accounting_Transactions_TransactionNumber
    );
  };

  const renderMainChildren = () => {
    return (
      <>
        <ListingWidget
          name="transactions"
          title="Transactions"
          orderByAscendingByDefault
          loading={loadingState}
          data={tableData}
          tableSettings={{
            headers,
          }}
          actions={actions}
          pageContext={PAGE_CONTEXT}
          onPageChange={(filterModel) => {
            const newFilterModel = {
              ...filterValues,
              ...filterModel,
            };
            const page = filterModel.pagination.pageNumber;
            return handlePageChange(page, newFilterModel);
          }}
          disableSelection
          usePagination={true}
        />
        {accountDrawerOpen && (
          <AccountDrawer
            open={accountDrawerOpen}
            onClose={() => setAccountDrawerOpen(false)}
            onSuccess={() => {
              handlePageChange(1);
            }}
            accountId={chosenEditId}
            accountInfo={undefined}
          />
        )}
        {accountImportDrawerOpen && (
          <AccountImportDrawer
            open={accountImportDrawerOpen}
            onClose={() => setAccountImportDrawerOpen(false)}
            onSuccess={() => {
              handlePageChange(1);
            }}
          />
        )}
        {transactionsPopupOpen && (
          <TransactionPopUpShell
            open={transactionsPopupOpen}
            onClose={() => setTransactionsPopupOpen(false)}
            transactionId={chosenEditId}
            onSuccess={() => {
              getListingData();
            }}
          />
        )}
        {exportTransactionsDrawer && (
          <ExportTransactionsDrawer
            open={exportTransactionsDrawer}
            onClose={() => setExportTransactionsDrawer(false)}
            data={{
              relatedCompany: tableData.lovs.company,
            }}
            onSuccess={() => {
              setExportTransactionsDrawer(false);
            }}
          />
        )}
      </>
    );
  };

  const onFilterUpdate = async (filters: IAbstractRecord) => {
    const newFilters = {
      ...filterValues,
      namedFilters: filters,
    };
    setFilter(filters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    getListingData(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={''}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      name={'Transactions'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
      rightChildren={
        <CustomActivities
          loader={auditTrailInfo?.loading || false}
          title={auditTrailInfo?.title || ''}
          items={auditTrailInfo?.items || []}
        />
      }
      onRightCollapseClick={() => {
        if (auditTrailInfo) {
          setAuditTrailInfo(undefined);
        }
      }}
      config={{
        leftColumn: {
          backgroundColor: '#FFFFFF',
          collapsable: false,
          collapsed: false,
          width: 2,
        },
        mainColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: false,
          collapsed: false,
          width: auditTrailInfo ? 7 : 10,
        },
        rightColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: true,
          collapsed: !auditTrailInfo,
          width: auditTrailInfo ? 3 : 0,
        },
      }}
    />
  );
};
export default TransactionsPage;

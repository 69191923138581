import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import { lookupListAsRecordObject } from '../../utils/graph-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Insurance.queries.listApplicationDetailsMotor.items.forEach(
    (obj: IGenericApplicationTableData, i: number) => {
      list[i] = {
        id: obj.insurance_Application_Id,
        name: obj.insurance_Application_ApplicationName,
        applicationContactDetails: obj.insuranceCustomer_FullName,
        policyNumber: obj.insurance_Application_Policies?.map((element) => ({
          id: element?.EntityId,
          title: element?.Title,
        })),
        plateCode: obj.insuranceApplicationMotorDetails_PlateCode?.Title,
        plateNumber: obj.insuranceApplicationMotorDetails_PlateNumber,
        plan:
          obj.insurance_Application_Status.toLowerCase() === 'new'
            ? ''
            : obj.insurance_Application_PlanCount,
        agency: obj.insuranceAgency_AgencyName,
        agent: obj.insuranceAgent_FirstName + ' ' + obj.insuranceAgent_LastName,
        effectiveDate: obj.insurance_Application_EffectiveDate,
        expiryDate: obj.insurance_Application_ExpiryDate,
        createdOn: obj.insurance_Application_createdOn,
        modifiedOn: obj.insurance_Application_modifiedOn,
        status: capitalizeFirstLetter(
          obj.insurance_Application_Status?.replaceAll('_', ' ')
        ),
        statusReason: capitalizeFirstLetter(
          obj.insurance_Application_StatusReason?.replaceAll('_', ' ')
        ),
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data.Insurance.queries.listApplicationDetailsMotor?.paging?.pageSize,
    pageNumber:
      data.Insurance.queries.listApplicationDetailsMotor?.paging?.pageNumber,
    totalCount:
      data.Insurance.queries.listApplicationDetailsMotor?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    agency: {},
    plan: {},
    status: {},
  };

  lovs.plan = lookupListAsRecordObject(data?.Insurance?.lookups?.products);

  data?.Insurance?.queries?.allAgencies?.forEach(
    (obj: {
      insurance_Agency_Id: string;
      insurance_Agency_AgencyName: string;
    }) => {
      lovs.agency[obj.insurance_Agency_Id] = obj.insurance_Agency_AgencyName;
    }
  );

  data?.Insurance_ApplicationStatuses?.enumValues?.forEach(
    (obj: { name: string }) => {
      lovs.status[obj.name] = capitalizeFirstLetter(
        obj.name?.replaceAll('_', ' ')
      );
    }
  );

  return lovs;
}

import { ITreatyDetailsResponse, ITreatyLovs } from '.';
import { IAbstractRecord } from '../../../../models';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../../../utils/graph-utils';
import {
  ITreatyDetails,
  ITreatyErrors,
  ITreatyLayer,
  ITreatyLayerAndCategoryResponse,
  ITreatyLayerCategory,
} from '.';
import { initialTreatyValues, validationRules } from './content';
import { cloneDeep } from 'lodash';
import { formatEnum } from '../../../../utils/formatting-utils';
import { IActivityEntityBase } from '../../../../activities';

export function toLookupsData(data: IAbstractRecord) {
  const lovs: ITreatyLovs = {
    currency: {},
    treatyType: {},
    treatySubType: {
      proportional: {},
      non_proportional: {},
    },
    category: {},
  };

  lovs.currency = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies,
    false,
    'Code',
    'Symbol'
  );

  lovs.treatyType = enumListAsRecordObject(
    data?.Production_TreatyType?.enumValues
  );

  const categoriesList: Record<string, string> = {};
  data?.PlanConfigManagement_SublineCategoryList?.enumValues?.forEach(
    (element: { __typename: string; name: string }) => {
      if (element.name) {
        categoriesList[element.name] = formatEnum(element.name);
      }
    }
  );

  lovs.category = categoriesList;

  data?.Production?.lookups?.treatySubType?.forEach((item: IAbstractRecord) => {
    if (item?.TreatyType?.toLowerCase() === 'proportional') {
      lovs.treatySubType.proportional[item.Code] = item.Title;
    } else if (item?.TreatyType?.toLowerCase() === 'non-proportional') {
      lovs.treatySubType.non_proportional[item.Code] = item.Title;
    }
  });

  return lovs;
}

export const initializeErrors = (values: ITreatyDetails): ITreatyErrors => {
  const errors: ITreatyErrors = {
    treatyName: '',
    treatyType: '',
    effectiveDate: '',
    expiryDate: '',
    currency: '',
    underwritingLimit: '',
    claimAdviseLimit: '',
    substandardLimit: '',
    layers: {},
  };

  for (const layerKey in values.layers) {
    const layer = values.layers[layerKey];
    errors.layers[layerKey] = {
      treatySubType: '',
      retentionShare: '',
      paymentDate: '',
      paymentAmount: '',
      totalTreatyPremium: '',
      totalExpectedPremium: '',
      premiumRate: '',
      categories: [],
    };

    layer.categories.forEach(() => {
      errors.layers[layerKey].categories.push({
        category: '',
        minimumLayerLimit: '',
        maximumLayerLimit: '',
        retentionLimit: '',
        priorityLimit: '',
        liabilityLimit: '',
        aggregateLimit: '',
        commissionRate: '',
      });
    });
  }

  return errors;
};

export const mapToWidgetValues = (
  treatyData: ITreatyDetailsResponse,
  layersData: ITreatyLayerAndCategoryResponse[]
): ITreatyDetails => {
  let layers: Record<string, ITreatyLayer> = {};

  if (layersData && layersData.length > 0) {
    // Group layers data by treaty layer id
    const groupedLayers = layersData.reduce(
      (acc, item) => {
        const id = item.production_TreatyLayer_Id;
        if (!acc[id]) {
          acc[id] = [];
        }
        acc[id].push(item);
        return acc;
      },
      {} as { [key: string]: ITreatyLayerAndCategoryResponse[] }
    );

    // Sort layers by treaty layer order
    const layersArray = Object.values(groupedLayers)
      .map((layerData) => ({
        layerOrder: layerData[0].production_TreatyLayer_TreatyLayerOrder,
        layerData,
      }))
      .sort((a, b) => a.layerOrder - b.layerOrder);

    layers = layersArray.reduce(
      (acc, layer, index) => {
        const firstItem = layer.layerData[0];

        // Map categories and sort them by category name
        const sortedCategories: ITreatyLayerCategory[] = layer.layerData
          .slice()
          .sort((a, b) =>
            a.treatyLayerCategory_Category.localeCompare(
              b.treatyLayerCategory_Category
            )
          )
          .map((category) => ({
            category: category.treatyLayerCategory_Category,
            aggregateLimit:
              category.treatyLayerCategory_AggregateLimit?.toString(),
            commissionRate:
              category.treatyLayerCategory_CommissionRate?.toString(),
            liabilityLimit:
              category.treatyLayerCategory_LiabilityLimit?.toString(),
            maximumLayerLimit:
              category.treatyLayerCategory_MaximumLayerLimit?.toString(),
            minimumLayerLimit:
              category.treatyLayerCategory_MinimumLayerLimit?.toString(),
            priorityLimit:
              category.treatyLayerCategory_PriorityLimit?.toString(),
            retentionLimit:
              category.treatyLayerCategory_RetentionLimit?.toString(),
          }));

        // Map layers
        acc[`tab_${index}`] = {
          treatySubType: firstItem.production_TreatyLayer_TreatySubType.Code,
          paymentAmount:
            firstItem.production_TreatyLayer_PaymentAmount?.toString(),
          paymentDate: firstItem.production_TreatyLayer_PaymentDate,
          premiumRate: firstItem.treatyLayerCategory_PremiumRate?.toString(),
          retentionShare:
            firstItem.production_TreatyLayer_QuotaSharePercentage?.toString(),
          totalExpectedPremium:
            firstItem.treatyLayerCategory_TotalExpectedPremium?.toString(),
          totalTreatyPremium:
            firstItem.production_TreatyLayer_TotalTreatyPremium?.toString(),
          categories: sortedCategories,
        };
        return acc;
      },
      {} as { [key: string]: any }
    );
  } else {
    layers = initialTreatyValues.values.layers;
    layers.tab_0.treatySubType =
      treatyData.production_Treaties_TreatyType.toLowerCase() === 'proportional'
        ? 'QuotaShare'
        : 'ExcessOfLoss';
  }

  return {
    treatyName: treatyData.production_Treaties_TreatyName,
    treatyType: treatyData.production_Treaties_TreatyType,
    effectiveDate: treatyData.production_Treaties_EffectiveDate,
    expiryDate: treatyData.production_Treaties_ExpiryDate,
    currency: treatyData.production_Treaties_TreatyCurrency.Code,
    underwritingLimit:
      treatyData.production_Treaties_TreatyUnderwritingLimit.toString(),
    claimAdviseLimit:
      treatyData.production_Treaties_ClaimAdviseLimit.toString(),
    substandardLimit:
      treatyData.production_Treaties_SubstandardLimit.toString(),
    layers,
  };
};

const validateField = (
  value: unknown,
  fieldName: string,
  errorObj: any
): boolean => {
  if (!value) {
    errorObj[fieldName] = 'Required';
    return true;
  } else {
    errorObj[fieldName] = '';
    return false;
  }
};

export const validateForm = (
  values: ITreatyDetails,
  errors: ITreatyErrors
): { isValid: boolean; errors: ITreatyErrors } => {
  const newErrors = cloneDeep(errors);
  let hasError = false;

  const { treatyType } = values;

  if (!treatyType) {
    newErrors.treatyType = 'Required';
    hasError = true;
  } else {
    newErrors.treatyType = '';

    const rulesForTreatyType =
      validationRules[treatyType.toLowerCase() as keyof typeof validationRules];

    // Validate top-level fields based on the rules
    for (const field in rulesForTreatyType) {
      if (
        field !== 'layer' &&
        rulesForTreatyType[field as keyof typeof rulesForTreatyType]
      ) {
        hasError =
          validateField(
            values[field as keyof ITreatyDetails],
            field,
            newErrors
          ) || hasError;
      }
    }

    // Validate layers
    Object.keys(values.layers).forEach((layerKey: string, index: number) => {
      if (treatyType.toLowerCase() === 'non_proportional' && index > 0) {
        return;
      }

      const layer = values.layers[layerKey];
      const { treatySubType } = layer;

      hasError =
        validateField(
          treatySubType,
          'treatySubType',
          newErrors.layers[layerKey]
        ) || hasError;

      if (treatySubType) {
        // Get rules for the layer type
        const layerRules = (rulesForTreatyType.layer as any)[treatySubType];

        for (const field in layerRules) {
          if (field !== 'categories' && layerRules[field]) {
            hasError =
              validateField(
                layer[field as keyof ITreatyLayer],
                field,
                newErrors.layers[layerKey]
              ) || hasError;
          }
        }

        // Validate categories
        layer.categories.forEach(
          (category: ITreatyLayerCategory, index: number) => {
            const categoryRules = layerRules.categories;

            for (const field in categoryRules) {
              if (categoryRules[field]) {
                hasError =
                  validateField(
                    category[field as keyof ITreatyLayerCategory],
                    field,
                    (newErrors.layers as any)[layerKey].categories[index]
                  ) || hasError;
              }
            }
          }
        );
      }
    });
  }

  return { isValid: !hasError, errors: newErrors };
};

export function convertAuditTrailsToActivities(
  data: IAbstractRecord
): IActivityEntityBase[] {
  return data.Production.queries.GetTreatyAuditTrails.map(
    (auditTrail: Record<string, string>) => ({
      id: auditTrail.production_AuditTrail_Id,
      createdAt: auditTrail.production_AuditTrail_createdOn,
      htmlBody: auditTrail.production_AuditTrail_Message,
      iconUrl: extractIconUrl(auditTrail.production_AuditTrail_Message),
    })
  );
}

function extractIconUrl(html: string): string | undefined {
  const match = html.match(/background-image: url\('(.+?)'\)/);
  return match ? match[1] : undefined;
}

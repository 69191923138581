import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  filterLovsQuery,
  listQuery,
  planDetailsInHealthQuery,
} from './queries';
import { dataToLovs, mapToListingData, toPlansList } from './utils';
import { filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _, { isEmpty } from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import {
  IEnhanceTableHeaderClickable,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { useNavigate } from 'react-router-dom';
import ApplicationDrawer from '../../forms/application-drawer/ApplicationDrawer';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { getError } from '../../utils/graph-utils';
import { createActionAsAgent } from '../applications-listing/queries';
import { useAppSelector } from '../../redux/hooks';
// import { Insurance_Application_Insurance_ApplicationQuotes_QueryResult } from "../../gql/__generated__/graphql";
import ApplicationDrawerWithMultipleAgencies from '../../forms/application-with-multiple-agencies-drawer/ApplicationDrawerWithMultipleAgencies';
import DateService from '../../services/dateService';

const HealthApplicationsPage: React.FC = () => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [
    applicationWithMultipleAgencieDrawerOpen,
    setApplicationWithMultipleAgencieDrawerOpen,
  ] = useState<boolean>(false);

  const [loadingState, setLoadingState] = useState<boolean>(false);

  const FILTER_SESSION_KEY = 'healthapplicationsFilter';

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [agentType, setAgentType] = useState<string>('');

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          assignedAgencyID: [],
          product: [],
          effectiveDate: [],
          createdDate: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getApplicationsLazy, { loading, error, data, fetchMore }] =
    useLazyQuery(listQuery(), {
      variables: {
        currentPage: 1,
        currentPageSize: tableData.pageSize,
        assignedAgencyID:
          filterValues?.assignedAgencyID &&
          filterValues?.assignedAgencyID?.length > 0
            ? filterValues?.assignedAgencyID
            : null,
        product:
          filterValues?.product && filterValues?.product?.length > 0
            ? filterValues?.product
            : null,
        effectiveDateFrom: DateService.formatDateBackend(
          filterValues.effectiveDate?.[0]
        ),
        effectiveDateTo: DateService.formatDateBackend(
          filterValues.effectiveDate?.[1]
        ),
        createdDateFrom: DateService.formatDateBackend(
          filterValues.createdDate?.[0]
        ),
        createdDateTo: DateService.formatDateBackend(
          filterValues.createdDate?.[1]
        ),
      },
      errorPolicy: 'ignore',
    });

  const [getLovsLazy, filterLovsResponse] = useLazyQuery(filterLovsQuery(), {
    // fetchPolicy: 'no-cache',
  });

  const [getPlanDetailsLazy] = useLazyQuery(planDetailsInHealthQuery(), {});

  const [mutateFunction] = useMutation(createActionAsAgent());

  useEffect(() => {
    getApplicationsLazy();
    getLovsLazy();

    setAgentType(
      user.userRoles.includes('Insurance-Underwriter')
        ? 'underwriter'
        : user.userRoles.includes('Insurance-QuotingAgent') ||
            user.userRoles.includes('Insurance-IssuingAgent')
          ? 'agent'
          : ''
    );
  }, []);

  let actions: IEnhancedTableMenuItem[] = [];
  // If user is Business User, the new motor application details will be created directly without a drawer
  // and directed to the new created page

  if (data?.Insurance?.actions?.able_To_CreateApplicationAsAgent) {
    actions = [
      {
        title: '+ New',
        onClick: () => undefined,
        isEntity: false,
        isBulk: false,
        iconUrl: '',
      },
    ];

    actions[0].onClick = () => {
      user.info.agencyId.length === 0 || user.info.agencyId.length === 1
        ? submitForm()
        : setApplicationWithMultipleAgencieDrawerOpen(true);
    };
  }
  // If user is Underwriter, a drawer will show to be filled accordingly
  // and then the submit of the drawer will direct to the new created page
  else if (data?.Insurance?.actions?.able_To_CreateApplicationAsUnderwriter) {
    actions = [
      {
        title: '+ New',
        onClick: () => undefined,
        isEntity: false,
        isBulk: false,
        iconUrl: '',
      },
    ];

    actions[0].onClick = () => {
      user.info.agencyId.length === 0 || user.info.agencyId.length === 1
        ? setDrawerOpen(true)
        : setApplicationWithMultipleAgencieDrawerOpen(true);
    };
  }

  const submitForm = async () => {
    try {
      const { data, errors } = await mutateFunction({
        variables: { lineOfBusiness: 'Health' },
      });
      if (isEmpty(errors)) {
        toast.success(
          <ToastSuccessMessage>
            {'Application created successfully'}
          </ToastSuccessMessage>
        );
        const newApplicationId =
          data.insurance.actions.createApplicationAsAgent.id;
        setTimeout(() => {
          navigate('/sales/applications/health/' + newApplicationId);
        }, 500);
      } else {
        toast.error(<ToastErrorMessage>{getError(data)}</ToastErrorMessage>);
      }
    } catch {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      //
    }
  };

  useEffect(() => {
    if (filterLovsResponse?.loading) setLoadingState(true);
    if (filterLovsResponse?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (filterLovsResponse?.data) {
      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = dataToLovs(filterLovsResponse.data);

      if (!Object.keys(lovs.assignedAgencyID).length) {
        lovs.assignedAgencyID = filterValues?.assignedAgencyID;
      }
      if (!Object.keys(lovs.product).length) {
        lovs.product = filterValues?.product;
      }

      const newFilterSections = filterSectionsContent(
        lovs,
        savedFilters,
        user.userRoles
      );
      setFilterSections(newFilterSections);
    }
    setLoadingState(false);
  }, [filterLovsResponse]);

  useEffect(() => {
    if (loading) setLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      const tableData = mapToListingData(data);
      setTableData({ ...tableData, pageNumber: 0 });
    }
    setLoadingState(false);
  }, [loading, error, data]);

  function handlePageChange(page: number) {
    setLoadingState(true);
    const nextPage = ++page;
    fetchMore({
      variables: {
        currentPage: nextPage,
        currentPageSize: tableData.pageSize,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const updatedTableData = {
            ...mapToListingData(fetchMoreResult.data),
            pageNumber: nextPage - 1,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setLoadingState(false);
      });
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    fetchMore({
      variables: {
        currentPage: 1,
        currentPageSize: numberOfRecordsPerPage,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const updatedTableData = {
            ...mapToListingData(fetchMoreResult.data),
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setLoadingState(false);
      });
  }

  (headers.name as IEnhanceTableHeaderClickable).callback = (payload: any) => {
    navigate('/sales/applications/health/' + payload.columns.id);
  };

  async function handleTooltipFetch(id: string): Promise<Record<string, any>> {
    let popoverRows: Record<string, any> = {};
    await getPlanDetailsLazy({
      variables: {
        id: id,
      },
    }).then((response) => {
      popoverRows = toPlansList(
        response.data.Insurance.entities.application.queries.insurance
          .applicationQuotes as unknown as [any]
      );
    });
    return popoverRows;
  }
  headers.plan.tooltipSettings.tooltipFetch = handleTooltipFetch;

  const renderMainChildren = () => {
    return (
      <>
        {loading ? (
          <div></div>
        ) : (
          <div style={{ marginTop: '20px' }}>
            <EnhancedTable
              title="Health Applications"
              name="count"
              orderByAscendingByDefault
              inline={false}
              data={tableData}
              headers={headers}
              handlePageChange={(page: number) => handlePageChange(page)}
              handleRowsPerPageChange={(page: number) =>
                handleRowsPerPageChange(page)
              }
              currentPage={tableData.pageNumber}
              hideToolbar
              usePagination
              disableSelection
              actions={actions}
            />
            {drawerOpen && (
              <ApplicationDrawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                lineOfBusiness="Health"
                onSuccess={() => {
                  setDrawerOpen(false);
                  handlePageChange(0);
                }}
              />
            )}
            {applicationWithMultipleAgencieDrawerOpen && (
              <ApplicationDrawerWithMultipleAgencies
                open={applicationWithMultipleAgencieDrawerOpen}
                loggedInAgentId={user.info.agentId}
                loggedInuserFirstName={user.info.firstName}
                loggedInuserLastName={user.info.lastName}
                lineOfBusiness={'Health'}
                agentType={agentType}
                onClose={() =>
                  setApplicationWithMultipleAgencieDrawerOpen(false)
                }
                onSuccess={() => {
                  handlePageChange(0);
                }}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={''}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={loading || loadingState}
      name={'Applications'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default HealthApplicationsPage;

import { trimTrailingZeros } from '../utils/formatting-utils';
import { isEmpty, isValidNumber } from '../utils/validationUtils';

export interface ILocalizationConfig {
  Country: string;
  PhoneCountry: string;
  Currency: {
    countryCode: string;
    currency: string;
    addSpace?: boolean;
  };
  Date: {
    countryCode: string;
    dateStyle: string;
    timeStyle: string;
    options?: {
      day: string;
      month: string;
      year: string;
    };
  };
}

export default class FormattingServices {
  private static config: ILocalizationConfig = {
    Country: 'us',
    PhoneCountry: 'US',
    Currency: {
      countryCode: 'en-US',
      currency: 'USD',
    },
    Date: {
      countryCode: 'en-US',
      dateStyle: 'long',
      timeStyle: 'medium',
    },
  };

  public static async setConfig(config: ILocalizationConfig) {
    if (!this.config) {
      this.config = config;
    }
  }

  public static formatCustomCurrency(
    numberValue: number,
    customCurrencySymbol: string = this.config.Currency.currency,
    decimals = 2,
    shouldTrimTrailingZeros = false,
    countryCode: string = this.config.Currency.countryCode
  ): string {
    let formattedNumber = new Intl.NumberFormat(countryCode, {
      style: 'decimal', // Use 'decimal' since 'currency' expects a valid currency code
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(numberValue);

    if (shouldTrimTrailingZeros) {
      formattedNumber =
        trimTrailingZeros(formattedNumber, decimals, true).toString() || '0';
    }
    // Manually append the custom currency symbol
    return `${customCurrencySymbol} ${formattedNumber}`;
  }

  public static formatCurrencyNumber(
    value: any,
    decimals = 2,
    currency: string = this.config.Currency.currency,
    countryCode: string = this.config.Currency.countryCode
  ) {
    if (isEmpty(value) || !isValidNumber(value)) {
      return '';
    }

    const numberValue = Number(value);
    const result = new Intl.NumberFormat(countryCode, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(numberValue);

    return result;
  }

  public static formatCurrencyNumberMillion(
    value: any,
    decimals = 2,
    currencySymbol: string
  ) {
    const { countryCode, currency } = this.config.Currency;

    if (isEmpty(value) || !isValidNumber(value)) {
      return '';
    }

    let numberValue = Number(value);
    numberValue /= 1000000;

    let result = new Intl.NumberFormat(countryCode, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(numberValue);

    result = result.replace(currencySymbol, 'M' + currencySymbol);

    return result;
  }
}

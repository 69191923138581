import { isArray } from 'lodash';
import { IProposalDetailsSummary } from './index2';
import { trimTrailingZeros } from '../../../../../utils/formatting-utils';
import PrecisionService from '../../../../../services/precisionService';

export interface IPayerData {
  payerId: string;
  fullName?: string;
  payerAddress: string;
  ownerIsDifferent: boolean;
  addressDetails?: string;
}

export interface IOwnerData {
  ownerId?: string;
  fullName?: string;
  ownerAddress?: string;
  addressDetails?: string;
}

export interface IInsuredData {
  id: string;
  order: string;
  fullName?: string;
  address?: string;
  addressDetails?: string;
  cardNumber?: string;
  exclusions?: string;
  remarks?: string;
  isPrincipal?: boolean;
  relation?: string;
}

export interface IBeneficiaryData {
  id: string;
  address: string;
  percentage: number;
  fullName?: string;
  addressDetails?: string;
}

export interface IPolicyPersons {
  payer: IPayerData;
  owner?: IOwnerData;
  insured: IInsuredData[];
  beneficiaries: IBeneficiaryData[];
}

export interface IProposalPageFormData {
  policyPersons: {
    payer: IPayerData;
    owner?: IOwnerData;
    insured: IInsuredData[];
    beneficiaries: IBeneficiaryData[];
  };
  costs: IProposalCostCharges;
  travelDetails: IProposalTravelDetails;
}

export interface IProposalCover {
  isMain: boolean;
  isMandatory: boolean;
  isEditable: boolean;
  policyCover: string;
  coverPremiumType: string;
  coverPremiumPercentage?: number;
  coverPremiumValue?: number;
  currency: string;
  coverSumInsured?: number;
  sumInsuredIsAdditive: boolean;
  excessOnClaimType: string;
  excessOnClaimAmount?: number;
  excessOnClaimPercentage?: number;
  excessOnClaimDays?: number;
  name?: string;
  clauseID: string;
  coverEffectiveFrom: string;
  coverEffectiveTo: string;
}

export interface IProposalCostCharges {
  policyCost: number;
  chargesPercentage: number;
  tpaFeesType: string;
  tpaFeesAmount?: number;
  tpaFeesPercentage?: number;
  tpaFeesApplicableOn: string;
}

export interface IProposalTravelDetails {
  destination: string;
  duration: number;
  policyCover: string[];
}

export interface IProposalPageFormState {
  values: IProposalPageFormData;
  errors: {
    policyPersons: {
      payer: Record<string, string>;
      owner: Record<string, string>;
      insured: Record<string, string>[];
      beneficiaries: Record<string, string>[];
    };
    costs: Record<string, string>;
    travelDetails: Record<string, string>;
  };
  touched: {
    policyPersons: {
      payer: Record<string, boolean>;
      owner: Record<string, boolean>;
      insured: Record<string, boolean>[];
      beneficiaries: Record<string, boolean>[];
    };
    costs: Record<string, boolean>;
    travelDetails: Record<string, boolean>;
  };
}

export const getProposalPageFormState = (
  summary?: IProposalDetailsSummary,
  policyPersons?: IPolicyPersons
): IProposalPageFormState => ({
  values: {
    policyPersons,
    costs: {
      policyCost: trimTrailingZeros(summary?.PricingOption.PolicyCost),
      chargesPercentage: PrecisionService.multiplyBy100(
        summary?.PricingOption.ChargesPercentage
      ),
      tpaFeesType: summary?.PricingOption.TPAFeesType,
      tpaFeesAmount: trimTrailingZeros(summary?.PricingOption.TPAFeesAmount),
      tpaFeesPercentage: PrecisionService.multiplyBy100(
        summary?.PricingOption.TPAFeesPercentage
      ),
      tpaFeesApplicableOn: summary?.PricingOption.TPAFeesApplicableOn,
    },
    travelDetails: {
      destination: summary?.PolicyTravel?.Destination?.Code || '',
      duration: summary?.PolicyTravel?.Duration || null,
      policyCover: isArray(summary?.PolicyTravel?.PolicyCover)
        ? summary.PolicyTravel.PolicyCover
        : [],
    },
  },
  errors: {
    policyPersons: {
      payer: {
        payerId: '',
        payerAddress: '',
        ownerIsDifferent: '',
      },
      owner: {
        ownerId: '',
        ownerAddress: '',
      },
      insured:
        policyPersons?.insured?.length > 0
          ? policyPersons.insured.map((i) => ({
              id: '',
              order: '',
              address: '',
            }))
          : [
              {
                id: '',
                order: '',
                address: '',
              },
            ],
      beneficiaries:
        [policyPersons?.beneficiaries || []]?.length > 0
          ? policyPersons?.beneficiaries.map((v) => ({
              id: '',
              order: '',
              address: '',
              percentage: '',
            }))
          : [
              {
                id: '',
                order: '',
                address: '',
                percentage: '',
              },
            ],
    },
    costs: {},
    travelDetails: {
      destination: '',
      duration: '',
      policyCover: '',
    },
  },
  touched: {
    policyPersons: {
      payer: {
        payerId: false,
        payerAddress: false,
        ownerIsDifferent: false,
      },
      owner: {
        ownerId: false,
        ownerAddress: false,
      },
      insured:
        policyPersons?.insured?.length > 0
          ? policyPersons.insured.map((i) => ({
              id: false,
              order: false,
              address: false,
            }))
          : [
              {
                id: false,
                order: false,
                address: false,
              },
            ],
      beneficiaries:
        [policyPersons?.beneficiaries || []]?.length > 0
          ? policyPersons?.beneficiaries.map((v) => ({
              id: false,
              order: false,
              address: false,
              percentage: false,
            }))
          : [
              {
                id: false,
                order: false,
                address: false,
                percentage: false,
              },
            ],
    },
    costs: {},
    travelDetails: {
      destination: false,
      duration: false,
      policyCover: false,
    },
  },
});

import React, { useEffect, useState } from 'react';
import { ICurrencyExchangeRateNewPageProps } from '.';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';
import EntityInfoWidget from '../../components/widgets/entity-info/EntityInfoWidget';
import { currencyHeaders, entityView, steps } from './content';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { IListingData } from '../../models/listing';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createCurrencyExchangeRate,
  getLatestCurrencyExchangeRateList,
} from './queries';
import { IEnhancedTableMenuItem } from '../../components/enhanced-table';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getError } from '../../utils/graph-utils';
import { OpenConfirmationAction } from '../../redux/confirmation/actions';
import { useAppDispatch } from '../../redux/hooks';
import { mapToCurrencyListingData } from './utils';
import WidgetSection from '../../components/common/WidgetSection';
import EnhancedStepperSpecificDesign from '../../components/common/EnhancedStepperSpecificDesign';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import DateService from '../../services/dateService';

const CurrencyExchangeRateNewPage: React.FC<
  ICurrencyExchangeRateNewPageProps
> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const [booted, setBooted] = useState<boolean>(false);

  const [currencyTableData, setCurrencyTableData] = useState<IListingData<any>>(
    {
      pagedItems: {},
      pageSize: 100,
      pageNumber: 1,
      totalCount: 0,
    }
  );

  const [currencyListQuery] = useLazyQuery(getLatestCurrencyExchangeRateList());

  const [createDailyExchangeRateResultsAction] = useMutation(
    createCurrencyExchangeRate(),
    {
      errorPolicy: 'all',
      refetchQueries: [getLatestCurrencyExchangeRateList()],
    }
  );

  const loadCurrencyList = async () => {
    const result = await currencyListQuery({
      variables: {
        currentPage: 1,
        currentPageSize: 1000,
      },
      errorPolicy: 'all',
    });

    if (result.data) {
      const currenciesTableData = mapToCurrencyListingData(result?.data);

      return currenciesTableData;
    }

    return null;
  };

  const initialize = async () => {
    const applicationProperties = entityView.sections[0];

    applicationProperties.properties.effectiveDate.value =
      DateService.formatDate(new Date());

    const newLeftEntityView = {
      ...entityView,
      sections: [applicationProperties],
    };
    const currencyList = await loadCurrencyList();
    Object.values(currencyList.pagedItems).forEach((row: any) => {
      row.disabled = row.core_CurrencyExchangeRate_EffectiveFrom
        ? DateService.isSameDate(
            row.core_CurrencyExchangeRate_EffectiveFrom,
            new Date(),
            'day'
          )
        : false;
    });

    setUpdatedLeftSideInfo(newLeftEntityView);
    setCurrencyTableData(currencyList);
    setBooted(true);
  };

  useEffect(() => {
    initialize();
  }, []);

  const currencyListingActions: IEnhancedTableMenuItem[] = [
    {
      title: 'Save',
      onClick: () => {
        const confirmation = {
          open: true,
          title: 'Save',
          message: (
            <>
              {'Are you sure you want to save your changes?'}
              <br />
              <i>
                <u>
                  {'Note that you can save the daily rate only once per day.'}
                </u>
              </i>
            </>
          ),
          callback: async () => {
            await handleSaveCallback();
          },
          submitButtonText: 'Submit',
          cancelButtonText: 'Cancel',
        };
        dispatch(OpenConfirmationAction(confirmation));
      },
      isEntity: false,
      isBulk: false,
      hidden: false,
      disabled:
        submitting ||
        !(
          currencyTableData &&
          currencyTableData.pagedItems &&
          Object.keys(currencyTableData.pagedItems).length > 0
        ),
      isSecondary: true,
    },
  ];

  const handleSaveCallback = async () => {
    setSubmitting(true);
    try {
      const modifiedRowData = Object.values(currencyTableData.pagedItems).map(
        (row: {
          core_CurrencyExchangeRate_CurrencyID_Code: string;
          core_CurrencyExchangeRate_Rate: string;
        }) => ({
          core_CurrencyExchangeRate_CurrencyID_Code:
            row.core_CurrencyExchangeRate_CurrencyID_Code,
          core_CurrencyExchangeRate_Rate: row.core_CurrencyExchangeRate_Rate,
        })
      );

      const variables = {
        rateImputs: modifiedRowData.map(
          (row: {
            core_CurrencyExchangeRate_CurrencyID_Code: string;
            core_CurrencyExchangeRate_Rate: string | number;
          }) => {
            return {
              currencyCode: row.core_CurrencyExchangeRate_CurrencyID_Code,
              rate:
                row.core_CurrencyExchangeRate_Rate === 0
                  ? 0
                  : row.core_CurrencyExchangeRate_Rate === '' ||
                      row.core_CurrencyExchangeRate_Rate === null ||
                      row.core_CurrencyExchangeRate_Rate === undefined
                    ? null
                    : row.core_CurrencyExchangeRate_Rate,
            };
          }
        ),
      };

      const result = await createDailyExchangeRateResultsAction({
        variables: variables,
        errorPolicy: 'all',
      });

      if (isEmpty(result.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {'Currency Exchange Rate successfully updated.'}
          </ToastSuccessMessage>
        );

        setTimeout(() => {
          navigate(`/configuration/currency-exchange-rate/`);
        }, 500);
      } else {
        toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
      }
    } catch (error) {
      toast.error(
        <ToastErrorMessage>{`An error occurred: ${error.message}`}</ToastErrorMessage>
      );
    } finally {
      setSubmitting(false);
    }
  };

  const handleCurrencyCellValueChanged = (
    rowIndex: number,
    columnId: string,
    newValue: any
  ) => {
    setCurrencyTableData((prevData) => {
      const currentValue = prevData.pagedItems[rowIndex]?.[columnId];
      let parsedValue =
        typeof newValue === 'string' ? parseFloat(newValue) : newValue;

      if (isNaN(parsedValue)) {
        parsedValue = null;
      }

      if (currentValue === parsedValue || newValue === '') {
        return prevData;
      }

      const updatedPagedItems = {
        ...prevData.pagedItems,
        [rowIndex]: {
          ...prevData.pagedItems[rowIndex],
          [columnId]: parsedValue,
        },
      };

      return {
        ...prevData,
        pagedItems: updatedPagedItems,
      };
    });
  };

  const renderMainChildren = () => {
    return (
      <>
        <div style={{ marginTop: '20px' }}>
          <EnhancedStepperSpecificDesign
            activeStep={''}
            showStepperButton={false}
            style={{ visibility: 'hidden' }}
            steps={steps}
          />

          <WidgetSection style={{ margin: '-23px 0 0 0' }}>
            <form>
              <div
                style={{
                  marginTop: '20px',
                  padding: '-23px 0 0 0 !important',
                }}
              >
                <EnhancedTable
                  title={capitalizeFirstLetter('Currency Exchange Rate')}
                  inlineTitle={capitalizeFirstLetter('Currency Exchange Rate')}
                  name="count"
                  entityName={'ClaimCover'}
                  entityIdColumnName={'planConfigManagement_ClaimCover_Id'}
                  showCellFullData={false}
                  isTitlePascalCase={true}
                  orderByAscendingByDefault
                  defaultOrderByColumn="name"
                  inline={true}
                  data={currencyTableData}
                  headers={currencyHeaders}
                  currentPage={currencyTableData.pageNumber}
                  hideToolbar={false}
                  usePagination
                  disableSelection={true}
                  actions={currencyListingActions}
                  showTablePagination={false}
                  onSelectionChange={() => undefined}
                  handleCellValueChanged={handleCurrencyCellValueChanged}
                  showInlineFilter={false}
                />
              </div>
            </form>
          </WidgetSection>
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: '' }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={'Currency Exchange Rate'}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name={'Currency Exchange Rate'}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default CurrencyExchangeRateNewPage;

import React from 'react';
import EnhancedModal from '../../components/common/generic-modal/EnhancedModal';
import { IPolicyPropertyDescriptionDetails } from '../../modules/production/policy/page';
import DescriptionOfRiskPopUp from './DescriptionOfRiskPopUp';

interface IDescriptionOfRiskPopUpShellProps {
  open: boolean;
  currencySymbol: string;
  propertyData: IPolicyPropertyDescriptionDetails[];
  lovs: Record<string, Record<string, string>>;
  onClose: () => void;
  onSuccess: () => void;
}

const DescriptionOfRiskPopUpShell: React.FC<
  IDescriptionOfRiskPopUpShellProps
> = ({ open, currencySymbol, propertyData, lovs, onClose, onSuccess }) => {
  return (
    <EnhancedModal
      open={open}
      blur={true}
      onClose={onClose}
      title="Modify Property Risk Description popup"
    >
      <DescriptionOfRiskPopUp
        currencySymbol={currencySymbol}
        data={propertyData}
        lovs={lovs}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </EnhancedModal>
  );
};

export default DescriptionOfRiskPopUpShell;

import { isValidNumber, isEmpty } from './validationUtils';
import PrecisionService from '../services/precisionService';

export const EMPTY_FIELD_PLACEHOLDER = '- -';

/**
 * Transforms a number to a string with Intl number format
 * @param {number} value The value to be formatted
 * @return {string} The value in Intl number format
 */
export function formatNumber(value: number) {
  return new Intl.NumberFormat().format(value);
}

/**
 * Truncates a string according to the provided limit
 * @param {string} input The value to be truncated
 * @param {number} limit The max number of characters allowed
 * @return {string} The string after being truncated followed by '...'
 * If the input or limit are not provided returns N/A
 */
export function limitCharacterNumber(input: string, limit: number) {
  let output: string = input;
  if (output && limit) {
    if (output.length > limit) {
      output = `${input.substr(0, limit - 1)}...`;
    }
    return output;
  }
  return EMPTY_FIELD_PLACEHOLDER;
}

/**
 * Removes empty spaces from a string
 * @param {string} input The string
 * @return {string} The string with all empty spaces removed
 */
export function removeEmptySpaces(input: string): string {
  if (input) {
    const chunks = input.split(' ');
    let output = '';
    for (let i = 0; i < chunks.length; i += 1) {
      output += chunks[i];
    }
    return output;
  }
  return '';
}

export function normaliseUrl(url: string): string {
  if (!url) {
    return url;
  }

  const protocols = ['http', 'ip', 'ftp', 'ssh', 'ssl'];
  let isValid = false;

  for (let i = 0; i < protocols.length; i += 1) {
    if (url.startsWith(protocols[i])) {
      isValid = true;
      break;
    }
  }

  if (!isValid) {
    return `http://${url}`;
  }
  return url;
}

/**
 * Replaces all substrings between {} in the template string with their
 * matching values in the dictionary values
 * @param {string} template The string to work on
 * @param {string} primaryValue The primaryValue, the pattern {PRIMARY_VARIABLE} will be matched to this value
 * @param {Record<string, string>} values The dictionary with each pattern mapped to a value
 * @return {string} The string after being parsed
 */
export function parseStringTemplate(
  template: string,
  primaryValue: string | number,
  values: Record<string, string>
): string {
  const PRIMARY_VARIABLE = 'PRIMARY_VARIABLE';
  let output = template;
  const allPatterns = template.match(/(\{[a-zA-Z_]+\})/gi);
  if (allPatterns && allPatterns.length > 0) {
    allPatterns.forEach((pattern) => {
      const variableName = pattern.slice(1, pattern.length - 1);
      let value = values[variableName];
      if (variableName === PRIMARY_VARIABLE && !isEmpty(primaryValue)) {
        value = primaryValue.toString();
      }
      output = output.replace(pattern, value);
    });
  }
  return output;
}

export const isExternalLink = (link: string) => {
  return link.match(/((https|http):\/\/|www\.)/gi);
};

export function returnNumberSign(input: number): string {
  const sign = Math.sign(input);
  switch (sign) {
    case -1:
      return '-';
    case 1:
      return '+';
    default:
      return '';
  }
}

export function reduceNumberOfDecimals(
  value: string | number | undefined,
  numberOfDecimals: number
): string {
  const parts = (value || '').toString().split('.');
  if (parts[1]) {
    parts[1] = parts[1].substr(0, numberOfDecimals);
  }
  return parts.join('.');
}

export function valueCommaSeparated(
  value: string | number | undefined
): string {
  if (isEmpty(value)) {
    return '';
  }
  const parts = (value || '0').toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export const formatWithCommas = (num: string) => {
  if (isEmpty(num)) {
    return '';
  }

  try {
    const parts = num.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  } catch (error) {
    return '';
  }
};

export function removeNumberDelimeter(value: string | number): string {
  let returnValue = value.toString();
  returnValue = returnValue.toString().replace(/,/g, '');
  const numberValue = Number(returnValue);
  if (isValidNumber(numberValue) && numberValue >= 0) {
    return returnValue;
  }
  return '0';
}

export function addZeroes(num: string | number, toFixedNumber = 2): string {
  const value = Number(num);
  return value.toFixed(toFixedNumber);
}

export function addZeroesAndSeparatevalue(
  value: string | number | undefined
): string {
  if (value) {
    return valueCommaSeparated(addZeroes(value, 3));
  }
  return '0.00';
}

export function removeSpecialCharacters(input: string) {
  return input ? input.replace(/[^a-zA-Z ]/g, '') : input;
}

export function replaceAllString(
  value: string,
  search: string,
  replacement: string
): string {
  return value.replace(new RegExp(search, 'g'), replacement);
}

export const cleanValueOfPattern = (
  value: string,
  pattern: string,
  mask: string
): string => {
  let cleanValue = value;
  let k = 0;
  for (let i = 0; i < value.length; i += 1) {
    if (pattern[i] !== mask && value[i] === pattern[i]) {
      if (!isEmpty(cleanValue[i - k])) {
        cleanValue =
          cleanValue.substring(0, i - k) +
          cleanValue.substring(i - k + 1, cleanValue.length);
        k += 1;
      }
    }
  }
  return cleanValue;
};

export const maskValueWithPattern = (
  value: string,
  pattern: string,
  mask = 'x'
): string => {
  const cleanValue = cleanValueOfPattern(value, pattern, mask);
  let returnValue = '';
  let j = 0;
  for (let i = 0; i < pattern.length; i += 1) {
    if (pattern[i] !== mask) {
      returnValue += pattern[i];
    } else if (!isEmpty(cleanValue[j])) {
      returnValue += cleanValue[j];
      j += 1;
    } else {
      returnValue += mask;
    }
  }
  return returnValue;
};

export const maskValueWithPatternIfMatch = (
  value: string,
  pattern: string,
  mask = 'x'
): string => {
  const cleanValue = cleanValueOfPattern(value, pattern, mask);
  let returnValue = '';
  let j = 0;
  for (let i = 0; i < pattern.length; i += 1) {
    if (pattern[i] !== mask) {
      returnValue += pattern[i];
    } else if (!isEmpty(cleanValue[j])) {
      returnValue += cleanValue[j];
      j += 1;
    } else {
      returnValue += mask;
    }
  }
  return returnValue.toLowerCase().includes(mask.toLowerCase())
    ? value
    : returnValue;
};

export function capitalizeFirstLetter(value: string, splitCharacter = ' ') {
  if (!value) {
    return '';
  }

  return value
    .toLowerCase()
    .split(splitCharacter)
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

export function capitalizeFirstCharacter(value: string, replaceChar?: string) {
  const returnValue = value.charAt(0).toUpperCase() + value.slice(1);
  if (replaceChar) {
    return returnValue.replace(replaceChar, ' ');
  }
  return returnValue;
}

export function separateCapicalCharacters(value: string) {
  return value.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function removePlusFromMobileNumber(value: string) {
  return value.replace('+', '');
}

export function removeHTMLTagesFromDescription(value: string) {
  if (!value) {
    return '';
  }

  return value.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '');
}

export function getIdsFromMultiselectData(array: []) {
  return array?.map((item: { Id: string }) => item?.Id);
}

export function getTitlesFromMultiselectData(array: any[]) {
  if (!array) {
    return '';
  }

  return array?.map((item: { Title: string }) => item?.Title).join(', ');
}

export function capitalizeFirstLetterLowerOthers(
  value: string,
  splitCharacter = ' '
) {
  if (!value) {
    return '';
  }

  return value
    .toLowerCase()
    .split(splitCharacter)
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase();
    })
    .join(' ');
}

/**
 * Removes trailing zeros and limits the value to 3 decimal places without rounding,
 * returning a number.
 * If the resulting value is zero, it returns 0.
 *
 * @param {number | string} value - The input value that can be a number or a string representing a number.
 *
 * @returns {number} - The formatted value as a number with up to 3 decimal places, without trailing zeros or rounding.
 * - If the value is 0 or equivalent to 0 (e.g., "0.00", "000"), the function returns 0.
 *
 * @example
 * // Returns 0
 * trimTrailingZeros(0);
 *
 * @example
 * // Returns 0
 * trimTrailingZeros("0.00");
 *
 * @example
 * // Returns 1.2
 * trimTrailingZeros(1.2000);
 *
 * @example
 * // Returns 45
 * trimTrailingZeros(45.000);
 *
 * @example
 * // Returns 0.088
 * trimTrailingZeros(0.088);
 */
export function trimTrailingZeros(
  value: number | string,
  maxDecimalPrecision: number,
  keepFormat: true
): string;
export function trimTrailingZeros(
  value: number | string,
  maxDecimalPrecision?: number,
  keepFormat?: false
): number;
export function trimTrailingZeros(
  value: number | string,
  maxDecimalPrecision = 3,
  keepFormat = false
): string | number {
  if (isEmpty(value)) {
    return keepFormat ? '0' : 0;
  }

  // Convert value to a string and remove commas temporarily for processing
  const sanitizedValue = value.toString().replace(/,/g, '');
  const [integerPart, decimalPart = ''] = sanitizedValue.split('.');

  // Trim the decimal part to the specified precision without rounding
  const trimmedDecimalPart = decimalPart.slice(0, maxDecimalPrecision);

  // Reassemble with integer and trimmed decimal parts
  let result = trimmedDecimalPart
    ? `${integerPart}.${trimmedDecimalPart}`
    : integerPart;

  // Remove trailing zeros only in the decimal part
  if (trimmedDecimalPart) {
    result = result.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
  }

  if (keepFormat) {
    // Format integer part with commas
    const [formattedIntegerPart, formattedDecimalPart] = result.split('.');
    const formattedIntegerWithCommas =
      Number(formattedIntegerPart).toLocaleString();
    return formattedDecimalPart
      ? `${formattedIntegerWithCommas}.${formattedDecimalPart}`
      : formattedIntegerWithCommas;
  } else {
    // Return as a number if keepFormat is false
    return Number(result);
  }
}

/**
 *
 * @param {string} value - The value to be formatted
 * @returns - The formatted value as a string with the first letter of each word capitalized and joined by the provided character.
 *
 * @example
 * // Returns 'NonProportional'
 * formatEnum('NON_PROPORTIONAL');
 *
 * @example
 * // Returns 'Non Proportional'
 * formatEnum('NON_PROPORTIONAL', ' ');
 *
 * @example
 * // Returns 'Property'
 * formatEnum('PROPERTY');
 */
export function formatEnum(value: string, joinBy?: string): string {
  if (!value) {
    return '';
  }

  return value
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(joinBy || '');
}

import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../../../components/common/generic-drawer/GenericDrawer';
import SectionDynamicForm from '../../../../DynamicForm/SectionDynamicForm';
import { getSections } from './content';
import { createTreatyMutation, getLovs } from './queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../../constants';
import { formatEnum } from '../../../../utils/formatting-utils';
import { useNavigate } from 'react-router-dom';
import { getError } from '../../../../utils/graph-utils';
import ToastSuccessMessage from '../../../../components/ToastSuccessMessage';
import DateService from '../../../../services/dateService';

interface INewTreatyDrawer {
  isOpen: boolean;
  onClose: () => void;
  lovs: Record<string, Record<string, string>>;
}

export interface IReinsurer {
  reinsurer: string;
  share: number;
}

interface ITreatyDrawerValues {
  company: string;
  treatyName: string;
  treatyType: string;
  currency: string;
  effectiveDate: string;
  expiryDate: string;
  underwritingLimit: number;
  claimadviseLimit: number;
  substandardLimit: string;
  reinsurersRepeater: IReinsurer[];
}

const NewTreatyDrawer: React.FC<INewTreatyDrawer> = ({
  isOpen,
  onClose,
  lovs,
}) => {
  const navigate = useNavigate();
  const [values, setValues] = useState<ITreatyDrawerValues>({
    company: '41',
    treatyName: null,
    treatyType: null,
    currency: '109',
    effectiveDate: null,
    expiryDate: null,
    underwritingLimit: null,
    claimadviseLimit: null,
    substandardLimit: null,
    reinsurersRepeater: [
      {
        reinsurer: null,
        share: null,
      },
    ],
  });
  const [reinsurersList, setReinsurersList] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [getLovsLazy] = useLazyQuery(getLovs);
  const [createTreaty, { loading }] = useMutation(createTreatyMutation);

  const getReinsurers = async (search?: string) => {
    try {
      const { data } = await getLovsLazy({
        variables: {
          SelectedCompany: '41',
          KeywordSearch: search,
          pagination: {
            pageNumber: 1,
            pageSize: 5,
          },
        },
      });

      const reinsurers: Record<string, string> = {};
      data?.Production?.queries?.ReinsurersList?.items.forEach(
        (item: Record<string, string>) => {
          reinsurers[item.salesforceManagement_BusinessPartner_Id] =
            item.salesforceManagement_BusinessPartner_FullName;
        }
      );

      setReinsurersList(reinsurers);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    getReinsurers();
  }, []);

  const handleChange = (
    fieldName: string,
    value: unknown,
    allValues: ITreatyDrawerValues
  ) => {
    if (
      fieldName === 'treatyType' &&
      (value as string).toLowerCase() === 'proportional'
    ) {
      allValues.substandardLimit = '100';
    }

    setValues(allValues);
    validateForm(allValues);
  };

  const handleSearch = async (
    fieldName: string,
    value: string
  ): Promise<void> => {
    if (fieldName === 'reinsurersRepeater') {
      getReinsurers(value);
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await createTreaty({
        variables: {
          Company: values.company,
          TreatyName: values.treatyName,
          SelectedType: formatEnum(values.treatyType),
          SelectedCurrency: values.currency,
          UnderwritingLimit: Number(values.underwritingLimit),
          ClaimLimit: Number(values.claimadviseLimit),
          SubstandardLimit: Number(values.substandardLimit),
          EffecitiveDate: DateService.formatDateBackend(values.effectiveDate),
          ExpiryDate: DateService.formatDateBackend(values.expiryDate),
          ReinsurersList: values.reinsurersRepeater
            .map((item, index) => ({
              IsLeader: index === 0,
              ReinsurerID: item.reinsurer,
              ReinsurerName: (reinsurersList as any)[item.reinsurer],
              ReinsurerShare: Number(item.share),
            }))
            .sort((a) => (a.IsLeader ? 1 : -1)),
        },
      });

      const createdTreatyId = res.data?.production?.actions?.createTreaty?.id;

      if (createdTreatyId) {
        toast.success(
          <ToastSuccessMessage>Treaty created successfully</ToastSuccessMessage>
        );
        setTimeout(() => {
          navigate('/reinsurance/treaties/' + createdTreatyId);
        }, 750);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
    }
  };

  const validateForm = (values: ITreatyDrawerValues) => {
    const Invalid =
      !values.company ||
      !values.treatyName ||
      !values.treatyType ||
      !values.currency ||
      !values.effectiveDate ||
      !values.expiryDate ||
      !values.underwritingLimit ||
      !values.claimadviseLimit ||
      (values.treatyType?.toLowerCase() === 'proportional' &&
        !values.substandardLimit) ||
      values.reinsurersRepeater.some((item) => !item.reinsurer || !item.share);

    setIsSubmitDisabled(Invalid);
  };

  return (
    <GenericDrawer
      title={'Add New Treaty'}
      onClose={() => onClose()}
      isOpen={isOpen}
    >
      <SectionDynamicForm
        disableForm={loading}
        isSubmitButtonDisabled={isSubmitDisabled || loading}
        onSubmit={handleSubmit}
        buttonText={'Submit'}
        sections={getSections(values || {}, lovs, reinsurersList)}
        onChange={handleChange}
        onSearch={handleSearch}
      />
    </GenericDrawer>
  );
};

export default NewTreatyDrawer;

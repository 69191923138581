import { IProductionDetailsSummary } from '../../modules/production/policy/page';
import { getInputs, initialValues } from './content';

export const mapDataToFormValues = (data: IProductionDetailsSummary) => ({
  ...initialValues,
  nameOnLicenseEn: data?.NameOnLicenseEn || '',
  nameOnLicenseAr: data?.NameOnLicenseAr || '',
  vignetteCode: data?.PolicyMotor?.VignetteCode || '',
  vignette: data?.PolicyMotor?.Vignette || '',
  plateCode: data?.Vehicle?.PlateCode || '',
  plateNumber: data?.Vehicle?.PlateNumber || '',
  usageType: data?.Vehicle?.UsageType || '',
  bodyType: data?.Vehicle?.BodyType || '',
  engineType: data?.Vehicle?.EngineType || '',
  make: data?.Vehicle?.BrandCode || '',
  model: data?.Vehicle?.ModelCode || '',
  color: data?.Vehicle?.ColorCode || '',
  yearOfMake: data?.Vehicle?.YearOfMake || '',
  engine: data?.Vehicle?.Motor || '',
  weight: data?.Vehicle?.Weight || '',
  engineSize: data?.Vehicle?.EngineSize || '',
  seatingCapacity: data?.Vehicle?.Seats || '',
  chassis: data?.Vehicle?.Chassis || '',
  horsePower: data?.Vehicle?.HorsePower || '',
  carValue: data?.Vehicle?.CarValue || '',
  agencyRepairYear: data?.PolicyMotor?.AgencyRepairYear || '',
  depreciationYears: data?.PolicyMotor?.DepreciationYears || '',
});

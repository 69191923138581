import React from 'react';
import InformationBox from '../../../components/information-box/InformationBox';
import { convertToTitleCase } from '../../../utils/helper-utils';
import { IProposalCover } from './form';
import { IProposalDetailsSummary } from './index2';
import OfferCoverage from './OfferCoverage';
import DateService from '../../../services/dateService';
import { InformationDataType } from '../../../components/information-box';

interface IPolicyProposalQuoteWidgetProps {
  data: IProposalDetailsSummary;
  covers: IProposalCover[];
}

const PolicyProposalQuoteWidget: React.FC<IPolicyProposalQuoteWidgetProps> = ({
  data,
  covers,
}) => {
  const coversData: Record<string, any> = {};
  const coverHeaders: Record<string, any> = {};
  const currency = data.PricingOption.PricingOptionCurrencySymbol;

  covers.forEach((cover, index) => {
    const coverName = cover.name || 'Cover' + index;
    coversData[coverName] = cover.coverPremiumValue;
    coverHeaders[coverName] = {
      name: coverName,
      title: coverName,
      type: InformationDataType.RoundedCurrency,
      urlTemplate: null,
      actionConfig: null,
      classificationPropertyName: null,
      iconUrl: null,
      openUrlInNewTab: false,
      tooltipSettings: null,
      textTrimLimit: null,
      currencySymbol: currency,
    };
  });

  const tabs = [
    {
      title: 'Offer Details',
      name: 'Details',
      data: {
        Details: {
          Plan: data.PlanID.Name,
          PolicyEffectiveDate: data.PolicyEffectiveDate,
          PolicyExpiryDate: data.PolicyExpiryDate,
        },
      },
      supportsGrouping: false,
      groupingValues: ['Details'],
      groupingTitle: null,
      headers: {
        Plan: {
          name: 'Plan',
          title: 'Plan',
          type: InformationDataType.Text,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
        },
        PolicyEffectiveDate: {
          name: 'PolicyEffectiveDate',
          title: 'Effective Date',
          type: InformationDataType.Date,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
        },
        PolicyExpiryDate: {
          name: 'PolicyExpiryDate',
          title: 'Expiry Date',
          type: InformationDataType.Date,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
        },
      },
      supportCycleDivision: false,
    },
    {
      title: 'Covers',
      name: 'Covers',
      data: {
        Covers: coversData,
      },
      supportsGrouping: false,
      groupingValues: ['Covers'],
      groupingTitle: null,
      headers: coverHeaders,
      supportCycleDivision: false,
    },
    {
      title: 'Breakdown',
      name: 'Breakdown',
      data: {
        Breakdown: {
          BasicPremium: data.PricingOption.BasicPremium,
          NetPremium: data.PricingOption.NetPremium,
          PolicyCost: data.PricingOption.PolicyCost,
          PolicyCostAmount: data.PricingOption.PolicyCostAmount,
          ChargesAmount: data.PricingOption.ChargesAmount,
          TPATotalFeesAmount: data.PricingOption.TPATotalFeesAmount,
          TotalPremium: data.PricingOption.TotalPremium,
          GrossPremium: data.PricingOption.GrossPremium,
          FixedStampAmount: data.PricingOption.FixedStampAmount,
          MunicipalityTaxAmount: data.PricingOption.MunicipalityTaxAmount,
          ProportionalStampAmount: data.PricingOption.ProportionalStampAmount,
        },
      },
      supportsGrouping: false,
      groupingValues: ['Breakdown'],
      groupingTitle: null,
      headers: {
        BasicPremium: {
          name: 'BasicPremium',
          title: 'Basic Premium',
          type: InformationDataType.RoundedCurrency,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        NetPremium: {
          name: 'NetPremium',
          title: 'Net Premium',
          type: InformationDataType.RoundedCurrency,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        PolicyCostAmount: {
          name: 'PolicyCostAmount',
          title: 'Policy Cost',
          type: InformationDataType.RoundedCurrency,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        ChargesAmount: {
          name: 'ChargesAmount',
          title: 'Charges',
          type: InformationDataType.RoundedCurrency,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        TPATotalFeesAmount: {
          name: 'TPATotalFeesAmount',
          title: 'TPA Fees',
          type: InformationDataType.RoundedCurrency,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        GrossPremium: {
          name: 'GrossPremium',
          title: 'Gross Premium',
          type: InformationDataType.RoundedCurrency,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        FixedStampAmount: {
          name: 'FixedStampAmount',
          title: 'Fixed Stamp',
          type: InformationDataType.RoundedCurrency,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        ProportionalStampAmount: {
          name: 'ProportionalStampAmount',
          title: 'Proportional Stamp',
          type: InformationDataType.RoundedCurrency,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        MunicipalityTaxAmount: {
          name: 'MunicipalityTaxAmount',
          title: 'Municipality Tax',
          type: InformationDataType.RoundedCurrency,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
        TotalPremium: {
          name: 'TotalPremium',
          title: 'Total Premium',
          type: InformationDataType.RoundedCurrency,
          urlTemplate: null,
          actionConfig: null,
          classificationPropertyName: null,
          iconUrl: null,
          openUrlInNewTab: false,
          tooltipSettings: null,
          textTrimLimit: null,
          currencySymbol: currency,
        },
      },
      supportCycleDivision: false,
    },
  ] as any[];

  return (
    <InformationBox
      visibleRows={0}
      title={'Offer Details'}
      status={convertToTitleCase(data.PricingOption.Status)}
      statusReason={convertToTitleCase(data.PricingOption.StatusReason || '')}
      statusIcon={''}
      statusColor={''}
      reviewedOn={''}
      subtitle={`Created On: ${DateService.formatDate(data.PricingOption.CreatedOn)}`}
      header={
        <>
          <OfferCoverage
            disabled={false}
            premium={data.PricingOption.TotalPremium}
            currency={currency}
            premiumTitle={''}
            coverageTitle={'Coverage'}
            coverage={data.PricingOption.SumInsured}
            hidePremium={false}
          />
        </>
      }
      tabs={tabs}
      collapsible={false}
      initiallyCollapsed={false}
    />
  );
};

export default PolicyProposalQuoteWidget;

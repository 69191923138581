import React from 'react';
import ViewBreakdown, { IFormValues } from './ViewBreakdown';
import EnhancedModal from '../../../../../components/common/generic-modal/EnhancedModal';
import { IPaymentTermsGeneralData } from '../../../../../forms/payment-terms';

interface IViewBreakdownShellProps {
  open: boolean;
  onClose: () => void;
  data: Partial<IFormValues>;
  generalData: IPaymentTermsGeneralData;
}

const ViewBreakdownShell: React.FC<IViewBreakdownShellProps> = ({
  open,
  onClose,
  data,
  generalData,
}) => {
  return (
    <EnhancedModal
      open={open}
      blur={true}
      onClose={onClose}
      title={`Installments Breakdown (${generalData.bill.type === 'production' ? generalData.policy.number : generalData.amendment.number})`}
    >
      <ViewBreakdown billId={generalData.bill.id} data={data} />
    </EnhancedModal>
  );
};

export default ViewBreakdownShell;

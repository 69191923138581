import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../../../components/widgets/custom-listing-filter';
import { FormInputTypes } from '../../../../DynamicForm';
import { IAbstractRecord } from '../../../../models';

export const headers: Record<string, EnhancedTableHeader> = {
  Production_Facultatives_FacultativeID: {
    name: 'Production_Facultatives_FacultativeID',
    title: 'Facultative ID',
    type: EnhancedTableHeaderType.Link,
    urlTemplate: '/reinsurance/facultatives/{facultativeId}',
    openUrlInNewTab: false,
  },
  Production_Facultatives_SlipReferenceNumber: {
    name: 'Production_Facultatives_SlipReferenceNumber',
    title: 'Slip Reference No.',
    type: EnhancedTableHeaderType.Text,
  },
  Proposal_ProposalName: {
    name: 'Proposal_ProposalName',
    title: 'Proposal Name',
    type: EnhancedTableHeaderType.Text,
  },
  policyName: {
    name: 'policyName',
    title: 'Policy Name',
    type: EnhancedTableHeaderType.Text,
  },
  MainReinsurerDetails_FullName: {
    name: 'MainReinsurerDetails_FullName',
    title: 'Main Reinsurer',
    type: EnhancedTableHeaderType.Text,
  },
  Production_Facultatives_PaymentDueDate: {
    name: 'Production_Facultatives_PaymentDueDate',
    title: 'Payment Due Date',
    type: EnhancedTableHeaderType.Date,
  },
  Proposal_Status: {
    name: 'Proposal_Status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
  Proposal_createdOn: {
    name: 'Proposal_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        proposal: {
          name: 'proposal',
          title: 'Proposal',
          type: FormInputTypes.newmultiautocomplete,
          value: filterValues?.proposal || null,
          placeholder: 'Select a proposal',
          triggerUpdate: true,
          canClearValue: true,
        },
        policy: {
          name: 'policy',
          title: 'Policy',
          type: FormInputTypes.newmultiautocomplete,
          value: filterValues?.policy || null,
          placeholder: 'Select a policy',
          triggerUpdate: true,
          canClearValue: true,
        },
        mainReinsurer: {
          name: 'mainReinsurer',
          title: 'Main Reinsurer',
          type: FormInputTypes.newselect,
          value: filterValues?.mainReinsurer || null,
          placeholder: 'Select a main reinsurer',
          triggerUpdate: true,
          selectOptions: { ...lovs.mainReinsurer },
          canClearValue: true,
        },
        status: {
          name: 'status',
          title: 'Status',
          type: FormInputTypes.newselect,
          value: filterValues?.status || null,
          placeholder: 'Select a status',
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
          canClearValue: true,
        },
        createdOn: {
          name: 'createdOn',
          title: 'Created On',
          type: FormInputTypes.daterange,
          value: filterValues?.createdOn || null,
          triggerUpdate: true,
          canClearDate: true,
        },
      },
    },
  ];
  return newFilterSections;
}

import { isEmpty } from 'lodash';
import { IAbstractRecord } from '../../../../models';
import { IListingData } from '../../../../models/listing';
import { separateCapicalCharacters } from '../../../../utils/formatting-utils';

export const mapToTreatyDistTableData = (
  data: IAbstractRecord[]
): IListingData => {
  if (isEmpty(data)) {
    return {
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    };
  }

  const list: Record<string, IAbstractRecord> = {};

  const groupedAmounts = data?.reduce(
    (
      acc: {
        [key: string]: {
          totalCededSumInsured: number;
          totalCededNetPremium: number;
        };
      },
      treaty: IAbstractRecord
    ) => {
      const key = treaty.policyPlanCover_Id;

      if (!acc[key]) {
        acc[key] = {
          totalCededSumInsured: 0,
          totalCededNetPremium: 0,
        };
      }

      acc[key].totalCededSumInsured +=
        treaty.production_PolicyTreatyDistribution_CededSumInsured;
      acc[key].totalCededNetPremium +=
        treaty.production_PolicyTreatyDistribution_CededNetPremium;

      return acc;
    },
    {}
  );

  data.forEach((item: IAbstractRecord) => {
    list[item.policyPlanCover_Id] = {
      coverName: item.policyCover_Name,
      treatyName: item.treatyDetails_TreatyName,
      treatyCurrency: item.treatyDetails_TreatyCurrency?.Title,
      treatyEffectiveDate: item.treatyDetails_EffectiveDate,
      treatyExpiryDate: item.treatyDetails_ExpiryDate,
      mainReinsurer: item.mainReinsurerDetails_FullName,
      totalCededSumInsured:
        groupedAmounts[item.policyPlanCover_Id].totalCededSumInsured,
      totalCededNetPremium:
        groupedAmounts[item.policyPlanCover_Id].totalCededNetPremium,
      totalCommission: item.production_PolicyTreatyDistribution_Commission,
      Currency: item.treatyDetails_TreatyCurrency?.Symbol,
    };
  });

  return {
    pagedItems: list,
    pageSize: 10,
    pageNumber: 1,
    totalCount: Object.keys(list).length,
  };
};

export const mapToBreakdownTableData = (
  data: IAbstractRecord[]
): IListingData => {
  if (isEmpty(data)) {
    return {
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    };
  }

  const list: Record<string, IAbstractRecord> = {};

  data.forEach((item: IAbstractRecord) => {
    list[item.production_PolicyTreatyDistribution_Id] = {
      layerType: separateCapicalCharacters(
        item.production_PolicyTreatyDistribution_LayerType
      ),
      excessAmount: item.production_PolicyTreatyDistribution_ExcessAmount,
      retainedSumInsured:
        item.production_PolicyTreatyDistribution_RetainedSumInsured,
      retainedNetPremium:
        item.production_PolicyTreatyDistribution_RetainedNetPremium,
      retainedNetPremiumBasic:
        item.production_PolicyTreatyDistribution_RetainedNetPremiumBasic,
      retainedNetPremiumSecondary:
        item.production_PolicyTreatyDistribution_RetainedNetPremiumSecondary,
      cededSumInsured: item.production_PolicyTreatyDistribution_CededSumInsured,
      cededNetPremium: item.production_PolicyTreatyDistribution_CededNetPremium,
      cededNetPremiumBasic:
        item.production_PolicyTreatyDistribution_CededNetPremiumBasic,
      cededNetPremiumSecondary:
        item.production_PolicyTreatyDistribution_CededNetPremiumSecondary,
      commission: item.production_PolicyTreatyDistribution_Commission,
      Currency: item.production_PolicyTreatyDistribution_PolicyCurrency,
    };
  });

  return {
    pagedItems: list,
    pageSize: 10,
    pageNumber: 1,
    totalCount: Object.keys(list).length,
  };
};

export const mapToFacDistTableData = (data: IAbstractRecord): IListingData => {
  if (isEmpty(data)) {
    return {
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    };
  }

  const list: Record<string, IAbstractRecord> = {};

  data.forEach((item: IAbstractRecord) => {
    list[item.facultative_Id] = {
      facId: item.facultative_Id,
      coverName: item.cover_Name,
      facSlipRefNum: item.facultative_SlipReferenceNumber,
      coverCurrency: item.policyPricing_PricingOptionCurrency?.Title,
      coverEffectiveDate: item.policyPlanCover_CoverEffectiveFrom,
      coverExpiryDate: item.policyPlanCover_CoverEffectiveTo,
      mainReinsurer: item.mainReinsurerDetails_FullName,
      cededSumInsured:
        item.production_PolicyFacultativeDistribution_CededSumInsured,
      cededNetPremium:
        item.production_PolicyFacultativeDistribution_CededNetPremium,
      commission: item.production_PolicyFacultativeDistribution_Commission,
      Currency: item.policyPricing_PricingOptionCurrency?.Symbol, // Sets the symbol for all table cells of type currency
    };
  });

  return {
    pagedItems: list,
    pageSize: 10,
    pageNumber: 1,
    totalCount: Object.keys(list).length,
  };
};

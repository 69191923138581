import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { IListingData } from '../../../models/listing';
import StaticLayout from '../../../page-layout/static-layout/StaticLayout';
import { listQuery } from './queries';
import { mapToListingData } from './utils';
import { filterSectionsContent, headers } from './content';
import { IListingFilterWidgetSection } from '../../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../../models';
import { getFilter, setFilter } from '../../../utils/filter-utils';
import { DEFAULT_ERROR_TEXT } from '../../../constants';
import { capitalizeFirstCharacter } from '../../../utils/formatting-utils';
import ListingWidget from '../../../components/form-fields/listing-widget/ListingWidget';
import { IFilterModel } from '../../../components/form-fields/listing-widget';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import DateService from '../../../services/dateService';

const PAGE_CONTEXT = 'Lines';
const FILTER_SESSION_KEY = 'linesFilter';

const LinesPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });
  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          lineStatus: [],
          createdDate: [],
        };
  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialfilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
  });

  const [lineListQuery] = useLazyQuery(listQuery());

  const getListingData = (filter = filterValues) => {
    if (!filter) {
      setLoading(false);
      return {};
    }

    setFilter(filter, FILTER_SESSION_KEY);
    setFilterValues(filter);

    const filterV = filter.namedFilters;
    const pagination = filter.pagination;

    setLoading(true);

    return lineListQuery({
      variables: {
        keywordSearch: filter.searchKey,
        currentPage: pagination.pageNumber || 1,
        currentPageSize: pagination.pageSize || 10,
        Attribute:
          capitalizeFirstCharacter(filter.orderBy) ||
          'PlanConfigManagement_Line_Name',
        Descending: filter.descending,
        lineStatus: filterV?.status?.length ? filterV?.status : null,
        createdDateFrom: DateService.formatDateBackend(
          filterV.createdDate?.[0]
        ),
        createdDateTo: DateService.formatDateBackend(filterV.createdDate?.[1]),
      },
    })
      .then(({ data }) => {
        if (data) {
          const newTableData = mapToListingData(data);
          setTableData(newTableData);
          const newFilterSections = filterSectionsContent(data, filterV);
          setFilterSections(newFilterSections);
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = async (
    page: number,
    filterModel = filterValues
  ): Promise<void> => {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  };

  const onFilterUpdate = (filters: IAbstractRecord) => {
    const newFilters = {
      ...filterValues,
      namedFilters: filters,
    };
    if (_.isEqual(newFilters, filterValues)) {
      // Do nothing if filters are the same
      return;
    }

    getListingData(newFilters);
  };

  const renderMainChildren = () => (
    <ListingWidget
      title="Lines"
      name="lines"
      orderByAscendingByDefault
      data={tableData}
      tableSettings={{
        headers,
      }}
      loading={loading}
      defaultOrderByColumn="planConfigManagement_Line_Name"
      pageContext={PAGE_CONTEXT}
      onPageChange={(filterModel) => {
        const newFilterModel = {
          ...filterValues,
          ...filterModel,
        };
        const page = filterModel.pagination.pageNumber;
        return handlePageChange(page, newFilterModel);
      }}
      actions={[]}
      disableSelection
      usePagination
    />
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        disabled={loading}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  return (
    <StaticLayout
      name="Lines"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default LinesPage;

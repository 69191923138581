import React, { useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import Loader from '../../components/Loader';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import { modifyExpat } from './queries';
import { useMutation } from '@apollo/client';
import { getInputs } from './content';
import { IExpatInfoDrawerProps } from '.';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { getError } from '../../utils/graph-utils';
import { isEmpty } from '../../utils/validationUtils';
import { useParams } from 'react-router-dom';

const ExpatDetailsDrawer: React.FC<IExpatInfoDrawerProps> = ({
  open,
  onClose,
  onSuccess,
  data,
  lovs,
}) => {
  const params = useParams();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [saveExpatDetails] = useMutation(modifyExpat());

  const inputForm = getInputs(lovs, data);

  const submitForm = async (values: Record<string, string>) => {
    const [formData] = normaliseDynamicValues(getInputs(lovs, data), values);
    setFormDisabled(true);
    setSubmitButtonState('loading');
    try {
      const variables = {
        entityId: params.id,
        expatInput: {
          medicalClass: formData.medicalClass,
          continuity: formData.continuity,
          continuityNumber: formData.continuityNumber,
          exclusion: formData.exclusion,
          remarks: formData.remarks,
          emergency: formData.emergency,
        },
      };

      const { errors } = await saveExpatDetails({ variables });
      if (isEmpty(errors)) {
        toast.success(
          <ToastSuccessMessage>
            Expat details updated successfully!
          </ToastSuccessMessage>
        );
        setSubmitButtonState('success');
        onSuccess();
        onClose();
      } else {
        setSubmitButtonState(undefined);
        const errorMessage = getError({ errors });
        toast.error(<ToastErrorMessage>{errorMessage}</ToastErrorMessage>);
      }
    } catch (error) {
      const errorMessage = getError({ error });
      toast.error(<ToastErrorMessage>{errorMessage}</ToastErrorMessage>);
      setSubmitButtonState(undefined);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title="Modify Expat Details"
      isOpen={!!open}
      onClose={onClose}
    >
      {!data ? (
        <Loader />
      ) : (
        <DynamicForm
          inputs={inputForm}
          onSubmit={submitForm}
          buttonText="Submit"
          submitButtonState={submitButtonState}
          disableForm={formDisabled}
          title="Information"
        />
      )}
    </GenericDrawer>
  );
};

export default ExpatDetailsDrawer;

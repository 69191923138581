import { IFacultativeDrawerLovs, IFacultativeDrawerValues } from '.';
import { DynamicFormInputType, FormInputTypes } from '../../../../DynamicForm';

export const getInputs = (
  lovs: IFacultativeDrawerLovs,
  values: IFacultativeDrawerValues
): Record<string, DynamicFormInputType> => {
  return {
    linkedTo: {
      name: 'linkedTo',
      title: 'Linked To',
      placeholder: 'Select Linked To',
      type: FormInputTypes.newselect,
      value: values.linkedTo,
      selectOptions: {
        proposal: 'Proposal',
        policy: 'Policy',
      },
      required: true,
    },
    proposal: {
      name: 'proposal',
      title: 'Proposal',
      placeholder: 'Select Proposal',
      type: FormInputTypes.newautocomplete,
      value: values.proposal,
      conditionalHidden: (values) => values.linkedTo !== 'proposal',
      conditionalRequired: (values) => values.linkedTo === 'proposal',
    },
    policy: {
      name: 'policy',
      title: 'Policy',
      placeholder: 'Select Policy',
      type: FormInputTypes.newautocomplete,
      value: values.policy,
      conditionalHidden: (values) => values.linkedTo !== 'policy',
      conditionalRequired: (values) => values.linkedTo === 'policy',
    },
    company: {
      name: 'company',
      title: 'Company',
      type: FormInputTypes.newtext,
      value: values.company,
      disabled: true,
      hidden: true,
    },
    currencySymbol: {
      name: 'currencySymbol',
      title: 'Currency',
      placeholder: 'Currency',
      type: FormInputTypes.newtext,
      value: values.currencySymbol,
      disabled: true,
      required: true,
    },
    cover: {
      name: 'cover',
      title: 'Cover',
      placeholder: 'Select Cover',
      type: FormInputTypes.newselect,
      selectOptions: lovs.covers?.name,
      value: values.cover,
      required: true,
    },
    coverEffectiveDate: {
      name: 'coverEffectiveDate',
      title: 'Cover Effective Date',
      type: FormInputTypes.newdate,
      value: values.coverEffectiveDate,
      required: true,
      disabled: true,
    },
    coverExpiryDate: {
      name: 'coverExpiryDate',
      title: 'Cover Expiry Date',
      type: FormInputTypes.newdate,
      value: values.coverExpiryDate,
      required: true,
      disabled: true,
    },
    reinsurer: {
      name: 'reinsurer',
      title: 'Reinsurer',
      placeholder: 'Type to search reinsurers',
      initialOptions: lovs.reinsurers,
      type: FormInputTypes.newautocomplete,
      value: values.reinsurer,
      required: true,
    },
  };
};

import { IAbstractRecord } from '../../../models';
import { CSSProperties } from 'react';
import { IListingData } from '../../../models/listing';

export type EnhancedUpdatedTableHeader =
  | IEnhanceUpdatedTableHeaderBadge
  | IEnhanceUpdatedTableHeaderClickable
  | IEnhanceUpdatedTableHeaderDate
  | IEnhanceUpdatedTableHeaderDateAndTime
  | IEnhanceUpdatedTableHeaderLink
  | IEnhanceUpdatedTableHeaderText
  | IEnhanceUpdatedTableHeaderColoredCurrency
  | IEnhanceUpdatedTableHeaderCurrency
  | IEnhanceUpdatedTableHeaderPercentage
  | IEnhanceUpdatedTableHeaderRate
  | IEnhanceUpdatedTableHeaderClassification
  | IEnhanceUpdatedTableHeaderInput
  | IEnhanceUpdatedTablePhoneNumber
  | IEnhanceUpdatedTableHeaderImage;

export type EnhancedUpdatedTableFilterType =
  | IEnhancedUpdatedTableDateRangeFilter
  | IEnhancedUpdatedTableSelectFilter
  | IEnhancedUpdatedTableDateFilter;

export enum EnhancedUpdatedTableHeaderType {
  Badge = 'Badge',
  Clickable = 'Clickable',
  Date = 'Date',
  DateAndTime = 'DateAndTime',
  Link = 'Link',
  Text = 'Text',
  Currency = 'Currency',
  ColoredCurrency = 'ColoredCurrency',
  Percentage = 'Percentage',
  RoundedPercentage = 'RoundedPercentage',
  Classification = 'Classification',
  Rate = 'Rate',
  Input = 'Input',
  PhoneNumber = 'PhoneNumber',
  IntPhoneNumber = 'IntPhoneNumber',
  Image = 'Image',
}

export interface IEnhancedUpdatedTableToolTipSettings {
  headers: Record<string, EnhancedUpdatedTableHeader>;
  tooltipApi: string;
}

export interface IEnhancedUpdatedTableHeaderBase {
  title: string;
  name: string;
  type: EnhancedUpdatedTableHeaderType;
  tooltip?: string;
  tooltipSettings?: IEnhancedUpdatedTableToolTipSettings;
  sortable?: boolean;
  whiteSpacePre?: boolean; // Use this to display tooltip on same line
}

export interface IEnhancedUpdatedTableFilters {
  filters: Record<string, IEnhancedUpdatedTableFilter>;
}

export interface IEnhancedUpdatedTableFilter {
  type: 'daterange' | 'select' | 'date';
  name: string;
  placeholder?: string;
  clientFilter?: boolean;
}
export interface IEnhancedUpdatedTableDateFilter
  extends IEnhancedUpdatedTableFilter {
  value: string | null;
  type: 'date';
}
export interface IEnhancedUpdatedTableDateRangeFilter
  extends IEnhancedUpdatedTableFilter {
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  value: Date[];
  type: 'daterange';
}

export interface IEnhancedUpdatedTableSelectFilter
  extends IEnhancedUpdatedTableFilter {
  selectedValues: string[];
  options: Record<string, string>;
  type: 'select';
}

export interface IBadgeConfig {
  color: string;
  backgroundColor: string;
}

export interface IEnhanceUpdatedTableHeaderText
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.Text;
}

export interface IEnhanceUpdatedTableHeaderCurrency
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.Currency;
}

export interface IEnhanceUpdatedTableHeaderColoredCurrency
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.ColoredCurrency;
  compareToKey?: string;
}

export interface IEnhanceUpdatedTableHeaderPercentage
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.Percentage;
}

export interface IEnhanceUpdatedTableHeaderRate
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.Rate;
}

export interface IEnhanceUpdatedTableHeaderClassification
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.Classification;
  classificationPropertyName: string;
}

export interface IEnhanceUpdatedTableHeaderDate
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.Date;
}

export interface IEnhanceUpdatedTableHeaderDateAndTime
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.DateAndTime;
}

export interface IEnhanceUpdatedTablePhoneNumber
  extends IEnhancedUpdatedTableHeaderBase {
  type:
    | EnhancedUpdatedTableHeaderType.PhoneNumber
    | EnhancedUpdatedTableHeaderType.IntPhoneNumber;
}

export interface IEnhanceUpdatedTableHeaderBadge
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.Badge;
  badgeConfig: Record<string, IBadgeConfig>;
}

export interface IEnhanceUpdatedTableHeaderClickable
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.Clickable;
  icon?: JSX.Element;
  buttonLabel?: string;
  buttonClass?: string;
  buttonType?: 'enhanced' | 'link';
  callback: (payload: any) => void;
}

export interface IEnhanceUpdatedTableHeaderInput
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.Input;
  inputType: EnhancedHeaderInputTypes;
  currencyIcon: string;
  currency: string;
  disabled?: boolean;
}

export interface IEnhanceUpdatedTableHeaderLink
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.Link;
  urlTemplate: string;
  openUrlInNewTab: boolean;
}

export enum EnhancedHeaderInputTypes {
  String = 'String',
  Number = 'Number',
  Currency = 'Currency',
}

export interface IEnhancedUpdatedTableMenuItem {
  title: string;
  onClick: (selected?: any[]) => void;
  isBulk?: boolean;
  isEntity?: boolean;
  disabled?: boolean;
  conditionalDisable?: (selected?: any[]) => boolean;
  returnAllData?: boolean;
}

export interface IEnhanceUpdatedTableHeaderImage
  extends IEnhancedUpdatedTableHeaderBase {
  type: EnhancedUpdatedTableHeaderType.Image;
  imageKey?: string;
}

export interface IEnhancedCommonProps {
  className?: string;
  style?: CSSProperties;
  loader?: boolean;
}

export interface IFilterCommonInputProps {
  name: string;
  // title: string;
  type: FilterInputTypes;
  placeholder?: string;
  value?: any;
  disabled?: boolean;
}

export enum FilterInputTypes {
  date = 'date',
  daterange = 'daterange',
  select = 'select',
}

export type FilterInputType =
  | IFilterDateRangeProps
  | IFilterSelectProps
  | IFilterDateProps;

export interface IFilterDateProps extends IFilterCommonInputProps {
  type: FilterInputTypes.date;
  value: string | null;
}

export interface IFilterDateRangeProps extends IFilterCommonInputProps {
  type: FilterInputTypes.daterange;
  min?: Date;
  max?: Date;
  value: Date[];
}

export interface IFilterSelectProps extends IFilterCommonInputProps {
  type: FilterInputTypes.select;
  splitCharacter?: string;
  selectedValues?: string[];
  options: Record<string, string>;
}

export interface IGroupTableProps {
  tables: {
    headers: Record<string, EnhancedUpdatedTableHeader>;
    rowSettings?: ITableRowSettings;
    data: IListingData<any>;
    title: string;
  }[];
}

export interface ITableRowSettings {
  iconType: string;
  iconUrl: string;
  iconTooltip: string;
  operator: ConditionOperator;
  conditions: ITableRowSettingsCondition[];
}

export interface ITableRowSettingsCondition {
  iconVisiblePropertyName: string;
  iconVisiblePropertyValues: Array<any>;
  operator: PropertyOperator;
}

export enum PropertyOperator {
  Equal = 0,
  NotEqual = 1,
  GreaterThan = 2,
  GreaterThanOrEqual = 3,
  LessThan = 4,
  LessThanOrEqual = 5,
}

export enum ConditionOperator {
  And = 0,
  Or = 1,
}

export interface IListingTableFilter {
  title: string;
  name: string;
  selectedValues: string[];
  options: string[];
}

export interface IEnhancedMenuItem {
  name: string;
  title: string;
  disabled?: boolean;
  target?: ActionTarget;
  iconUri?: string;
  isPrimary?: boolean;
  supportBulk?: boolean;
  displayActionPerRecord?: boolean;
  rowIndependant?: boolean;
  onClick: (payload?: string[]) => void;
}

export interface IEnhancedRow {
  key: string;
  columns: IAbstractRecord;
  index: number;
  disabled?: boolean;
}

export enum ActionTarget {
  Entity = 'Entity',
}

export interface IFilterModel {
  searchKey?: string;
  namedFilters?: IAbstractRecord;
  pagination?: IAbstractRecord;
  userId?: string;
  userRoles?: string[];
}

export interface IEnhancedTableFilter {
  type: 'daterange' | 'select' | 'date';
  name: string;
  placeholder?: string;
  clientFilter?: boolean;
}
export interface IEnhancedTableDateFilter extends IEnhancedTableFilter {
  value: string | null;
  type: 'date';
}
export interface IEnhancedTableDateRangeFilter extends IEnhancedTableFilter {
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  value: Date[];
  type: 'daterange';
}

export interface IEnhancedTableSelectFilter extends IEnhancedTableFilter {
  selectedValues: string[];
  options: Record<string, string>;
  type: 'select';
}

export type EnhancedTableFilterType =
  | IEnhancedTableDateRangeFilter
  | IEnhancedTableSelectFilter
  | IEnhancedTableDateFilter;

import { gql } from '@apollo/client';

export const getFacDetailsQuery = gql`
  query getFacDetails($FacultativeID: String!) {
    Production {
      queries {
        GetFacReinsurers(FacultativeID: $FacultativeID) {
          facultativeReinsurersBusinessPartner_Id
          facultativeReinsurersBusinessPartner_FullName
          production_FacultativeReinsurers_SharePercentage
          facultativeReinsurersBusinessPartner_RelatedCompany {
            Code
          }
        }
        GetFacDetails(FacultativeID: $FacultativeID) {
          production_Facultatives_LinkedTo
          production_Facultatives_FacultativeID
          production_Facultatives_CreatedByName
          production_Facultatives_createdOn
          production_Facultatives_UpdatedByName
          production_Facultatives_modifiedOn
          production_Facultatives_FacultativeStatus
          production_Facultatives_SlipReferenceNumber
          proposal_Id
          production_Facultatives_PaymentDueDate
          production_Facultatives_TotalCededSumInsured
          production_Facultatives_TotalCededPremium
          production_Facultatives_TotalCommission
          policy_Id
          policyPlanCover_Id
          policyPlanCover_CoverEffectiveFrom
          policyPlanCover_CoverEffectiveTo
          policyPlanCover_ProposalID {
            queries {
              production {
                pricingOption {
                  pricingOption_PricingOptionCurrency {
                    Code
                    Symbol
                  }
                }
              }
            }
          }
          policyPlanCover_SumInsuredIsAdditive
          facultativeCovers_CededSumInsured
          facultativeCovers_CededPremium
          facultativeCovers_FacultativeRate
          facultativeCovers_CommissionRate
          facultativeCovers_CommissionAmount
        }
        GetActiveProposals {
          production_Proposal_Id
          production_Proposal_ProposalName
        }
        getPolicyListWithoutPagination {
          production_Policy_Id
          production_Policy_PolicyName
        }
      }
    }
  }
`;

export const getProposalDetailsQuery = gql`
  query getProposalDetails($proposalId: String!) {
    Production {
      queries {
        getPolicyPlanCovers(selectedProposalID: $proposalId) {
          production_PolicyPlanCover_Id
          policyCover_Name
          production_PolicyPlanCover_CoverEffectiveFrom
          production_PolicyPlanCover_CoverEffectiveTo
        }
      }
      entities {
        proposal(id: $proposalId) {
          queries {
            production {
              pricingOption {
                pricingOption_PricingOptionCurrency {
                  Code
                  Symbol
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getPolicyDetailsQuery = gql`
  query getPolicyDetails($policyId: String!) {
    Production {
      queries {
        getPolicyPlanCovers(selectedPolicyID: $policyId) {
          production_PolicyPlanCover_Id
          policyCover_Name
          production_PolicyPlanCover_CoverEffectiveFrom
          production_PolicyPlanCover_CoverEffectiveTo
        }
      }
      entities {
        policy(id: $policyId) {
          queries {
            production {
              pricingOption {
                pricingOption_PricingOptionCurrency {
                  Code
                  Symbol
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getCoverDetailsQuery = gql`
  query getFacCoverDetails($CoverID: String!) {
    Production {
      queries {
        GetPolicyPlanCoverDetails(CoverID: $CoverID) {
          production_PolicyPlanCover_CoverSumInsured
          production_PolicyPlanCover_CoverPremiumValue
          production_PolicyPlanCover_SumInsuredIsAdditive
        }
      }
    }
  }
`;

export const calculateRemainingAmountsMutation = gql`
  mutation calculateRemainingSIAndNP(
    $Proposal: String
    $Policy: String
    $SelectedCover: String!
    $EnterCededSumInsured: Decimal!
    $EnterCededPremium: Decimal!
  ) {
    production {
      actions {
        calculateRemainingSIAndNP(
          Proposal: $Proposal
          Policy: $Policy
          SelectedCover: $SelectedCover
          EnterCededSumInsured: $EnterCededSumInsured
          EnterCededPremium: $EnterCededPremium
        ) {
          RemainingCoverSumInsured
          RemainingCoverPremium
        }
      }
    }
  }
`;

export const saveFacultativeMutation = gql`
  mutation saveFacultative(
    $CurrentFacID: String!
    $Proposal: String
    $Policy: String
    $SlipReferenceNumber: String!
    $TotalCededPremium: Decimal!
    $TotalCededSumInsured: Decimal!
    $TotalCommission: Decimal!
    $PaymentDueDate: Date!
    $FacCoverList: [Production_FacCoverList!]!
  ) {
    production {
      actions {
        saveFacultative(
          CurrentFacID: $CurrentFacID
          Proposal: $Proposal
          Policy: $Policy
          SlipReferenceNumber: $SlipReferenceNumber
          TotalCededPremium: $TotalCededPremium
          TotalCededSumInsured: $TotalCededSumInsured
          TotalCommission: $TotalCommission
          PaymentDueDate: $PaymentDueDate
          FacCoverList: $FacCoverList
        )
      }
    }
  }
`;

export const updateFacReinsurerMutation = gql`
  mutation updateFacReinsurer($CurrentFacID: String!, $Reinsurer: String!) {
    production {
      actions {
        updateFacultativeReinsurer(
          CurrentFacID: $CurrentFacID
          Reinsurer: $Reinsurer
        )
      }
    }
  }
`;

export const getFacDocumentsQuery = gql`
  query getFacultativeDocuments($FacultativeID: String!) {
    Production {
      queries {
        GetFacDocuments(FacultativeID: $FacultativeID) {
          production_FacultativeDocuments_createdOn
          production_FacultativeDocuments_DocumentName
          production_FacultativeDocuments_DocumentType
          production_FacultativeDocuments_Id
          production_FacultativeDocuments_Version
          production_FacultativeDocuments_Document {
            contentType
            fileName
            id
            length
            location
            path
          }
        }
      }
    }
  }
`;

export const activateFacultativeMutation = gql`
  mutation activateFacultative($CurrentFacultativeID: String!) {
    production {
      actions {
        activateFacultative(CurrentFacID: $CurrentFacultativeID) {
          id
        }
      }
    }
  }
`;

export const closeFacultativeMutation = gql`
  mutation closeFacultative($CurrentFacultativeID: String!) {
    production {
      actions {
        closeFacultative(CurrentFacultativeID: $CurrentFacultativeID) {
          id
        }
      }
    }
  }
`;

export const getFacAuditTrailsQuery = gql`
  query getFacAuditTrail($FacultativesID: String!) {
    Production {
      queries {
        GetFacAuditTrails(FacultativesID: $FacultativesID) {
          production_AuditTrail_createdOn
          production_AuditTrail_Id
          production_AuditTrail_Message
        }
      }
    }
  }
`;

import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState, useRef } from 'react';
import FlatPicker from 'flatpickr';
import ClearIcon from '@mui/icons-material/Clear';
import { IEnhancedCommonProps } from '..';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import DateService from '../../services/dateService';

export interface IEnhancedDateRangePickerProps extends IEnhancedCommonProps {
  name: string;
  title: string;
  error?: string;
  disabled?: boolean;
  value: string[];
  placeholder?: string;
  maxDate?: Date;
  minDate?: Date;
  onDateRangeChange: (values: string[]) => void;
}

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  flatInput: {
    cursor: 'pointer',
    visibility: 'hidden',
    position: 'absolute',
    height: '0',
    width: '0',
    margin: '0',
    padding: '0',
    border: '0',
    bottom: '0',
    left: '50%',
  },
  input: {
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    lineHeight: '15px',
    height: 35,
    width: '100%',
    padding: '0 15px',
    margin: '0 auto',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    textAlign: 'left',
    cursor: 'pointer',
    color: MAIN_ONE_THEME.palette.primary1.main,
    '&:disabled': {
      cursor: 'not-allowed',
      color: 'rgba(0, 0, 0, 0.5)!important',
      opacity: `0.6 !important`,
      textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.5) !important`,
    },
    outline: 'none',
    fontFamily: contentFontFamilyRegular,
  },
  placeholder: {
    color: 'rgba(0, 0, 0, 0.20)',
    fontStyle: 'italic',
    fontFamily: contentFontFamilyRegular,
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
  },
  clearButton: {
    cursor: 'pointer',
    height: '12px',
    width: '12px',
    position: 'absolute',
    right: '8px',
    bottom: '50%',
    top: 'calc(50% - 7.5px)',
    color: 'rgba(0, 0, 0, 0.25)',
    fontSize: '1.0rem !important',
  },
}));

const EnhancedDateRangePicker: React.FC<IEnhancedDateRangePickerProps> = ({
  className,
  style,
  name,
  title,
  error,
  disabled = false,
  value = [],
  maxDate,
  minDate,
  placeholder,
  onDateRangeChange,
}) => {
  const [flatpicker, setFlatPicker] = useState<FlatPicker.Instance>();
  const flatPickerInput = useRef<HTMLInputElement>();
  const { classes } = useStyles();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const onValueUpdate = async (selectedDates: Date[]) => {
    const startDateValue =
      selectedDates[0] && DateService.isValidDate(selectedDates[0])
        ? DateService.formatDateBackend(selectedDates[0])
        : '';

    const endDateValue =
      selectedDates[1] && DateService.isValidDate(selectedDates[1])
        ? DateService.formatDateBackend(selectedDates[1])
        : '';
    setStartDate(startDateValue);
    setEndDate(endDateValue || startDateValue);
  };

  useEffect(() => {
    if (value && value.length > 0) {
      setStartDate(value[0]);
      setEndDate(value[1]);
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  }, [value]);

  useEffect(() => {
    if (flatpicker) {
      flatpicker.config.disable = [
        () => {
          return false;
        },
      ] as any;
    }
  }, [disabled]);

  useEffect(() => {
    const defaultValue = [
      startDate && DateService.isValidDate(startDate)
        ? new Date(startDate)
        : '',
      endDate && DateService.isValidDate(endDate) ? new Date(endDate) : '',
    ];
    if (flatpicker) {
      flatpicker.destroy();
    }
    if (flatPickerInput && flatPickerInput.current) {
      setFlatPicker(
        FlatPicker(flatPickerInput.current as any, {
          enableTime: false,
          altInput: true,
          altFormat: 'F j, Y',
          dateFormat: DateService.FRONTEND_DATE_FORMAT,
          mode: 'range',
          minDate: minDate ? new Date(minDate) : undefined,
          maxDate: maxDate ? new Date(maxDate) : undefined,
          disableMobile: true,
          defaultDate: defaultValue,
          onClose: async (selectedDates: Date[]) => {
            const startDateValue =
              selectedDates[0] && DateService.isValidDate(selectedDates[0])
                ? DateService.formatDateBackend(selectedDates[0])
                : '';

            const endDateValue =
              selectedDates[1] && DateService.isValidDate(selectedDates[1])
                ? DateService.formatDateBackend(selectedDates[1])
                : '';
            if (startDateValue || endDateValue) {
              onDateRangeChange([
                startDateValue,
                endDateValue || startDateValue,
              ]);
            } else {
              onDateRangeChange([]);
            }
          },
          onValueUpdate,
        }) as FlatPicker.Instance
      );
    }

    return () => flatpicker && flatpicker.destroy();
  }, [onDateRangeChange]);

  const renderDateValue = () => {
    if (startDate && endDate) {
      return `${DateService.formatDate(startDate)} to ${DateService.formatDate(
        endDate
      )}`;
    }
    return (
      <span className={classes.placeholder}>
        {placeholder || 'Select Date Range'}
      </span>
    );
  };

  const renderInput = () => {
    return (
      <div className={classes.container}>
        <button
          type="button"
          className={classes.input}
          disabled={disabled}
          onClick={() => {
            if (!disabled && flatpicker) {
              flatpicker.open();
            }
          }}
        >
          {renderDateValue()}
        </button>
        <ClearIcon
          className={classes.clearButton}
          onClick={() => {
            onDateRangeChange([]);
          }}
        />
        <input
          className={classes.flatInput}
          type="hidden"
          ref={flatPickerInput as any}
        />
      </div>
    );
  };

  return (
    <EnhancedInputsWrapper
      title={title}
      error={error}
      name={name}
      className={className}
      style={style}
    >
      {renderInput()}
    </EnhancedInputsWrapper>
  );
};

export default EnhancedDateRangePicker;

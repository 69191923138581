import { cloneDeep } from 'lodash';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';

import { IFormState } from '../form';
import { IMarineGroupCertificateGroupCover, IProposalCover } from '..';
import { IProductionDetailsSummary } from '../../../..';
import EnhancedChipInput from '../../../../../../../../components/enhanced-form/EnhancedChipInput';
import { isEmpty } from '../../../../../../../../utils/validationUtils';
import EnhancedCurrencyInput from '../../../../../../../../components/enhanced-form/EnhancedCurrencyInput';
import EnhancedPercentageInput from '../../../../../../../../components/enhanced-form/EnhancedPercentageInput';
import EnhancedFormattedNumberInput from '../../../../../../../../components/enhanced-form/EnhancedFormattedNumberInput';
import EnhancedDatePicker from '../../../../../../../../components/enhanced-form/EnhancedDatePicker';
import {
  removeObjectAtIndex,
  updateFormField,
} from '../../../../../../../../utils/helper-utils';
import WidgetPaper from '../../../../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../../../../components/common/WidgetSection';
import { validateCoverRepeaterField } from '../validation';

const initialValues = {
  initialError: {
    isMandatory: '',
    premium: '',
    currency: '',
    coverSumInsured: '',
    sumInsuredIsAdditive: '',
    excessOnClaimType: '',
    excessOnClaimAmount: '',
    excessOnClaimPercentage: '',
    excessOnClaimDays: '',
    coverEffectiveFrom: '',
    coverEffectiveTo: '',
  },
  initialTouched: {
    isMandatory: false,
    premium: false,
    currency: false,
    coverSumInsured: false,
    sumInsuredIsAdditive: false,
    excessOnClaimType: false,
    excessOnClaimAmount: false,
    excessOnClaimPercentage: false,
    excessOnClaimDays: false,
    coverEffectiveFrom: false,
    coverEffectiveTo: false,
  },
};

interface IPolicyDetailsWidgetProps {
  pageState: IFormState;
  onPageStateUpdate: (pageState: IFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  defaultPlanCoversList: Record<string, IProposalCover>;
  data: IProductionDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '33px 23px 23px',
    marginBottom: '14px',
    position: 'relative',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 23.5%)`,
    gap: '0 2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    margin: '20px auto 0',
    display: 'block',
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: '8px',
  },
  clearBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  label: {
    color: '#000000',
    margin: '0px!important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  labelCurrency: {
    color: '#000000',
    margin: '8.5px 0 -1.5px 0 !important',
    padding: '0px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  materialInput: {
    backgroundColor: 'unset',
    border: 'none',
    borderBottom: `1px solid #E5E5E5`,
    color: '#5A5A5A',
    margin: '0 auto',
    borderRadius: 0,
    padding: '10px 15px 4px 4px',
    fontFamily: 'SourceSansPro-Regular !important',
    fontSize: '14px',
  },
  selectMaterial: {
    width: '100%',
    '& .MuiInput-input': {
      fontFamily: 'SourceSansPro-Regular !important',
      fontSize: '14px',
      lineHeight: '15px',
      color: '#5A5A5A',
      paddingBottom: '0px !important',
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.20)',
        fontStyle: 'italic',
        opacity: '10',
        fontSize: '14px',
      },
    },
    '& .MuiInputBase-root:before': {
      border: 'none',
      backgroundColor: 'unset !important',
      borderRadius: '0 !important',
      borderBottom: `1px solid #E5E5E5`,
    },
    '& .MuiInput-root': {
      height: '34px',
      paddingBottom: '0px !important',
      paddingLeft: '4px',
    },
    '& .MuiInput-root.Mui-disabled:before': {
      borderBottomStyle: 'solid',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
      {
        borderBottom: `1px solid #E5E5E5`,
      },
  },
  inputProps: {
    fontSize: '14px !important',
    width: '100%',
    backgroundColor: 'unset',
    '& .MuiInputBase-input': {
      width: '100%',
      backgroundColor: 'unset',
      color: '#5A5A5A',
      margin: '0 auto',
      fontFamily: 'SourceSansPro-Regular !important',
      fontSize: '14px !important',
    },
    '& .MuiFilledInput-root': {
      '& .MuiInputAdornment-root': {
        marginLeft: '-40px',
        fontFamily: 'SourceSansPro-Regular !important',
        fontSize: '14px !important',
      },
    },
    '& .MuiButtonBase-root': {
      marginRight: '1px',
    },
  },
}));

const MarineGroupPolicyCoversRepeaterWidget: React.FC<
  IPolicyDetailsWidgetProps
> = ({
  pageState,
  onPageStateUpdate,
  disabledForm,
  lovs,
  defaultPlanCoversList,
  data,
}) => {
  const { classes } = useStyles();
  const values = pageState.values.covers;
  const errors = pageState.errors.covers;
  const touched = pageState.touched.covers;

  const planToPolicyRate = pageState.values.policyDetails.planToPolicyRate;

  const onFieldBlur = (fieldName: string, rowIndex: number) => {
    const newPageState = cloneDeep(pageState);
    newPageState.touched.covers[rowIndex][fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IMarineGroupCertificateGroupCover,
    value: any,
    rowIndex: number,
    touched = false
  ) => {
    const newPageState = cloneDeep(pageState);

    if (fieldName === 'policyCover') {
      const defaultPlanCover = defaultPlanCoversList[value];

      if (defaultPlanCover) {
        newPageState.values.covers[rowIndex] = defaultPlanCoversList[value];
        newPageState.errors.covers[rowIndex] = initialValues.initialError;
        newPageState.touched.covers[rowIndex] = initialValues.initialTouched;
      }
    }

    if (fieldName === 'excessOnClaimType') {
      newPageState.values.covers[rowIndex].excessOnClaimPercentage = null;
      newPageState.values.covers[rowIndex].excessOnClaimAmount = null;
      newPageState.values.covers[rowIndex].excessOnClaimDays = null;

      newPageState.errors.covers[rowIndex].excessOnClaimPercentage = '';
      newPageState.errors.covers[rowIndex].excessOnClaimAmount = '';
      newPageState.errors.covers[rowIndex].excessOnClaimDays = '';

      newPageState.touched.covers[rowIndex].excessOnClaimPercentage = false;
      newPageState.touched.covers[rowIndex].excessOnClaimAmount = false;
      newPageState.touched.covers[rowIndex].excessOnClaimDays = false;
    }

    newPageState.values.covers[rowIndex] = updateFormField(
      newPageState.values.covers[rowIndex],
      fieldName,
      value
    );

    const isValid = validateCoverRepeaterField(
      fieldName,
      value,
      newPageState.values.covers[rowIndex]
    );
    newPageState.errors.covers[rowIndex][fieldName] = isValid;

    if (touched) {
      newPageState.touched.covers[rowIndex][fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  const renderRow = (row: IMarineGroupCertificateGroupCover) => {
    const disabledRow =
      disabledForm ||
      !(data.BusinessUserID?.AllowPlanCoverEdits && row.isEditable);
    // status?.toUpperCase() === 'CLOSED_WON' ||
    // status?.toUpperCase() === 'CLOSED_LOST' ||
    // !row.isEditable ||
    // !allowPlanCoverEdits;

    const filteredPlanLov = { ...lovs.coversList };
    values.forEach((r) => {
      if (r !== row && r.policyCover) {
        delete filteredPlanLov[r.policyCover];
      }
    });

    const rowIndex = values.indexOf(row);

    return (
      <div key={`policyCover-${rowIndex}`} className={classes.container}>
        <div className={classes.clearBtn}>
          {!disabledForm && !(row?.isMain || row?.isMandatory) && (
            <button
              className={classes.removeBtn}
              onClick={() => onRowDelete(rowIndex)}
            >
              <ClearIcon fontSize="small" />
            </button>
          )}
        </div>
        <div className={classes.fieldRow}>
          <EnhancedChipInput
            key="policyCover"
            name="policyCover"
            title="Policy Cover*"
            placeholder="Policy Cover"
            value={values[rowIndex].policyCover}
            error={
              touched[rowIndex].policyCover ? errors[rowIndex].policyCover : ''
            }
            onChange={(v) => onFieldUpdate('policyCover', v, rowIndex)}
            disabled={
              disabledForm ||
              !(!row?.isMandatory && !row?.isMain) ||
              row.isInGroupPolicy
            }
            selectOptions={filteredPlanLov}
            required
            multiple={false}
            material
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.label,
              inputStyles: classes.selectMaterial,
            }}
          />
          <EnhancedCurrencyInput
            key="coverSumInsured"
            name="coverSumInsured"
            title="Sum Insured*"
            placeholder="Sum Insured"
            maxDecimalPercision={3}
            currencyText={data.PolicyCurrency.Symbol}
            useCurrencyText
            value={values[rowIndex].coverSumInsured * planToPolicyRate || ''}
            error={
              touched[rowIndex].coverSumInsured
                ? errors[rowIndex].coverSumInsured
                : ''
            }
            onBlur={() => onFieldBlur('coverSumInsured', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('coverSumInsured', v.target.value, rowIndex)
            }
            disabled={disabledRow}
            hidden={isEmpty(values[rowIndex].policyCover)}
            material
            customStyles={{
              labelStyles: classes.labelCurrency,
              inputStyles: classes.materialInput,
            }}
          />
          <EnhancedChipInput
            key="sumInsuredIsAdditive"
            name="sumInsuredIsAdditive"
            title="Additive SumInsured*"
            placeholder="Additive SumInsured"
            value={
              isEmpty(values[rowIndex].sumInsuredIsAdditive)
                ? ''
                : values[rowIndex].sumInsuredIsAdditive
                  ? 'YES'
                  : 'NO'
            }
            error={
              touched[rowIndex].sumInsuredIsAdditive
                ? errors[rowIndex].sumInsuredIsAdditive
                : ''
            }
            onChange={(v) =>
              onFieldUpdate(
                'sumInsuredIsAdditive',
                v === 'YES' ? true : false,
                rowIndex
              )
            }
            disabled={disabledRow}
            selectOptions={{ YES: 'Yes', NO: 'No' }}
            required
            multiple={false}
            hidden={isEmpty(values[rowIndex].policyCover)}
            material
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.label,
              inputStyles: classes.selectMaterial,
            }}
          />
          <EnhancedChipInput
            key="isMandatory"
            name="isMandatory"
            title="Mandatory*"
            placeholder="Mandatory"
            value={
              isEmpty(values[rowIndex].isMandatory)
                ? ''
                : values[rowIndex].isMandatory
                  ? 'YES'
                  : 'NO'
            }
            error={
              touched[rowIndex].isMandatory ? errors[rowIndex].isMandatory : ''
            }
            onChange={(v) =>
              onFieldUpdate('isMandatory', v === 'YES' ? true : false, rowIndex)
            }
            disabled={true}
            selectOptions={{ YES: 'Yes', NO: 'No' }}
            required
            multiple={false}
            hidden={isEmpty(values[rowIndex].policyCover)}
            material
            canClearSingleValueSelection={false}
            customStyles={{
              labelStyles: classes.label,
              inputStyles: classes.selectMaterial,
            }}
          />

          {!isEmpty(values[rowIndex].policyCover) && (
            <>
              <EnhancedCurrencyInput
                key="premium"
                name="premium"
                title="Premium*"
                placeholder="Premium"
                currencyText={data.PolicyCurrency.Symbol}
                useCurrencyText
                maxDecimalPercision={3}
                value={values[rowIndex].premium * planToPolicyRate || ''}
                error={
                  touched[rowIndex].premium ? errors[rowIndex].premium : ''
                }
                onBlur={() => onFieldBlur('premium', rowIndex)}
                onChange={(v) =>
                  onFieldUpdate('premium', v.target.value, rowIndex)
                }
                disabled={disabledRow}
                material
                customStyles={{
                  labelStyles: classes.labelCurrency,
                  inputStyles: classes.materialInput,
                }}
              />

              <EnhancedChipInput
                key="excessOnClaimType"
                name="excessOnClaimType"
                title="Excess Type*"
                placeholder="Excess Type"
                value={values[rowIndex].excessOnClaimType}
                error={
                  touched[rowIndex].excessOnClaimType
                    ? errors[rowIndex].excessOnClaimType
                    : ''
                }
                onChange={(v) =>
                  onFieldUpdate('excessOnClaimType', v, rowIndex)
                }
                disabled={disabledRow}
                selectOptions={{
                  PERCENTAGE: 'Percentage',
                  VALUE: 'Value',
                  DAYS: 'Days',
                }}
                required
                multiple={false}
                material
                canClearSingleValueSelection={false}
                customStyles={{
                  labelStyles: classes.label,
                  inputStyles: classes.selectMaterial,
                }}
              />
              {values[rowIndex].excessOnClaimType === 'PERCENTAGE' && (
                <EnhancedPercentageInput
                  key="excessOnClaimPercentage"
                  name="excessOnClaimPercentage"
                  title="Excess*"
                  placeholder="Excess On Claim"
                  value={values[rowIndex].excessOnClaimPercentage}
                  error={
                    touched[rowIndex].excessOnClaimPercentage
                      ? errors[rowIndex].excessOnClaimPercentage
                      : ''
                  }
                  onBlur={() =>
                    onFieldBlur('excessOnClaimPercentage', rowIndex)
                  }
                  onChange={(v) =>
                    onFieldUpdate(
                      'excessOnClaimPercentage',
                      v.target.value,
                      rowIndex
                    )
                  }
                  disabled={
                    disabledForm ||
                    !(
                      data.BusinessUserID?.AllowPlanCoverEdits && row.isEditable
                    )
                  }
                  material
                  customStyles={{
                    labelStyles: classes.labelCurrency,
                    inputStyles: classes.materialInput,
                  }}
                />
              )}
              {values[rowIndex].excessOnClaimType === 'VALUE' && (
                <EnhancedCurrencyInput
                  key="excessOnClaimAmount"
                  name="excessOnClaimAmount"
                  title="Excess*"
                  currencyText={data.PolicyCurrency.Symbol}
                  useCurrencyText
                  placeholder="Excess On Claim"
                  maxDecimalPercision={3}
                  value={
                    values[rowIndex].excessOnClaimAmount * planToPolicyRate ||
                    ''
                  }
                  error={
                    touched[rowIndex].excessOnClaimAmount
                      ? errors[rowIndex].excessOnClaimAmount
                      : ''
                  }
                  onBlur={() => onFieldBlur('excessOnClaimAmount', rowIndex)}
                  onChange={(v) =>
                    onFieldUpdate(
                      'excessOnClaimAmount',
                      v.target.value,
                      rowIndex
                    )
                  }
                  disabled={
                    disabledForm ||
                    !(
                      data.BusinessUserID?.AllowPlanCoverEdits && row.isEditable
                    )
                  }
                  material
                  customStyles={{
                    labelStyles: classes.labelCurrency,
                    inputStyles: classes.materialInput,
                  }}
                />
              )}
              {values[rowIndex].excessOnClaimType === 'DAYS' && (
                <EnhancedFormattedNumberInput
                  key="excessOnClaimDays"
                  name="excessOnClaimDays"
                  title="Excess (Days)*"
                  placeholder="Excess On Claim (Days)"
                  value={values[rowIndex].excessOnClaimDays}
                  error={
                    touched[rowIndex].excessOnClaimDays
                      ? errors[rowIndex].excessOnClaimDays
                      : ''
                  }
                  onBlur={() => onFieldBlur('excessOnClaimDays', rowIndex)}
                  onChange={(v) =>
                    onFieldUpdate('excessOnClaimDays', v.target.value, rowIndex)
                  }
                  disabled={
                    disabledForm ||
                    !(
                      data.BusinessUserID?.AllowPlanCoverEdits && row.isEditable
                    )
                  }
                  material
                  customStyles={{
                    labelStyles: classes.labelCurrency,
                    inputStyles: classes.materialInput,
                  }}
                />
              )}
              <EnhancedDatePicker
                key="coverEffectiveFrom"
                name="coverEffectiveFrom"
                title="Effective From*"
                placeholder="Effective From"
                value={
                  disabledRow
                    ? pageState.values.policyDetails.policyEffectiveDate
                    : values[rowIndex].coverEffectiveFrom
                }
                error={
                  touched[rowIndex].coverEffectiveFrom
                    ? errors[rowIndex].coverEffectiveFrom
                    : ''
                }
                onDateChange={(v) =>
                  onFieldUpdate('coverEffectiveFrom', v, rowIndex)
                }
                disabled={disabledRow}
                material
                onBlur={() => onFieldBlur('coverEffectiveFrom', rowIndex)}
                customStyles={{
                  labelStyles: classes.label,
                  inputStyles: classes.inputProps,
                }}
              />
              <EnhancedDatePicker
                key="coverEffectiveTo"
                name="coverEffectiveTo"
                title="Effective To*"
                placeholder="Effective To"
                value={
                  disabledRow
                    ? pageState.values.policyDetails.policyExpiryDate
                    : values[rowIndex].coverEffectiveTo
                }
                error={
                  touched[rowIndex].coverEffectiveTo
                    ? errors[rowIndex].coverEffectiveTo
                    : ''
                }
                onDateChange={(v) =>
                  onFieldUpdate('coverEffectiveTo', v, rowIndex)
                }
                disabled={disabledRow}
                material
                onBlur={() => onFieldBlur('coverEffectiveTo', rowIndex)}
                customStyles={{
                  labelStyles: classes.label,
                  inputStyles: classes.inputProps,
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const onRowAdd = () => {
    const newPageState = cloneDeep(pageState);
    newPageState.values.covers.push({
      isMain: null,
      isMandatory: null,
      policyCover: '',
      premium: null,
      currency: '',
      isEditable: true,
      coverSumInsured: null,
      sumInsuredIsAdditive: null,
      excessOnClaimType: '',
      excessOnClaimAmount: null,
      excessOnClaimPercentage: null,
      excessOnClaimDays: null,
      // clauseID: '',
      planCoverId: '',
      coverEffectiveFrom: '',
      coverEffectiveTo: '',
    });

    newPageState.errors.covers.push({ ...initialValues.initialError });
    newPageState.touched.covers.push({ ...initialValues.initialTouched });

    onPageStateUpdate(newPageState);
  };

  const onRowDelete = (rowIndex: number) => {
    const newPageState = cloneDeep(pageState);
    newPageState.values.covers = removeObjectAtIndex(
      newPageState.values.covers,
      rowIndex
    );
    newPageState.errors.covers = removeObjectAtIndex(
      newPageState.errors.covers,
      rowIndex
    );
    newPageState.touched.covers = removeObjectAtIndex(
      newPageState.touched.covers,
      rowIndex
    );
    onPageStateUpdate(newPageState);
  };

  const shouldDisplayPlus = () => {
    const filteredPlanLov = { ...lovs.planCovers };

    values.forEach(
      (r) => r.policyCover && delete filteredPlanLov[r.policyCover]
    );

    return !disabledForm && Object.keys(filteredPlanLov).length > 0;
  };

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection
        title="Policy Covers"
        hasTitleSpecificDesign={false}
        useSeparator={false}
      >
        {values.map((v) => renderRow(v))}
        {shouldDisplayPlus() && (
          <button className={classes.addBtn} onClick={onRowAdd}>
            <AddCircleOutlineIcon fontSize="small" color="error" />
          </button>
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default MarineGroupPolicyCoversRepeaterWidget;

import { IAbstractRecord } from '../../../../models';
import { formatEnum } from '../../../../utils/formatting-utils';

const BillStatusMapper = {
  NOT_DUE: 'Not Due',
  DUE: 'Due',
  BILLED: 'Billed',
  OVERDUE: 'Overdue',
  PARTIALLY_PAID: 'Partially Paid',
  PAID: 'Paid',
};

export const transformPolicyArrayToObject = (
  dataArray: IAbstractRecord[],
  paymentDivisions: Record<string, string>
) => {
  if (!dataArray.length) {
    return {};
  }

  // const receivableBills: string[] = [];
  // const payableBills: string[] = [];
  // const groupedAmounts = dataArray?.reduce(
  //   (acc: { [key: string]: Record<string, number> }, bill: IAbstractRecord) => {
  //     const key = bill.policy_Id;

  //     if (!acc[key]) {
  //       acc[key] = {};
  //       acc[key].AmountReceived = 0;
  //       acc[key].AmountPaid = 0;
  //     }

  //     if (!receivableBills.includes(bill.receivablesPayment_Id)) {
  //       acc[key].AmountReceived += Number(
  //         bill.receivablesPayment_AmountReceivedToBillCurrency
  //       );
  //       receivableBills.push(bill.receivablesPayment_Id);
  //     }
  //     if (!payableBills.includes(bill.payablePayment_Id)) {
  //       acc[key].AmountPaid += Number(
  //         bill.payablePayment_AmountReceivedToBillCurrency
  //       );
  //       payableBills.push(bill.payablePayment_Id);
  //     }
  //     return acc;
  //   },
  //   {}
  // );

  return dataArray?.reduce((acc, item) => {
    const key = item.policy_Id;

    acc[key] = {
      Id: item.policy_Id,
      PolicyNumber: item.policy_PolicyNumber,
      PaymentDivisionType:
        paymentDivisions[item.policy_PaymentDivisionType] || 'Equivalent',
      PaymentMode: item.policy_PaymentMode?.Title || 'Annually',
      TotalPremium: item.receivableBills_TotalPremium,
      AmountReceived: item.policy_TotalAmountReceived,
      TotalCommission: item.payableBills_TotalCommission,
      TaxOnCommission: item.payableBills_TaxOnCommission,
      AmountPaid: item.payableBills_AmountPaid,
      OutstandingPayable:
        Number(item.receivableBills_TotalPremium) -
        Number(item.receivableBills_AmountReceived) -
        (Number(item.payableBills_TotalCommission) -
          Number(item.payableBills_TaxOnCommission) -
          Number(item.payableBills_AmountPaid)),
      Currency: item.receivableBills_Currency?.Symbol,
    };
    return acc;
  }, {});
};

export const transformReceivableBillsArrayToObject = (
  dataArray: IAbstractRecord[]
) => {
  if (!dataArray?.length) {
    return {};
  }

  const receivableBills = dataArray?.filter(
    (bill) => bill.accounting_Bills_BillType === 'RECEIVABLE'
  );

  return receivableBills.reduce((acc, item) => {
    acc[item.accounting_Bills_Id] = {
      Id: item.accounting_Bills_Id,
      BillType:
        item.parentBill_ParentBillCategory === 'PRODUCTION'
          ? formatEnum(item.parentBill_ParentBillCategory)
          : item.linkedAmendment_AmendmentNumber,
      BillNumber: item.accounting_Bills_BillNumber,
      DueDate: item.accounting_Bills_DueDate,
      AmountDue: item.accounting_Bills_AmountDue,
      NetPremium: item.accounting_Bills_NetPremium,
      TotalPremium: item.accounting_Bills_TotalPremium,
      AmountReceived: item.accounting_Bills_AmountReceived,
      OutstandingBalance: item.accounting_Bills_OutstandingBalance,
      AmendmentNumber: item.linkedAmendment_AmendmentNumber,
      BillStatus:
        BillStatusMapper[
          item.accounting_Bills_BillStatus as keyof typeof BillStatusMapper
        ],
      Currency: item.accounting_Bills_Currency?.Symbol,
    };
    return acc;
  }, {});
};

export const transformPayableBillsArrayToObject = (
  dataArray: IAbstractRecord[]
) => {
  if (!dataArray?.length) {
    return {};
  }

  return dataArray?.reduce((acc, item) => {
    acc[item.accounting_Bills_Id] = {
      Id: item.accounting_Bills_Id,
      BillType:
        item.parentBill_ParentBillCategory === 'PRODUCTION'
          ? formatEnum(item.parentBill_ParentBillCategory)
          : item.linkedAmendment_AmendmentNumber,
      BillNumber: item.accounting_Bills_BillNumber,
      DueDate: item.accounting_Bills_DueDate,
      Discount: item.accounting_Bills_Discount,
      TotalCommission: item.accounting_Bills_TotalCommission,
      TaxOnCommission: item.accounting_Bills_TaxOnCommission,
      AmountReceived: item.accounting_Bills_AmountPaid,
      OutstandingBalance: item.accounting_Bills_OutstandingBalance,
      BillStatus:
        BillStatusMapper[
          item.accounting_Bills_BillStatus as keyof typeof BillStatusMapper
        ],
      Currency: item.accounting_Bills_Currency?.Symbol,
    };
    return acc;
  }, {});
};

import { gql } from '@apollo/client';

export const resetPasswordQuery = gql`
  mutation ResetPassword($email: String!) {
    system {
      actions {
        resetPassword(email: $email)
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { filterQuery, lineFilter, listQuery } from './queries';
import { dataToLovs, mapToListingData } from './utils';
import { getHeaders, filterSectionsContent } from './content';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../redux/hooks';
import { IListingFilterWidgetSection } from '../../../../../components/widgets/custom-listing-filter';
import { getFilter, setFilter } from '../../../../../utils/filter-utils';
import { IAbstractRecord } from '../../../../../models';
import { IListingData } from '../../../../../models/listing';
import { DEFAULT_ERROR_TEXT } from '../../../../../constants';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import EnhancedTable from '../../../../../components/enhanced-table/EnhancedTable';
import ListingFilterWidget from '../../../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import Loader from '../../../../../components/Loader';
import StaticLayout from '../../../../../page-layout/static-layout/StaticLayout';
import DateService from '../../../../../services/dateService';

const GroupPoliciesListingPage: React.FC = () => {
  const navigate = useNavigate();
  const headers = getHeaders(navigate);
  const user = useAppSelector((state) => state.user);

  const businessUserId = user.info.businessUser_Id;

  const [booted, setBooted] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const FILTER_SESSION_KEY = 'groupPolicyFilter';

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          line: [],
          subline: [],
          plan: [],
          businessPartner: [],
          insured: [],
          SelectedStatuses: [],
          SelectedStatusReason: [],
          effectiveDate: [],
          issueDate: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getProductionPoliciesLazy] = useLazyQuery(listQuery(), {
    fetchPolicy: 'no-cache',
  });
  const [filterResponseLazy] = useLazyQuery(filterQuery(), {
    fetchPolicy: 'no-cache',
  });
  const [filterLineLazy] = useLazyQuery(lineFilter(), {
    fetchPolicy: 'no-cache',
  });

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    filterV = filterValues
  ) => {
    setLoadingState(true);
    try {
      // setSelectedLineId(filterV.line);

      const [policiesResult, filterResponseResult, filterLineResult] =
        await Promise.all([
          getProductionPoliciesLazy({
            variables: {
              currentPage: currentPage + 1,
              currentPageSize: pageSize,
              selectedLines:
                filterV?.line && filterV?.line?.length > 0 ? filterV?.line : [],
              selectedSublines:
                filterV?.subline && filterV?.subline?.length > 0
                  ? filterV?.subline
                  : [],
              selectedPlans:
                filterV?.plan && filterV?.plan?.length > 0 ? filterV?.plan : [],
              selectedBusinessPartners:
                filterV?.businessPartner && filterV?.businessPartner?.length > 0
                  ? filterV?.businessPartner
                  : [],
              selectedInsuredIDs:
                filterV?.insured && filterV?.insured?.length > 0
                  ? filterV?.insured
                  : [],
              SelectedStatuses:
                filterV?.status && filterV?.status?.length > 0
                  ? filterV.status
                  : null,
              selectedStatusReasons:
                filterV?.statusReason && filterV?.statusReason?.length > 0
                  ? filterV.statusReason
                  : null,
              effectiveDateFrom: DateService.formatDateBackend(
                filterV.effectiveDate?.[0]
              ),
              effectiveDateTo: DateService.formatDateBackend(
                filterV.effectiveDate?.[1]
              ),

              issueDateFrom: DateService.formatDateBackend(
                filterV.issueDate?.[0]
              ),
              issueDateTo: DateService.formatDateBackend(
                filterV.issueDate?.[1]
              ),
              type: 'GROUP',
            },
          }),
          filterResponseLazy({
            variables: {
              selectedBusinessUserID: businessUserId,
              selectedLineID: filterV.line,
            },
          }),
          filterLineLazy({
            variables: {
              selectedBusinessUserID: businessUserId,
            },
          }),
        ]);

      if (policiesResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }
      if (filterResponseResult.error || filterLineResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }

      const policiesData = policiesResult.data;
      const filtersData = filterResponseResult.data;
      const lineFiltersData = filterLineResult.data;

      // Update table data
      const newTableData = mapToListingData(policiesData);
      setTableData({ ...newTableData, pageNumber: currentPage, pageSize });

      const savedFilters = filterV;

      // Update filters
      const lovs = dataToLovs(filtersData, lineFiltersData, filterV);

      setFilterValues(savedFilters);

      if (!Object.keys(lovs.line).length) {
        lovs.line = filterV?.line;
      }

      if (!Object.keys(lovs.subline).length) {
        lovs.subline = filterV?.subline;
      }

      if (!Object.keys(lovs.plan).length) {
        lovs.plan = filterV?.plan;
      }
      if (savedFilters.subline) {
        savedFilters.subline = savedFilters.subline.filter((val: string) =>
          Object.keys(lovs.subline).includes(val)
        );
      }
      if (savedFilters.plan) {
        savedFilters.plan = savedFilters.plan.filter((val: string) =>
          Object.keys(lovs.plan).includes(val)
        );
      }

      if (!Object.keys(lovs.businessPartner).length) {
        lovs.businessPartner = filterV?.businessPartner;
      }

      if (!Object.keys(lovs.insured).length) {
        lovs.insured = filterV?.insured;
      }

      if (!Object.keys(lovs.status).length) {
        lovs.status = filterV?.status;
      }

      if (!Object.keys(lovs.statusReason).length) {
        lovs.statusReason = filterV?.statusReason;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);

      setFilter(savedFilters, FILTER_SESSION_KEY);
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoadingState(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    const savedFilters = getFilter(FILTER_SESSION_KEY);
    await loadData(0, tableData.pageSize, savedFilters);
  };

  useEffect(() => {
    initialize();
  }, []);

  const handlePageChange = (page: number) => {
    loadData(page, tableData.pageSize, filterValues);
  };

  const handleRowsPerPageChange = (numberOfRecordsPerPage: number) => {
    loadData(0, numberOfRecordsPerPage, filterValues);
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    await loadData(0, tableData.pageSize, newFilters);
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: '20px' }}>
      <EnhancedTable
        title="Group Policies"
        name="policies"
        orderByAscendingByDefault
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        currentPage={tableData.pageNumber}
        hideToolbar
        isServerSide
        usePagination
        disableSelection
        actions={[]}
        loader={loadingState}
      />
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        disabled={loadingState}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name="policiesListingPage"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default GroupPoliciesListingPage;

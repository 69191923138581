import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  exportMotorPolicies,
  filterLovsQuery,
  getMotorAgentInfo,
  listQuery,
} from './queries';
import { dataToLovs, mapToListingData } from './utils';
import { filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _, { isEmpty } from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import {
  IEnhancedTableMenuItem,
  IEnhanceTableHeaderClickable,
} from '../../components/enhanced-table';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { IConfirmation } from '../../redux/confirmation/types';
import { getError } from '../../utils/graph-utils';
import { OpenConfirmationAction } from '../../redux/confirmation/actions';
import DownloadJsonasExcel from '../../components/custom/DownloadJsonasExcel';
import Loader from '../../components/Loader';
import { ITableOrder, TableSortOrder } from '../../utils/table-utils';
import { capitalizeFirstCharacter } from '../../utils/formatting-utils';
import DateService from '../../services/dateService';

const PolicyListing: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });
  const FILTER_SESSION_KEY = 'motorPoliciesFilter';

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          agency: [],
          plan: [],
          effectiveDate: [],
          issueDate: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const [actions, setActions] = useState<IEnhancedTableMenuItem[]>([]);

  const agentInfoResult = useQuery(getMotorAgentInfo(), {
    variables: { id: user.info.agentId },
    skip: user.userRoles.includes('Insurance-Admin'),
  });

  const excelHeaders = {
    insuranceCustomer_FullName: {
      label: 'Customer',
      visible: true,
      isDate: false,
    },
    insurance_Policy_FullPolicyNumber: {
      label: 'Policy Number',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Product: {
      label: 'Product',
      visible: true,
      isDate: false,
    },
    insurance_Policy_PolicyEffectiveDate: {
      label: 'Policy Effective Date',
      visible: true,
      isDate: true,
    },
    insurance_Policy_PolicyIssueDate: {
      label: 'Policy Issue Date',
      visible: true,
      isDate: true,
    },
    insurance_Policy_PolicyExpiryDate: {
      label: 'Policy Expiry Date',
      visible: true,
      isDate: true,
    },
    insuranceAgency_AgencyName: {
      label: 'Business Partner',
      visible: true,
      isDate: false,
    },
    insuranceAgent_FirstName: {
      label: 'Business User',
      visible: true,
      isDate: false,
    },
    insurance_Policy_TotalAnnualPremium: {
      label: 'Premium',
      visible: true,
      isDate: false,
    },
    insurance_Policy_IsRenewal: {
      label: 'Renewal',
      visible: true,
      isDate: false,
    },
    insurance_Policy_RenewalNumber: {
      label: 'Renewal Number',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Vignette: {
      label: 'Vignette',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_PlateNumber: {
      label: 'Plate Number',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_UsageType: {
      label: 'Usage',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_BodyType: {
      label: 'Body',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_EngineType: {
      label: 'Engine Type',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Brand: {
      label: 'Make and Model',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_YearOfMake: {
      label: 'Year of Make',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Motor: {
      label: 'Motor',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Weight: {
      label: 'Weight (in Tons)',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_EngineSize: {
      label: 'Engine Size (in cc)',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Seats: {
      label: 'Seating Capacity',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_Chassis: {
      label: 'Chassis',
      visible: true,
      isDate: false,
    },
    insurancePolicyMotorDetails_HorsePower: {
      label: 'Horse Power',
      visible: true,
      isDate: false,
    },
    insurance_Policy_AgencyCommission: {
      label: 'Commission',
      visible:
        user.userRoles.includes('Insurance-Admin') ||
        (!isEmpty(agentInfoResult) &&
          agentInfoResult?.data?.Insurance?.entities?.agent?.views
            ?.Insurance_all?.properties?.CanViewCommission),
      isDate: false,
    },
  };

  const specialHeaders = {
    'Business User': ['insuranceAgent_FirstName', 'insuranceAgent_LastName'],
    'Plate Number': [
      'insurancePolicyMotorDetails_PlateCode',
      'insurancePolicyMotorDetails_PlateNumber',
    ],
    Vignette: [
      'insurancePolicyMotorDetails_VignetteCode',
      'insurancePolicyMotorDetails_Vignette',
    ],
  };

  const [getApplicationsLazy] = useLazyQuery(listQuery());
  const [getLovsLazy] = useLazyQuery(filterLovsQuery(), {
    fetchPolicy: 'no-cache',
  });
  const [exportMotorPoliciesLazy] = useLazyQuery(exportMotorPolicies());

  const [keywordSearch, setKeywordSearch] = useState('');
  const [tableOrder, setTableOrder] = useState<Record<string, ITableOrder>>({
    policy: {
      orderBy: '',
      orderDirection: 'desc',
    },
  });

  let setSearchTimeout: NodeJS.Timeout;

  const handleSearchChange = (search: string) => {
    setKeywordSearch(search);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      handleSearchChange(val);
      handlePageChange(0, tableData.pageSize, tableOrder, filterValues, val);
    }, 1000);
  };

  const handleSortChange = (orderBy: string, order: string) => {
    const newOrder = _.cloneDeep(tableOrder);

    newOrder.policy.orderBy = orderBy;
    newOrder.policy.orderDirection = order as 'asc' | 'desc';
    handlePageChange(
      tableData.pageNumber,
      tableData.pageSize,
      newOrder,
      filterValues,
      keywordSearch
    );
    setTableOrder(newOrder);
  };

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    filterV = filterValues,
    orders: Record<string, ITableOrder> = tableOrder,
    searchKeyword: string = keywordSearch
  ) => {
    setLoading(true);
    try {
      const [applicationsResult, lovsResult] = await Promise.all([
        getApplicationsLazy({
          variables: {
            currentPage: currentPage + 1,
            currentPageSize: pageSize,
            agencyId:
              filterV?.agency && filterV?.agency?.length > 0
                ? filterV.agency
                : null,
            product:
              filterV?.plan && filterV?.plan?.length > 0 ? filterV.plan : null,
            effectiveDateFrom: DateService.formatDateBackend(
              filterV.effectiveDate?.[0]
            ),
            effectiveDateTo: DateService.formatDateBackend(
              filterV.effectiveDate?.[1]
            ),
            issueDateFrom: DateService.formatDateBackend(
              filterV.issueDate?.[0]
            ),
            issueDateTo: DateService.formatDateBackend(filterV.issueDate?.[1]),
            Attribute:
              capitalizeFirstCharacter(orders?.policy?.orderBy) ||
              'Insurance_Customer_FullName',
            Descending: orders?.policy?.orderDirection !== 'desc',
            keywordSearch: searchKeyword || null,
            agentType: user?.info?.agentType?.toUpperCase() || undefined,
          },
          fetchPolicy: 'no-cache',
        }),
        getLovsLazy(),
      ]);

      if (applicationsResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }
      if (lovsResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }

      const data = applicationsResult.data;
      const lovsData = lovsResult.data;

      if (data) {
        const newTableData = mapToListingData(data);
        setTableData({ ...newTableData, pageNumber: currentPage, pageSize });

        // Define actions based on data availability
        const newActions: IEnhancedTableMenuItem[] = [
          {
            title: 'Export Policies',
            onClick: () => {
              if (newTableData.totalCount > 0) {
                handleExportPolicies();
              } else {
                toast.info(
                  <ToastErrorMessage>
                    {'No Data To Be Exported'}
                  </ToastErrorMessage>
                );
              }
            },
            isEntity: false,
            isBulk: false,
            iconUrl: '',
            hidden: user.userRoles.includes('Insurance-Underwriter'),
          },
        ];
        setActions(newActions);
      }

      if (lovsData) {
        const savedFilters = getFilter(FILTER_SESSION_KEY) || filterV;

        if (!_.isEqual(savedFilters, filterValues)) {
          setFilterValues(savedFilters);
          setFilter(savedFilters, FILTER_SESSION_KEY);
        }

        const lovs = dataToLovs(lovsData);
        const newFilterSections = filterSectionsContent(
          lovs,
          savedFilters,
          user.userRoles
        );
        setFilterSections(newFilterSections);
      }

      (
        headers.insurance_Policy_FullName as IEnhanceTableHeaderClickable
      ).callback = (payload: any) => {
        navigate('/plm/policies/' + payload.columns.id);
      };
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    await loadData(0, tableData.pageSize, filterValues);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (booted) {
      handlePageChange(
        0,
        tableData.pageSize,
        tableOrder,
        filterValues,
        keywordSearch
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  const handlePageChange = async (
    page: number,
    pageSize: number = tableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrder,
    filterV = filterValues,
    searchKeyword: string = keywordSearch
  ) => {
    await loadData(page, pageSize, filterV, orders, searchKeyword);
  };

  const handleRowsPerPageChange = async (numberOfRecordsPerPage: number) => {
    await loadData(
      0,
      numberOfRecordsPerPage,
      filterValues,
      tableOrder,
      keywordSearch
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    if (_.isEqual(newFilters, filterValues)) {
      return;
    }
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    await handlePageChange(0, tableData.pageSize, tableOrder, v, keywordSearch);
  };

  const handleExportPolicies = async () => {
    const confirmation: IConfirmation = {
      open: true,
      title: 'Export Motor Policies',
      message: `Are you sure you want to export policies and download the Excel file?`,
      callback: async () => {
        try {
          const result = await exportMotorPoliciesLazy({
            variables: {
              currentPage: 1,
              currentPageSize: tableData.totalCount || 1, // Ensure we get all data
              products:
                filterValues?.plan && filterValues?.plan?.length > 0
                  ? filterValues.plan
                  : null,
              effectiveDateFrom: DateService.formatDateBackend(
                filterValues.effectiveDate?.[0]
              ),
              effectiveDateTo: DateService.formatDateBackend(
                filterValues.effectiveDate?.[1]
              ),
              issueFrom: DateService.formatDateBackend(
                filterValues.issueDate?.[0]
              ),
              issueTo: DateService.formatDateBackend(
                filterValues.issueDate?.[1]
              ),
              lineOfBusiness: 'Motor',
            },
          });

          if (isEmpty(result.error)) {
            const exportData =
              result.data?.Insurance?.queries?.ExportPolicies?.flat() ?? [];
            if (!isEmpty(exportData)) {
              dispatch(
                OpenConfirmationAction({
                  open: true,
                  title: 'Export Motor Policies',
                  message: (
                    <div>
                      <p>
                        {
                          'Are you sure you want to export policies and download the Excel file?'
                        }
                      </p>
                      <DownloadJsonasExcel
                        data={exportData}
                        filename="Motor Policy Listing"
                        headers={excelHeaders}
                        specialHeaders={specialHeaders}
                      />
                    </div>
                  ),
                  submitButtonText: 'Export',
                  cancelButtonText: 'Cancel',
                })
              );
            } else {
              toast.info(
                <ToastErrorMessage>
                  {'No Data To Be Exported!'}
                </ToastErrorMessage>
              );
            }
          } else {
            toast.error(
              <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
            );
          }
        } catch (error: any) {
          toast.error(<ToastErrorMessage>{error.message}</ToastErrorMessage>);
        }
      },
      submitButtonText: 'Export',
      cancelButtonText: 'Cancel',
    };
    dispatch(OpenConfirmationAction(confirmation));
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: '20px' }}>
      <EnhancedTable
        title="Motor Policies"
        name="count"
        orderByAscendingByDefault
        defaultOrderByColumn="insurance_Policy_FullName"
        inline={false}
        isServerSide
        data={tableData}
        headers={headers}
        handlePageChange={handlePageChange}
        handleSearchChange={delaySearch}
        handleSort={handleSortChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        currentPage={tableData.pageNumber}
        hideToolbar={false}
        usePagination
        disableSelection
        actions={actions}
        ordering={tableOrder.policy.orderDirection as TableSortOrder}
        orderingBy={tableOrder.policy.orderBy}
        isToolbarAction={false}
      />
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name={''}
        disabled={loading}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name={'Policies'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default PolicyListing;

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { PlanCover } from '../../../modules/production/policy/page';
import SelectFormField from '../../../components/form-fields/SelectFormField';
import TextInputFormField from '../../../components/form-fields/TextInputFormField';
import CurrencyFormField from '../../../components/form-fields/CurrencyFormField';

const useStyles = makeStyles()(() => ({
  container: {
    boxShadow: '0px 0px 10px #DFE3EBC9',
    border: '1px solid #E8E8E8',
    borderRadius: '4px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  headerRow: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr 2fr 2fr 40px',
    gridColumnGap: '8px',
    padding: '8px 16px',
    backgroundColor: '#F9F9F9',
    borderBottom: '1px solid #E8E8E8',
    fontFamily: 'SourceSansPro-SemiBold',
    fontSize: '15px',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr 2fr 2fr 40px',
    gridColumnGap: '8px',
    padding: '8px 16px',
    borderBottom: '1px solid #F2F2F2',
    alignItems: 'center',
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  addBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
}));

interface ICoverSplitProps {
  currencySymbol: string;
  rows: PlanCover[];
  lovs: Record<string, Record<string, string>>;
  onUpdateRows: (newRows: PlanCover[]) => void;
}

const CoverSplit: React.FC<ICoverSplitProps> = ({
  currencySymbol,
  rows,
  lovs,
  onUpdateRows,
}) => {
  const { classes } = useStyles();

  const handleAddRow = () => {
    const newRow: PlanCover = {
      planCoverID: '',
      riskRate: 0,
      sumInsured: 0,
      premium: 0,
    };
    onUpdateRows([...rows, newRow]);
  };

  const handleRemoveRow = (index: number) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    onUpdateRows(newRows);
  };

  const handleChange = <K extends keyof PlanCover>(
    index: number,
    field: K,
    value: PlanCover[K]
  ) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    onUpdateRows(newRows);
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerRow}>
        <span>Plan Cover ID</span>
        <span>Risk Rate</span>
        <span>Sum Insured</span>
        <span>Premium</span>
        <span />
      </div>

      {rows.map((row, index) => (
        <div key={`cover-${index}`} className={classes.row}>
          <SelectFormField
            name={`planCoverID-${index}`}
            title=""
            placeholder="Select cover..."
            inputOnly
            hideError
            value={row.planCoverID}
            onChange={(val) =>
              handleChange(index, 'planCoverID', val as string)
            }
            selectOptions={lovs.policyCovers}
          />
          <TextInputFormField
            name={`riskRate-${index}`}
            title=""
            inputOnly
            hideError
            value={row.riskRate?.toString() || '0'}
            onChange={(e) =>
              handleChange(index, 'riskRate', parseFloat(e.target.value) || 0)
            }
          />
          <CurrencyFormField
            name={`sumInsured-${index}`}
            hideError
            inputOnly
            maxDecimalPrecision={2}
            value={row.sumInsured}
            onChange={(e) =>
              handleChange(index, 'sumInsured', parseFloat(e.target.value) || 0)
            }
            title={''}
            currencySymbol={currencySymbol}
          />
          <CurrencyFormField
            name={`premium-${index}`}
            hideError
            inputOnly
            maxDecimalPrecision={2}
            value={row.premium}
            onChange={(e) =>
              handleChange(index, 'premium', parseFloat(e.target.value) || 0)
            }
            title={''}
            currencySymbol={currencySymbol}
          />

          <button
            className={classes.removeBtn}
            onClick={() => handleRemoveRow(index)}
          >
            <ClearIcon fontSize="small" />
          </button>
        </div>
      ))}

      <div className={classes.addBtnContainer}>
        <button className={classes.addBtn} onClick={handleAddRow}>
          <AddCircleOutlineIcon htmlColor="#C00020" fontSize="medium" />
        </button>
      </div>
    </div>
  );
};

export default CoverSplit;

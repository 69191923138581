import { IAbstractRecord } from '../../../../models';

export const mapItemsToExcelData = (data: IAbstractRecord[]) => {
  return data
    .map((item: IAbstractRecord) => {
      return {
        transactionNumber: item.accounting_Transactions_TransactionNumber,
        transactionType:
          item.accounting_Transactions_TransactionType?.LongTitle,
        referenceNumber: item.accounting_Transactions_ReferenceNumber,
        transactionDate: item.accounting_Transactions_TransactionDate,
        description: item.accounting_Transactions_Description,
        //add id name
        account:
          item.transactionDetails_RelatedAccount.views.Accounting_all.properties
            ?.AccountID +
          ' ' +
          item.transactionDetails_RelatedAccount.views.Accounting_all.properties
            ?.AccountName,
        recordDescription: item.transactionDetails_Description,
        accountDebit: item.transactionDetails_AccountDebit,
        accountCredit: item.transactionDetails_AccountCredit,
        basicDebit: item.transactionDetails_SystemDebit,
        basicCredit: item.transactionDetails_SystemCredit,
        secondaryDebit: item.transactionDetails_SystemSecondaryDebit,
        secondaryCredit: item.transactionDetails_SystemSecondaryCredit,
        currencySymbol:
          item.transactionDetails_RelatedAccount.views.Accounting_all.properties
            ?.AccountCurrency.Symbol,
      };
    })
    .sort((a, b) => {
      return Number(a.transactionNumber) - Number(b.transactionNumber);
    });
};

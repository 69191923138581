import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ILineDetails, initialValues } from '.';
import Loader from '../../../components/Loader';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import EntityInfoWidget from '../../../components/widgets/entity-info/EntityInfoWidget';
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from '../../../constants';
import {
  headers,
  lineDetailsTabs,
  planHeaders,
  claimCoverHeaders,
  steps,
  policyCoverHeaders,
  clauseHeaders,
  getEntityView,
} from './content';
import {
  activateLine,
  deactivateLine,
  getSubLineList,
  getLineDetailsInfo,
  getPlanList,
  getClaimCoverList,
  getPolicyCoverList,
  getClauseList,
} from './queries';
import {
  entityToLineDetails,
  getLineStatus,
  mapToClaimCoversListingData,
  mapToClausesListingData,
  mapToLSublinesListingData,
  mapToPlansListingData,
  mapToPolicyCoversListingData,
} from './utils';
import {
  capitalizeFirstCharacter,
  capitalizeFirstLetter,
} from '../../../utils/formatting-utils';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IListingData } from '../../../models/listing';
import WidgetSection from '../../../components/common/WidgetSection';
import EnhancedStepperSpecificDesign from '../../../components/common/EnhancedStepperSpecificDesign';
import { makeStyles } from 'tss-react/mui';
import TabsLayout from '../../../page-layout/tabs-layout/TabsLayout';
import { EnhancedButtonStatus } from '../../../components/EnhancedButton';
import { getError } from '../../../utils/graph-utils';
import ToastSuccessMessage from '../../../components/ToastSuccessMessage';
import { isEmpty } from '../../../utils/validationUtils';
import { IConfirmation } from '../../../redux/confirmation/types';
import { OpenConfirmationAction } from '../../../redux/confirmation/actions';
import ClaimCoverDrawer from '../../../forms/claim-cover-drawer/ClaimCoverDrawer';
import ClausePopUpShell from '../../../forms/clause-popup/ClausePopUpShell';
import PlanDrawer from '../../../forms/plan-drawer/PlanDrawer';
import PolicyCoverDrawer from '../../../forms/policy-cover-drawer/PolicyCoverDrawer';
import SublineDrawer from '../../../forms/subline-drawer/SublineDrawer';
import {
  ActionTarget,
  IEnhancedMenuItem,
} from '../../../components/form-fields/table';
import ListingTableWithPagination from '../../../components/form-fields/table/with-pagination/ListingTableWithPagination';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '90%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ': {
      color: MAIN_ONE_THEME.palette.primary5.main,
      '& .MuiStepIcon-text': {
        fontSize: 0,
        lineHeight: '0px',
        fill: 'black',
      },
    },

    '.Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
      '& .MuiStepIcon-text': {
        fontSize: 0,
        lineHeight: '0px',
        fill: 'black',
      },
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  section: {
    display: 'grid',
    gridTemplateColumns: '19.5% 19.5% 19.5% 19.5% 19.5%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '& label': {
      '& span': {
        margin: '-2px 0',
      },
    },
  },
  sectionTitle: {
    padding: '-10x 10x!important',
  },
  sectionFullRow: {
    display: 'grid',
    gridTemplateColumns: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  row: {
    flexWrap: 'wrap',
    margin: '15px 20px 10px 0px',
    alignItems: 'flex-start',
    display: 'grid',
    gap: '5px',
    '& label': {
      flexGrow: '1',
      flexBasis: '0',
      minWidth: '0',
    },
    gridTemplateColumns: '19.5% 19.5% 19.5% 19% 16% 3% 3%',
  },
  thickSeperator: {
    height: 10,
    margin: '10px 0 10px 0',
    gridColumnStart: '1',
    gridColumnEnd: '4',
  },
  field: {
    width: '96%',
    marginRight: '10px !important',
  },
  repeaterField: {
    margin: '0px!important',
  },
  arField: {
    width: '96%',
    marginRight: '10px !important',
    direction: 'rtl',
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    '&:hover': {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: '0',
  },
  dirtyChip: {
    margin: '0 0 -15px 0',
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    '& span': {
      color: '#ffffff',
    },
  },
  tabPanelStyle: {
    padding: 'unset',
    marginTop: '20px',
    justifyContent: 'flex-start',
  },
}));

const LineDetailsPage: React.FC = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);
  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();

  const [sublineDrawer, setSublineDrawer] = useState<{
    open: boolean;
    id?: string;
  }>({
    open: false,
  });

  const [planDrawer, setPlanDrawer] = useState<{
    open: boolean;
    id?: string;
    sublineStatus?: string;
    sublineID?: string;
  }>({
    open: false,
  });

  const [claimCoverDrawer, setClaimCoverDrawer] = useState<{
    open: boolean;
    id?: string;
  }>({ open: false });

  const [policyCoverDrawer, setPolicyCoverDrawer] = useState<{
    open: boolean;
    id?: string;
  }>({ open: false });

  const [clausePopUp, setClausePopUp] = useState<{
    open: boolean;
    id?: string;
  }>({ open: false });

  const dispatch = useAppDispatch();

  //#endregion

  //#region component hooks and states
  const [lineDetailsInfo, setLineDetailsInfo] =
    useState<ILineDetails>(initialValues);

  const entityId = params.id;

  //#endregion

  const isAdmin = user.userRoles.includes('Insurance-Admin');
  const sublineListingActions: IEnhancedMenuItem[] = isAdmin
    ? [
        {
          name: 'new',
          title: 'New',
          onClick: () => {
            setSublineDrawer({
              open: true,
            });
          },
          iconUri: tenant.cdnUrl + '/icons/add-primary-red.svg',
          target: ActionTarget.Entity,
          rowIndependant: true,
        },
        {
          name: 'edit',
          title: 'Edit',
          target: ActionTarget.Entity,
          iconUri: tenant.cdnUrl + '/icons/edit-secondary.svg',
          onClick: (selected) => {
            setSublineDrawer({
              open: true,
              id: selected?.[0],
            });
          },
        },
      ]
    : [];

  const planListingActions: IEnhancedMenuItem[] = isAdmin
    ? [
        {
          name: 'new',
          title: 'New',
          onClick: () => {
            setPlanDrawer({
              open: true,
              id: undefined,
            });
          },
          iconUri: tenant.cdnUrl + '/icons/add-primary-red.svg',
          target: ActionTarget.Entity,
          rowIndependant: true,
        },
        {
          name: 'edit',
          title: 'Edit',
          target: ActionTarget.Entity,
          iconUri: tenant.cdnUrl + '/icons/edit-secondary.svg',
          onClick: (selected) => {
            const selectedRowData = Object.values(planTableData.pagedItems)[
              Number(selected?.[0])
            ];
            setPlanDrawer({
              open: true,
              id: selected?.[0],
              sublineStatus: selectedRowData?.sublineStatus ?? '',
              sublineID: selectedRowData?.sublineID ?? '',
            });
          },
        },
      ]
    : [];

  const policyCoverListingActions: IEnhancedMenuItem[] = isAdmin
    ? [
        {
          name: 'new',
          title: 'New',
          onClick: () => {
            setPolicyCoverDrawer({
              open: true,
            });
          },
          iconUri: tenant.cdnUrl + '/icons/add-primary-red.svg',
          target: ActionTarget.Entity,
          rowIndependant: true,
        },
        {
          name: 'edit',
          title: 'Edit',
          target: ActionTarget.Entity,
          iconUri: tenant.cdnUrl + '/icons/edit-secondary.svg',
          onClick: (selected) => {
            setPolicyCoverDrawer({
              open: true,
              id: selected?.[0],
            });
          },
        },
      ]
    : [];

  const claimCoverListingActions: IEnhancedMenuItem[] = isAdmin
    ? [
        {
          name: 'new',
          title: 'New',
          onClick: () => {
            setClaimCoverDrawer({
              open: true,
            });
          },
          iconUri: tenant.cdnUrl + '/icons/add-primary-red.svg',
          target: ActionTarget.Entity,
          rowIndependant: true,
        },
        {
          name: 'edit',
          title: 'Edit',
          target: ActionTarget.Entity,
          iconUri: tenant.cdnUrl + '/icons/edit-secondary.svg',
          onClick: (selected) => {
            setClaimCoverDrawer({
              open: true,
              id: selected?.[0],
            });
          },
        },
      ]
    : [];

  const clauseListingActions: IEnhancedMenuItem[] = isAdmin
    ? [
        {
          name: 'new',
          title: 'New',
          onClick: () => {
            setClausePopUp({
              open: true,
            });
          },
          iconUri: tenant.cdnUrl + '/icons/add-primary-red.svg',
          target: ActionTarget.Entity,
          rowIndependant: true,
        },
        {
          name: 'edit',
          title: 'Edit',
          target: ActionTarget.Entity,
          iconUri: tenant.cdnUrl + '/icons/edit-secondary.svg',
          onClick: (selected) => {
            setClausePopUp({
              open: true,
              id: selected?.[0],
            });
          },
        },
      ]
    : [];

  //#region graphql hooks
  const [entityResultQuery, { loading: loadingPage }] =
    useLazyQuery(getLineDetailsInfo());
  const [activateAction] = useMutation(activateLine(), {
    variables: {
      entityId: entityId,
    },
    refetchQueries: [getLineDetailsInfo()],
  });
  const [deactivateAction] = useMutation(deactivateLine(), {
    variables: {
      entityId: entityId,
    },
    refetchQueries: [getLineDetailsInfo(), getSubLineList(), getPlanList()],
  });

  const [sublineData, setSublineData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [planTableData, setPlanTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });
  const [policyCoverTableData, setPolicyCoverTableData] =
    useState<IListingData>({
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    });
  const [claimCoverTableData, setClaimCoverTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });
  const [clauseTableData, setClauseTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [sublineListQuery, { loading: loadingSublines }] =
    useLazyQuery(getSubLineList());
  const [planListResultQuery, { loading: loadingPlans }] =
    useLazyQuery(getPlanList());
  const [claimCoverListQuery, { loading: loadingClaimCovers }] =
    useLazyQuery(getClaimCoverList());
  const [policyCoverListQuery, { loading: loadingPolicyCovers }] =
    useLazyQuery(getPolicyCoverList());
  const [clauseListQuery, { loading: loadingClauses }] =
    useLazyQuery(getClauseList());
  //#endregion

  const loadEntityData = async () => {
    const result = await entityResultQuery({
      variables: { id: entityId },
      errorPolicy: 'all',
    });

    return result;
  };

  const loadSublines = async (
    currentPage: number,
    pageSize = sublineData.pageSize,
    searchKey?: string,
    order?: string,
    orderBy?: string
  ) => {
    try {
      const result = await sublineListQuery({
        variables: {
          currentPage,
          currentPageSize: pageSize,
          SelectedLineId: params.id,
          Attribute: capitalizeFirstCharacter(
            orderBy || 'PlanConfigManagement_Subline_Name'
          ),
          Descending: order === 'desc',
          keywordSearch: searchKey || null,
        },
        errorPolicy: 'all',
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const data = result.data;
      const mappedSubList = mapToLSublinesListingData(data);
      setSublineData(mappedSubList);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const loadPlanList = async (
    currentPage: number,
    pageSize = sublineData.pageSize,
    searchKey?: string,
    order?: string,
    orderBy?: string
  ) => {
    const result = await planListResultQuery({
      variables: {
        currentPage: currentPage,
        currentPageSize: pageSize,
        SelectedLineId: params.id,
        Attribute: capitalizeFirstCharacter(
          orderBy || 'PlanConfigManagement_Plan_Name'
        ),
        Descending: order === 'desc',
        keywordSearch: searchKey,
      },
      errorPolicy: 'all',
    });
    return result;
  };

  const loadPolicyCoverList = async (
    currentPage: number,
    pageSize = sublineData.pageSize,
    searchKey?: string,
    order?: string,
    orderBy?: string
  ) => {
    const result = await policyCoverListQuery({
      variables: {
        currentPage: currentPage,
        currentPageSize: pageSize,
        SelectedLineId: params.id,
        Attribute: capitalizeFirstCharacter(
          orderBy || 'PlanConfigManagement_PolicyCover_Name'
        ),
        Descending: order === 'desc',
        keywordSearch: searchKey,
      },
      errorPolicy: 'all',
    });
    return result;
  };

  const loadCoverList = async (
    currentPage: number,
    pageSize = sublineData.pageSize,
    searchKey?: string,
    order?: string,
    orderBy?: string
  ) => {
    const result = await claimCoverListQuery({
      variables: {
        currentPage: currentPage,
        currentPageSize: pageSize,
        SelectedLineId: params.id,
        Attribute: capitalizeFirstCharacter(
          orderBy || 'PlanConfigManagement_ClaimCover_Name'
        ),
        Descending: order === 'desc',
        keywordSearch: searchKey,
      },
      errorPolicy: 'all',
    });
    return result;
  };

  const loadClauseList = async (
    currentPage: number,
    pageSize = sublineData.pageSize,
    searchKey?: string,
    order?: string,
    orderBy?: string
  ) => {
    const result = await clauseListQuery({
      variables: {
        currentPage: currentPage,
        currentPageSize: pageSize,
        selectedLineIds: params.id,
        Attribute: capitalizeFirstCharacter(
          orderBy || 'PlanConfigManagement_Clause_Name'
        ),
        Descending: order === 'desc',
        keywordSearch: searchKey,
      },
      errorPolicy: 'all',
    });
    return result;
  };

  const handleSublinePageChange = async (
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) => {
    await loadSublines(
      page,
      rowsPerPage || sublineData.pageSize,
      searchKey,
      order,
      orderBy
    );
  };

  const handlePlanPageChange = async (
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) => {
    try {
      const result = await loadPlanList(
        page,
        rowsPerPage || planTableData.pageSize,
        searchKey,
        order,
        orderBy
      );

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }
      const mappedPlanList = mapToPlansListingData(result.data);
      setPlanTableData(mappedPlanList);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const handlePolicyCoverPageChange = async (
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) => {
    try {
      const result = await loadPolicyCoverList(
        page,
        rowsPerPage || policyCoverTableData.pageSize,
        searchKey,
        order,
        orderBy
      );
      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }
      const mappedPolicyCoverList = mapToPolicyCoversListingData(result.data);
      setPolicyCoverTableData(mappedPolicyCoverList);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const handleClaimCoverPageChange = async (
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) => {
    try {
      const result = await loadCoverList(
        page,
        rowsPerPage || claimCoverTableData.pageSize,
        searchKey,
        order,
        orderBy
      );
      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }
      const mappedClaimCoverList = mapToClaimCoversListingData(result.data);
      setClaimCoverTableData(mappedClaimCoverList);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const handleClausePageChange = async (
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) => {
    try {
      const result = await loadClauseList(
        page,
        rowsPerPage || clauseTableData.pageSize,
        searchKey,
        order,
        orderBy
      );
      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }
      const mappedClauseList = mapToClausesListingData(result.data);
      setClauseTableData(mappedClauseList);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const initialize = async () => {
    const [entityData] = await Promise.all([loadEntityData()]);

    const lineDetails = entityToLineDetails(entityData.data);
    setLineDetailsInfo(lineDetails);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMainChildren = () => {
    const status = lineDetailsInfo?.status?.toLowerCase();
    const isLineActive = status === 'active';

    return (
      <>
        <div style={{ marginTop: '20px' }}>
          <EnhancedStepperSpecificDesign
            activeStep={getLineStatus(status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isLineActive ? 'Deactivate' : 'Activate'}
            buttonState={
              isLineActive ? activateButtonState : deactivateButtonState
            }
            buttonOnClick={() => {
              if (isLineActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: 'Deactivate',
                  message: `Are you sure you want to deactivate this line?`,
                  callback: async () => {
                    setDeactivateButtonState('loading');
                    deactivateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setDeactivateButtonState('success');
                          initialize();
                          toast.success(
                            <ToastSuccessMessage>
                              {
                                'Line successfully deactivated. Relevant sublines and plans are deactivated as well.'
                              }
                            </ToastSuccessMessage>
                          );
                        } else {
                          setDeactivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setDeactivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: 'Yes',
                  cancelButtonText: 'No',
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: 'Activate',
                  message: `Are you sure you want to activate this line?`,
                  callback: async () => {
                    setActivateButtonState('loading');
                    activateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setActivateButtonState('success');
                          initialize();
                          toast.success(
                            <ToastSuccessMessage>
                              {'Line successfully activated.'}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setActivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setActivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: 'Yes',
                  cancelButtonText: 'No',
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: '' }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={lineDetailsInfo.lineName}
          view={getEntityView(lineDetailsInfo)}
        />
      </>
    );
  };

  const tabs = () => {
    lineDetailsTabs.tabs[0].widgets[0].children = (
      <>
        <WidgetSection style={{ margin: '-23px 0 0 0' }}>
          <form>
            <div>
              <ListingTableWithPagination
                inlineTitle={capitalizeFirstLetter('Sublines')}
                name="sublines"
                orderByAscendingByDefault
                defaultOrderByColumn="planConfigManagement_Subline_Name"
                inline
                data={sublineData}
                headers={headers}
                onPageChange={handleSublinePageChange}
                actions={sublineListingActions}
                loader={loadingSublines}
              />
              {sublineDrawer.open && (
                <SublineDrawer
                  open={sublineDrawer.open}
                  lineId={entityId}
                  lineName={lineDetailsInfo.lineName}
                  sublineId={sublineDrawer.id}
                  lineStatus={lineDetailsInfo.status?.toLowerCase()}
                  onClose={() =>
                    setSublineDrawer({
                      open: false,
                      id: '',
                    })
                  }
                  onSuccess={() => {
                    handleSublinePageChange(1);
                  }}
                />
              )}
            </div>
          </form>

          <form>
            <div style={{ marginTop: '20px' }}>
              <ListingTableWithPagination
                inlineTitle={capitalizeFirstLetter('Plans')}
                name="plans"
                inline
                orderByAscendingByDefault
                defaultOrderByColumn="planConfigManagement_Plan_Name"
                data={planTableData}
                headers={planHeaders}
                onPageChange={handlePlanPageChange}
                actions={planListingActions}
                loader={loadingPlans}
              />
              {planDrawer.open && (
                <PlanDrawer
                  open={planDrawer.open}
                  lineId={entityId}
                  lineName={lineDetailsInfo.lineName}
                  planId={planDrawer.id || null}
                  lineStatus={lineDetailsInfo.status?.toLowerCase()}
                  sublineStatus={planDrawer.sublineStatus}
                  isSublineNameDisabled={!!planDrawer.id}
                  sublineID={planDrawer.sublineID}
                  onClose={() =>
                    setPlanDrawer({ open: false, id: '', sublineStatus: '' })
                  }
                  onSuccess={() => {
                    handlePlanPageChange(1);
                  }}
                />
              )}
            </div>
          </form>
        </WidgetSection>
      </>
    );

    lineDetailsTabs.tabs[1].widgets[0].children = (
      <>
        <WidgetSection style={{ margin: '-23px 0 0 0' }}>
          <form>
            <div>
              <ListingTableWithPagination
                inlineTitle={capitalizeFirstLetter('Policy Covers')}
                name="policyCovers"
                inline
                orderByAscendingByDefault
                defaultOrderByColumn="planConfigManagement_PolicyCover_Name"
                data={policyCoverTableData}
                headers={policyCoverHeaders}
                onPageChange={handlePolicyCoverPageChange}
                actions={policyCoverListingActions}
                loader={loadingPolicyCovers}
              />
              {policyCoverDrawer.open && (
                <PolicyCoverDrawer
                  open={policyCoverDrawer.open}
                  lineId={entityId}
                  lineName={lineDetailsInfo.lineName}
                  policyCoverId={policyCoverDrawer.id ?? null}
                  onClose={() => setPolicyCoverDrawer({ open: false })}
                  onSuccess={() => {
                    handlePolicyCoverPageChange(1);
                  }}
                />
              )}
            </div>
          </form>

          <form>
            <div style={{ marginTop: '20px' }}>
              <ListingTableWithPagination
                inlineTitle={capitalizeFirstLetter('Claim Covers')}
                name="claimCovers"
                inline
                orderByAscendingByDefault
                defaultOrderByColumn="planConfigManagement_ClaimCover_Name"
                data={claimCoverTableData}
                headers={claimCoverHeaders}
                onPageChange={handleClaimCoverPageChange}
                actions={claimCoverListingActions}
                loader={loadingClaimCovers}
              />
              {claimCoverDrawer.open && (
                <ClaimCoverDrawer
                  open={claimCoverDrawer.open}
                  lineId={entityId}
                  lineName={lineDetailsInfo.lineName}
                  claimCoverId={claimCoverDrawer.id ?? null}
                  onClose={() => setClaimCoverDrawer({ open: false })}
                  onSuccess={() => {
                    handleClaimCoverPageChange(1);
                  }}
                />
              )}
            </div>
          </form>
        </WidgetSection>
      </>
    );

    lineDetailsTabs.tabs[2].widgets[0].children = (
      <>
        <WidgetSection style={{ margin: '-23px 0 0 0' }}>
          <form>
            <div>
              <ListingTableWithPagination
                inlineTitle={capitalizeFirstLetter('Clauses')}
                name="clauses"
                inline
                orderByAscendingByDefault
                defaultOrderByColumn="planConfigManagement_Clause_Name"
                data={clauseTableData}
                headers={clauseHeaders}
                onPageChange={handleClausePageChange}
                actions={clauseListingActions}
                loader={loadingClauses}
              />
              {clausePopUp.open && (
                <ClausePopUpShell
                  open={clausePopUp.open}
                  lineId={entityId}
                  lineName={lineDetailsInfo.lineName || ''}
                  clauseId={clausePopUp.id ?? null}
                  onClose={() => setClausePopUp({ open: false })}
                  onSuccess={() => {
                    handleClausePageChange(1);
                  }}
                />
              )}
            </div>
          </form>
        </WidgetSection>
      </>
    );

    return lineDetailsTabs;
  };

  return loadingPage ? (
    <Loader />
  ) : (
    <TabsLayout
      name="lineDetailsPage"
      layout={tabs()}
      theme={tenant.theme}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
      cdnUrl={tenant.cdnUrl}
      userInfo={user['info']}
      firstTabAsActiveTab={true}
      tabPanelClassName={classes.tabPanelStyle}
    />
  );
};

export default LineDetailsPage;

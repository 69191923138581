import {
  FileUploadStatus,
  IFileCardProps,
} from '../../../../../../models/file';
import { ITreatyDocumentResponse } from '.';

export const mapFileToDocumentWidgetFiles = (
  documentsData: ITreatyDocumentResponse[]
): IFileCardProps[] => {
  const docsList: IFileCardProps[] = [];

  if (documentsData && documentsData.length > 0) {
    documentsData.map((document) => {
      docsList.push({
        title: document?.production_TreatyDocuments_DocumentName,
        version: document?.production_TreatyDocuments_Version,
        status: FileUploadStatus.Uploaded,
        allowedFileTypes: [],
        entityViewId: 'Production-all',
        propertyId: 'Document',
        entityId:
          document?.production_TreatyDocuments_Document?.path?.split('/')[2],
        entityTypeId: 'Production-TreatyDocuments',
        hideReviewedLabel: true,
        createdOn: document?.production_TreatyDocuments_createdOn,
        fileInfo: {
          contentType:
            document?.production_TreatyDocuments_Document?.contentType,
          fileName: document?.production_TreatyDocuments_Document?.fileName,
          id: document?.production_TreatyDocuments_Document?.id,
          length: document?.production_TreatyDocuments_Document?.length,
          location: document?.production_TreatyDocuments_Document?.location,
          path: document?.production_TreatyDocuments_Document?.path,
        },
      });
    });
  }
  return docsList;
};

// import { Insurance_Application_Insurance_ApplicationQuotes_QueryResult } from '../../gql/__generated__/graphql';
import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import { lookupListAsRecordObject } from '../../utils/graph-utils';

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};
  data.Insurance.queries.listApplicationDetailsHealth.items.forEach(
    (obj: IGenericHealthApplicationTableData, i: number) => {
      list[i] = {
        id: obj.insurance_Application_Id,
        name: obj.insurance_Application_ApplicationName,
        applicationContactDetails: obj.insuranceCustomer_FullName,
        plan:
          obj.insurance_Application_Status.toLowerCase() !== 'new'
            ? obj.insurance_Application_PlanCount
            : '',
        agency: obj.insuranceAgency_AgencyName,
        agent: obj.insuranceAgent_FirstName + ' ' + obj.insuranceAgent_LastName,
        effectiveDate: obj.insurance_Application_EffectiveDate,
        expiryDate: obj.insurance_Application_ExpiryDate,
        createdOn: obj.insurance_Application_createdOn,
        modifiedOn: obj.insurance_Application_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data.Insurance.queries.listApplicationDetailsHealth?.paging?.pageSize,
    pageNumber:
      data.Insurance.queries.listApplicationDetailsHealth?.paging?.pageNumber,
    totalCount:
      data.Insurance.queries.listApplicationDetailsHealth?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    assignedAgencyID: {},
    product: {},
  };
  lovs.product = lookupListAsRecordObject(data?.Insurance?.lookups?.products);

  data?.Insurance?.queries?.allAgencies?.forEach(
    (obj: {
      insurance_Agency_Id: string;
      insurance_Agency_AgencyName: string;
    }) => {
      lovs.assignedAgencyID[obj.insurance_Agency_Id] =
        obj.insurance_Agency_AgencyName;
    }
  );

  return lovs;
}

export function toPlansList(data: [any]) {
  const popoverRows: Record<string, any> = {};

  data.forEach((quoteDetails: any, i: number) => {
    popoverRows[i] = {
      Plan: quoteDetails?.insuranceQuote_Product?.Title || 'N/A',
      Class:
        quoteDetails?.insuranceQuote_Product?.Code == 'Standard'
          ? quoteDetails?.healthPlanInputs_StandardInHospitalClass?.Title
          : quoteDetails?.healthPlanInputs_OptimumInHospitalClass?.Title,
      AmbulatoryCoverage:
        quoteDetails?.insuranceQuote_Product?.Code == 'Standard'
          ? quoteDetails?.healthPlanInputs_StandardAmbulatoryCoverage?.Title
          : quoteDetails?.healthPlanInputs_OptimumAmbulatoryCoverage?.Title,
      TotalPremium: quoteDetails?.insuranceQuote_TotalAnnualPremium || 'N/A',
      Status:
        capitalizeFirstLetter(quoteDetails?.insuranceQuote_Status, '_') ||
        'N/A',
      StatusReason:
        capitalizeFirstLetter(quoteDetails?.insuranceQuote_StatusReason, '_') ||
        'N/A',
    };
  });

  return popoverRows;
}

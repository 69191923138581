import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { IListingData } from '../../../models/listing';
import StaticLayout from '../../../page-layout/static-layout/StaticLayout';
import { listQuery, filterQuery } from './queries';
import { mapToListingData, dataToLovs } from './utils';
import { filterSectionsContent, headers } from './content';
import ListingWidget from '../../../components/form-fields/listing-widget/ListingWidget';
import { IFilterModel } from '../../../components/form-fields/listing-widget';
import ListingFilterWidget from '../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { getFilter, setFilter } from '../../../utils/filter-utils';
import { DEFAULT_ERROR_TEXT } from '../../../constants';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import DateService from '../../../services/dateService';
import SublineDrawer from '../../../forms/subline-drawer/SublineDrawer';
import { capitalizeFirstCharacter } from '../../../utils/formatting-utils';
import { IAbstractRecord } from '../../../models';

const PAGE_CONTEXT = 'Sublines';
const FILTER_SESSION_KEY = 'sublinesFilter';

const SublinesPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filterSections, setFilterSections] = useState(null);
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });
  const [sublineDrawerOpen, setSublineDrawerOpen] = useState<{
    open: boolean;
    id?: string;
  }>({
    open: false,
  });

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          line: [],
          SelectedStatuses: [],
          createdOn: [],
        };

  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialFilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
    descending: true,
  });

  const [sublinesQuery] = useLazyQuery(listQuery());
  const [filterQueryLazy] = useLazyQuery(filterQuery(), {
    fetchPolicy: 'no-cache',
  });

  const getListingData = (filter = filterValues) => {
    if (!filter) {
      setLoading(false);
      return {};
    }

    setLoading(true);
    setFilter(filter, FILTER_SESSION_KEY);
    setFilterValues(filter);

    const { namedFilters, pagination } = filter;

    return sublinesQuery({
      variables: {
        keywordSearch: filter.searchKey,
        currentPage: pagination.pageNumber || 1,
        currentPageSize: pagination.pageSize || 10,
        Attribute:
          capitalizeFirstCharacter(filter.orderBy || '') ||
          'PlanConfigManagement_Subline_Name',
        Descending: filter.descending,
        SelectedLineId: namedFilters.line || [],
        SelectedStatuses: namedFilters.status || null,
        FromCreatedOn: DateService.formatDateBackend(
          namedFilters.createdOn?.[0]
        ),
        ToCreatedOn: DateService.formatDateBackend(namedFilters.createdOn?.[1]),
      },
    })
      .then(({ data }) => {
        if (data) {
          setTableData(mapToListingData(data));
          filterQueryLazy().then(({ data: filterData }) => {
            setFilterSections(
              filterSectionsContent(dataToLovs(filterData), namedFilters)
            );
          });
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (
    page: number,
    filterModel = filterValues
  ): Promise<void> => {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  };

  const onFilterUpdate = (newFilters: IAbstractRecord) => {
    const updatedFilters = {
      ...filterValues,
      namedFilters: newFilters,
    };
    if (!_.isEqual(updatedFilters, filterValues)) {
      getListingData(updatedFilters);
    }
  };

  const renderMainChildren = () => (
    <>
      <ListingWidget
        title="Sublines"
        name="sublines"
        data={tableData}
        tableSettings={{
          headers,
        }}
        orderByAscendingByDefault
        defaultOrderByColumn="planConfigManagement_Subline_Name"
        loading={loading}
        pageContext={PAGE_CONTEXT}
        onPageChange={(filterModel) => {
          const newFilterModel = {
            ...filterValues,
            ...filterModel,
          };
          const page = filterModel.pagination.pageNumber;
          return handlePageChange(page, newFilterModel);
        }}
        actions={[]}
        usePagination
        disableSelection
      />
      {sublineDrawerOpen.open && (
        <SublineDrawer
          open={sublineDrawerOpen.open}
          onClose={() =>
            setSublineDrawerOpen({
              open: false,
            })
          }
          onSuccess={() => handlePageChange(1)}
          isLineNameDisabled={false}
          canNavigateToDetailsPage={true}
          sublineId={sublineDrawerOpen.id}
        />
      )}
    </>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
        disabled={loading}
      />
    );

  return (
    <StaticLayout
      name="Sublines"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    ></StaticLayout>
  );
};

export default SublinesPage;

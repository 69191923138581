import React, { useEffect, useState, useMemo } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getInputs, initialValues } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { isEmpty } from 'lodash';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { ITravelPlanRatesDrawerInfo, ITravelPlanRatesDrawerProps } from '.';
import {
  createPlanSpecificTravel,
  getTravelPlanRatesEnums,
  getPlanSpecificTravelInfo,
  updatePlanSpecificTravel,
  getPolicyCoversListQuery,
  getClausesListQuery,
} from './queries';
import {
  LookupToList,
  graphqlToTravelPlanRatesInfo,
  extractPolicyCovers,
  extractClauses,
} from './utils';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { IAbstractRecord } from '../../models';
import PrecisionService from '../../services/precisionService';

const TravelPlanRatesDrawer: React.FC<ITravelPlanRatesDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planId,
  planCurrency,
  planCurrencySymbol,
  travelPlanRateId,
  lineId,
}) => {
  const [booted, setBooted] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [values, setValues] = useState<ITravelPlanRatesDrawerInfo>();
  const [lists, setLists] = useState<Record<string, Record<string, string>>>({
    currencies: {},
    travelDestinations: {},
    gender: {},
    policyCover: {},
    tpaApplicableOnOptions: {},
    clauseReference: {},
  });

  const [travelPlanRatesResultQuery] = useLazyQuery(getTravelPlanRatesEnums());
  const [travelPlanRatesInfoQuery] = useLazyQuery(getPlanSpecificTravelInfo());
  const [travelPlanRatesAction] = useMutation(
    travelPlanRateId ? updatePlanSpecificTravel() : createPlanSpecificTravel()
  );
  const [getPolicyCoversListLazy] = useLazyQuery(getPolicyCoversListQuery);
  const [getClausesListLazy] = useLazyQuery(getClausesListQuery);

  const loadLovList = async () => {
    const data = await travelPlanRatesResultQuery();
    const newTravelPlanRatesEnums = LookupToList(data.data);
    const result: Record<string, Record<string, string>> = {
      currencies: newTravelPlanRatesEnums['currencies'],
      travelDestinations: newTravelPlanRatesEnums['travelDestinations'],
      gender: newTravelPlanRatesEnums['gender'],
      policyCover: {},
      tpaApplicableOnOptions: newTravelPlanRatesEnums['tpaApplicableOnOptions'],
      clauseReference: {},
    };

    return result;
  };

  const getEntityInfo = async () => {
    if (travelPlanRateId) {
      const apiResult = await travelPlanRatesInfoQuery({
        variables: { id: travelPlanRateId },
      });
      if (apiResult.data) {
        const travelPlanRateEntity = graphqlToTravelPlanRatesInfo(
          apiResult.data
        );
        return travelPlanRateEntity;
      }
    }
    return null;
  };

  const initialize = async () => {
    try {
      const [travelPlanRatesEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      let newValues = { ...initialValues };

      if (travelPlanRateId && travelPlanRatesEntity) {
        newValues = {
          ...newValues,
          planCurrency: planCurrency,
          ageFrom: travelPlanRatesEntity.ageFrom,
          ageTo: travelPlanRatesEntity.ageTo,
          gender: travelPlanRatesEntity.gender,
          travelDurationFrom: travelPlanRatesEntity.travelDurationFrom,
          travelDurationTo: travelPlanRatesEntity.travelDurationTo,
          travelDestination: travelPlanRatesEntity.travelDestination,
          nbOfAdherent: travelPlanRatesEntity.nbOfAdherent,
          premium: travelPlanRatesEntity.premium,
          policyCover: travelPlanRatesEntity.policyCover,
          policyCoverTitle: travelPlanRatesEntity.policyCoverTitle,
          isMain: travelPlanRatesEntity.isMain,
          isMandatory: travelPlanRatesEntity.isMandatory,
          isEditable: travelPlanRatesEntity.isEditable,
          sumInsured: travelPlanRatesEntity.sumInsured,
          sumInsuredIsAdditive: travelPlanRatesEntity.sumInsuredIsAdditive,
          tpaType: travelPlanRatesEntity.tpaType || '',
          tpaAmount: travelPlanRatesEntity.tpaAmount || '',
          tpaFeesPercentage: travelPlanRatesEntity.tpaFeesPercentage,
          tpaFeesApplicableOn: travelPlanRatesEntity.tpaFeesApplicableOn,
          minTPAFees: travelPlanRatesEntity.minTPAFees,
          maxTPAFees: travelPlanRatesEntity.maxTPAFees,
          excessOnClaimType: travelPlanRatesEntity.excessOnClaimType,
          excessOnClaimAmount: travelPlanRatesEntity.excessOnClaimAmount,
          excessOnClaimPercentage:
            travelPlanRatesEntity.excessOnClaimPercentage,
          excessOnClaimDays: travelPlanRatesEntity.excessOnClaimDays,
          clauseReference: travelPlanRatesEntity.clauseReference,
          clauseReferenceTitle: travelPlanRatesEntity.clauseReferenceTitle,
        };
      }

      setValues(newValues);
      setLists(lovData);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCustomChange = async (
    fieldName: string,
    value: unknown,
    allValues: ITravelPlanRatesDrawerInfo
  ) => {
    setValues(allValues);
  };

  const handleSearch = async (
    fieldName: string,
    value: string,
    pagination: {
      pageNumber: number;
      pageSize: number;
    }
  ) => {
    if (fieldName === 'policyCover') {
      return getPolicyCoversListLazy({
        variables: {
          pagination: pagination,
          searchKeyword: value,
          selectedLineIds: [lineId],
        },
      }).then((result) => {
        const data =
          result.data?.PlanConfigManagement?.queries
            .allPolicyCoverListPaginated;
        const newPolicyCovers = extractPolicyCovers(data.items);
        if (!(values.policyCover in newPolicyCovers)) {
          newPolicyCovers[values.policyCover] = values.policyCoverTitle;
        }
        return {
          options: newPolicyCovers,
          totalCount: data.paging.totalCount,
        };
      });
    } else if (fieldName === 'clauseReference') {
      return getClausesListLazy({
        variables: {
          pagination: pagination,
          selectedLineIds: [lineId],
          searchKeyword: value,
        },
      }).then((result) => {
        const data =
          result.data?.PlanConfigManagement?.queries.allClauseListPaginated;
        const newClaues = extractClauses(data.items);
        if (!(values.clauseReference in newClaues)) {
          newClaues[values.clauseReference] = values.clauseReferenceTitle;
        }
        return {
          options: newClaues,
          totalCount: data.paging.totalCount,
        };
      });
    }

    return new Promise<{
      options: Record<string, string>;
      totalCount: number;
    }>((resolve) => {
      resolve({ options: {}, totalCount: 0 });
    });
  };

  const submitForm = async (formValues: ITravelPlanRatesDrawerInfo) => {
    const [data] = normaliseDynamicValues(
      getInputs(formValues, lists, planCurrency, planCurrencySymbol),
      formValues
    );

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const variablesMutation = {
        planSpecificTravelInputs: {
          planID: planId,
          ageFrom: Number(data?.ageFrom),
          ageTo: Number(data?.ageTo),
          travelDurationFrom: Number(data?.travelDurationFrom),
          travelDurationTo: Number(data?.travelDurationTo),
          travelDestination: data?.travelDestination,
          premium: Number(data?.premium),
          gender: data?.gender,
          nbOfAdherent: Number(data?.nbOfAdherent) || null,
          policyCoverID: data?.policyCover || '',
          tpaFeesPercentage:
            data?.tpaType === 'PERCENTAGE'
              ? PrecisionService.divideBy100(data?.tpaFeesPercentage)
              : null,
          minTPAFees:
            data?.tpaType === 'PERCENTAGE' ? Number(data?.minTPAFees) : null,
          maxTPAFees:
            data?.tpaType === 'PERCENTAGE' ? Number(data?.maxTPAFees) : null,
          sumInsured: Number(data?.sumInsured),
          sumInsuredIsAdditive: data?.sumInsuredIsAdditive,
          excessOnClaimType: data?.excessOnClaimType,
          excessOnClaimAmount: data?.excessOnClaimAmount
            ? Number(data?.excessOnClaimAmount)
            : 0,
          excessOnClaimPercentage:
            data?.excessOnClaimType === 'PERCENTAGE'
              ? PrecisionService.divideBy100(data?.excessOnClaimPercentage)
              : null,
          excessOnClaimDays:
            data?.excessOnClaimType === 'DAYS'
              ? Number(data?.excessOnClaimDays)
              : null,
          clauseID: data?.clauseReference || null,
          tpaApplicableOn:
            data?.tpaType === 'PERCENTAGE' ? data?.tpaFeesApplicableOn : null,
          isMain: data?.isMain,
          isMandatory: data?.isMandatory,
          isEditable: data?.isEditable,
          tpaFeesAmount:
            data?.tpaType === 'AMOUNT' ? Number(data?.tpaAmount) : null,
          tpaType: data?.tpaType,
        },
      };

      const res = await travelPlanRatesAction({
        variables: travelPlanRateId
          ? { ...variablesMutation, entityId: travelPlanRateId }
          : variablesMutation,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {travelPlanRateId
              ? 'Plan Rate successfully updated.'
              : 'Plan Rate successfully added.'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          setFormDisabled(false);
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        setFormDisabled(false);
      }
    } catch (err) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
      setFormDisabled(false);
    }
  };

  const inputForm = useMemo(() => {
    return getInputs(
      values || initialValues,
      lists,
      planCurrency,
      planCurrencySymbol
    );
  }, [values, lists, planCurrency, planCurrencySymbol]);

  const handleAgeDifference = (ageTo: string, ageFrom: string) => {
    const fromAge = Number(ageFrom);
    const toAge = Number(ageTo);
    const isAgeInvalid = fromAge != 0 && toAge != 0 && fromAge > toAge;

    if (isAgeInvalid) {
      return 'Age To must be greater than Age From';
    } else {
      return '';
    }
  };

  const handleTravelDurationChange = (
    travelDurationTo: string,
    travelDurationFrom: string
  ) => {
    const fromTravelDuration = Number(travelDurationFrom);
    const toTravelDuration = Number(travelDurationTo);
    const isInvalid =
      fromTravelDuration != 0 &&
      toTravelDuration != 0 &&
      fromTravelDuration > toTravelDuration;

    if (isInvalid) {
      return 'Travel Duration To must be greater than Travel Duration From';
    } else {
      return '';
    }
  };

  const onCustomValidate = async (
    fieldName: string,
    values: IAbstractRecord
  ) => {
    if (fieldName === 'ageTo') {
      return handleAgeDifference(values.ageTo, values.ageFrom);
    }

    if (fieldName === 'travelDurationTo') {
      return handleTravelDurationChange(
        values.travelDurationTo,
        values.travelDurationFrom
      );
    }

    return '';
  };

  const onCustomBlur = async (
    fieldName: string,
    values: IAbstractRecord,
    errors: IAbstractRecord
  ) => {
    if (fieldName === 'ageFrom' || fieldName === 'ageTo') {
      const ageToError = await onCustomValidate('ageTo', values);
      errors.ageTo = ageToError;
    }

    if (
      fieldName === 'travelDurationFrom' ||
      fieldName === 'travelDurationTo'
    ) {
      const travelDurationToError = await onCustomValidate(
        'travelDurationTo',
        values
      );
      errors.travelDurationTo = travelDurationToError;
    }

    return { values, errors };
  };

  const onCustomValidateForm = async (
    values: IAbstractRecord,
    errors: Record<string, string>
  ) => {
    if (!errors.ageFrom) {
      errors.ageFrom = await onCustomValidate('ageFrom', values);
    }

    if (!errors.ageTo) {
      errors.ageTo = await onCustomValidate('ageTo', values);
    }

    if (!errors.travelDurationFrom) {
      errors.travelDurationFrom = await onCustomValidate(
        'travelDurationFrom',
        values
      );
    }

    if (!errors.travelDurationTo) {
      errors.travelDurationTo = await onCustomValidate(
        'travelDurationTo',
        values
      );
    }

    return errors;
  };

  return (
    <GenericDrawer
      title={travelPlanRateId ? 'Modify Plan Rate' : 'Add Plan Rate'}
      onClose={onClose}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <DynamicForm
          inputs={inputForm}
          onSubmit={submitForm}
          buttonText="Submit"
          submitButtonState={submitButtonState}
          isSubmitButtonDisabled={!!submitButtonState}
          disableForm={formDisabled}
          title="Information"
          hasDoprdownSpecificBehavior={true}
          onChange={onCustomChange}
          onAutocompleteSearch={handleSearch}
          onCustomValidate={onCustomValidateForm}
          onCustomBlur={onCustomBlur}
        />
      )}
    </GenericDrawer>
  );
};

export default TravelPlanRatesDrawer;

import { isEmpty } from 'lodash';
import { IPaymentTermResponse, IPaymentTermsTableData } from '.';
import { IListingData } from '../../../../../models/listing';
import { IAbstractRecord } from '../../../../../models';

export function mapToListingData(
  data: IPaymentTermResponse[],
  categories: Record<string, string>
): IListingData {
  if (isEmpty(data))
    return {
      pagedItems: {},
      pageSize: 5,
      pageNumber: 0,
      totalCount: 0,
    };

  const finalList: Record<string, IPaymentTermsTableData> = {};

  data?.forEach((obj: IPaymentTermResponse) => {
    const billType = obj.accounting_ParentBill_ParentBillCategory;
    const policyName =
      obj.accounting_ParentBill_PolicyID?.views?.Production_all?.properties
        ?.PolicyNumber;

    finalList[obj.accounting_ParentBill_Id] = {
      id: obj.accounting_ParentBill_Id,
      billType: categories[billType],
      reference:
        billType === 'PRODUCTION'
          ? policyName
          : `${policyName} ${obj.linkedAmendment_AmendmentNumber}`,
      totalPremium: obj.accounting_ParentBill_TotalPremium,
      amountReceived: obj.accounting_ParentBill_TotalAmountReceived,
      amountPaid: obj.accounting_ParentBill_TotalAmountPaid,
      amountDue: obj.accounting_ParentBill_TotalAmountDue,
      commission: obj.accounting_ParentBill_TotalCommission,
      taxOnCommission: obj.accounting_ParentBill_TotalTaxOnCommission,
      linkedAmendmentId: obj.linkedAmendment_Id,
      linkedAmendment: obj.linkedAmendment_AmendmentNumber,
      linkedAmendmentEffectiveDate: obj.linkedAmendment_AmendmentEffectiveDate,
      Currency: obj.accounting_ParentBill_Currency?.Symbol,
      viewBreakdown: 'View Breakdown',
      AvailableActions:
        !isNaN(obj.accounting_ParentBill_TotalAmountDue) &&
        Number(obj.accounting_ParentBill_TotalAmountDue) > 0
          ? ['changepaymentterms']
          : [],
    };
  });

  return {
    pagedItems: finalList,
    pageSize: 5,
    pageNumber: 1,
    totalCount: Object.keys(finalList).length,
  };
}

export const transformReceivableBillsArrayToObject = (
  dataArray: IAbstractRecord[]
) => {
  if (!dataArray?.length) {
    return {};
  }

  const receivableBills = dataArray?.filter(
    (bill) => bill.accounting_Bills_BillType === 'RECEIVABLE'
  );

  return receivableBills.reduce((acc, item) => {
    if (acc[item.accounting_Bills_Id]) {
      // If the bill ID already exists, update the AmountPaid by summing it
      acc[item.accounting_Bills_Id].AmountPaid += Number(
        item.payableBills_AmountPaid || 0
      );
      acc[item.accounting_Bills_Id].TotalBalance =
        Number(acc[item.accounting_Bills_Id].AmountDue || 0) -
        Number(acc[item.accounting_Bills_Id].AmountReceived || 0) -
        (Number(acc[item.accounting_Bills_Id].Commission || 0) -
          Number(acc[item.accounting_Bills_Id].TaxOnCommission || 0) -
          acc[item.accounting_Bills_Id].AmountPaid);
    } else {
      // If the bill ID doesn't exist, initialize the entry
      acc[item.accounting_Bills_Id] = {
        Id: item.accounting_Bills_Id,
        BillNumber: item.accounting_Bills_BillNumber,
        DueDate: item.accounting_Bills_DueDate,
        AmountDue: item.accounting_Bills_AmountDue,
        TotalPremium: item.accounting_Bills_TotalPremium,
        AmountReceived: item.accounting_Bills_AmountReceived,
        Commission: item.accounting_Bills_TotalCommission,
        TaxOnCommission: item.accounting_Bills_TaxOnCommission,
        AmountPaid: item.payableBills_AmountPaid,
        TotalBalance:
          Number(item.accounting_Bills_AmountDue || 0) -
          Number(item.accounting_Bills_AmountReceived || 0) -
          (Number(item.accounting_Bills_TotalCommission || 0) -
            Number(item.accounting_Bills_TaxOnCommission || 0) -
            Number(item.payableBills_AmountPaid || 0)),
        Currency: item.accounting_Bills_Currency?.Symbol,
      };
    }
    return acc;
  }, {});
};

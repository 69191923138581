import React, { useEffect, useState } from 'react';
import StaticLayout from '../../../../page-layout/static-layout/StaticLayout';
import ListingWidget from '../../../../components/form-fields/listing-widget/ListingWidget';
import { IListingData } from '../../../../models/listing';
import { getFilter, setFilter } from '../../../../utils/filter-utils';
import { IListingFilterWidgetSection } from '../../../../components/widgets/custom-listing-filter';
import { IEnhancedMenuItem } from '../../../../components/form-fields/table';
import { useLazyQuery } from '@apollo/client';
import ListingFilterWidget from '../../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { IAbstractRecord } from '../../../../models';
import { IFilterModel } from '../../../../components/form-fields/listing-widget';
import { filterSectionsContent, headers } from './content';
import { mapToListingData, toLookupsData } from './utils';
import {
  getFacultativesQuery,
  getLovsQuery,
  getPolicyNumberQuery,
  getProposalNameQuery,
} from './queries';
import { getPoliciesListQuery, getProposalsListQuery } from './queries';
import {
  getError,
  lookupListAsRecordObject,
} from '../../../../utils/graph-utils';
import { formatEnum } from '../../../../utils/formatting-utils';
import { valueOrNull } from '../../../../utils/helper-utils';
import NewFacultativeDrawer from '../new-facultative-drawer/NewFacultativeDrawer';
import DateService from '../../../../services/dateService';

const PAGE_CONTEXT = 'Facultatives';
const FILTER_SESSION_KEY = 'facultativesFilter';

const FacultativesListingPage: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          proposal: [],
          policy: [],
          mainReinsurer: '',
          status: '',
          createdOn: '',
        };
  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialFilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [getFacultativesLazy, { loading }] = useLazyQuery(getFacultativesQuery);
  const [getLovsLazy] = useLazyQuery(getLovsQuery);
  const [getProposalsListLazy] = useLazyQuery(getProposalsListQuery);
  const [getPoliciesListLazy] = useLazyQuery(getPoliciesListQuery);
  const [getProposalNameLazy] = useLazyQuery(getProposalNameQuery);
  const [getPolicyNumberLazy] = useLazyQuery(getPolicyNumberQuery);

  function handlePageChange(
    page: number,
    filterModel = filterValues
  ): Promise<void> {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  }

  const onFilterUpdate = async (filters: IAbstractRecord) => {
    const newFilters = {
      ...filterValues,
      namedFilters: filters,
    };
    setFilter(filters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    getListingData(newFilters);
  };

  const getVariables = (filter = filterValues) => {
    return {
      SelectedProposal: valueOrNull(filter.namedFilters.proposal),
      SelectedPolicy: valueOrNull(filter.namedFilters.policy),
      SelectedReinsurer: valueOrNull(filter.namedFilters.mainReinsurer),
      CreatedFromDate: valueOrNull(
        DateService.formatDateBackend(filter.namedFilters.createdOn?.[0])
      ),
      CreatedToDate: valueOrNull(
        DateService.formatDateBackend(filter.namedFilters.createdOn?.[1])
      ),
      SelectedStatus: valueOrNull(formatEnum(filter.namedFilters.status)),
      KeywordSearch: filter.searchKey,
      OrderByField: valueOrNull(filter.orderBy),
      DescendingField: filter.descending,
      pagination: {
        pageSize: filter.pagination.pageSize,
        pageNumber: filter.pagination.pageNumber,
      },
    };
  };

  const getLovs = async (filter = filterValues) => {
    if (!filter) {
      return;
    }
    const { data: lovsData } = await getLovsLazy();
    const lovs = toLookupsData(lovsData);

    const newFilterSections = filterSectionsContent(lovs, filter.namedFilters);
    setFilterSections(newFilterSections);
    setFilterValues(filter);
  };

  useEffect(() => {
    getLovs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListingData = (filter = filterValues) => {
    if (!filter) {
      return {};
    }
    const variables = getVariables(filter);
    return getFacultativesLazy({
      variables: variables,
    })
      .then(async ({ data }) => {
        if (data) {
          const tableData = mapToListingData(
            data?.Production?.queries?.FacultativesList
          );

          setTableData({
            ...tableData,
            pageNumber: tableData.pageNumber,
            pageSize: tableData.pageSize,
            totalCount: tableData.totalCount,
          });
        }
      })
      .catch((error) => {
        toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
      });
  };

  const actions: IEnhancedMenuItem[] = [
    {
      name: 'newFacultative',
      title: '+ New',
      isPrimary: false,
      onClick: () => {
        setIsDrawerOpen(true);
      },
    },
  ];

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={''}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
          onAutocompleteSearch={(
            fieldName: string,
            value: string,
            pagination
          ) => {
            if (fieldName === 'proposal') {
              return getProposalsListLazy({
                variables: {
                  KeywordSearch: value,
                  pagination,
                },
              }).then((result) => {
                return {
                  options: lookupListAsRecordObject(
                    result.data?.Production?.queries?.getProposalList?.items,
                    false,
                    'production_Proposal_Id',
                    'production_Proposal_ProposalName'
                  ),
                  totalCount:
                    result.data?.Production?.queries?.getProposalList?.paging
                      ?.totalCount,
                };
              });
            } else if (fieldName === 'policy') {
              return getPoliciesListLazy({
                variables: {
                  KeywordSearch: valueOrNull(value),
                  pagination,
                },
              }).then((result) => {
                return {
                  options: lookupListAsRecordObject(
                    result.data?.Production?.queries?.getPolicyList?.items,
                    false,
                    'production_Policy_Id',
                    'production_Policy_PolicyNumber'
                  ),
                  totalCount:
                    result.data?.Production?.queries?.getPolicyList?.paging
                      ?.totalCount,
                };
              });
            }

            return new Promise<{
              options: Record<string, string>;
              totalCount: number;
            }>((resolve) => {
              resolve({ options: {}, totalCount: 0 });
            });
          }}
          initialMultiAutocompleteSearch={(
            fieldName: string,
            values: string[]
          ) => {
            if (fieldName === 'proposal') {
              return getProposalNameLazy({
                variables: {
                  ProposalIDs: values,
                },
              }).then((result) => {
                return {
                  options: lookupListAsRecordObject(
                    result.data?.Production?.queries?.GetProposalName,
                    false,
                    'production_Proposal_Id',
                    'production_Proposal_ProposalName'
                  ),
                  totalCount:
                    result.data?.Production?.queries?.GetProposalName?.length,
                };
              });
            } else if (fieldName === 'policy') {
              return getPolicyNumberLazy({
                variables: {
                  PolicyIDs: values,
                },
              }).then((result) => {
                return {
                  options: lookupListAsRecordObject(
                    result.data?.Production?.queries?.GetPolicyNumber,
                    false,
                    'production_Policy_Id',
                    'production_Policy_PolicyNumber'
                  ),
                  totalCount:
                    result.data?.Production?.queries?.GetPolicyNumber?.length,
                };
              });
            }

            return new Promise<{
              options: Record<string, string>;
              totalCount: number;
            }>((resolve) => {
              resolve({ options: {}, totalCount: 0 });
            });
          }}
        />
      )
    );
  };

  const renderMainChildren = () => {
    return (
      <>
        <ListingWidget
          name="facultatives"
          title="Facultatives"
          orderByAscendingByDefault
          loading={loading}
          data={tableData}
          tableSettings={{
            headers: headers,
          }}
          actions={actions}
          pageContext={PAGE_CONTEXT}
          onPageChange={(filterModel) => {
            const newFilterModel = {
              ...filterValues,
              ...filterModel,
            };
            const page = filterModel.pagination.pageNumber;
            return handlePageChange(page, newFilterModel);
          }}
          disableSelection
          usePagination={true}
        />
        {isDrawerOpen && (
          <NewFacultativeDrawer
            isOpen={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          />
        )}
      </>
    );
  };

  return (
    <StaticLayout
      name="Facultatives"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default FacultativesListingPage;

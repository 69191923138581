import PrecisionService from '../../services/precisionService';
import { isEmpty, isValidNumber } from '../../utils/validationUtils';
import { IProposalPageFormState } from './custom-widgets/form';
import { IProposalDetailsSummary } from './custom-widgets/index2';

export interface IProposalDetailsPageSubmissionModel {
  entityId: string;
  planId: string;
  policyCurrency: string;
  policyEffectiveDate: string;
  policyExpiryDate: string;
  agencyRepairYear: number;
  depreciationYears: number;
  policyPersonInput: {
    personId: string;
    personAddressId: string;
    type: string;
    percentage?: number;
    orderNumber?: number;
    ownerIsDifferent?: boolean;
  }[];
  policyPlanCoverInput: {
    coverPremiumPercentage: number;
    coverPremiumType: string;
    coverPremiumValue: number;
    excessOnClaimAmount: number;
    excessOnClaimDays: number;
    excessOnClaimPercentage: number;
    excessOnClaimType: string;
    planCoverID: string;
  }[];
  vehicleDetailsInput: {
    plateNumber: string;
    weight?: number;
    engineSize?: number;
    seatingCapacity?: number;
    chassis: string;
    carValue: number;
    bodyType: string;
    engineType: string;
    horsePower: number;
    make: string;
    model: string;
    nameOnLicenseAr: string;
    nameOnLicenseEn: string;
    usageType: string;
    yearOfMake?: number;
    engine: string;
    vignette: number;
    vignetteCode: string;
    plateCode: string;
    color: string;
  };
  costAndChargeInput: {
    chargesPercentage: number;
    policyCost: number;
    tpaFeesAmount: number;
    tpaFeesApplicableOn?: string;
    tpaFeesPercentage: number;
    tpaFeesType?: string;
  };
}

export function convertProposalPageStateToSubmission(
  summary: IProposalDetailsSummary,
  pageState: IProposalPageFormState
) {
  const { values } = pageState;

  const policyPersons: IProposalDetailsPageSubmissionModel['policyPersonInput'] =
    [];

  if (values.policyPersons?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: 'PAYER',
      ownerIsDifferent: values.policyPersons.payer.ownerIsDifferent,
    });
  }

  if (values.policyPersons.owner?.ownerId) {
    policyPersons.push({
      personId: values.policyPersons.owner.ownerId,
      personAddressId: values.policyPersons.owner.ownerAddress,
      type: 'OWNER',
    });
  } else if (values.policyPersons?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: 'OWNER',
    });
  }

  values.policyPersons.insured.forEach((person) => {
    policyPersons.push({
      personId: person.id,
      personAddressId: person.address,
      type: 'INSURED',
      orderNumber: isValidNumber(person.order)
        ? Number(person.order)
        : undefined,
    });
  });

  values.policyPersons.beneficiaries.forEach((person) => {
    policyPersons.push({
      personId: person.id,
      personAddressId: person.address,
      type: 'BENEFICIARY',
    });
  });

  const data: IProposalDetailsPageSubmissionModel = {
    entityId: summary.Id,
    planId: summary.PlanID.Id,
    policyCurrency: summary.PolicyCurrency.Code,
    policyEffectiveDate: summary.PolicyEffectiveDate,
    policyExpiryDate: summary.PolicyExpiryDate,
    agencyRepairYear: isValidNumber(values.motorDetails.agencyRepairYear)
      ? Number(values.motorDetails.agencyRepairYear)
      : null,
    depreciationYears: isValidNumber(values.motorDetails.depreciationYears)
      ? Number(values.motorDetails.depreciationYears)
      : null,
    policyPersonInput: policyPersons,
    policyPlanCoverInput: values.covers
      .filter((c) => !isEmpty(c.policyCover))
      .map((cover, i) => ({
        order: i + 1,
        coverPremiumPercentage: isValidNumber(cover.coverPremiumPercentage)
          ? PrecisionService.divideBy100(cover.coverPremiumPercentage)
          : null,
        coverPremiumType: cover.coverPremiumType,
        coverPremiumValue: isValidNumber(cover.coverPremiumValue)
          ? Number(cover.coverPremiumValue)
          : null,
        excessOnClaimAmount: isValidNumber(cover.excessOnClaimAmount)
          ? Number(cover.excessOnClaimAmount)
          : null,
        excessOnClaimDays: isValidNumber(cover.excessOnClaimDays)
          ? Number(cover.excessOnClaimDays)
          : null,
        excessOnClaimPercentage: isValidNumber(cover.excessOnClaimPercentage)
          ? PrecisionService.divideBy100(cover.excessOnClaimPercentage)
          : null,
        excessOnClaimType: cover.excessOnClaimType,
        planCoverID: cover.policyCover,
        isEditable: cover.isEditable,
        coverSumInsured: isValidNumber(cover.coverSumInsured)
          ? Number(cover.coverSumInsured)
          : 0,
        sumInsuredIsAdditive: cover.sumInsuredIsAdditive,
        isMandatory: cover.isMandatory,
        isMain: cover.isMain,
        clauseID: cover.clauseID,
        coverEffectiveFrom: cover.coverEffectiveFrom,
        coverEffectiveTo: cover.coverEffectiveTo,
      })),
    vehicleDetailsInput: {
      bodyType: values.motorDetails.bodyType,
      engineType: values.motorDetails.engineType,
      horsePower: isValidNumber(values.motorDetails.horsePower)
        ? Number(values.motorDetails.horsePower)
        : 0,
      make: values.motorDetails.brand,
      model: values.motorDetails.model,
      nameOnLicenseAr: values.motorDetails.nameOnLicenseAr,
      nameOnLicenseEn: values.motorDetails.nameOnLicenseEn,
      usageType: values.motorDetails.usageType,
      yearOfMake: isValidNumber(values.motorDetails.yearOfMake)
        ? Number(values.motorDetails.yearOfMake)
        : null,
      engine: values?.motorDetails?.motor,
      vignette: isValidNumber(values.motorDetails.vignette)
        ? Number(values.motorDetails.vignette)
        : 0,
      vignetteCode: values.motorDetails.vignetteCode,
      chassis: values.motorDetails.chassis,
      plateNumber: values.motorDetails.plateNumber?.toString() || null,
      weight: isValidNumber(values.motorDetails.weight)
        ? Number(values.motorDetails.weight)
        : null,
      engineSize: isValidNumber(values.motorDetails.engineSize)
        ? Number(values.motorDetails.engineSize)
        : null,
      seatingCapacity: isValidNumber(values.motorDetails.seats)
        ? Number(values.motorDetails.seats)
        : null,
      carValue: isValidNumber(values.motorDetails.carValue)
        ? Number(values.motorDetails.carValue)
        : null,
      plateCode: values.motorDetails.plateCode,
      color: values.motorDetails.color || null,
    },
    costAndChargeInput: {
      chargesPercentage: isValidNumber(values.costs.chargesPercentage)
        ? PrecisionService.divideBy100(values.costs.chargesPercentage)
        : 0,
      policyCost: isValidNumber(values.costs.policyCost)
        ? Number(values.costs.policyCost)
        : 0,
      tpaFeesType: values.costs.tpaFeesType || null,
      tpaFeesAmount: isValidNumber(values.costs.tpaFeesAmount)
        ? Number(values.costs.tpaFeesAmount)
        : 0,
      // tpaFeesApplicableOn: values.costs.tpaFeesApplicableOn,
      tpaFeesPercentage: isValidNumber(values.costs.tpaFeesPercentage)
        ? PrecisionService.divideBy100(values.costs.tpaFeesPercentage)
        : 0,
      // tpaFeesType: values.costs.tpaFeesType,
    },
  };

  return data;
}

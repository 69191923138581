import _ from 'lodash';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
// import { validateCostWidgetField } from '../validation';
import { IFormState } from '../form';
import { IMarineGroupCertificateCostCharges } from '..';
import WidgetPaper from '../../../../../../../../components/common/WidgetPaper';
import EnhancedPercentageInput from '../../../../../../../../components/enhanced-form/EnhancedPercentageInput';
import EnhancedCurrencyInput from '../../../../../../../../components/enhanced-form/EnhancedCurrencyInput';
import WidgetSection from '../../../../../../../../components/common/WidgetSection';
import { validateCostWidgetField } from '../validation';

interface IMarineGroupPolicyCostChargesWidgetProps {
  pageState: IFormState;
  onPageStateUpdate: (pageState: IFormState) => void;
  disabledForm?: boolean;
  status: string;
  editableLine: boolean;
  currencySymbol: string;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, 32%)`,
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
}));

const MarineGroupPolicyCostChargesWidget: React.FC<
  IMarineGroupPolicyCostChargesWidgetProps
> = ({
  pageState,
  onPageStateUpdate,
  disabledForm,
  editableLine,
  currencySymbol,
}) => {
  const { classes } = useStyles();

  const values = pageState.values.costs;
  const errors = pageState.errors.costs;
  const touched = pageState.touched.costs;

  const planToPolicyRate = pageState.values.policyDetails.planToPolicyRate;

  const disabledRow = disabledForm || !editableLine;

  const onFieldBlur = (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.costs[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IMarineGroupCertificateCostCharges,
    value: any,
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);

    newPageState.values.costs[fieldName] = value;
    newPageState.errors.costs[fieldName] = validateCostWidgetField(
      fieldName,
      value
    );

    if (touched) {
      newPageState.touched.costs[fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper style={{ marginTop: '1em' }}>
      <WidgetSection
        title="Costs and Charges"
        hasTitleSpecificDesign={false}
        classes={
          {
            // container: classes.section,
          }
        }
        useSeparator={false}
      >
        <div className={classes.fieldRow}>
          <EnhancedCurrencyInput
            key="policyCost"
            name="policyCost"
            title="Policy Cost*"
            placeholder="Policy Cost"
            value={values.policyCost * planToPolicyRate}
            error={touched.policyCost ? errors.policyCost : ''}
            onBlur={() => onFieldBlur('policyCost')}
            onChange={(v) => onFieldUpdate('policyCost', v.target.value)}
            disabled={disabledRow}
            maxDecimalPercision={3}
            type={''}
            currencyText={currencySymbol}
            useCurrencyText
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedPercentageInput
            key="chargesPercentage"
            name="chargesPercentage"
            title="Charges Percentage*"
            placeholder="Charges Percentage"
            value={values.chargesPercentage}
            error={touched.chargesPercentage ? errors.chargesPercentage : ''}
            onBlur={() => onFieldBlur('chargesPercentage')}
            onChange={(v) => onFieldUpdate('chargesPercentage', v.target.value)}
            disabled={disabledRow}
            material
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedCurrencyInput
            key="tpaFeesAmount"
            name="tpaFeesAmount"
            title="TPA Fees Amount*"
            placeholder="TPA Fees Amount"
            currencyText={currencySymbol}
            useCurrencyText
            value={values.tpaFeesAmount * planToPolicyRate}
            error={touched.tpaFeesAmount ? errors.tpaFeesAmount : ''}
            onBlur={() => onFieldBlur('tpaFeesAmount')}
            onChange={(v) => onFieldUpdate('tpaFeesAmount', v.target.value)}
            disabled={disabledRow}
            maxDecimalPercision={3}
            type={''}
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />
        </div>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default MarineGroupPolicyCostChargesWidget;

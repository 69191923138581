import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  FetchResult,
  HttpLink,
  InMemoryCache,
  NextLink,
  Observable,
  Operation,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deepmerge from 'deepmerge';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { makeStyles } from 'tss-react/mui';
import Portal from './Portal';
import Loader from './components/Loader';
import CloseButtonIcon from './components/common/CloseButtonIcon';
import { MAIN_ONE_THEME, mainFontFamilyMedium } from './constants';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { LoadTenantSuccessAction } from './redux/tenant/actions';
import { ITenant } from './redux/tenant/types';
import { SignoutUserAction } from './redux/user/actions';
import DataService from './services/dataService';
import {
  resolveGraphqlBaseUrl,
  resolveIdentityBaseUrl,
  updateTenantConfigPerEnvironment,
} from './utils/tenant-utils';
import {
  getUserIdToken,
  getUserToken,
  refreshTokensAsync,
} from './utils/userUtils';

const rawTheme = createTheme();
const MAIN_THEME = deepmerge(rawTheme, MAIN_ONE_THEME);

const useStyles = makeStyles()(() => ({
  toastClass: {
    borderRadius: '4px',
    fontSize: '14px',
    minWidth: '316px',
    minHeight: '50px',
    padding: 15,
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: '10px',
    '&.Toastify__toast--success': {
      backgroundColor: '#FFF',
      color: MAIN_THEME.palette.primary2.main,
    },
    '&.Toastify__toast--info': {
      backgroundColor: MAIN_THEME.palette.primary2.main,
    },
    '&.Toastify__toast--error': {
      backgroundColor: MAIN_THEME.palette.error.main,
    },
    '&.Toastify__toast--warning': {
      backgroundColor: MAIN_THEME.palette.primary2.main,
    },
  },
  bodyToastClass: {
    '&.Toastify__toast-body': {
      display: 'grid',
      gridGap: '10px',
      gridTemplateColumns: 'auto auto',
      alignItems: 'baseline',
    },
  },
  closeButtonClass: {
    color: 'inherit',
    background: 'none',
    border: 0,
    cursor: 'pointer',
    outline: 0,
  },
  loaderContainer: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

class EmptyLink extends ApolloLink {
  constructor() {
    super();
  }

  request(operation: Operation, forward?: NextLink): Observable<FetchResult> {
    return new Observable((observer) => {
      observer.next(operation);
      observer.complete();
    });
  }
}

export const App: React.FC = () => {
  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    classes;
    loadTenant();
  }, []);

  const httpLink = new HttpLink({
    uri: `${resolveGraphqlBaseUrl()}/graphql`,
  });

  const setAuthHeaderLink = setContext(() => {
    return {
      headers: {
        authorization: `Bearer ${getUserToken()}` || null,
      },
    };
  });

  const errorLink = onError((errorResponse) => {
    const { graphQLErrors, forward, operation } = errorResponse;
    if (
      graphQLErrors &&
      graphQLErrors.length > 0 &&
      graphQLErrors[0]?.extensions?.code == 'UNAUTHORIZED_ACCESS'
    ) {
      const oldContext = operation.getContext();
      operation.setContext({
        ...oldContext,
        isTokenExpired: true,
        isUserAuthenticated: user.isAuthenticated,
        lastIdToken: getUserIdToken(),
      });
      return forward(operation);
    }

    return undefined;
  });

  const tokenRefreshLink = setContext(async (_, previousContext) => {
    const { lastIdToken } = previousContext;
    if (getUserIdToken() === lastIdToken) {
      try {
        setBooted(false);
        setLoading(true);
        const res = await refreshTokensAsync();
        if (res) {
          return {
            ...previousContext,
            isTokenRefreshed: true,
          };
        } else {
          return {
            ...previousContext,
            isTokenRefreshed: false,
          };
        }
      } catch (err) {
        return {
          ...previousContext,
          isTokenRefreshed: false,
        };
      } finally {
        setTimeout(() => {
          setBooted(true);
          setLoading(false);
        }, 100);
      }
    } else {
      return {
        ...previousContext,
        isTokenRefreshed: user.isAuthenticated,
      };
    }
  });

  const logoutLink = new ApolloLink((operation, forward) => {
    if (user.isAuthenticated) {
      dispatch(SignoutUserAction());
    }
    return forward(operation);
  });

  const link = errorLink
    ?.split(
      (operation) => operation.getContext().isTokenExpired,
      tokenRefreshLink
    )
    .split(
      (operation) => {
        const { isTokenExpired = false, isTokenRefreshed = false } =
          operation.getContext() as {
            isTokenExpired?: boolean;
            isTokenRefreshed?: boolean;
          };
        return isTokenExpired && !isTokenRefreshed;
      },
      from([logoutLink, new EmptyLink()]),
      from([setAuthHeaderLink, httpLink])
    );

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
  });

  const loadTenant: () => Promise<void> = async () => {
    let tenantResult: ITenant = {
      name: 'UFA',
      apiUrl: resolveGraphqlBaseUrl(),
      cdnUrl: 'https://ufa-cdn.baibars.club/ufa-app-portal',
      backgroundTemplate: '#D30D2B',
      login: {
        background: 'linear-gradient(0deg, #FFF, #C9C9C9)',
        logoUrl: '/images/logo-large-red.png',
        logoHeight: '92px',
        logoWidth: '159px',
        btnBackgroundColor: '#3F3F3F',
        btnFontColor: 'white',
        fontFamily: mainFontFamilyMedium,
      },
      header: {
        appLauncherUrl: '/images/app-launcher.svg',
        logoAlt: 'UFA Logo',
        logoUrl: '/images/logo-small-white.png',
        fontFamily: mainFontFamilyMedium,
      },
      astAccountName: 'stablemgariskcus',
      aircall: false,
      theme: { ...MAIN_ONE_THEME, fontFamily: mainFontFamilyMedium },
      currencySymbol: '$',
      phoneNumberPattern: '+1 (xxx) xxx-xxxx',
    } as any;

    tenantResult = updateTenantConfigPerEnvironment(tenantResult);

    DataService.setBaseUrl(resolveIdentityBaseUrl());
    DataService.setLogout(() => {
      dispatch(SignoutUserAction());
    });
    dispatch(LoadTenantSuccessAction(tenantResult));

    document.title = tenantResult.name;
    document.head.insertAdjacentHTML(
      'beforeend',
      `
      <link rel="manifest" href="${tenantResult.cdnUrl}/favicons/manifest.json" />
      <link rel="shortcut icon" href="${tenantResult.cdnUrl}/favicons/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="${tenantResult.cdnUrl}/favicons/apple-touch-icon.png">
      <meta name="msapplication-TileImage" content="${tenantResult.cdnUrl}/favicons/ms-icon-144x144.png">
		  <style>
			::-webkit-scrollbar {
				width: 6px;
				height: 6px;
				margin-top: 1px;
				color: ${tenantResult.theme.palette.primary1.main};
        fontFamily: mainFontFamilyMedium;
			}
			::-webkit-scrollbar-thumb {
				background: ${tenantResult.theme.palette.primary1.main};
				border-radius: 10px;
        fontFamily: mainFontFamilyMedium;
			}
			.MuiPickersToolbar-toolbar{
				background-color: ${tenantResult.login.btnBackgroundColor};
        fontFamily: mainFontFamilyMedium;
			}
			.MuiPickersDay-daySelected{
				background-color: ${tenantResult.login.btnBackgroundColor};
        fontFamily: mainFontFamilyMedium;
			}
			.MuiPickersDay-daySelected:hover{
				background-color: ${tenantResult.login.btnBackgroundColor};
        fontFamily: mainFontFamilyMedium;
			}
			.MuiTypography-colorPrimary{
				color: ${tenantResult.login.btnBackgroundColor};
			}
			body .flatpickr-day.selected {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
        fontFamily: mainFontFamilyMedium;
			}
			body .flatpickr-day.selected:focus {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.selected:hover {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			
			body .flatpickr-day.startRange {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.startRange:focus {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.startRange:hover {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			
			body .flatpickr-day.endRange {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.endRange:focus {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.endRange:hover {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			
			body .flatpickr-day.selected.inRange {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.startRange.inRange {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.selected.prevMonthDay {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.startRange.prevMonthDay {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.endRange.prevMonthDay {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.selected.nextMonthDay {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.startRange.nextMonthDay {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.endRange.nextMonthDay {
				background: ${tenantResult.theme.palette.primary3.main};
				border-color: ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)) {
				box-shadow: -10px 0 0 ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)) {
				box-shadow: -10px 0 0 ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)) {
				box-shadow: -10px 0 0 ${tenantResult.theme.palette.primary3.main};
			}
			body .flatpickr-day.week.selected {
				box-shadow: -5px 0 0 ${tenantResult.theme.palette.primary3.main} 5px 0 0 ${tenantResult.theme.palette.primary3.main};
			}

      input:focus {
        outline: none;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus, 
      input:-webkit-autofill:active{
          -webkit-box-shadow: 0 0 0 30px #f9f9f9 inset !important;
      }
		  </style>
        `
    );
    setBooted(true);
  };

  return booted ? (
    <ApolloProvider client={client}>
      <ThemeProvider theme={MAIN_THEME}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <Portal />
        </LocalizationProvider>
        <CssBaseline />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          draggable
          pauseOnHover
          closeButton={<CloseButtonIcon className={classes.closeButtonClass} />}
          toastClassName={classes.toastClass}
          bodyClassName={classes.bodyToastClass}
        />
      </ThemeProvider>
    </ApolloProvider>
  ) : loading ? (
    <div className={classes.loaderContainer}>
      <Loader />
    </div>
  ) : (
    <></>
  );
};

export default App;

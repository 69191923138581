import { gql } from '@apollo/client';

export const getPolicyDetailsQuery = gql`
  query getPolicyDetails($policyId: String!) {
    Production {
      entities {
        policy(id: $policyId) {
          views {
            Production_all {
              properties {
                PolicyExpiryDate
                PolicyIssueDate
                PolicyNumber
                PolicyName
              }
            }
          }
        }
      }
    }
    Production_PaymentDivisionTypeList: __type(
      name: "Production_PaymentDivisionTypeList"
    ) {
      name
      enumValues {
        name
      }
    }
    Accounting_BillStatusList: __type(name: "Accounting_BillStatusList") {
      name
      enumValues {
        name
      }
    }
    Accounting {
      queries {
        GetPolicyReceivableBills(PolicyID: $policyId) {
          accounting_Bills_OutstandingBalance
          accounting_Bills_BillNumber
          accounting_Bills_BillType
          accounting_Bills_Currency {
            Code
            Title
            Id
            Symbol
          }
        }
      }
    }
  }
`;

export function calculateMaxFrequencyQuery() {
  return gql`
    mutation calculateMaxFrequency(
      $policyIssuanceDate: Date!
      $policyExpiryDate: Date
      $effectiveFromDate: Date!
    ) {
      accounting {
        actions {
          calculateMaxFrequency(
            PolicyIssuanceDate: $policyIssuanceDate
            PolicyExpiryDate: $policyExpiryDate
            EffectiveFromDate: $effectiveFromDate
          ) {
            Values
          }
        }
      }
    }
  `;
}

export function calculateMaxPaymentsQuery() {
  return gql`
    mutation calculateMaxPayments(
      $policyIssuanceDate: Date!
      $policyExpiryDate: Date
      $effectiveFromDate: Date!
      $selectedFrequency: String!
    ) {
      accounting {
        actions {
          calculateMaxPayments(
            PolicyIssuanceDate: $policyIssuanceDate
            PolicyExpiryDate: $policyExpiryDate
            EffectiveFromDate: $effectiveFromDate
            SelectedFrequency: $selectedFrequency
          ) {
            Value
          }
        }
      }
    }
  `;
}

export function changePaymentSimulationQuery() {
  return gql`
    mutation changePaymentSimulation(
      $policyIssuanceDate: Date!
      $policyExpiryDate: Date
      $effectiveFromDate: Date!
      $selectedFrequency: String!
      $paymentType: Production_PaymentDivisionTypeList!
      $noOfPayments: Long!
      $noOfPaymentsList: [Long!]!
      $amountToBeAllocated: Decimal!
      $currentPolicyID: String!
      $parentBillID: String
    ) {
      accounting {
        actions {
          changePaymentSimulation(
            PolicyIssuanceDate: $policyIssuanceDate
            PolicyExpiryDate: $policyExpiryDate
            EffectiveFromDate: $effectiveFromDate
            SelectedFrequency: $selectedFrequency
            PaymentType: $paymentType
            NoOfPayments: $noOfPayments
            NoOfPaymentsList: $noOfPaymentsList
            AmountToBeAllocated: $amountToBeAllocated
            CurrentPolicyID: $currentPolicyID
            ParentBillID: $parentBillID
          ) {
            List {
              AmountDue
              BillNumber
              BillStatus
              DueDate
              OutstandingBalance
              TotalCommission
              TaxOnCommission
              Commission
              TotalPremium
            }
          }
        }
      }
    }
  `;
}

export function createChangePaymentTermsQuery() {
  return gql`
    mutation createChangePaymentTerms(
      $policyIssuanceDate: Date!
      $policyExpiryDate: Date
      $effectiveFromDate: Date!
      $selectedFrequency: String!
      $paymentType: Production_PaymentDivisionTypeList!
      $noOfPayments: Long!
      $noOfPaymentsList: [Long!]!
      $amountToBeAllocated: Decimal!
      $currentPolicyID: String!
      $parentBillID: String
      $newReceivableBillsList: [Accounting_NewReceivableBillsInput!]!
    ) {
      accounting {
        actions {
          createChangePaymentTerms(
            PolicyIssuanceDate: $policyIssuanceDate
            PolicyExpiryDate: $policyExpiryDate
            EffectiveFromDate: $effectiveFromDate
            SelectedFrequency: $selectedFrequency
            PaymentType: $paymentType
            NoOfPayments: $noOfPayments
            NoOfPaymentsList: $noOfPaymentsList
            AmountToBeAllocated: $amountToBeAllocated
            CurrentPolicyID: $currentPolicyID
            ParentBillID: $parentBillID
            NewReceivableBillsList: $newReceivableBillsList
          )
        }
      }
    }
  `;
}

import { IGenericLinesTableData } from '.';
import { IAbstractRecord } from '../../../models';
import { IListingData } from '../../../models/listing';
import { capitalizeFirstLetter } from '../../../utils/formatting-utils';

export function mapToListingData(data: IAbstractRecord): IListingData {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getLineList.items.forEach(
    (obj: IGenericLinesTableData, i: number) => {
      list[i] = {
        planConfigManagement_Line_Id: obj.planConfigManagement_Line_Id,
        planConfigManagement_Line_Name: obj.planConfigManagement_Line_Name,
        planConfigManagement_Line_ExternalCode:
          obj.planConfigManagement_Line_ExternalCode,
        planConfigManagement_Line_Abbreviation:
          obj.planConfigManagement_Line_Abbreviation,
        planConfigManagement_Line_NameArabic:
          obj.planConfigManagement_Line_NameArabic,
        planConfigManagement_Line_createdOn:
          obj.planConfigManagement_Line_createdOn,
        planConfigManagement_Line_modifiedOn:
          obj.planConfigManagement_Line_modifiedOn,
        planConfigManagement_Line_Status: capitalizeFirstLetter(
          obj.planConfigManagement_Line_Status?.replaceAll('_', ' ')
        ),
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.PlanConfigManagement.queries.getLineList?.paging?.pageSize,
    pageNumber:
      data.PlanConfigManagement.queries.getLineList?.paging?.pageNumber,
    totalCount:
      data.PlanConfigManagement.queries.getLineList?.paging?.totalCount,
  };
}

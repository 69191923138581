import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../../../components/ToastErrorMessage';
import { IListingData } from '../../../../../../../models/listing';
import { cloneDeep } from 'lodash';
import {
  mapToAccoutsLov,
  mapToPopupListingData,
  mapToTransactionListingData,
} from '../utils';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  generatePVTransactionsMutation,
  getPaymentPayablesMutation,
} from '../queries';
import { DEFAULT_ERROR_TEXT } from '../../../../../../../constants';
import { PVPopupHeaders } from '../content';
import {
  IAddVoucherLOVs,
  ITransactionTableData,
  IVoucherPopupValues,
} from '../../shared/types';
import { getAccountsByCompanyAndCurrencyQuery } from '../../shared/queries';
import DatePickerFormField from '../../../../../../../components/form-fields/DatePickerFormField';
import SelectFormField from '../../../../../../../components/form-fields/SelectFormField';
import TextInputFormField from '../../../../../../../components/form-fields/TextInputFormField';
import CurrencyFormField from '../../../../../../../components/form-fields/CurrencyFormField';
import EnhancedButton from '../../../../../../../components/form-fields/buttons/EnhancedButton';
import { IAbstractRecord } from '../../../../../../../models';
import ListingTableWithCellInputs from '../../../../../../../components/form-fields/table/with-cell-inputs/ListingTableWithCellInputs';
import { IEnhancedRow } from '../../../../../../../components/form-fields/table';
import { getBusinessPartnerInfo } from '../../../queries';
import {
  isEmpty,
  isValidNumber,
} from '../../../../../../../utils/validationUtils';
import { IAddVoucherErrors } from '..';
import DateService from '../../../../../../../services/dateService';

interface IPVPopupFormSection {
  isEdit: boolean;
  lovs: IAddVoucherLOVs;
  setLovs: (state: IAddVoucherLOVs) => void;
  values: IVoucherPopupValues;
  setValues: (state: IVoucherPopupValues) => void;
  errors: IAddVoucherErrors;
  setErrors: (state: IAddVoucherErrors) => void;
  tableData: IListingData;
  setTableData: (state: IListingData) => void;
  setIsSubmitDisabled: (state: boolean) => void;
  businessPartnerId: string;
  classes: Record<string, string>;
  disabled: boolean;
  loader?: boolean;
}

const PVPopupFormSection: React.FC<IPVPopupFormSection> = ({
  isEdit,
  lovs,
  setLovs,
  values,
  setValues,
  errors,
  setErrors,
  tableData,
  setTableData,
  setIsSubmitDisabled,
  businessPartnerId,
  classes,
  disabled,
  loader,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [getPaymentPayables] = useMutation(getPaymentPayablesMutation);
  const [generatePVTransactions] = useMutation(generatePVTransactionsMutation);
  const [getAccountsByCompanyAndCurrency] = useLazyQuery(
    getAccountsByCompanyAndCurrencyQuery()
  );

  const { data: businessPartnerData } = useQuery(getBusinessPartnerInfo, {
    variables: { id: businessPartnerId },
  });

  const getPaymentPayablesOfBrokers = async (pageValues: IAbstractRecord) => {
    try {
      if (isEdit) {
        return;
      }
      setLoading(true);

      const res = await getPaymentPayables({
        variables: {
          selectedCurrency: pageValues.currency ? pageValues.currency : null,
          selectedPaymentDate: DateService.formatDateBackend(
            pageValues.dateOfPayment
          ),
          currentBusinessPartnerID: businessPartnerId,
        },
        errorPolicy: 'all',
      });

      if (res.errors && res.errors.length > 0) {
        if (res.errors[0]?.extensions?.code === 'CurrencyExchangeRateNull') {
          toast.error(
            <ToastErrorMessage>{res.errors[0].message}</ToastErrorMessage>
          );
          setTableData({
            pagedItems: {},
            pageNumber: 1,
            pageSize: 5,
            totalCount: 0,
          });
        }
        return;
      }

      const data = mapToPopupListingData(
        res.data.accounting.actions.getPaymentPayables.PaymentBill,
        lovs.currenciesSymbols
      );
      setErrors({
        ...errors,
        tableAmountPaid: Array.from(
          { length: Object.keys(data).length },
          (): string => ''
        ),
      });
      setTableData({
        pagedItems: data,
        pageNumber: 1,
        pageSize: 5,
        totalCount: Object.keys(data).length,
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    setLoading(false);
  };

  const validateTableAmountPaid = (rowIndex?: number) => {
    const newTableData = cloneDeep(tableData);
    const newErrors = cloneDeep(errors);

    if (!isNaN(rowIndex)) {
      const row = newTableData.pagedItems[rowIndex];

      // Row-level validation: Check if the amount exceeds the outstanding amount
      if (row.amountOutstandingCurrency >= 0) {
        // Bill is positive
        if (row.amount > row.amountOutstandingCurrency) {
          newErrors.tableAmountPaid[rowIndex] =
            'Amount Paid cannot be greater than Amount Outstanding';
          newTableData.pagedItems[rowIndex].error = 'error';
        } else if (row.amount < 0) {
          newErrors.tableAmountPaid[rowIndex] =
            'Amount Paid cannot be negative';
          newTableData.pagedItems[rowIndex].error = 'error';
        } else {
          newErrors.tableAmountPaid[rowIndex] = '';
          newTableData.pagedItems[rowIndex].error = '';
        }
      } else {
        // Bill is negative
        if (row.amount < row.amountOutstandingCurrency) {
          newErrors.tableAmountPaid[rowIndex] =
            'Amount Paid cannot be greater than Amount Outstanding';
          newTableData.pagedItems[rowIndex].error = 'error';
        } else if (row.amount > 0) {
          newErrors.tableAmountPaid[rowIndex] =
            'Amount Paid cannot be positive';
          newTableData.pagedItems[rowIndex].error = 'error';
        } else {
          newErrors.tableAmountPaid[rowIndex] = '';
          newTableData.pagedItems[rowIndex].error = '';
        }
      }
    }

    // If no row-level error, calculate the total unallocated amount
    if (!newErrors.tableAmountPaid[rowIndex]) {
      const sumAmountPaid = Object.keys(tableData.pagedItems).reduce(
        (total: number, key: string) => {
          const amount = newTableData.pagedItems[key].amount;
          return isValidNumber(amount) ? total + parseFloat(amount) : total;
        },
        0
      );

      const totalAmountUnAllocated = values.totalAmount - sumAmountPaid;

      // Total validation: Check if the total paid exceeds the total amount

      newErrors.totalAmount =
        totalAmountUnAllocated < 0
          ? 'Sum of all Amount Paid cannot be greater than Total Amount Paid'
          : '';

      if (!newErrors.totalAmount) {
        setValues({
          ...values,
          totalAmountUnAllocated,
        });
      }
    }

    setErrors(newErrors);
    setTableData(newTableData);
  };

  const handleTableValueChange = (
    index: number,
    columnName: string,
    value: unknown
  ) => {
    const newTableData = cloneDeep(tableData);
    newTableData.pagedItems[index][columnName] = value;
    setTableData(newTableData);
    setIsSubmitDisabled(true);
  };

  const handleChange = (inputName: string, value: unknown) => {
    const newValues = cloneDeep(values);
    (newValues as any)[inputName] = value;

    if (inputName === 'dateOfPayment') {
      getPaymentPayablesOfBrokers(newValues);
    }
    setValues(newValues);
    setIsSubmitDisabled(true);
  };

  const handleAccountsUpdate = async (
    transactions: ITransactionTableData[]
  ) => {
    const newLovs = cloneDeep(lovs);

    for (let index = 0; index < transactions.length; index++) {
      const transaction = transactions[index];

      if (transaction.accountName === '--' || !transaction.accountName) {
        const res = await getAccountsByCompanyAndCurrency({
          variables: {
            selectedCompanyID: values.companyId,
            accountCurrency:
              transaction.transactionCurrency ||
              values.systemCurrencies.primary.Code,
            pageNumber: 1,
            pageSize: 99999,
          },
        });

        newLovs.accounts[index] = mapToAccoutsLov(
          res.data.Accounting.queries.GetAccountsByCompanyandCurrency.items
        );
      }
    }

    setLovs(newLovs);
  };

  const handleSubmit = async () => {
    try {
      const res = await generatePVTransactions({
        variables: {
          paymentCurrency: values.currency,
          paymentDate: DateService.formatDateBackend(values.dateOfPayment),
          totalAmount: Number(values.totalAmount),
          pVList: Object.keys(tableData.pagedItems)
            .filter((key: string) => !isEmpty(tableData.pagedItems[key].amount))
            .map((key: string) => {
              return {
                PolicyID: tableData.pagedItems[key].policyId,
                PolicyNumber: tableData.pagedItems[key].policyNum,
                BillID: tableData.pagedItems[key].billId,
                BillNumber: tableData.pagedItems[key].billNum,
                DueDate: DateService.formatDateBackend(
                  tableData.pagedItems[key].dueDate
                ),
                CommissionDue: Number(tableData.pagedItems[key].amountDue),
                AmountOutstanding: Number(
                  tableData.pagedItems[key].amountOutstandingCurrency
                ),
                AmountOutstandingCurrency: Number(
                  tableData.pagedItems[key].amountOutstandingCurrency
                ),
                AmountPaid: Number(tableData.pagedItems[key].amount),
              };
            }),
          currentBusinessPartnerID: businessPartnerId,
        },
      });

      const { totalDebit, totalCredit, transactions } =
        mapToTransactionListingData(
          res.data.accounting.actions.generatePVTransactions
            .PaymentTransactionList
        );

      handleAccountsUpdate(transactions);

      setValues({
        ...values,
        totalDebit,
        totalCredit,
        transactions,
      });

      setIsSubmitDisabled(false);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const dynamicRowMessage = (row: IEnhancedRow): string[] => {
    if (row && row.columns) {
      if (errors.tableAmountPaid) {
        return [errors.tableAmountPaid[row.index]];
      }
    }
    return [];
  };

  const validateForm = () => {
    // Check if any of the fields are empty or invalid
    // If so, return true to disable the submit button
    const invalid =
      !values.dateOfPayment ||
      !values.currency ||
      !values.totalAmount ||
      Number(values.totalAmount) === 0 ||
      Number(values.totalAmountUnAllocated) !== 0 ||
      errors.tableAmountPaid.some((error) => error !== '');

    return invalid;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className={classes.inputsRow}>
        <DatePickerFormField
          name="dateOfPayment"
          title="Date Payment was Paid*"
          value={values.dateOfPayment}
          onDateChange={(v) => {
            handleChange('dateOfPayment', v);
          }}
          error={errors.dateOfPayment}
          disabled={disabled}
        />
        <SelectFormField
          name="currency"
          title="Currency*"
          placeholder="Select a Currency"
          selectOptions={lovs.currenciesSymbols}
          onChange={(v) => {
            handleChange('currency', v);
          }}
          onBlur={() => {
            getPaymentPayablesOfBrokers(values);
          }}
          value={values.currency}
          error={errors.currency}
          disabled={disabled}
        />
        <SelectFormField
          name="paymentMethod"
          title="Payment Method*"
          placeholder="Select a Payment Method"
          selectOptions={lovs.paymentMethods}
          onChange={(v) => {
            handleChange('paymentMethod', v);
          }}
          value={values.paymentMethod}
          error={errors.paymentMethod}
          disabled={disabled}
        />
        <TextInputFormField
          name="referenceNum"
          title="Reference Number"
          placeholder="Enter a Reference Number"
          value={values.referenceNum}
          onChange={(e) => {
            handleChange('referenceNum', e.target.value);
          }}
          onBlur={() => {}}
          error={errors.referenceNum}
          disabled={disabled}
        />
      </div>
      <div className={classes.inputsRow}>
        <CurrencyFormField
          name="totalAmount"
          title="Total Amount Paid*"
          placeholder="Enter the Total Amount Paid"
          value={values.totalAmount}
          onBlur={() => {
            validateTableAmountPaid();
          }}
          onChange={(e) => {
            handleChange('totalAmount', e.target.value);
          }}
          currencySymbol={lovs.currenciesSymbols[values.currency]}
          error={errors.totalAmount}
          disabled={disabled || !values.currency}
        />
        <CurrencyFormField
          name="totalAmountUnAllocated"
          title="Total Amount Unallocated*"
          placeholder="Total Amount Unallocated"
          value={values.totalAmountUnAllocated}
          onBlur={() => {}}
          onChange={() => {}}
          disabled
          currencySymbol={lovs.currenciesSymbols[values.currency]}
          error={errors.totalAmountUnAllocated}
        />
      </div>
      <ListingTableWithCellInputs
        name="paymentVouchers"
        inlineTitle="Payment Vouchers"
        headers={PVPopupHeaders(
          values.currency,
          lovs.currenciesSymbols,
          disabled,
          businessPartnerData?.SalesforceManagement.entities.businessPartner
            .views.SalesforceManagement_all.properties.Type.Code === 'Reinsurer'
        )}
        loader={loader || loading}
        data={tableData}
        handleCellValueChanged={(index, columnName, value) => {
          handleTableValueChange(index, columnName, value);
        }}
        handleUpdate={(index, columnName) => {
          if (columnName === 'amount') {
            validateTableAmountPaid(index);
          }
        }}
        disableSelection
        inline
        dynamicRowMessage={dynamicRowMessage}
        forceShowSelectColumn={true}
      />
      {!disabled && (
        <div className={classes.buttonRow}>
          <EnhancedButton disabled={validateForm()} type="submit">
            Generate Transactions
          </EnhancedButton>
        </div>
      )}
    </form>
  );
};

export default PVPopupFormSection;

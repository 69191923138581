import React, { useCallback } from 'react';
import clsx from 'clsx';
import EnhancedCheckbox from '../../enhanced-form/EnhancedCheckbox';
import { ITableRowSettings } from '../../widgets/listing-widget';
import Loader from '../../Loader';
import { makeStyles } from 'tss-react/mui';
import {
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  lighten,
} from '@mui/material';
import {
  MAIN_ONE_THEME,
  contentFontFamilyRegular,
  contentFontFamilyBold,
} from '../../../constants';
import { IAbstractRecord } from '../../../models';
import { EnhancedTableHeader } from '../../enhanced-table';
import EnhancedTableBodyCell from '../../enhanced-table/EnhancedTableBodyCell';
import Error from '@mui/icons-material/Error';
import { getRowConditions } from '../../../utils/table-utils';
import { isEmpty } from '../../../utils/validationUtils';
import { IEnhancedRow } from '.';

interface IListingTableBodyProps {
  disableSelection?: boolean | DisableSelectionType;
  emptyRows: number;
  handleClick(
    name: string,
    checked: boolean,
    rowIndex?: string,
    rowId?: string
  ): void;
  handleCellValueChanged?(
    rowKey: number,
    property: string,
    value: unknown
  ): void;
  handleBlur?(rowIndex: number, columnId: string, newValue: unknown): void;
  headers: Record<string, EnhancedTableHeader>;
  rowSettings?: ITableRowSettings;
  dynamicRowMessage?: (row: IEnhancedRow) => string[];
  isSelected: (key: string) => boolean;
  rows?: IEnhancedRow[];
  currencySymbol?: string;
  phoneNumberPattern?: string;
  entityName?: string;
  loader?: boolean;
  showCellFullData?: boolean;
  forceShowSelectColumn?: boolean;
  tableClasses?: {
    inputContainer?: string;
  };
}

export enum DisableSelectionType {
  'Hide' = 'Hide',
}

const useStyles = makeStyles()(() => ({
  bodyRoot: {
    '& tr:nth-of-type(odd)': {
      backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    },
  },
  rowRoot: {
    '&.Mui-selected': {
      backgroundColor: lighten(MAIN_ONE_THEME.palette.primary2.main, 0.85),
    },
    '&.MuiTableRow-hover:hover': {
      backgroundColor: lighten(MAIN_ONE_THEME.palette.primary2.main, 0.85),
    },
  },
  cellRoot: {
    color: `${MAIN_ONE_THEME.palette.primary1.main} !important`,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}  !important`,
    lineHeight: '12px !important',
    height: '34px !important',
    border: 'none !important',
    padding: '4px 2px !important',
    fontFamily: contentFontFamilyBold,
  },
  checkboxCellRoot: {
    width: '14px !important',
    padding: '0 14px 0 8px !important',
    border: 'none !important',
    height: '34px !important',
    position: 'relative !important' as 'relative',
    fontFamily: contentFontFamilyRegular,
  },
  checkboxCellRootWithMessage: {
    padding: '0 20px 0 8px !important',
  },
  noDataMessage: {
    textAlign: 'center',
    color: MAIN_ONE_THEME.palette.secondary2.main,
  },
  noDataCell: {
    color: MAIN_ONE_THEME.palette.primary1.main,
    border: 'none',
    height: '34px',
    fontSize: '14px',
    lineHeight: '12px',
    fontFamily: contentFontFamilyRegular,
  },
  errorIconStyle: {
    height: '15px',
    width: '15px',
    color: 'red',
    position: 'absolute',
    top: '10px',
    right: '8px',
    cursor: 'pointer',
  },
  paddedCellRoot: {
    padding: '0px 6px 0px 20px !important',
  },
  selectablePaddedCellRoot: {
    padding: '0px 6px 0px 60px !important',
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize} !important`,
  },
}));

const ListingTableBody: React.FC<IListingTableBodyProps> = ({
  emptyRows,
  handleClick,
  handleCellValueChanged,
  handleBlur,
  headers,
  isSelected,
  dynamicRowMessage,
  rowSettings,
  rows,
  currencySymbol,
  disableSelection,
  loader,
  showCellFullData,
  forceShowSelectColumn,
  tableClasses,
}) => {
  const { classes } = useStyles();

  const checkIfRowHasMessage = useCallback(
    (row: IEnhancedRow) => {
      if (dynamicRowMessage) {
        const dynamcMessages = dynamicRowMessage(row);
        if (dynamcMessages && dynamcMessages.length > 0) {
          return true;
        }
      }

      if (
        isSelected(row.key) &&
        Number(row.columns.OutstandingAmount) <
          Number(row.columns.CollectedAmount)
      ) {
        return true;
      }
      if (
        !isSelected(row.key) ||
        !rowSettings ||
        !rowSettings.conditions ||
        rowSettings.conditions.length === 0
      ) {
        return false;
      }
      return getRowConditions(rowSettings, row);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, rowSettings]
  );

  const renderRow = (row: IEnhancedRow) => {
    let message = '';
    if (dynamicRowMessage) {
      const dynamcMessages = dynamicRowMessage(row);
      if (dynamcMessages && dynamcMessages.length > 0) {
        message = dynamcMessages[0];
      }
    }

    if (!message) {
      if (rowSettings && checkIfRowHasMessage(row)) {
        message = rowSettings.iconTooltip;
      }
    }
    if (message) {
      return (
        <Tooltip title={message}>
          <span>
            <Error className={classes.errorIconStyle} />
          </span>
        </Tooltip>
      );
    }

    return <></>;
  };

  return (
    <TableBody
      classes={{
        root: classes.bodyRoot,
      }}
    >
      {rows.length > 0 ? (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.key);
          const labelId = `enhanced-table-checkbox-${index}`;
          const rowMessage = checkIfRowHasMessage(row);

          return (
            <TableRow
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.key}
              selected={isItemSelected}
              classes={{ root: classes.rowRoot }}
            >
              {(!disableSelection || !isEmpty(rowMessage)) && (
                <TableCell
                  padding="none"
                  classes={{
                    root: clsx(classes.checkboxCellRoot),
                  }}
                >
                  {!disableSelection && (
                    <EnhancedCheckbox
                      checked={isItemSelected}
                      name={row.key}
                      onChange={handleClick}
                      checkboxOnly
                    />
                  )}
                  {renderRow(row)}
                </TableCell>
              )}
              {Object.values(headers).map((header, headerIndex) => {
                return (
                  <EnhancedTableBodyCell
                    selected={isSelected(row.key)}
                    key={header.name + labelId}
                    row={row}
                    header={header}
                    currencySymbol={currencySymbol}
                    className={clsx({
                      [classes.paddedCellRoot]:
                        disableSelection &&
                        forceShowSelectColumn &&
                        !headerIndex,
                    })}
                    tableClasses={tableClasses}
                    showCellFullData={showCellFullData}
                    handleCellValueChanged={handleCellValueChanged}
                    handleUpdate={handleBlur}
                  />
                );
              })}
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell
            rowSpan={2}
            colSpan={
              Object.keys(headers).length > 0
                ? Object.keys(headers).length + 1
                : 1
            }
            align="center"
            classes={{ root: classes.noDataCell }}
          >
            {loader ? <Loader /> : 'No Data'}
          </TableCell>
        </TableRow>
      )}
      {emptyRows > 0 && <TableRow style={{ height: 34 * emptyRows }} />}
    </TableBody>
  );
};

export default ListingTableBody;

import { makeStyles } from 'tss-react/mui';
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import FlatPicker from 'flatpickr';
import ClearIcon from '@mui/icons-material/Clear';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import { IEnhancedFormInputBaseProps } from '.';
import DateService from '../../services/dateService';

export interface IDateRangeFormFieldProps extends IEnhancedFormInputBaseProps {
  disabled?: boolean;
  value: string[];
  hideError?: boolean;
  placeholder?: string;
  maxDate?: Date;
  minDate?: Date;
  onDateRangeChange: (values: string[]) => void;
}

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  flatInput: {
    cursor: 'pointer',
    visibility: 'hidden',
    position: 'absolute',
    height: '0',
    width: '0',
    margin: '0',
    padding: '0',
    border: '0',
    bottom: '0',
    left: '50%',
  },
  input: {
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    lineHeight: '15px',
    height: 35,
    width: '100%',
    padding: '0 15px',
    margin: '0 auto',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    textAlign: 'left',
    cursor: 'pointer',
    color: MAIN_ONE_THEME.palette.primary1.main,
    '&:disabled': {
      cursor: 'not-allowed',
      color: 'rgba(0, 0, 0, 0.5)!important',
      opacity: `0.6 !important`,
      textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.5) !important`,
    },
    outline: 'none',
    fontFamily: contentFontFamilyRegular,
  },
  placeholder: {
    fontStyle: 'italic',
    fontFamily: contentFontFamilyRegular,
    color: '#33333333',
    opacity: 1,
    fontSize: '14px',
  },
  clearButton: {
    cursor: 'pointer',
    height: '12px',
    width: '12px',
    position: 'absolute',
    right: '8px',
    bottom: '50%',
    top: 'calc(50% - 7.5px)',
    color: 'rgba(0, 0, 0, 0.25)',
    fontSize: '1.0rem !important',
  },
}));

const DateRangeFormField: React.FC<IDateRangeFormFieldProps> = ({
  className,
  style,
  name,
  title,
  inputOnly,
  hideError,
  error,
  disabled = false,
  value = [],
  maxDate,
  minDate,
  placeholder,
  onDateRangeChange,
}) => {
  const { classes } = useStyles();
  const flatPickerInput = useRef<HTMLInputElement>(null);
  const flatpickerInstance = useRef<FlatPicker.Instance | null>(null);
  const [dateRange, setDateRange] = useState<
    [string | undefined, string | undefined]
  >([undefined, undefined]);

  const handleDateUpdate = useCallback(
    (selectedDates: Date[]) => {
      const formattedStartDate =
        selectedDates[0] && DateService.isValidDate(selectedDates[0])
          ? DateService.formatDateBackend(selectedDates[0])
          : '';
      const formattedEndDate =
        selectedDates[1] && DateService.isValidDate(selectedDates[1])
          ? DateService.formatDateBackend(selectedDates[1])
          : '';
      setDateRange([
        formattedStartDate,
        formattedEndDate || formattedStartDate,
      ]);
      onDateRangeChange([
        formattedStartDate,
        formattedEndDate || formattedStartDate,
      ]);
    },
    [onDateRangeChange]
  );

  useEffect(() => {
    if (value && value.length > 0) {
      setDateRange([value[0], value[1]]);
    } else {
      setDateRange([undefined, undefined]);
    }
  }, [value]);

  useEffect(() => {
    if (flatPickerInput.current) {
      flatpickerInstance.current = FlatPicker(flatPickerInput.current, {
        enableTime: false,
        altInput: true,
        altFormat: 'F j, Y',
        dateFormat: DateService.FRONTEND_DATE_FORMAT,
        mode: 'range',
        minDate: minDate ? new Date(minDate) : undefined,
        maxDate: maxDate ? new Date(maxDate) : undefined,
        disableMobile: true,
        defaultDate:
          value && value.length > 0
            ? value.map((v) => (DateService.isValidDate(v) ? new Date(v) : ''))
            : undefined,
        onClose: handleDateUpdate,
      });
    }
    return () => {
      if (flatpickerInstance.current) {
        flatpickerInstance.current.destroy();
        flatpickerInstance.current = null;
      }
    };
  }, [handleDateUpdate, maxDate, minDate, value]);

  const renderDateValue = useMemo(() => {
    if (dateRange[0] && dateRange[1]) {
      return `${DateService.formatDate(dateRange[0])} to ${DateService.formatDate(
        dateRange[1]
      )}`;
    }
    return (
      <span className={classes.placeholder}>
        {placeholder || 'Select Date Range'}
      </span>
    );
  }, [dateRange, placeholder, classes.placeholder]);

  const renderInput = () => (
    <div className={classes.container}>
      <button
        type="button"
        className={classes.input}
        disabled={disabled}
        onClick={() => {
          if (!disabled && flatpickerInstance.current) {
            flatpickerInstance.current.open();
          }
        }}
      >
        {renderDateValue}
      </button>
      {dateRange[0] && (
        <ClearIcon
          className={classes.clearButton}
          onClick={() => {
            setDateRange([undefined, undefined]);
            onDateRangeChange([]);
          }}
        />
      )}
      <input
        className={classes.flatInput}
        type="hidden"
        ref={flatPickerInput}
      />
    </div>
  );

  if (inputOnly) {
    return renderInput();
  }

  return (
    <EnhancedInputsWrapper
      title={title}
      error={error}
      name={name}
      hideError={hideError}
      className={className}
      style={style}
    >
      {renderInput()}
    </EnhancedInputsWrapper>
  );
};

export default DateRangeFormField;

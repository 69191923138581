import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import PrecisionService from '../../services/precisionService';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  primaryBP: '',
  primaryPercentage: 0,
  secondaryBP: '',
  secondaryPercentage: 0,
  additionalBP: '',
  additionalPercentage: 0,
};

export const getInitialInputs = (
  data: IUpdateCommissionProposalData,
  partnerLov: Record<string, string>
): Record<string, DynamicFormInputType> => {
  return {
    primaryBP: {
      name: 'primaryBP',
      title: 'Primary Business Partner',
      type: FormInputTypes.chips,
      placeholder: 'Primary Business Partner',
      value: data?.primaryBP || initialValues.primaryBP || '',
      required: true,
      disabled: true,
      selectOptions: partnerLov,
    },
    primaryPercentage: {
      name: 'primaryPercentage',
      title: 'Share',
      type: FormInputTypes.percentage,
      placeholder: 'Share',
      value: data?.primaryPercentage
        ? PrecisionService.multiplyBy100(data.primaryPercentage)
        : PrecisionService.multiplyBy100(initialValues.primaryPercentage),
      required: true,
    },
    secondaryBP: {
      name: 'secondaryBP',
      title: 'Secondary Business Partner',
      type: FormInputTypes.chips,
      placeholder: 'Secondary Business Partner',
      value: data?.secondaryBP || initialValues?.secondaryBP || '',
      required: !isEmpty(data?.secondaryBP),
      hidden: isEmpty(data?.secondaryBP),
      disabled: true,
      selectOptions: partnerLov,
    },
    secondaryPercentage: {
      name: 'secondaryPercentage',
      title: 'Share',
      type: FormInputTypes.percentage,
      placeholder: 'Share',
      value: data?.secondaryPercentage
        ? PrecisionService.multiplyBy100(data.secondaryPercentage)
        : PrecisionService.multiplyBy100(initialValues.secondaryPercentage),
      required: !isEmpty(data?.secondaryBP),
      hidden: isEmpty(data?.secondaryBP),
    },
    additionalBP: {
      name: 'additionalBP',
      title: 'Additional Business Partner',
      type: FormInputTypes.chips,
      placeholder: 'Additional Business Partner',
      value: data.additionalBP || initialValues.additionalBP,
      required: !isEmpty(data?.additionalBP),
      hidden: isEmpty(data?.additionalBP),
      disabled: true,
      selectOptions: partnerLov,
    },
    additionalPercentage: {
      name: 'additionalPercentage',
      title: 'Share',
      type: FormInputTypes.percentage,
      placeholder: 'Share',
      value: data?.additionalPercentage
        ? PrecisionService.multiplyBy100(data.additionalPercentage)
        : PrecisionService.multiplyBy100(initialValues.additionalPercentage),
      required: !isEmpty(data?.additionalBP),
      hidden: isEmpty(data?.additionalBP),
    },
  };
};

import { gql } from '@apollo/client';

export const getFacultativesQuery = gql`
  query getFacultatives(
    $pagination: PaginationInput!
    $SelectedProposal: [String]
    $SelectedPolicy: [String]
    $SelectedReinsurer: [String]
    $CreatedFromDate: Date
    $CreatedToDate: Date
    $SelectedStatus: String
    $KeywordSearch: String
    $OrderByField: String
    $DescendingField: Boolean
  ) {
    Production {
      queries {
        FacultativesList(
          pagination: $pagination
          SelectedProposal: $SelectedProposal
          SelectedPolicy: $SelectedPolicy
          SelectedReinsurer: $SelectedReinsurer
          CreatedFromDate: $CreatedFromDate
          CreatedToDate: $CreatedToDate
          SelectedStatus: $SelectedStatus
          KeywordSearch: $KeywordSearch
          OrderByField: $OrderByField
          DescendingField: $DescendingField
        ) {
          items {
            production_Facultatives_Id
            production_Facultatives_FacultativeID
            production_Facultatives_SlipReferenceNumber
            proposal_ProposalName
            production_Facultatives_PolicyID {
              id
              views {
                Production_all {
                  properties {
                    PolicyName
                  }
                }
              }
            }
            mainReinsurerDetails_FullName
            production_Facultatives_PaymentDueDate
            production_Facultatives_FacultativeStatus
            production_Facultatives_createdOn
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
  }
`;

export const getLovsQuery = gql`
  query getFacLovs {
    Production {
      queries {
        GetFacReinsurers {
          facultativeReinsurersBusinessPartner_FullName
        }
      }
    }
    Production_FacStatusList: __type(name: "Production_FacStatusList") {
      enumValues {
        name
      }
    }
  }
`;

export const getProposalsListQuery = gql`
  query getProposalsList(
    $pagination: PaginationInput!
    $KeywordSearch: String
  ) {
    Production {
      queries {
        getProposalList(
          pagination: $pagination
          KeywordSearch: $KeywordSearch
        ) {
          items {
            production_Proposal_Id
            production_Proposal_ProposalName
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
  }
`;

export const getPoliciesListQuery = gql`
  query getPoliciesList($pagination: PaginationInput!, $KeywordSearch: String) {
    Production {
      queries {
        getPolicyList(pagination: $pagination, KeywordSearch: $KeywordSearch) {
          items {
            production_Policy_Id
            production_Policy_PolicyNumber
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
  }
`;

export const getProposalNameQuery = gql`
  query getProposalName($ProposalIDs: [String]) {
    Production {
      queries {
        GetProposalName(ProposalIDs: $ProposalIDs) {
          production_Proposal_Id
          production_Proposal_ProposalName
        }
      }
    }
  }
`;

export const getPolicyNumberQuery = gql`
  query getPolicyNumber($PolicyIDs: [String]) {
    Production {
      queries {
        GetPolicyNumber(PolicyIDs: $PolicyIDs) {
          production_Policy_Id
          production_Policy_PolicyNumber
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export function getActiveAssignedBPPlanList() {
  return gql`
    query getActiveAssignedBPPlanList(
      $selectedBusinessUserID: String!
      $selectedBusinessPartnerID: String
      $selectedLineID: String
      $selectedPlanID: String
      $selectedSublineID: String
    ) {
      SalesforceManagement {
        queries {
          getActiveAssignedBPPlanList(
            pagination: { pageNumber: 1, pageSize: 1000000 }
            selectedBusinessUserID: $selectedBusinessUserID
            selectedBusinessPartnerID: $selectedBusinessPartnerID
            selectedLineID: $selectedLineID
            selectedPlanID: $selectedPlanID
            selectedSublineID: $selectedSublineID
          ) {
            items {
              salesforceManagement_AssignedBPPlan_Id
              person_Id
              person_FullName
              plan_Id
              plan_EffectiveFrom
              plan_EffectiveTo
              plan_PlanStatus
              subline_Id
              subline_Status
              businessPartnerPlan_Id
              businessPartnerPlan_ActiveFrom
              businessPartnerPlan_ActiveTo
              line_Id
              line_Status
              businessPartner_Id
              salesforceManagement_AssignedBPPlan_BusinessUserID {
                id
              }
              salesforceManagement_AssignedBPPlan_AssignedBusinessPartnerID {
                id
              }
              salesforceManagement_AssignedBPPlan_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                      Name
                    }
                  }
                }
              }
              salesforceManagement_AssignedBPPlan_PlanID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      PolicyCost
                      TPAFeesAmount
                      ChargesPercentage
                      ExternalCode
                      Name
                      PlanCurrency {
                        Code
                      }
                      SublineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              ExternalCode
                              Name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getEligiblePlanCovers() {
  return gql`
    query GetEligiblePlanCovers($planID: String) {
      Production {
        queries {
          GetPlanCovers(planID: $planID) {
            planConfigManagement_PlanCover_Id
            policyCover_Id
            policyCover_Name
            policyCover_ExternalCode
            planConfigManagement_PlanCover_IsMain
            planConfigManagement_PlanCover_IsMandatory
            planConfigManagement_PlanCover_IsEditable
            planConfigManagement_PlanCover_CoverPremiumType
            planConfigManagement_PlanCover_CoverPremiumPercentage
            planConfigManagement_PlanCover_CoverPremiumValue
            planConfigManagement_PlanCover_CoverSumInsured
            planConfigManagement_PlanCover_SumInsuredIsAdditive
            planConfigManagement_PlanCover_ExcessOnClaimType
            planConfigManagement_PlanCover_ExcessOnClaimAmount
            planConfigManagement_PlanCover_ExcessOnClaimPercentage
            planConfigManagement_PlanCover_ExcessOnClaimDays
            planConfigManagement_PlanCover_ClauseID {
              id
            }
            planConfigManagement_PlanCover_PlanID {
              views {
                PlanConfigManagement_all {
                  properties {
                    PlanCurrency {
                      Code
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getLovs() {
  return gql`
    query GetLovs {
      Core {
        lookups {
          matters {
            Id
            Code
            Title
          }
          currencies {
            Id
            Code
            Title
          }
        }
      }
    }
  `;
}

export function addCertificate() {
  return gql`
    mutation AddCertificate(
      $entityId: String!
      $certificateInput: [Production_MarineCertificateInput!]!
    ) {
      production {
        entities {
          policy {
            production {
              uploadMarineCertificate(
                entityId: $entityId
                certificateInput: $certificateInput
              ) {
                Items {
                  SublineID
                  LineID
                  PlanID
                  Matter
                  VesselName
                  VesselAge
                  DepartureFrom
                  DestinationTo
                  TransportationVia
                  LCNumber
                  LCIssueDate
                  Loading
                  SumPerShipment
                  SumPerShipmentCurrency
                  SumPerShipmentRate
                  SumPerShipmentPolicyCurrency
                  certificatePricingOption {
                    pricingOptionCurrency {
                      Id
                      Symbol
                    }
                    sumInsured
                    netPremium
                    policyCostAmount
                    chargesAmount
                    tpaFeesTotalAmount
                    grossPremium
                    fixedStampAmount
                    proportionalStampAmount
                    municipalityTaxAmount
                    totalPremium
                    basicPremium
                    basicPremiumBasic
                    basicPremiumSecondary
                    basicToPolicyRate
                    chargesBasic
                    chargesPercentage
                    chargesSecondary
                    commissionToBasicRate
                    commissionToPolicyRate
                    fixedStamp
                    fixedStampBasic
                    fixedStampSecondary
                    fixedStampToBasicRate
                    fixedStampToPolicyRate
                    grossPremiumBasic
                    grossPremiumSecondary
                    municipalityTax
                    municipalityTaxBasic
                    municipalityTaxSecondary
                    netPremiumBasic
                    netPremiumSecondary
                    planToBasicRate
                    planToPolicyRate
                    policyCost
                    policyCostBasic
                    policyCostSecondary
                    proportionalStamp
                    proportionalStampBasic
                    proportionalStampSecondary
                    reinsuranceTax
                    reinsuranceTaxAmount
                    sumInsuredArabic
                    sumInsuredArabicLetters
                    sumInsuredBasic
                    sumInsuredLetters
                    sumInsuredSecondary
                    taxOnCommission
                    taxOnCommissionAmount
                    taxOnCommissionBasic
                    taxOnCommissionSecondary
                    totalCommission
                    totalCommissionBasic
                    totalCommissionSecondary
                    totalPremiumArabic
                    totalPremiumArabicLetters
                    totalPremiumBasic
                    totalPremiumLetters
                    totalPremiumSecondary
                    tpaFeesAmount
                    tpaFeesApplicableOn
                    tpaFeesPercentage
                    tpaFeesTotalBasic
                    tpaFeesTotalSecondary
                    tpaFeesType
                    fixedStampCurrency {
                      Id
                    }
                    pricingOptionCurrency {
                      Id
                    }
                    status
                  }
                  EffectiveDate
                  Bank
                  CargoContract
                  certificatePolicyBusinessPartner {
                    businessPartnerID
                    commissionPercentage
                    commissionToBasicRate
                    commissionToPolicyRate
                    discount
                    discountBasic
                    GracePeriod
                    isPrimary
                    planCommissionAmount
                    planCommissionAmountBasic
                    planCommissionRate
                    planCommissionType
                    planCommissionValue
                    planCommissionValueBasic
                    policyCommissionAmount
                    policyCommissionAmountBasic
                    taxOnCommissionAmount
                    taxOnCommissionBasic
                    applicableOn {
                      Id
                    }
                    valueCurrency {
                      Id
                    }
                  }
                  certificatePolicyPlanCovers {
                    clauseEditable
                    clauseID
                    CoverEffectiveFrom
                    CoverEffectiveTo
                    coverPremiumArabic
                    coverPremiumBasic
                    coverPremiumPercentage
                    coverPremiumSecondary
                    coverPremiumType
                    coverPremiumValue
                    coverSumInsured
                    coverSumInsuredArabic
                    coverSumInsuredBasic
                    coverSumInsuredSecondary
                    excessOnClaimAmount
                    excessOnClaimDays
                    excessOnClaimPercentage
                    excessOnClaimType
                    isEditable
                    isMain
                    isMandatory
                    planCoverID
                    PrintingOrder
                    SumInsuredBeforeLoading
                    sumInsuredIsAdditive
                    SumInsuredPerClaim
                  }
                  ChargesPercentage
                  ClaimsPayableBy
                  ClauseText
                  Description
                  ExpiryDate
                  PolicyCost
                  Remarks
                  RowNumber
                  Survey
                  TPAFeesAmount
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function generateCertificate() {
  return gql`
    mutation GenerateCertificate(
      $entityId: String!
      $certificateDetailsOutput: [Production_MarineCertificateDetailsOutputInput!]!
    ) {
      production {
        entities {
          policy {
            production {
              generateMarineCertificate(
                entityId: $entityId
                certificateDetailsOutput: $certificateDetailsOutput
              )
            }
          }
        }
      }
    }
  `;
}

export function calculateMarineBasicRate() {
  return gql`
    mutation calculateBasicRate($currency: String) {
      production {
        actions {
          calculateBasicRate(currency: $currency) {
            BasicRate
          }
        }
      }
    }
  `;
}

import React, { useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { getSections } from './content';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getZonesByCountry, modifyPerson } from './queries';
import { lookupListAsRecordObject } from '../../utils/graph-utils';
import { useParams } from 'react-router-dom';
import SectionDynamicForm from '../../DynamicForm/SectionDynamicForm';
import { IPersonDetailsDrawerProps, IPersonDetailsInfo } from '.';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { EnhancedButtonStatus } from '../../components/EnhancedButton';
import DateService from '../../services/dateService';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';

const PersonDetailsDrawer: React.FC<IPersonDetailsDrawerProps> = ({
  section,
  open,
  data,
  lovs,
  onSuccess,
  onClose,
}) => {
  const params = useParams();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [localLovs, setLocalLovs] = useState(lovs);
  const [getZonesByCountryLazy] = useLazyQuery(getZonesByCountry(), {
    fetchPolicy: 'no-cache',
  });
  const [personAction] = useMutation(modifyPerson());

  const loadZoneLov = async (countryId: string) => {
    const result = await getZonesByCountryLazy({
      variables: { countryID: countryId },
    });
    const zonesData = result.data.Insurance.lookups.zones;
    return lookupListAsRecordObject(zonesData);
  };

  const submitForm = async (data: Record<string, string>) => {
    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const variables = {
        entityId: params.id,
        dateOfBirth: DateService.formatDateBackend(data.dateOfBirth),
        type: section.toUpperCase(),
        personDetailsInputs: {
          fullName: data.fullName || null,
          phoneType: data.phoneType.toUpperCase() || null,
          phoneNumber: data.phoneNumber || null,
        },
        personQuickCreateInputs: {
          isTaxable: data.isTaxable ?? false,
          occupationClass: data.occupationClass || null,
          profession: data.profession || null,
          country: data.country,
          zone: data.zone || null,
          city: data.city || null,
          street: data.street || null,
          building: data.building || null,
          addressDetails: data.addressDetails || null,
          addressDetailsArabic: data.addressDetailsArabic || null,
        },
        personWorkInputs: {
          nationalityOne: data.nationalityOne || null,
          isTaxable: data.isTaxable ?? false,
          occupationClass: data.occupationClass || null,
          profession: data.profession || null,
        },
      };

      const res = await personAction({
        variables,
        errorPolicy: 'all',
      });

      if (!res.errors) {
        toast.success(
          <ToastSuccessMessage>
            {section === 'payer'
              ? 'Payer details successfully updated'
              : 'Owner details successfully created'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          setFormDisabled(false);
          onSuccess && onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        setFormDisabled(false);
        toast.error(
          <ToastErrorMessage>{res.errors[0].message}</ToastErrorMessage>
        );
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      setFormDisabled(false);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const onChange = async (
    fieldName: string,
    value: any,
    values: Record<string, any>
  ) => {
    if (fieldName === 'country') {
      values.zone = '';
      const updatedLovs = cloneDeep(localLovs);
      const zoneData = await loadZoneLov(value);
      updatedLovs.zone = zoneData;
      setLocalLovs(updatedLovs);
    }
  };

  const sections = useMemo(() => {
    return getSections(data, localLovs, section);
  }, [data, localLovs, section]);

  return (
    <GenericDrawer
      title={
        section === 'payer' ? 'Modify Payer Details' : 'Modify Owner Details'
      }
      onClose={onClose}
      isOpen={open}
    >
      <SectionDynamicForm
        sections={sections}
        onSubmit={(values) => submitForm(values)}
        buttonText={'Submit'}
        submitButtonState={submitButtonState}
        isSubmitButtonDisabled={!!submitButtonState}
        disableForm={formDisabled}
        title=""
        onChange={onChange}
      />
    </GenericDrawer>
  );
};

export default PersonDetailsDrawer;

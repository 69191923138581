import PrecisionService from '../../../../../services/precisionService';
import { isEmpty, isValidNumber } from '../../../../../utils/validationUtils';
import { IProposalPageFormState } from './form';
import { IProposalDetailsSummary } from './index2';

export interface IProposalDetailsExpatPageSubmissionModel {
  entityId: string;
  planId: string;
  policyCurrency: string;
  policyEffectiveDate: string;
  policyExpiryDate: string;
  descriptionOfRisk: string;
  geoLocation: string;
  // agencyRepairYear: number;
  policyPersonInput: {
    personId: string;
    personAddressId: string;
    type: string;
    percentage?: number;
    orderNumber?: number;
    ownerIsDifferent?: boolean;
    limitOfCover?: number;
  }[];
  policyPlanCoverInput: {
    coverPremiumPercentage: number;
    coverPremiumType: string;
    coverPremiumValue: number;
    excessOnClaimAmount: number;
    excessOnClaimDays: number;
    excessOnClaimPercentage: number;
    excessOnClaimType: string;
    planCoverID: string;
  }[];
  costAndChargeInput: {
    chargesPercentage: number;
    policyCost: number;
    tpaFeesAmount: number;
    tpaFeesApplicableOn: string;
    tpaFeesPercentage: number;
    tpaFeesType: string;
  };
  bankerInputs: {
    branchName: string;
    isMain: boolean;
    isAgency: boolean;
    numberOfEmployees?: number;
    underwritingYear?: number;
    infidelity: number;
    burglary: number;
    holdUp: number;
    inSafe: number;
    inTransit: number;
    forgedCheckes: number;
    counterFeitCurrencies: number;
    clause6: number;
    falsification: number;
  }[];
  // expatInput: {
  //   medicalClass: string;
  //   continuity: string;
  //   continuityNumber: string;
  //   exclusion: string;
  //   remarks: string;
  // };
  marineInput?: {
    matter: string;
    description: string;
    vesselName: string;
    vesselAge: number;
    departureFrom: string;
    destinationTo: string;
    transportationVia: string;
    survey: string;
    cargoContract: string;
    lCNumber: string;
    lCIssueDate: string;
    bank: string;
    claimsPayableBy: string;
    loading: number;
    remarks: string;
    sumPerShipment: number;
    sumPerShipmentCurrency: string;
    sumPerShipmentRate: number;
    sumPerShipmentPolicyCurrency: number;
  };
  marineHullInput?: {
    vesselName: string;
    connectionPort: string;
    territorialWaters: string;
    usage: string;
    builder: string;
    constructionYear: string;
    registeryPort: string;
    length: string;
    weight: string;
    height: string;
    engineType: string;
    engineNumber: string;
    engineYearBuilt: string;
    engineHorsePower: string;
    engineMaxDesignedSpeed: string;
    engineFuel: string;
    remarks: string;
  };
  policyProperty: {
    RiskDescription: string;
    GeographicalLocation: string;
    Location: string;
    PlanCoverID: string;
    FireRate: number;
    SumInsured: number;
    Premium: number;
    Order: number;
  }[];
}

export function convertProposalPageStateToSubmission(
  summary: IProposalDetailsSummary,
  pageState: IProposalPageFormState
) {
  const { values } = pageState;
  const descriptionOfRiskLines = [
    '5',
    '9',
    '10',
    '13',
    '21',
    '35',
    '11',
    '15',
    '16',
    '25',
    '27',
    '29',
    '30',
    '31',
    '37',
    '38',
    '39',
    '42',
    '46',
  ];

  const policyPersons: IProposalDetailsExpatPageSubmissionModel['policyPersonInput'] =
    [];

  if (values.policyPersons?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: 'PAYER',
      ownerIsDifferent: values.policyPersons.payer.ownerIsDifferent,
    });
  }

  if (values.policyPersons.owner?.ownerId) {
    policyPersons.push({
      personId: values.policyPersons.owner.ownerId,
      personAddressId: values.policyPersons.owner.ownerAddress,
      type: 'OWNER',
    });
  } else if (values.policyPersons?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: 'OWNER',
    });
  }

  values.policyPersons.insured.forEach((person) => {
    if (person.id) {
      policyPersons.push({
        personId: person.id,
        personAddressId: person.address || null,
        type: 'INSURED',
        orderNumber: isValidNumber(person.order)
          ? Number(person.order)
          : undefined,
        limitOfCover:
          person.limitOfCover && isValidNumber(person.limitOfCover)
            ? Number(person.limitOfCover)
            : null,
      });
    }
  });

  values.policyPersons.beneficiaries.forEach((person) => {
    if (person.id) {
      policyPersons.push({
        personId: person.id,
        personAddressId: person.address,
        type: 'BENEFICIARY',
        percentage: isValidNumber(person.percentage)
          ? PrecisionService.divideBy100(person.percentage)
          : 0,
      });
    }
  });

  const data: IProposalDetailsExpatPageSubmissionModel = {
    entityId: summary.Id,
    planId: summary.PlanID.Id,
    policyCurrency: summary.PolicyCurrency.Code,
    policyEffectiveDate: summary.PolicyEffectiveDate,
    policyExpiryDate: summary.PolicyExpiryDate,
    descriptionOfRisk: descriptionOfRiskLines.includes(
      summary?.LineId?.ExternalCode
    )
      ? values?.descriptionOfRiskDetails?.descriptionOfRisk
      : undefined,
    geoLocation: descriptionOfRiskLines.includes(summary?.LineId?.ExternalCode)
      ? values?.descriptionOfRiskDetails?.geoLocation
      : undefined,
    policyPersonInput: policyPersons,
    policyPlanCoverInput: values.covers
      .filter((c) => !isEmpty(c.policyCover))
      .map((cover, i) => ({
        order: i + 1,
        coverPremiumPercentage: isValidNumber(cover.coverPremiumPercentage)
          ? PrecisionService.divideBy100(cover.coverPremiumPercentage)
          : null,
        coverPremiumType: cover.coverPremiumType,
        coverPremiumValue: isValidNumber(cover.coverPremiumValue)
          ? Number(cover.coverPremiumValue)
          : null,
        excessOnClaimAmount: isValidNumber(cover.excessOnClaimAmount)
          ? Number(cover.excessOnClaimAmount)
          : null,
        excessOnClaimDays: isValidNumber(cover.excessOnClaimDays)
          ? Number(cover.excessOnClaimDays)
          : null,
        excessOnClaimPercentage: isValidNumber(cover.excessOnClaimPercentage)
          ? PrecisionService.divideBy100(cover.excessOnClaimPercentage)
          : null,
        excessOnClaimType: cover.excessOnClaimType,
        planCoverID: cover.policyCover,
        isEditable: cover.isEditable,
        isMain: cover.isMain,
        coverSumInsured: isValidNumber(cover.coverSumInsured)
          ? Number(cover.coverSumInsured)
          : 0,
        sumInsuredIsAdditive: cover.sumInsuredIsAdditive,
        isMandatory: cover.isMandatory,
        clauseID: cover.clauseID,
        coverEffectiveFrom: cover.coverEffectiveFrom,
        coverEffectiveTo: cover.coverEffectiveTo,
      })),
    bankerInputs:
      summary?.LineId?.ExternalCode === '12'
        ? values?.bankersDetails?.map((b, index) => ({
            order: index + 1,
            branchName: b.branchName,
            isMain: isEmpty(b.isMain)
              ? null
              : b.isMain?.toLowerCase() === 'yes',
            isAgency: isEmpty(b.isAgency)
              ? null
              : b.isAgency?.toLowerCase() === 'yes',
            numberOfEmployees: isValidNumber(b.numberOfEmployees)
              ? Number(b.numberOfEmployees)
              : null,
            underwritingYear: isValidNumber(b.underwritingYear)
              ? Number(b.underwritingYear)
              : null,
            infidelity: isValidNumber(b.infidelity)
              ? Number(b.infidelity)
              : null,
            burglary: isValidNumber(b.burglary) ? Number(b.burglary) : null,
            holdUp: isValidNumber(b.holdUp) ? Number(b.holdUp) : null,
            inSafe: isValidNumber(b.inSafe) ? Number(b.inSafe) : null,
            inTransit: isValidNumber(b.inTransit) ? Number(b.inTransit) : null,
            forgedCheckes: isValidNumber(b.forgedChecks)
              ? Number(b.forgedChecks)
              : null,
            counterFeitCurrencies: isValidNumber(b.counterfeitCurrencies)
              ? Number(b.counterfeitCurrencies)
              : null,
            clause6: isValidNumber(b.clause6) ? Number(b.clause6) : null,
            falsification: isValidNumber(b.falsification)
              ? Number(b.falsification)
              : null,
          })) || []
        : undefined,
    marineInput:
      summary?.LineId?.ExternalCode === '3'
        ? {
            matter: values.marineDetails.matter,
            description: values.marineDetails.description,
            vesselName: values.marineDetails.vesselName,
            vesselAge: isValidNumber(values.marineDetails.vesselAge)
              ? Number(values.marineDetails.vesselAge)
              : null,
            departureFrom: values.marineDetails.from,
            destinationTo: values.marineDetails.to,
            transportationVia: values.marineDetails.via,
            survey: values.marineDetails.survey,
            cargoContract: values.marineDetails.cargoContract,
            lCNumber: values.marineDetails.lcNumber,
            lCIssueDate: values.marineDetails.lcIssueDate || null,
            bank: values.marineDetails.bank,
            claimsPayableBy: values.marineDetails.claimsPayableBy,
            loading: isValidNumber(values.marineDetails.loading)
              ? PrecisionService.divideBy100(values.marineDetails.loading)
              : null,
            remarks: values.marineDetails.remarks,
            sumPerShipment: isValidNumber(values.marineDetails.sumPerShipment)
              ? Number(values.marineDetails.sumPerShipment)
              : null,
            sumPerShipmentCurrency:
              values?.marineDetails?.sumPerShipmentCurrency,
            sumPerShipmentPolicyCurrency:
              values?.marineDetails?.sumPerShipmentInPolicyCurrency,
            sumPerShipmentRate: values?.marineDetails?.sumPerShipmentRate,
          }
        : undefined,
    marineHullInput:
      summary?.LineId?.ExternalCode === '33' ||
      summary?.LineId?.ExternalCode === '20'
        ? {
            vesselName: values.marineHullDetails.vesselName,
            connectionPort: values.marineHullDetails.connectionPort,
            territorialWaters: values.marineHullDetails.territorialWaters,
            usage: values.marineHullDetails.usage,
            builder: values.marineHullDetails.builder,
            constructionYear: values.marineHullDetails.constructionYear,
            registeryPort: values.marineHullDetails.registeryPort,
            length: values.marineHullDetails.length,
            weight: values.marineHullDetails.weight,
            height: values.marineHullDetails.height,
            engineType: values.marineHullDetails.engineType,
            engineNumber: values.marineHullDetails.engineNumber,
            engineYearBuilt: values.marineHullDetails.engineYearBuilt,
            engineHorsePower: values.marineHullDetails.engineHorsePower,
            engineMaxDesignedSpeed:
              values.marineHullDetails.engineMaxDesignedSpeed,
            engineFuel: values.marineHullDetails.engineFuel,
            remarks: values.marineHullDetails.remarks,
          }
        : undefined,
    costAndChargeInput: {
      chargesPercentage: isValidNumber(values.costs.chargesPercentage)
        ? PrecisionService.divideBy100(values.costs.chargesPercentage)
        : 0,
      policyCost: isValidNumber(values.costs.policyCost)
        ? Number(values.costs.policyCost)
        : 0,
      tpaFeesAmount: isValidNumber(values.costs.tpaFeesAmount)
        ? Number(values.costs.tpaFeesAmount)
        : 0,
      tpaFeesApplicableOn: values.costs.tpaFeesApplicableOn,
      tpaFeesPercentage: isValidNumber(values.costs.tpaFeesPercentage)
        ? PrecisionService.divideBy100(values.costs.tpaFeesPercentage)
        : 0,
      tpaFeesType: values.costs.tpaFeesType,
    },
    policyProperty: ['2', '8', '23', '26'].includes(
      summary?.LineId?.ExternalCode
    )
      ? values?.riskDetails?.flatMap((r, i) =>
          r.planCovers.map((p) => ({
            RiskDescription: r.riskDescription || null,
            GeographicalLocation: r.geoLocation || null,
            Location: r.location || null,
            PlanCoverID: p.planCoverID || null,
            FireRate: isValidNumber(p.riskRate) ? Number(p.riskRate) : null,
            SumInsured: isValidNumber(p.sumInsured)
              ? Number(p.sumInsured)
              : null,
            Premium: isValidNumber(p.premium) ? Number(p.premium) : null,
            Order: i + 1,
          }))
        ) || []
      : undefined,
  };

  return data;
}

import React from 'react';
import DescriptionOfRiskPopUpForm from './DescriptionOfRiskPopUpForm';
import { IPolicyPropertyDescriptionDetails } from '../../modules/production/policy/page';

interface IDescriptionOfRiskPopUpProps {
  currencySymbol: string;
  data: IPolicyPropertyDescriptionDetails[];
  lovs: Record<string, Record<string, string>>;
  onClose: () => void;
  onSuccess: () => void;
}

const DescriptionOfRiskPopUp: React.FC<IDescriptionOfRiskPopUpProps> = ({
  currencySymbol,
  data,
  lovs,
  onClose,
  onSuccess,
}) => {
  return (
    <DescriptionOfRiskPopUpForm
      currencySymbol={currencySymbol}
      data={data}
      lovs={lovs}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
};

export default DescriptionOfRiskPopUp;

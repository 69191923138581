import dayjs, { OpUnitType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Extend dayjs with plugins
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

export default class DateService {
  // --- Formats used throughout the application ---
  public static readonly DATE_PICKER_FORMAT = 'dd/MM/yyyy'; // small letters required by component
  public static readonly FRONTEND_DATE_FORMAT = 'DD/MM/YYYY';
  public static readonly STRICT_FRONTEND_DATE_FORMATs = [
    this.FRONTEND_DATE_FORMAT,
    'D/M/YYYY',
  ];
  private static readonly FRONTEND_DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm A';
  private static readonly BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

  /**
   * Parses a date string in YYYY-MM-DD format and creates a timezone-agnostic date
   */
  private static parseBackendDate(dateStr: string): dayjs.Dayjs {
    if (!dateStr) return null;
    // Parse the date in YYYY-MM-DD format strictly
    return dayjs(dateStr, this.BACKEND_DATE_FORMAT, true);
  }

  /**
   * Normalizes a date by stripping time and timezone information.
   * formats ensures dates that are not in strict format can still be parsed, ex: D/M/YYYY or D/MM/YYYY
   * For date strings in YYYY-MM-DD format, uses strict parsing.
   */
  private static normalizeDate(
    date: string | Date,
    formats?: string[]
  ): dayjs.Dayjs {
    if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return this.parseBackendDate(date);
    }
    return dayjs(date, formats).startOf('day');
  }

  /**
   * Converts a backend date string to a Date object for the DatePicker
   * This is specifically for handling the DatePicker's value prop
   */
  public static parseForDatePicker(dateStr: string): Date | null {
    if (!dateStr) return null;
    const parsed = this.parseBackendDate(dateStr);
    return parsed.isValid() ? parsed.toDate() : null;
  }

  /**
   * Formats the given date (string or Date) into a front-end date string (e.g., "DD/MM/YYYY").
   *
   * @example
   * DateService.formatDateFrontend('2021-12-31') // "31/12/2021"
   *
   * @param {string | Date} date - The date to format.
   * @returns {string | null} The formatted date, or `null` if `date` is falsy.
   */
  public static formatDate(date: string | Date): string {
    if (!date) {
      return null;
    }
    return this.normalizeDate(date).format(this.FRONTEND_DATE_FORMAT);
  }

  /**
   * Formats the given date (string or Date) into a front-end date/time string
   * (e.g., "DD/MM/YYYY hh:mm A").
   *
   * @example
   * DateService.formatDateTime('2025-01-16T11:13:16.000Z'); // "16/01/2025 11:13 AM"
   *
   * @param {string | Date} date - The date to format.
   * @returns {string | null} The formatted date/time, or `null` if `date` is falsy.
   */
  public static formatDateTime(date: string | Date): string {
    if (!date) {
      return null;
    }
    return dayjs(date).format(this.FRONTEND_DATE_TIME_FORMAT);
  }

  /**
   * Formats the given date (string or Date) into a back-end date string (e.g., "YYYY-MM-DD").
   *
   * @example
   * DateService.formatDateBackend('16-01-2025') // '2025-01-16'
   *
   * @param {string | Date} date - The date to format.
   * @returns {string | null} The formatted date, or `null` if `date` is falsy.
   */
  public static formatDateBackend(date: string | Date): string {
    if (!date) {
      return null;
    }
    return this.normalizeDate(date, this.STRICT_FRONTEND_DATE_FORMATs).format(
      this.BACKEND_DATE_FORMAT
    );
  }
  /**
   * Returns the end of the month if the current month is December (month === 11).
   * Otherwise, returns the date one month from now.
   *
   * @returns {Date} The computed date based on the current month.
   */
  public static getDateBasedOnMonth(): Date {
    const now = this.normalizeDate(new Date());

    return now.month() === 11 // 11 is December
      ? now.endOf('month').toDate() // Return the end of December
      : now.add(1, 'month').toDate(); // Return the date one month from now
  }

  /**
   * Calculates the age (in whole years) for a given date of birth,
   * taking into account whether the birthday has occurred yet this year.
   *
   * @param {string | Date} dob - The person's date of birth.
   * @returns {number} The calculated age in whole years.
   */
  public static getAge(dob: string | Date): number {
    const birthDate = this.normalizeDate(dob);
    const today = this.normalizeDate(new Date());

    let age = today.year() - birthDate.year();

    // If today's month/day is still before the birth month/day, subtract 1
    if (
      today.month() < birthDate.month() ||
      (today.month() === birthDate.month() && today.date() < birthDate.date())
    ) {
      age--;
    }
    return age;
  }

  /**
   * Adds the specified number of days to the given date, treating the date as UTC.
   *
   * @param {string | Date} date - The date to which to add days.
   * @param {number} days - The number of days to add.
   * @returns {Date | null} The resulting date after adding the specified days in UTC, or `null` if `date` is falsy.
   */
  public static addDays(date: string | Date, days: number): Date {
    if (!date) {
      return null;
    }

    return this.normalizeDate(date).add(days, 'days').toDate();
  }

  /**
   * Adds the specified number of months to the given date.
   *
   * @param {string | Date} date - The date to which to add months.
   * @param {number} months - The number of months to add.
   * @returns {Date} The resulting date after adding the specified months.
   */
  public static addMonths(date: string | Date, months: number): Date {
    return this.normalizeDate(date).add(months, 'months').toDate();
  }

  /**
   * Subtracts the specified number of months from the given date.
   *
   * @param {string | Date} date - The date from which to subtract months.
   * @param {number} months - The number of months to subtract.
   * @returns {Date} The resulting date after subtracting the specified months.
   */
  public static subtractMonths(date: string | Date, months: number): Date {
    return this.normalizeDate(date).subtract(months, 'months').toDate();
  }

  /**
   * Adds the specified number of years to the given date.
   *
   * @param {string | Date} date - The date to which to add years.
   * @param {number} years - The number of years to add.
   * @returns {Date} The resulting date after adding the specified years.
   */
  public static addYears(date: string | Date, years: number): Date {
    return this.normalizeDate(date).add(years, 'years').toDate();
  }

  /**
   * Checks if one date is strictly before another date.
   *
   * @param {string | Date} date - The date to compare.
   * @param {string | Date} dateToCompare - The date against which `date` is compared.
   * @returns {boolean} `true` if `date` is strictly before `dateToCompare`; otherwise, `false`.
   */
  public static isDateBefore(
    date: string | Date,
    dateToCompare: string | Date
  ): boolean {
    return this.normalizeDate(date).isBefore(this.normalizeDate(dateToCompare));
  }

  /**
   * Checks if the provided date is strictly between the given start and end dates.
   *
   * @param {string | Date} date - The date to check.
   * @param {string | Date} startDate - The start of the range.
   * @param {string | Date} endDate - The end of the range.
   * @returns {boolean} `true` if `date` is between `startDate` and `endDate`; otherwise, `false`.
   */
  public static isDateBetween(
    date: string | Date,
    startDate: string | Date,
    endDate: string | Date
  ): boolean {
    return this.normalizeDate(date).isBetween(
      this.normalizeDate(startDate),
      this.normalizeDate(endDate)
    );
  }

  /**
   * Checks whether two dates are the same based on the specified unit of time.
   *
   * If no unit is provided, the dates are compared by their exact date/time value.
   *
   * @param {string | Date} date - The date to compare.
   * @param {string | Date} compareDate - The date against which to compare.
   * @param {OpUnitType} [unit] - The unit of time for comparison (e.g. 'day', 'month', 'year').
   * @returns {boolean} `true` if both dates are the same based on the unit; otherwise, `false`.
   */
  public static isSameDate(
    date: string | Date,
    compareDate: string | Date,
    unit?: OpUnitType
  ): boolean {
    return dayjs(date).isSame(dayjs(compareDate), unit);
  }

  /**
   * Returns the difference between two dates in milliseconds.
   *
   * A positive value means `date1` is after `date2`;
   * zero means they are the same moment in time;
   * a negative value means `date1` is before `date2`.
   *
   * @param {string | Date} date1 - The first date to compare.
   * @param {string | Date} date2 - The second date to compare.
   * @returns {number} The difference in milliseconds.
   */
  public static diff(date1: string | Date, date2: string | Date): number {
    return dayjs(date1).diff(dayjs(date2));
  }

  /**
   * Determines whether the provided value represents a valid date.
   *
   * @param {string | Date} date - The date value (string or `Date` object) to validate.
   * @returns {boolean} `true` if `date` is valid according to dayjs; otherwise, `false`.
   */
  public static isValidDate(date: string | Date): boolean {
    return dayjs(date).isValid();
  }

  /**
   * Determines whether the provided value represents a valid date format.
   *
   * @param {string | Date} date - The date value (string or `Date` object) to validate.
   * @param {string[]} format - The formats to check.
   * @returns {boolean} `true` if `date` is valid according to dayjs; otherwise, `false`.
   */
  public static isValidDateWithFormat(
    date: string | Date,
    format = this.STRICT_FRONTEND_DATE_FORMATs
  ): boolean {
    return dayjs(date, format).isValid();
  }

  /**
   * Checks if a given date string is valid according to the specified format.
   *
   * @param {string} date - The date string to validate.
   * @param {string} [format=this.FRONTEND_DATE_FORMAT] - The format to check against (defaults to FRONTEND_DATE_FORMAT).
   * @returns {boolean} `true` if the string is valid for the given format; otherwise, `false`.
   */
  public static isValidFormat(
    date: string,
    format = this.FRONTEND_DATE_FORMAT
  ): boolean {
    return dayjs(date, format).isValid();
  }
}

import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from 'tss-react/mui';
import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { EnhancedButtonStatus } from '../../components/EnhancedButton';
import Loader from '../../components/Loader';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { MAIN_ONE_THEME, contentFontFamilyBold } from '../../constants';
import AuthLayout from '../../layouts/AuthBody';
import {
  useAppDispatch,
  useAppSelector,
  useUserDetailsApi,
} from '../../redux/hooks';
import { ILogin } from '../../redux/tenant/types';
import {
  LoadUserSuccessAction,
  LoginSuccessAction,
} from '../../redux/user/actions';
import DataService from '../../services/dataService';
import { supportsWebCrypto } from '../../utils/appUtils';
import { getError } from '../../utils/graph-utils';
import { resolveMainHostUrl } from '../../utils/tenant-utils';
import { REGEX } from '../../utils/validationUtils';
import { getClient, getRedirectUri } from '../../utils/identity-utils';

const useStyles = makeStyles<{ loginMeta: ILogin }>()(
  (theme, { loginMeta }) => ({
    mainContainer: {
      backgroundColor: '#F8F8F8',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100vh',
      alignItems: 'center',
      width: '100%',
    },
    logoFig: {
      margin: '0 auto',
      lineHeight: '0',
    },
    loginBoxContainer: {
      width: '430px',
      margin: '46px auto 0',
      maxWidth: '491px',
      boxSizing: 'border-box',
      backgroundColor: '#fff',
      border: '1px solid rgba(0,0,0,.125)',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      borderRadius: '4px',
    },
    greeting: {
      color: MAIN_ONE_THEME.palette.primary2.main,
      fontSize: '28px',
      lineHeight: '40px',
      textAlign: 'center',
      fontFamily: contentFontFamilyBold,
      letterSpacing: '0px',
      borderBottom: '1px solid #F7F7F7',
      width: '100%',
      margin: '0',
      padding: '28px 0',
      fontWeight: 'normal',
    },
    submitBtn: {
      margin: '24px 0 12px 0',
      borderRadius: '8px !important',
      fontFamily: 'HelveticaNeue-Regular !important',
      fontSize: '13px !important',
      lineHeight: '15px !important',
    },
    formContainer: {
      padding: '24px 30px',
      width: '100%',
    },
    formError: {
      color: theme.palette.error.main,
      height: 'fit-content',
      marginTop: '8px',
      boxSizing: 'border-box',
      padding: '0px 12px 0px 12px',
      fontSize: '11px',
      textAlign: 'center',
    },
    inputLabel: {
      fontFamily: 'HelveticaNeue-Regular !important',
      fontSize: '13px',
      lineHeight: '15px',
    },
    forgotPassword: {
      color: MAIN_ONE_THEME.palette.primary5.main,
      border: 0,
      cursor: 'pointer',
      background: 'none',
      marginTop: '24px',
      textDecoration: 'underline',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
);

type LoginFormProps = {
  onForgotPasswordClicked: () => void;
};

const LoginForm: React.FC<LoginFormProps> = ({ onForgotPasswordClicked }) => {
  const dispatch = useAppDispatch();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);
  const loginMeta = tenant.login;
  const { classes } = useStyles({ loginMeta });
  const params = useParams();
  const navigate = useNavigate();

  const [formDisabled, setFormDisabled] = useState(false);
  const [booted, setBooted] = useState(false);
  const [btnState, setBtnState] = useState<EnhancedButtonStatus>();
  const [formError, setFormError] = useState('');

  const { getUserDetails } = useUserDetailsApi();

  const inputs = useRef<Record<string, DynamicFormInputType>>(
    supportsWebCrypto()
      ? {
          email: {
            name: 'email',
            title: 'Email',
            type: FormInputTypes.text,
            placeholder: 'john@example.com',
            value: '',
            regex: REGEX.email,
            regexError: 'Invalid format',
            required: true,
            disabled: false,
            customStyles: {
              labelStyles: classes.inputLabel,
            },
          },
          password: {
            name: 'password',
            title: 'Password',
            type: FormInputTypes.password,
            placeholder: 'Password',
            value: '',
            required: true,
            disabled: false,
            includePasswordVisibility: true,
            includeCapsLockCheck: false,
            customStyles: {
              labelStyles: classes.inputLabel,
            },
          },
          rememberMe: {
            name: 'rememberMe',
            title: 'Remember Me',
            type: FormInputTypes.checkbox,
            value: false,
            disabled: false,
          },
        }
      : {
          email: {
            name: 'email',
            title: 'Email',
            type: FormInputTypes.text,
            placeholder: 'john@example.com',
            value: '',
            regex: REGEX.email,
            regexError: 'Invalid format',
            required: true,
            disabled: false,
            customStyles: {
              labelStyles: classes.inputLabel,
            },
          },
          password: {
            name: 'password',
            title: 'Password',
            type: FormInputTypes.password,
            placeholder: 'Password',
            value: '',
            required: true,
            disabled: false,
            customStyles: {
              labelStyles: classes.inputLabel,
            },
          },
        }
  );

  const successRedirect = useCallback(() => {
    const { redirect } = params;
    if (redirect) {
      const param = decodeURIComponent(redirect);
      navigate(param.startsWith('/') ? param : `/${param}`, { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  }, [params]);

  useEffect(() => {
    if (user.isAuthenticated) {
      successRedirect();
    } else {
      setBooted(true);
    }
  }, [successRedirect, user.isAuthenticated]);

  const onLogin = useCallback(
    async (
      jwtToken: string,
      id_token: string,
      rememberMe: boolean,
      refresh_token?: string,
      expires_in?: string
    ) => {
      dispatch(
        LoginSuccessAction(
          jwtToken,
          id_token,
          rememberMe && !isEmpty(expires_in)
            ? {
                refreshToken: refresh_token as string,
                expiresIn: expires_in as string,
              }
            : undefined
        )
      );
      const userDetails = await getUserDetails();
      dispatch(LoadUserSuccessAction(userDetails));
    },
    []
  );

  const submit = (values: Record<string, any>) => {
    setBtnState('loading');
    setFormDisabled(true);

    const data = {
      email: values.email,
      password: values.password,
      returnUrl: `/connect/authorize?redirect_uri=${resolveMainHostUrl()}/connect/token&client_id=graphql&response_type=code&scope=${
        values.rememberMe ? 'offline_access ' : ''
      }openid`,
      rememberLogin: true,
    };
    DataService.post('/api/accounts/login', data, {
      credentials: 'include',
    })
      .then(async (response) => {
        if (response.ok) {
          const result = (await response.json()) as {
            validReturnUrl: string;
          };

          return DataService.get(result.validReturnUrl, {
            credentials: 'include',
          })
            .then(async (userResponse) => {
              const url = new URL(userResponse.url);
              const searchParams = url.searchParams;
              const code = searchParams.get('code') || '';
              const { client_id, client_secret } = getClient();
              const redirect_uri = getRedirectUri();
              DataService.fetch(`${DataService.getBaseUrl}/connect/token`, {
                method: 'POST',
                body: new URLSearchParams({
                  client_id,
                  client_secret,
                  grant_type: 'authorization_code',
                  code,
                  redirect_uri,
                }),
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
              }).then(async (tokenResponse) => {
                if (tokenResponse.ok) {
                  const result = (await tokenResponse.json()) as {
                    id_token: string;
                    access_token: string;
                    refresh_token: string;
                    expires_in: number;
                    token_type: string;
                    scope: string;
                  };
                  setBtnState('success');

                  onLogin(
                    result.access_token,
                    result.id_token,
                    values.rememberMe,
                    result.refresh_token,
                    String(result.expires_in)
                  );
                } else {
                  setFormError('Email or password is incorrect');
                  setBtnState(undefined);
                }
              });
            })
            .catch((err) => {
              toast.error(
                <ToastErrorMessage>{getError(err)}</ToastErrorMessage>
              );
            });
        } else {
          setFormError('Email or password is incorrect');
          setBtnState(undefined);
        }
      })
      .catch((error: Error) => {
        setFormError('Email or password is incorrect');
        setBtnState(undefined);
      })
      .finally(() => setFormDisabled(false));
  };

  return (
    <AuthLayout>
      <figure className={classes.logoFig}>
        <img
          height={117}
          width={125}
          src={tenant.cdnUrl + loginMeta.logoUrl}
          alt={'UFA logo'}
        />
      </figure>
      {booted ? (
        <div className={classes.loginBoxContainer}>
          <h1 className={classes.greeting}>Welcome!</h1>
          <div className={classes.formContainer}>
            <DynamicForm
              inputs={inputs.current}
              buttonText="Sign In"
              onSubmit={async (values) => {
                submit(values);
              }}
              disableForm={formDisabled}
              submitButtonState={btnState}
              customStyles={{
                submitButtonStyles: classes.submitBtn,
              }}
            ></DynamicForm>
            {!isEmpty(formError) && (
              <p className={classes.formError}>{formError}</p>
            )}
            <button
              type="button"
              onClick={onForgotPasswordClicked}
              className={classes.forgotPassword}
            >
              Forgot Password
            </button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </AuthLayout>
  );
};

export default LoginForm;

import { ITreatyDetails } from '../..';
import DateService from '../../../../../../services/dateService';
import { formatEnum } from '../../../../../../utils/formatting-utils';

export const getMutationVariables = (
  currentTreatyId: string,
  values: ITreatyDetails
) => {
  const result: Record<string, any> = {
    CurrentTreatyID: currentTreatyId,
    TreatyName: values.treatyName,
    TreatyType: formatEnum(values.treatyType),
    Currency: values.currency,
    UnderwritingLimit: Number(values.underwritingLimit),
    ClaimLimit: Number(values.claimAdviseLimit),
    SubstandardLimit: Number(values.substandardLimit),
    EffecitiveDate: DateService.formatDateBackend(values.effectiveDate),
    ExpiryDate: DateService.formatDateBackend(values.expiryDate),
    LayerDetails: [],
  };

  Object.keys(values.layers).forEach((key: string, layerIndex: number) => {
    if (
      values.treatyType.toLowerCase() === 'non_proportional' &&
      layerIndex > 0
    ) {
      return;
    }

    const layer = values.layers[key];

    layer.categories.forEach((category) => {
      result.LayerDetails.push({
        LayerName: key,
        LayerOrder: layerIndex,
        LayerType: layer.treatySubType,
        LayerPaymentAmount: Number(layer.paymentAmount),
        LayerPaymentDate: DateService.formatDateBackend(layer.paymentDate),
        LayerQuotaSharePercentage: Number(layer.retentionShare),
        LayerTotalTreatyPremium: Number(layer.totalTreatyPremium),
        LayerCategoryTotalExpectedPremium: Number(layer.totalExpectedPremium),
        LayerCategoryPremiumRate: Number(layer.premiumRate),
        LayerCategory: category.category,
        LayerCategoryMinimumLayerLimit: Number(category.minimumLayerLimit),
        LayerCategoryMaximumLayerLimit: Number(category.maximumLayerLimit),
        LayerCategoryCommissionRate: Number(category.commissionRate),
        LayerCategoryRetentionLimit: Number(category.retentionLimit),
        LayerCategoryPriorityLimit: Number(category.priorityLimit),
        LayerCategoryLiabilityLimit: Number(category.liabilityLimit),
        LayerCategoryAggregateLimit: Number(category.aggregateLimit),
      });
    });
  });

  return result;
};

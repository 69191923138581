/* eslint-disable prettier/prettier */
import { IAbstractRecord } from '../../../../../models';
import PrecisionService from '../../../../../services/precisionService';
import { isValidNumber } from '../../../../../utils/validationUtils';
import { IProposalPageFormState } from './form';
import { IProposalDetailsSummary } from './index2';

export interface IProposalDetailsMedicalPageSubmissionModel {
  entityId: string;
  planId: string;
  policyCurrency: string;
  policyEffectiveDate: string;
  policyExpiryDate: string;
  policyPersonInput: {
    personId: string;
    personAddressId?: string;
    type: string;
    percentage?: number;
    orderNumber?: number;
    ownerIsDifferent?: boolean;
    relation?: string;
    isPrincipal?: boolean;
    cardNumber?: string;
    exclusions?: string;
    remarks?: string;
    grLimitDays?: number;
    continuity?: string;
    tpaReference1?: string;
    tpaReference2?: string;
    limitOfCover?: number;
  }[];
  costAndChargeInput: {
    chargesPercentage: number;
    policyCost: number;
    tpaFeesAmount: number;
    tpaFeesApplicableOn: string;
    tpaFeesPercentage: number;
    tpaFeesType: string;
  };
  medicalDetailsInput: {
    class: string;
    nssf: string;
    level: string;
    coverIDs: string[];
  };
  medicalPolicyPersonsInput?: IAbstractRecord;
  nbOfAdherent: number;
}

export function convertProposalPageStateToSubmission(
  summary: IProposalDetailsSummary,
  pageState: IProposalPageFormState
) {
  const { values } = pageState;

  const policyPersons: IProposalDetailsMedicalPageSubmissionModel['policyPersonInput'] =
    [];

  if (values.policyPersons?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: 'PAYER',
      ownerIsDifferent: values.policyPersons.payer.ownerIsDifferent,
    });
  }
  if (values.policyPersons.owner?.ownerId) {
    policyPersons.push({
      personId: values.policyPersons.owner.ownerId,
      personAddressId: values.policyPersons.owner.ownerAddress,
      type: 'OWNER',
    });
  } else if (values.policyPersons?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: 'OWNER',
    });
  }

  if (summary.LineId.ExternalCode === '19') {
    values.policyPersons.insured.forEach((person) => {
      if (person.id) {
        policyPersons.push({
          personId: person.id,
          personAddressId: person.address || undefined,
          type: 'INSURED',
          orderNumber: isValidNumber(person.order)
            ? Number(person.order)
            : undefined,
          relation: person.isPrincipal
            ? 'PRINCIPAL'
            : person.relation || undefined,
          isPrincipal: person.isPrincipal || false,
          cardNumber: person.cardNumber || undefined,
          exclusions: person.exclusions || undefined,
          remarks: person.remarks || undefined,
          grLimitDays: isValidNumber(summary.PlanID.GRLimitDays)
            ? Number(summary.PlanID.GRLimitDays)
            : undefined,
          continuity: person.continuity || undefined,
          tpaReference1: person.tpaReference1 || undefined,
          tpaReference2: person.tpaReference2 || undefined,
        });
      }
    });
  }

  values.policyPersons.beneficiaries.forEach((person) => {
    if (person.id) {
      policyPersons.push({
        personId: person.id,
        personAddressId: person.address || undefined,
        type: 'BENEFICIARY',
        percentage: isValidNumber(person.percentage)
          ? PrecisionService.divideBy100(person.percentage)
          : 0,
      });
    }
  });

  const data: IProposalDetailsMedicalPageSubmissionModel = {
    entityId: summary.Id,
    planId: summary.PlanID.Id,
    policyCurrency: summary.PolicyCurrency.Code,
    policyEffectiveDate: summary.PolicyEffectiveDate,
    policyExpiryDate: summary.PolicyExpiryDate,
    // agencyRepairYear: isValidNumber(values.motorDetails.agencyRepairYear)
    //     ? Number(values.motorDetails.agencyRepairYear)
    //     : null,
    policyPersonInput: policyPersons,
    costAndChargeInput: {
      chargesPercentage: isValidNumber(values.costs.chargesPercentage)
        ? PrecisionService.divideBy100(values.costs.chargesPercentage)
        : 0,
      policyCost: isValidNumber(values.costs.policyCost)
        ? Number(values.costs.policyCost)
        : 0,
      tpaFeesAmount: isValidNumber(values.costs.tpaFeesAmount)
        ? Number(values.costs.tpaFeesAmount)
        : 0,
      tpaFeesApplicableOn: values.costs.tpaFeesApplicableOn,
      tpaFeesPercentage: isValidNumber(values.costs.tpaFeesPercentage)
        ? PrecisionService.divideBy100(values.costs.tpaFeesPercentage)
        : 0,
      tpaFeesType: values.costs.tpaFeesType,
    },
    medicalDetailsInput: {
      class: values.medicalDetails.class || undefined,
      nssf: values.medicalDetails.cnss || undefined,
      level: values.medicalDetails.level || undefined,
      coverIDs:
        summary.LineId.ExternalCode === '19' &&
        values.medicalDetails.policyCover?.length > 0
          ? values.medicalDetails.policyCover
          : undefined,
    },
    medicalPolicyPersonsInput: values.groupMedicalDetails,
    nbOfAdherent: values?.policyPersons?.insured?.length || 0,
  };

  return data;
}

import React, { useEffect, useCallback, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import EnhancedTableHead from './EnhancedTableHead';
import {
  TableSortOrder,
  rowSort,
  getSorting,
  rowWithoutSort,
} from '../../utils/table-utils';

import EnhancedTableBody from './EnhancedTableBody';
import { IListingData } from '../../models/listing';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
  EnhancedTableFilterType,
} from '.';
import { ITableRowSettings } from '../widgets/listing-widget';
import { isEmpty } from '../../utils/validationUtils';
import { IAbstractRecord } from '../../models';
import {
  FilterInputType,
  FilterInputTypes,
  IEnhancedMenuItem,
  IFilterSelectProps,
} from '../index';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import Separator from '../common/Separator';
import clsx from 'clsx';
import ActionBar from '../ActionBar';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME, mainFontFamilyMedium } from '../../constants';
import { useAppSelector } from '../../redux/hooks';
import Loader from '../Loader';
import DateService from '../../services/dateService';

export interface IEnhancedTableProps {
  name: string;
  entityName?: string;
  headers: Record<string, EnhancedTableHeader>;
  rowSettings?: ITableRowSettings;
  data: IListingData<any>;
  title?: string;
  inline?: boolean;
  inlineTitle?: string;
  dropdownActionsNames?: string[];
  defaultOrderByColumn?: string;
  filterableNames?: string[];
  orderByAscendingByDefault?: boolean;
  secondOrderByColumn?: string;
  secondOrderByAscendingByDefault?: boolean;
  disableSelection?: boolean;
  context?: IAbstractRecord;
  actions?: IEnhancedTableMenuItem[];
  tableFilters?: Record<string, FilterInputType>;
  handlePageChange?: (page: number) => void;
  handleSearchChange?: (search: string) => void;
  handleSort?: (orderBy: string, order: string) => void;
  handleRowsPerPageChange?: (page: number) => void; // used with usePagination
  currentPage?: number;
  // onChangeFilteredItems?: (ids: ListingDataItemType<any>) => void;
  hideToolbar?: boolean;
  usePagination?: boolean;
  loader?: boolean;
  showTablePagination?: boolean;
  isTitlePascalCase?: boolean;
  onSelectionChange?: (newSelectedItems: any[]) => void;
  showCellFullData?: boolean;
  entityIdColumnName?: string;
  secondaryActions?: boolean;
  specificDesign?: boolean;
  lineNameController?: JSX.Element;
  isToolbarAction?: boolean;
  ordering?: TableSortOrder;
  orderingBy?: string;
  searchValue?: string;
  tableClasses?: {
    paperClass?: string;
  };
  handleCellValueChanged?: (
    rowIndex: number,
    columnId: string,
    newValue: any
  ) => void;
  handleBlur?: (rowIndex: number, columnId: string, newValue: any) => void;
  showInlineFilter?: boolean;
  isServerSide?: boolean;
}

interface ISelectionObject {
  index: string;
  id: string;
  sublineID?: string;
  sublineStatus?: string;
  lineStatus?: string;
  lineIdStatus?: string;
  businessPartnerStatus?: string;
  businessPartnerPlanStatus?: string;
  businessPartnerPlan_BusinessPartnerLine_Status?: string;
  businessPartnerPlan_Line_Status?: string;
  businessPartnerPlan_Plan_Status?: string;

  businessPartnerCommissionType?: string;
  businessPartnerCommissionValue?: string;
  businessPartnerCommissionValueCurrency?: string;
  businessPartnerCommissionRate?: string;
  businessPartnerCommissionApplicableOn?: string;
  businessPartnerActiveFrom?: Date;
  businessPartnerActiveTo?: Date;

  salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType?: string;
  salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue?: string;
  salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency?: string;
  salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate?: string;
  salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn?: string;

  salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable?: string;
  salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionType?: string;
  salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValue?: string;
  salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValueCurrency?: string;
  salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionRate?: string;
  salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicableOn?: string;

  salesforceManagement_AssignedBPPlan_Id?: string;
  salesforceManagement_AssignedBPPlan_Status?: string;
  salesforceManagement_AssignedBPPlan_BusinessPartnerLineID_Status?: string;
  salesforceManagement_AssignedBPPlan_BusinessPartnerPlanID_Status?: string;
}

const useStyles = makeStyles<{ isTitlePascalCase: boolean }>()(
  (theme, { isTitlePascalCase }) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      boxShadow: '0px 0px 6px #C7C7C7',
      margin: '0 auto',
    },
    toolbarPaper: {
      margin: '0 0 1em',
    },
    separator: {
      height: 2,
      margin: 0,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    header: {
      margin: '0 0 1em',
      display: 'grid',
      gridTemplateAreas: `'title actions'`,
      gridGap: '1em',
    },
    title: {
      display: 'block',
      textAlign: 'left',
      fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
      fontFamily: mainFontFamilyMedium,
      letterSpacing: 0,
      color: MAIN_ONE_THEME.palette.primary1.main,
      fontWeight: 'normal',
      margin: '0 0 17.5px 0',
      gridArea: 'title',
      alignSelf: 'center',
      textTransform: isTitlePascalCase ? 'none' : 'uppercase',
      width: '450px',
    },
    actionsContainer: {
      margin: '0 3px 0 auto',
    },
    action: {
      '& span': {
        margin: '0.5em auto 0',
      },
    },
    button: {
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px!important`,
    },
    heightAndCenter: {
      height: '500px',
      justifyContent: 'center',
    },
  })
);

const EnhancedTable: React.FC<IEnhancedTableProps> = ({
  name,
  headers,
  rowSettings,
  data,
  // actions,
  title,
  inlineTitle,
  defaultOrderByColumn,
  orderByAscendingByDefault,
  secondOrderByColumn,
  secondOrderByAscendingByDefault,
  // filterableNames,
  entityName = '',
  disableSelection,
  tableFilters,
  actions = [],
  handlePageChange = () => undefined,
  handleSearchChange = () => undefined,
  handleSort = () => undefined,
  handleRowsPerPageChange = undefined,
  currentPage = 0,
  inline = false,
  // onChangeFilteredItems = () => undefined,
  hideToolbar = false,
  usePagination = false,
  loader,
  showTablePagination = true,
  isTitlePascalCase = false,
  onSelectionChange,
  showCellFullData = false,
  entityIdColumnName = '',
  secondaryActions = false,
  specificDesign = false,
  lineNameController,
  isToolbarAction = true,
  ordering,
  orderingBy,
  searchValue,
  tableClasses,
  handleCellValueChanged = () => undefined,
  handleBlur = () => undefined,
  showInlineFilter = true,
  isServerSide = false,
}) => {
  const { classes } = useStyles({ isTitlePascalCase });
  const cdnUrl = useAppSelector((state) => state.tenant.cdnUrl);

  let filterDelay: NodeJS.Timeout;
  const [order, setOrder] = useState<TableSortOrder>();
  const [orderBy, setOrderBy] = useState<string>();
  const [secondOrder, setSecondOrder] = useState<TableSortOrder>();
  const [page, setPage] = useState(
    data.pageNumber > 0 ? data.pageNumber - 1 : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(data.pageSize);
  const [selected, setSelected] = useState<string[]>([]);
  const [filtered, setFiltered] = useState(data.pagedItems);
  const [filterValue, setFilterValue] = useState('');
  const currentName = useRef<string>();

  const [dataVersion, setDataVersion] = useState(0);
  const prevDataVersionRef = useRef(dataVersion);

  let detailedSelection: ISelectionObject[];
  const [filters, setFilters] =
    useState<Record<string, EnhancedTableFilterType>>();
  const getFilterValues = useCallback(
    (filter: IFilterSelectProps) => {
      const options: string[] = [];
      if (data.pagedItems.length === 0) {
        return [];
      }
      Object.values(data.pagedItems).forEach((item) => {
        if (filter.splitCharacter) {
          if (!isEmpty(item[filter.name])) {
            const subItems = (item[filter.name] as string).split(
              filter.splitCharacter
            );
            subItems.forEach((subItem) => {
              if (!options.includes(subItem)) {
                options.push(subItem);
              }
            });
          }
        } else {
          if (
            !isEmpty(item[filter.name]) &&
            !options.includes(item[filter.name])
          ) {
            options.push(item[filter.name]);
          }
        }
      });
      return options;
    },
    [data.pagedItems]
  );

  useEffect(() => {
    const newFilters: Record<string, EnhancedTableFilterType> = {};
    if (tableFilters) {
      let options: any = null;
      Object.values(tableFilters).forEach((filter) => {
        if (filter.type === FilterInputTypes.select) {
          options = getFilterValues(filter);
          if (
            headers &&
            headers[filter.name] &&
            headers[filter.name].type === EnhancedTableHeaderType.Date
          ) {
            options = options.map((option: any) =>
              DateService.formatDate(option)
            );
          }
          if (options?.length > 0) {
            newFilters[filter.name] = {
              type: 'select',
              name: filter.name,
              title: headers[filter.name].title || filter.name,
              options,
              selectedValues: filter.selectedValues
                ? filter.selectedValues
                : [],
              splitCharacter: filter.splitCharacter,
            };
          }
        } else if (filter.type === FilterInputTypes.date) {
          newFilters[filter.name] = {
            type: 'daterange',
            name: filter.name,
            title: headers[filter.name].title || filter.name,
            maxDate: filter.max ? filter.max : null,
            minDate: filter.min ? filter.min : null,
            value: filter.value ? filter.value : null,
          };
        }
      });
      setFilters(newFilters);
    } else {
      setFilters(undefined);
    }
  }, [data.pagedItems, tableFilters, getFilterValues, headers]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    filterDelay = setTimeout(() => {
      if (headers) {
        const outKeys: string[] = Object.keys(data.pagedItems).filter(
          (itemKey) => {
            const curItem = data.pagedItems[itemKey];
            return (
              isServerSide ||
              (Object.values(headers).some(
                (header) =>
                  curItem[header.name] &&
                  typeof curItem[header.name] === 'string' &&
                  curItem[header.name]
                    .toLowerCase()
                    .includes(filterValue.toLowerCase())
              ) &&
                (!filters ||
                  Object.values(filters).every((filter) => {
                    if (filter.type === 'select') {
                      if (
                        filter.options?.length === 0 ||
                        filter.selectedValues.length === 0
                      ) {
                        return true;
                      }
                      if (
                        headers[filter.name].type ===
                        EnhancedTableHeaderType.Date
                      ) {
                        return filter.selectedValues.includes(
                          DateService.formatDate(curItem[filter.name])
                        );
                      }
                      if (curItem[filter.name] && !!filter.splitCharacter) {
                        return curItem[filter.name]
                          .split(filter.splitCharacter)
                          .some((v: string) =>
                            filter.selectedValues.includes(v)
                          );
                      }

                      return filter.selectedValues.includes(
                        curItem[filter.name]
                      );
                    } else if (filter.type === 'daterange') {
                      if (filter.value) {
                        if (DateService.isValidDate(curItem[filter.name])) {
                          return DateService.isDateBetween(
                            curItem[filter.name],
                            filter.value[0],
                            filter.value[1]
                          );
                        }
                        return false;
                      }
                      return true;
                    }
                    return true;
                  })))
            );
          }
        );
        const output: typeof data.pagedItems = {};
        outKeys.forEach((key) => {
          output[key] = data.pagedItems[key];
        });
        // setSelected(selected.filter((key) => outKeys.includes(key)));
        setFiltered(output);
        // onChangeFilteredItems(output);
        setPage(currentPage);
      } else {
        setFiltered(data.pagedItems);
        // onChangeFilteredItems(data.pagedItems);
      }
    }, 0);
    return () => {
      clearTimeout(filterDelay);
    };
  }, [data.pagedItems, filterValue, headers, filters, isServerSide]);

  useEffect(() => {
    if (currentName.current !== name && defaultOrderByColumn) {
      currentName.current = name;
      setOrder(orderByAscendingByDefault ? 'asc' : 'desc');
      setOrderBy(defaultOrderByColumn);
      if (secondOrderByColumn) {
        setSecondOrder(secondOrderByAscendingByDefault ? 'asc' : 'desc');
      }
    }
  }, [
    defaultOrderByColumn,
    name,
    orderByAscendingByDefault,
    secondOrderByAscendingByDefault,
    secondOrderByColumn,
  ]);

  useEffect(() => {
    setDataVersion((prevVersion) => prevVersion + 1);
  }, [data.pagedItems]);
  useEffect(() => {
    if (dataVersion !== prevDataVersionRef.current) {
      setSelected([]);
      if (onSelectionChange) {
        onSelectionChange([]);
      }
      prevDataVersionRef.current = dataVersion;
    }
  }, [dataVersion, onSelectionChange]);

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: string
  ) {
    const isDesc = orderBy === property && order === 'desc';
    handleSort(property, isDesc ? 'asc' : 'desc');
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function setDetailedSelection(
    newSelected: string[],
    items: Record<string, any>
  ) {
    const itemsArray = Object.values(items);
    detailedSelection = newSelected
      .map((id) => {
        let item = items[id];
        if (!item) {
          item = itemsArray.find(
            (item, index) => index.toString() === id?.toString()
          );
        }
        if (item) {
          const selectionObject: ISelectionObject = {
            index: id,
            id: item[entityIdColumnName],
          };
          if (entityIdColumnName === 'planConfigManagement_Plan_Id') {
            selectionObject.sublineID =
              item['planConfigManagement_Plan_SublineId'];
            selectionObject.sublineStatus =
              item['planConfigManagement_Plan_SublineStatus'];
          }
          if (
            entityIdColumnName === 'salesforceManagement_BusinessPartnerLine_Id'
          ) {
            selectionObject.lineStatus =
              item['salesforceManagement_BusinessPartnerLine_Status'];
            selectionObject.lineIdStatus = item['line_Status'];
          }
          if (
            entityIdColumnName === 'salesforceManagement_BusinessPartnerPlan_Id'
          ) {
            selectionObject.businessPartnerStatus =
              item['salesforceManagement_BusinessPartner_Status'];
            selectionObject.businessPartnerPlanStatus =
              item['salesforceManagement_BusinessPartnerPlan_Status'];
            selectionObject.businessPartnerPlan_BusinessPartnerLine_Status =
              item[
                'salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_Status'
              ];
            selectionObject.businessPartnerPlan_Line_Status =
              item['line_Status'];
            selectionObject.businessPartnerPlan_Plan_Status =
              item['plan_PlanStatus'];

            selectionObject.businessPartnerCommissionType =
              item[
                'salesforceManagement_BusinessPartnerPlan_PlanCommissionType'
              ];
            selectionObject.businessPartnerCommissionValue =
              item[
                'salesforceManagement_BusinessPartnerPlan_PlanCommissionValue_Only'
              ];
            selectionObject.businessPartnerCommissionValueCurrency =
              item[
                'salesforceManagement_BusinessPartnerPlan_PlanCommissionValueCurrency_Only'
              ];
            selectionObject.businessPartnerCommissionRate =
              item[
                'salesforceManagement_BusinessPartnerPlan_PlanCommissionRate_Only'
              ];
            selectionObject.businessPartnerCommissionApplicableOn =
              item['salesforceManagement_BusinessPartnerPlan_ApplicableOnCode'];
            selectionObject.businessPartnerActiveFrom =
              item['salesforceManagement_BusinessPartnerPlan_ActiveFrom'];
            selectionObject.businessPartnerActiveTo =
              item['salesforceManagement_BusinessPartnerPlan_ActiveTo'];

            selectionObject.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType =
              item[
                'salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType'
              ];
            selectionObject.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue =
              item[
                'salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue'
              ];
            selectionObject.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency =
              item[
                'salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency'
              ];
            selectionObject.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate =
              item[
                'salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate'
              ];
            selectionObject.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn =
              item[
                'salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn'
              ];

            selectionObject.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable =
              item[
                'salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable'
              ];
            selectionObject.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionType =
              item[
                'salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionType'
              ];
            selectionObject.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValue =
              item[
                'salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValue'
              ];
            selectionObject.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValueCurrency =
              item[
                'salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValueCurrency'
              ];
            selectionObject.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionRate =
              item[
                'salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionRate'
              ];
            selectionObject.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicableOn =
              item[
                'salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicableOn'
              ];
          }
          if (entityIdColumnName === 'salesforceManagement_AssignedBPPlan_Id') {
            selectionObject.salesforceManagement_AssignedBPPlan_Id =
              item['salesforceManagement_AssignedBPPlan_Id'];
            selectionObject.salesforceManagement_AssignedBPPlan_Status =
              item['salesforceManagement_AssignedBPPlan_Status'];
            selectionObject.salesforceManagement_AssignedBPPlan_BusinessPartnerLineID_Status =
              item[
                'salesforceManagement_AssignedBPPlan_BusinessPartnerLineID_Status'
              ];
            selectionObject.salesforceManagement_AssignedBPPlan_BusinessPartnerPlanID_Status =
              item[
                'salesforceManagement_AssignedBPPlan_BusinessPartnerPlanID_Status'
              ];
          }
          return selectionObject;
        }
        return null;
      })
      .filter((item) => item !== null);

    if (onSelectionChange) {
      onSelectionChange(detailedSelection);
    }
  }

  function handleSelectAllClick(rowName: string, checked: boolean) {
    let newSelected: string[] = [];

    if (checked) {
      newSelected = Object.keys(filtered);
    }
    setSelected(newSelected);

    setDetailedSelection(newSelected, filtered);
  }

  function handleClick(rowId: any, idKey: string) {
    const selectedIndex = selected.indexOf(rowId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, rowId];
    } else {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);

    setDetailedSelection(newSelected, data.pagedItems);
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
    handlePageChange(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    const v = +event.target.value;
    setRowsPerPage(v);
    setPage(0);
    if (handleRowsPerPageChange != undefined) {
      handleRowsPerPageChange(v);
    }
  }

  const handleChange = (search: string) => {
    setFilterValue(search);
    handleSearchChange(search);
  };

  const isSelected = (key: string) => selected.indexOf(key) !== -1;

  const handleActionClick = (
    action: IEnhancedTableMenuItem,
    returnAll = false
  ) => {
    if (returnAll) {
      const tempArray: any[] = [];
      selected.forEach((key) => tempArray.push(data.pagedItems[key]));
      action.onClick(tempArray);
    } else {
      action.onClick(selected);
    }
  };

  const getTableActions = () => {
    const items: IEnhancedMenuItem[] = [];

    const selectedItems: Record<string, any>[] = [];
    if (selected.length > 0) {
      selected.forEach((s) => {
        selectedItems.push(data.pagedItems[s]);
      });
    }

    actions
      .filter((action) => !action.isEntity)
      .forEach((action) => {
        if (action.iconUrl) {
          items.push({
            icon: action.iconUrl,
            hidden: action.hidden,
            title: action.title,
            isSecondary: action.isSecondary,
            onClick: () => handleActionClick(action, action.returnAllData),
            disabled:
              action.disabled ||
              (action.conditionalDisable &&
                action.conditionalDisable(selected, selectedItems)),
          });
        } else {
          items.push({
            icon: action.iconUrl,
            title: action.title,
            hidden: action.hidden,
            isSecondary: action.isSecondary,
            onClick: () => handleActionClick(action, action.returnAllData),
            disabled:
              action.disabled ||
              (action.conditionalDisable &&
                action.conditionalDisable(selected, selectedItems)),
          });
        }
      });

    return items;
  };

  const getEntityActions = (): IEnhancedMenuItem[] => {
    const tableMenuItems: IEnhancedMenuItem[] = [];
    const tempArray: any[] = [];
    selected.forEach((key) => tempArray.push(data.pagedItems[key]));
    actions
      .filter((action) => action.isEntity)
      .forEach((action) => {
        tableMenuItems.push({
          title: action.title,
          onClick: () => handleActionClick(action, action.returnAllData),
          disabled:
            selected.length === 0 ||
            (!action.isBulk && selected.length > 1) ||
            action.disabled ||
            (action.conditionalDisable && action.conditionalDisable(tempArray)),
        });
      });

    return tableMenuItems;
  };

  const handleFilterChange = (field: string, value: any[]) => {
    if (filters && filters[field]) {
      const row: Record<string, EnhancedTableFilterType> = {};
      const fieldRow: EnhancedTableFilterType = _.cloneDeep(filters[field]);
      if (fieldRow.type === 'select') {
        if (value) {
          fieldRow.selectedValues = value;
        } else {
          fieldRow.selectedValues = fieldRow.selectedValues.filter(
            (option) => option !== field
          );
        }
      } else if (fieldRow.type === 'daterange') {
        fieldRow.value = value;
      }
      row[field] = fieldRow;
      setFilters({ ...filters, ...row });
    }
  };

  const handleFilterClear = () => {
    if (filters) {
      const newFilters: Record<string, EnhancedTableFilterType> =
        _.cloneDeep(filters);
      Object.values(newFilters).forEach((filter) => {
        if (filter.type === 'select') {
          filter.selectedValues = [];
        } else if (filter.type === 'daterange') {
          filter.value = null;
        }
      });
      setFilters(newFilters);
    }
  };

  const renderToolbar = () => (
    <EnhancedTableToolbar
      filters={filters}
      secondaryActions={getEntityActions()}
      onFilterClick={handleFilterChange}
      handleChange={handleChange}
      inline={inline}
      title={inlineTitle}
      onFilterClear={handleFilterClear}
      selected={[]}
      cdnUrl={cdnUrl}
      actions={getTableActions()}
      areSecondaryActions={secondaryActions}
      specificDesign={specificDesign}
      fieldNextToTitle={lineNameController}
      isToolbarAction={isToolbarAction}
      searchValue={searchValue}
      showInlineFilter={showInlineFilter}
    />
  );

  const renderActions = () => {
    const output: IEnhancedMenuItem[] = [];
    getTableActions().forEach((action) =>
      output.push({
        title: action.title,
        onClick: action.onClick,
        disabled: action.disabled,
        icon: action.icon,
        hidden: action.hidden,
        isSecondary: action.isSecondary,
      })
    );
    return <ActionBar items={output} classes={{ button: classes.button }} />;
  };

  const emptyRows = !usePagination
    ? rowsPerPage -
      Math.min(rowsPerPage, Object.keys(filtered).length - page * rowsPerPage)
    : 0;

  const getRowCount = () => {
    if (!usePagination) {
      return Object.keys(filtered).length;
    } else {
      return data.totalCount || 0;
    }
  };

  const renderTable = () => {
    return (
      <div className={classes.root}>
        {/* <div className={classes.tableHeader}>
        {/* {inlineTitle && (
        <span className={classes.tableTitle}>{inlineTitle}</span>
      )} */}
        {!hideToolbar && !inline && (
          <Paper
            className={clsx(
              classes.paper,
              classes.toolbarPaper,
              tableClasses?.paperClass
            )}
          >
            {renderToolbar()}
          </Paper>
        )}
        <Paper className={clsx(classes.paper, tableClasses?.paperClass)}>
          {!loader ? (
            <>
              {!hideToolbar && inline && (
                <>
                  {renderToolbar()}
                  <Separator style={{ height: 2, margin: 0 }} />
                </>
              )}

              <div className={classes.tableWrapper}>
                <Table aria-labelledby="tableTitle" size="small">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={ordering}
                    orderBy={orderingBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={Object.keys(filtered).length}
                    headRows={headers}
                    disableSelection={disableSelection}
                    disabledSorting={false}
                  />
                  <EnhancedTableBody
                    entityName={entityName}
                    emptyRows={emptyRows}
                    handleClick={(rowId) =>
                      handleClick(rowId, entityIdColumnName)
                    }
                    headers={headers}
                    rowSettings={rowSettings}
                    isSelected={isSelected}
                    rows={
                      !usePagination
                        ? rowSort(
                            filtered,
                            getSorting(
                              order,
                              orderBy,
                              headers,
                              secondOrder,
                              secondOrderByColumn
                            )
                          ).slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : rowWithoutSort(filtered)
                    }
                    disableSelection={disableSelection}
                    showCellFullData={showCellFullData}
                    handleCellValueChanged={handleCellValueChanged}
                    handleBlur={handleBlur}
                  />
                </Table>
              </div>
              {showTablePagination && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={getRowCount()}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'previous',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'next',
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage=""
                />
              )}
            </>
          ) : (
            <Loader containerClass={classes.heightAndCenter} />
          )}
        </Paper>
      </div>
    );
  };

  return (
    <>
      {!inline && (
        <section className={classes.header}>
          <h1 className={classes.title}>{title || inlineTitle}</h1>
          <div className={classes.actionsContainer}>{renderActions()}</div>
          {/* <div className={classes.actions}>
          {!inline && (widgetActions || buttons) && (
            <>
              <ActionBar
                actions={widgetActions.filter(
                  action =>
                    !dropdownActionsNames ||
                    !dropdownActionsNames.includes(action.name),
                )}
                buttons={buttons}
              />
              {renderActionMenu()}
            </>
          )}
        </div> */}
        </section>
      )}
      {renderTable()}
    </>
  );
};

export default EnhancedTable;

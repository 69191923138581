import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { getSections, initialValues } from './content';
import { cloneDeep, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createPolicyPerson,
  getPolicyPersonEnums,
  getPersonInfo,
  updatePolicyPerson,
  getZonesByCountry,
} from './queries';
import { graphqlEntityToPersonInfo, LookupToList } from './utils';
import { EnhancedButtonStatus } from '../../components/EnhancedButton';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { getError, lookupListAsRecordObject } from '../../utils/graph-utils';
import Loader from '../../components/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import SectionDynamicForm from '../../DynamicForm/SectionDynamicForm';
import DateService from '../../services/dateService';

const ProposalPolicyPersonDrawer: React.FC<IPolicyPersonDrawerProps> = ({
  proposalId,
  personId,
  section,
  lineExternalCode,
  open,
  successRoute = '/production/proposal/',
  onSuccess,
  onClose,
  onSubmitSuccess,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [booted, setBooted] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [values, setValues] = useState<Record<string, any>>();
  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({});

  const [personDetailsListResults] = useLazyQuery(getPolicyPersonEnums(), {
    fetchPolicy: 'no-cache',
  });
  const [personInfoQuery] = useLazyQuery(getPersonInfo(), {
    fetchPolicy: 'no-cache',
  });

  const [getZonesByCountryLazy] = useLazyQuery(getZonesByCountry(), {
    fetchPolicy: 'no-cache',
  });

  const [personAction] = useMutation(
    personId ? updatePolicyPerson() : createPolicyPerson()
  );

  const loadLovList = async () => {
    const result = await personDetailsListResults();
    const personEnums = LookupToList(result.data);

    return personEnums;
  };

  const getEntityInfo = async () => {
    if (personId) {
      const apiResult = await personInfoQuery({
        variables: { id: personId },
      });
      if (apiResult.data) {
        const personEntity = graphqlEntityToPersonInfo(apiResult.data);
        return personEntity;
      }
    }

    return initialValues;
  };

  const loadZoneLov = async (countryId: string) => {
    const result = await getZonesByCountryLazy({
      fetchPolicy: 'no-cache',
      variables: { countryID: countryId },
    });

    const zonesData = result.data.Insurance.lookups.zones;

    return lookupListAsRecordObject(zonesData);
  };

  const initialize = async () => {
    try {
      const newValues: IPolicyPersonInfo = initialValues;
      const newLovs: Record<string, Record<string, string>> = {};
      const [personEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      if (personEntity) {
        newValues.type = personEntity.type;
        newValues.companyName = personEntity.companyName;
        newValues.title = personEntity.title;
        newValues.firstName = personEntity.firstName;
        newValues.middleName = personEntity.middleName;
        newValues.lastName = personEntity.lastName;
        newValues.dateOfBirth = personEntity.dateOfBirth;
        newValues.email = personEntity.email;
        newValues.phoneType = personEntity.phoneType;
        newValues.phoneNumber = personEntity.phoneNumber;
        newValues.nationalityOne = personEntity.nationalityOne;
        newValues.isTaxable = personEntity.isTaxable;
        newValues.occupationClass = personEntity.occupationClass;
        newValues.profession = personEntity.profession;
        newValues.employer = personEntity.employer;
        newValues.dateOfEmployment = personEntity.dateOfEmployment;
        newValues.addressType = personEntity.addressType;
        newValues.country = personEntity.country;
        newValues.zone = personEntity.zone;
        newValues.city = personEntity.city;
        newValues.street = personEntity.street;
        newValues.building = personEntity.building;
        newValues.addressDetails = personEntity.addressDetails;
        newValues.addressDetailsArabic = personEntity.addressDetailsArabic;
        newValues.isPrimary = personEntity.isPrimary;
      }

      if (personEntity?.country) {
        newLovs.zone = await loadZoneLov(personEntity.country);
      }
      setLovs({ ...lovData, ...newLovs });
      setValues(newValues);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const submitForm = async (data: Record<string, any>) => {
    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const variables = {
        entityId: params.id,
        dateOfBirth: DateService.formatDateBackend(data.dateOfBirth),
        dateOfEmployment: DateService.formatDateBackend(data.dateOfEmployment),
        policyPersonType: section.toUpperCase(),
        personDetailsInputs: {
          personType: data.type,
          companyName: !isEmpty(data.companyName) ? data.companyName : null,
          title: !isEmpty(data.title) ? data.title : null,
          firstName: !isEmpty(data.firstName) ? data.firstName : null,
          middleName: !isEmpty(data.middleName) ? data.middleName : null,
          lastName: !isEmpty(data.lastName) ? data.lastName : null,
          email: !isEmpty(data.email) ? data.email : null,
          phoneType: !isEmpty(data.phoneType) ? data.phoneType : null,
          phoneNumber: !isEmpty(data.phoneNumber) ? data.phoneNumber : null,
        },
        personWorkDetailsInputs: {
          nationalityOne: data.nationalityOne || null,
          gender: data.gender || null,
          isTaxable: data.isTaxable,
          occupationClass: !isEmpty(data.occupationClass)
            ? data.occupationClass
            : null,
          employer: !isEmpty(data.employer) ? data.employer : null,
          profession: !isEmpty(data.profession) ? data.profession : null,
        },
      };

      const res = await personAction({
        variables: personId ? { ...variables, entityId: personId } : variables,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {personId
              ? 'Person successfully updated'
              : 'Person successfully created'}
          </ToastSuccessMessage>
        );

        const newPersonId =
          res.data.production.entities.proposal.production.createAssignedPerson
            .Person.Id;
        const personFullName =
          res.data.production.entities.proposal.production.createAssignedPerson
            .Person.FullName;
        const personCode =
          res.data.production.entities.proposal.production.createAssignedPerson
            .Person.PersonCode;
        const newPersonFullName = `${personCode} - ${personFullName}`;

        if (onSubmitSuccess) {
          onSubmitSuccess(newPersonId, newPersonFullName);
        }

        setTimeout(() => {
          setSubmitButtonState('success');
          setFormDisabled(false);
          onSuccess && onSuccess();
          onClose();
          navigate(`${successRoute}${proposalId}`, {
            state: {
              newPersonFullName,
              newPersonId,
              section,
            },
          });
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        setFormDisabled(false);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      setFormDisabled(false);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const onChange = async (
    fieldName: string,
    value: any,
    values: Record<string, any>
  ) => {
    if (fieldName === 'country') {
      values.zone = '';
      const newLovs = cloneDeep(lovs);
      newLovs.zone = await loadZoneLov(value);
      setLovs(newLovs);
    }
    setValues(values);
  };

  const sections = useMemo(() => {
    return getSections(values, lovs, section, lineExternalCode);
  }, [values, lovs, section, lineExternalCode]);

  return (
    <GenericDrawer
      title={personId ? '' : 'New Policy Person'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <SectionDynamicForm
            sections={sections}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={!!submitButtonState}
            disableForm={formDisabled}
            title=""
            onChange={onChange}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ProposalPolicyPersonDrawer;

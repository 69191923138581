import {
  IGenericAllRiskPlanRatesTableData,
  IGenericExpatPlanRatesTableData,
  IGenericMedicalPlanRatesTableData,
  IGenericPlanCoversRatesTableData,
  IGenericPolicyCoverTableData,
  IGenericTravelPlanRatesTableData,
} from '.';
import { IAbstractRecord } from '../../../models';
import { IListingData } from '../../../models/listing';
import PrecisionService from '../../../services/precisionService';
import {
  capitalizeFirstLetter,
  getTitlesFromMultiselectData,
  trimTrailingZeros,
} from '../../../utils/formatting-utils';
import { steps } from './content';

export function entityToPlanDetails(data: IAbstractRecord): any {
  const sublineDetailsInfo =
    data?.PlanConfigManagement?.entities?.plan?.views?.PlanConfigManagement_all
      ?.properties;

  return {
    planName: sublineDetailsInfo?.Name,
    sublineName:
      sublineDetailsInfo?.SublineID?.views?.PlanConfigManagement_all?.properties
        ?.Name,
    sublineId: sublineDetailsInfo?.SublineID?.id,
    lineName:
      sublineDetailsInfo?.LineID?.views?.PlanConfigManagement_all?.properties
        ?.Name,
    lineId: sublineDetailsInfo?.LineID?.id,
    externalCode: sublineDetailsInfo?.ExternalCode,
    arabicName: sublineDetailsInfo?.NameArabic,
    effectiveFrom: sublineDetailsInfo?.EffectiveFrom,
    effectiveTo: sublineDetailsInfo?.EffectiveTo,
    abbreviation: sublineDetailsInfo?.Abbreviation,
    planCurrency: sublineDetailsInfo?.PlanCurrency?.Id,
    planCurrencyTitle: sublineDetailsInfo?.PlanCurrency?.Title,
    planCurrencySymbol: sublineDetailsInfo?.PlanCurrency?.Symbol,
    maxNumberofInsured: sublineDetailsInfo?.MaxNbInsured,
    maxNumberofBeneficiary: sublineDetailsInfo?.MaxNbBeneficiary,
    clauseEditable: sublineDetailsInfo?.ClauseEditable,

    acalCategory: sublineDetailsInfo?.AcalCategory?.Id,
    scratching: sublineDetailsInfo?.Scratching,
    scratchingTitle: capitalizeFirstLetter(sublineDetailsInfo?.Scratching),
    acalCategoryTitle: sublineDetailsInfo?.AcalCategory?.Title,
    sOSService: sublineDetailsInfo?.SOSService,
    sOSServiceTitle: capitalizeFirstLetter(sublineDetailsInfo?.SOSService),
    replacementCarApplied: sublineDetailsInfo?.ReplacementCarApplied,
    replacementCarAppliedTitle: capitalizeFirstLetter(
      sublineDetailsInfo?.ReplacementCarApplied
    ),
    replacementCarValue: sublineDetailsInfo?.ReplacementCarValue?.toString(),
    replacementCarDays: sublineDetailsInfo?.ReplacementCarDays,
    lineIdExternalCode:
      sublineDetailsInfo?.LineID?.views?.PlanConfigManagement_all?.properties
        ?.ExternalCode,

    status: sublineDetailsInfo?.PlanStatus,

    createdBy: `${data?.PlanConfigManagement?.entities?.plan?.queries?.planConfigManagement?.relatedUsers[0]?.createdBy_firstName} ${data?.PlanConfigManagement?.entities?.plan?.queries?.planConfigManagement?.relatedUsers[0]?.createdBy_lastName}`,
    createdOn: sublineDetailsInfo?.createdOn,
    modifiedBy: `${data?.PlanConfigManagement?.entities?.plan?.queries?.planConfigManagement?.relatedUsers[0]?.modifiedBy_firstName} ${data?.PlanConfigManagement?.entities?.plan?.queries?.planConfigManagement?.relatedUsers[0]?.modifiedBy_lastName}`,
    modifiedOn: sublineDetailsInfo?.modifiedOn,

    specialCommissionApplicable:
      sublineDetailsInfo?.SpecialCommissionApplicable,
    specialCommissionType: sublineDetailsInfo?.SpecialCommissionType,
    specialCommissionValue: sublineDetailsInfo?.SpecialCommissionValue,
    specialCommissionValueCurrency:
      sublineDetailsInfo?.SpecialCommissionValueCurrency?.Id,
    specialCommissionValueCurrencyTitle:
      sublineDetailsInfo?.SpecialCommissionValueCurrency?.Title,
    specialCommissionRate: sublineDetailsInfo?.SpecialCommissionRate,
    specialCommissionRateTitle: `${trimTrailingZeros(PrecisionService.multiplyBy100(Number(sublineDetailsInfo.SpecialCommissionRate)))}%`,
    specialCommissionApplicableOn:
      sublineDetailsInfo?.SpecialCommissionApplicableOn?.Id,
    specialCommissionApplicableOnTitle:
      sublineDetailsInfo?.SpecialCommissionApplicableOn?.Title,

    policyCost: trimTrailingZeros(sublineDetailsInfo?.PolicyCost),
    policyCostTitle:
      sublineDetailsInfo?.PolicyCost &&
      `${sublineDetailsInfo?.PlanCurrency?.Symbol} ${trimTrailingZeros(
        sublineDetailsInfo?.PolicyCost,
        3,
        true
      )}`,
    endorsementCost: trimTrailingZeros(sublineDetailsInfo?.EndorsementCost),
    endorsementCostTitle:
      sublineDetailsInfo?.EndorsementCost &&
      `${sublineDetailsInfo?.PlanCurrency?.Symbol} ${trimTrailingZeros(
        sublineDetailsInfo?.EndorsementCost,
        3,
        true
      )}`,
    chargesPercentage: sublineDetailsInfo?.ChargesPercentage,
    chargesPercentageTitle:
      sublineDetailsInfo?.ChargesPercentage &&
      `${trimTrailingZeros(PrecisionService.multiplyBy100(sublineDetailsInfo.ChargesPercentage))}%`,
    tPAFeesType: sublineDetailsInfo?.TPAFeesType,
    tPAFeesTypeTitle: capitalizeFirstLetter(sublineDetailsInfo?.TPAFeesType),
    tPAFeesAmount: trimTrailingZeros(sublineDetailsInfo?.TPAFeesAmount),
    tPAFeesAmountTitle:
      sublineDetailsInfo?.TPAFeesAmount &&
      `${sublineDetailsInfo?.PlanCurrency?.Symbol} ${trimTrailingZeros(
        sublineDetailsInfo?.TPAFeesAmount,
        3,
        true
      )}`,
    tPAFeesPercentage: sublineDetailsInfo?.TPAFeesPercentage,
    tPAFeesPercentageTitle:
      sublineDetailsInfo?.TPAFeesPercentage &&
      `${trimTrailingZeros(PrecisionService.multiplyBy100(sublineDetailsInfo.TPAFeesPercentage))}%`,
    tPAFeesApplicableOn: sublineDetailsInfo?.TPAFeesApplicableOn,
    tPAFeesApplicableOnTitle: capitalizeFirstLetter(
      sublineDetailsInfo?.TPAFeesApplicableOn?.replaceAll('_', ' ')
    ),
    minTPA: trimTrailingZeros(sublineDetailsInfo?.MinTPA),
    minTPATitle:
      sublineDetailsInfo?.MinTPA &&
      `${sublineDetailsInfo?.PlanCurrency?.Symbol} ${trimTrailingZeros(
        sublineDetailsInfo?.MinTPA,
        3,
        true
      )}`,
    maxTPA: sublineDetailsInfo?.MaxTPA,
    maxTPATitle:
      sublineDetailsInfo?.MaxTPA &&
      `${sublineDetailsInfo?.PlanCurrency?.Symbol} ${trimTrailingZeros(
        sublineDetailsInfo?.MaxTPA,
        3,
        true
      )}`,
    grLimitDetails: sublineDetailsInfo?.GRLimitDays,
  };
}

export const getPlanStatus = (status: string) => {
  switch (status) {
    case 'inactive':
      return steps[0];
    case 'active':
      return steps[1];
    default:
      return steps[0];
  }
};

export function mapToPolicyCoversListingData(
  data: IAbstractRecord
): IListingData {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getPolicyCoverList.items.forEach(
    (obj: IGenericPolicyCoverTableData) => {
      list[obj.planConfigManagement_PolicyCover_Id] = {
        planConfigManagement_PolicyCover_Id:
          obj.planConfigManagement_PolicyCover_Id,
        planConfigManagement_PolicyCover_Name:
          obj.planConfigManagement_PolicyCover_Name,
        planConfigManagement_PolicyCover_ExternalCode:
          obj.planConfigManagement_PolicyCover_ExternalCode,
        planConfigManagement_PolicyCover_NameArabic:
          obj.planConfigManagement_PolicyCover_NameArabic,
        planConfigManagement_PolicyCover_PrintingOrder:
          obj.planConfigManagement_PolicyCover_PrintingOrder,
        planConfigManagement_PolicyCover_PolicyCoverDescription:
          obj.planConfigManagement_PolicyCover_PolicyCoverDescription,
        planConfigManagement_PolicyCover_createdOn:
          obj.planConfigManagement_PolicyCover_createdOn,
        planConfigManagement_PolicyCover_modifiedOn:
          obj.planConfigManagement_PolicyCover_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPolicyCoverList?.paging?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPolicyCoverList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPolicyCoverList?.paging
        ?.totalCount,
  };
}

export function mapToAllRiskPlanRatesListingData(
  data: IAbstractRecord,
  planCurrency: string
): IListingData {
  const list: IAbstractRecord = {};

  data?.PlanConfigManagement?.queries?.getPlanSpecificAllRiskList?.items?.forEach(
    (obj: IGenericAllRiskPlanRatesTableData, i: number) => {
      list[obj.planConfigManagement_PlanSpecificAllRisk_Id] = {
        planConfigManagement_PlanSpecificAllRisk_Id:
          obj.planConfigManagement_PlanSpecificAllRisk_Id,
        planConfigManagement_PlanSpecificAllRisk_CarValueFrom:
          obj.planConfigManagement_PlanSpecificAllRisk_CarValueFrom,
        planConfigManagement_PlanSpecificAllRisk_CarValueTo:
          obj.planConfigManagement_PlanSpecificAllRisk_CarValueTo,
        planConfigManagement_PlanSpecificAllRisk_CarAgeFrom:
          obj.planConfigManagement_PlanSpecificAllRisk_CarAgeFrom,
        planConfigManagement_PlanSpecificAllRisk_CarAgeTo:
          obj.planConfigManagement_PlanSpecificAllRisk_CarAgeTo,
        // planConfigManagement_PlanSpecificAllRisk_CarCategory:
        //   obj.planConfigManagement_PlanSpecificAllRisk_CarCategory?.Id,
        planConfigManagement_PlanSpecificAllRisk_CarCategory:
          obj.planConfigManagement_PlanSpecificAllRisk_CarCategory?.Title,
        planConfigManagement_PlanSpecificAllRisk_MinimumPremium:
          obj.planConfigManagement_PlanSpecificAllRisk_MinimumPremium,
        planConfigManagement_PlanSpecificAllRisk_Rate: trimTrailingZeros(
          PrecisionService.multiplyBy100(
            Number(obj.planConfigManagement_PlanSpecificAllRisk_Rate)
          )
        ),
        planConfigManagement_PlanSpecificAllRisk_AgencyYear:
          obj.planConfigManagement_PlanSpecificAllRisk_AgencyYear,
        planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear:
          obj.planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear,
        Currency:
          obj.planConfigManagement_PlanSpecificAllRisk_PlanID?.views
            ?.PlanConfigManagement_all?.properties?.PlanCurrency?.Symbol,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPlanSpecificAllRiskList?.paging
        ?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPlanSpecificAllRiskList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPlanSpecificAllRiskList?.paging
        ?.totalCount,
  };
}

export function mapToExpatPlanRatesListingData(
  data: IAbstractRecord,
  planCurrency: string
): IListingData {
  const list: IAbstractRecord = {};

  data?.PlanConfigManagement?.queries?.getPlanSpecificExpatList?.items?.forEach(
    (obj: IGenericExpatPlanRatesTableData) => {
      list[obj.planConfigManagement_PlanSpecificExpat_Id] = {
        planConfigManagement_PlanSpecificExpat_Id:
          obj.planConfigManagement_PlanSpecificExpat_Id,
        planConfigManagement_PlanSpecificExpat_AgeFrom:
          obj.planConfigManagement_PlanSpecificExpat_AgeFrom,
        planConfigManagement_PlanSpecificExpat_AgeTo:
          obj.planConfigManagement_PlanSpecificExpat_AgeTo,
        planConfigManagement_PlanSpecificExpat_Gender: capitalizeFirstLetter(
          obj.planConfigManagement_PlanSpecificExpat_Gender
        ),
        planConfigManagement_PlanSpecificExpat_OccupationClass:
          obj.planConfigManagement_PlanSpecificExpat_OccupationClass?.Title,
        planConfigManagement_PlanSpecificExpat_Nationality:
          getTitlesFromMultiselectData(
            obj.planConfigManagement_PlanSpecificExpat_Nationality
          ),
        planConfigManagement_PlanSpecificExpat_Premium:
          obj.planConfigManagement_PlanSpecificExpat_Premium,
        planConfigManagement_PlanSpecificExpat_MedicalClass:
          obj.planConfigManagement_PlanSpecificExpat_MedicalClass?.Title,
        planConfigManagement_PlanSpecificExpat_Emergency: capitalizeFirstLetter(
          obj?.planConfigManagement_PlanSpecificExpat_Emergency || ''
        ),
        Currency:
          obj.planConfigManagement_PlanSpecificExpat_PlanID?.views
            ?.PlanConfigManagement_all?.properties?.PlanCurrency?.Symbol,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPlanSpecificExpatList?.paging
        ?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPlanSpecificExpatList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPlanSpecificExpatList?.paging
        ?.totalCount,
  };
}

export function mapToTravelPlanRatesListingData(
  data: IAbstractRecord
): IListingData {
  const list: IAbstractRecord = {};

  data?.PlanConfigManagement?.queries?.getPlanSpecificTravelList?.items?.forEach(
    (obj: IGenericTravelPlanRatesTableData) => {
      const planCurrencySymbol =
        obj.planConfigManagement_PlanSpecificTravel_PlanID?.views
          ?.PlanConfigManagement_all?.properties?.PlanCurrency?.Symbol || '';

      let tpaFeesDisplay = '';
      const tpaType = obj.planConfigManagement_PlanSpecificTravel_TPAType;
      if (tpaType === 'PERCENTAGE') {
        const percentageValue = PrecisionService.multiplyBy100(
          Number(obj.planConfigManagement_PlanSpecificTravel_TPAFeesPercentage)
        );
        tpaFeesDisplay = `${trimTrailingZeros(percentageValue, 3, true)}%`;
      } else if (tpaType === 'AMOUNT') {
        const amountValue = trimTrailingZeros(
          obj.planConfigManagement_PlanSpecificTravel_TPAFeesAmount,
          3,
          true
        );
        tpaFeesDisplay = `${planCurrencySymbol} ${amountValue}`;
      }

      let excessOnClaim = '';
      const claimType =
        obj.planConfigManagement_PlanSpecificTravel_ExcessOnClaimType;
      if (claimType === 'VALUE') {
        excessOnClaim = `${planCurrencySymbol} ${trimTrailingZeros(
          obj.planConfigManagement_PlanSpecificTravel_ExcessOnClaimAmount,
          3,
          true
        )}`;
      } else if (claimType === 'PERCENTAGE') {
        const perc = PrecisionService.multiplyBy100(
          Number(
            obj.planConfigManagement_PlanSpecificTravel_ExcessOnClaimPercentage
          )
        );
        excessOnClaim = `${trimTrailingZeros(perc, 3, true)}%`;
      } else if (claimType === 'DAYS') {
        excessOnClaim = `${obj.planConfigManagement_PlanSpecificTravel_ExcessOnClaimDays} Days`;
      }

      const clauseReference = `${
        obj.planConfigManagement_PlanSpecificTravel_ClauseID?.views
          ?.PlanConfigManagement_all?.properties?.ExternalCode || '-'
      } - ${
        obj.planConfigManagement_PlanSpecificTravel_ClauseID?.views
          ?.PlanConfigManagement_all?.properties?.Name || ''
      }`.trim();

      list[obj.planConfigManagement_PlanSpecificTravel_Id] = {
        planConfigManagement_PlanSpecificTravel_Id:
          obj.planConfigManagement_PlanSpecificTravel_Id,
        planConfigManagement_PlanSpecificTravel_AgeFrom:
          obj.planConfigManagement_PlanSpecificTravel_AgeFrom,
        planConfigManagement_PlanSpecificTravel_AgeTo:
          obj.planConfigManagement_PlanSpecificTravel_AgeTo,
        planConfigManagement_PlanSpecificTravel_Gender: capitalizeFirstLetter(
          obj.planConfigManagement_PlanSpecificTravel_Gender
        ),
        planConfigManagement_PlanSpecificTravel_TravelDurationFrom:
          obj.planConfigManagement_PlanSpecificTravel_TravelDurationFrom,
        planConfigManagement_PlanSpecificTravel_TravelDurationTo:
          obj.planConfigManagement_PlanSpecificTravel_TravelDurationTo,
        planConfigManagement_PlanSpecificTravel_TravelDestination:
          obj.planConfigManagement_PlanSpecificTravel_TravelDestination?.Title,
        planConfigManagement_PlanSpecificTravel_IsMain:
          obj.planConfigManagement_PlanSpecificTravel_IsMain ? 'Yes' : 'No',
        planConfigManagement_PlanSpecificTravel_IsMandatory:
          obj.planConfigManagement_PlanSpecificTravel_IsMandatory
            ? 'Yes'
            : 'No',
        planConfigManagement_PlanSpecificTravel_IsEditable:
          obj.planConfigManagement_PlanSpecificTravel_IsEditable ? 'Yes' : 'No',
        planConfigManagement_PlanSpecificTravel_NbOfAdherent:
          obj.planConfigManagement_PlanSpecificTravel_NbOfAdherent,
        planConfigManagement_PlanSpecificTravel_SumInsured:
          obj.planConfigManagement_PlanSpecificTravel_SumInsured,
        planConfigManagement_PlanSpecificTravel_SumInsuredIsAdditive:
          obj.planConfigManagement_PlanSpecificTravel_SumInsuredIsAdditive
            ? 'Yes'
            : 'No',
        planConfigManagement_PlanSpecificTravel_Premium:
          obj.planConfigManagement_PlanSpecificTravel_Premium,
        planConfigManagement_PlanSpecificTravel_TPAFees: tpaFeesDisplay,
        planConfigManagement_PlanSpecificTravel_MinTPAFees:
          obj.planConfigManagement_PlanSpecificTravel_MinTPAFees
            ? `${planCurrencySymbol} ${trimTrailingZeros(
                obj.planConfigManagement_PlanSpecificTravel_MinTPAFees,
                3,
                true
              )}`
            : '',
        planConfigManagement_PlanSpecificTravel_MaxTPAFees:
          obj.planConfigManagement_PlanSpecificTravel_MaxTPAFees
            ? `${planCurrencySymbol} ${trimTrailingZeros(
                obj.planConfigManagement_PlanSpecificTravel_MaxTPAFees,
                3,
                true
              )}`
            : '',
        planConfigManagement_PlanSpecificTravel_ExcessOnClaimType:
          capitalizeFirstLetter(claimType),
        planConfigManagement_PlanSpecificTravel_ExcessOnClaim: excessOnClaim,
        planConfigManagement_PlanSpecificTravel_ClauseReference:
          clauseReference,
        planConfigManagement_PlanSpecificTravel_PolicyCoverID:
          obj.planConfigManagement_PlanSpecificTravel_PolicyCoverID?.views
            ?.PlanConfigManagement_all?.properties?.Name,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPlanSpecificTravelList?.paging
        ?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPlanSpecificTravelList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPlanSpecificTravelList?.paging
        ?.totalCount,
  };
}

export function mapToMedicalPlanRatesListingData(
  data: IAbstractRecord
): IListingData {
  const list: IAbstractRecord = {};

  data?.PlanConfigManagement?.queries?.getPlanSpecificMedicalList?.items?.forEach(
    (obj: IGenericMedicalPlanRatesTableData) => {
      let excessOnClaim = '';
      const claimType =
        obj.planConfigManagement_PlanSpecificMedical_ExcessOnClaimType;

      const planCurrency =
        obj.planConfigManagement_PlanSpecificMedical_PlanID?.views
          ?.PlanConfigManagement_all?.properties?.PlanCurrency?.Symbol || '';

      if (claimType === 'VALUE') {
        excessOnClaim = `${planCurrency} ${trimTrailingZeros(
          obj.planConfigManagement_PlanSpecificMedical_ExcessOnClaimAmount,
          3,
          true
        )}`;
      } else if (claimType === 'PERCENTAGE') {
        excessOnClaim = `${trimTrailingZeros(
          PrecisionService.multiplyBy100(
            Number(
              obj.planConfigManagement_PlanSpecificMedical_ExcessOnClaimPercentage
            )
          ),
          3,
          true
        )}%`;
      } else if (claimType === 'DAYS') {
        excessOnClaim = `${obj.planConfigManagement_PlanSpecificMedical_ExcessOnClaimDays} Days`;
      }

      const clauseReference = `${
        obj.planConfigManagement_PlanSpecificMedical_ClauseID?.views
          ?.PlanConfigManagement_all?.properties?.ExternalCode || '-'
      } - ${
        obj.planConfigManagement_PlanSpecificMedical_ClauseID?.views
          ?.PlanConfigManagement_all?.properties?.Name || ''
      }`;
      const tpaType = obj.planConfigManagement_PlanSpecificMedical_TPAType;
      let tpaFeesValue = '';
      if (tpaType === 'PERCENTAGE') {
        tpaFeesValue = `${trimTrailingZeros(
          PrecisionService.multiplyBy100(
            Number(
              obj.planConfigManagement_PlanSpecificMedical_TPAFeesPercentage
            )
          ),
          3,
          true
        )}%`;
      } else if (tpaType === 'AMOUNT') {
        tpaFeesValue = `${planCurrency} ${trimTrailingZeros(
          obj.planConfigManagement_PlanSpecificMedical_TPAAmount,
          3,
          true
        )}`;
      }

      list[obj.planConfigManagement_PlanSpecificMedical_Id] = {
        planConfigManagement_PlanSpecificMedical_Id:
          obj.planConfigManagement_PlanSpecificMedical_Id,
        planConfigManagement_PlanSpecificMedical_PolicyCoverID:
          obj.planConfigManagement_PlanSpecificMedical_PolicyCoverID?.views
            ?.PlanConfigManagement_all?.properties?.Name,
        planConfigManagement_PlanSpecificMedical_NbOfAdherent:
          obj.planConfigManagement_PlanSpecificMedical_NbOfAdherent,
        planConfigManagement_PlanSpecificMedical_Class:
          obj.planConfigManagement_PlanSpecificMedical_Class?.Title,
        planConfigManagement_PlanSpecificMedical_AgeFrom:
          obj.planConfigManagement_PlanSpecificMedical_AgeFrom,
        planConfigManagement_PlanSpecificMedical_AgeTo:
          obj.planConfigManagement_PlanSpecificMedical_AgeTo,
        planConfigManagement_PlanSpecificMedical_CoNssf: capitalizeFirstLetter(
          obj.planConfigManagement_PlanSpecificMedical_CoNssf
        ),
        planConfigManagement_PlanSpecificMedical_NetPremium: `${planCurrency} ${trimTrailingZeros(
          obj.planConfigManagement_PlanSpecificMedical_NetPremium,
          3,
          true
        )}`,
        planConfigManagement_PlanSpecificMedical_TPAFees: tpaFeesValue,
        planConfigManagement_PlanSpecificMedical_MinTPAFees: `${planCurrency} ${trimTrailingZeros(
          obj.planConfigManagement_PlanSpecificMedical_MinTPAFees,
          3,
          true
        )}`,
        planConfigManagement_PlanSpecificMedical_MaxTPAFees: `${planCurrency} ${trimTrailingZeros(
          obj.planConfigManagement_PlanSpecificMedical_MaxTPAFees,
          3,
          true
        )}`,
        planConfigManagement_PlanSpecificMedical_IsMain:
          obj.planConfigManagement_PlanSpecificMedical_IsMain ? 'Yes' : 'No',
        planConfigManagement_PlanSpecificMedical_IsMandatory:
          obj.planConfigManagement_PlanSpecificMedical_IsMandatory
            ? 'Yes'
            : 'No',
        planConfigManagement_PlanSpecificMedical_IsEditable:
          obj.planConfigManagement_PlanSpecificMedical_IsEditable
            ? 'Yes'
            : 'No',
        planConfigManagement_PlanSpecificMedical_Level:
          obj.planConfigManagement_PlanSpecificMedical_Level?.Title,
        planConfigManagement_PlanSpecificMedical_SumInsured: `${planCurrency} ${trimTrailingZeros(
          obj.planConfigManagement_PlanSpecificMedical_SumInsured,
          3,
          true
        )}`,
        planConfigManagement_PlanSpecificMedical_AdditiveSumInsured:
          obj.planConfigManagement_PlanSpecificMedical_SumInsuredIsAdditive
            ? 'Yes'
            : 'No',
        planConfigManagement_PlanSpecificMedical_ExcessOnClaimType:
          capitalizeFirstLetter(
            obj.planConfigManagement_PlanSpecificMedical_ExcessOnClaimType
          ),
        planConfigManagement_PlanSpecificMedical_ExcessOnClaim: excessOnClaim,
        planConfigManagement_PlanSpecificMedical_ClauseReference:
          clauseReference,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPlanSpecificMedicalList?.paging
        ?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPlanSpecificMedicalList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPlanSpecificMedicalList?.paging
        ?.totalCount,
  };
}

export function mapToPlanCoversRatesListingData(
  data: IAbstractRecord
): IListingData {
  const list: IAbstractRecord = {};

  data?.PlanConfigManagement?.queries?.getPlanCoverList?.items?.forEach(
    (obj: IGenericPlanCoversRatesTableData) => {
      list[obj.planConfigManagement_PlanCover_Id] = {
        planConfigManagement_PlanCover_Id:
          obj.planConfigManagement_PlanCover_Id,
        planConfigManagement_PlanCover_PolicyCoverID_ID:
          obj.planConfigManagement_PlanCover_PolicyCoverID?.id,
        planConfigManagement_PlanCover_PolicyCoverID: `${obj.planConfigManagement_PlanCover_PolicyCoverID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${obj.planConfigManagement_PlanCover_PolicyCoverID?.views?.PlanConfigManagement_all?.properties?.Name}`,
        planConfigManagement_PlanCover_IsMain:
          obj.planConfigManagement_PlanCover_IsMain ? 'Yes' : 'No',
        planConfigManagement_PlanCover_IsMandatory:
          obj.planConfigManagement_PlanCover_IsMandatory ? 'Yes' : 'No',
        planConfigManagement_PlanCover_IsEditable:
          obj.planConfigManagement_PlanCover_IsEditable ? 'Yes' : 'No',
        planConfigManagement_PlanCover_CoverPremiumType: capitalizeFirstLetter(
          obj.planConfigManagement_PlanCover_CoverPremiumType
        ),
        planConfigManagement_PlanCover_CoverPremiumValue:
          obj.planConfigManagement_PlanCover_CoverPremiumType === 'VALUE'
            ? `${
                obj.planConfigManagement_PlanCover_PlanID?.views
                  ?.PlanConfigManagement_all?.properties?.PlanCurrency?.Symbol
              } ${trimTrailingZeros(
                obj.planConfigManagement_PlanCover_CoverPremiumValue,
                3,
                true
              )}`
            : `${trimTrailingZeros(
                PrecisionService.multiplyBy100(
                  Number(
                    obj.planConfigManagement_PlanCover_CoverPremiumPercentage
                  )
                )
              )}%`,
        planConfigManagement_PlanCover_ExcessOnClaimAmount:
          obj.planConfigManagement_PlanCover_ExcessOnClaimType === 'VALUE'
            ? `${
                obj.planConfigManagement_PlanCover_PlanID?.views
                  ?.PlanConfigManagement_all?.properties?.PlanCurrency?.Symbol
              } ${trimTrailingZeros(
                obj.planConfigManagement_PlanCover_ExcessOnClaimAmount,
                3,
                true
              )}`
            : obj.planConfigManagement_PlanCover_ExcessOnClaimType ===
                'PERCENTAGE'
              ? `${trimTrailingZeros(
                  PrecisionService.multiplyBy100(
                    Number(
                      obj.planConfigManagement_PlanCover_ExcessOnClaimPercentage
                    )
                  )
                )}%`
              : `${obj.planConfigManagement_PlanCover_ExcessOnClaimDays} Days`,

        planConfigManagement_PlanCover_CoverSumInsured: `${
          obj.planConfigManagement_PlanCover_PlanID?.views
            ?.PlanConfigManagement_all?.properties?.PlanCurrency?.Symbol
        } ${trimTrailingZeros(
          obj.planConfigManagement_PlanCover_CoverSumInsured,
          3,
          true
        )}`,

        planConfigManagement_PlanCover_SumInsuredIsAdditive:
          obj.planConfigManagement_PlanCover_SumInsuredIsAdditive
            ? 'Yes'
            : 'No',

        planConfigManagement_PlanCover_ClauseID: `${
          obj.planConfigManagement_PlanCover_ClauseID?.views
            ?.PlanConfigManagement_all?.properties?.ExternalCode || '-'
        } - ${
          obj.planConfigManagement_PlanCover_ClauseID?.views
            ?.PlanConfigManagement_all?.properties?.Name || ''
        }`,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPlanCoverList?.paging?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPlanCoverList?.paging?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPlanCoverList?.paging?.totalCount,
  };
}

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import { gridWidthToPercentage } from '../../../../utils/grid-utils';
import EnhancedStepper from '../../../../components/common/EnhancedStepper';
import ProductionPolicyEntityInfo from './ProductionPolicyEntityInfo';
import { DEFAULT_ERROR_TEXT, MAIN_ONE_THEME } from '../../../../constants';
import ProductionPolicyDetailsWidget from './SummaryTab/PolicyDetailsWidget';
import TabsLayout from '../../../../page-layout/tabs-layout/TabsLayout';
import { useAppSelector } from '../../../../redux/hooks';
import { productionGroupPolicyPage } from './content';
import _ from 'lodash';
import ProductionPolicyCoversInfo from './SummaryTab/ProductionPolicyCoversInfo';
import ProductionCostChargesWidget from './SummaryTab/ProductionCostChargesWidget';
import {
  getGroupPolicyDetailsQuery,
  getMarineGroupPolicyList,
  getPlanPolicySpecificMedicalCovers,
  getProductionDocuments,
  getProductionGroupPolicyDetails,
} from './queries';
import { useLazyQuery } from '@apollo/client';
import { GetDocumentsData, GetProductionPolicyData } from './functions';
import { IDocumentsDetails, IProductionDetailsSummary } from '.';
import ProductionPolicyQuoteWidget from './SummaryTab/PolicyProposalQuoteWidget';
import PersonsWidget from './custom-components/PersonsWidget';
import { ProductionComissionWidget } from './custom-components/commission-widget/ProductionComissionWidget';
import DocumentWidget from '../../../../components/widgets/file-card/DocumentWidget';
import {
  FileType,
  FileUploadStatus,
  IFile,
  IFileCardProps,
} from '../../../../models/file';
import Separator from '../../../../components/common/Separator';
import EnhancedButton from '../../../../components/EnhancedButton';
import AddDocumentDrawer from './drawers/AddDocumentDrawer';
import ProductionPolicyPaymentTermsSchedule from './PaymentTermsTab/ProductionPolicyPaymentTermsSchedule';
import { getPlanCoversLov } from './utils';
import { MarineGroupCertificateTableWidget } from './certificates-tab/marine-group-certificate-table/MarineGroupCertificateTableWidget';
import { FleetGroupCertificateTableWidget } from './certificates-tab/fleet-certificate-table/FleetCertificateTableWidget';
import PolicyCertficateMarineGroupSummaryDialogShell from './certificates-tab/components/marine-group-certificate-summary-popup/PolicyCertficateMarineGroupSummaryDialogShell';
import ProductionCertificatePolicyPaymentTermsSchedule from './certificate-paymentterms-tab/ProductionCertificatePolicyPaymentTermsSchedule';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import ProductionAmendmentListing from './amendments-tab/ProductionAmendmentListing';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '95%',
    width: '95%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  buttonsContainer: {
    width: '95%',
    margin: '10px auto 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  widgetTopMargin: {
    marginTop: '1em',
  },
  tabPanelClassName: {
    padding: '24px 0',
  },
  thickSeperator: {
    height: 10,
    margin: '10px 0 10px 0',
    gridColumnStart: '1',
    gridColumnEnd: '4',
  },
  container: {
    display: 'flex',
    gap: 'calc(1em + 6px)',
    flexDirection: 'column',
  },
}));

const GroupProductionPolicyPage: React.FC = () => {
  const { classes } = useStyles();

  const user = useAppSelector((state) => state.user);
  const tenant = useAppSelector((state) => state.tenant);

  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams();
  const entityId = params.id;

  const [data, setData] = useState<IProductionDetailsSummary>();
  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    matters: {},
  });

  const [documentsData, setDocumentsData] = useState<IDocumentsDetails>();

  const [getGroupPolicyDetailsLazy] = useLazyQuery(getGroupPolicyDetailsQuery);
  const [geDocumentsDetailsLazy] = useLazyQuery(getProductionDocuments());
  const [getNonSelectedPlanCoverLazy] = useLazyQuery(
    getPlanPolicySpecificMedicalCovers()
  );

  const [getMarineGroupPolicies] = useLazyQuery(getMarineGroupPolicyList());

  const [addDocumentDrawerOpen, setAddDocumentDrawerOpen] =
    useState<boolean>(false);

  const [marineCertficateOpen, setMarineCertficateOpen] =
    useState<boolean>(false);
  const certificateActionCount = useRef<number>(0);

  const getPlanCoverDetails = async (
    planId: string,
    planCoverIds: string[]
  ) => {
    const { data } = await getNonSelectedPlanCoverLazy({
      variables: {
        selectedPlanID: planId,
        selectedPlanCoverIDs: planCoverIds,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return data;
  };

  const initialize = async () => {
    try {
      setLoading(true);
      const { data: policyDetails } = await getGroupPolicyDetailsLazy({
        variables: { id: entityId, state: 'LATEST' },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      const summaryData = GetProductionPolicyData(policyDetails);

      if (summaryData.LineId.ExternalCode?.toString() === '6') {
        const planCoverDetails = await getPlanCoverDetails(
          summaryData.PlanID.Id,
          []
        );
        const lovCovers = getPlanCoversLov(planCoverDetails);
        setLovs({
          policyCovers: lovCovers,
        });
      }

      setData(summaryData);
      setLoading(false);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const loadDocuments = async () => {
    const { data } = await geDocumentsDetailsLazy({
      variables: { id: entityId },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    const summaryData = GetDocumentsData(data);

    setDocumentsData(summaryData);
  };

  useEffect(() => {
    initialize();
    loadDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLeftSection = () => {
    if (data) {
      return <ProductionPolicyEntityInfo data={data} isGroup={true} />;
    }

    return <></>;
  };

  const renderMainChildren = () => {
    if (data) {
      const steps: string[] = ['Bound', 'Inforce', 'Cancelled'];

      const activeStep = steps.find(
        (a) => a.toLowerCase() === data.Status?.toLowerCase()
      );

      return (
        <>
          <div style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <div style={{ width: `${gridWidthToPercentage(8)}%` }}>
                <EnhancedStepper
                  activeStep={activeStep}
                  steps={steps}
                  className={classes.stepper}
                />
              </div>
            </div>
          </div>
        </>
      );
    }

    return <></>;
  };

  const addFileToDocumentWidgetFiles = (
    file: IFile,
    propertyId: string,
    entityTypeId: string,
    hideReviewedLabel = true,
    createdOn?: string,
    version?: number,
    documentCode = ''
  ) => {
    const newFile: IFileCardProps = {
      title: documentCode,
      status: FileUploadStatus.Uploaded,
      createdOn: createdOn,
      version: version,
      fileInfo: {
        id: file?.id,
        location: file?.location,
        path: file?.path,
        fileName: file?.fileName,
        length: file?.length,
        contentType: file?.contentType,
      },
      allowedFileTypes: [
        FileType.pdf,
        FileType.docx,
        FileType.jpeg,
        FileType.png,
      ],
      entityViewId: 'Production-download',
      propertyId: propertyId,
      entityId: file?.path?.split('/')[2],
      entityTypeId: entityTypeId,
      hideReviewedLabel: hideReviewedLabel,
    };

    return newFile;
  };
  const renderTabs = useMemo(() => {
    if (!data) {
      return null;
    }

    const documentWidgetFiles: IFileCardProps[] = [];
    const additionalDocumentWidgetFiles: IFileCardProps[] = [];

    if (documentsData) {
      if (
        documentsData.regeneratedDocuments &&
        documentsData.regeneratedDocuments.length > 0
      ) {
        documentsData.regeneratedDocuments.forEach((policyDocument) => {
          documentWidgetFiles.push(
            addFileToDocumentWidgetFiles(
              policyDocument.file,
              'Document',
              'Production-PolicyDocument',
              true,
              policyDocument.createdOn || '',
              policyDocument.version,
              policyDocument.code
            )
          );
        });
      }

      if (
        documentsData.additionalDocuments &&
        documentsData.additionalDocuments.length > 0
      ) {
        documentsData.additionalDocuments.forEach((policyDocument) => {
          additionalDocumentWidgetFiles.push(
            addFileToDocumentWidgetFiles(
              policyDocument.file,
              'Document',
              'Production-PolicyDocument',
              true,
              policyDocument.createdOn,
              policyDocument.version,
              policyDocument.code
            )
          );
        });
      }
    }
    const tabs = _.cloneDeep(productionGroupPolicyPage);

    tabs.tabs[0].widgets[0].children = (
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            width: `${gridWidthToPercentage(8)}%`,
            padding: '0px 1em 1em 0',
          }}
        >
          <ProductionPolicyDetailsWidget data={data} />
          {data?.Covers?.length > 0 && (
            <>
              <div style={{ height: '1em' }} />
              <ProductionPolicyCoversInfo
                currencySymbol={data?.PolicyCurrency?.Symbol || ''}
                data={data.Covers}
                lineExternalCode={Number(data.LineId.ExternalCode)}
              />
            </>
          )}
          <div style={{ height: '1em' }} />
          <ProductionCostChargesWidget data={data} />
        </div>

        <div
          style={{
            width: `${gridWidthToPercentage(4)}%`,
            padding: '0',
          }}
        >
          <ProductionPolicyQuoteWidget data={data} />
          <ProductionComissionWidget
            className={classes.widgetTopMargin}
            data={data}
          />
        </div>
      </div>
    );

    tabs.tabs[1].widgets[0].children = (
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            width: `${gridWidthToPercentage(12)}%`,
            padding: '0px 1em 1em 0',
          }}
        >
          <PersonsWidget data={data} />
        </div>
      </div>
    );

    tabs.tabs[2].widgets[0].children = (
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            width: `${gridWidthToPercentage(12)}%`,
            padding: '0px 1em 1em 0',
          }}
        >
          {data?.LineId?.ExternalCode?.toString() === '3' && (
            <MarineGroupCertificateTableWidget
              entityId={data.Id}
              key={`certificate-table-${certificateActionCount.current}`}
              currenySymbol={data.PolicyCurrency.Symbol}
              onAddCertActionClicked={() => {
                setMarineCertficateOpen(true);
              }}
            />
          )}
          {['51', '50', '4'].includes(
            data?.LineId?.ExternalCode?.toString()
          ) && (
            <FleetGroupCertificateTableWidget
              entityId={data.Id}
              currencySymbol={data.PolicyCurrency.Symbol}
              lineExternalCode={data.LineId.ExternalCode}
            />
          )}
        </div>
      </div>
    );

    tabs.tabs[3].widgets[0].children = (
      <>
        <DocumentWidget
          title="Policy Documents"
          cdnUrl={tenant.cdnUrl}
          files={documentWidgetFiles}
        />
        <Separator className={classes.thickSeperator} />
        <DocumentWidget
          title="Additional Documents"
          cdnUrl={tenant.cdnUrl}
          files={additionalDocumentWidgetFiles}
          disabledAddDocumentAction={false}
          actions={
            <EnhancedButton
              type="button"
              backgroundColor={'#FFF'}
              color={MAIN_ONE_THEME.palette.primary4.main}
              style={{ display: 'inline' }}
              onClick={() => {
                setAddDocumentDrawerOpen(true);
              }}
              disabled={addDocumentDrawerOpen}
            >
              Add Document
            </EnhancedButton>
          }
          policyEntityId={params.id}
        />
      </>
    );

    tabs.tabs[4].widgets[0].children = (
      <div className={classes.container}>
        <ProductionPolicyPaymentTermsSchedule policyId={params.id} />
        <ProductionCertificatePolicyPaymentTermsSchedule policyId={params.id} />
      </div>
    );

    tabs.tabs[5].widgets[0].children = (
      <ProductionAmendmentListing
        policyId={params.id}
        isGroup
        allowEndorsement={user.info.businessUser_AllowEndorsement}
        allowPolicyCorrection={user.info.businessUser_AllowPolicyCorrection}
        transferredToAccounting={data.TransferredToAccounting}
      />
    );

    return tabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, documentsData, certificateActionCount.current]);

  return loading || !data ? (
    <Loader />
  ) : (
    <>
      <TabsLayout
        name="productionPolicyPage"
        layout={renderTabs}
        theme={MAIN_ONE_THEME}
        tabPanelClassName={classes.tabPanelClassName}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
        firstTabAsActiveTab={true}
        cdnUrl={tenant.cdnUrl}
        userInfo={user.info}
      />
      {addDocumentDrawerOpen && (
        <AddDocumentDrawer
          open={addDocumentDrawerOpen}
          onClose={() => {
            setAddDocumentDrawerOpen(false);
          }}
          onSuccess={() => {
            setAddDocumentDrawerOpen(false);
            loadDocuments();
          }}
          entityId={params.id}
        />
      )}

      {marineCertficateOpen && (
        <PolicyCertficateMarineGroupSummaryDialogShell
          onClose={() => {
            setMarineCertficateOpen(false);
          }}
          onSuccess={() => {
            certificateActionCount.current++;
            setMarineCertficateOpen(false);
          }}
          data={data}
        />
      )}
    </>
  );
};

export default GroupProductionPolicyPage;

import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { cloneDeep, isEmpty } from 'lodash';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { IPlanDetailsDefaultCommissionDrawerProps } from '.';
import {
  getDefaultCommissionEnums,
  updatePlanSpecialCommissions,
} from './queries';
import { LookupToList } from './utils';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { trimTrailingZeros } from '../../utils/formatting-utils';
import PrecisionService from '../../services/precisionService';

const PlanDetailsDefaultCommissionDrawer: React.FC<
  IPlanDetailsDefaultCommissionDrawerProps
> = ({ open, onSuccess, onClose, defaultCommissionDetailsInfo, planId }) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [defaultCommissionDetailsListResults] = useLazyQuery(
    getDefaultCommissionEnums()
  );

  const [booted, setBooted] = useState<boolean>(false);

  const [defaultCommissionAction] = useMutation(updatePlanSpecialCommissions());

  useEffect(() => {
    initialize();
  }, []);

  const loadLovList = async () => {
    const result = await defaultCommissionDetailsListResults({
      fetchPolicy: 'no-cache',
    });

    const newAdditionalInformationEnums = LookupToList(result.data);

    return newAdditionalInformationEnums;
  };

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      const lovData = await loadLovList();

      (
        updatedInputs.specialCommissionApplicable as IFormSelectDynamicProps
      ).selectOptions = lovData['specialCommissionApplicable'];

      (
        updatedInputs.specialCommissionType as IFormSelectDynamicProps
      ).selectOptions = lovData['commissionTypes'];

      (
        updatedInputs.specialCommissionValueCurrency as IFormSelectDynamicProps
      ).selectOptions = lovData['currencies'];

      (
        updatedInputs.specialCommissionApplicableOn as IFormSelectDynamicProps
      ).selectOptions = lovData['commissionApplicableOnOptions'];

      if (defaultCommissionDetailsInfo) {
        updatedInputs.specialCommissionApplicable.value =
          defaultCommissionDetailsInfo.specialCommissionApplicable
            ? 'Yes'
            : 'No';
        updatedInputs.specialCommissionType.value =
          defaultCommissionDetailsInfo.specialCommissionType || 'RATE';

        updatedInputs.specialCommissionValue.value = trimTrailingZeros(
          defaultCommissionDetailsInfo.specialCommissionValue
        );
        updatedInputs.specialCommissionValueCurrency.value =
          defaultCommissionDetailsInfo.specialCommissionValueCurrency ||
          '39735';
        updatedInputs.specialCommissionRate.value = `${trimTrailingZeros(
          PrecisionService.multiplyBy100(
            Number(defaultCommissionDetailsInfo.specialCommissionRate)
          )
        )}`;
        updatedInputs.specialCommissionApplicableOn.value =
          defaultCommissionDetailsInfo.specialCommissionApplicableOn;
      }

      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const isApplicable = data.specialCommissionApplicable === 'Yes';
      const isRate = data.specialCommissionType === 'RATE';

      const variablesMutation = {
        entityId: planId,
        planSpecialCommissionInputs: {
          specialCommissionApplicable: isApplicable,
          specialCommissionType: isApplicable
            ? data.specialCommissionType
            : null,
          specialCommissionValue: isApplicable
            ? isRate
              ? 0
              : trimTrailingZeros(data.specialCommissionValue)
            : null,
          specialCommissionValueCurrency: isApplicable
            ? isRate
              ? null
              : data.specialCommissionValueCurrency || '39735'
            : null,
          specialCommissionRate: isApplicable
            ? isRate
              ? PrecisionService.divideBy100(data.specialCommissionRate)
              : 0
            : null,
          specialCommissionApplicableOn: isApplicable
            ? data.specialCommissionApplicableOn
            : null,
        },
      };

      const res = await defaultCommissionAction({
        variables: variablesMutation,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            Commissions successfully updated.
          </ToastSuccessMessage>
        );

        setTimeout(() => {
          setSubmitButtonState('success');
          onSuccess();
          setFormDisabled(false);
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        setFormDisabled(false);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (err) {
      setSubmitButtonState(undefined);
      setFormDisabled(false);
      toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
    } finally {
      //
    }
  };

  return (
    <GenericDrawer
      title={'Modify Business Partner Default Commissions'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PlanDetailsDefaultCommissionDrawer;

import React, { useEffect, useState } from 'react';
import { IListingData, ListingDataItemType } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createActionAsAgent,
  filterLovsQuery,
  listQuery,
  planDetailsQuery,
} from './queries';
import { dataToLovs, mapToListingData } from './utils';
import { filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _ from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import ApplicationDrawer from '../../forms/application-drawer/ApplicationDrawer';
import {
  IEnhanceTableHeaderClickable,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { Link, useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import { DEFAULT_ERROR_TEXT, MAIN_ONE_THEME } from '../../constants';
import { makeStyles } from 'tss-react/mui';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { getError } from '../../utils/graph-utils';
import { useAppSelector } from '../../redux/hooks';
import ApplicationDrawerWithMultipleAgencies from '../../forms/application-with-multiple-agencies-drawer/ApplicationDrawerWithMultipleAgencies';
import Loader from '../../components/Loader';
import { isEmpty } from '../../utils/validationUtils';
import DateService from '../../services/dateService';

const useStyles = makeStyles()(() => ({
  linkHolder: {
    width: '130px',
    fontSize: '12px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  linkStyle: {
    color: MAIN_ONE_THEME.palette.primary5.main,
  },
}));

const ApplicationsPage: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [
    applicationWithMultipleAgencieDrawerOpen,
    setApplicationWithMultipleAgencieDrawerOpen,
  ] = useState<boolean>(false);
  const [agentType, setAgentType] = useState<string>('');

  const FILTER_SESSION_KEY = 'applicationsFilter';

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          agency: [],
          plan: [],
          effectiveDate: [],
          createdDate: [],
          status: ['QUOTE_PREPARATION', 'QUOTE_CONFIRMATION', 'CLOSED'],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [actions, setActions] = useState<IEnhancedTableMenuItem[]>([]);

  const [getApplicationsLazy] = useLazyQuery(listQuery());
  const [getLovsLazy] = useLazyQuery(filterLovsQuery(), {
    fetchPolicy: 'no-cache',
  });
  const [mutateFunction] = useMutation(createActionAsAgent());
  const [getPlanDetailsLazy] = useLazyQuery(planDetailsQuery());

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    filterV = filterValues
  ) => {
    setLoading(true);
    try {
      setAgentType(
        user.userRoles.includes('Insurance-Underwriter')
          ? 'underwriter'
          : user.userRoles.includes('Insurance-QuotingAgent') ||
              user.userRoles.includes('Insurance-IssuingAgent')
            ? 'agent'
            : ''
      );

      const [applicationsResult, lovsResult] = await Promise.all([
        getApplicationsLazy({
          variables: {
            currentPage: currentPage + 1,
            currentPageSize: pageSize,
            agencyId:
              filterV?.agency && filterV?.agency?.length > 0
                ? filterV?.agency
                : null,
            product:
              filterV?.plan && filterV?.plan?.length > 0 ? filterV?.plan : null,
            effectiveDateFrom: DateService.formatDateBackend(
              filterV.effectiveDate?.[0]
            ),
            effectiveDateTo: DateService.formatDateBackend(
              filterV.effectiveDate?.[1]
            ),
            createdDateFrom: DateService.formatDateBackend(
              filterV.createdDate?.[0]
            ),
            createdDateTo: DateService.formatDateBackend(
              filterV.createdDate?.[1]
            ),
            selectedStatuses:
              filterV?.status && filterV?.status?.length > 0
                ? filterV?.status
                : null,
          },
          fetchPolicy: 'no-cache',
        }),
        getLovsLazy(),
      ]);

      if (applicationsResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }
      if (lovsResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }

      const data = applicationsResult.data;
      const lovsData = lovsResult.data;

      if (data) {
        const newTableData = mapToListingData(data);
        convertToPolicyNumberJsxElement(newTableData.pagedItems);
        setTableData({ ...newTableData, pageNumber: currentPage, pageSize });

        // Set actions based on data
        let newActions: IEnhancedTableMenuItem[] = [];
        if (data?.Insurance?.actions?.able_To_CreateApplicationAsAgent) {
          newActions = [
            {
              title: '+ New',
              onClick: () => {
                user.info.agencyId.length === 0 ||
                user.info.agencyId.length === 1
                  ? submitForm()
                  : setApplicationWithMultipleAgencieDrawerOpen(true);
              },
              isEntity: false,
              isBulk: false,
              iconUrl: '',
            },
          ];
        } else if (
          data?.Insurance?.actions?.able_To_CreateApplicationAsUnderwriter
        ) {
          newActions = [
            {
              title: '+ New',
              onClick: () => {
                user.info.agencyId.length === 0 ||
                user.info.agencyId.length === 1
                  ? setDrawerOpen(true)
                  : setApplicationWithMultipleAgencieDrawerOpen(true);
              },
              isEntity: false,
              isBulk: false,
              iconUrl: '',
            },
          ];
        }
        setActions(newActions);

        // Update header callbacks
        (headers.name as IEnhanceTableHeaderClickable).callback = (
          payload: any
        ) => {
          navigate('/sales/applications/motor/' + payload.columns.id);
        };

        headers.plan.tooltipSettings.tooltipFetch = handleTooltipFetch;
      }

      if (lovsData) {
        const savedFilters = getFilter(FILTER_SESSION_KEY) || filterV;
        setFilterValues(savedFilters);
        setFilter(savedFilters, FILTER_SESSION_KEY);

        const lovs = dataToLovs(lovsData);
        const newFilterSections = filterSectionsContent(
          lovs,
          savedFilters,
          user.userRoles
        );
        setFilterSections(newFilterSections);
      }
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    await loadData(0, tableData.pageSize, filterValues);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = async (page: number) => {
    await loadData(page, tableData.pageSize, filterValues);
  };

  const handleRowsPerPageChange = async (numberOfRecordsPerPage: number) => {
    await loadData(0, numberOfRecordsPerPage, filterValues);
  };

  const submitForm = async () => {
    try {
      const { data, errors } = await mutateFunction({
        variables: { lineOfBusiness: 'Motor' },
      });
      if (isEmpty(errors)) {
        toast.success(
          <ToastSuccessMessage>
            {'Application created successfully'}
          </ToastSuccessMessage>
        );
        const newApplicationId =
          data.insurance.actions.createApplicationAsAgent.id;
        setTimeout(() => {
          navigate('/sales/applications/motor/' + newApplicationId);
        }, 500);
      } else {
        toast.error(<ToastErrorMessage>{getError(data)}</ToastErrorMessage>);
      }
    } catch {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const handleTooltipFetch = async (
    id: string
  ): Promise<Record<string, any>> => {
    const popoverRows: Record<string, any> = {};
    try {
      const response = await getPlanDetailsLazy({
        variables: { id },
      });
      response?.data?.Insurance?.entities?.application?.queries?.insurance?.applicationQuotes.forEach(
        (obj: any, i: number) => {
          popoverRows[i] = {
            Plan: obj?.insuranceQuote_Product?.Title || 'N/A',
            Coverage: obj?.insuranceQuoteMotorDetails_SumInsured,
            TotalPremium: obj?.insuranceQuote_TotalAnnualPremium,
            Status:
              capitalizeFirstLetter(obj?.insuranceQuote_Status, '_') || 'N/A',
            StatusReason:
              capitalizeFirstLetter(obj?.insuranceQuote_StatusReason, '_') ||
              'N/A',
          };
        }
      );
    } catch (error) {
      console.error('Error fetching tooltip data:', error);
    }
    return popoverRows;
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    if (_.isEqual(newFilters, filterValues)) {
      return;
    }
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    await loadData(0, tableData.pageSize, newFilters);
  };

  const renderPolicyNumberLinks = (data: any): React.ReactNode => {
    return data?.map((element: { id: string; title: string }) => (
      <div key={element.id} className={classes.linkHolder}>
        <Link
          to={`/plm/policies/` + element.id}
          className={classes.linkStyle}
          target="_blank"
        >
          {element.title}
        </Link>
      </div>
    ));
  };

  const convertToPolicyNumberJsxElement = (data: ListingDataItemType<any>) => {
    Object.keys(data).forEach((id, index) => {
      if (data[index]) {
        data[index].policyNumber = renderPolicyNumberLinks(
          data[index]?.policyNumber
        );
      }
    });
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: '20px' }}>
      <EnhancedTable
        title="Motor Applications"
        name="count"
        orderByAscendingByDefault
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        currentPage={tableData.pageNumber}
        hideToolbar
        usePagination
        disableSelection
        actions={actions}
        loader={loading}
      />
      {drawerOpen && (
        <ApplicationDrawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          onSuccess={() => {
            setDrawerOpen(false);
            handlePageChange(0);
          }}
        />
      )}
      {applicationWithMultipleAgencieDrawerOpen && (
        <ApplicationDrawerWithMultipleAgencies
          open={applicationWithMultipleAgencieDrawerOpen}
          loggedInAgentId={user.info.agentId}
          loggedInuserFirstName={user.info.firstName}
          loggedInuserLastName={user.info.lastName}
          lineOfBusiness={'Motor'}
          agentType={agentType}
          onClose={() => setApplicationWithMultipleAgencieDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0);
          }}
        />
      )}
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name={''}
        disabled={loading}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      ></ListingFilterWidget>
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name={'Applications'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ApplicationsPage;

import PrecisionService from '../../../services/precisionService';
import { trimTrailingZeros } from '../../../utils/formatting-utils';
import { IProposalDetailsSummary } from './index2';

export interface IPayerData {
  payerId: string;
  fullName?: string;
  payerAddress: string;
  ownerIsDifferent: boolean;
  addressDetails?: string;
}

export interface IOwnerData {
  ownerId?: string;
  fullName?: string;
  ownerAddress?: string;
  addressDetails?: string;
}

export interface IInsuredData {
  id: string;
  order: string;
  fullName?: string;
  address?: string;
  addressDetails?: string;
  limitOfCover?: string;
}

export interface IBeneficiaryData {
  id: string;
  address: string;
  percentage: number;
  fullName?: string;
  addressDetails?: string;
}

export interface IPolicyPersons {
  payer: IPayerData;
  owner?: IOwnerData;
  insured: IInsuredData[];
  beneficiaries: IBeneficiaryData[];
}

export interface IProposalPageFormData {
  policyPersons: {
    payer: IPayerData;
    owner?: IOwnerData;
    insured: IInsuredData[];
    beneficiaries: IBeneficiaryData[];
  };
  covers: IProposalCover[];
  costs: IProposalCostCharges;
  motorDetails: IProposalMotorDetails;
}

export interface IProposalCover {
  isMain: boolean;
  isMandatory: boolean;
  isEditable: boolean;
  policyCover: string;
  coverPremiumType: string;
  coverPremiumPercentage?: number;
  coverPremiumValue?: number;
  currency: string;
  coverSumInsured?: number;
  sumInsuredIsAdditive: boolean;
  excessOnClaimType: string;
  excessOnClaimAmount?: number;
  excessOnClaimPercentage?: number;
  excessOnClaimDays?: number;
  name?: string;
  clauseID: string;
  coverEffectiveFrom: string;
  coverEffectiveTo: string;
}

export interface IProposalCostCharges {
  policyCost: number;
  chargesPercentage: number;
  tpaFeesType: string;
  tpaFeesAmount?: number;
  tpaFeesPercentage?: number;
  tpaFeesApplicableOn: string;
}

export interface IProposalMotorDetails {
  nameOnLicenseEn: string;
  nameOnLicenseAr: string;
  vignetteCode: string;
  vignette: number;
  plateCode: string;
  plateNumber: number;
  usageType: string;
  bodyType: string;
  engineType: string;
  brand: string;
  model: string;
  color: string;
  yearOfMake: number;
  motor: string;
  weight: number;
  engineSize: number;
  seats: number;
  chassis: string;
  horsePower: number;
  carValue: number;
  agencyRepairYear: number;
  depreciationYears: number;
}

export interface IProposalPageFormState {
  values: IProposalPageFormData;
  errors: {
    policyPersons: {
      payer: Record<string, string>;
      owner: Record<string, string>;
      insured: Record<string, string>[];
      beneficiaries: Record<string, string>[];
    };
    covers: Record<string, string>[];
    costs: Record<string, string>;
    motorDetails: Record<string, string>;
  };
  touched: {
    policyPersons: {
      payer: Record<string, boolean>;
      owner: Record<string, boolean>;
      insured: Record<string, boolean>[];
      beneficiaries: Record<string, boolean>[];
    };
    covers: Record<string, boolean>[];
    costs: Record<string, boolean>;
    motorDetails: Record<string, boolean>;
  };
}

export const getProposalPageFormState = (
  summary?: IProposalDetailsSummary,
  covers: IProposalCover[] = [],
  policyPersons?: IPolicyPersons
): IProposalPageFormState => ({
  values: {
    policyPersons,
    covers: covers,
    costs: {
      policyCost: trimTrailingZeros(summary?.PricingOption.PolicyCost),
      chargesPercentage: PrecisionService.multiplyBy100(
        summary?.PricingOption.ChargesPercentage
      ),
      tpaFeesType: summary?.PricingOption.TPAFeesType,
      tpaFeesAmount: trimTrailingZeros(summary?.PricingOption.TPAFeesAmount),
      tpaFeesPercentage: PrecisionService.multiplyBy100(
        summary?.PricingOption.TPAFeesPercentage
      ),
      tpaFeesApplicableOn: summary?.PricingOption.TPAFeesApplicableOn,
    },
    motorDetails: {
      nameOnLicenseEn: summary?.NameOnLicenseEn,
      nameOnLicenseAr: summary?.NameOnLicenseAr,
      vignetteCode: summary?.PolicyMotor?.VignetteCode,
      vignette: summary?.PolicyMotor?.Vignette,
      plateCode: summary?.Vehicle?.PlateCode,
      plateNumber: summary?.Vehicle?.PlateNumber,
      usageType: summary?.Vehicle?.UsageType,
      bodyType: summary?.Vehicle?.BodyType,
      engineType: summary?.Vehicle?.EngineType,
      brand: summary?.Vehicle?.Brand,
      model: summary?.Vehicle?.Model,
      color: summary?.Vehicle?.Color,
      yearOfMake: summary?.Vehicle?.YearOfMake,
      motor: summary?.Vehicle?.Motor,
      weight: summary?.Vehicle?.Weight,
      engineSize: summary?.Vehicle?.EngineSize,
      seats: summary?.Vehicle?.Seats,
      chassis: summary?.Vehicle?.Chassis,
      horsePower: summary?.Vehicle?.HorsePower,
      carValue: summary?.Vehicle?.CarValue,
      agencyRepairYear: summary?.PolicyMotor?.AgencyRepairYear,
      depreciationYears: summary?.PolicyMotor?.DepreciationYears,
    },
  },
  errors: {
    policyPersons: {
      payer: {
        payerId: '',
        payerAddress: '',
        ownerIsDifferent: '',
      },
      owner: {
        ownerId: '',
        ownerAddress: '',
      },
      insured: [
        {
          id: '',
          order: '',
          address: '',
        },
      ],
      beneficiaries: [
        {
          id: '',
          address: '',
          percentage: '',
        },
      ],
    },
    covers: covers.map((c) => ({
      isMain: '',
      isMandatory: '',
      coverPremiumType: '',
      coverPremiumPercentage: '',
      coverPremiumValue: '',
      currency: '',
      coverSumInsured: '',
      sumInsuredIsAdditive: '',
      excessOnClaimType: '',
      excessOnClaimAmount: '',
      excessOnClaimPercentage: '',
      excessOnClaimDays: '',
      coverEffectiveFrom: '',
      coverEffectiveTo: '',
    })),
    costs: {},
    motorDetails: {},
  },
  touched: {
    policyPersons: {
      payer: {
        payerId: false,
        payerAddress: false,
        ownerIsDifferent: false,
      },
      owner: {
        ownerId: false,
        ownerAddress: false,
      },
      insured: [
        {
          id: false,
          order: false,
          address: false,
        },
      ],
      beneficiaries: [
        {
          id: false,
          address: false,
          percentage: false,
        },
      ],
    },
    covers: covers.map((_c) => ({
      isMain: false,
      isMandatory: false,
      coverPremiumType: false,
      coverPremiumPercentage: false,
      coverPremiumValue: false,
      currency: false,
      coverSumInsured: false,
      sumInsuredIsAdditive: false,
      excessOnClaimType: false,
      excessOnClaimAmount: false,
      excessOnClaimPercentage: false,
      excessOnClaimDays: false,
      coverEffectiveFrom: false,
      coverEffectiveTo: false,
    })),
    costs: {},
    motorDetails: {},
  },
});

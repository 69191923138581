import React, { useEffect, useState, useMemo } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery, useQuery } from '@apollo/client';
import { filterQuery, listQuery } from './queries';
import { dataToLovs, mapToListingData } from './utils';
import { filterSectionsContent, headers } from './content';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { isEqual } from 'lodash';
import ListingWidget from '../../components/form-fields/listing-widget/ListingWidget';
import { IFilterModel } from '../../components/form-fields/listing-widget';
import { IEnhancedMenuItem } from '../../components/form-fields/table';
import { useNavigate } from 'react-router';
import DateService from '../../services/dateService';

const FILTER_SESSION_KEY = 'currencyExchangeRatesFilter';

const CurrencyExchangeRatesPage: React.FC = () => {
  const navigate = useNavigate();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          currency: [],
          date: null,
        };

  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialfilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
  });

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [getListLazy, { loading: loadingTable }] = useLazyQuery(listQuery());
  const filterResponse = useQuery(filterQuery());

  const actions: IEnhancedMenuItem[] = useMemo(
    () => [
      {
        name: 'new',
        title: '+ New',
        onClick: () => {
          navigate(`/configuration/currency-exchange-rate/add-ons`);
        },
      },
    ],
    []
  );

  const initialize = async () => {
    try {
      const { data, error } = await filterResponse.refetch();
      if (error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const savedFilters =
        Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
          ? getFilter(FILTER_SESSION_KEY)
          : filterValues.namedFilters;

      const lovs = dataToLovs(data);
      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);

      const updatedFilterValues: IFilterModel = {
        ...filterValues,
        namedFilters: savedFilters,
      };
      setFilterValues(updatedFilterValues);
      await getListingData(updatedFilterValues);
    } catch (error) {
      console.error('Error initializing data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListingData = async (filter: IFilterModel) => {
    const filterV = filter.namedFilters;
    const pagination = filter.pagination;

    try {
      const { data, error } = await getListLazy({
        variables: {
          currentPage: pagination.pageNumber,
          currentPageSize: pagination.pageSize,
          selectedCurrencies:
            filterV?.currency && filterV.currency.length > 0
              ? filterV.currency
              : [],
          selectedDate: DateService.formatDateBackend(filterV?.date),
          isSelectedDate: DateService.isValidDate(filterV?.date) ? true : false,
        },
      });

      if (error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }
      setFilterValues(filter);
      const mappedList = mapToListingData(data);
      setTableData(mappedList);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const handlePageChange = (page: number, filterModel = filterValues) => {
    const newFilterModel = {
      ...filterModel,
      pagination: {
        ...filterModel.pagination,
        pageNumber: page,
      },
    };
    return getListingData(newFilterModel);
  };

  const onFilterUpdate = async (filters: IAbstractRecord) => {
    if (isEqual(filters, filterValues.namedFilters)) {
      return;
    }
    setFilter(filters, FILTER_SESSION_KEY);
    const newFilters: IFilterModel = {
      ...filterValues,
      namedFilters: filters,
      pagination: {
        ...filterValues.pagination,
        pageNumber: 1,
      },
    };
    setFilterValues(newFilters);
    getListingData(newFilters);
  };

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  const renderMainChildren = () => (
    <>
      <ListingWidget
        title="Currency Exchange Rate"
        name="currencyExchangeRate"
        data={tableData}
        tableSettings={{ headers }}
        onPageChange={(filterModel) => {
          const newFilterModel = { ...filterValues, ...filterModel };
          const page = filterModel.pagination.pageNumber;
          return handlePageChange(page, newFilterModel);
        }}
        usePagination
        disableSelection
        disabledSorting
        actions={actions}
        loading={loadingTable}
      />
    </>
  );

  return (
    <StaticLayout
      name="Currency Exchange Rates"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default CurrencyExchangeRatesPage;

export interface IFileCardProps {
  cardIconDescription?: string;
  title: string;
  createdOn?: string;
  revisedOn?: string;
  iconUrl?: string;
  version?: number;
  description?: string;
  hideReviewedLabel?: boolean;
  wrapDocumentsTitle?: boolean;
  hideStatusLabel?: boolean;
  status: FileUploadStatus;
  fileInfo: IFile;
  allowedFileTypes: FileType[];
  progress?: number;
  hideDownloadAction?: boolean;
  hideUploadAction?: boolean;
  entityTypeId: string;
  entityId: string;
  entityViewId: string;
  propertyId: string;
}

export interface IFile {
  id: string;
  location: string;
  path: string;
  fileName: string;
  length: number;
  contentType: string;
}

export interface IDocument {
  id: string;
  code: string;
  version: number;
  createdOn: string;
  file: IFile;
}

export enum FileUploadStatus {
  Missing = 'Missing',
  Accepted = 'Accepted',
  Uploaded = 'Uploaded',
  Uploading = 'Uploading',
  Rejected = 'Rejected',
}

export enum FileType {
  jpg = 'jpg',
  jpeg = 'jpeg',
  png = 'png',
  xls = 'xls',
  xlsx = 'xlsx',
  csv = 'csv',
  doc = 'doc',
  docx = 'docx',
  dotx = 'dotx',
  pdf = 'pdf',
  dxf = 'dxf',
  dwf = 'dwf',
  gif = 'gif',
  msg = 'msg',
  eml = 'eml',
  svg = 'svg',
  wav = 'wav',
  mp3 = 'mp3',
  aac = 'aac',
}

export const FILE_TYPES_META: Record<FileType, IFileType> = {
  jpg: { mimetype: 'image/jpeg', extension: '.jpg' },
  jpeg: { mimetype: 'image/jpeg', extension: '.jpeg' },
  png: { mimetype: 'image/png', extension: '.png' },
  xls: { mimetype: 'application/vnd.ms-excel', extension: '.xls' },
  svg: { mimetype: 'image/svg+xml', extension: '.svg' },
  xlsx: {
    mimetype:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: '.xlsx',
  },
  csv: { mimetype: 'text/csv', extension: '.csv' },
  doc: { mimetype: 'application/msword', extension: 'doc' },
  docx: {
    mimetype:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extension: '.docx',
  },
  dotx: {
    mimetype:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    extension: '.dotx',
  },
  pdf: { mimetype: 'application/pdf', extension: '.pdf' },
  dxf: { mimetype: 'application/dxf', extension: '.dxf' },
  dwf: { mimetype: 'model/vnd.dwf', extension: '.dxf' },
  gif: { mimetype: 'image/gif', extension: '.gif' },
  msg: { mimetype: 'application/vnd.ms-outlook', extension: '.msg' },
  eml: { mimetype: 'message/rfc822', extension: '.eml' },
  wav: { mimetype: 'audio/wav', extension: '.wav' },
  mp3: { mimetype: 'audio/mp3', extension: '.mp3' },
  aac: { mimetype: 'audio/aac', extension: '.aac' },
};

export const ALLOWED_TYPE_REGEX = /.msg$/im;

export interface IFileType {
  mimetype: string;
  extension: string;
}

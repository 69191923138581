import React, { useState } from 'react';
import { IListingData } from '../../../models/listing';
import StaticLayout from '../../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { listQuery } from './queries';
import { mapToListingData, toLookupsData } from './utils';
import { filterSectionsContent, headers } from './content';
import { IListingFilterWidgetSection } from '../../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../../models';
import _ from 'lodash';
import { getFilter, setFilter } from '../../../utils/filter-utils';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { DEFAULT_ERROR_TEXT } from '../../../constants';
import TransferDrawer from '../../../forms/transfers-drawer/TransferDrawer';
import ListingWidget from '../../../components/form-fields/listing-widget/ListingWidget';
import { IEnhancedMenuItem } from '../../../components/form-fields/table';
import { IFilterModel } from '../../../components/form-fields/listing-widget';
import DateService from '../../../services/dateService';

const PAGE_CONTEXT = 'Transfers';
const FILTER_SESSION_KEY = 'transfersFilter';

const TransfersPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [transfersDrawerOpen, setTransfersDrawerOpen] =
    useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>('');
  const actions: IEnhancedMenuItem[] = [
    {
      name: 'newtransfer',
      title: '+ New',
      onClick: () => {
        setChosenEditId(undefined);
        setTransfersDrawerOpen(true);
      },
    },
  ];

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY).namedFilters
      : {
          createdOn: [],
          company: '41',
          Type: '',
        };

  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialFilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
    orderBy: 'Accounting_AccountingTransfers_TransferNumber',
    descending: false,
  });

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [getTransfersLazy] = useLazyQuery(listQuery());

  const getListingData = (filter = filterValues) => {
    if (!filter) {
      setLoading(false);
      return {};
    }
    setFilter(filter, FILTER_SESSION_KEY);
    setFilterValues(filter);

    const filterV = filter.namedFilters;
    const pagination = filter.pagination;

    setLoading(true);
    return getTransfersLazy({
      variables: {
        SelectedCompany:
          filterV.company && filterV.company?.length > 0
            ? filterV.company
            : '41',
        TransferFromDate: DateService.formatDateBackend(filterV.createdOn?.[0]),
        TransferToDate: DateService.formatDateBackend(
          DateService.addDays(filterV.createdOn?.[1], 1)
        ),

        TransferType: filterV.Type ? filterV.Type : null,
        KeywordSearch: filter.searchKey,
        OrderByField:
          filter.orderBy || 'Accounting_AccountingTransfers_TransferNumber',
        DescendingField: filter.descending,
        pageNumber: pagination.pageNumber || 1,
        pageSize: pagination.pageSize || 10,
      },
      fetchPolicy: 'no-cache',
    })
      .then(({ data }) => {
        if (data) {
          // Update table data
          const newTableData = mapToListingData(data);
          setTableData(newTableData);

          // Update filters
          const lovs = toLookupsData(data);

          if (!Object.keys(lovs.company).length) {
            lovs.company = filterV?.company;
          }

          if (!Object.keys(lovs.Type).length) {
            lovs.Type = filterV?.Type;
          }

          const newFilterSections = filterSectionsContent(lovs, filterV);
          setFilterSections(newFilterSections);
          setFilterValues(filter);
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = async (
    page: number,
    filterModel = filterValues
  ): Promise<void> => {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  };

  const onFilterUpdate = (filters: IAbstractRecord) => {
    const newFilters = {
      ...filterValues,
      namedFilters: filters,
    };
    if (_.isEqual(newFilters, filterValues)) {
      // Do nothing if filters are the same
      return;
    }

    getListingData(newFilters);
  };

  const renderMainChildren = () => (
    <>
      <ListingWidget
        title="Transfers"
        name="count"
        orderByAscendingByDefault
        defaultOrderByColumn="Accounting_AccountingTransfers_TransferNumber"
        inline={false}
        data={tableData}
        tableSettings={{ headers }}
        actions={actions}
        loading={loading}
        pageContext={PAGE_CONTEXT}
        onPageChange={(filterModel) => {
          const newFilterModel = {
            ...filterValues,
            ...filterModel,
          };
          const page = filterModel.pagination.pageNumber;
          return handlePageChange(page, newFilterModel);
        }}
        disableSelection
        usePagination
      />
      {transfersDrawerOpen && (
        <TransferDrawer
          open={transfersDrawerOpen}
          onClose={() => setTransfersDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0);
          }}
          transferId={chosenEditId}
          transferInfo={undefined}
        />
      )}
    </>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        disabled={loading}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  return (
    <StaticLayout
      name={'Transfers'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default TransfersPage;

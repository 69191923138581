import { gql } from '@apollo/client';

export function getPolicyPersonEnums() {
  return gql`
    query getPolicyPersonEnums {
      SalesforceManagement_PersonTypes: __type(
        name: "SalesforceManagement_PersonTypes"
      ) {
        name
        enumValues {
          name
          __typename
        }
        __typename
      }
      Insurance_PhoneType: __type(name: "Insurance_PhoneType") {
        name
        enumValues {
          name
        }
      }
      Insurance_Genders: __type(name: "Insurance_Genders") {
        name
        enumValues {
          name
        }
      }
      Core_AddressTypes: __type(name: "Core_AddressTypes") {
        name
        enumValues {
          name
        }
      }
      Insurance {
        lookups {
          titles {
            Id
            Title
          }
          nationalities {
            Id
            Title
          }
          occupationClasses {
            Id
            Title
          }
          countries {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getPersonInfo() {
  return gql`
    query getPersonInfo($id: String!) {
      SalesforceManagement {
        entities {
          person(id: $id) {
            id
            views {
              SalesforceManagement_all {
                properties {
                  PersonType
                  CompanyName
                  Title {
                    Code
                    Title
                  }
                  FirstName
                  MiddleName
                  LastName
                  DateOfBirth
                  Email
                  PhoneDetails {
                    PhoneType
                    PhoneNumber
                  }
                  NationalityOne {
                    Id
                    Code
                    Title
                  }
                  IsTaxable
                  OccupationClass {
                    Id
                    Code
                    Title
                  }
                  Profession
                  Employer
                  DateOfEmployment
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function modifyPerson() {
  return gql`
    mutation modifyPerson(
      $type: Production_PolicyPersonTypes
      $entityId: String!
      $personDetailsInputs: SalesforceManagement_PersonInitialDetailsInputs!
      $personQuickCreateInputs: SalesforceManagement_PersonQuickCreateInputs
      $dateOfBirth: Date
      $personWorkInputs: SalesforceManagement_PersonWorkdetailsInputs
    ) {
      production {
        entities {
          policy {
            production {
              modifyPerson(
                entityId: $entityId
                personDetailsInputs: $personDetailsInputs
                personQuickCreateInputs: $personQuickCreateInputs
                dateOfBirth: $dateOfBirth
                personWorkInputs: $personWorkInputs
                type: $type
              )
            }
          }
        }
      }
    }
  `;
}

export function getZonesByCountry() {
  return gql`
    query getZonesByCountry($countryID: String!) {
      Insurance {
        lookups {
          zones(Insurance_Countries: $countryID) {
            Id
            Title
          }
        }
      }
    }
  `;
}

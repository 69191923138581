import _ from 'lodash';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { IFormState } from '../form';
import { IMarineGroupCertficateForm } from '..';
import WidgetPaper from '../../../../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../../../../components/common/WidgetSection';
import EnhancedChipInput from '../../../../../../../../components/enhanced-form/EnhancedChipInput';
import EnhancedDatePicker from '../../../../../../../../components/enhanced-form/EnhancedDatePicker';
import {
  updateFormField,
  ValueOf,
} from '../../../../../../../../utils/helper-utils';
import { validatePolicyWidgetField } from '../validation';
import { useMutation } from '@apollo/client';
import { calculateMarineBasicRate } from '../queries';
import EnhancedFormattedNumberInput from '../../../../../../../../components/enhanced-form/EnhancedFormattedNumberInput';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../../../../utils/graph-utils';

interface IMarineGroupPolicyDetailsWidgetProps {
  pageState: IFormState;
  policyCurrency: string;
  onPageStateUpdate: (pageState: IFormState) => void;
  onSublineSelect: (pageState: IFormState) => void;
  onPlanSelect: (pageState: IFormState) => void;
  disabledForm?: boolean;
  lovs?: Record<string, Record<string, string>>;
  loader?: boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, 32%)`,
    gap: '2%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
}));

const MarineGroupPolicyDetailsWidget: React.FC<
  IMarineGroupPolicyDetailsWidgetProps
> = ({
  pageState,
  policyCurrency,
  onPageStateUpdate,
  onSublineSelect,
  onPlanSelect,
  disabledForm,
  lovs = {},
  loader = false,
}) => {
  const { classes } = useStyles();

  const values = pageState.values.policyDetails;
  const errors = pageState.errors.policyDetails;
  const touched = pageState.touched.policyDetails;
  const [calculateBasicRateAction] = useMutation(calculateMarineBasicRate());

  const onFieldBlur = (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.policyDetails[fieldName] = true;

    if (fieldName === 'plan') {
      const planCurrency = newPageState.values.policyDetails.planCurrency;
      if (!planCurrency || !policyCurrency) {
        newPageState.values.policyDetails.planToPolicyRate = 0;
        onPageStateUpdate(newPageState);
        return;
      }
      // If same currency, rate=1
      if (planCurrency === policyCurrency) {
        newPageState.values.policyDetails.planToPolicyRate = 1;
        onPageStateUpdate(newPageState);
        return;
      }
      // Otherwise, call the mutation
      calculateBasicRateAction({ variables: { currency: planCurrency } })
        .then(({ data: planData }) => {
          const planToBasicRate =
            planData?.production?.actions?.calculateBasicRate?.BasicRate;
          return calculateBasicRateAction({
            variables: { currency: policyCurrency },
          }).then(({ data: policyData }) => {
            const policyToBasicRate =
              policyData?.production?.actions?.calculateBasicRate?.BasicRate;
            newPageState.values.policyDetails.planToPolicyRate =
              planToBasicRate / policyToBasicRate;
            onPageStateUpdate(newPageState);
          });
        })
        .catch((err) => {
          newPageState.values.policyDetails.planToPolicyRate = 0;
          toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
          onPageStateUpdate(newPageState);
        });
      return;
    }

    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IMarineGroupCertficateForm['policyDetails'],
    value: ValueOf<IMarineGroupCertficateForm['policyDetails']>,
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values.policyDetails = updateFormField(
      newPageState.values.policyDetails,
      fieldName,
      value
    );

    if (touched) {
      newPageState.touched.policyDetails[fieldName] = true;
    }

    const isValid = validatePolicyWidgetField(fieldName, value);
    newPageState.errors.policyDetails[fieldName] = isValid;

    if (fieldName === 'subline') {
      newPageState.values.policyDetails.plan = undefined;
      newPageState.errors.policyDetails.plan = '';
      newPageState.touched.policyDetails.plan = false;
      onSublineSelect(newPageState);
    } else if (fieldName === 'plan') {
      onPlanSelect(newPageState);
    } else {
      if (fieldName === 'policyEffectiveDate') {
        newPageState.values.covers = newPageState.values.covers.map(
          (cover) => ({
            ...cover,
            coverEffectiveFrom: value as string,
          })
        );
        newPageState.touched.policyDetails[fieldName] = true;
      } else if (fieldName === 'policyExpiryDate') {
        newPageState.values.covers = newPageState.values.covers.map(
          (cover) => ({
            ...cover,
            coverEffectiveTo: value as string,
          })
        );
        newPageState.touched.policyDetails[fieldName] = true;
      }
      onPageStateUpdate(newPageState);
    }
  };

  return (
    <WidgetPaper>
      <WidgetSection title="Policy Details" useSeparator={false}>
        <div className={classes.fieldRow}>
          <EnhancedChipInput
            key="subline"
            name="subline"
            title="Subline*"
            placeholder="Subline"
            value={values.subline}
            error={touched.subline ? errors.subline : ''}
            onChange={(v) => onFieldUpdate('subline', v)}
            onBlur={() => onFieldBlur('subline')}
            disabled={loader || disabledForm}
            selectOptions={lovs.subline}
            required
            multiple={false}
            material
            canClearSingleValueSelection={false}
            customStyles={{ labelStyles: classes.labelHeader }}
          />

          <EnhancedChipInput
            key="plan"
            name="plan"
            title="Plan*"
            placeholder="Plan"
            value={values.plan}
            error={touched.plan ? errors.plan : ''}
            onChange={(v) => onFieldUpdate('plan', v)}
            onBlur={() => onFieldBlur('plan')}
            disabled={!values.subline || disabledForm}
            selectOptions={lovs.plan}
            required
            multiple={false}
            material
            loader={loader}
            canClearSingleValueSelection={false}
            customStyles={{ labelStyles: classes.labelHeader }}
          />

          <EnhancedDatePicker
            key="policyEffectiveDate"
            name="policyEffectiveDate"
            title="Policy Effective Date*"
            placeholder="Policy Effective Date"
            value={values.policyEffectiveDate}
            loader={loader}
            error={
              touched.policyEffectiveDate ? errors.policyEffectiveDate : ''
            }
            minDate={new Date(values.effectiveDate)}
            onDateChange={(v) => {
              values.policyEffectiveDate = v.toLocaleString();
              onFieldUpdate('policyEffectiveDate', v.toLocaleString(), true);
            }}
            onBlur={() => undefined}
            disabled={disabledForm}
            material
            customStyles={{
              useWrapperLabel: true,
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedDatePicker
            key="policyEndDate"
            name="policyEndDate"
            title="Policy End Date"
            placeholder="Policy End Date"
            value={values.policyExpiryDate}
            maxDate={new Date(values.expiryDate)}
            error={touched.policyExpiryDate ? errors.policyExpiryDate : ''}
            loader={loader}
            onDateChange={(v) => {
              values.policyExpiryDate = v.toLocaleString();
              onFieldUpdate('policyExpiryDate', v.toLocaleString(), true);
            }}
            onBlur={() => undefined}
            disabled={disabledForm}
            material
            customStyles={{
              useWrapperLabel: true,
              labelStyles: classes.labelHeader,
            }}
          />
        </div>

        <div className={classes.fieldRow}>
          <EnhancedFormattedNumberInput
            key="planToPolicyRate"
            name="planToPolicyRate"
            title="Plan To Policy Rate"
            placeholder="Plan To Policy Rate"
            value={values.planToPolicyRate || 0}
            error={touched.planToPolicyRate ? errors.planToPolicyRate : ''}
            disabled
            material
            maxDecimalPercision={3}
            customStyles={{ labelStyles: classes.labelHeader }}
            onChange={() => {}}
          />
        </div>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default MarineGroupPolicyDetailsWidget;

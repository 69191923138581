import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';
import EnhancedIcon from './EnhancedIcon';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../../constants';

interface IEnhanceIconButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title: string;
  iconUrl: string;
  iconOnly?: boolean;
  hasBackground?: boolean;
  customTooltips?: boolean;
  customTooltipActive?: string;
  customTooltipDisabled?: string;
}

const useStyles = makeStyles()(() => ({
  button: {
    margin: '0.5em',
    border: 'none',
    background: 'unset',
    cursor: 'pointer',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.4,
    },
    fontFamily: `${contentFontFamilyRegular} !important`,
  },
  text: {
    margin: '0.5em auto 0',
    display: 'block',
    color: MAIN_ONE_THEME.palette.primary3.main,
    font: 'normal 11px/12px',
    fontFamily: contentFontFamilyRegular,
    textAlign: 'center',
  },
}));

const EnhancedIconButton: React.FC<IEnhanceIconButtonProps> = ({
  className,
  title,
  iconUrl,
  iconOnly,
  hasBackground,
  customTooltips = false,
  customTooltipActive = '',
  customTooltipDisabled = '',
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <button
      className={clsx(classes.button, className)}
      type="button"
      {...props}
    >
      {iconOnly ? (
        <Tooltip
          title={
            customTooltips
              ? props.disabled
                ? customTooltipDisabled
                : customTooltipActive
              : title
          }
        >
          <span>
            <EnhancedIcon
              title={title}
              hasBackground={hasBackground}
              iconUrl={iconUrl}
            />
          </span>
        </Tooltip>
      ) : (
        iconUrl !== '' && (
          <EnhancedIcon
            title={title}
            hasBackground={hasBackground}
            iconUrl={iconUrl}
          />
        )
      )}
      {!iconOnly && <span className={classes.text}>{title}</span>}
    </button>
  );
};

export default EnhancedIconButton;

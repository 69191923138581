import React, { useEffect, useMemo, useRef, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import Loader from '../../components/Loader';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import {
  createAgent,
  getAgentInfo,
  getAgentLookups,
  getAgentPlansLookups,
  getAgentProcuctsLookups,
  updateAgent,
} from './queries';
import {
  graphqlEntityToAgentInfo,
  lookupToList,
  toAgenciesTypes,
} from './utils';
import DynamicForm from '../../DynamicForm/DynamicForm';
import _ from 'lodash';
import { getInputs, HEADQUARTER, initialValues } from './content';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import { isEmpty } from '../../utils/validationUtils';
import { getError } from '../../utils/graph-utils';
import DateService from '../../services/dateService';

const AgentDrawer: React.FC<IAgentDrawerProps> = ({
  agentId,
  open,
  onSuccess,
  onClose,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [loading, setLoading] = useState<boolean>(true);

  const [values, setValues] = useState<Record<string, any>>();
  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({});

  const [agentLookupsLazy] = useLazyQuery(getAgentLookups());
  const [agentInfoLazy] = useLazyQuery(getAgentInfo(), {
    variables: { id: agentId },
  });

  const [isHeadquarter, setIsHeadquater] = useState<boolean>(false);

  const [agentProductsQuery] = useLazyQuery(getAgentProcuctsLookups());
  const [agentPlansQuery] = useLazyQuery(getAgentPlansLookups());
  const agenciesType = useRef<Record<string, string>>({});

  const [agentAction] = useMutation(agentId ? updateAgent() : createAgent());

  const initialize = async () => {
    try {
      setLoading(true);

      const { data: lookupData } = await agentLookupsLazy();
      const newAgentLookups = lookupToList(lookupData);
      agenciesType.current = toAgenciesTypes(
        lookupData.Insurance.queries.allAgencies
      );

      const newLovs = {
        agentType: newAgentLookups['Insurance_AgentTypes'],
        agentStatus: newAgentLookups['Insurance_AgentStatuses'],
        agencyName: newAgentLookups['agencies'],
        agentAccess: newAgentLookups['Insurance_AgentAccesses'],
        linesOfBusiness:
          lookupData?.Insurance?.lookups?.linesOfBusiness?.reduce(
            (
              acc: Record<string, string>,
              curr: { Code: string; Title: string }
            ) => ({
              ...acc,
              [curr.Code]: curr.Title,
            }),
            {}
          ),
        products: {},
        eligiblePlans: {},
      };

      if (agentId) {
        const { data: agentData } = await agentInfoLazy();
        const agentInfo = graphqlEntityToAgentInfo(agentData);
        const headQuater = agentInfo.agencyType.every((m) => m === HEADQUARTER);
        // Fetch products and plans
        const products = await getListOfProducts(agentInfo.linesOfBusiness);
        newLovs.products = products;

        const plans = await getListOfPlans(agentInfo.products);
        newLovs.eligiblePlans = plans;

        setIsHeadquater(headQuater);
        setValues({
          ...agentInfo,
          agentId: agentId,
          linesOfBusiness: agentInfo.linesOfBusiness,
          products: agentInfo.products,
          eligiblePlans: agentInfo.eligiblePlans,
        });
      } else {
        setValues({
          ...initialValues,
          agentId: agentId,
          linesOfBusiness: [],
          products: [],
          eligiblePlans: [],
        });
      }

      setLovs(newLovs);
      setLoading(false);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(
      getInputs(undefined, lovs, isHeadquarter),
      values
    );
    setSubmitButtonState('loading');
    setFormDisabled(true);

    try {
      const variables = {
        agencyId: data.agencyName,
        firstName: data.firstName,
        lastName: data.lastName,
        mobileNumber: data.mobileNumber,
        agentType: data.agentType,
        access: data.agentAccess,
        agentStatus: data.agentStatus,
        linesOfBusiness: data.linesOfBusiness,
        products: data.products,
        eligiblePlans: data.eligiblePlans || [],
        agentICCNumber: data?.agentICCNumber,
        agentRegistrationNumber: data?.agentRegistrationNumber,
        agentRegistrationExpiryDate: DateService.formatDateBackend(
          data.agentRegistrationExpiryDate
        ),
        agentAddress: data.agentAddress,
        ufaCode: data.ufaCode,
        canViewCommission: data.canViewCommission,
      };

      const res = await agentAction({
        variables: agentId
          ? { ...variables, entityId: agentId }
          : {
              ...variables,
              email: data.email,
              password: data.agentPassword || null,
            },
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {agentId
              ? 'Business User successfully updated'
              : 'Business User successfully created'}
          </ToastSuccessMessage>
        );

        setTimeout(() => {
          setFormDisabled(false);
          setSubmitButtonState('success');
          onSuccess();
          onClose();
        }, 500);
      } else {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (err) {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      //
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const getListOfProducts = async (lines: string[]) => {
    const productPromises = lines.map(async (line) => {
      const { data: productsData } = await agentProductsQuery({
        variables: { lineOfBusiness: line },
      });
      return productsData.Insurance.lookups.products.reduce(
        (
          acc: Record<string, string>,
          curr: { Code: string; Title: string }
        ) => ({
          ...acc,
          [curr.Code]: curr.Title,
        }),
        {}
      );
    });

    const productsArray = await Promise.all(productPromises);
    return productsArray.reduce(
      (acc, products) => ({ ...acc, ...products }),
      {}
    );
  };

  const getListOfPlans = async (lines: string[]) => {
    const planPromises = lines.map(async (line) => {
      const { data: plansData } = await agentPlansQuery({
        variables: { product: line },
      });
      return plansData.Insurance.lookups.eligiblePlans.reduce(
        (
          acc: Record<string, string>,
          curr: { Code: string; Title: string }
        ) => ({
          ...acc,
          [curr.Code]: curr.Title,
        }),
        {}
      );
    });

    const plansArray = await Promise.all(planPromises);
    return plansArray.reduce((acc, plans) => ({ ...acc, ...plans }), {});
  };

  const onChange = async (
    fieldName: string,
    value: any,
    newValues: Record<string, any>
  ) => {
    const newLovs = _.cloneDeep(lovs);
    // adding agentId again because it is not being returned in newValues
    newValues.agentId = agentId;

    if (fieldName === 'linesOfBusiness') {
      setFormDisabled(true);
      const products = await getListOfProducts(value);
      newLovs.products = products;
      newValues.products.forEach((p: string) => {
        if (!Object.keys(newLovs.products).includes(p)) {
          newValues.products = (newValues.products as string[]).filter(
            (pv) => pv !== p
          );
        }
      });

      const plans = await getListOfPlans(newValues.products);
      newLovs.eligiblePlans = plans;

      newValues.eligiblePlans.forEach((p: string) => {
        if (!Object.keys(newLovs.eligiblePlans).includes(p)) {
          newValues.eligiblePlans = (
            newValues.eligiblePlans as string[]
          ).filter((pv) => pv !== p);
        }
      });

      setLovs(newLovs);
      setFormDisabled(false);
    }
    if (fieldName === 'products') {
      setFormDisabled(true);
      const plans = await getListOfPlans(value);
      newLovs.eligiblePlans = plans;

      newValues.eligiblePlans.forEach((p: string) => {
        if (!Object.keys(newLovs.eligiblePlans).includes(p)) {
          newValues.eligiblePlans = (
            newValues.eligiblePlans as string[]
          ).filter((pv) => pv !== p);
        }
      });

      setLovs(newLovs);
      setFormDisabled(false);
    }

    if (fieldName === 'agencyName') {
      const selectedOptions = Array.isArray(value) ? value : [value];
      const optionsTypes = selectedOptions.map((option) => {
        const type = agenciesType ? agenciesType.current[option] : '';
        return type;
      });
      const isHQ =
        optionsTypes.length > 0 && optionsTypes.every((a) => a === HEADQUARTER);
      if (isHQ) {
        newValues.agentICCNumber = '';
        newValues.agentRegistrationNumber = '';
        newValues.agentRegistrationExpiryDate = '';
      }

      setIsHeadquater(isHQ);
    }

    setValues(newValues);
  };

  const inputForm = useMemo(() => {
    const result = getInputs(
      values || (initialValues as any),
      lovs,
      isHeadquarter
    );
    return result;
  }, [values, lovs, isHeadquarter]);

  return (
    <GenericDrawer
      title={agentId ? 'Edit Business User' : 'New Business User'}
      onClose={onClose}
      isOpen={open}
    >
      {loading ? (
        <Loader />
      ) : (
        <DynamicForm
          inputs={inputForm}
          onSubmit={submitForm}
          buttonText={'Submit'}
          submitButtonState={submitButtonState}
          disableForm={formDisabled}
          title="Information"
          hasDoprdownSpecificBehavior={false}
          onChange={onChange}
        />
      )}
    </GenericDrawer>
  );
};

export default AgentDrawer;

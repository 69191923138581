import {
  IAmendmentPersons,
  IBeneficiaryData,
  IInsuredData,
  IOwnerData,
  IPayerData,
} from '../..';
import { IAbstractRecord } from '../../../../../models';
import PrecisionService from '../../../../../services/precisionService';
import { ITravelAmendmentLovs } from '../../line-amendment/TravelAmendment';

export function extractPersonsFixed(data: IAbstractRecord) {
  const result: Record<string, string> = {};

  data.Production.queries.GetAssignedPersons.forEach(
    (element: {
      salesforceManagement_AssignedPerson_PersonID: {
        id: string;
      };
      salesforceManagement_AssignedPerson_FullName: string;
      salesforceManagement_AssignedPerson_PersonCode: string;
    }) => {
      result[element.salesforceManagement_AssignedPerson_PersonID?.id] =
        `${element.salesforceManagement_AssignedPerson_PersonCode} - ${element.salesforceManagement_AssignedPerson_FullName}`;
    }
  );

  return result;
}

export function extractPersonsAddressesFixed(
  data: IAbstractRecord
): Record<string, { address: string; isPrimary: boolean }> {
  const result: Record<string, { address: string; isPrimary: boolean }> = {};

  data?.SalesforceManagement?.queries?.getPersonAddressListWithoutPagination?.forEach(
    (element: {
      salesforceManagement_PersonAddress_Id: string;
      salesforceManagement_PersonAddress_Address: {
        Country: {
          Title: string;
        };
        City: string;
        Street: string;
        Building: string;
      };
      salesforceManagement_PersonAddress_IsPrimary: boolean;
    }) => {
      const { Building, Street, City, Country } =
        element.salesforceManagement_PersonAddress_Address;
      const parts = [Building, Street, City, Country?.Title].filter(
        (part) => part != null && part !== ''
      );
      const address = parts.join(', ');

      result[element.salesforceManagement_PersonAddress_Id] = {
        address,
        isPrimary: element.salesforceManagement_PersonAddress_IsPrimary,
      };
    }
  );

  return result;
}

export function convertToPolicyPersons(
  data: IAbstractRecord
): IAmendmentPersons {
  const persons = data.Production.queries.GetPolicyPerson;

  let payer: IPayerData = {
    payerId: '',
    payerAddress: '',
    ownerIsDifferent: false,
  };
  let owner: IOwnerData = { ownerId: '', ownerAddress: '' };
  let insured: IInsuredData[] = [];
  const beneficiaries: IBeneficiaryData[] = [];

  persons.forEach((person: IAbstractRecord) => {
    const building = person?.production_PolicyPerson_Address?.Building;
    const street = person?.production_PolicyPerson_Address?.Street;
    const city = person?.production_PolicyPerson_Address?.City;
    const country = person?.production_PolicyPerson_Address?.Country?.Title;
    const address = `${building ? building + ', ' : ''} ${
      street ? street + ', ' : ''
    } ${city ? city + ', ' : ''} ${country ? country : ''}`;

    switch (person.production_PolicyPerson_Type) {
      case 'PAYER':
        payer = {
          payerId: person.production_PolicyPerson_PersonID?.id,
          fullName: `${person.production_PolicyPerson_PersonCode} - ${person.production_PolicyPerson_FullName}`,
          payerAddress: person?.production_PolicyPerson_PersonAddressID?.id,
          ownerIsDifferent: person.production_PolicyPerson_OwnerIsDifferent,
          addressDetails: address,
        };
        break;
      case 'INSURED':
        insured.push({
          id: person.production_PolicyPerson_PersonID?.id,
          order: person.production_PolicyPerson_OrderNumber,
          fullName: `${person.production_PolicyPerson_PersonCode} - ${person.production_PolicyPerson_FullName}`,
          address: person?.production_PolicyPerson_PersonAddressID?.id,
          addressDetails: address,

          grLimitDays: person.production_PolicyPerson_GRLimitDays,
          cardNumber: person.production_PolicyPerson_CardNumber,
          continuity: person.production_PolicyPerson_Continuity,
          tpaReference1: person.production_PolicyPerson_TPAReference1,
          tpaReference2: person.production_PolicyPerson_TPAReference2,
          exclusions: person.production_PolicyPerson_Exclusions,
          remarks: person.production_PolicyPerson_Remarks,
          isPrincipal: person.production_PolicyPerson_IsPrincipal,
          relation: person.production_PolicyPerson_Relation,
        });
        insured.sort((a, b) => Number(a.order) - Number(b.order));
        break;
      case 'BENEFICIARY':
        beneficiaries.push({
          id: person.production_PolicyPerson_PersonID?.id,
          address: person?.production_PolicyPerson_PersonAddressID?.id,
          percentage: PrecisionService.multiplyBy100(
            person.production_PolicyPerson_Percentage
          ),
          fullName: `${person.production_PolicyPerson_PersonCode} - ${person.production_PolicyPerson_FullName}`,
          addressDetails: address,
        });
        break;
      case 'OWNER':
        owner = {
          ownerId: person.production_PolicyPerson_PersonID?.id || '',
          ownerAddress:
            person?.production_PolicyPerson_PersonAddressID?.id || '',
          addressDetails: address,
          fullName: `${person.production_PolicyPerson_PersonCode} - ${person.production_PolicyPerson_FullName}`,
        };
        break;
    }
  });

  if (insured.length === 0) {
    insured.push({
      id: '',
      order: '1',
      fullName: '',
      address: '',
      addressDetails: '',

      grLimitDays: undefined,
      cardNumber: undefined,
      continuity: undefined,
      tpaReference1: '',
      tpaReference2: '',
      exclusions: '',
      remarks: '',
      isPrincipal: true,
      relation: '',
    });
  }

  insured = insured.sort((a, b) => {
    if (a.isPrincipal === b.isPrincipal) {
      return 0; // Keep original order if both are the same
    } else if (a.isPrincipal) {
      return -1; // a comes before b
    } else {
      return 1; // b comes before a
    }
  });

  if (beneficiaries.length === 0) {
    beneficiaries.push({
      id: '',
      fullName: '',
      percentage: 0,
      address: '',
      addressDetails: '',
    });
  }

  return {
    payer: payer,
    owner: owner,
    insured,
    beneficiaries,
  };
}

export const getDefaultPersonListIfEmpty = (
  list: Record<string, string>,
  insured: IInsuredData
) => {
  if (Object.keys(list || {}).length > 0 || !insured.fullName) {
    return list;
  }

  const newList: Record<string, string> = {
    [insured.id]: insured.fullName,
  };

  return newList;
};

export const getInsuredLov = (
  row: IInsuredData,
  lovs: ITravelAmendmentLovs,
  insuredValues: IInsuredData[]
) => {
  const result = { ...lovs.insuredRelations };
  delete result['PRINCIPAL'];
  if (row.relation !== 'SPOUSE') {
    if (insuredValues.some((a) => a.relation === 'SPOUSE')) {
      delete result['SPOUSE'];
    }
  }
  return result;
};

import { IListingData } from '../../../models/listing';
import { lookupListAsRecordObject } from '../../../utils/graph-utils';
import { IActivityEntityBase } from '../../../activities';
import { IAbstractRecord } from '../../../models';
import { ITransactionsGenericTableData, ITransactionsResponseData } from '.';

export function mapToListingData(
  data: IAbstractRecord,
  lovs: Record<string, Record<string, string>>
): IListingData {
  const list: Record<string, ITransactionsGenericTableData> = {};
  data.Accounting.queries.TransactionsList?.items?.forEach(
    (obj: ITransactionsResponseData, i: number) => {
      list[i] = {
        Accounting_Transactions_Id: obj.accounting_Transactions_Id,
        Accounting_Transactions_createdOn:
          obj.accounting_Transactions_createdOn,
        Accounting_Transactions_TransactionNumber:
          obj.accounting_Transactions_TransactionNumber,
        Accounting_Transactions_ReferenceNumber:
          obj.accounting_Transactions_ReferenceNumber,
        Accounting_Transactions_Description:
          obj.accounting_Transactions_Description,
        Accounting_Transactions_TransactionDate:
          obj.accounting_Transactions_TransactionDate,
        Accounting_Transactions_TransactionType:
          lovs.type?.[obj.accounting_Transactions_TransactionType?.Code],
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Accounting.queries.TransactionsList?.paging.pageSize,
    pageNumber: data.Accounting.queries.TransactionsList?.paging.pageNumber,
    totalCount: data.Accounting.queries.TransactionsList?.paging.totalCount,
  };
}

export function EntityAccountToList(data: IAbstractRecord) {
  const lookuplistAsRecordObject: Record<string, string> = {};

  data?.Accounting?.queries?.GetAccountsOfCompany?.forEach(
    (element: { [key: string]: string }) => {
      if (element.accounting_ChartOfAccounts_Id) {
        lookuplistAsRecordObject[element.accounting_ChartOfAccounts_Id] =
          element.accounting_ChartOfAccounts_AccountName;
      }
    }
  );

  return lookuplistAsRecordObject;
}

export function toLookupsData(data: IAbstractRecord) {
  const lovs: Record<string, Record<string, string>> = {
    company: {},
    account: {},
    type: {},
  };

  lovs.type = lookupListAsRecordObject(
    data?.Accounting?.lookups?.transactionType,
    false,
    'Code',
    'LongTitle'
  );

  lovs.company = lookupListAsRecordObject(
    data?.SalesforceManagement?.lookups?.relatedCompanies
  );

  data?.Accounting?.queries?.GetAccountsOfCompany?.forEach(
    (element: { [key: string]: string }) => {
      if (element.accounting_ChartOfAccounts_Id) {
        lovs.account[element.accounting_ChartOfAccounts_Id] =
          element.accounting_ChartOfAccounts_AccountID +
          ' ' +
          element.accounting_ChartOfAccounts_AccountName;
      }
    }
  );

  return lovs;
}

export function convertAuditTrailsToActivities(
  data: IAbstractRecord
): IActivityEntityBase[] {
  return data.Accounting.queries.GetTransactionsAuditTrails.map(
    (auditTrail: Record<string, string>) => ({
      id: auditTrail.accounting_AuditTrail_Id,
      createdAt: auditTrail.accounting_AuditTrail_createdOn,
      htmlBody: auditTrail.accounting_AuditTrail_Message,
      iconUrl: extractIconUrl(auditTrail.accounting_AuditTrail_Message),
    })
  );
}

function extractIconUrl(html: string): string | undefined {
  const match = html.match(/background-image: url\('(.+?)'\)/);
  return match ? match[1] : undefined;
}

import React from 'react';
import WidgetPaper from '../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../components/common/WidgetSection';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME } from '../../../../../constants';
import { Chip } from '@mui/material';
import EnhancedButton from '../../../../../components/form-fields/buttons/EnhancedButton';
import { IFacDetails, IFacErrors, IFacLovs } from '..';
import { cloneDeep, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import ToastSuccessMessage from '../../../../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../utils/graph-utils';
import TextInputFormField from '../../../../../components/form-fields/TextInputFormField';
import SelectFormField from '../../../../../components/form-fields/SelectFormField';
import { useMutation } from '@apollo/client';
import DatePickerFormField from '../../../../../components/form-fields/DatePickerFormField';
import CurrencyFormField from '../../../../../components/form-fields/CurrencyFormField';
import CoversRepeaterWidget from './CoversRepeaterWidget';
import { saveFacultativeMutation } from '../queries';
import DateService from '../../../../../services/dateService';
import { valueOrNull } from '../../../../../utils/helper-utils';
import Loader from '../../../../../components/Loader';

interface IFacDetailsWidget {
  currentFacId: string;
  isDisabled: boolean;
  values: IFacDetails;
  updateValues: (values: IFacDetails) => void;
  errors: IFacErrors;
  updateErrors: (errors: IFacErrors) => void;
  lovs: IFacLovs;
  updateLovs: (lovs: IFacLovs) => void;
  shouldSave: boolean;
  setShouldSave: (shouldSave: boolean) => void;
  loading: boolean;
  validateForm: () => boolean;
  onSuccess: () => void;
  getProposalDetails: (proposalId: string) => Promise<{
    currencySymbol: string;
    currencyCode: string;
    covers: IFacLovs['covers'];
  }>;
  getPolicyDetails: (proposalId: string) => Promise<{
    currencySymbol: string;
    currencyCode: string;
    covers: IFacLovs['covers'];
  }>;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  inputsGrid: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, 32%)`,
    gap: '2%',
    marginBottom: '1em',
  },
  dirtyChip: {
    marginBottom: '-7px',
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    '& span': {
      color: '#ffffff',
    },
  },
}));

const FacultativeDetailsWidget: React.FC<IFacDetailsWidget> = ({
  currentFacId,
  isDisabled,
  values,
  updateValues,
  errors,
  updateErrors,
  lovs,
  updateLovs,
  shouldSave,
  setShouldSave,
  loading,
  validateForm,
  onSuccess,
  getProposalDetails,
  getPolicyDetails,
}) => {
  const { classes } = useStyles();

  const [saveFacultative] = useMutation(saveFacultativeMutation);

  const handleFieldChange = async <T extends keyof IFacDetails>(
    newValue: IFacDetails[T],
    fieldName: T
  ) => {
    const newValues = cloneDeep(values);
    const valueChanged = !isEqual(newValues[fieldName], newValue);
    if (valueChanged) {
      if (fieldName === 'proposal') {
        const { currencyCode, currencySymbol, covers } =
          await getProposalDetails(newValue as string);

        newValues.covers.map((cover) => {
          cover.currencyCode = currencyCode;
          cover.currencySymbol = currencySymbol;
          return cover;
        });

        updateLovs({
          ...lovs,
          covers,
          currency: {
            code: currencyCode,
            symbol: currencySymbol,
          },
        });
      } else if (fieldName === 'policy') {
        const { currencyCode, currencySymbol, covers } = await getPolicyDetails(
          newValue as string
        );

        newValues.covers.map((cover) => {
          cover.currencyCode = currencyCode;
          cover.currencySymbol = currencySymbol;
          return cover;
        });

        updateLovs({
          ...lovs,
          covers,
          currency: {
            code: currencyCode,
            symbol: currencySymbol,
          },
        });
      }

      newValues[fieldName] = newValue;
      updateValues(newValues);
      setShouldSave(true);
    }
  };

  const getMutationVariables = () => {
    return {
      CurrentFacID: currentFacId,
      Proposal: valueOrNull(values.proposal),
      Policy: valueOrNull(values.policy),
      SlipReferenceNumber: values.slipRefNo,
      TotalCededPremium: values.totalCededPremium,
      TotalCededSumInsured: values.totalCededSumInsured,
      TotalCommission: values.totalCommission,
      PaymentDueDate: DateService.formatDateBackend(values.paymentDueDate),
      FacCoverList: values.covers.map((cover) => ({
        CoverID: cover.coverId,
        CededPremium: parseFloat(cover.cededPremium),
        CededSumInsured: parseFloat(cover.cededSumInsured),
        CommissionAmount: parseFloat(cover.commissionAmount),
        CommissionRate: Number(cover.commissionRate),
        FacultativeRate: Number(cover.facultativeRate),
      })),
    };
  };

  const handleSave = async () => {
    const isValid = validateForm();
    if (isValid) {
      try {
        const mutationVariables = getMutationVariables();

        const res = await saveFacultative({
          variables: mutationVariables,
        });

        if (!res.errors) {
          setShouldSave(false);
          toast.success(
            <ToastSuccessMessage>Successfully saved</ToastSuccessMessage>
          );
          onSuccess();
        }
      } catch (error) {
        toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
      }
    }
  };

  return (
    <WidgetPaper>
      <WidgetSection
        title="Facultative Details"
        hasTitleSpecificDesign={false}
        useSeparator={false}
        middle={
          shouldSave &&
          !isDisabled && (
            <Chip
              label={'Facultative details changed, please save again'}
              className={classes.dirtyChip}
            />
          )
        }
        actions={
          isDisabled ? null : (
            <EnhancedButton onClick={handleSave} disabled={!shouldSave}>
              Save
            </EnhancedButton>
          )
        }
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className={classes.inputsGrid}>
              <TextInputFormField
                name="slipRefNo"
                title="Slip Reference No.*"
                placeholder="Slip Reference No"
                value={values.slipRefNo}
                onChange={(e) => {
                  handleFieldChange(e.target.value, 'slipRefNo');
                }}
                error={errors.slipRefNo}
                disabled={isDisabled}
              />
              {values.linkedTo === 'PROPOSAL' ? (
                <SelectFormField
                  name="proposal"
                  title="Proposal*"
                  placeholder="Select Proposal"
                  selectOptions={lovs.proposals}
                  value={values.proposal}
                  onChange={(v) => {
                    handleFieldChange(v, 'proposal');
                  }}
                  error={errors.proposal}
                  disabled={isDisabled}
                />
              ) : (
                <SelectFormField
                  name="policy"
                  title="Policy*"
                  placeholder="Select Policy"
                  selectOptions={lovs.policies}
                  value={values.policy}
                  onChange={(v) => {
                    handleFieldChange(v, 'policy');
                  }}
                  error={errors.policy}
                  disabled={isDisabled}
                />
              )}
              <DatePickerFormField
                name="paymentDueDate"
                title="Payment Due Date*"
                value={values.paymentDueDate}
                onDateChange={(v) => {
                  handleFieldChange(v.toString(), 'paymentDueDate');
                }}
                minDate={new Date()}
                error={errors.paymentDueDate}
                disabled={isDisabled}
              />
              <CurrencyFormField
                name="totalCededSumInsured"
                title="Total Ceded Sum Insured*"
                placeholder="Total Ceded Sum Insured"
                value={values.totalCededSumInsured}
                onChange={(e) => {
                  handleFieldChange(
                    Number(e.target.value),
                    'totalCededSumInsured'
                  );
                }}
                currencySymbol={lovs.currency.symbol}
                error={errors.totalCededSumInsured}
                disabled={true}
              />
              <CurrencyFormField
                name="totalCededPremium"
                title="Total Ceded Premium*"
                placeholder="Total Ceded Premium"
                value={values.totalCededPremium}
                onChange={(e) => {
                  handleFieldChange(
                    Number(e.target.value),
                    'totalCededPremium'
                  );
                }}
                currencySymbol={lovs.currency.symbol}
                error={errors.totalCededPremium}
                disabled={true}
              />
              <CurrencyFormField
                name="totalCommission"
                title="Total Commission*"
                placeholder="Total Commission"
                value={values.totalCommission}
                onChange={(e) => {
                  handleFieldChange(Number(e.target.value), 'totalCommission');
                }}
                currencySymbol={lovs.currency.symbol}
                error={errors.totalCommission}
                disabled={true}
              />
            </div>
            <CoversRepeaterWidget
              values={values}
              updateValues={(v) => {
                if (!isEqual(v, values)) {
                  setShouldSave(true);
                }
                updateValues(v);
              }}
              errors={errors.covers}
              updateErrors={(e) => {
                const newErrors = cloneDeep(errors);
                newErrors.covers = e;
                updateErrors(newErrors);
              }}
              lovs={lovs}
              isDisabled={isDisabled}
            />
          </>
        )}
      </WidgetSection>
    </WidgetPaper>
  );
};

export default FacultativeDetailsWidget;

import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IPlanDetails, initialValues } from '.';
import Loader from '../../../components/Loader';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import { IEntityInfoView } from '../../../components/widgets/entity-info/EntityInfoFields';
import EntityInfoWidget from '../../../components/widgets/entity-info/EntityInfoWidget';
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from '../../../constants';
import {
  allRiskPlanRatesHeaders,
  entityView,
  expatPlanRatesHeaders,
  planTabs,
  steps,
  medicalPlanRatesHeaders,
  planCoversRatesHeaders,
  getTravelPlanRatesHeaders,
} from './content';
import {
  activatePlan,
  deactivatePlan,
  getPlanDetailsInfo,
  getPlanSpecificAllRiskList,
  getPlanSpecificExpatList,
  getPlanSpecificMedicalList,
  getPlanSpecificTravelList,
  getPlanCoversRatesList,
} from './queries';
import {
  entityToPlanDetails,
  getPlanStatus,
  mapToAllRiskPlanRatesListingData,
  mapToExpatPlanRatesListingData,
  mapToMedicalPlanRatesListingData,
  mapToPlanCoversRatesListingData,
  mapToTravelPlanRatesListingData,
} from './utils';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import EnhancedStepperSpecificDesign from '../../../components/common/EnhancedStepperSpecificDesign';
import { makeStyles } from 'tss-react/mui';
import { EnhancedDisplayType } from '../../../components/enhanced-form/EnhancedDisplay';
import PlanDrawer from '../../../forms/plan-drawer/PlanDrawer';
import EnhancedButton, {
  EnhancedButtonStatus,
} from '../../../components/EnhancedButton';
import { IConfirmation } from '../../../redux/confirmation/types';
import { isEmpty } from 'lodash';
import ToastSuccessMessage from '../../../components/ToastSuccessMessage';
import { getError } from '../../../utils/graph-utils';
import { OpenConfirmationAction } from '../../../redux/confirmation/actions';
import TabsLayout from '../../../page-layout/tabs-layout/TabsLayout';
import { IDynamicDiplayView } from '../../../components/widgets/dynamic-display';
import {
  capitalizeFirstCharacter,
  capitalizeFirstLetter,
  valueCommaSeparated,
} from '../../../utils/formatting-utils';
import DynamicWidget from '../../../components/widgets/dynamic-display/DynamicWidget';
import ShowForUser from '../../../components/user/ShowForUser';
import PlanDetailsDefaultCommissionDrawer from '../../../forms/plan-details-default-commission-drawer/PlanDetailsDefaultCommissionDrawer';
import WidgetSection from '../../../components/common/WidgetSection';
import { IListingData } from '../../../models/listing';
import PlanInformationDrawer from '../../../forms/plan-information-drawer/PlanInformationDrawer';
import AllRiskPlanRatesDrawer from '../../../forms/plan-allRisk-rates-drawer/AllRiskPlanRates';
import ExpatPlanRatesDrawer from '../../../forms/plan-expat-rates-drawer/ExpatPlanRates';
import TravelPlanRatesDrawer from '../../../forms/plan-travel-rates-drawer/TravelPlanRates';
import MedicalPlanRatesDrawer from '../../../forms/plan-medical-rates-drawer/MedicalPlanRates';
import PlanAdditionalFeesAndChargesDrawer from '../../../forms/plan-additional-fees-drawer/PlanAdditionalFeesAndChargesDrawer';
import AssignPolicyCoverDrawer from '../../../forms/assign-policy-cover-drawer/AssignPolicyCoverDrawer';
import ListingTableWithPagination from '../../../components/form-fields/table/with-pagination/ListingTableWithPagination';
import {
  ActionTarget,
  IEnhancedMenuItem,
} from '../../../components/form-fields/table';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '90%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ': {
      color: MAIN_ONE_THEME.palette.primary5.main,
      '& .MuiStepIcon-text': {
        fontSize: 0,
        lineHeight: '0px',
        fill: 'black',
      },
    },

    '.Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
      '& .MuiStepIcon-text': {
        fontSize: 0,
        lineHeight: '0px',
        fill: 'black',
      },
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  section: {
    display: 'grid',
    gridtemplatecolumns: '19.5% 19.5% 19.5% 19.5% 19.5%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '& label': {
      '& span': {
        margin: '-2px 0',
      },
    },
  },
  sectionTitle: {
    padding: '-10x 10x!important',
  },
  sectionFullRow: {
    display: 'grid',
    gridtemplatecolumns: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  row: {
    flexWrap: 'wrap',
    margin: '15px 20px 10px 0px',
    alignItems: 'flex-start',
    display: 'grid',
    gap: '5px',
    '& label': {
      flexGrow: '1',
      flexBasis: '0',
      minWidth: '0',
    },
    gridTemplateColumns: '19.5% 19.5% 19.5% 19% 16% 3% 3%',
  },
  thickSeperator: {
    height: 10,
    margin: '10px 0 10px 0',
    gridColumnStart: '1',
    gridColumnEnd: '4',
  },
  field: {
    width: '96%',
    marginRight: '10px !important',
  },
  repeaterField: {
    margin: '0px!important',
  },
  arField: {
    width: '96%',
    marginRight: '10px !important',
    direction: 'rtl',
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    '&:hover': {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: '0',
  },
  dirtyChip: {
    margin: '0 0 -15px 0',
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    '& span': {
      color: '#ffffff',
    },
  },
  tabPanelStyle: {
    padding: 'unset',
  },
  actionButton: {
    display: 'inline-block',
    marginRight: '5px',
  },
}));

const PlanDetailsPage: React.FC = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const params = useParams();
  const dispatch = useAppDispatch();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);
  const client = useApolloClient();

  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [planCurrency, setPlanCurrency] = useState<string>('');
  const [planCurrencyTitle, setPlanCurrencyTitle] = useState<string>('');

  const [planDrawerOpen, setPlanDrawerOpen] = useState<boolean>(false);
  const [planInformationDrawerOpen, setPlanInformationDrawerOpen] =
    useState<boolean>(false);
  const [defaultCommissionDrawerOpen, setDefaultCommissionDrawerOpen] =
    useState<boolean>(false);
  const [allRiskPlanRatesDrawerOpen, setAllRiskPlanRatesDrawerOpen] = useState<{
    open: boolean;
    id?: string;
  }>({
    open: false,
  });
  const [expatPlanRatesDrawerOpen, setExpatPlanRatesDrawerOpen] = useState<{
    open: boolean;
    id?: string;
  }>({
    open: false,
  });
  const [travelPlanRatesDrawerOpen, setTravelPlanRatesDrawerOpen] = useState<{
    open: boolean;
    id?: string;
  }>({
    open: false,
  });
  const [medicalPlanRatesDrawerOpen, setMedicalPlanRatesDrawerOpen] = useState<{
    open: boolean;
    id?: string;
  }>({
    open: false,
  });
  const [planCoversRatesDrawerOpen, setPlanCoversRatesDrawerOpen] = useState<{
    open: boolean;
    id?: string;
  }>({
    open: false,
  });
  const [
    additionalFeesandChargesDrawerOpen,
    setAdditionalFeesandChargesDrawerOpen,
  ] = useState<{
    open: boolean;
    id?: string;
  }>({
    open: false,
  });

  const [planInformationViewConfig, setPlanInformationViewConfig] =
    useState<IDynamicDiplayView>();
  const [coversInformationViewConfig, setCoversInformationViewConfig] =
    useState<IDynamicDiplayView>();
  const [defaultCommissionViewConfig, setDefaultCommissionViewConfig] =
    useState<IDynamicDiplayView>();
  const [
    additionalFeesandChargesViewConfig,
    setAdditionalFeesandChargesViewConfig,
  ] = useState<IDynamicDiplayView>();
  //#endregion

  //#region component hooks and states
  const [lineExternalCode, setLineExternalCode] = useState<string>('');

  const [planDetailsInfo, setPlanDetailsInfo] =
    useState<IPlanDetails>(initialValues);

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const entityId = params.id;

  const [getPlanDetailsLazy, { data: entityResult, loading: entityLoading }] =
    useLazyQuery(getPlanDetailsInfo(), {
      variables: { id: entityId },
      errorPolicy: 'all',
    });

  const [allRiskPlanRatesTableData, setAllRiskPlanRatesTableData] =
    useState<IListingData>({
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    });

  const [expatPlanRatesTableData, setExpatPlanRatesTableData] =
    useState<IListingData>({
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    });

  const [travelPlanRatesTableData, setTravelPlanRatesTableData] =
    useState<IListingData>({
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    });

  const [medicalPlanRatesTableData, setMedicalPlanRatesTableData] =
    useState<IListingData>({
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    });

  const [planCoversRatesTableData, setPlanCoversRatesTableData] =
    useState<IListingData>({
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    });

  const [activateAction] = useMutation(activatePlan(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: 'all',
    refetchQueries: [getPlanDetailsInfo()],
  });
  const [deactivateAction] = useMutation(deactivatePlan(), {
    variables: {
      entityId: entityId,
    },
    refetchQueries: [getPlanDetailsInfo()],
  });

  const [getPlanDetailsInfoLazy] = useLazyQuery(getPlanDetailsInfo());

  const loadPlanDetailsInfo = async () => {
    const result = await getPlanDetailsInfoLazy({
      variables: { id: entityId },
      errorPolicy: 'all',
    });

    return result;
  };

  const [getAllRiskPlanRatesListLazy, { loading: allRiskLoading }] =
    useLazyQuery(getPlanSpecificAllRiskList());

  const loadAllRiskPlanRatesList = async (
    currentPage: number,
    pageSize = allRiskPlanRatesTableData.pageSize,
    searchKey?: string,
    order?: string,
    orderBy?: string
  ) => {
    const result = await getAllRiskPlanRatesListLazy({
      variables: {
        currentPage,
        currentPageSize: pageSize,
        selectedPlanIds: [entityId],
        Attribute: capitalizeFirstCharacter(orderBy),
        Descending: order == 'asc' ? false : true,
        keywordSearch: searchKey || null,
      },
      errorPolicy: 'all',
    });

    return result;
  };

  const [getPlanCoversRatesListLazy, { loading: planCoversLoading }] =
    useLazyQuery(getPlanCoversRatesList());

  const loadPlanCoversRatesList = async (
    currentPage: number,
    pageSize = planCoversRatesTableData.pageSize,
    searchKey?: string,
    order?: string,
    orderBy?: string
  ) => {
    const result = await getPlanCoversRatesListLazy({
      variables: {
        currentPage,
        currentPageSize: pageSize,
        selectedPlanIds: [entityId],
        Attribute: capitalizeFirstCharacter(orderBy),
        Descending: order == 'asc' ? false : true,
        keywordSearch: searchKey || null,
      },
      errorPolicy: 'all',
    });

    return result;
  };

  const [getExpatPlanRatesListLazy, { loading: expatPlanLoading }] =
    useLazyQuery(getPlanSpecificExpatList());

  const loadExpatPlanRatesList = async (
    currentPage: number,
    pageSize = expatPlanRatesTableData.pageSize,
    searchKey?: string,
    order?: string,
    orderBy?: string
  ) => {
    const result = await getExpatPlanRatesListLazy({
      variables: {
        currentPage,
        currentPageSize: pageSize,
        selectedPlanIds: [entityId],
        Attribute: capitalizeFirstCharacter(orderBy),
        Descending: order == 'asc' ? false : true,
        keywordSearch: searchKey || null,
      },
      errorPolicy: 'all',
    });

    return result;
  };

  const [getTravelPlanRatesListLazy, { loading: travelPlanLoading }] =
    useLazyQuery(getPlanSpecificTravelList());

  const loadTravelPlanRatesList = async (
    currentPage: number,
    pageSize = travelPlanRatesTableData.pageSize,
    searchKey?: string,
    order?: string,
    orderBy?: string
  ) => {
    const result = await getTravelPlanRatesListLazy({
      variables: {
        currentPage,
        currentPageSize: pageSize,
        selectedPlanIds: [entityId],
        Attribute: capitalizeFirstCharacter(orderBy),
        Descending: order == 'asc' ? false : true,
        keywordSearch: searchKey || null,
      },
      errorPolicy: 'all',
    });

    return result;
  };

  const [getMedicalPlanRatesListLazy, { loading: medicalPlanLoading }] =
    useLazyQuery(getPlanSpecificMedicalList());

  const loadMedicalPlanRatesList = async (
    currentPage: number,
    pageSize = medicalPlanRatesTableData.pageSize,
    searchKey?: string,
    order?: string,
    orderBy?: string
  ) => {
    const result = await getMedicalPlanRatesListLazy({
      variables: {
        currentPage,
        currentPageSize: pageSize,
        selectedPlanIds: [entityId],
        Attribute: capitalizeFirstCharacter(orderBy),
        Descending: order == 'asc' ? false : true,
        keywordSearch: searchKey || null,
      },
      errorPolicy: 'all',
    });

    return result;
  };

  const initialize = async () => {
    const entityData = await loadPlanDetailsInfo();
    const planDetails = await entityToPlanDetails(entityData.data);

    setPlanDetailsInfo(planDetails);
    setPlanCurrency(planDetails.planCurrency);
    setPlanCurrencyTitle(planDetails.planCurrencyTitle);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleAllRiskPlanRatesPageChange(
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) {
    const result = await loadAllRiskPlanRatesList(
      page,
      rowsPerPage || allRiskPlanRatesTableData.pageSize,
      searchKey,
      order,
      orderBy
    );
    const mappedSubList = mapToAllRiskPlanRatesListingData(
      result.data,
      planCurrencyTitle
    );
    setAllRiskPlanRatesTableData(mappedSubList);
  }

  async function handleExpatPlanRatesPageChange(
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) {
    const result = await loadExpatPlanRatesList(
      page,
      rowsPerPage || expatPlanRatesTableData.pageSize,
      searchKey,
      order,
      orderBy
    );
    const mappedSubList = mapToExpatPlanRatesListingData(
      result.data,
      planCurrencyTitle
    );
    setExpatPlanRatesTableData(mappedSubList);
  }

  async function handleTravelPlanRatesPageChange(
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) {
    const result = await loadTravelPlanRatesList(
      page,
      rowsPerPage || travelPlanRatesTableData.pageSize,
      searchKey,
      order,
      orderBy
    );
    const mappedSubList = mapToTravelPlanRatesListingData(result.data);
    setTravelPlanRatesTableData(mappedSubList);
  }

  async function handleMedicalPlanRatesPageChange(
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) {
    const result = await loadMedicalPlanRatesList(
      page,
      rowsPerPage || medicalPlanRatesTableData.pageSize,
      searchKey,
      order,
      orderBy
    );
    const mappedSubList = mapToMedicalPlanRatesListingData(result.data);
    setMedicalPlanRatesTableData(mappedSubList);
  }

  async function handlePlanCoversRatesPageChange(
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) {
    const result = await loadPlanCoversRatesList(
      page,
      rowsPerPage || planCoversRatesTableData.pageSize,
      searchKey,
      order,
      orderBy
    );
    const mappedSubList = mapToPlanCoversRatesListingData(result.data);
    setPlanCoversRatesTableData(mappedSubList);
  }

  //#region useEffect

  useEffect(() => {
    getPlanDetailsLazy();
    // getPlanCoversRatesListLazy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      if (entityResult) {
        const planDetails = entityToPlanDetails(entityResult);
        setLineExternalCode(planDetails.lineIdExternalCode);
        setPlanCurrency(planDetails.planCurrency);
        setPlanCurrencyTitle(planDetails.planCurrencyTitle);

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.lineName = {
          title: 'Line',
          name: 'Line',
          multiline: false,
          value: planDetails.lineName,
          type: EnhancedDisplayType.RelativeLink,
          tooltip: false,
          url:
            '/plan/lines/' +
            entityResult?.PlanConfigManagement?.entities?.plan?.views
              ?.PlanConfigManagement_all?.properties?.LineID?.id,
        };
        applicationProperties.properties.sublineName = {
          title: 'Subline',
          name: 'SublineName',
          multiline: false,
          value: planDetails.sublineName,
          type: EnhancedDisplayType.RelativeLink,
          tooltip: false,
          url:
            '/plan/sublines/' +
            entityResult?.PlanConfigManagement?.entities?.plan?.views
              ?.PlanConfigManagement_all?.properties?.SublineID?.id,
        };

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value = planDetails.createdBy;
        systemProperties.properties.createdOn.value = planDetails.createdOn;
        systemProperties.properties.modifiedBy.value = planDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = planDetails.modifiedOn;

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        setPlanDetailsInfo(planDetails);

        setUpdatedLeftSideInfo(newLeftEntityView);

        const summaryDynamicView: IDynamicDiplayView = {
          name: 'Plan Details',
          sections: [
            {
              name: 'generalDetails',
              title: 'General Details',
              showActionButtons: true,
              properties: {
                planName: {
                  multiline: false,
                  value: planDetails.planName,
                  name: 'planName',
                  title: 'Plan Name',
                },
                externalCode: {
                  multiline: false,
                  value: planDetails.externalCode,
                  name: 'externalCode',
                  title: 'External Code',
                },
                arabicName: {
                  multiline: false,
                  value: planDetails.arabicName,
                  name: 'arabicName',
                  title: 'Arabic Name',
                },
                abbreviation: {
                  multiline: false,
                  value: planDetails.abbreviation,
                  name: 'abbreviation',
                  title: 'Abbreviation',
                },
                effectiveFrom: {
                  multiline: false,
                  value: planDetails.effectiveFrom,
                  name: 'effectiveFrom',
                  title: 'Effective From',
                  type: EnhancedDisplayType.Date,
                },
                effectiveTo: {
                  multiline: false,
                  value: planDetails.effectiveTo,
                  name: 'effectiveTo',
                  title: 'Effective To',
                  type: EnhancedDisplayType.Date,
                },
                planCurrency: {
                  multiline: false,
                  value: planDetails.planCurrencyTitle,
                  name: 'planCurrency',
                  title: 'Plan Currency',
                },
                maxNumberofInsured: {
                  multiline: false,
                  value: planDetails.maxNumberofInsured,
                  name: 'maxNumberofInsured',
                  title: 'Max Number of Insured',
                },
                maxNumberofBeneficiary: {
                  multiline: false,
                  value: planDetails.maxNumberofBeneficiary,
                  name: 'maxNumberofBeneficiary',
                  title: 'Max Number of Beneficiary',
                  hidden: ['51', '50', '4'].includes(
                    planDetails.lineIdExternalCode
                  ),
                },
                clauseEditable: {
                  multiline: false,
                  value: planDetails.clauseEditable ? 'Yes' : 'No',
                  name: 'clauseEditable',
                  title: 'Clause Editable',
                },
              },
            },
          ],
        };

        if (
          planDetails.lineIdExternalCode === '51' ||
          planDetails.lineIdExternalCode === '50' ||
          planDetails.lineIdExternalCode === '4' ||
          planDetails.lineIdExternalCode === '6' ||
          planDetails.lineIdExternalCode === '19'
        ) {
          summaryDynamicView.sections.push({
            name: 'additionalDetails',
            title: 'Additional Details',
            showActionButtons: false,
            properties: {
              acalCategory: {
                multiline: false,
                value: planDetails.acalCategoryTitle,
                name: 'acalCategory',
                title: 'Acal Category',
                hidden: !(
                  planDetails.lineIdExternalCode === '51' ||
                  planDetails.lineIdExternalCode === '50' ||
                  planDetails.lineIdExternalCode === '4'
                ),
              },
              scratching: {
                multiline: false,
                value: planDetails.scratchingTitle,
                name: 'scratching',
                title: 'Scratching',
                hidden: !(
                  planDetails.lineIdExternalCode === '51' ||
                  planDetails.lineIdExternalCode === '50' ||
                  planDetails.lineIdExternalCode === '4'
                ),
              },
              sosService: {
                multiline: false,
                value: planDetails.sOSServiceTitle,
                name: 'sosService',
                title: 'SOS Service',
                hidden: !(
                  planDetails.lineIdExternalCode === '51' ||
                  planDetails.lineIdExternalCode === '4'
                ),
              },
              replacementCarApplied: {
                multiline: false,
                value: planDetails.replacementCarAppliedTitle,
                name: 'replacementCarApplied',
                title: 'Replacement Car Applied',
                hidden: !(planDetails.lineIdExternalCode === '51'),
              },
              replacementCarValue: {
                multiline: false,
                value: planDetails.replacementCarValue,
                name: 'replacementCarValue',
                title: 'Replacement Car Value',
                type: EnhancedDisplayType.RoundedCurrency,
                hidden: !(planDetails.lineIdExternalCode === '51'),
              },
              replacementCarDays: {
                multiline: false,
                value: planDetails.replacementCarDays,
                name: 'replacementCarDays',
                title: 'Replacement Car Days',
                hidden: !(planDetails.lineIdExternalCode === '51'),
              },
              grLimitDetails: {
                multiline: false,
                value: planDetails.grLimitDetails,
                name: 'grLimitDetails',
                title: 'GR Limit Days',
                hidden: !['6', '19'].includes(planDetails.lineIdExternalCode),
              },
            },
          });
        }

        setPlanInformationViewConfig(summaryDynamicView);

        const coversDynamicView: IDynamicDiplayView = {
          name: 'Plan Details',
          sections: [
            {
              name: 'generalDetails',
              title: 'General Details',
              showActionButtons: true,
              properties: {
                planName: {
                  multiline: false,
                  value: planDetails.relatedCompanyTitle,
                  name: 'planName',
                  title: 'Plan Name',
                },
                externalCode: {
                  multiline: false,
                  value: capitalizeFirstLetter(planDetails.typeTitle),
                  name: 'externalCode',
                  title: 'External Code',
                },
                arabicName: {
                  multiline: false,
                  value: planDetails.categoryTitle,
                  name: 'arabicName',
                  title: 'Arabic Name',
                },
                abbreviation: {
                  multiline: false,
                  value: planDetails.isCompany ? 'Yes' : 'No',
                  name: 'abbreviation',
                  title: 'Abbreviation',
                },
                effectiveFrom: {
                  multiline: false,
                  value: planDetails.isLocal ? 'Yes' : 'No',
                  name: 'effectiveFrom',
                  title: 'Effective From',
                },
                effectiveTo: {
                  multiline: false,
                  value: planDetails.isRegistered ? 'Yes' : 'No',
                  name: 'effectiveTo',
                  title: 'Effective To',
                },
                planCurrency: {
                  multiline: false,
                  value: planDetails.registerNumber,
                  name: 'planCurrency',
                  title: 'Plan Currency',
                },
              },
            },
            {
              name: 'additionalDetails',
              title: 'Additional Details',
              showActionButtons: false,
              hidden: !(
                lineExternalCode === '51' ||
                lineExternalCode === '50' ||
                lineExternalCode === '4'
              ),
              properties: {
                acalCategory: {
                  multiline: false,
                  value: capitalizeFirstLetter(planDetails.smoker),
                  name: 'acalCategory',
                  title: 'Acal Category',
                  hidden: !(
                    lineExternalCode === '51' ||
                    lineExternalCode === '50' ||
                    lineExternalCode === '4'
                  ),
                },
                sosService: {
                  multiline: false,
                  value: planDetails.weight,
                  name: 'sosService',
                  title: 'SOS Service',
                  hidden: !(
                    lineExternalCode === '51' || lineExternalCode === '4'
                  ),
                },
                replacementCarApplied: {
                  multiline: false,
                  value: planDetails.height,
                  name: 'replacementCarApplied',
                  title: 'Replacement Car Applied',
                  hidden: !(lineExternalCode === '51'),
                },
                replacementCarValue: {
                  multiline: false,
                  value: planDetails.dateOfDeath,
                  name: 'replacementCarValue',
                  title: 'Replacement Car Value',
                  hidden: !(lineExternalCode === '51'),
                  type: EnhancedDisplayType.Date,
                },
                replacementCarDays: {
                  multiline: false,
                  value: planDetails.dateOfDeathNotified,
                  name: 'replacementCarDays',
                  title: 'Replacement Car Days',
                  hidden: !(lineExternalCode === '51'),
                  type: EnhancedDisplayType.Date,
                },
              },
            },
          ],
        };
        setCoversInformationViewConfig(coversDynamicView);
        const defaultCommissionView: IDynamicDiplayView = {
          name: 'Business Partner Default Commissions',
          sections: [
            {
              name: 'businessPartnerDefaultCommissions',
              title: 'Business Partner Default Commissions',
              showActionButtons: true,
              properties: {
                specialCommissionApplicable: {
                  multiline: false,
                  value: planDetails.specialCommissionApplicable ? 'Yes' : 'No',
                  name: 'specialCommissionApplicable',
                  title: 'Special Commission Applicable',
                },
                specialCommissionType: {
                  multiline: false,
                  value: capitalizeFirstLetter(
                    planDetails.specialCommissionType
                  ),
                  name: 'specialCommissionType',
                  title: 'Special Commission Type',
                  hidden: !planDetails.specialCommissionApplicable,
                },
                specialCommissionValue: {
                  multiline: false,
                  value: valueCommaSeparated(
                    planDetails.specialCommissionValue
                  ),
                  name: 'specialCommissionValue',
                  title: 'Special Commission Value',
                  hidden: !(
                    planDetails.specialCommissionApplicable &&
                    planDetails.specialCommissionType === 'VALUE'
                  ),
                },
                specialCommissionValueCurrency: {
                  multiline: false,
                  value: planDetails.specialCommissionValueCurrencyTitle,
                  name: 'specialCommissionValueCurrency',
                  title: 'Special Commission Value Currency',
                  hidden: !(
                    planDetails.specialCommissionApplicable &&
                    planDetails.specialCommissionType === 'VALUE'
                  ),
                },
                specialCommissionRate: {
                  multiline: false,
                  value: planDetails.specialCommissionRateTitle,
                  name: 'specialCommissionRate',
                  title: 'Special Commission Rate',
                  hidden: !(
                    planDetails.specialCommissionApplicable &&
                    planDetails.specialCommissionType === 'RATE'
                  ),
                },
                specialCommissionApplicableOn: {
                  multiline: false,
                  value: planDetails.specialCommissionApplicableOnTitle,
                  name: 'specialCommissionApplicableOn',
                  title: 'Special Commission Applicable On',
                  hidden: !(
                    planDetails.specialCommissionApplicable &&
                    planDetails.specialCommissionType === 'RATE'
                  ),
                },
              },
            },
          ],
        };
        setDefaultCommissionViewConfig(defaultCommissionView);

        const additionalFeesandChargesView: IDynamicDiplayView = {
          name: 'Additional Fees & Charges',
          sections: [
            {
              name: 'additionalFeesandCharges',
              title: 'Additional Fees & Charges',
              showActionButtons: true,
              properties: {
                policyCost: {
                  multiline: false,
                  value: planDetails.policyCostTitle,
                  name: 'policyCost',
                  title: 'Policy Cost',
                },
                endorsementCost: {
                  multiline: false,
                  value: planDetails.endorsementCostTitle,
                  name: 'endorsementCost',
                  title: 'Endorsement Cost',
                },
                chargesPercentage: {
                  multiline: false,
                  value: planDetails.chargesPercentageTitle,
                  name: 'chargesPercentage',
                  title: 'Charges Percentage',
                },
                tPAFeesAmount: {
                  multiline: false,
                  value: planDetails.tPAFeesAmountTitle,
                  name: 'tPAFeesAmount',
                  title: 'TPA Fees Amount',
                  hidden: planDetails.tPAFeesType === 'PERCENTAGE',
                },
                tPAFeesPercentage: {
                  multiline: false,
                  value: planDetails.tPAFeesPercentageTitle,
                  name: 'tPAFeesPercentage',
                  title: 'TPA Fees Percentage',
                  hidden: planDetails.tPAFeesType !== 'PERCENTAGE',
                },
                tPAFeesApplicableOn: {
                  multiline: false,
                  value: planDetails.tPAFeesApplicableOnTitle,
                  name: 'tPAFeesApplicableOn',
                  title: 'TPA Fees ApplicableOn',
                  hidden: planDetails.tPAFeesType !== 'PERCENTAGE',
                },
                minTPA: {
                  multiline: false,
                  value: planDetails.minTPATitle,
                  name: 'minTPA',
                  title: 'Min TPA',
                  hidden: planDetails.tPAFeesType !== 'PERCENTAGE',
                },
                maxTPA: {
                  multiline: false,
                  value: planDetails.maxTPATitle,
                  name: 'maxTPA',
                  title: 'Max TPA',
                  hidden: planDetails.tPAFeesType !== 'PERCENTAGE',
                },
              },
            },
          ],
        };
        setAdditionalFeesandChargesViewConfig(additionalFeesandChargesView);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityResult]);

  const handlePlanSectionChange = async () => {
    try {
      client.refetchQueries({
        include: [getPlanDetailsInfo()],
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{error}</ToastErrorMessage>);
    }
  };

  const renderMainChildren = () => {
    const status =
      entityResult?.PlanConfigManagement?.entities?.plan?.views?.PlanConfigManagement_all?.properties?.PlanStatus.toLowerCase();

    const lineStatus =
      entityResult?.PlanConfigManagement?.entities?.plan?.views
        ?.PlanConfigManagement_all?.properties?.LineID?.views
        ?.PlanConfigManagement_all?.properties?.Status;

    const sublineStatus =
      entityResult?.PlanConfigManagement?.entities?.plan?.views?.PlanConfigManagement_all?.properties?.SublineID?.views?.PlanConfigManagement_all?.properties?.Status.toLowerCase();

    const isPlanActive = status === 'active';

    return (
      <>
        <div style={{ marginTop: '20px' }}>
          <EnhancedStepperSpecificDesign
            activeStep={getPlanStatus(status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isPlanActive ? 'Deactivate' : 'Activate'}
            buttonState={
              isPlanActive ? activateButtonState : deactivateButtonState
            }
            buttonOnClick={() => {
              if (isPlanActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: 'Deactivate',
                  message: `Are you sure you want to deactivate this plan?`,
                  callback: async () => {
                    setDeactivateButtonState('loading');
                    deactivateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setDeactivateButtonState('success');
                          toast.success(
                            <ToastSuccessMessage>
                              {
                                'Plan successfully deactivated. Plans assigned to Business Partners are deactivated as well.'
                              }
                            </ToastSuccessMessage>
                          );
                        } else {
                          setDeactivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setDeactivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: 'Yes',
                  cancelButtonText: 'No',
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: 'Activate',
                  message: `Are you sure you want to activate this plan?`,
                  callback: async () => {
                    setActivateButtonState('loading');
                    activateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setActivateButtonState('success');
                          toast.success(
                            <ToastSuccessMessage>
                              {'Plan successfully activated.'}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setActivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setActivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: 'Yes',
                  cancelButtonText: 'No',
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
          {planDrawerOpen && (
            <PlanDrawer
              open={planDrawerOpen}
              lineId={
                entityResult?.PlanConfigManagement?.entities?.plan?.views
                  ?.PlanConfigManagement_all?.properties?.LineID?.id
              }
              lineName={
                entityResult?.PlanConfigManagement?.entities?.plan?.views
                  ?.PlanConfigManagement_all?.properties?.LineID?.views
                  ?.PlanConfigManagement_all?.properties?.Name
              }
              lineStatus={lineStatus.toLowerCase()}
              planId={entityId}
              sublineID={
                entityResult?.PlanConfigManagement?.entities?.plan?.views
                  ?.PlanConfigManagement_all?.properties?.SublineID?.id
              }
              isSublineNameDisabled={true}
              sublineStatus={sublineStatus}
              onClose={() => setPlanDrawerOpen(false)}
              onSuccess={() => {
                handlePlanSectionChange();
              }}
            />
          )}
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: '' }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={planDetailsInfo.planName}
          view={updatedLeftSideInfo}
          // iconUrl={tenant.cdnUrl + "/icons/edit-secondary.svg"}
          // onClickIcon={handleIconClick}
        ></EntityInfoWidget>
      </>
    );
  };

  const allRiskPlanRatesListingActions: IEnhancedMenuItem[] = [
    {
      name: 'new',
      rowIndependant: true,
      title: 'New',
      onClick: () => {
        setAllRiskPlanRatesDrawerOpen({
          open: true,
        });
      },
      target: ActionTarget.Entity,
      iconUri: tenant.cdnUrl + '/icons/add-primary-red.svg',
    },
    {
      name: 'edit',
      title: 'Edit',
      onClick: (selected) => {
        setAllRiskPlanRatesDrawerOpen({
          open: true,
          id: selected?.[0],
        });
      },
      target: ActionTarget.Entity,
      iconUri: tenant.cdnUrl + '/icons/edit-secondary.svg',
    },
  ];

  const expatPlanRatesListingActions: IEnhancedMenuItem[] = [
    {
      name: 'new',
      rowIndependant: true,
      title: 'New',
      onClick: () => {
        setExpatPlanRatesDrawerOpen({
          open: true,
        });
      },
      target: ActionTarget.Entity,
      iconUri: tenant.cdnUrl + '/icons/add-primary-red.svg',
    },
    {
      name: 'edit',
      title: 'Edit',
      onClick: (selected) => {
        setExpatPlanRatesDrawerOpen({
          open: true,
          id: selected?.[0],
        });
      },
      target: ActionTarget.Entity,
      iconUri: tenant.cdnUrl + '/icons/edit-secondary.svg',
    },
  ];

  const travelPlanRatesListingActions: IEnhancedMenuItem[] = [
    {
      name: 'new',
      rowIndependant: true,
      title: 'New',
      onClick: () => {
        setTravelPlanRatesDrawerOpen({
          open: true,
        });
      },
      target: ActionTarget.Entity,
      iconUri: tenant.cdnUrl + '/icons/add-primary-red.svg',
    },
    {
      name: 'edit',
      title: 'Edit',
      onClick: (selected) => {
        setTravelPlanRatesDrawerOpen({
          open: true,
          id: selected?.[0],
        });
      },
      target: ActionTarget.Entity,
      iconUri: tenant.cdnUrl + '/icons/edit-secondary.svg',
    },
  ];

  const medicalPlanRatesListingActions: IEnhancedMenuItem[] = [
    {
      name: 'new',
      rowIndependant: true,
      title: 'New',
      onClick: () => {
        setMedicalPlanRatesDrawerOpen({
          open: true,
        });
      },
      target: ActionTarget.Entity,
      iconUri: tenant.cdnUrl + '/icons/add-primary-red.svg',
    },
    {
      name: 'edit',
      title: 'Edit',
      onClick: (selected) => {
        setMedicalPlanRatesDrawerOpen({
          open: true,
          id: selected?.[0],
        });
      },
      target: ActionTarget.Entity,
      iconUri: tenant.cdnUrl + '/icons/edit-secondary.svg',
    },
  ];

  const planCoversRatesListingActions: IEnhancedMenuItem[] = [
    {
      name: 'new',
      rowIndependant: true,
      title: 'New',
      onClick: () => {
        setPlanCoversRatesDrawerOpen({
          open: true,
        });
      },
      target: ActionTarget.Entity,
      iconUri: tenant.cdnUrl + '/icons/add-primary-red.svg',
    },
    {
      name: 'edit',
      title: 'Edit',
      onClick: (selected) => {
        setPlanCoversRatesDrawerOpen({
          open: true,
          id: selected?.[0],
        });
      },
      target: ActionTarget.Entity,
      iconUri: tenant.cdnUrl + '/icons/edit-secondary.svg',
    },
  ];
  //#endregion

  //#region tabs
  const tabs = () => {
    planTabs.tabs[0].widgets[0].children = (
      <>
        {planInformationViewConfig && (
          <div style={{ marginTop: '20px' }}>
            <DynamicWidget
              hasFourFields={true}
              view={planInformationViewConfig}
              currencySymbol={planDetailsInfo.planCurrencySymbol}
              actions={
                <ShowForUser allowedRoles={['Insurance-Admin']}>
                  {status != 'inactive' && (
                    <div>
                      <EnhancedButton
                        type="button"
                        backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                        color="#FFF"
                        onClick={() => setPlanInformationDrawerOpen(true)}
                        className={classes.actionButton}
                      >
                        Modify
                      </EnhancedButton>
                    </div>
                  )}
                </ShowForUser>
              }
            />
          </div>
        )}
        {defaultCommissionViewConfig && (
          <div style={{ marginTop: '20px' }}>
            <DynamicWidget
              hasFourFields={true}
              view={defaultCommissionViewConfig}
              actions={
                <ShowForUser allowedRoles={['Insurance-Admin']}>
                  {status != 'inactive' && (
                    <div>
                      <EnhancedButton
                        type="button"
                        backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                        color="#FFF"
                        onClick={() => setDefaultCommissionDrawerOpen(true)}
                        className={classes.actionButton}
                      >
                        Modify
                      </EnhancedButton>
                    </div>
                  )}
                </ShowForUser>
              }
            />
          </div>
        )}
      </>
    );

    planTabs.tabs[1].widgets[0].children = (
      <>
        {coversInformationViewConfig && (
          <>
            <WidgetSection style={{ margin: '-23px 0 0 0' }}>
              <form>
                {lineExternalCode === '51' && (
                  <div style={{ marginTop: '20px', padding: '0' }}>
                    <ListingTableWithPagination
                      inlineTitle={capitalizeFirstLetter('All Risk Plan Rates')}
                      name="riskplanrates"
                      inline
                      loader={allRiskLoading}
                      data={allRiskPlanRatesTableData}
                      headers={allRiskPlanRatesHeaders}
                      onPageChange={handleAllRiskPlanRatesPageChange}
                      actions={allRiskPlanRatesListingActions}
                    />
                    {allRiskPlanRatesDrawerOpen.open && (
                      <AllRiskPlanRatesDrawer
                        open={allRiskPlanRatesDrawerOpen.open}
                        onClose={() =>
                          setAllRiskPlanRatesDrawerOpen({
                            open: false,
                          })
                        }
                        onSuccess={() => {
                          initialize();
                        }}
                        planId={params.id}
                        planCurrency={planCurrency}
                        planCurrencySymbol={planDetailsInfo.planCurrencySymbol}
                        allRiskPlanRateId={allRiskPlanRatesDrawerOpen.id}
                      />
                    )}
                  </div>
                )}
              </form>
            </WidgetSection>
            <WidgetSection style={{ margin: '-23px 0 0 0' }}>
              <form>
                {lineExternalCode === '43' && (
                  <div style={{ marginTop: '20px', padding: '0' }}>
                    <ListingTableWithPagination
                      inlineTitle={capitalizeFirstLetter('Expat Plan Rates')}
                      name="expatplanrates"
                      inline
                      loader={expatPlanLoading}
                      data={expatPlanRatesTableData}
                      headers={expatPlanRatesHeaders}
                      onPageChange={handleExpatPlanRatesPageChange}
                      actions={expatPlanRatesListingActions}
                    />
                    {expatPlanRatesDrawerOpen.open && (
                      <ExpatPlanRatesDrawer
                        open={expatPlanRatesDrawerOpen.open}
                        onClose={() =>
                          setExpatPlanRatesDrawerOpen({
                            open: false,
                          })
                        }
                        onSuccess={() => {
                          initialize();
                        }}
                        planId={params.id}
                        planCurrency={planCurrency}
                        planCurrencySymbol={planDetailsInfo.planCurrencySymbol}
                        expatPlanRateId={expatPlanRatesDrawerOpen.id}
                      />
                    )}
                  </div>
                )}
              </form>
            </WidgetSection>
            <WidgetSection style={{ margin: '-23px 0 0 0' }}>
              <form>
                {lineExternalCode === '48' && (
                  <div style={{ marginTop: '20px', padding: '0' }}>
                    <ListingTableWithPagination
                      inlineTitle={capitalizeFirstLetter('Travel Plan Rates')}
                      name="travelplanrates"
                      inline
                      loader={travelPlanLoading}
                      data={travelPlanRatesTableData}
                      headers={getTravelPlanRatesHeaders(
                        planDetailsInfo.planCurrencySymbol
                      )}
                      onPageChange={handleTravelPlanRatesPageChange}
                      actions={travelPlanRatesListingActions}
                    />
                    {travelPlanRatesDrawerOpen.open && (
                      <TravelPlanRatesDrawer
                        open={travelPlanRatesDrawerOpen.open}
                        onClose={() =>
                          setTravelPlanRatesDrawerOpen({
                            open: false,
                          })
                        }
                        onSuccess={() => {
                          initialize();
                        }}
                        planId={params.id}
                        planCurrency={planCurrency}
                        planCurrencySymbol={planDetailsInfo.planCurrencySymbol}
                        travelPlanRateId={travelPlanRatesDrawerOpen.id}
                        lineId={
                          entityResult?.PlanConfigManagement?.entities?.plan
                            ?.views?.PlanConfigManagement_all?.properties
                            ?.LineID?.id
                        }
                      />
                    )}
                  </div>
                )}
              </form>
            </WidgetSection>
            <WidgetSection style={{ margin: '-23px 0 0 0' }}>
              <form>
                {['6', '19'].includes(lineExternalCode) && (
                  <div style={{ marginTop: '20px', padding: '0' }}>
                    <ListingTableWithPagination
                      inlineTitle={capitalizeFirstLetter('Medical Plan Rates')}
                      name="medicalplanrates"
                      inline
                      loader={medicalPlanLoading}
                      data={medicalPlanRatesTableData}
                      headers={medicalPlanRatesHeaders}
                      onPageChange={handleMedicalPlanRatesPageChange}
                      actions={medicalPlanRatesListingActions}
                    />
                    {medicalPlanRatesDrawerOpen.open && (
                      <MedicalPlanRatesDrawer
                        open={medicalPlanRatesDrawerOpen.open}
                        onClose={() =>
                          setMedicalPlanRatesDrawerOpen({
                            open: false,
                          })
                        }
                        onSuccess={() => {
                          initialize();
                        }}
                        planId={params.id}
                        lineId={
                          entityResult?.PlanConfigManagement?.entities?.plan
                            ?.views?.PlanConfigManagement_all?.properties
                            ?.LineID?.id
                        }
                        planCurrency={planCurrency}
                        planCurrencySymbol={planDetailsInfo.planCurrencySymbol}
                        medicalPlanRateId={medicalPlanRatesDrawerOpen.id}
                      />
                    )}
                  </div>
                )}
              </form>
            </WidgetSection>
          </>
        )}

        {coversInformationViewConfig &&
          !['6', '19', '48'].includes(lineExternalCode) && (
            <>
              <WidgetSection style={{ margin: '-23px 0 0 0' }}>
                <form>
                  <div style={{ marginTop: '20px', padding: '0' }}>
                    <ListingTableWithPagination
                      inlineTitle={capitalizeFirstLetter('Plan Covers & Rates')}
                      name="plancovers"
                      inline
                      loader={planCoversLoading}
                      data={planCoversRatesTableData}
                      headers={planCoversRatesHeaders}
                      onPageChange={handlePlanCoversRatesPageChange}
                      actions={planCoversRatesListingActions}
                    />
                    {planCoversRatesDrawerOpen.open && (
                      <AssignPolicyCoverDrawer
                        open={planCoversRatesDrawerOpen.open}
                        onClose={() =>
                          setPlanCoversRatesDrawerOpen({
                            open: false,
                          })
                        }
                        onSuccess={() => {
                          handlePlanCoversRatesPageChange(1);
                        }}
                        lineId={
                          entityResult?.PlanConfigManagement?.entities?.plan
                            ?.views?.PlanConfigManagement_all?.properties
                            ?.LineID?.id
                        }
                        planId={params.id}
                        planCurrencyId={planCurrency}
                        planCurrencyTitle={planCurrencyTitle}
                        planCurrencySymbol={planDetailsInfo.planCurrencySymbol}
                        assignPolicyCoverId={planCoversRatesDrawerOpen.id}
                        planLineExternalCode={lineExternalCode}
                      />
                    )}
                  </div>
                </form>
              </WidgetSection>
            </>
          )}
        {additionalFeesandChargesViewConfig && (
          <div style={{ marginTop: '20px' }}>
            <DynamicWidget
              hasFourFields={true}
              view={additionalFeesandChargesViewConfig}
              actions={
                <ShowForUser allowedRoles={['Insurance-Admin']}>
                  <div>
                    <EnhancedButton
                      type="button"
                      backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                      color="#FFF"
                      onClick={() =>
                        setAdditionalFeesandChargesDrawerOpen({
                          open: true,
                        })
                      }
                      className={classes.actionButton}
                    >
                      Modify
                    </EnhancedButton>
                  </div>
                </ShowForUser>
              }
            />
          </div>
        )}
      </>
    );

    return planTabs;
  };
  //#endregion tabs

  return entityLoading ? (
    <Loader />
  ) : (
    <>
      {planInformationDrawerOpen && (
        <PlanInformationDrawer
          open={planInformationDrawerOpen}
          onClose={() => setPlanInformationDrawerOpen(false)}
          onSuccess={() => {
            getPlanDetailsLazy();
            initialize();
          }}
          planId={params.id}
          planDetailsInfo={entityToPlanDetails(entityResult)}
        />
      )}
      {defaultCommissionDrawerOpen && (
        <PlanDetailsDefaultCommissionDrawer
          open={defaultCommissionDrawerOpen}
          onClose={() => setDefaultCommissionDrawerOpen(false)}
          onSuccess={() => {
            getPlanDetailsLazy();
          }}
          planId={params.id}
          defaultCommissionDetailsInfo={entityToPlanDetails(entityResult)}
        />
      )}
      {additionalFeesandChargesDrawerOpen.open && (
        <PlanAdditionalFeesAndChargesDrawer
          open={additionalFeesandChargesDrawerOpen.open}
          onClose={() =>
            setAdditionalFeesandChargesDrawerOpen({
              open: false,
            })
          }
          onSuccess={() => {
            getPlanDetailsLazy();
          }}
          planId={params.id}
          planCurrencySymbol={planDetailsInfo.planCurrencySymbol}
          additionalFeesAndChargesDetailsInfo={entityToPlanDetails(
            entityResult
          )}
          lineExternalCode={lineExternalCode}
        />
      )}
      <TabsLayout
        name="policyPageDetails"
        layout={tabs()}
        theme={tenant.theme}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
        cdnUrl={tenant.cdnUrl}
        userInfo={user['info']}
        tabPanelClassName={classes.tabPanelStyle}
        firstTabAsActiveTab={true}
      />
    </>
  );
};

export default PlanDetailsPage;

import React from 'react';
import { IPaymentTermsPopupProps } from '.';
import EnhancedModal from '../../components/common/generic-modal/EnhancedModal';
import PaymentTermsForms from './PaymentTermsForms';

const PaymentTermsPopup: React.FunctionComponent<IPaymentTermsPopupProps> = ({
  generalData,
  currencySymbol,
  data,
  open,
  onClose,
}) => {
  const isProductionBill = generalData.bill.type === 'production';

  return (
    <EnhancedModal
      open={open}
      blur={true}
      onClose={() => {
        onClose(false);
      }}
      title={`Change Payment Terms (${isProductionBill ? generalData.policy.number : generalData.amendment.number})`}
    >
      <PaymentTermsForms
        isProductionBill={isProductionBill}
        generalData={generalData}
        currencySymbol={currencySymbol}
        onClose={onClose}
        data={data}
      />
    </EnhancedModal>
  );
};

export default PaymentTermsPopup;

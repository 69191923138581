import { gql } from '@apollo/client';

export function modifyExpat() {
  return gql`
    mutation modifyExpat(
      $entityId: String!
      $expatInput: Production_ExpatDetailsInputs!
    ) {
      production {
        entities {
          policy {
            production {
              modifyExpat(entityId: $entityId, expatInput: $expatInput)
            }
          }
        }
      }
    }
  `;
}

import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ISublineDetails, initialValues } from '.';
import Loader from '../../../components/Loader';
import ToastErrorMessage from '../../../components/ToastErrorMessage';
import EntityInfoWidget from '../../../components/widgets/entity-info/EntityInfoWidget';
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from '../../../constants';
import { getEntityView, planHeaders, steps } from './content';
import {
  activateSubline,
  deactivateSubline,
  getSublineDetailsInfo,
  getSublineDetailsPagePlanList,
} from './queries';
import {
  entityToSublineDetails,
  getSublineStatus,
  LookupToList,
  mapToPlansListingData,
} from './utils';
import { capitalizeFirstLetter } from '../../../utils/formatting-utils';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IListingData } from '../../../models/listing';
import WidgetSection from '../../../components/common/WidgetSection';
import EnhancedStepperSpecificDesign from '../../../components/common/EnhancedStepperSpecificDesign';
import { makeStyles } from 'tss-react/mui';
import PlanDrawer from '../../../forms/plan-drawer/PlanDrawer';
import StaticLayout from '../../../page-layout/static-layout/StaticLayout';
import SublineDrawer from '../../../forms/subline-drawer/SublineDrawer';
import { EnhancedButtonStatus } from '../../../components/EnhancedButton';
import { IConfirmation } from '../../../redux/confirmation/types';
import { isEmpty } from 'lodash';
import ToastSuccessMessage from '../../../components/ToastSuccessMessage';
import { getError } from '../../../utils/graph-utils';
import { OpenConfirmationAction } from '../../../redux/confirmation/actions';
import { capitalizeFirstCharacter } from '../../../utils/formatting-utils';
import ListingTableWithPagination from '../../../components/form-fields/table/with-pagination/ListingTableWithPagination';
import {
  ActionTarget,
  IEnhancedMenuItem,
} from '../../../components/form-fields/table';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '90%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ': {
      color: MAIN_ONE_THEME.palette.primary5.main,
      '& .MuiStepIcon-text': {
        fontSize: 0,
        lineHeight: '0px',
        fill: 'black',
      },
    },

    '.Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
      '& .MuiStepIcon-text': {
        fontSize: 0,
        lineHeight: '0px',
        fill: 'black',
      },
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  section: {
    display: 'grid',
    'grid-template-columns': '19.5% 19.5% 19.5% 19.5% 19.5%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '& label': {
      '& span': {
        margin: '-2px 0',
      },
    },
  },
  sectionTitle: {
    padding: '-10x 10x!important',
  },
  sectionFullRow: {
    display: 'grid',
    'grid-template-columns': '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  row: {
    flexWrap: 'wrap',
    margin: '15px 20px 10px 0px',
    alignItems: 'flex-start',
    display: 'grid',
    gap: '5px',
    '& label': {
      flexGrow: '1',
      flexBasis: '0',
      minWidth: '0',
    },
    'grid-template-columns': '19.5% 19.5% 19.5% 19% 16% 3% 3%',
  },
  thickSeperator: {
    height: 10,
    margin: '10px 0 10px 0',
    'grid-column-start': '1',
    'grid-column-end': '4',
  },
  field: {
    width: '96%',
    marginRight: '10px !important',
  },
  repeaterField: {
    margin: '0px!important',
  },
  arField: {
    width: '96%',
    marginRight: '10px !important',
    direction: 'rtl',
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    '&:hover': {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: '0',
  },
  dirtyChip: {
    margin: '0 0 -15px 0',
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    '& span': {
      color: '#ffffff',
    },
  },
  tabPanelStyle: {
    padding: 'unset',
    marginTop: '20px',
  },
}));

const SublineDetailsPage: React.FC = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [sublineDrawerOpen, setSublineDrawerOpen] = useState<{
    open: boolean;
    id?: string;
  }>({
    open: false,
  });
  const [planDrawerOpen, setPlanDrawerOpen] = useState<{
    open: boolean;
    id?: string;
  }>({
    open: false,
  });
  //#endregion

  //#region component hooks and states
  const [sublineDetailsInfo, setSublineDetailsInfo] =
    useState<ISublineDetails>(initialValues);

  const entityId = params.id;

  //#endregion

  const isAdmin = user.userRoles.includes('Insurance-Admin');

  const planListingActions: IEnhancedMenuItem[] = isAdmin
    ? [
        {
          name: 'new',
          title: 'New',
          onClick: () => {
            setPlanDrawerOpen({
              open: true,
            });
          },
          iconUri: tenant.cdnUrl + '/icons/add-primary-red.svg',
        },
        {
          name: 'edit',
          title: 'Edit',
          onClick: (selected) => {
            setPlanDrawerOpen({
              open: true,
              id: selected?.[0],
            });
          },
          iconUri: tenant.cdnUrl + '/icons/edit-secondary.svg',
          target: ActionTarget.Entity,
        },
      ]
    : [];

  //#region graphql hooks

  const [activateAction] = useMutation(activateSubline(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: 'all',
    refetchQueries: [getSublineDetailsInfo()],
  });
  const [deactivateAction] = useMutation(deactivateSubline(), {
    variables: {
      entityId: entityId,
    },
    refetchQueries: [getSublineDetailsInfo(), getSublineDetailsPagePlanList()],
  });

  const [planTableData, setPlanTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [planListResultQuery, { loading: planDataLoading }] = useLazyQuery(
    getSublineDetailsPagePlanList()
  );

  const [entityResultQuery, { loading }] = useLazyQuery(
    getSublineDetailsInfo()
  );

  const loadEntityData = async () => {
    const result = await entityResultQuery({
      variables: { id: entityId },
      errorPolicy: 'all',
    });

    return result;
  };

  const loadPlanList = async (
    currentPage: number,
    pageSize = planTableData.pageSize,
    searchKey?: string,
    order?: string,
    orderBy?: string
  ) => {
    const result = await planListResultQuery({
      variables: {
        currentPage,
        currentPageSize: pageSize,
        SelectedSublineID: params.id,
        Attribute: capitalizeFirstCharacter(
          orderBy || 'PlanConfigManagement_Plan_Name'
        ),
        Descending: order == 'asc' ? false : true,
        keywordSearch: searchKey || null,
      },
      errorPolicy: 'all',
    });

    return result;
  };

  //#endregion

  //#region useEffect
  const initialize = async () => {
    const [entityData] = await Promise.all([loadEntityData()]);
    const sublineEnums = LookupToList(entityData.data);
    const sublineDetails = await entityToSublineDetails(
      entityData.data,
      sublineEnums
    );
    setSublineDetailsInfo(sublineDetails);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region functions

  async function handlePlanPageChange(
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) {
    const result = await loadPlanList(
      page,
      rowsPerPage || planTableData.pageSize,
      searchKey,
      order,
      orderBy
    );
    if (result.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
      return;
    }
    const mappedSubList = mapToPlansListingData(result.data);
    setPlanTableData(mappedSubList);
  }

  const renderMainChildren = () => {
    const status = sublineDetailsInfo?.status.toLowerCase();
    const lineStatus = sublineDetailsInfo.lineStatus;
    const isSublineActive = status === 'active';

    return (
      <>
        <div style={{ marginTop: '20px' }}>
          <EnhancedStepperSpecificDesign
            activeStep={getSublineStatus(status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isSublineActive ? 'Deactivate' : 'Activate'}
            buttonState={
              isSublineActive ? activateButtonState : deactivateButtonState
            }
            buttonOnClick={async () => {
              if (isSublineActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: 'Deactivate',
                  message: `Are you sure you want to deactivate this subline?`,
                  callback: async () => {
                    try {
                      setDeactivateButtonState('loading');
                      const result = await deactivateAction();

                      if (isEmpty(result.errors)) {
                        setDeactivateButtonState('success');
                        initialize();
                        toast.success(
                          <ToastSuccessMessage>
                            {
                              'Subline successfully deactivated. Relevant plans are deactivated as well.'
                            }
                          </ToastSuccessMessage>
                        );
                      } else {
                        setDeactivateButtonState(undefined);
                        toast.error(
                          <ToastErrorMessage>
                            {getError(result)}
                          </ToastErrorMessage>
                        );
                      }
                    } finally {
                      setTimeout(() => {
                        setDeactivateButtonState(undefined);
                      }, 1000);
                    }
                  },
                  submitButtonText: 'Yes',
                  cancelButtonText: 'No',
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: 'Activate',
                  message: `Are you sure you want to activate this subline?`,
                  callback: async () => {
                    try {
                      setActivateButtonState('loading');
                      const result = await activateAction();

                      if (isEmpty(result.errors)) {
                        setActivateButtonState('success');
                        initialize();
                        toast.success(
                          <ToastSuccessMessage>
                            {'Subline successfully activated.'}
                          </ToastSuccessMessage>
                        );
                      } else {
                        setActivateButtonState(undefined);
                        toast.error(
                          <ToastErrorMessage>
                            {getError(result)}
                          </ToastErrorMessage>
                        );
                      }
                    } finally {
                      setTimeout(() => {
                        setActivateButtonState(undefined);
                      }, 1000);
                    }
                  },
                  submitButtonText: 'Yes',
                  cancelButtonText: 'No',
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
          <WidgetSection style={{ margin: '-23px 0 0 0' }}>
            <form>
              <div
                style={{
                  marginTop: '20px',
                  padding: '-23px 0 0 0 !important',
                }}
              >
                <ListingTableWithPagination
                  inlineTitle={capitalizeFirstLetter('Plans')}
                  name="plans"
                  orderByAscendingByDefault
                  defaultOrderByColumn="PlanConfigManagement_Plan_Name"
                  inline
                  data={planTableData}
                  headers={planHeaders}
                  onPageChange={handlePlanPageChange}
                  actions={planListingActions}
                  loader={planDataLoading}
                />
                {planDrawerOpen.open && (
                  <PlanDrawer
                    open={planDrawerOpen.open}
                    lineId={sublineDetailsInfo.lineId}
                    lineName={sublineDetailsInfo.lineName}
                    lineStatus={lineStatus.toLowerCase()}
                    planId={planDrawerOpen.id}
                    sublineID={entityId}
                    sublineName={sublineDetailsInfo.sublineName}
                    isSublineNameDisabled={true}
                    onClose={() =>
                      setPlanDrawerOpen({
                        open: false,
                      })
                    }
                    onSuccess={async () => {
                      await initialize();
                    }}
                  />
                )}
                {sublineDrawerOpen.open && (
                  <SublineDrawer
                    open={sublineDrawerOpen.open}
                    lineId={sublineDetailsInfo.lineId}
                    lineName={sublineDetailsInfo.lineName}
                    lineStatus={lineStatus.toLowerCase()}
                    sublineId={entityId}
                    onClose={() =>
                      setSublineDrawerOpen({
                        open: false,
                      })
                    }
                    onSuccess={() => {
                      initialize();
                    }}
                  />
                )}
              </div>
            </form>
          </WidgetSection>
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: '' }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={sublineDetailsInfo.sublineName}
          view={getEntityView(sublineDetailsInfo)}
          iconUrl={tenant.cdnUrl + '/icons/edit-secondary.svg'}
          onClickIcon={() => {
            setSublineDrawerOpen({
              open: true,
            });
          }}
        ></EntityInfoWidget>
      </>
    );
  };

  //#endregion

  return loading ? (
    <Loader />
  ) : (
    <StaticLayout
      name={'Subline Details'}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default SublineDetailsPage;

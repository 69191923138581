import React, { useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { NavLink } from 'react-router-dom';
import {
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input';
import { IEnhancedRow } from './EnhancedTableBody';
import {
  EnhancedHeaderInputTypes,
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '.';
import {
  parseStringTemplate,
  addZeroesAndSeparatevalue,
  maskValueWithPatternIfMatch,
  isExternalLink,
  trimTrailingZeros,
} from '../../utils/formatting-utils';
import {
  isValidNumber,
  isEmpty,
  isValidDecimalCount,
} from '../../utils/validationUtils';
import LimitedTextWrapper from '../LimitTextWrapper';
import { IEnhancedCommonProps } from '..';
import { makeStyles } from 'tss-react/mui';
import { Button, Tooltip } from '@mui/material';
import {
  MAIN_ONE_THEME,
  contentFontFamilyRegular,
  contentFontFamilyRegularImportant,
} from '../../constants';
import EnhancedToolTip from '../enhanced-tooltip/EnhancedTooltip';
import EnhancedToolTipTable from '../enhanced-tooltip/EnhancedToolTipTable';
import EnhancedFormattedDecimalInput from '../enhanced-form/EnhancedFormattedDecimalInput';
import EnhancedInput from '../enhanced-form/EnhancedInput';
import FormattingServices from '../../services/formating-services';
import CurrencyFormField from '../form-fields/CurrencyFormField';
import DatePickerFormField from '../form-fields/DatePickerFormField';
import SelectFormField from '../form-fields/SelectFormField';
import clsx from 'clsx';
import DateService from '../../services/dateService';
import PrecisionService from '../../services/precisionService';

interface IEnhancedTableBodyCellProps extends IEnhancedCommonProps {
  row: IEnhancedRow;
  header: EnhancedTableHeader;
  currencySymbol?: string;
  selected: boolean;
  handleUpdate?(rowIndex: number, property: string, value: any): void;
  showCellFullData?: boolean;
  handleCellValueChanged?(
    rowIndex: number,
    columnId: string,
    newValue: any,
    rowId?: string
  ): void;
  tableClasses?: {
    inputContainer?: string;
  };
}

const useStyles = makeStyles()(() => ({
  cellRoot: {
    color: `${MAIN_ONE_THEME.palette.primary1.main} !important`,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
    lineHeight: '12px !important',
    height: '34px !important',
    border: 'none !important',
    padding: '2px 8px',
    fontFamily: contentFontFamilyRegularImportant,
  },
  cellLink: {
    color: `${MAIN_ONE_THEME.palette.primary1.main} !important`,
    fontFamily: contentFontFamilyRegular,
  },
  cellbutton: {
    textAlign: 'left',
    color: MAIN_ONE_THEME.palette.primary1.main,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    lineHeight: '12px',
    height: 34,
    padding: 0,
    fontFamily: contentFontFamilyRegular,
  },
  disabledButton: {
    cursor: 'not-allowed',
  },
  cellBadge: {
    borderRadius: '5px',
    padding: '5px',
    display: 'inline-block',
    minWidth: '50px',
    textAlign: 'center',
  },
  classificationContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    padding: '0 5px 0 0',
  },
  icon: {
    minHeight: '15px',
    minWidth: '15px',
    borderRadius: '50%',
    margin: '0 7px 0 0',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  classificationInput: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  input: {
    width: '90%',
    maxWidth: '200px',
    border: '1px solid #E5E5E5',
    height: '28px',
    margin: '0 auto',
    fontSize: '13px',
    boxSizing: 'border-box',
    lineHeight: '15px',
    borderRadius: '5px',
    backgroundColor: 'white',
    minHeight: '28px !important',
    fontFamily: contentFontFamilyRegular,
    '& .MuiInputBase-root': {
      minHeight: '28px !important',
    },
  },
  editableInput: {
    width: '100px',
    maxWidth: '100px',
    border: '1px solid #E5E5E5',
    height: '28px',
    margin: '0 auto',
    fontSize: '13px',
    boxSizing: 'border-box',
    lineHeight: '15px',
    borderRadius: '5px',
    backgroundColor: 'white',
    fontFamily: contentFontFamilyRegular,
  },
  underlined: {
    textDecoration: 'underline',
  },
  positiveCurrency: {
    color: '#78b33a',
  },
  negativeCurrency: {
    color: '#d03536',
  },
  svgClickableButton: {
    height: 'auto',
    padding: 3,
    boxShadow: `0 0 3px #aaa`,
    borderRadius: 4,
    transition: '125ms',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  svgClickableIcon: {
    width: '15px',
    height: '15px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    '@media only screen and (max-width: 650px)': {
      width: '12px',
      height: '12px',
    },
  },
  inputContainer: {
    maxWidth: '200px',
    height: '30px !important',
    maxHeight: '30px',
    margin: '0 0 0 -4px !important',
    '& .MuiInputBase-input': {
      maxHeight: '30px !important',
    },
  },
}));

const EnhancedTableBodyCell: React.FC<IEnhancedTableBodyCellProps> = ({
  row,
  header,
  className,
  selected,
  handleUpdate = () => {},
  showCellFullData,
  handleCellValueChanged = () => {},
  tableClasses,
}) => {
  const { classes } = useStyles();
  const [currentValue, setCurrentValue] = useState<any>();
  const currencySymbol = '$';
  const phoneNumberPattern = '';

  useEffect(() => {
    setCurrentValue(row.columns[header.name]);
  }, [header.name, row.columns]);

  function renderCellContents(): JSX.Element | string {
    switch (header.type) {
      case EnhancedTableHeaderType.Link: {
        return !isEmpty(row.columns[header.name]) &&
          isExternalLink(row.columns[header.name]) !== null ? (
          <button
            type="button"
            onClick={() => {
              window.open(row.columns[header.name]);
            }}
          >
            <LimitedTextWrapper
              text={row.columns[header.name]}
              maxCharacter={40}
            />
          </button>
        ) : (
          <NavLink
            className={classes.cellLink}
            target={header.openUrlInNewTab ? '_blank' : undefined}
            to={parseStringTemplate(
              header.urlTemplate,
              row.columns.id,
              row.columns
            )}
            // exact
          >
            <button className={classes.cellbutton}>
              {row.columns[header.name]}
            </button>
          </NavLink>
        );
      }
      case EnhancedTableHeaderType.Date:
        return DateService.formatDate(row.columns[header.name]);
      case EnhancedTableHeaderType.DateAndTime:
        return DateService.formatDateTime(row.columns[header.name]);
      case EnhancedTableHeaderType.Clickable:
        if (header.icon) {
          return (
            <Button onClick={() => header.callback(row)}>{header.icon}</Button>
          );
        } else {
          return (
            <button
              className={classes.cellbutton}
              type="button"
              onClick={() => header.callback(row)}
            >
              {header.buttonLabel || row.columns[header.name]}
            </button>
          );
        }
      case EnhancedTableHeaderType.Badge:
        return (
          <span
            className={classes.cellBadge}
            style={{
              color:
                header.badgeConfig[row.columns[header.name].toString()].color,
              backgroundColor:
                header.badgeConfig[row.columns[header.name]].backgroundColor,
            }}
          >
            {row.columns[header.name]}
          </span>
        );
      case EnhancedTableHeaderType.BadgeArray: {
        const values = row.columns[header.name] as string[];
        return (
          <div>
            {values.map((v, index) => {
              const isLastIndex = values.length === index + 1;
              return (
                <span
                  className={classes.cellBadge}
                  key={v}
                  style={{
                    color: header.badgeConfig[v.toString()].color,
                    backgroundColor: header.badgeConfig[v].backgroundColor,
                    marginRight: !isLastIndex ? '5px' : 0,
                  }}
                >
                  {v}
                </span>
              );
            })}
          </div>
        );
      }
      case EnhancedTableHeaderType.Currency: {
        if (!isValidNumber(row.columns[header.name])) {
          return row.columns[header.name];
        }

        const currency =
          header.currency || row.columns['Currency'] || currencySymbol;

        const value = Number(row.columns[header.name]);
        const displayValue = FormattingServices.formatCustomCurrency(
          Math.abs(value),
          currency
        );
        const isPostive = value >= 0;
        if (isPostive) {
          return displayValue;
        }

        return <>{`(${displayValue})`}</>;
      }
      case EnhancedTableHeaderType.RoundedCurrency: {
        const currency =
          header.currency || row.columns['Currency'] || currencySymbol;

        if (!isValidNumber(row.columns[header.name])) {
          return `${currency} 0`;
        }
        const value = Number(row.columns[header.name]);
        const displayValue = FormattingServices.formatCustomCurrency(
          Math.abs(value),
          currency,
          3,
          true
        );
        const isPostive = value >= 0;
        if (isPostive) {
          return displayValue;
        }

        return <>{`(${displayValue})`}</>;
      }
      case EnhancedTableHeaderType.ColoredCurrency: {
        if (!isValidNumber(row.columns[header.name])) {
          return row.columns[header.name];
        }

        const currency =
          header.currency || row.columns['Currency'] || currencySymbol;

        const value = Number(row.columns[header.name]);
        const displayValue = FormattingServices.formatCustomCurrency(
          Math.abs(value),
          currency
        );
        const isPostive = value >= 0;
        if (isPostive) {
          return (
            <span className={classes.positiveCurrency}>{displayValue}</span>
          );
        }

        return (
          <span className={classes.negativeCurrency}>
            {`(${displayValue})`}
          </span>
        );
      }
      case EnhancedTableHeaderType.Percentage:
        return isValidNumber(row.columns[header.name])
          ? `${addZeroesAndSeparatevalue(row.columns[header.name])} %`
          : row.columns[header.name];
      case EnhancedTableHeaderType.RoundedPercentage:
        return isValidNumber(row.columns[header.name])
          ? `${trimTrailingZeros(addZeroesAndSeparatevalue(row.columns[header.name]))} %`
          : row.columns[header.name];
      case EnhancedTableHeaderType.Rate:
        return isValidNumber(row.columns[header.name])
          ? `${PrecisionService.multiplyBy100(row.columns[header.name])} %`
          : row.columns[header.name];

      case EnhancedTableHeaderType.Classification:
        return header.classificationPropertyName ? (
          <div className={classes.classificationContainer}>
            <figure
              className={classes.icon}
              style={{
                backgroundColor: row.columns[header.classificationPropertyName],
              }}
            />
            <span
              className={classes.classificationInput}
              style={{ color: row.columns[header.classificationPropertyName] }}
            >
              {row.columns[header.name]}
            </span>
          </div>
        ) : (
          row.columns[header.name]
        );

      case EnhancedTableHeaderType.PhoneNumber: {
        const value =
          row.columns[header.name] && phoneNumberPattern
            ? maskValueWithPatternIfMatch(
                row.columns[header.name],
                phoneNumberPattern
              )
            : row.columns[header.name];
        return <LimitedTextWrapper text={value} maxCharacter={30} />;
      }
      case EnhancedTableHeaderType.IntPhoneNumber: {
        let value = row.columns[header.name];
        if (value && isPossiblePhoneNumber(value)) {
          value = formatPhoneNumberIntl(value);
        }

        return <LimitedTextWrapper text={value} maxCharacter={30} />;
      }
      case EnhancedTableHeaderType.Image: {
        const value = row.columns[header.name];
        return header.hasConditionalImage ? (
          value.toLowerCase() === 'active' ? (
            <Tooltip title={value}>
              <img
                src={header.variableOneImageKey}
                alt=""
                width={14}
                height={14}
              />
            </Tooltip>
          ) : (
            <Tooltip title={value}>
              <img
                src={header.variableTwoImageKey}
                alt=""
                width={14}
                height={14}
              />
            </Tooltip>
          )
        ) : (
          <img src={header.imageKey} alt="" width={20} height={20} />
        );
      }
      case EnhancedTableHeaderType.JSXElement: {
        return row.columns[header.name];
      }
      case EnhancedTableHeaderType.Input: {
        switch (header.inputType) {
          case EnhancedHeaderInputTypes.Decimal: {
            return (
              <>
                <EnhancedFormattedDecimalInput
                  title=""
                  name=""
                  inline
                  onChange={(e) => {
                    const { value } = e.target;
                    if (
                      isEmpty(value) ||
                      isValidDecimalCount(Number(value), 10)
                    ) {
                      setCurrentValue(e.target.value);
                      handleCellValueChanged(row.index, header.name, value);
                    }
                  }}
                  onBlur={() => {
                    const value = !isEmpty(currentValue)
                      ? Number(currentValue)
                      : '';

                    handleUpdate(row.index, header.name, value);
                  }}
                  value={currentValue || ''}
                  error={header.error || row.columns.error}
                  disabled={header.disabled || row.columns?.disabled}
                  placeholder={header.title}
                  className={classes.editableInput}
                  isEditable={true}
                  minValue={header.minValue}
                  maxValue={header.maxValue}
                />
              </>
            );
          }
          case EnhancedHeaderInputTypes.Text: {
            return (
              <EnhancedInput
                title=""
                name=""
                inline
                type="text"
                onChange={(e) => {
                  setCurrentValue(e.target.value);
                  handleCellValueChanged(
                    row.index,
                    header.name,
                    e.target.value
                  );
                }}
                onBlur={() => {
                  handleUpdate(row.index, header.name, currentValue);
                }}
                value={currentValue || ''}
                error={header.error || row.columns.error}
                hideError={false}
                disabled={header.disabled || row.columns?.disabled}
                placeholder={header.title}
              />
            );
          }
          case EnhancedHeaderInputTypes.RoundedCurrency: {
            return (
              <CurrencyFormField
                name=""
                title=""
                inputOnly
                hideError
                value={currentValue || ''}
                maxDecimalPrecision={header.maxDecimalPercision}
                currencySymbol={
                  header.currency || row.columns['Currency'] || currencySymbol
                }
                onChange={(e) => {
                  setCurrentValue(e.target.value);
                  handleCellValueChanged(
                    row.index,
                    header.name,
                    e.target.value
                  );
                }}
                onBlur={() => {
                  handleUpdate(row.index, header.name, currentValue);
                }}
                allowNegative={header.allowNegative}
                disabled={header.disabled || row.columns?.disabled}
                error={header.error || row.columns.error}
                placeholder={header.title}
                classes={{
                  inputContainer: classes.inputContainer,
                }}
              />
            );
          }
          case EnhancedHeaderInputTypes.Date: {
            return (
              <DatePickerFormField
                title=""
                name=""
                inputOnly
                onDateChange={(value) => {
                  setCurrentValue(value);
                  handleCellValueChanged(row.index, header.name, value);
                }}
                value={currentValue || ''}
                error={header.error || row.columns.error}
                disabled={header.disabled || row.columns?.disabled}
                placeholder={header.title}
                hideError
                classes={{
                  inputContainer: classes.inputContainer,
                }}
              />
            );
          }
          case EnhancedHeaderInputTypes.Select: {
            return (
              <SelectFormField
                title=""
                name=""
                inputOnly
                onChange={(value) => {
                  setCurrentValue(value);
                  handleCellValueChanged(
                    row.index,
                    header.name,
                    value,
                    row.key
                  );
                }}
                onBlur={() => {
                  handleUpdate(row.index, header.name, currentValue);
                }}
                value={currentValue || ''}
                error={header.error || row.columns.error}
                hideError
                disabled={header.disabled || row.columns?.disabled}
                placeholder={header.title}
                selectOptions={header.options || {}}
                classes={{
                  input: clsx(
                    classes.inputContainer,
                    tableClasses.inputContainer
                  ),
                }}
              />
            );
          }
          default:
            break;
        }
      }
      // eslint-disable-next-line no-fallthrough
      default:
        return (
          <LimitedTextWrapper
            text={row.columns[header.name]}
            maxCharacter={showCellFullData ? 300 : 80}
          />
        );
    }
  }

  const renderToolTipsWrapper = () => {
    if (header.tooltipSettings && row.columns.id) {
      const allHeaders: Record<string, EnhancedTableHeader> =
        header.tooltipSettings.headers;
      return (
        <EnhancedToolTip
          displayContent={
            <span className={classes.cellbutton}> {renderCellContents()} </span>
          }
        >
          <EnhancedToolTipTable
            headers={allHeaders}
            rowId={row.columns.id}
            tooltipFetch={header.tooltipSettings.tooltipFetch}
          />
        </EnhancedToolTip>
      );
    }

    return renderCellContents();
  };

  return (
    <TableCell
      component="th"
      scope="row"
      padding="none"
      classes={{ root: classes.cellRoot }}
      className={className}
    >
      {renderToolTipsWrapper()}
    </TableCell>
  );
};

export default EnhancedTableBodyCell;

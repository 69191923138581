import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { clearUserTokens, setUserTokens } from '../../utils/userUtils';
import { IUserMeta, LoadUserSuccessAction, LoginSuccessAction } from './types';

const initialState: IUserMeta = {
  isAuthenticated: false,
  info: {
    id: '',
    firstName: '',
    lastName: '',
    businessUser_Id: '',
    businessUser_Code: '',
    email: '',
    agentType: '',
    businessUser_AllowGroupPolicy: false,
    businessUser_AllowPolicyCorrection: false,
    businessUser_AllowEndorsementCostEdits: false,
    businessUser_AllowEndorsement: false,
  },
  userRoles: [],
  haveWeakPassword: false,
  linesOfBusinessStatuses: {
    Active: false,
    Code: '',
    Title: '',
  },
};

const usersSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    LoginSuccess(state, action: PayloadAction<LoginSuccessAction>) {
      state.isAuthenticated = true;
      const { jwtToken, id_token, refreshTokenObject } = action.payload;
      setUserTokens(jwtToken, id_token, refreshTokenObject);
      return state;
    },
    LoadUserSuccess(state, action: PayloadAction<LoadUserSuccessAction>) {
      state = {
        ...action.payload.user,
        isAuthenticated: true,
      };
      return state;
    },
    LogoutUser(state) {
      state = initialState;
      clearUserTokens();
      return state;
    },
  },
});

export const { LoginSuccess, LoadUserSuccess, LogoutUser } = usersSlice.actions;

const userReducer = usersSlice.reducer;
export default userReducer;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import AuthLayout from '../../layouts/AuthBody';
import WidgetPaper from '../../components/common/WidgetPaper';
import WidgetSection from '../../components/common/WidgetSection';
import { MAIN_ONE_THEME } from '../../constants';
import { Controller, useForm } from 'react-hook-form';
import EnhancedInput from '../../components/enhanced-form/EnhancedInput';
import { isEmpty } from '../../utils/validationUtils';
import { IEnvironmentSettings, initialValues } from '.';
import EnvironmentSettingsDrawer from '../environment-settings-drawer/EnvironmentSettingsDrawer';
import { useMutation } from '@apollo/client';
import { getEnvironmentSettings } from './queries';
import DateService from '../../services/dateService';

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingLeft: '15px',
  },
  header: {
    margin: '0 0 3em',
  },
  title: {
    display: 'block',
    textAlign: 'left',
    fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
    fontFamily: 'HelveticaNeue-Medium ',
    letterSpacing: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontWeight: 'normal',
    width: '103%',
    gridArea: 'title',
    alignSelf: 'center',
  },
  mainContainer: {
    paddingTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'flex-start',
    width: '100%',
    backgroundColor: 'rgb(247, 247, 247)',
  },
  logoFig: {
    margin: '0 auto',
    lineHeight: '0',
  },
  loginBoxContainer: {
    width: '430px',
    margin: '46px auto 0',
    maxWidth: '491px',
  },
  formContainer: {
    padding: '24px 30px',
    width: '100%',
  },
  formError: {
    color: theme.palette.error.main,
    height: 'fit-content',
    marginTop: '8px',
    boxSizing: 'border-box',
    padding: '0px 12px 0px 12px',
    fontSize: '11px',
    textAlign: 'center',
  },
  button: {
    background: 'none',
    border: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: 0,
    paddingTop: '0px',
    marginTop: '-6px',
  },
}));

const EnvironmentSettingsPage: React.FC = () => {
  const { classes } = useStyles();
  const [environmentSettingsDrawerOpen, setEnvironmentSettingsDrawerOpen] =
    useState<boolean>(false);
  const [environment, setEnvironment] = useState<string>('');
  const [entityResult, setEntityResult] = useState<any>(null);

  const windowLocationEnvironmentName = window.location.hostname.toLowerCase();
  const sourceBranchEnvironmentName = 'Development';

  const [getEnvironmentSettingsLazy] = useMutation(getEnvironmentSettings(), {
    variables: {},
    onCompleted: (data) => {
      setEntityResult(data);
    },
  });

  useEffect(() => {
    getEnvironmentSettingsLazy();
  }, []);

  useEffect(() => {
    if (windowLocationEnvironmentName && sourceBranchEnvironmentName) {
      setEnvironment('Development');
    } else if (windowLocationEnvironmentName === 'localhost') {
      setEnvironment('Development');
    }
  }, [windowLocationEnvironmentName, sourceBranchEnvironmentName]);

  const { control } = useForm<IEnvironmentSettings>({
    defaultValues: initialValues,
    shouldUnregister: true,
  });

  function openEnvironmentSettingsDrawer() {
    setEnvironmentSettingsDrawerOpen(true);
  }

  return (
    <AuthLayout mainContainer={classes.mainContainer}>
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <section className={classes.header}>
            <h1 className={classes.title}>{'Environment Settings'}</h1>
          </section>
        </div>
        <div className={classes.loginBoxContainer}>
          <WidgetPaper>
            <WidgetSection
              title="Environment"
              narrowSpacing={false}
              style={{ margin: '0' }}
              useSeparator={false}
            >
              <Controller
                name="environmentName"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <label style={{ marginRight: '10px' }}>{'Name:'}</label>
                    <EnhancedInput
                      type="text"
                      name="environmentName"
                      title=""
                      onChange={(e) => {
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        onBlur();
                      }}
                      value={!isEmpty(environment) ? environment : ''}
                      specificMaterial
                      disabled
                    />
                  </div>
                )}
              />

              <Controller
                name="minimumIssueDate"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <label style={{ marginRight: '10px' }}>
                        {'Issue Date Reference:'}
                      </label>
                      <EnhancedInput
                        type="text"
                        name="minimumIssueDate"
                        title=""
                        onChange={(e) => {
                          onChange(e);
                        }}
                        onBlur={(e) => {
                          onBlur();
                        }}
                        value={
                          entityResult?.insurance?.actions
                            ?.getEnvironmentSettings?.IssueDateReference
                            ? DateService.formatDate(
                                entityResult?.insurance?.actions
                                  ?.getEnvironmentSettings?.IssueDateReference
                              )
                            : ''
                        }
                        disabled
                        style={{ marginLeft: '10px' }}
                        specificMaterial
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        overflow: 'visible',
                      }}
                    >
                      <label style={{ marginRight: '10px' }}></label>
                      <button
                        type="button"
                        className={classes.button}
                        onClick={openEnvironmentSettingsDrawer}
                      >
                        {'Change'}
                      </button>
                      {environmentSettingsDrawerOpen && (
                        <EnvironmentSettingsDrawer
                          open={environmentSettingsDrawerOpen}
                          minimumIssueDate={
                            entityResult?.insurance?.actions
                              ?.getEnvironmentSettings?.IssueDateReference
                          }
                          onClose={() => {
                            setEnvironmentSettingsDrawerOpen(false);
                            getEnvironmentSettingsLazy();
                          }}
                          onSuccess={() => {
                            getEnvironmentSettingsLazy();
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
              />
            </WidgetSection>
          </WidgetPaper>
        </div>
      </div>
    </AuthLayout>
  );
};

export default EnvironmentSettingsPage;

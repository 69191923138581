import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getSections, initialValues } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { isEmpty } from 'lodash';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { IPersonDetailsDrawerInfo, IPersonDetailsDrawerProps } from '.';
import SectionDynamicForm from '../../DynamicForm/SectionDynamicForm';
import {
  checkCompanyExists,
  checkPersonExists,
  getListForm,
  updatePersonInfo,
} from './queries';
import { toLookups } from './utils';
import { getError } from '../../utils/graph-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DateService from '../../services/dateService';

const PersonDetailsDrawer: React.FC<IPersonDetailsDrawerProps> = ({
  personId,
  open,
  onSuccess,
  onClose,
  personDetailsInfo,
}) => {
  const [booted, setBooted] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [values, setValues] = useState<IPersonDetailsDrawerInfo>();
  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({});

  const [personDetailsListResults] = useLazyQuery(getListForm(), {
    fetchPolicy: 'no-cache',
  });
  const [checkPersonExistsLazy] = useLazyQuery(checkPersonExists(), {});
  const [checkCompanyExistsLazy] = useLazyQuery(checkCompanyExists(), {});

  const [personAction] = useMutation(updatePersonInfo());

  const loadLovList = async () => {
    const result = await personDetailsListResults();
    const personEnums = toLookups(result.data);

    return personEnums;
  };

  const initialize = async () => {
    try {
      const lovData = await loadLovList();

      setLovs(lovData);
      if (personDetailsInfo) {
        setValues({
          ...personDetailsInfo,
        });
      } else {
        setValues({ ...initialValues });
      }
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const validateCompanyNameExists = async (companyName: string) => {
    if (!isEmpty(companyName)) {
      const result = await checkCompanyExistsLazy({
        variables: {
          companyName: companyName,
          personID: personId,
        },
      });
      if (
        result.data.SalesforceManagement?.queries?.checkCompanyNameExists
          .length > 0
      ) {
        return 'Company already Exists';
      }
    }
    return '';
  };

  const validatePersonNameExistsOnChange = async (
    firstName: string,
    middleName: string,
    lastName: string,
    dateOfBirth: Date
  ) => {
    if (!isEmpty(firstName) && !isEmpty(middleName) && !isEmpty(lastName)) {
      const result = await checkPersonExistsLazy({
        variables: {
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          dateOfBirth: DateService.formatDateBackend(dateOfBirth),
        },
      });

      if (
        result.data.SalesforceManagement?.queries?.checkPersonExists.length > 0
      ) {
        if (
          result.data.SalesforceManagement?.queries?.checkPersonExists?.[0]
            .salesforceManagement_Person_Id !== personId
        ) {
          return 'Person already Exists';
        }
      }
    }
    return '';
  };

  const onChange = async (
    fieldName: string,
    value: unknown,
    values: Record<string, any>
  ) => {
    setValues(values as any);
  };

  const onCustomValidate = async (
    fieldName: string,
    values: Record<string, any>
  ) => {
    if (fieldName === 'companyName') {
      return await validateCompanyNameExists(values.companyName);
    }

    if (
      fieldName === 'firstName' ||
      fieldName === 'middleName' ||
      fieldName === 'lastName' ||
      fieldName === 'dateOfBirth'
    ) {
      return await validatePersonNameExistsOnChange(
        values.firstName,
        values.middleName,
        values.lastName,
        values.dateOfBirth
      );
    }

    return '';
  };

  const onBlur = async (
    fieldName: string,
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    if (
      fieldName === 'firstName' ||
      fieldName === 'middleName' ||
      fieldName === 'lastName' ||
      fieldName === 'dateOfBirth'
    ) {
      if (values.firstName && values.middleName && values.lastName) {
        const error = await onCustomValidate(fieldName, values);
        errors.firstName = error;
        errors.middleName = error;
        errors.lastName = error;
      }
    }

    if (fieldName === 'companyName') {
      if (!errors.companyName) {
        const error = await onCustomValidate(fieldName, values);
        errors.companyName = error;
      }
    }
    return { values, errors };
  };

  const onCustomValidateForm = async (
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    const isCompany = values.type?.toLowerCase() === 'company';
    const isPerson = values?.type?.toLowerCase() === 'person';

    if (
      isPerson &&
      !errors.firstName &&
      !errors.middleName &&
      !errors.lastName
    ) {
      const error = await onCustomValidate('firstName', values);
      errors.firstName = error;
      errors.middleName = error;
      errors.lastName = error;
    }

    if (isCompany && !errors.companyName) {
      const error = await onCustomValidate('companyName', values);
      errors.companyName = error;
    }
    return errors;
  };

  const submitForm = async (data: Record<string, any>) => {
    setSubmitButtonState('loading');
    setFormDisabled(true);

    try {
      const selectedType = data.type.toLowerCase();
      const isPerson = selectedType === 'person';
      const isCompany = selectedType === 'company';

      const variablesMutation = {
        entityId: personId,
        dateOfBirth: isPerson
          ? DateService.formatDateBackend(data.dateOfBirth)
          : null,
        dateOfDeath: isPerson
          ? DateService.formatDateBackend(data.dateOfDeath)
          : null,
        dateOfDeathNotified: isPerson
          ? DateService.formatDateBackend(data.dateOfDeathNotified)
          : null,
        dateOfEmployment: isPerson
          ? DateService.formatDateBackend(data.dateOfEmployment)
          : null,
        dateOfKYC: isPerson
          ? DateService.formatDateBackend(data.dateOfKYC)
          : null,
        dateOfSelfCertificateReceived: isPerson
          ? DateService.formatDateBackend(data.dateOfSelfCertificateReceived)
          : null,
        dateOfSelfCertificateValidated: isPerson
          ? DateService.formatDateBackend(data.dateOfSelfCertificateValidated)
          : null,
        personDetailsInputs: {
          firstName: isPerson ? data.firstName || null : null,
          middleName: isPerson ? data.middleName || null : null,
          lastName: isPerson ? data.lastName || null : null,
          email: data.email || null,
          personType: data.type,
          title: isPerson ? data.title : null,
          phoneType: data.phoneType,
          phoneNumber: data.phoneNumber,
          companyName: isCompany ? data.companyName || null : null,
        },
        personnalInfoInputs: {
          comments: data.comments || null,
          employer: isPerson ? data.employer || null : null,
          height: isPerson ? parseInt(data.height as unknown as string) : null,
          initials: data.initials || null,
          nationalityOne: isPerson ? data.nationality : null,
          profession: isPerson ? data.profession || null : null,
          relativeOf: data.relativeOf || null,
          salary: isPerson ? parseInt(data.salary as unknown as string) : null,
          smoker: isPerson ? data.smoker || null : null,
          surname: isPerson ? data.surname || null : null,
          weight: isPerson ? parseInt(data.weight as unknown as string) : null,
          financialReference: data.financialReference || null,
          isTaxable: data.taxable ? data.taxable : false,
          occupationClass: isPerson ? data.occupationClass || null : null,
        },
      };

      const res = await personAction({
        variables: variablesMutation,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {'Person successfully updated'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          setFormDisabled(false);
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        setFormDisabled(false);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      setFormDisabled(false);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const sections = useMemo(() => {
    return getSections(values, lovs);
  }, [values, lovs]);

  return (
    <GenericDrawer
      title="Modify Personal Information"
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <SectionDynamicForm
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            onCustomBlur={onBlur}
            onCustomValidate={onCustomValidateForm}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            sections={sections}
            hasDoprdownSpecificBehavior={true}
            onChange={onChange}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PersonDetailsDrawer;

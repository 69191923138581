import React, { useMemo } from 'react';
import { IDynamicDiplayView } from '../../../components/widgets/dynamic-display';
import DynamicWidget from '../../../components/widgets/dynamic-display/DynamicWidget';
import {
  capitalizeFirstLetter,
  valueCommaSeparated,
} from '../../../utils/formatting-utils';
import { EnhancedDisplayType } from '../../../components/enhanced-form/EnhancedDisplay';

interface IPersonalInformationDetailsWidgetProps {
  data: Record<string, any>;
  actions?: JSX.Element;
}

const PersonalInformationDetailsWidget: React.FC<
  IPersonalInformationDetailsWidgetProps
> = ({ data, actions }) => {
  const view = useMemo(() => {
    const isPerson = data.type.toUpperCase() === 'PERSON';
    const dynamicView: IDynamicDiplayView = {
      name: 'Personal Information',
      sections: [
        {
          name: 'generalDetails',
          title: 'General Details',
          showActionButtons: true,
          properties: {
            type: {
              multiline: false,
              value: isPerson
                ? 'Individual'
                : capitalizeFirstLetter(data?.type),
              name: 'type',
              title: 'Type',
            },
            companyName: {
              multiline: false,
              value: data?.companyName,
              name: 'companyName',
              title: 'Company Name',
              hidden: isPerson,
            },
            title: {
              multiline: false,
              value: capitalizeFirstLetter(data?.title),
              name: 'title',
              title: 'Title',
              hidden: !isPerson,
            },
            firstName: {
              multiline: false,
              value: data?.firstName,
              name: 'firstName',
              title: 'First Name',
              hidden: !isPerson,
            },
            middleName: {
              multiline: false,
              value: data?.middleName,
              name: 'middleName',
              title: 'Middle Name',
              hidden: !isPerson,
            },
            lastName: {
              multiline: false,
              value: data?.lastName,
              name: 'lastName',
              title: 'Last Name',
              hidden: !isPerson,
            },
            surname: {
              multiline: false,
              value: data?.surname,
              name: 'Maiden Name',
              title: 'Maiden Name',
              hidden: !isPerson,
            },
            initials: {
              multiline: false,
              value: data?.initials,
              name: 'initials',
              title: 'Initials',
            },
            dateOfBirth: {
              multiline: false,
              value: data?.dateOfBirth,
              name: 'dateOfBirth',
              title: 'Date of Birth',
              hidden: !isPerson,
              type: EnhancedDisplayType.Date,
            },
            nationality: {
              multiline: false,
              value: capitalizeFirstLetter(data?.nationality),
              name: 'nationality',
              title: 'Nationality',
              hidden: !isPerson,
            },
          },
        },
        {
          name: 'contactDetails',
          title: 'Contact Details',
          showActionButtons: false,
          properties: {
            email: {
              multiline: false,
              value: data?.email,
              name: 'email',
              title: 'Email',
            },
            phoneType: {
              multiline: false,
              value: capitalizeFirstLetter(data?.phoneType),
              name: 'phoneType',
              title: 'Phone Type',
            },
            phoneNumber: {
              multiline: false,
              value: data?.phoneNumber,
              name: 'phoneNumber',
              title: 'Phone Number',
            },
            relativeOf: {
              multiline: false,
              value: data?.relativeOf,
              name: 'relativeOf',
              title: 'Relative of',
            },
          },
        },
        {
          name: 'healthDetails',
          title: 'Health Details',
          showActionButtons: false,
          hidden: !isPerson,
          properties: {
            smoker: {
              multiline: false,
              value: capitalizeFirstLetter(data?.smoker),
              name: 'smoker',
              title: 'Smoker',
              hidden: !isPerson,
            },
            weight: {
              multiline: false,
              value: data?.weight,
              name: 'weight',
              title: 'Weight (kg)',
              hidden: !isPerson,
            },
            height: {
              multiline: false,
              value: data?.height,
              name: 'Height',
              title: 'Height (cm)',
              hidden: !isPerson,
            },
            dateOfDeath: {
              multiline: false,
              value: data?.dateOfDeath,
              name: 'dateOfDeath',
              title: 'Date of Death',
              hidden: !isPerson,
              type: EnhancedDisplayType.Date,
            },
            dateOfDeathNotified: {
              multiline: false,
              value: data?.dateOfDeathNotified,
              name: 'dateOfDeathNotified',
              title: 'Date of Death Notified',
              hidden: !isPerson,
              type: EnhancedDisplayType.Date,
            },
          },
        },
        {
          name: 'businessDetails',
          title: 'Business Details',
          showActionButtons: false,
          properties: {
            taxable: {
              multiline: false,
              value: data?.taxable ? 'Yes' : 'No',
              name: 'taxable',
              title: 'Taxable',
            },
            financialReference: {
              multiline: false,
              value: data?.financialReference,
              name: 'financialReference',
              title: 'Financial Reference',
            },
            occupationClass: {
              multiline: false,
              value: capitalizeFirstLetter(data?.occupationClass),
              name: 'occupationClass',
              title: 'Occupation Class',
              hidden: !isPerson,
            },
            profession: {
              multiline: false,
              value: data?.profession,
              name: 'profession',
              title: 'Profession',
              hidden: !isPerson,
            },
            employer: {
              multiline: false,
              value: data?.employer,
              name: 'employer',
              title: 'Employer / Institute',
              hidden: !isPerson,
            },
            dateOfEmployment: {
              multiline: false,
              value: data?.dateOfEmployment,
              name: 'dateOfEmployment',
              title: 'Date of Employment',
              hidden: !isPerson,
              type: EnhancedDisplayType.Date,
            },
            salary: {
              multiline: false,
              value: `$ ${valueCommaSeparated(data?.salary)}`,
              name: 'salary',
              title: 'Salary',
              hidden: !isPerson,
            },
            dateOfSelfCertificateReceived: {
              multiline: false,
              value: data?.dateOfSelfCertificateReceived,
              name: 'dateOfSelfCertificateReceived',
              title: 'Date of Self Certificate Received',
              hidden: !isPerson,
              type: EnhancedDisplayType.Date,
            },
            dateOfSelfCertificateValidated: {
              multiline: false,
              value: data?.dateOfSelfCertificateValidated,
              name: 'dateOfSelfCertificateValidated',
              title: 'Date of Self Certificate Validated',
              hidden: !isPerson,
              type: EnhancedDisplayType.Date,
            },
            category: {
              multiline: false,
              value: capitalizeFirstLetter(data?.category),
              name: 'category',
              title: 'Category',
              hidden: !isPerson,
            },
            dateOfKYC: {
              multiline: false,
              value: data?.dateOfKYC,
              name: 'dateOfKYC',
              title: 'Date of KYC',
              hidden: !isPerson,
              type: EnhancedDisplayType.Date,
            },
            comments: {
              multiline: true,
              value: data?.comments,
              name: 'comments',
              title: 'Comments',
              hidden: false,
            },
          },
        },
      ],
    };

    return dynamicView;
  }, [data]);

  return <DynamicWidget hasFourFields={true} view={view} actions={actions} />;
};

export default PersonalInformationDetailsWidget;

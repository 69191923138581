import { cloneDeep, isEmpty } from 'lodash';
import {
  IFacCoverDetails,
  IFacDetails,
  IFacDetailsResponse,
  IFacDocumentResponse,
  IFacErrors,
  IFacLovs,
} from '.';
import { initialFacValues, validationRules } from './content';
import { FileUploadStatus, IFileCardProps } from '../../../../models/file';
import { IAbstractRecord } from '../../../../models';
import { IActivityEntityBase } from '../../../../activities';

export function mapDataToFacDetails(data: IFacDetailsResponse[]): IFacDetails {
  if (isEmpty(data)) {
    return initialFacValues.values;
  }
  const covers = data.map((cover) => {
    return {
      coverId: cover.policyPlanCover_Id,
      effectiveFrom: cover.policyPlanCover_CoverEffectiveFrom,
      effectiveTo: cover.policyPlanCover_CoverEffectiveTo,
      currencyCode:
        cover.policyPlanCover_ProposalID.queries.production.pricingOption[0]
          ?.pricingOption_PricingOptionCurrency?.Code,
      currencySymbol:
        cover.policyPlanCover_ProposalID.queries.production.pricingOption[0]
          ?.pricingOption_PricingOptionCurrency?.Symbol,
      cededSumInsured:
        cover.facultativeCovers_CededSumInsured?.toString() || '',
      cededPremium: cover.facultativeCovers_CededPremium?.toString() || '',
      facultativeRate:
        cover.facultativeCovers_FacultativeRate?.toString() || '',
      commissionRate: cover.facultativeCovers_CommissionRate?.toString() || '',
      commissionAmount:
        cover.facultativeCovers_CommissionAmount?.toString() || '',
      sumInsuredIsAdditive: cover.policyPlanCover_SumInsuredIsAdditive,
      maxCededSumInsured: 0,
      maxCededPremium: 0,
      maxSumInsured: 0,
      maxPremium: 0,
    };
  });

  let totalCededSumInsured = 0,
    totalCededPremium = 0,
    totalCommission = 0;

  covers.forEach((cover) => {
    if (cover.sumInsuredIsAdditive) {
      totalCededSumInsured += Number(cover.cededSumInsured);
    }
    totalCededPremium += Number(cover.cededPremium);
    totalCommission += Number(cover.commissionAmount);
  });

  const firstElement = data[0];

  const result: IFacDetails = {
    createdBy: firstElement.production_Facultatives_CreatedByName,
    createdOn: firstElement.production_Facultatives_createdOn,
    modifiedBy: firstElement.production_Facultatives_UpdatedByName,
    modifiedOn: firstElement.production_Facultatives_modifiedOn,
    status: firstElement.production_Facultatives_FacultativeStatus,
    linkedTo: firstElement.production_Facultatives_LinkedTo,
    facId: firstElement.production_Facultatives_FacultativeID,
    slipRefNo: firstElement.production_Facultatives_SlipReferenceNumber ?? '',
    proposal: firstElement.proposal_Id,
    policy: firstElement.policy_Id,
    paymentDueDate: firstElement.production_Facultatives_PaymentDueDate,
    totalCededSumInsured: totalCededSumInsured,
    totalCededPremium: totalCededPremium,
    totalCommission: totalCommission,
    covers: covers,
  };

  return result;
}

export function getSumOf(
  covers: IFacCoverDetails[],
  key: keyof IFacCoverDetails
): number {
  return covers.reduce((acc: number, cover: IFacCoverDetails) => {
    if (key === 'cededSumInsured') {
      return cover.sumInsuredIsAdditive
        ? acc + Number(cover.cededSumInsured)
        : acc;
    }

    return acc + Number(cover[key]);
  }, 0);
}

export const initializeErrors = (values: IFacDetails): IFacErrors => {
  const errors: IFacErrors = {
    facId: '',
    slipRefNo: '',
    proposal: '',
    policy: '',
    paymentDueDate: '',
    totalCededSumInsured: '',
    totalCededPremium: '',
    totalCommission: '',
    covers: [],
  };

  values.covers.forEach(() => {
    errors.covers.push({
      coverId: '',
      effectiveFrom: '',
      effectiveTo: '',
      currencyCode: '',
      currencySymbol: '',
      cededSumInsured: '',
      cededPremium: '',
      facultativeRate: '',
      commissionRate: '',
      commissionAmount: '',
      sumInsuredIsAdditive: '',
    });
  });

  return errors;
};

export const mapFileToDocumentWidgetFiles = (
  documentsData: IFacDocumentResponse[]
): IFileCardProps[] => {
  const docsList: IFileCardProps[] = [];

  if (documentsData && documentsData.length > 0) {
    documentsData.map((document) => {
      docsList.push({
        title: document?.production_FacultativeDocuments_DocumentName,
        version: document?.production_FacultativeDocuments_Version,
        status: FileUploadStatus.Uploaded,
        allowedFileTypes: [],
        entityViewId: 'Production-all',
        propertyId: 'Document',
        entityId:
          document?.production_FacultativeDocuments_Document?.path?.split(
            '/'
          )[2],
        entityTypeId: 'Production-FacultativeDocuments',
        hideReviewedLabel: true,
        createdOn: document?.production_FacultativeDocuments_createdOn,
        fileInfo: {
          contentType:
            document?.production_FacultativeDocuments_Document?.contentType,
          fileName:
            document?.production_FacultativeDocuments_Document?.fileName,
          id: document?.production_FacultativeDocuments_Document?.id,
          length: document?.production_FacultativeDocuments_Document?.length,
          location:
            document?.production_FacultativeDocuments_Document?.location,
          path: document?.production_FacultativeDocuments_Document?.path,
        },
      });
    });
  }
  return docsList;
};

export function convertAuditTrailsToActivities(
  data: IAbstractRecord
): IActivityEntityBase[] {
  return data.Production.queries.GetFacAuditTrails.map(
    (auditTrail: Record<string, string>) => ({
      id: auditTrail.production_AuditTrail_Id,
      createdAt: auditTrail.production_AuditTrail_createdOn,
      htmlBody: auditTrail.production_AuditTrail_Message,
      iconUrl: extractIconUrl(auditTrail.production_AuditTrail_Message),
    })
  );
}

function extractIconUrl(html: string): string | undefined {
  const match = html.match(/background-image: url\('(.+?)'\)/);
  return match ? match[1] : undefined;
}

export function getCoversDetails(
  data: IAbstractRecord,
  type: 'proposal' | 'policy'
) {
  const covers: IFacLovs['covers'] = {
    name: {},
    dates: {},
  };
  data?.Production?.queries?.getPolicyPlanCovers?.forEach(
    (cover: IAbstractRecord) => {
      covers.name[cover.production_PolicyPlanCover_Id] = cover.policyCover_Name;
      covers.dates[cover.production_PolicyPlanCover_Id] = {
        effectiveDate: cover.production_PolicyPlanCover_CoverEffectiveFrom,
        expiryDate: cover.production_PolicyPlanCover_CoverEffectiveTo,
      };
    }
  );

  const entity =
    type === 'proposal'
      ? data.Production.entities.proposal
      : data.Production.entities.policy;

  const pricingOption =
    entity.queries.production.pricingOption[0]
      ?.pricingOption_PricingOptionCurrency;

  return {
    currencyCode: pricingOption?.Code,
    currencySymbol: pricingOption?.Symbol,
    covers,
  };
}

const validateField = (
  value: unknown,
  fieldName: string,
  errorObj: any
): boolean => {
  if (value === 0 || value === '0' || value) {
    errorObj[fieldName] = '';
    return false;
  } else {
    errorObj[fieldName] = 'Required';
    return true;
  }
};

export const validateForm = (
  values: IFacDetails,
  errors: IFacErrors
): { isValid: boolean; errors: IFacErrors } => {
  const newErrors = cloneDeep(errors);
  let hasError = false;

  for (const field in validationRules) {
    if (field === 'covers') {
      continue;
    } else if (field === 'policy' && values.linkedTo === 'PROPOSAL') {
      continue;
    } else if (field === 'proposal' && values.linkedTo === 'POLICY') {
      continue;
    }

    hasError =
      validateField(values[field as keyof IFacDetails], field, newErrors) ||
      hasError;
  }

  values.covers.forEach((cover, index) => {
    for (const field in validationRules.covers) {
      hasError =
        validateField(
          cover[field as keyof IFacCoverDetails],
          field,
          newErrors.covers[index]
        ) || hasError;
    }
  });

  return { isValid: !hasError, errors: newErrors };
};

export const extractValidationDetails = ({
  coverData,
  remainingAmountsData,
}: {
  coverData: IAbstractRecord;
  remainingAmountsData: IAbstractRecord;
}): {
  maxCededSumInsured: number;
  maxCededPremium: number;
  maxSumInsured: number;
  maxPremium: number;
  sumInsuredIsAdditive: boolean;
} => {
  const remainingAmounts =
    remainingAmountsData.production.actions.calculateRemainingSIAndNP;
  const PolicyPlanCover =
    coverData.Production.queries.GetPolicyPlanCoverDetails[0];

  return {
    maxCededSumInsured: remainingAmounts?.RemainingCoverSumInsured,
    maxCededPremium: remainingAmounts?.RemainingCoverPremium,
    maxSumInsured: PolicyPlanCover?.production_PolicyPlanCover_CoverSumInsured,
    maxPremium: PolicyPlanCover?.production_PolicyPlanCover_CoverPremiumValue,
    sumInsuredIsAdditive:
      PolicyPlanCover?.production_PolicyPlanCover_SumInsuredIsAdditive,
  };
};

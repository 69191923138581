import { EnhancedDisplayType } from '../../../components/enhanced-form/EnhancedDisplay';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../components/enhanced-table';
import { IEntityInfoView } from '../../../components/widgets/entity-info/EntityInfoFields';
import { ITabsLayout, LayoutType } from '../../../page-layout';

export const steps = ['Inactive', 'Active'];

export const entityView: IEntityInfoView = {
  name: 'section1',
  title: 'Plan Details',
  iconUrl: '',
  sections: [
    {
      name: 'planDetailsSection1',
      title: '',
      properties: {
        lineName: {
          value: '',
          multiline: false,
          name: 'lineName',
          title: 'Line',
        },
        sublineName: {
          value: '',
          multiline: false,
          name: 'sublineName',
          title: 'Subline Name',
        },
      },
    },
    {
      name: 'planDetailsSection2',
      title: '',
      properties: {
        createdBy: {
          value: '',
          multiline: false,
          name: 'createdBy',
          title: 'Created By',
        },
        createdOn: {
          value: '',
          multiline: false,
          name: 'createdOn',
          title: 'Created On',
          type: EnhancedDisplayType.DateTime,
        },
        modifiedBy: {
          value: '',
          multiline: false,
          name: 'modifiedBy',
          title: 'Modified By',
        },
        modifiedOn: {
          value: '',
          multiline: false,
          name: 'modifiedOn',
          title: 'Modified On',
          type: EnhancedDisplayType.DateTime,
        },
      },
    },
  ],
  socialMedia: {
    facebook: '',
    linkedIn: '',
    twitter: '',
  },
};

export const policyCoverHeaders: Record<string, EnhancedTableHeader> = {
  planConfigManagement_PolicyCover_Name: {
    name: 'planConfigManagement_PolicyCover_Name',
    title: 'Policy Cover Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_ExternalCode: {
    name: 'planConfigManagement_PolicyCover_ExternalCode',
    title: 'External Code',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_NameArabic: {
    name: 'planConfigManagement_PolicyCover_NameArabic',
    title: 'Arabic Name',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_PrintingOrder: {
    name: 'planConfigManagement_PolicyCover_PrintingOrder',
    title: 'Printing Order',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PolicyCover_PolicyCoverDescription: {
    name: 'planConfigManagement_PolicyCover_PolicyCoverDescription',
    title: 'Description',
    type: EnhancedTableHeaderType.Text,
  },
  createdOn: {
    name: 'planConfigManagement_PolicyCover_createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
  modifiedOn: {
    name: 'planConfigManagement_PolicyCover_modifiedOn',
    title: 'Modified On',
    type: EnhancedTableHeaderType.Date,
  },
};

export const planTabs: ITabsLayout = {
  typeLogicalId: LayoutType.tabslayout,
  leftColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 2,
    collapsed: false,
  },
  mainColumn: {
    backgroundColor: '#F7F7F7',
    collapsable: false,
    width: 10,
    collapsed: false,
  },
  rightColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 0,
    collapsed: false,
  },
  tabs: [
    {
      name: 'summary',
      title: 'Summary',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'coversandrates',
      title: 'Covers & Rates',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
  ],
};

export const allRiskPlanRatesHeaders: Record<string, EnhancedTableHeader> = {
  planConfigManagement_PlanSpecificAllRisk_CarValueFrom: {
    name: 'planConfigManagement_PlanSpecificAllRisk_CarValueFrom',
    title: 'Car Value From',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  planConfigManagement_PlanSpecificAllRisk_CarValueTo: {
    name: 'planConfigManagement_PlanSpecificAllRisk_CarValueTo',
    title: 'Car Value To',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  planConfigManagement_PlanSpecificAllRisk_CarAgeFrom: {
    name: 'planConfigManagement_PlanSpecificAllRisk_CarAgeFrom',
    title: 'Car Age From',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificAllRisk_CarAgeTo: {
    name: 'planConfigManagement_PlanSpecificAllRisk_CarAgeTo',
    title: 'Car Age To',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificAllRisk_CarCategory: {
    name: 'planConfigManagement_PlanSpecificAllRisk_CarCategory',
    title: 'Car Category',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificAllRisk_AgencyYear: {
    name: 'planConfigManagement_PlanSpecificAllRisk_AgencyYear',
    title: 'Agency Year',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear: {
    name: 'planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear',
    title: 'Depreciation Year',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificAllRisk_MinimumPremium: {
    name: 'planConfigManagement_PlanSpecificAllRisk_MinimumPremium',
    title: 'Minimum Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  planConfigManagement_PlanSpecificAllRisk_Rate: {
    name: 'planConfigManagement_PlanSpecificAllRisk_Rate',
    title: 'Rate',
    type: EnhancedTableHeaderType.Text,
  },
};

export const expatPlanRatesHeaders: Record<string, EnhancedTableHeader> = {
  planConfigManagement_PlanSpecificExpat_AgeFrom: {
    name: 'planConfigManagement_PlanSpecificExpat_AgeFrom',
    title: 'Age From',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificExpat_AgeTo: {
    name: 'planConfigManagement_PlanSpecificExpat_AgeTo',
    title: 'Age To',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificExpat_Gender: {
    name: 'planConfigManagement_PlanSpecificExpat_Gender',
    title: 'Gender',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificExpat_OccupationClass: {
    name: 'planConfigManagement_PlanSpecificExpat_OccupationClass',
    title: 'Occupation Class',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificExpat_Nationality: {
    name: 'planConfigManagement_PlanSpecificExpat_Nationality',
    title: 'Nationality',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificExpat_MedicalClass: {
    name: 'planConfigManagement_PlanSpecificExpat_MedicalClass',
    title: 'Medical Class',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificExpat_Emergency: {
    name: 'planConfigManagement_PlanSpecificExpat_Emergency',
    title: 'Emergency Included',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificExpat_Premium: {
    name: 'planConfigManagement_PlanSpecificExpat_Premium',
    title: 'Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
};

export function getTravelPlanRatesHeaders(
  planCurrency: string
): Record<string, EnhancedTableHeader> {
  return {
    planConfigManagement_PlanSpecificTravel_PolicyCoverID: {
      name: 'planConfigManagement_PlanSpecificTravel_PolicyCoverID',
      title: 'Policy Cover',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_IsMain: {
      name: 'planConfigManagement_PlanSpecificTravel_IsMain',
      title: 'Main',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_IsMandatory: {
      name: 'planConfigManagement_PlanSpecificTravel_IsMandatory',
      title: 'Mandatory',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_IsEditable: {
      name: 'planConfigManagement_PlanSpecificTravel_IsEditable',
      title: 'Editable',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_NbOfAdherent: {
      name: 'planConfigManagement_PlanSpecificTravel_NbOfAdherent',
      title: 'Number Of Adherent',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_AgeFrom: {
      name: 'planConfigManagement_PlanSpecificTravel_AgeFrom',
      title: 'Age From',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_AgeTo: {
      name: 'planConfigManagement_PlanSpecificTravel_AgeTo',
      title: 'Age To',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_Gender: {
      name: 'planConfigManagement_PlanSpecificTravel_Gender',
      title: 'Gender',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_TravelDurationFrom: {
      name: 'planConfigManagement_PlanSpecificTravel_TravelDurationFrom',
      title: 'Travel Duration From (Days)',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_TravelDurationTo: {
      name: 'planConfigManagement_PlanSpecificTravel_TravelDurationTo',
      title: 'Travel Duration To (Days)',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_TravelDestination: {
      name: 'planConfigManagement_PlanSpecificTravel_TravelDestination',
      title: 'Travel Destination',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_SumInsured: {
      name: 'planConfigManagement_PlanSpecificTravel_SumInsured',
      title: 'SumInsured',
      type: EnhancedTableHeaderType.RoundedCurrency,
      currency: planCurrency,
    },
    planConfigManagement_PlanSpecificTravel_SumInsuredIsAdditive: {
      name: 'planConfigManagement_PlanSpecificTravel_SumInsuredIsAdditive',
      title: 'Additive SumInsured',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_Premium: {
      name: 'planConfigManagement_PlanSpecificTravel_Premium',
      title: 'Premium',
      type: EnhancedTableHeaderType.RoundedCurrency,
      currency: planCurrency,
    },
    planConfigManagement_PlanSpecificTravel_TPAFees: {
      name: 'planConfigManagement_PlanSpecificTravel_TPAFees',
      title: 'TPA Fees',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_MinTPAFees: {
      name: 'planConfigManagement_PlanSpecificTravel_MinTPAFees',
      title: 'Min TPA Fees',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_MaxTPAFees: {
      name: 'planConfigManagement_PlanSpecificTravel_MaxTPAFees',
      title: 'Max TPA Fees',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_ExcessOnClaimType: {
      name: 'planConfigManagement_PlanSpecificTravel_ExcessOnClaimType',
      title: 'Excess On Claim Type',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_ExcessOnClaim: {
      name: 'planConfigManagement_PlanSpecificTravel_ExcessOnClaim',
      title: 'Excess On Claim',
      type: EnhancedTableHeaderType.Text,
    },
    planConfigManagement_PlanSpecificTravel_ClauseReference: {
      name: 'planConfigManagement_PlanSpecificTravel_ClauseReference',
      title: 'Clause Reference',
      type: EnhancedTableHeaderType.Text,
    },
  };
}

export const medicalPlanRatesHeaders: Record<string, EnhancedTableHeader> = {
  planConfigManagement_PlanSpecificMedical_PolicyCoverID: {
    name: 'planConfigManagement_PlanSpecificMedical_PolicyCoverID',
    title: 'Policy Cover',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_IsMain: {
    name: 'planConfigManagement_PlanSpecificMedical_IsMain',
    title: 'Main',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_IsMandatory: {
    name: 'planConfigManagement_PlanSpecificMedical_IsMandatory',
    title: 'Mandatory',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_IsEditable: {
    name: 'planConfigManagement_PlanSpecificMedical_IsEditable',
    title: 'Editable',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_NbOfAdherent: {
    name: 'planConfigManagement_PlanSpecificMedical_NbOfAdherent',
    title: 'Nb Of Adherent',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_Class: {
    name: 'planConfigManagement_PlanSpecificMedical_Class',
    title: 'Class',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_CoNssf: {
    name: 'planConfigManagement_PlanSpecificMedical_CoNssf',
    title: 'Co-Nssf',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_Level: {
    name: 'planConfigManagement_PlanSpecificMedical_Level',
    title: 'Level',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_AgeFrom: {
    name: 'planConfigManagement_PlanSpecificMedical_AgeFrom',
    title: 'Age From',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_AgeTo: {
    name: 'planConfigManagement_PlanSpecificMedical_AgeTo',
    title: 'Age To',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_SumInsured: {
    name: 'planConfigManagement_PlanSpecificMedical_SumInsured',
    title: 'SumInsured',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_AdditiveSumInsured: {
    name: 'planConfigManagement_PlanSpecificMedical_AdditiveSumInsured',
    title: 'Additive SumInsured',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_NetPremium: {
    name: 'planConfigManagement_PlanSpecificMedical_NetPremium',
    title: 'Net Premium',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_TPAFees: {
    name: 'planConfigManagement_PlanSpecificMedical_TPAFees',
    title: 'TPA Fees',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_MinTPAFees: {
    name: 'planConfigManagement_PlanSpecificMedical_MinTPAFees',
    title: 'Min TPA Fees',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_MaxTPAFees: {
    name: 'planConfigManagement_PlanSpecificMedical_MaxTPAFees',
    title: 'Max TPA Fees',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_ExcessOnClaimType: {
    name: 'planConfigManagement_PlanSpecificMedical_ExcessOnClaimType',
    title: 'Excess On Claim Type',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_ExcessOnClaim: {
    name: 'planConfigManagement_PlanSpecificMedical_ExcessOnClaim',
    title: 'Excess On Claim',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanSpecificMedical_ClauseReference: {
    name: 'planConfigManagement_PlanSpecificMedical_ClauseReference',
    title: 'Clause Reference',
    type: EnhancedTableHeaderType.Text,
  },
};

export const planCoversRatesHeaders: Record<string, EnhancedTableHeader> = {
  planConfigManagement_PlanCover_PolicyCoverID: {
    name: 'planConfigManagement_PlanCover_PolicyCoverID',
    title: 'Policy Cover',
    type: EnhancedTableHeaderType.Link,
    urlTemplate:
      '/plan/covers/{planConfigManagement_PlanCover_PolicyCoverID_ID}',
    openUrlInNewTab: false,
  },
  planConfigManagement_PlanCover_IsMain: {
    name: 'planConfigManagement_PlanCover_IsMain',
    title: 'Main',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanCover_IsMandatory: {
    name: 'planConfigManagement_PlanCover_IsMandatory',
    title: 'Mandatory',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanCover_IsEditable: {
    name: 'planConfigManagement_PlanCover_IsEditable',
    title: 'Editable',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanCover_CoverPremiumType: {
    name: 'planConfigManagement_PlanCover_CoverPremiumType',
    title: 'Premium Type',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanCover_CoverPremiumValue: {
    name: 'planConfigManagement_PlanCover_CoverPremiumValue',
    title: 'Premium',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanCover_ExcessOnClaimAmount: {
    name: 'planConfigManagement_PlanCover_ExcessOnClaimAmount',
    title: 'Excess On Claim',
    type: EnhancedTableHeaderType.Text,
  },

  planConfigManagement_PlanCover_CoverSumInsured: {
    name: 'planConfigManagement_PlanCover_CoverSumInsured',
    title: 'Sum Insured',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanCover_SumInsuredIsAdditive: {
    name: 'planConfigManagement_PlanCover_SumInsuredIsAdditive',
    title: 'Additive Sum Insured',
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_PlanCover_ClauseID: {
    name: 'planConfigManagement_PlanCover_ClauseID',
    title: 'Clause Reference',
    type: EnhancedTableHeaderType.Text,
  },
};

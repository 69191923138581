import { gql } from '@apollo/client';

export const getPolicyListQuery = gql`
  query getPolicyList(
    $KeywordSearch: String
    $pagination: PaginationInput!
    $selectedStatuses: [Production_PolicyStatuses]
  ) {
    Production {
      queries {
        getPolicyList(
          KeywordSearch: $KeywordSearch
          selectedStatuses: $selectedStatuses
          pagination: $pagination
        ) {
          items {
            production_Policy_Id
            production_Policy_PolicyName
          }
          paging {
            totalCount
          }
        }
      }
    }
  }
`;

export const GetActiveProposalsPaginated = gql`
  query GetActiveProposalsPaginated(
    $KeywordSearch: String
    $pagination: PaginationInput!
  ) {
    Production {
      queries {
        GetActiveProposalsPaginated(
          KeywordSearch: $KeywordSearch
          pagination: $pagination
        ) {
          items {
            production_Proposal_Id
            production_Proposal_ProposalName
          }
          paging {
            totalCount
          }
        }
      }
    }
  }
`;

export const getPolicyDetailsQuery = gql`
  query getPolicyDetails($policyID: String!) {
    Production {
      queries {
        getPolicyPlanCovers(selectedPolicyID: $policyID) {
          production_PolicyPlanCover_Id
          policyCover_Name
          production_PolicyPlanCover_CoverEffectiveFrom
          production_PolicyPlanCover_CoverEffectiveTo
        }
      }
      entities {
        policy(id: $policyID) {
          views {
            Production_all {
              properties {
                RelatedCompany {
                  Code
                }
              }
            }
          }
          queries {
            production {
              pricingOption {
                pricingOption_PricingOptionCurrency {
                  Code
                  Symbol
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getProposalDetailsQuery = gql`
  query getProposalDetails($proposalId: String!) {
    Production {
      queries {
        getPolicyPlanCovers(selectedProposalID: $proposalId) {
          production_PolicyPlanCover_Id
          policyCover_Name
          production_PolicyPlanCover_CoverEffectiveFrom
          production_PolicyPlanCover_CoverEffectiveTo
        }
      }
      entities {
        proposal(id: $proposalId) {
          views {
            Production_all {
              properties {
                RelatedCompany {
                  Code
                }
              }
            }
          }
          queries {
            production {
              pricingOption {
                pricingOption_PricingOptionCurrency {
                  Code
                  Symbol
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const createFacultativeMutation = gql`
  mutation createFacultativeMutation(
    $Proposal: String
    $Policy: String
    $LinkedTo: Production_LinkTo!
    $Company: String!
    $ProposalCover: String!
    $Reinsurer: String!
  ) {
    production {
      actions {
        createFacultative(
          Proposal: $Proposal
          Policy: $Policy
          LinkedTo: $LinkedTo
          Company: $Company
          ProposalCover: $ProposalCover
          Reinsurer: $Reinsurer
        ) {
          id
        }
      }
    }
  }
`;

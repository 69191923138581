import { IFacultativeDrawerLovs } from '.';
import { IAbstractRecord } from '../../../../models';

export const extractDetails = (
  data: IAbstractRecord,
  type: 'proposal' | 'policy'
) => {
  if (!data) return {};
  const covers: IFacultativeDrawerLovs['covers'] = {
    name: {},
    dates: {},
  };
  data?.Production?.queries?.getPolicyPlanCovers?.forEach(
    (cover: IAbstractRecord) => {
      covers.name[cover.production_PolicyPlanCover_Id] = cover.policyCover_Name;
      covers.dates[cover.production_PolicyPlanCover_Id] = {
        effectiveDate: cover.production_PolicyPlanCover_CoverEffectiveFrom,
        expiryDate: cover.production_PolicyPlanCover_CoverEffectiveTo,
      };
    }
  );

  const entity =
    type === 'proposal'
      ? data.Production.entities.proposal
      : data.Production.entities.policy;
  const entityCurrency =
    entity.queries.production.pricingOption[0]
      ?.pricingOption_PricingOptionCurrency;

  return {
    company: entity.views.Production_all.properties.RelatedCompany?.Code,
    currencyCode: entityCurrency.Code,
    currencySymbol: entityCurrency.Symbol,
    covers,
  };
};
